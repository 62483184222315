import { styled } from '@mui/system'
import MDButton from 'components/MDButton'

const Button_DemHead = styled(MDButton)(({theme}) => ({
  backgroundColor: '#1c4e80',
  color: '#f1f1f1',
  border: '2px solid #f1f1f1',
  // color: '#7e909a',
  '&: focus:not(:hover)': {
    backgroundColor: '#7e909a',
    color: '#f1f1f1'
  },
  '&: hover': {
    backgroundColor: '#0091d5',
    color: '#f1f1f1',
    border: '2px solid #f1f1f1'
  },
  '&: disabled': {
    backgroundColor: '#7e909a',
    color: '#dadada'
  }
}))

export default Button_DemHead