function getAnnualOpportunity(kpi, allData, goalVal, goalCol, daysInPeriod){

  let kpiIndex
  let multiplier

  // Set multiplier
  switch(kpi){
    case 'Average Check Total':
      kpiIndex = allData.findIndex(x => x.kpi === 'Check Count')
      multiplier = allData[kpiIndex].actualValue
      break;
    case 'Average Check Dine-In':
      kpiIndex = allData.findIndex(x => x.kpi === 'Dine-In Check Count')
      multiplier = allData[kpiIndex].actualValue
      break;
    case 'Average Check Delivery (Gross)':
    case 'Average Check Delivery (Net)':
      kpiIndex = allData.findIndex(x => x.kpi === 'Delivery Check Count (Gross/Net)')
      multiplier = allData[kpiIndex].actualValue
      break;
    case 'Average Check To Go':
      kpiIndex = allData.findIndex(x => x.kpi === 'To Go Check Count')
      multiplier = allData[kpiIndex].actualValue
      break;
    case 'Check Count':
      kpiIndex = allData.findIndex(x => x.kpi === 'Average Check Total')
      multiplier = allData[kpiIndex].actualValue
      break;
    case 'Dine-In Check Count':
      kpiIndex = allData.findIndex(x => x.kpi === 'Average Check Dine-In')
      multiplier = allData[kpiIndex].actualValue
      break;
    case 'Delivery Check Count (Gross/Net)':
      kpiIndex = allData.findIndex(x => x.kpi === 'Average Check Delivery (Gross)')
      multiplier = allData[kpiIndex].actualValue
      break;
    case 'To Go Check Count':
      kpiIndex = allData.findIndex(x => x.kpi === 'Average Check To Go')
      multiplier = allData[kpiIndex].actualValue
      break;
    default:
      multiplier = 1
  }

  // Convert multiplier to only digits
  if(isNaN(multiplier)){
    multiplier = multiplier.replace('$', '')
    multiplier = multiplier.replace(',', '')
  }

  // Get Net Sales
  let netSalesIndex = allData.findIndex(x => x.kpi === 'Net Sales')
  let netSales = allData[netSalesIndex].actualValue 
  netSales = netSales.replace('$','')
  netSales = netSales.replace(',', '') 
  
  // Set Annual Opportunity
  let annualOpp
  let tempOpp
  let goalValue
  let curIndex = allData.findIndex(x => x.kpi === kpi)
  let curValue = allData[curIndex].actualValue 
  curValue = curValue.replace('$', '')
  curValue = curValue.replace(',','')

  switch(goalCol){
    case 'dollarOpp':
      goalValue = goalVal.replace('$', '')
      goalValue = goalValue.replace(',', '')

      tempOpp = (goalValue - curValue) * multiplier / daysInPeriod * 365
      tempOpp = tempOpp.toFixed(0)
      annualOpp = '$' + tempOpp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      return annualOpp
      break;
    case 'percentOpp':
      let netSalesIndex = allData.findIndex(x => x.kpi === 'Net Sales')
      let netSales = allData[netSalesIndex].actualValue 
      netSales = netSales.replace('$','')
      netSales = netSales.replace(',', '') 
      
      goalValue = goalVal.replace('%', '')
      let goalDollars = goalVal / 100 * netSales
      tempOpp = (goalDollars - curValue) / daysInPeriod * 365
      tempOpp = tempOpp.toFixed(0)
      annualOpp = '$' + tempOpp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      return annualOpp
      break;
    default:
      return null
  }  
}

export default getAnnualOpportunity