import { useEffect, useState } from 'react'

export default function useCachedFetch(fetchData, setStatus) {
  const [data, setData] = useState(null)

  useEffect(() => {
    let cancel = false

    async function loadData() {
      const response = await fetchData(setStatus)
      if(!cancel) setData(response)
    }

    if(!data) loadData()

    return () => {
      cancel = true
    }
  }, [])

  return data
}