import { useState } from 'react'

import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress,Stack, Typography } from '@mui/material'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import useProfiles from './useProfiles'

import R0005Profile from '../R0005Profile'
import AddProfileDialog from './AddProfileDialog'
import EditProfileDialog from './EditProfileDialog'
import createProfile from './createProfile'
import editProfile from './editProfile'

export default function ThresholdProfiles({
  width
}){
  console.log('*** ThresholdProfiles', width)
  const [expand, setExpand] = useState(false)
  const [openAddProfile, setOpenAddProfile] = useState(false)
  const [openEditProfile, setOpenEditProfile] = useState(false)
  const [profileToEdit, setProfileToEdit] = useState(null)

  const { status, data: profileData, error  } = useProfiles()

  const isLoading = !profileData && status !== 'error'

  function addNewProfile(profileData) {
    
    setOpenAddProfile(false)
    
    createProfile(profileData)
  }

  function updateProfile(profileData){
    
    setProfileToEdit(null)
    setOpenEditProfile(false)
    editProfile(profileData)
  }

  function handleEdit(profileId){
    
    const targetProfile = profileData.filter(p => p.id === profileId)
    
    setProfileToEdit(targetProfile[0])
    setOpenEditProfile(true)
  }

  return (
    <Box>
      {openAddProfile && (
        <AddProfileDialog 
          open={ openAddProfile }
          close={ () => setOpenAddProfile(false) }
          existingNames={['Profile 1']}
          submit={(profile) => addNewProfile(profile) }
        />
      )}
      {
        openEditProfile &&
        profileToEdit && (
          <EditProfileDialog 
            open={ openEditProfile }
            close={ () => setOpenEditProfile(false) }
            existingNames={['Profile 1']}
            submit={(profile) => updateProfile(profile)}
            profile={profileToEdit}
          />
        )
      }
      
      <Accordion 
        expanded={expand}
        style={{width: width}}
      >
        <TPHeader 
          expand={expand}
          setExpand={setExpand}
          setOpenAdd={setOpenAddProfile}
        />
        <TPList 
          data={profileData}
          isLoading={isLoading}
          onEdit={(id) => handleEdit(id)}
          closeEdit={() => setOpenEditProfile(false)}
        />
      </Accordion>
    </Box>
  )
}

function TPHeader({
  expand,
  openAdd,
  setExpand,
  setOpenAdd
}){
  console.log('expand', expand)
  return (
    <AccordionSummary 
      expandIcon={
        <ExpandMoreIcon 
          style={{
            cursor: 'pointer'
          }}
          onClick={() => setExpand(!expand)}
        />
      }
      sx={{
        cursor: 'unset !important'
      }}
      aria-controls='panel1a-content'
      id='panel1a-header'
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
        sx={{
          width: '95%'
        }}
      >
        <Typography>
          Threshold Profiles
        </Typography>
        {
          expand &&
          <Button
            variant='contained'
            sx={{
              mr: 2
            }}
            onClick={() => setOpenAdd(true)}
          >
            Add Profile
          </Button>
        }
      </Stack>
    </AccordionSummary>
  )
}

function TPList({
  data,
  isLoading,
  onEdit,
  closeEdit
}){
  return (
    <AccordionDetails>
      {
        isLoading ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress 
              color='success'
            />
          </Box>
        ) : data == null || data.length === 0 ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant='subtitle2'>
              No Profiles Found
            </Typography>
          </Box>
        ) : (
          <Stack direction='column' spacing={1}>
            <Typography variant='caption'>
              Rules are applied in the order shown within each profile.
            </Typography>
            {
              data.map((profile) => (
                <R0005Profile 
                  key={profile.id}
                  profile={profile}
                  onEdit={(id) => onEdit(id)}
                  closeEdit={closeEdit}
                />
              ))
            }
          </Stack>
        )
      }
    </AccordionDetails>
  )
}
