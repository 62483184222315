import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// AWS Amplify components
import { API } from 'aws-amplify'

// GraphQL
import { createStore, deleteStore, updateStore } from '../../../graphql/mutations';  

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDAlert from 'components/MDAlert'

// RadixDash components
import AddStoreDialog from './AddStoreDialog';
import EditStoreDialog from './EditStoreDialog'
import RDTable from 'components/RDTable'
import StoreCellStyle from './StoreCellStyle';


export default function StoreTable(props){
  console.log('StoreTable...')
  console.log('props', props)

  const managers = props.managers
  
  const [data, setData] = useState(props.stores)
  const [stores, setStores] = useState([])
  const [openAddStore, setOpenAddStore] = useState(false)
  const [openAddProgress, setOpenAddProgress] = useState(false)
  const [openEditStore, setOpenEditStore] = useState(false)
  const [rowToEdit, setRowToEdit] = useState(null)
  const [alert, setAlert] = useState(null)
  const [alertMessage, setAlertMessage] = useState({
    type: 'none',
    message: ''
  })

  // Render Function for Managers column
  function Managers(props) {
    const managerList = props.managers.value
    
    if(managerList !== null){
    
      return (
        
        <List>
          {managerList.map((manager, i) => (
            <ListItem key={i}>{managers[manager]}</ListItem>
          ))}
        </List>
      )
    } else {
      return null
    }
  }

  // Render Function for Theoretical Hours column
  function TheoHrs(row) {
    const values = row.row.row.values
    if(values.shortName !== null){
      let dataIdx = data.findIndex(x => x.shortName === values.shortName)
      let fohMon = 0
      let fohTue = 0
      let fohWed = 0
      let fohThu = 0
      let fohFri = 0
      let fohSat = 0
      let fohSun = 0
      let bohMon = 0
      let bohTue = 0
      let bohWed = 0
      let bohThu = 0
      let bohFri = 0
      let bohSat = 0
      let bohSun = 0
      if(dataIdx !== -1){
        fohMon = data[dataIdx].fohMon
        fohTue = data[dataIdx].fohTue
        fohWed = data[dataIdx].fohWed
        fohThu = data[dataIdx].fohThu
        fohFri = data[dataIdx].fohFri
        fohSat = data[dataIdx].fohSat
        fohSun = data[dataIdx].fohSun
        bohMon = data[dataIdx].bohMon
        bohTue = data[dataIdx].bohTue
        bohWed = data[dataIdx].bohWed
        bohThu = data[dataIdx].bohThu
        bohFri = data[dataIdx].bohFri
        bohSat = data[dataIdx].bohSat
        bohSun = data[dataIdx].bohSun
      }

      return (
        
          <table style={{paddingLeft:'10px', paddingRight:'10px'}}>
            <thead>
              <tr>
                <th></th>
                <th style={{ paddingLeft:'10px', paddingRight:'10px' }}>
                  Mo
                </th>
                <th style={{ paddingLeft:'10px', paddingRight:'10px' }}>
                  Tu
                </th>
                <th style={{ paddingLeft:'10px', paddingRight:'10px' }}>
                  We
                </th>
                <th style={{ paddingLeft:'10px', paddingRight:'10px' }}>
                  Th
                </th>
                <th style={{ paddingLeft:'10px', paddingRight:'10px' }}>
                  Fr
                </th>
                <th style={{ paddingLeft:'10px', paddingRight:'10px' }}>
                  Sa
                </th>
                <th style={{ paddingLeft:'10px', paddingRight:'10px' }}>
                  Su
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ paddingRight:'10px' }}>
                  <strong>FOH</strong>
                </td>
                <td style={{ textAlign:'center' }}>
                  {fohMon}
                </td>
                <td style={{ textAlign:'center' }}>
                  {fohTue}
                </td>
                <td style={{ textAlign:'center' }}>
                  {fohWed}
                </td>
                <td style={{ textAlign:'center' }}>
                  {fohThu}
                </td>
                <td style={{ textAlign:'center' }}>
                  {fohFri}
                </td>
                <td style={{ textAlign:'center' }}>
                  {fohSat}
                </td>
                <td style={{ textAlign:'center' }}>
                  {fohSun}
                </td>
              </tr>
              <tr>
                <td style={{ paddingRight:'10px' }}>
                  <strong>BOH</strong>
                </td>
                <td style={{ textAlign:'center' }}>
                  {bohMon}
                </td>
                <td style={{ textAlign:'center' }}>
                  {bohTue}
                </td>
                <td style={{ textAlign:'center' }}>
                  {bohWed}
                </td>
                <td style={{ textAlign:'center'}}>
                  {bohThu}
                </td>
                <td style={{  textAlign:'center' }}>
                  {bohFri}
                </td>
                <td style={{ textAlign:'center' }}>
                  {bohSat}
                </td>
                <td style={{ textAlign:'center',}}
                >
                  {bohSun}
                </td>
              </tr>
            </tbody>
          </table>
      )
    } else {
      return null
    }
  }
  
  const columns = [
    { 
      Header: 'Area', 
      accessor: 'areaName', 
      defaultGroupOrder: 0, 
      defaultGroupSort: 'asc',
      align: 'center',
      columnStyle: {
        backgroundColor: '#e0e0e0',
        color: '#181818',
        borderBottom: '1px solid #000',
        fontSize: '.7rem',
        fontWeight: 'bold',
        paddingTop: '5px'
      }
    },
    { 
      Header: 'Store', 
      accessor: 'shortName', 
      disableGroupBy: true,
      align: 'center',
      columnStyle: {
        backgroundColor: '#e0e0e0',
        color: '#181818',
        borderBottom: '1px solid #000',
        fontSize: '.7rem',
        fontWeight: 'bold',
        paddingTop: '5px'
      },
    },
    { 
      Header: 'Managers', 
      accessor: 'gm', 
      disableGroupBy: true,
      Cell: rowData => (<Managers managers={rowData} /> ),
      align: 'center',
      width: '400px',
      columnStyle: {
        backgroundColor: '#e0e0e0',
        color: '#181818',
        borderBottom: '1px solid #000',
        fontSize: '.7rem',
        fontWeight: 'bold',
        paddingTop: '5px'
      },
    },
    { 
      Header: 'Type', 
      accessor: 'type', 
      align: 'center',
      alignCell: 'center',
      columnStyle: {
        backgroundColor: '#e0e0e0',
        color: '#181818',
        borderBottom: '1px solid #000',
        fontSize: '.7rem',
        fontWeight: 'bold',
        paddingTop: '5px'
      },
    },
    { 
      Header: 'Status', 
      accessor: 'status', 
      align: 'center',
      columnStyle: {
        backgroundColor: '#e0e0e0',
        color: '#181818',
        borderBottom: '1px solid #000',
        fontSize: '.7rem',
        fontWeight: 'bold',
        paddingTop: '5px'
      },
    },
    { 
      Header: 'Theoretical Hours', 
      Cell: rowData => (
        <TheoHrs row={rowData} />
      ),
      disableSortBy: true,
      align: 'center',
      columnStyle: {
        backgroundColor: '#e0e0e0',
        color: '#181818',
        borderBottom: '1px solid #000',
        fontSize: '.7rem',
        fontWeight: 'bold',
        paddingTop: '5px'
      },
    },   
  ]


  useEffect(() => {
    console.log('data changed', data)
  }, [data])

  async function addStore(storeData) {
    console.log('addStore(storeData)...', storeData)
    setOpenAddProgress(true)

    // Set fohBaseTheoHrs and bohBaseTheoHrs
    let bohHrs = {}
    let fohHrs = {}
    if(storeData.setDailyTheo === false){
      bohHrs = {
        Mon: storeData.defaultBOH,      
        Tue: storeData.defaultBOH,      
        Wed: storeData.defaultBOH,      
        Thu: storeData.defaultBOH,      
        Fri: storeData.defaultBOH,      
        Sat: storeData.defaultBOH,      
        Sun: storeData.defaultBOH,      
      }
      fohHrs = {
        Mon: storeData.defaultFOH,      
        Tue: storeData.defaultFOH,      
        Wed: storeData.defaultFOH,      
        Thu: storeData.defaultFOH,      
        Fri: storeData.defaultFOH,      
        Sat: storeData.defaultFOH,      
        Sun: storeData.defaultFOH,      
      }
    } else {
      bohHrs = {
        Mon: storeData.monBOH,      
        Tue: storeData.tueBOH,      
        Wed: storeData.wedBOH,      
        Thu: storeData.thuBOH,      
        Fri: storeData.friBOH,      
        Sat: storeData.satBOH,      
        Sun: storeData.sunBOH,      
      }
      fohHrs = {
        Mon: storeData.monFOH,      
        Tue: storeData.tueFOH,      
        Wed: storeData.wedFOH,      
        Thu: storeData.thuFOH,      
        Fri: storeData.friFOH,      
        Sat: storeData.satFOH,      
        Sun: storeData.sunFOH,    
      }
    }

    let managers = storeData.managers.map(x => ({username: x}) )

    // Get areaId of selected area
    const areaIdx = props.areas.findIndex(x => x.areaName === storeData.areaName)
    const areaId = props.areas[areaIdx].areaId
    
    // create the newStore object
    let newStore = {
      shortName: storeData.shortName,
      areaId: areaId,
      fohBaseTheoHrs: fohHrs,
      bohBaseTheoHrs: bohHrs,
      gm: managers,
      status: storeData.status,
      type: storeData.type
    }

    console.log('newStore', newStore)

    try {
      console.log('attempt createStore')
      let result = await API.graphql(
        {
          query: createStore,
          variables: {
            input: newStore
          }
        }
      )
      console.log('Successfully added new store to DB', result)

      // Add new store to existing stores
      let storeObj = {
        areaName: storeData.areaName,
        bohMon: bohHrs.Mon,
        bohTue: bohHrs.Tue,
        bohWed: bohHrs.Wed,
        bohThu: bohHrs.Thu,
        bohFri: bohHrs.Fri,
        bohSat: bohHrs.Sat,
        bohSun: bohHrs.Sun,
        fohMon: fohHrs.Mon,
        fohTue: fohHrs.Tue,
        fohWed: fohHrs.Wed,
        fohThu: fohHrs.Thu,
        fohFri: fohHrs.Fri,
        fohSat: fohHrs.Sat,
        fohSun: fohHrs.Sun,
        gm: storeData.managers,
        shortName: storeData.shortName,
        type: storeData.type,
        status: storeData.status
      }
      console.log('storeObj', storeObj)

      // Update the dataTable with the new Store
      let newData = [...data, storeObj]
      console.log('setData - newData', newData)
      setData([...newData])
      setOpenAddProgress(false)
      setOpenAddStore(false)
      
      // Notify Admin that the Store creation was successful
      setAlertMessage({
        type: 'success',
        message: `The ${storeData.shortName} store has been created`
      })

    } catch (err) {
      console.log('Error creating store', err)
      setOpenAddProgress(false)
      setOpenAddStore(false)
      // Notify Admin that Store creation failed
      setAlertMessage({
        type: 'error',
        message: err
      })
    }

    
  }

  // comparison function
  function cmp(x, y) {
    return x > y ? 1 : x < y ? -1 : 0
  }

  useEffect(() => {
    console.log('rowToEdit changed', rowToEdit)
    if(rowToEdit){
      setOpenEditStore(true)
    }
  }, [rowToEdit])

  async function modifyStore(values){
    console.log('modifyStore(values)...', values)

    const index = values.index
    const newData = values.newData
    const oldData = values.oldData

    // Set fohBaseTheoHrs and bohBaseTheoHrs
    let bohHrs = {}
    let fohHrs = {}
    
    bohHrs = {
      Mon: newData.monBOH,      
      Tue: newData.tueBOH,      
      Wed: newData.wedBOH,      
      Thu: newData.thuBOH,      
      Fri: newData.friBOH,      
      Sat: newData.satBOH,      
      Sun: newData.sunBOH,      
    }
    fohHrs = {
      Mon: newData.monFOH,      
      Tue: newData.tueFOH,      
      Wed: newData.wedFOH,      
      Thu: newData.thuFOH,      
      Fri: newData.friFOH,      
      Sat: newData.satFOH,      
      Sun: newData.sunFOH,    
    }
    
    let managers = newData.managers.map(x => ({username: x}) )
    
    // Get areaId for new area
    let areaId = oldData.areaId
    let newAreaId
    if(newData.areaName === oldData.areaName){
      newAreaId = areaId
    } else {
      const index = data.findIndex(x => x.areaName === newData.areaName)
      newAreaId = data[index].areaId
    }

    // create the newStore object
    let updatedStore = {
      storeId: oldData.storeId,
      longName: oldData.longName,
      shortName: newData.shortName,
      areaId: areaId,
      newAreaId: newAreaId,
      fohBaseTheoHrs: fohHrs,
      bohBaseTheoHrs: bohHrs,
      gm: managers,
      type: newData.type,
      status: newData.status,
      compStores: newData.compStores
    }

    console.log('updatedStore', updatedStore)
    try {
      let result = await API.graphql(
        {
          query: updateStore,
          variables: {
            input: updatedStore
          }
        }
      )
      console.log('Successfully updated Store', result)

      // Add new store to existing stores
      let storeObj = {
        areaId: areaId,
        areaName: newData.areaName,
        bohMon: bohHrs.Mon,
        bohTue: bohHrs.Tue,
        bohWed: bohHrs.Wed,
        bohThu: bohHrs.Thu,
        bohFri: bohHrs.Fri,
        bohSat: bohHrs.Sat,
        bohSun: bohHrs.Sun,
        fohMon: fohHrs.Mon,
        fohTue: fohHrs.Tue,
        fohWed: fohHrs.Wed,
        fohThu: fohHrs.Thu,
        fohFri: fohHrs.Fri,
        fohSat: fohHrs.Sat,
        fohSun: fohHrs.Sun,
        gm: newData.managers,
        storeId: oldData.storeId,
        shortName: newData.shortName,
        type: newData.type,
        status: newData.status,
        compStores: newData.compStores
      }
      console.log('storeObj', storeObj)

      // Update table
      const dataUpdate = [...data]
      dataUpdate[index] = storeObj
      console.log('setData - dataUpdate', dataUpdate)
      setData([...dataUpdate])
      setOpenEditStore(false)

      // Notify Admin that Store change was successful
      setAlertMessage({
        type: 'success',
        message: 'Store successfully updated'
      })
    } catch (err) {
      console.log('Error updating Store', err)
      setOpenEditStore(false)
      // Notify Admin that Store modification failed
      setAlertMessage({
        type: 'error',
        message: 'Store update failed'
      })
    }
  }

  async function removeStore(row){
    console.log('removeStore(store)...', row)

    const index = row.index
    const storeId = row.original.storeId
    const areaId = row.original.areaId
    const shortName = row.original.shortName

    try {
      let result = await API.graphql(
        {
          query: deleteStore,
          variables: {
            areaId: areaId,
            storeId: storeId
          }
        }
      )
      console.log('Successfully deleted Store from DB', result)

      // Remove Store from table
      const dataDelete = [...data]
      dataDelete.splice(index, 1)
      console.log('setData - dataDelete', dataDelete)
      setData([...dataDelete])

      // Notify Admin that Store deletion was successful
      setAlertMessage({
        type: 'success',
        message: `${shortName} successfully deleted`
      })
    } catch (err) {
      console.log('Error deleting Store', err)
      // Notify Admin that Store deletion failed
      setAlertMessage({
        type: 'error',
        message: 'Store deletion failed'
      })
    }
  }

  /************************************
   * ALERT Functions
   * **********************************/
  // Determine which alert to trigger
  useEffect(() => {
    switch(alertMessage.type){
      case 'error':
        errorAlert()
        break;
      case 'success':
        successAlert()
        break;
      default:
        // No alert triggered
    }
  }, [alertMessage.type])

  // Close the alert
  const hideAlert = () => {    
    setAlert(null)
    setAlertMessage('')
  }

  const successAlert = () => {
    console.log('successAlert...')
    setAlert(
      <MDAlert color='success' dismissible>
        { alertMessage.message }
      </MDAlert>
    )
    setTimeout(function() {hideAlert()}, 3000)
  }  

  const errorAlert = () => {
    console.log('errorAlert()')
    setAlert(
      <MDAlert color='error' dismissible>
        { alertMessage.message }
      </MDAlert>
    )
    setTimeout(function() {hideAlert()}, 3000)
  }
  
  return (
    <MDBox>
      { alert }
      { openAddStore &&
        <AddStoreDialog 
          open={ openAddStore }
          close={ () => setOpenAddStore(false)}
          submit={ addStore }
          areas={ props.areas }
          managers={ props.managers }
          openProgress={ openAddProgress }
        />
      }
      { openEditStore &&
        <EditStoreDialog 
          open={ openEditStore }
          rawData={ data }
          rowData={ rowToEdit }
          close={ () => setOpenEditStore(false) }
          submit={ modifyStore }
          areas={ props.areas }
          managers={ props.managers }
        />
      }
         
      <RDTable
        table={{
          columns: columns,
          rows: data,
        }}
        editable={{ // Temporarily block ability for viewers to add and edit entries
          onRowAdd: null,
          // onRowAdd: () => setOpenAddStore(true),
          // onRowAdd: newData => addStore(newData),
          onRowEdit: null,
          onRowDelete: null
        }}
        // editable={{
        //   onRowAdd: null,
        //   // onRowAdd: () => setOpenAddStore(true),
        //   // onRowAdd: newData => addStore(newData),
        //   onRowEdit: row => setRowToEdit(row),
        //   onRowDelete: row => removeStore(row)
        // }}
        tableType='Store'
        canSearch
        cellStyle={ StoreCellStyle }              
      />
    </MDBox>
  )
}

StoreTable.propTypes = {
  areas: PropTypes.array,
  managers: PropTypes.object,
  stores: PropTypes.array,
  value: PropTypes.array
}