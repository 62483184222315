import { API, graphqlOperation } from 'aws-amplify'
import { metricDataByClientDate } from 'graphql/queries'

function createDateFilter(startDate, endDate) {
  return startDate === endDate
    ? { eq: startDate }
    : { between: [startDate, endDate] }
}

function createMetricFilter(metricNames) {
  return {
    or: Object.values(metricNames).map(metricName => ({
      metricName: { eq: metricName }
    }))
  }
}

async function fetchMetricDataByClientDateAndName(
  clientId,
  startDate,
  endDate,
  metricNames,
  batchSize = 1000,
) {

  const variables = {
    clientId: clientId,
    date: createDateFilter(startDate, endDate),
    filter: createMetricFilter(metricNames),
    limit: batchSize
  }

  let allData = []
  let nextToken = null
  let batchCount = 0

  const metricTracker = new Map()
  Object.values(metricNames).forEach(metric => metricTracker.set(metric, 0))

  try {
    do {
      batchCount++
      // console.log(`Fetching batch ${batchCount}`)

      const response = await API.graphql(graphqlOperation(metricDataByClientDate, {
        ...variables,
        nextToken
      }))

      const { items, nextToken: newNextToken } = response.data.metricDataByClientDate

      items.forEach(item => {
        metricTracker.set(item.metricName, (metricTracker.get(item.metricName) || 0) + 1)
      })

      // Log batch details
      // const batchMetrics = Object.groupBy(items, item => item.metricName)

      // console.log('batchMetrics', batchMetrics)

      // console.log('Batch Analysis:', {
      //   batchNumber: batchCount,
      //   totalItems: items.length,
      //   metricsPresent: Object.keys(batchMetrics),
      //   itemsPerMetric: Object.fromEntries(
      //     Object.entries(batchMetrics).map(([metric, items]) => [metric, items.length])
      //   ),
      //   hasMoreData: !!newNextToken
      // })

      allData = allData.concat(items)
      nextToken = newNextToken
    } while (nextToken)
    
    // const finalMetricCounts = Object.groupBy(allData, item => item.metricName)
    // console.log('Final fetch summary:', {
    //   totalBatches:  batchCount,
    //   totalItems: allData.length,
    //   itemsPerMetric: Object.fromEntries(
    //     Object.entries(finalMetricCounts).map(([metric, items]) => [metric, items.length])
    //   ),
    //   dateRange: { startDate, endDate }
    // })

    return allData
  } catch (error) {
    console.error('Error in fetchMetricDatabyClientDateAndName', error)
    throw error
  }
}

export { fetchMetricDataByClientDateAndName }