// AWS Amplify components
import { API } from 'aws-amplify'

// GraphQL
import { dbGetItem } from 'graphql/queries'

export default async function getNetSalesData(startDate, endDate) {
  console.log('getNetSalesData')
  console.log('startDate', startDate)
  console.log('endDate', endDate)

  const dbVar = JSON.stringify({
    report: 'r0005_KPI',
    startDate: startDate,
    endDate: endDate,
    filter: ['netSales'],
    stores: []
  })

  try {
    const response = await API.graphql({
      query: dbGetItem,
      variables: {
        db: 'grm.reports',
        dbVariables: dbVar
      }
    })
    console.log('getNetSalesData response', response)
    const netSalesData = JSON.parse(JSON.parse(response.data.dbGetItem.statusItem))
    return netSalesData
  } catch (err) {
    console.log('Error fetching Net Sales data', err)
    throw err
  }
}