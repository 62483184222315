export default function R0003IncentiveCellStyle(row, cell){

  const rowType = row.original.rowType
  const column = cell.column.id
  const threshold = row.original.threshold
  const lowBound = 1 - threshold
  const highBound = 1 + threshold

  let cellColor = 'white'
  let fontSize = '.7rem'
  let fontStyle = 'normal'
  let fontWeight = 'normal'
  let leftBorder = ''
  let padding = 0
  let paddingTop = '1px'
  let paddingRight = 0
  let paddingBottom = '1px'
  let paddingLeft = 0
  let textAlign = 'center'
  let textColor = 'black'
  let topBorder = '1px solid #e0e0e0'
  let width = 75

  switch(rowType) {
    case 'scoreRow':
      fontWeight = 'bold'
      topBorder = '3px solid #000'
      switch(column){
        case 'nsActPct':
        case 'fActPct':
        case 'fSchedPct':
        case 'bActPct':
        case 'bSchedPct':
          if(cell.value === undefined || cell.value === null){
            cellColor = 'white'
          } else if(cell.value >= 10){
            cellColor = 'green'
            textColor = 'white'
          } else if(cell.value < 10){
            cellColor = 'red'
          }
          break;
        default:
          cellColor = 'white'
      }
      break;
    default: // weekRow
      switch(column){
        case 'nsActPct':
          if(cell.value === undefined || cell.value === null){
            cellColor = 'white'
          } else {
            // convert value to decimal
            const value = parseFloat(cell.value)/100
          
            if(value >= 1){
              cellColor = 'green'
              textColor = 'white'
              fontWeight = 'bold'
            } else {
              cellColor = 'red'
            }
          }
          break;
        case 'fActPct':
        case 'fSchedPct':
        case 'bActPct':
        case 'bSchedPct':
          if(cell.value === undefined || cell.value === null){
            cellColor = 'white'
          } else {
            // convert value to decimal
            const value = parseFloat(cell.value)/100
          
            if(value >= lowBound && value <= highBound){
              cellColor = 'green'
              textColor = 'white'
              fontWeight = 'bold'
            } else {
              cellColor = 'red'
            }
          }
          break;
        case 'fTheo':
        case 'bTheo':
          cellColor = 'white'
          leftBorder = '1px solid #000'
          break;
        default:
          cellColor = 'white'
      }      
  }

  

  const setting = {
    backgroundColor: cellColor,
    borderLeft: leftBorder,
    borderTop: topBorder,
    color: textColor,
    fontSize: fontSize,
    fontStyle: fontStyle,
    fontWeight: fontWeight,
    paddingTop: paddingTop,
    paddingRight: paddingRight,
    paddingBottom: paddingBottom,
    paddingLeft: paddingLeft,
    textAlign: textAlign,
    width: width
  }

  return setting
}

let cellColor = 'white'
  let fontSize = '.7rem'
  let fontStyle = 'normal'
  let fontWeight = 'normal'
  let padding = 0
  let paddingTop = '1px'
  let paddingRight = 0
  let paddingBottom = '1px'
  let paddingLeft = 0
  let textAlign = 'center'
  let textColor = 'black'
  let topBorder = '1px solid #e0e0e0'
  let width = 75