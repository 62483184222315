/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const dbGetItem = /* GraphQL */ `
  query DbGetItem($db: String!, $dbVariables: AWSJSON!) {
    dbGetItem(db: $db, dbVariables: $dbVariables) {
      statusState
      statusMessage
      statusItem
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($clientId: String!, $messageId: String!) {
    getMessage(clientId: $clientId, messageId: $messageId) {
      messageId
      note
      status
      position
      createdAt
      __typename
    }
  }
`;
export const getPriceIncrease = /* GraphQL */ `
  query GetPriceIncrease($client: String!, $years: [String!]) {
    getPriceIncrease(client: $client, years: $years)
  }
`;
export const getPriceIncreaseList = /* GraphQL */ `
  query GetPriceIncreaseList($client: String!) {
    getPriceIncreaseList(client: $client) {
      year
      amount
      __typename
    }
  }
`;
export const getR0001Reportv2 = /* GraphQL */ `
  query GetR0001Reportv2(
    $endDate: AWSDate!
    $startDate: AWSDate!
    $stores: [String]
  ) {
    getR0001Reportv2(
      endDate: $endDate
      startDate: $startDate
      stores: $stores
    ) {
      id
      startDate
      endDate
      shifts {
        shift
        store {
          name
          kpis {
            kpi
            low
            mid
            top
            actualPercent
            actualValue
            percentRank
            percentToMid
            percentToTop
            valueRank
            valueToMid
            valueToTop
            subRows {
              kpi
              low
              mid
              top
              actualPercent
              actualValue
              percentRank
              percentToMid
              percentToTop
              valueRank
              valueToMid
              valueToTop
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getR0003ProjectionData = /* GraphQL */ `
  query GetR0003ProjectionData($week: String!, $store: String!) {
    getR0003ProjectionData(week: $week, store: $store) {
      week
      store
      avgWage
      monProjSales
      monProjAvgCheck
      monSchedFOH
      monSchedBOH
      tueProjSales
      tueProjAvgCheck
      tueSchedFOH
      tueSchedBOH
      wedProjSales
      wedProjAvgCheck
      wedSchedFOH
      wedSchedBOH
      thuProjSales
      thuProjAvgCheck
      thuSchedFOH
      thuSchedBOH
      friProjSales
      friProjAvgCheck
      friSchedFOH
      friSchedBOH
      satProjSales
      satProjAvgCheck
      satSchedFOH
      satSchedBOH
      sunProjSales
      sunProjAvgCheck
      sunSchedFOH
      sunSchedBOH
      __typename
    }
  }
`;
export const getR0003Reportv2 = /* GraphQL */ `
  query GetR0003Reportv2($week: String!, $user: String!, $role: String!) {
    getR0003Reportv2(week: $week, user: $user, role: $role) {
      id
      data {
        area
        data {
          day
          projCheckCount
          projAvgCheck
          projSales
          theoFOH
          theoBOH
          projSPLH
          schedFOH
          schedBOH
          schedSPLH
          actCheckCount
          actAvgCheck
          actSales
          adjTheoFOH
          adjTheoBOH
          actFOH
          actBOH
          fohActVsTheo
          bohActVsTheo
          theoSPLH
          actSPLH
          trainingFOH
          trainingBOH
          notes
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport(
    $userName: String!
    $clientId: String!
    $reportId: String!
    $reportVariables: [ReportVariable]
  ) {
    getReport(
      userName: $userName
      clientId: $clientId
      reportId: $reportId
      reportVariables: $reportVariables
    ) {
      pageId
      data {
        rowNum
        rowType
        rowCells {
          colName
          value
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getReportv2 = /* GraphQL */ `
  query GetReportv2(
    $userName: String!
    $clientId: String!
    $reportId: String!
    $reportVariables: AWSJSON
  ) {
    getReportv2(
      userName: $userName
      clientId: $clientId
      reportId: $reportId
      reportVariables: $reportVariables
    ) {
      statusState
      statusMessage
      statusItem
      __typename
    }
  }
`;
export const listAreas2 = /* GraphQL */ `
  query ListAreas2($report: String!, $user: String, $role: String) {
    listAreas2(report: $report, user: $user, role: $role) {
      areaId
      areaName
      areaManager
      areaType
      stores {
        storeId
        shortName
        longName
        fohBaseTheoHrs {
          Mon
          Tue
          Wed
          Thu
          Fri
          Sat
          Sun
          __typename
        }
        bohBaseTheoHrs {
          Mon
          Tue
          Wed
          Thu
          Fri
          Sat
          Sun
          __typename
        }
        gm
        status
        type
        compStores {
          storeId
          shortName
          __typename
        }
        thresholds {
          avgCheck
          netSales
          splhTotal
          splhFOH
          splhBOH
          __typename
        }
        toastId
        __typename
      }
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages($clientId: String!, $status: String) {
    listMessages(clientId: $clientId, status: $status) {
      messageId
      note
      status
      position
      createdAt
      __typename
    }
  }
`;
export const listMetrics = /* GraphQL */ `
  query ListMetrics(
    $clientId: ID!
    $startDate: AWSDate!
    $endDate: AWSDate!
    $storeIds: [String!]
    $metricNames: [String!]
    $frequencies: [FrequencyType!]
  ) {
    listMetrics(
      clientId: $clientId
      startDate: $startDate
      endDate: $endDate
      storeIds: $storeIds
      metricNames: $metricNames
      frequencies: $frequencies
    ) {
      items {
        metricId
        clientId
        storeId
        date
        hour
        frequency
        metricName
        metricType
        numberValue
        stringValue
        booleanValue
        dayPart
        diningOption
        source
        timestamp
        unit
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles($clientId: String!) {
    listRoles(clientId: $clientId) {
      clientId
      role
      description
      __typename
    }
  }
`;
export const listUserPermissions = /* GraphQL */ `
  query ListUserPermissions($role: String!) {
    listUserPermissions(role: $role) {
      report
      viewPermissions
      updatePermissions
      __typename
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      clientId
      clientName
      contactName
      contactPhone
      contactEmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        clientName
        contactName
        contactPhone
        contactEmail
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getForecastedSales = /* GraphQL */ `
  query GetForecastedSales($id: ID!) {
    getForecastedSales(id: $id) {
      id
      storeName
      date
      forecastedSales
      storeId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listForecastedSales = /* GraphQL */ `
  query ListForecastedSales(
    $filter: ModelForecastedSalesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForecastedSales(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        storeName
        date
        forecastedSales
        storeId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStoreRecord = /* GraphQL */ `
  query GetStoreRecord($id: ID!) {
    getStoreRecord(id: $id) {
      id
      storeId
      storeName
      clientId
      toastId
      timeZone
      address {
        street_1
        street_2
        city
        state
        zipCode
        country
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStoreRecords = /* GraphQL */ `
  query ListStoreRecords(
    $filter: ModelStoreRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStoreRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        storeId
        storeName
        clientId
        toastId
        timeZone
        address {
          street_1
          street_2
          city
          state
          zipCode
          country
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const storeByStoreId = /* GraphQL */ `
  query StoreByStoreId(
    $storeId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelStoreRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    storeByStoreId(
      storeId: $storeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        storeId
        storeName
        clientId
        toastId
        timeZone
        address {
          street_1
          street_2
          city
          state
          zipCode
          country
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const storeByClientId = /* GraphQL */ `
  query StoreByClientId(
    $clientId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelStoreRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    storeByClientId(
      clientId: $clientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        storeId
        storeName
        clientId
        toastId
        timeZone
        address {
          street_1
          street_2
          city
          state
          zipCode
          country
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      client
      userName
      firstName
      lastName
      email
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        client
        userName
        firstName
        lastName
        email
        role
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMetricData = /* GraphQL */ `
  query GetMetricData($metricId: ID!) {
    getMetricData(metricId: $metricId) {
      metricId
      clientId
      storeId
      date
      hour
      frequency
      metricName
      metricType
      numberValue
      stringValue
      booleanValue
      dayPart
      diningOption
      source
      timestamp
      unit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMetricData = /* GraphQL */ `
  query ListMetricData(
    $metricId: ID
    $filter: ModelMetricDataFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMetricData(
      metricId: $metricId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        metricId
        clientId
        storeId
        date
        hour
        frequency
        metricName
        metricType
        numberValue
        stringValue
        booleanValue
        dayPart
        diningOption
        source
        timestamp
        unit
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const metricDataByClientDate = /* GraphQL */ `
  query MetricDataByClientDate(
    $clientId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMetricDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    metricDataByClientDate(
      clientId: $clientId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        metricId
        clientId
        storeId
        date
        hour
        frequency
        metricName
        metricType
        numberValue
        stringValue
        booleanValue
        dayPart
        diningOption
        source
        timestamp
        unit
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const metricDataByDate = /* GraphQL */ `
  query MetricDataByDate(
    $date: AWSDate!
    $clientIdStoreId: ModelMetricDataByDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMetricDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    metricDataByDate(
      date: $date
      clientIdStoreId: $clientIdStoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        metricId
        clientId
        storeId
        date
        hour
        frequency
        metricName
        metricType
        numberValue
        stringValue
        booleanValue
        dayPart
        diningOption
        source
        timestamp
        unit
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getThreshold = /* GraphQL */ `
  query GetThreshold($id: ID!) {
    getThreshold(id: $id) {
      id
      clientId
      name
      entityType
      entityId
      metric
      thresholdType
      thresholdBands {
        position
        operator
        numberValue
        textValue
        backgroundColor
        color
        fontWeight
        fontStyle
        borderColor
        borderWidth
        icon
        text
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listThresholds = /* GraphQL */ `
  query ListThresholds(
    $filter: ModelThresholdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThresholds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        name
        entityType
        entityId
        metric
        thresholdType
        thresholdBands {
          position
          operator
          numberValue
          textValue
          backgroundColor
          color
          fontWeight
          fontStyle
          borderColor
          borderWidth
          icon
          text
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getThresholdMapping = /* GraphQL */ `
  query GetThresholdMapping($id: ID!) {
    getThresholdMapping(id: $id) {
      id
      entityId
      extEntityId
      thresholdId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listThresholdMappings = /* GraphQL */ `
  query ListThresholdMappings(
    $filter: ModelThresholdMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThresholdMappings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityId
        extEntityId
        thresholdId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
