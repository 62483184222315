import { useState } from 'react'

// @mui components
import { Button, Paper, Stack, Typography } from '@mui/material'

import RuleDialog from './RuleDialog';
import RuleBoxRow from './RuleBoxRow';

export default function RulesBox({
  rules,
  setRules
}){
  console.log('RulesBox rules', rules)

  const [openRuleEditor, setOpenRuleEditor] = useState(false)
  const [ruleToEdit, setRuleToEdit] = useState(null)

  function handleRowChange(row){
    console.log('handleRowChange', row)
  }

  function handleAddRule(row){
    
    const newRule = {...row}
    // Set position to the last position in the rule list
    newRule.position = rules.length + 1
    const newRuleList = [...rules]
    newRuleList.push(newRule)
    
    setRules(newRuleList)
    setRuleToEdit(null)
    setOpenRuleEditor(false);
  }

  function handleEditRule(row){
    
    const updateRules = (rules, row) => {
      
      return rules.map(rule => {
        
        if(rule.position === row.position){
          return row
        } else {
          return rule
        }
      })
    }

    const updatedRules = updateRules(rules, row)

    setRuleToEdit(null)
    setRules(updatedRules)
    setOpenRuleEditor(false)
  }

  function editRule(selectedRule){
    
    setRuleToEdit(selectedRule)
    setOpenRuleEditor(true);
  }

  function handleDeleteRule(ruleToDelete){
    
    const filteredRules = (rules, ruleToDelete) => {
      return rules.filter(rule => {
        return rule.position !== ruleToDelete.position
      })
    }

    const updatedRules = filteredRules(rules, ruleToDelete)

    updatedRules.sort((a, b) => a.position - b.position)

    updatedRules.forEach((rule, index) => {
      rule.position = index + 1
    })

    setRules(updatedRules)
  }

  function handleRuleDialogClose(){
    setRuleToEdit(null)
    setOpenRuleEditor(false)
  }  

  return (
    <Paper elevation={2}>
      
      <RuleDialog 
        close={() => handleRuleDialogClose()}
        open={openRuleEditor}
        onAddRule={(row) => handleAddRule(row)}
        onEditRule={(row) => handleEditRule(row)}
        rule={ruleToEdit}
      />
      
      <RuleBoxHeader 
        editRules={() => setOpenRuleEditor(true)}
      />
      {
        rules.map(rule => (
          <RuleBoxRow 
            rule={rule} 
            editRule={(selectedRule) => editRule(selectedRule)}
            deleteRule={(selectedRule) => handleDeleteRule(selectedRule)}
            setPosition={handleRowChange}
          />
        ))
      }
    </Paper>
  )

}

function RuleBoxHeader({
  editRules,
}){
  return (
    <Stack
      direction='row' 
      alignItems='center'
      justifyContent='space-between'
      sx={{
        bgcolor: '#e0e0e0'
      }}
    >
      <Typography 
          variant='subtitle2'
          sx={{
            ml: 2,
            fontWeight: 'bold'
          }}
      >
        Rules 
      </Typography>
      <Button
        onClick={editRules}
      >
        Add Rule
      </Button>
    </Stack>
  )
}

