import React from 'react'

// @mui components
import { Tooltip } from '@mui/material'
import Icon from '@mui/material/Icon'

// Material Dashboard 2 Pro React components
import MDButton from 'components/MDButton'

export default function RDConfigButton({
  disabled,
  style,
  toggleConfigurator
}){
  
  return (
    <Tooltip title='Report Settings'>
      <MDButton
        variant='gradient'
        onClick={ toggleConfigurator }
        iconOnly
        sx={{
          backgroundColor: style.bgColor,
          color: style.textColor,
          '&:hover': {
            backgroundColor: style.hoverColor
          }
        }}
        disabled={ disabled }
      >
        <Icon 
          fontSize='large'
          color='inherit'
        >
          settings
        </Icon>
      </MDButton>
    </Tooltip>
  )
}