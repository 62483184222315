import React from 'react'
import PropTypes from "prop-types";
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { Formik, Form } from 'formik'
import Textfield from 'components/FormsUI/Textfield'
import Select from 'components/FormsUI/Select'

function EditUserForm(props) {

  console.log('Form props', props)
  
  async function handleSubmit(values) {
    props.submit(values)
  }

  function handleCancel() {
    props.cancel()
  }

  async function handleDelete() {
    props.delete()
  }

  return (
    <>
      <Formik
        initialValues={ props.userData }
        onSubmit={ (values) => {
          handleSubmit(values)
        }}
      >
        { props => (
          <Form>
            <GridContainer>
              <GridItem xs={12}>
                <Textfield
                  name='firstName'
                  label='First Name'
                />
              </GridItem>
              <GridItem xs={12}>
                <Textfield
                  name='lastName'
                  label='Last Name'
                />
              </GridItem>
              <GridItem xs={12}>
                <Textfield
                  name='userName'
                  label='User Name'
                />
              </GridItem>
              <GridItem xs={12}>
                <Textfield
                  name='email'
                  label='Email'
                />
              </GridItem>
              <GridItem xs={12}>
                <Select
                  name='role'
                  options={['Admin', 'AreaMgr', 'GenMgr']}
                  label='Role'
                />
              </GridItem>
              <GridItem xs={3}>
                <Button onClick={ handleDelete }>Delete User</Button>
              </GridItem>
              <GridItem xs={3}>
                <Button>Reset Password</Button>
              </GridItem>
              <GridItem xs={3}>
                <Button onClick={ handleCancel }>Cancel</Button>
              </GridItem>
              <GridItem xs={3}>
                <Button type='submit'>Update</Button>
              </GridItem>
              
            </GridContainer>
          </Form>
        )}        
      </Formik>
    </>
  )

}

export default EditUserForm

EditUserForm.propTypes = {
  userData: PropTypes.object,
  submit: PropTypes.func,
  cancel: PropTypes.func,
  delete: PropTypes.func,
}