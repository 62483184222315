import { API } from 'aws-amplify'
import { createThreshold } from 'graphql/mutations'

export default async function createProfile(profileData){
  console.log('createProfile', profileData)

  const thresholdBands = profileData.rules.map(band => {
    return {
      position: band.position,
      operator: band.operator,
      numberValue: band.numberValue,
      backgroundColor: band.backgroundColor,
      color: band.color,
      fontWeight: band.fontWeight
    }
  })

  const createThresholdInput = {
    clientId: 'grm',
    name: profileData.name,
    entityId: 'r0005',
    entityType: 'dummy',
    metric: 'forecastedRevenue',
    thresholdType: 'PERCENTAGE',
    thresholdBands: thresholdBands
  }

  try {
    const response = await API.graphql({
      query: createThreshold,
      variables: {
        input: createThresholdInput
      }
    })
    console.log('response', response)
  } catch (err) {
    console.log('Error creating profile', err)
  }
    
}