import { Grid, Icon, Stack, TextField, Tooltip } from '@mui/material';
import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography';
import Autocomplete_GrmHead from 'components/RDAutocomplete/Autocomplete_GrmHead';
import Button_GrmHead from 'components/RDButton/Button_GrmHead';

export const ReportHeader = ({ 
  title,
  area,
  areas,
  week,
  weeks,
  dataReady,
  onAreaChange,
  onWeekChange,
  hasUpdatePermission,
  onMenuClick
}) => (
  <MDBox px={3} pt={1} sx={{ backgroundColor: '#29261f'}}>
    <Grid container pt={2}>
      <Grid item xs={7} sx={{ display: 'flex', justifyContent: 'flex-start'}}>
        <MDTypography variant='h6' fontWeight='medium' style={{ color: '#cf9e0b'}}>
          {title}
        </MDTypography>
      </Grid>
      <Grid item xs={5}>
        <Stack direction='row' justifyContent='end' spacing={3}>
          {dataReady && (
            <Autocomplete_GrmHead 
              id='r0003AreaSelector'
              options={areas}
              value={area}
              onChange={onAreaChange}
              disableClearable={true}
              style={{ width: 120 }}
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  label='Area' 
                  variant='outlined' 
                  size='small'
                />
              )}
            />
          )}
          
          <Autocomplete_GrmHead 
            id='r0003WeekSelector'
            options={weeks}
            value={week}
            onChange={onWeekChange}
            disableClearable={true}
            style={{ width: 120 }}
            renderInput={(params) => (
              <TextField 
                {...params}
                label='Week'
                variant='outlined'
                size='small'
              />
            )}
          />
      
          {hasUpdatePermission && (
            <Tooltip title='Menu'>
              <Button_GrmHead
                variant='gradient'
                iconOnly
                onClick={onMenuClick}
              >
                <Icon fontSize='large'>menu</Icon>
              </Button_GrmHead>
            </Tooltip>
          )}
        </Stack>
      </Grid>
    </Grid>
  </MDBox>
);