// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// import Dashboard from "views/Dashboard/Dashboard.js";

// Admin Pages
import UserAdmin from 'clients/admin/UserAdmin/UserAdmin'
import RolesAdmin from 'clients/admin/RolesAdmin/RolesAdmin'
import StoreAdmin from 'clients/admin/StoreAdmin/StoreAdmin'
import ReportAdmin from 'clients/admin/ReportAdmin/ReportAdmin'
import DashboardAdmin from 'clients/admin/DashboardAdmin/DashboardAdmin'

// Report Pages
import R0001Main from 'clients/grm/views/R0001/R0001Main'
import R0002Main from 'clients/grm/views/R0002/R0002Main'
import R0003Main from 'clients/grm/views/R0003/R0003Main'
import R0004Main from 'clients/grm/views/R0004/R0004Main'
import R0005Main from 'containers/clients/grm/R0005/R0005Main'

// @material-ui/icons
// import Build from "@material-ui/icons/Build";
// import DashboardIcon from "@material-ui/icons/Dashboard";
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import { vi } from "date-fns/locale";
import grmIcon from 'assets/images/grimaldis-icon.png'

const reportViews = {
  r0001: {
    name: 'Metrics Report',
    key: 'r0001',
    route: '/planning-tools/r0001',
    component: <R0001Main />
  },
  r0002: {
    name: 'Summary Report',
    key: 'r0002',
    route: "/planning-tools/r0002",
    // component: <R0002Report />
    component: <R0002Main />
  },
  r0003: {
    name: 'SPLH Report',
    key: 'r0003',
    route: '/planning-tools/r0003',
    component: <R0003Main />
  },
  r0004: {
    name: 'Product Mix Report',
    key: 'r0004',
    route: '/planning-tools/r0004',
    component: <R0004Main />
  },
  r0005: {
    name: 'Revenue Projections',
    key: 'r0005',
    route: '/planning-tools/r0005',
    component: <R0005Main />
  }
}

const adminViews = {
  rolesAdmin: {
    name: "Roles Admin",
    key: 'roles-admin',
    route: "/rolesAdmin",
    component: <RolesAdmin />,
  },
  userAdmin: {
    name: "User Admin",
    key: 'user-admin',
    route: "/userAdmin",
    component: <UserAdmin />,
  },
  storeAdmin: {
    name: "Store Admin",
    key: 'store-admin',
    route: "/storeAdmin",
    component: <StoreAdmin />,
  },
  reportAdmin: {
    name: "Report Admin",
    key: 'report-admin',
    route: "/reportAdmin",
    component: <ReportAdmin />,
  },
  dashboardAdmin: {
    name: "Dashboard Admin",
    key: 'dashboard-admin',
    route: '/dashboardAdmin',
    component: <DashboardAdmin />
  },
}

export default function grmRoutes(name, role, permissions) {
  // console.log("grmRoutes", name, role, permissions)
  
  let reportViewArr = []
  let adminViewArr = []

  // Setup Admin Views
  switch(role) {
    case 'Admin':
      adminViewArr[0] = adminViews.rolesAdmin
      adminViewArr[1] = adminViews.userAdmin
      // adminViewArr[2] = adminViews.storeAdmin
      adminViewArr[2] = adminViews.reportAdmin
      adminViewArr[3] = adminViews.dashboardAdmin
      adminViewArr[4] = adminViews.storeAdmin
      break;
    default:
      // No action, leave as empty array
  }
  // console.log('permissions', permissions)
  // Setup Report Views
  let arrIdx = 0
  for(let i = 0; i < permissions.length; i++){
    let report = permissions[i].report
    report = report.toLowerCase()
    if(report in reportViews){
      let viewPermissions = permissions[i].viewPermissions
      if(viewPermissions !== 'no-permission'){
        reportViewArr[arrIdx] = reportViews[report]
        arrIdx += 1
      }
    }
  }
  // console.log('reportViewArr', reportViewArr)

  
  // // Main Dashboard View
  // const dashboard = {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: DashboardIcon,
  //   component: Dashboard,
  //   layout: "/admin",
  // }

  // Create Routes
  let routes = []

  if(reportViewArr.length > 0){
    let reportRoutes = {
      type: 'collapse',
      name: 'Planning Tools',
      key: 'planning-tools',
      icon: <Icon fontSize='medium'>format_list_numbered_icon</Icon>,
      collapse: reportViewArr,  
    }
    routes.push(reportRoutes)
  }

  if(adminViewArr.length > 0) {
    let adminRoutes = {
      type: 'collapse',
      name: "Admin Tools",
      key: 'admin-tools',
      icon: <Icon fontSize='medium'>content_paste</Icon>,
      collapse: adminViewArr,
    }
    routes.push(adminRoutes)
  }

  let finalDivider = {
    type: 'divider', key: 'final-divider'
  }

  routes.push(finalDivider)
  
  return routes
}