import { Card, CardContent, Divider } from '@mui/material'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

function R0001Message(){

  return (
    <MDBox
      sx={{
        display: 'flex',
        alignContent: 'center',
        height: '75vh'
      }}
    >
      <Card 
        sx={{
          maxWidth: 343,
          margin: 'auto',
          padding: '12px',
          backgroundColor: '#1c4e80',
        }}
      >
        <CardContent>
          <MDTypography 
            variant='h3'
            sx={{
              textAlign: 'center',
              color: '#f1f1f1'
            }}
          >Attention</MDTypography>
          <Divider 
            sx={{
              background: '#f1f1f1',
              margin: '8px',
              marginBottom: '24px'
            }}
          />
          <MDTypography 
            variant='body2'
            sx={{
              color: '#f1f1f1'
            }}
          >
            You can include a report-specific message.
          </MDTypography>
          <MDTypography 
            variant='body2'
            sx={{
              color: '#ea6a47',
              margin: '16px'
            }}
          >
            <strong>Highlight important text...</strong>
          </MDTypography>

        </CardContent>
      </Card>
    </MDBox>
  )
}

export default R0001Message