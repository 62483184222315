import React, { useEffect, useState } from 'react'

// AWS Amplify components
import { API } from 'aws-amplify'
import { listMessages } from 'graphql/queries'

// Material Dashboard 2 Pro React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// RadixDash components
import RDDashboard from 'components/RDDashboard'
import RDMessageBox from 'components/RDMessageBox'
import { useUser } from 'components/RDAuthContext'

// Images
import backgroundImage from "clients/rdx/assets/images/dashboard-bg-1.jpg";
import logoImage from 'assets/images/dem/demAdminLogo.png'

export default function Home(){
  const { user } = useUser()
  const clientId = user.client

  const [activeMessages, setActiveMessages] = useState([])
  const [messagesToDisplay, setMessagesToDisplay] = useState(false)

  useEffect(() => {
    if(activeMessages.length > 0){
      setMessagesToDisplay(true)
    } else {
      setMessagesToDisplay(false)
    }
  }, [activeMessages])

  useEffect(() => {
    fetchMessages()
  }, [])

  async function fetchMessages() {
    try {
      const queryResponse = await API.graphql({
        query: listMessages,
        variables: {
          clientId: clientId,
          status: 'active'
        }
      })
      console.log('messages fetched successfully', queryResponse)
      setActiveMessages(queryResponse.data.listMessages)

    } catch (err) {
      console.log('Error fetching messages', err)
    }
  }

  return(
    <RDDashboard 
      headerBg={backgroundImage}
      logo={logoImage}
    >
      {
        messagesToDisplay &&
        <RDMessageBox 
          messages={ activeMessages }
          noteColor='#f9e9d3'
          textColor='#000'
        />
      }
    </RDDashboard>
  )
}