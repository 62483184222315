import React from 'react'
import PropTypes from 'prop-types'

// MD2 components
import MDBox from 'components/MDBox'

export default function RDTabPanel(props){
  const {
    children,
    value,
    index,
    ...other
  } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <MDBox sx={{ p: 3}}>
          {children}
        </MDBox>
      )}
    </div>
  )
}

RDTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number
}