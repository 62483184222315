import { addDays, differenceInCalendarDays, startOfWeek, subDays } from 'date-fns';

/****************************************
 * R0002 CONSTANTS
 ****************************************/
/**
 * r0002Columns:  An array of the R0002 columns with each columns label and key.  
 * The array lists each column in order from left to right in the report.
 */
const r0002Columns = [
  {label: 'Store', key: 'store'},
  {label: 'PY Net', key: 'pNSTotal'},
  {label: 'CY Net', key: 'cNSTotal'},
  {label: '%', key: 'pct'},
  {label: 'Dine In', key: 'cNSDineIn'},
  {label: 'To Go', key: 'cNSToGo'},
  {label: 'Net Del', key: 'cNSDelivery'},
  {label: 'Grs Del', key: 'cGSDelivery'},
  {label: 'CC Total', key: 'cCCTotal'},
  {label: 'CC Dine In', key: 'cCCDineIn'},
  {label: 'CC To Go', key: 'cCCToGo'},
  {label: 'CC Del', key: 'cCCDelivery'},
  {label: 'AC Total', key: 'cACTotal'},
  {label: 'AC Dine In', key: 'cACDineIn'},
  {label: 'AC To Go', key: 'cACToGo'},
  {label: 'AC Del', key: 'cACDelivery'},
  {label: 'SPLH Total', key: 'cSPLHTotal'},
  {label: 'SPLH FOH', key: 'cFOH'},
  {label: 'SPLH BOH', key: 'cBOH'},
  {label: 'OT Total', key: 'cOTTotal'},
  {label: 'OT FOH', key: 'cOTFOH'},
  {label: 'OT BOH', key: 'cOTBOH'},
  {label: 'Labor %', key: 'cLaborPct'},
]

const maxDate = getMaxDate()
const minDate = new Date(2019, 0, 1)
const initialStartDate = startOfWeek(maxDate, {weekStartsOn: 1})
const compStartDate = subDays(initialStartDate, 364)
const daysInDateRange = differenceInCalendarDays(maxDate, initialStartDate)
const compEndDate = addDays(compStartDate, daysInDateRange)

/****************************************
 * R0002 FUNCTIONS
 ****************************************/

function getMaxDate(){
  const maxDate = new Date()
  maxDate.setHours(0, 0, 0, 0)
  maxDate.setDate(maxDate.getDate() - 1)
  return maxDate
}

/**
 * Generate an array of year objects from 2019 up to the year before the specified currentYear.
 * The array is sorted in descending order based on the year property of each object.
 *
 * @param {number} currentYear - The current year to generate the years until.
 * @returns {Array} - An array of year objects in descending order.
 */
function getYears(currentYear){
  let startYear = 2019
  const years = []
  while(startYear <= currentYear - 1){
    years.push( {year: startYear.toString() })
    startYear++
  }
  years.sort((a,b) => (a.year > b.year) ? -1 : ((b.year > a.year) ? 1 : 0))
  return years
}

function getShiftString(dayParts){
  let shiftString = ''
  if(dayParts.lunch){
    shiftString += 'L'
  }
  if(dayParts.social){
    shiftString += 'S'
  }
  if(dayParts.dinner){
    shiftString += 'D'
  }
  return shiftString
}

/**
 * 
 * @param {*} rowData 
 * @param {*} thresholdData 
 * @param {*} storeType 
 * @returns 
 */
function buildR0002Pages(rowData, thresholdData, storeType){
  console.log('rowData', rowData)
  let pageArr = []

  rowData.forEach(row => {
    let newRow = {...row.rowData}
    newRow.rowType = row.rowType
    switch(row.rowType){
      case 'storeRow':
        newRow.thresholds = {...row.thresholds}
        break;
      case 'managerRow':
        newRow.thresholds = {...row.threshold}
        break;
      case 'totalRow':
        if(row.rowData.store !== 'Total' && row.rowData.store !== 'Overall Total'){
          const isMarketTotalRow = /\sTotal$/.test(row.rowData.store)

          if(isMarketTotalRow){
            const marketThresholds = setTotalRowThresholds(row.rowData, thresholdData, storeType)
            newRow.thresholds = {...marketThresholds}
          } else {
            newRow.thresholds = {...row.thresholds}
          }
        }
        break;
      default:
        // No Action
    }
    const cellStyles = getCellStyles(newRow, thresholdData, storeType)
    newRow.styles = cellStyles
    
    pageArr.push(newRow)
  })
  return pageArr
}

function getCellStyles(row, thresholds, storeType){
  
  const { rowType } = row
  
  let styles = {}

  r0002Columns.forEach(column => {
    styles[column.key] = {
      bgColorPrevious: '#fff',
      bgColorCompany: '#fff',
      borderBottom: null,
      borderLeft: null,
      borderTop: null,
      fontWeight: null,
      paddingLeft: 0,
      textAlign: 'center',
      textColorPrevious: '#000',
      textColorCompany: '#000'
    }
  })

  // Non-Conditional Formatting
  switch(rowType){
    case 'bottomBorderRow':
      for(const key in styles){
        if(styles.hasOwnProperty(key)){
          styles[key].borderBottom = '3px solid #000'
          styles[key].fontWeight = 'bold'
        }
      }
      break;
    case 'managerRow':
      for(const key in styles){
        if(styles.hasOwnProperty(key)){
          styles[key].bgColorPrevious = '#a44c0e'
          styles[key].bgColorCompany = '#a44c0e'
          styles[key].textColorCompany = '#fff'
          styles[key].textColorPrevious = '#fff'
          styles[key].fontWeight = 'bold'
          styles[key].textAlign = 'center'
        }
      }
      break;
    case 'storeRow':
    case 'otherStoreRow':
      for(const key in styles){
        if(styles.hasOwnProperty(key)){
          styles[key].paddingLeft = key === 'store' ? '10px' : null
          styles[key].textAlign = key === 'store' ? 'left' : 'center'
        }
      }
      break;
    case 'summaryRow':
      for(const key in styles){
        if(styles.hasOwnProperty(key)){
          styles[key].fontWeight = 'bold'
        }
      }
      break;
    case 'totalRow':
      for(const key in styles){
        if(styles.hasOwnProperty(key)){
          styles[key].borderTop = '3px solid #000'
          styles[key].fontWeight = 'bold'
        }
      }
      break;
    default:
  }

  for(const key in styles){
    if(styles.hasOwnProperty(key)){
      switch(key){
        case 'pNSTotal':
        case 'cNSDineIn':
        case 'cCCTotal':
        case 'cACTotal':
        case 'cSPLHTotal':
        case 'cOTTotal':
        case 'cLaborPct':
          styles[key].borderLeft = '3px solid #000'
          break;
        default:
          // No Action
      }
    }
  }

  // Conditional Formatting
  let conditionalStyles = {}
  switch(rowType){
    case 'storeRow':
    case 'totalRow':
      for(const key in styles){
        const curName = key === 'pct' ? 'cNSTotal' : key
        switch(key){
          case 'cNSTotal':
          case 'pct':
          case 'cNSDineIn':
          case 'cNSToGo':
          case 'cNSDelivery':
          case 'cGSDelivery':            
            conditionalStyles = getConditionalStyles(storeType, 'netSales', curName, row, thresholds)            
            break;
          case 'cCCTotal':
          case 'cCCDineIn':
          case 'cCCToGo':
          case 'cCCDelivery':
            conditionalStyles = getConditionalStyles(storeType, 'checkCounts', curName, row, thresholds)
            break;
          case 'cACTotal':
          case 'cACDineIn':
          case 'cACToGo':
          case 'cACDelivery':            
            conditionalStyles = getConditionalStyles(storeType, 'avgCheck', curName, row, thresholds)
            break;
          case 'cSPLHTotal':
            conditionalStyles = getConditionalStyles(storeType, 'splhTotal', curName, row, thresholds)
            break;
          case 'cFOH':
            conditionalStyles = getConditionalStyles(storeType, 'splhFOH', curName, row, thresholds)
            break;
          case 'cBOH':
            conditionalStyles = getConditionalStyles(storeType, 'splhBOH', curName, row, thresholds)
            break;
          case 'cOTTotal':
          case 'cOTFOH':
          case 'cOTBOH':
            conditionalStyles = getConditionalStyles(storeType, 'overtime', curName, row, thresholds)
            break;
          case 'cLaborPct':
            conditionalStyles = getConditionalStyles(storeType, 'labor', curName, row, thresholds)
            break;
          default:
            // No Action
        }
        styles[key].bgColorPrevious = conditionalStyles.bgColorPrevious
        styles[key].bgColorCompany = conditionalStyles.bgColorCompany
        styles[key].textColorPrevious = conditionalStyles.textColorPrevious
        styles[key].textColorCompany = conditionalStyles.textColorCompany
      }
      break;
    default:
      // No Action
  }  
  return styles
}

function getConditionalStyles(
  storeType,
  thresholdType,
  curName,
  rowData,
  thresholdData
){
  let bgColorPrevious = '#fff'
  let bgColorCompany = '#fff'
  let textColorPrevious = '#000'
  let textColorCompany = '#000'
  if(rowData.store !== 'Total' && rowData.store !== 'Overall Total'){

    const compNames = getCompNames(curName)
    const currentValue = parseInt(toNum(rowData[curName]))
    const previousValue = parseInt(toNum(rowData[compNames.previousCompName]))
    const companyValue = storeType === 'franchiseStore' ? parseInt(toNum(rowData[compNames.companyCompName])) : null
    const thresholdId = rowData.thresholds[thresholdType]

    const { lowTH, highTH } = getThresholds(thresholdType, thresholdId, thresholdData )

    switch(thresholdType){
      case 'avgCheck': 
        bgColorPrevious = calculateBackgroundColor(
          currentValue,
          previousValue,
          highTH,
          lowTH
        )

        bgColorCompany = storeType === 'franchiseStore' ? 
          calculateBackgroundColor(
            currentValue,
            companyValue,
            highTH,
            lowTH
          ) : bgColorPrevious
        break;
      case 'splhTotal':
      case 'splhFOH':
      case 'splhBOH':
        bgColorPrevious = calculateBackgroundColor(
          currentValue,
          previousValue,
          highTH/100,
          lowTH/100
        )

        bgColorCompany = storeType === 'franchiseStore' ?
          calculateBackgroundColor(
            currentValue,
            companyValue,
            highTH/100,
            lowTH/100
          ) : bgColorPrevious
        break;
      case 'netSales':
        // Percent Thresholds
        const pctPrev = (previousValue !== 0) ? (currentValue - previousValue)/previousValue * 100 : 0
        if((pctPrev >= highTH) || ((pctPrev === 0) && (currentValue > 0))){
          bgColorPrevious = 'green'
        } else if(pctPrev > lowTH && pctPrev < highTH){
          bgColorPrevious = 'yellow'
        } else {
          bgColorPrevious = 'red'
        }
        if(storeType === 'franchiseStore'){
          bgColorCompany = currentValue >= companyValue ? 'green' : 'red'
        } else {
          bgColorCompany = bgColorPrevious
        }
        break;
      case 'overtime':
      case 'labor':
        // Background reverse
        bgColorPrevious = currentValue <= previousValue ? 'green' : 'red'
        if(storeType === 'franchiseStore'){
          bgColorCompany = currentValue <= companyValue ? 'green' : 'red'
        } else {
          bgColorCompany = bgColorPrevious
        }        
        break;
      default:
        // No thresholds
        bgColorPrevious = currentValue === 0 ? 'grey' : currentValue >= previousValue ? 'green' : 'red'
        if(storeType === 'franchiseStore'){
          bgColorCompany = currentValue >= companyValue ? 'green' : 'red'
        } else {
          bgColorCompany = bgColorPrevious
        }        
    }    
  }

  textColorPrevious = getTextColor(bgColorPrevious)
  textColorCompany = getTextColor(bgColorCompany)

  return {
    bgColorPrevious: bgColorPrevious,
    bgColorCompany: bgColorCompany,
    textColorPrevious: textColorPrevious,
    textColorCompany: textColorCompany
  }
}

function getTextColor(bgColor){
  return bgColor === 'green' || bgColor === 'red' ? '#fff' : '#000'
}

function getCompNames(curName){
  if(curName.length === 0){
    return {
      previousCompName: curName,
      companyCompName: curName
    }
  } else {
    return {
      previousCompName: 'p' + curName.slice(1),
      companyCompName: 'comp' + curName.slice(1)
    }
  }
  
}

function getThresholds(type, assigned, options){
  
  const cIdx = options.findIndex(x => x.type === type)
  if(cIdx === -1){
    return { lowTH: null, highTH: null }
  }

  const tIdx = options[cIdx].thresholds.findIndex(x => x._id === assigned)
  if(tIdx === -1){
    return {lowTH: null, highTH: null }
  }
  
  const { lowValue, highValue } = options[cIdx].thresholds[tIdx]  
  
  return {
    lowTH: lowValue ?? null,
    highTH: highValue ?? null
  }
}

function calculateBackgroundColor(cur, comp, highTH, lowTH) {
  return cur === 0 ? 'grey' : cur >= comp + highTH ? 'green' : cur > comp + lowTH && cur < comp + highTH ? 'yellow' : 'red'
}


function setTotalRowThresholds(row, thresholds){
  
  let marketThresholds = {
    avgCheck: '',
    netSales: '',
    splhBOH: '',
    splhFOH: '',
    splhTotal: '',
  }  
  
  const netSalesTH = getThreshold(thresholds, 'netSales', row.store)
  marketThresholds.netSales = netSalesTH._id

  const avgCheckTH = getThreshold(thresholds, 'avgCheck', row.store)
  marketThresholds.avgCheck = avgCheckTH._id

  const splhTotalTH = getThreshold(thresholds, 'splhTotal', row.store)
  marketThresholds.splhTotal = splhTotalTH._id

  const splhFOHTH = getThreshold(thresholds, 'splhFOH', row.store)
  marketThresholds.splhFOH = splhFOHTH._id

  const splhBOHTH = getThreshold(thresholds, 'splhBOH', row.store)
  marketThresholds.splhBOH = splhBOHTH._id
      
  return marketThresholds
}

function getThreshold(thresholds, thresholdType, store) {

  return {}

  const thresholdMapping = {
    netSales: {
      // 'AZ Market Total': '2024 AZ Stores',
      'Arizona - Dave Total': '2024 AZ Stores',
      'Arizona - Mike Total': '2024 AZ Stores',
      'Athena Total': '2024 Non-AZ Stores',
      'Brad Total': '2024 Non-AZ Stores',
      'James Total': '2024 Non-AZ Stores',
      'Jessica Total': '2024 Non-AZ Stores',
      'John Total': '2024 Non-AZ Stores',
      'Kevin Total': '2024 Non-AZ Stores',
      'Franchise 01 Total': '2024 Non-AZ Stores',
      'Howard Total': '2024 Non-AZ Stores',
      'Franchise - Iowa Total': '2024 Non-AZ Stores',
    },
    avgCheck: {
      // 'AZ Market Total': '2024 AZ Stores',
      'Arizona - Dave Total': '2024 AZ Stores',
      'Arizona - Mike Total': '2024 AZ Stores',
      'Athena Total': '2024 Non-AZ Stores',
      'Brad Total': '2024 Non-AZ Stores',
      'James Total': '2024 Non-AZ Stores',
      'Jessica Total': '2024 Non-AZ Stores',
      'John Total': '2024 Non-AZ Stores',
      'Kevin Total': '2024 Non-AZ Stores',
      'Franchise 01 Total': '2024 Non-AZ Stores',
      'Howard Total': '2024 Non-AZ Stores',
      'Franchise - Iowa Total': '2024 Non-AZ Stores',
    },
    splhTotal: {
      // 'AZ Market Total': '2024 Default',
      'Arizona - Dave Total': '2024 Default',
      'Arizona - Mike Total': '2024 Default',
      'Athena Total': '2024 Default',
      'Brad Total': '2024 Default',
      'James Total': '2024 Default',
      'Jessica Total': '2024 Default',
      'John Total': '2024 Default',
      'Kevin Total': '2024 Default',
      'Franchise 01 Total': '2024 Default',
      'Howard Total': '2024 Default',
      'Franchise - Iowa Total': '2024 Default',
    },
    splhBOH: {
      // 'AZ Market Total': '2024 Default',
      'Arizona - Dave Total': '2024 Default',
      'Arizona - Mike Total': '2024 Default',
      'Athena Total': '2024 Default',
      'Brad Total': '2024 Default',
      'James Total': '2024 Default',
      'Jessica Total': '2024 Default',
      'John Total': '2024 Default',
      'Kevin Total': '2024 Default',
      'Franchise 01 Total': '2024 Default',
      'Howard Total': '2024 Default',
      'Franchise - Iowa Total': '2024 Default',
    },
    splhFOH: {
      // 'AZ Market Total': '2024 Default',
      'Arizona - Dave Total': '2024 Default',
      'Arizona - Mike Total': '2024 Default',
      'Athena Total': '2024 Default',
      'Brad Total': '2024 Default',
      'James Total': '2024 Default',
      'Jessica Total': '2024 Default',
      'John Total': '2024 Default',
      'Kevin Total': '2024 Default',
      'Franchise 01 Total': '2024 Default',
      'Howard Total': '2024 Default',
      'Franchise - Iowa Total': '2024 Default',
    },
  }

  const typeMapping = thresholdMapping[thresholdType]

  const name = typeMapping[store]

  return thresholds.find(t => {
    return t.type === thresholdType
  }).thresholds.find(th => {
    return th.name === name
  })
}

function toNum(str){
  if(str == null || typeof str === 'number'){
    return str
  } else {
    let newStr = str.replace(/\D/g,'')
    return newStr
  }
}

export function usdStringToNum(dollars){
  const number = parseFloat(dollars.replace(/[\$,]/g, ''))
  return number
}

// Helper function to convert data to number
export function convertCurrencyStringToNumber(currencyString) {
  // Check if the input is a string
  if (typeof currencyString === 'string') {
    // Remove currency symbol and commas, then convert to a float
    return parseFloat(currencyString.replace('$', '').replace(/,/g, ''));
  } else if (typeof currencyString === 'number') {
    // If it's already a number, just return it
    return currencyString;
  } else {
    // If it's neither a string nor a number (e.g., null or undefined), return 0
    return 0;
  }
}

function formatNumberWithCommas(number){
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function calculatePercentage(numerator, denominator, decimalDigits = 2) {
  if (!denominator || denominator === 0) {
    return 0
  }
  const percentage = (numerator / denominator) * 100
  // console.log('percentage', percentage)
  // console.log('percentage type', typeof(percentage))
  return Number(percentage.toFixed(decimalDigits))
}

export function formatMetricValue(metricName, value) {
  const format = getMetricFormat(metricName);
  switch (format.type) {
      case 'currency':
          return formatCurrency(value, format.decimals);
      case 'count':
          return formatCount(value);
      case 'percentage':
          return formatPercentage(value);
      default:
          return formatCount(value);
  }
}

function getMetricFormat(metricName) {
  if (metricName.match(/LaborPct|pct/)) {
      return { type: 'percentage' };
  } else if (metricName.match(/NS/)) {
      return { type: 'currency', decimals: 0 };
  } else if (metricName.match(/AC|OT/)) {
      return { type: 'currency', decimals: 2 };
  } else if (metricName.match(/TotalLaborHoursSPLH|FOHLaborHours|BOHLaborHours|Labor|GrossSales/)) {
      return { type: 'currency', decimals: 0 };
  } else if (metricName.match(/CC|SPLH|FOH|BOH/)) {
      return { type: 'count' };
  }
  return { type: 'count' }; // Default format
}

export function formatCurrency(value, decimalDigits = 2){
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimalDigits,
    maximumFractionDigits: decimalDigits
  }).format(value)
}

export function formatCount(value){
  return new Intl.NumberFormat('en-US').format(value)
}

export function formatPercentage(value, decimalDigits = 2){
  return `${value.toFixed(decimalDigits)}%`
}

export function calculateAverageCheck (netSales, checkCount) {
  if (!checkCount || checkCount === '0') return '$0.00'
  const avgCheck = parseFloat(netSales.replace(/[$,]/g, '')) / parseInt(checkCount)
  return `$${avgCheck.toFixed(2)}`
}

export const aggregateRowData = (stores) => {
  if (!stores || stores.length === 0) return {};

  // Get structure from first store (including previous year values)
  const result = { ...stores[0].rowData };
  
  // Initialize aggregation objects for current year calculations
  let currentTotals = {
    netSales: {
      total: 0,
      dineIn: 0,
      toGo: 0,
      delivery: 0
    },
    checkCounts: {
      total: 0,
      dineIn: 0,
      toGo: 0,
      delivery: 0
    },
    laborHours: {
      total: 0,
      boh: 0,
      foh: 0
    }
  };

  // Aggregate current year values only
  stores.forEach(store => {
    // Net Sales
    currentTotals.netSales.total += convertCurrencyStringToNumber(store.rowData.cNSTotal);
    currentTotals.netSales.dineIn += convertCurrencyStringToNumber(store.rowData.cNSDineIn);
    currentTotals.netSales.toGo += convertCurrencyStringToNumber(store.rowData.cNSToGo);
    currentTotals.netSales.delivery += convertCurrencyStringToNumber(store.rowData.cNSDelivery);

    // Check Counts
    currentTotals.checkCounts.total += parseNumberString(store.rowData.cCCTotal);
    currentTotals.checkCounts.dineIn += parseNumberString(store.rowData.cCCDineIn);
    currentTotals.checkCounts.toGo += parseNumberString(store.rowData.cCCToGo);
    currentTotals.checkCounts.delivery += parseNumberString(store.rowData.cCCDelivery);

    // Calculate hours for SPLH
    const storeNetSales = convertCurrencyStringToNumber(store.rowData.cNSTotal);
    const storeSPLHTotal = convertCurrencyStringToNumber(store.rowData.cSPLHTotal);
    const storeBOH = convertCurrencyStringToNumber(store.rowData.cBOH);
    const storeFOH = convertCurrencyStringToNumber(store.rowData.cFOH);

    if (storeSPLHTotal > 0) {
      currentTotals.laborHours.total += storeNetSales / storeSPLHTotal;
    }
    if (storeBOH > 0) {
      currentTotals.laborHours.boh += storeNetSales / storeBOH;
    }
    if (storeFOH > 0) {
      currentTotals.laborHours.foh += storeNetSales / storeFOH;
    }
  });

  // Calculate and format current year values only
  // Net Sales
  result.cNSTotal = `$${currentTotals.netSales.total.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
  result.cNSDineIn = `$${currentTotals.netSales.dineIn.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
  result.cNSToGo = `$${currentTotals.netSales.toGo.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
  result.cNSDelivery = `$${currentTotals.netSales.delivery.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;

  // Check Counts
  result.cCCTotal = currentTotals.checkCounts.total.toString();
  result.cCCDineIn = currentTotals.checkCounts.dineIn.toString();
  result.cCCToGo = currentTotals.checkCounts.toGo.toString();
  result.cCCDelivery = currentTotals.checkCounts.delivery.toString();

  // SPLH
  result.cSPLHTotal = currentTotals.laborHours.total > 0 ? 
    `$${(currentTotals.netSales.total / currentTotals.laborHours.total).toFixed(0)}` : '$0';
  result.cBOH = currentTotals.laborHours.boh > 0 ? 
    `$${(currentTotals.netSales.total / currentTotals.laborHours.boh).toFixed(0)}` : '$0';
  result.cFOH = currentTotals.laborHours.foh > 0 ? 
    `$${(currentTotals.netSales.total / currentTotals.laborHours.foh).toFixed(0)}` : '$0';

  // Average Checks
  result.cACTotal = currentTotals.checkCounts.total > 0 ? 
    `$${(currentTotals.netSales.total / currentTotals.checkCounts.total).toFixed(2)}` : '$0.00';
  result.cACDineIn = currentTotals.checkCounts.dineIn > 0 ? 
    `$${(currentTotals.netSales.dineIn / currentTotals.checkCounts.dineIn).toFixed(2)}` : '$0.00';
  result.cACToGo = currentTotals.checkCounts.toGo > 0 ? 
    `$${(currentTotals.netSales.toGo / currentTotals.checkCounts.toGo).toFixed(2)}` : '$0.00';
  result.cACDelivery = currentTotals.checkCounts.delivery > 0 ? 
    `$${(currentTotals.netSales.delivery / currentTotals.checkCounts.delivery).toFixed(2)}` : '$0.00';

  return result;
};

// Helper function to convert string numbers to numbers
export const parseNumberString = (numString) => {
  if (typeof numString === 'string') {
    return parseInt(numString.replace(/,/g, '')) || 0;
  }
  if (typeof numString === 'number') {
    return numString;
  }
  return 0;
};

// Function to transform keys in the Raw Data (Alhoa) data structure
export function transformAlohaKeys(data) {

  const KEY_TRANSFORMATIONS = {
    cLabor: 'cLaborDollars',
    pLabor: 'pLaborDollars',
    cFOHLaborHours: 'cFOHHours',
    pFOHLaborHours: 'pFOHHours',
    cBOHLaborHours: 'cBOHHours',
    pBOHLaborHours: 'pBOHHours',
  }
  // First level: array of objects
  return data.map(pageObj => {
    // Clone the object to avoid mutating original
    const newPageObj = { ...pageObj };
    
    // Transform the data array within each page object
    if (newPageObj.data && Array.isArray(newPageObj.data)) {
      newPageObj.data = newPageObj.data.map(row => {
        // Clone the row to avoid mutating original
        const newRow = { ...row };
        
        // If rowData exists and has either labor key, transform them
        if (newRow.rowData) {
          Object.entries(KEY_TRANSFORMATIONS).forEach(([oldKey, newKey]) => {
            if (oldKey in newRow.rowData) {
              newRow.rowData[newKey] = newRow.rowData[oldKey]
              delete newRow.rowData[oldKey]
            }
          })
        }
        
        return newRow;
      });
    }
    
    return newPageObj;
  });
}

export default {
  buildR0002Pages,
  compEndDate,
  compStartDate,
  // formatMetricValue,
  formatNumberWithCommas,
  getShiftString,
  getCellStyles,
  getYears,
  initialStartDate,
  maxDate,
  minDate,
  r0002Columns,
  transformAlohaKeys,
  usdStringToNum,
}