import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types'

import { API } from 'aws-amplify'

import { createR0003Projection, updateR0003Projection } from 'graphql/mutations'
import { listAreas2, getR0003ProjectionData } from 'graphql/queries'

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// RadixDash components
import Progress from 'components/Progress/Progress'
import R0003ProjectionSettings from "../R0003ProjectionSettings";
import ProjectionBody from './ProjectionBody'
import ProjectionHeader from './ProjectionHeader';
import { useUser } from 'components/RDAuthContext'

import { cleanProjectionData, getForecastedSales, getHistoricalSalesData, initialData, initialTheoHours, removeDayKeys, weeks /*, writeToDynamoDB */ } from "./projectionUtils";
// import { v4 as uuidv4 } from 'uuid'

export default function R0003Projections(props){
  
  const { user: cognitoUser } = useUser()
  const user = cognitoUser.userName
  const role = cognitoUser.role

  const [dataLoading, setDataLoading] = useState(false)
  const [openSettings, setOpenSettings] = useState(true)
  const [newProjection, setNewProjection] = useState(false)
  const [storeConfig, setStoreConfig] = useState([{}])
  const [week, setWeek] = useState(null)
  const [store, setStore] = useState(null)
  const [fohBaseTheoHrs, setFohBaseTheoHrs] = useState(initialTheoHours)
  const [bohBaseTheoHrs, setBohBaseTheoHrs] = useState(initialTheoHours)
  const [data, setData] = useState(initialData)
  // const [oneYearAgoData, setOneYearAgoData] = useState({})
  // const [twoYearsAgoData, setTwoYearsAgoData] = useState({})
  // const [pastSalesData, setPastSalesData] = useState({})

  const [stores, setStores] = useState(['Fetching stores...'])

  async function setStoreDropdown(){
    try {
      const queryResponse = await API.graphql(
        {
          query: listAreas2,
          variables: {
            report: 'r0003_store_dropdown',
            user: user,
            role: role
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      )

      const results = queryResponse.data.listAreas2[0].stores
      setStoreConfig(results) 
      const storeArr = results.map(x => x.shortName)
      setStores(storeArr)
    } catch (err) {
      console.log('Error fetching store dropdown data', err)
      setStores(['Error: Unable to Load Stores'])
    }
  }

  useEffect(() => {
    setStoreDropdown()
  }, [])    

  function handleCancel(){
    setOpenSettings(false)
    props.setPage('actions')
  }

  async function handleSelection(selection){
    console.log('handleSelection', selection)
    const selectedWeek = weeks[selection.week]
    const selectedStore = stores[selection.store]
    setWeek(selectedWeek)
    setStore(selectedStore)   
    setOpenSettings(false)
    setDataLoading(true)

    let salesData = {}
    try {
      salesData = await getHistoricalSalesData(selectedStore, selectedWeek)
      
    } catch (err) {
      console.log('Error getting historical sales data', err)
    }

    // for(let i = 10; i < 20; i++){
    //   console.log('index', i)
    //   const day = i
    //   const item = {
    //     id: uuidv4(),
    //     storeName: 'aspen place',
    //     date: `2024-01-${day}`,
    //     forecastedSales: parseFloat(Math.random() * (5000 - 1000) + 1000).toFixed(2)
    //   }

    //   const result = await writeToDynamoDB(item)
    //   console.log('For Loop result', result)
    // }

    let forecastedData = {}
    try {
      forecastedData = await getForecastedSales(selectedStore, selectedWeek)
    } catch (err) {
      console.log('Error getting forecasted sales data', err)
    }
    console.log('forecastedData', forecastedData)

    
    // Set Base Theo Hours
    const storeIdx = storeConfig.findIndex(x => x.shortName === selectedStore)
    const foh = storeConfig[storeIdx].fohBaseTheoHrs
    const boh = storeConfig[storeIdx].bohBaseTheoHrs
    setFohBaseTheoHrs(foh)
    setBohBaseTheoHrs(boh)    

    // Query the DB to determine if data already exists
    try {
      const result = await API.graphql({
        query: getR0003ProjectionData,
        variables: { week: selectedWeek, store: selectedStore } ,
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })
      console.log('Query Result', result.data.getR0003ProjectionData)

      if(result.data.getR0003ProjectionData.store == null){
        console.log('New Projection')
        setNewProjection(true)
        setData({ 
          ...data, 
          ...salesData,
          ...forecastedData,
          week: selectedWeek, 
          store: selectedStore
        })
        
      } else {
        console.log('Existing Projection')
        setNewProjection(false)
        let tempObj = JSON.parse(JSON.stringify(result.data.getR0003ProjectionData))
        tempObj.avgWage = tempObj.avgWage.toFixed(2)

        const keyToRemove = '__typename'
        if (tempObj.hasOwnProperty(keyToRemove)) {
          delete tempObj[keyToRemove]
        } 
        
        console.log('tempObj', tempObj)
        console.log('salesData', salesData)
        setData({
          ...tempObj,
          ...salesData,
          ...forecastedData
        })
        
      }
    } catch(err) {
      console.log('Query Error', err)
      console.log('New Projection')
      setNewProjection(true)
      setData({ 
        ...data, 
        ...salesData,
        ...forecastedData,
        week: selectedWeek,
        store: selectedStore
      })
      
    }  
  }

  useEffect(() => {
    if(data.week !== ''){
      setDataLoading(false)
    }
  }, [data])

  async function handleSubmitProjections(inValues){
    console.log('handleSubmitProjections')
    console.log('inValues', inValues)
    
    let result

    // Remove the average and past years data

    const projectionValues = removeDayKeys(inValues)

    const values = cleanProjectionData(projectionValues)
    console.log('values', values)

    try {
      if(newProjection){
        result = await API.graphql({
          query: createR0003Projection,
          variables: { input: values }
        })
        console.log('Successfully created projection')
      } else {
        result = await API.graphql({
          query: updateR0003Projection,
          variables: { input: values }
        })
        console.log('Successfully updated projection')
      }
      props.setPage('actions')

    } catch (err) {
      console.log('Create Projection error', err)
    }
  }

  function handleTextFieldChange(event){
    let dataCopy = {...data}
    const field = event.target.name
    dataCopy[field] = event.target.value
    setData(dataCopy)
  }

  function getTextFieldValue(day, field){
 
    const fohWeekday = 316
    const fohWeekend = 230
    const bohWeekday = 345
    const bohWeekend = 316

    const shortDay = `${day.substring(0, 3)}`
    const projSalesKey = `${shortDay.toLowerCase()}ProjSales`    
    let productivityLevel
    const baseTheoHours = field === 'TheoFOH' ? fohBaseTheoHrs[shortDay] : bohBaseTheoHrs[shortDay]
    
    switch(day){
      case 'Monday':
      case 'Tuesday':
      case 'Wednesday':
      case 'Thursday':
        productivityLevel = field === 'TheoFOH' ? fohWeekday : bohWeekday
        break;
      default:
        productivityLevel = field === 'TheoFOH' ? fohWeekend : bohWeekend
    }
    return (data[projSalesKey] / productivityLevel + baseTheoHours).toFixed(2)    
  }

  return (    
      <MDBox 
        textAlign='center' 
      >
        <R0003ProjectionSettings 
          open={ openSettings }
          cancel={ handleCancel }
          weeks={ weeks }
          stores={ stores }
          close={ handleSelection}
        />        
        
        <ProjectionHeader store={store} week={week}/>

        { dataLoading ?
          <Progress /> :          
          <ProjectionBody 
            data={data}
            getTextFieldValue={getTextFieldValue}
            handleTextFieldChange={handleTextFieldChange} 
            handleSubmitProjections={handleSubmitProjections} 
            handleCancel={handleCancel}
            week={week}
          /> 
        }      
      </MDBox>
  )
}

R0003Projections.propTypes = {
  setPage: PropTypes.func
}