import { styled } from '@mui/system'
import MDButton from 'components/MDButton'

const Button_GrmDark = styled(MDButton)(({theme}) => ({
  backgroundColor: '#a44c0e',
  color: '#f9e9d3',
  '&: focus:not(:hover)': {
    backgroundColor: '#a44c0e',
    color: '#f9e9d3'
  },
  '&: hover': {
    backgroundColor: '#cf9e0b',
    color: '#29261f'
  },
  '&: disabled': {
    backgroundColor: '#b9ad9c',
    color: '#29261f'
  }
}))

export default Button_GrmDark