import { Box, Stack, Typography } from '@mui/material'

export default function ProjectionHeader({
  store,
  week,
}){
  
  return (
    <Box
      py={2}
      px={3}
      sx={{ backgroundColor: '#29261f' }}
    >
      <Stack direction='row' justifyContent='space-between'>
        <Stack direction='column'>
          <Typography variant='h6' fontWeight='medium' style={{ color: '#CF9E0B' }}>
            SPLH: Set Weekly Projections
          </Typography>
          <Typography variant='h6' fontWeight='medium' style={{ color: '#CF9E0B' }}>
            {store}
          </Typography>
        </Stack>
        <Stack direction='column'>
          <Typography variant='h6' fontWeight='medium' style={{ color: '#CF9E0B' }}>
            Week Starting
          </Typography>
          <Typography variant='h6' fontWeight='medium' style={{ color: '#CF9E0B' }}>
            {week}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  )
}