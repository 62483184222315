import { AGGREGATION_TYPES, METRIC_TYPES } from '../../../../constants';

export const checkCountMetrics = {
  cCCTotal: { 
    type: AGGREGATION_TYPES.SUM,
    metricType: METRIC_TYPES.CHECK_COUNTS
  },
  cCCDineIn: { 
    type: AGGREGATION_TYPES.SUM,
    metricType: METRIC_TYPES.CHECK_COUNTS
  },
  cCCToGo: { 
    type: AGGREGATION_TYPES.SUM,
    metricType: METRIC_TYPES.CHECK_COUNTS
  },
  cCCDelivery: { 
    type: AGGREGATION_TYPES.SUM,
    metricType: METRIC_TYPES.CHECK_COUNTS
  }
}