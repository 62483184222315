import { useEffect, useMemo, useState } from 'react';

// AWS Amplify components
import { API } from 'aws-amplify'

// GraphQL
import { dbGetItem, listAreas2 } from 'graphql/queries'

import { toTitleCase } from './r0004Utils';

import { useUser } from 'components/RDAuthContext'

function getCacheKey(filters){
  return `${filters.years.join('-')}-${filters.products.join('-')}-${filters.stores.join('-')}`
}

function filterData(data, filters){
  const years = filters.years
  const products = filters.products
  const stores = filters.stores

  return data.filter(item => {
    const year = item.period.slice(0, 4)
    const productIncluded = item.products.some(p => {
      return products.includes(p.product)
    })

    const storeIncluded = item.products.some(p => {
      return p.stores.some(s => {
        return stores.includes(s.store)
      })
    })

    return (
      years.includes(year) &&
      productIncluded &&
      storeIncluded
    )
  })
}

export function useFilterCache(data, filters){
  const cache = {}

  const cacheKey = getCacheKey(filters)

  const cachedData = cache[cacheKey]

  const filteredData = useMemo(() => {
    if(cachedData) return cachedData
    
    const filtered = filterData(data, filters)
    cache[cacheKey] = filtered
    return filtered
  }, [filters])
  console.log('cache', cache)
  return filteredData
}

async function fetchProducts() {
  try {
    const dbVar = {
      report: 'r0004',
    }
    const dbVarJSON = JSON.stringify(dbVar)
    const queryResponse = await API.graphql({
      query: dbGetItem,
      variables: {
        db: 'grm.products',
        dbVariables: dbVarJSON,
      }
    })
    console.log('fetchProducts queryResponse', queryResponse)
    const productData = JSON.parse(JSON.parse(queryResponse.data.dbGetItem.statusItem))
    return productData
  } catch (err) {
    console.log('Error fetching product data', err)
  }
}

export function useProducts() {
  const [productMap, setProductMap] = useState([])
  const [productsReady, setProductsReady] = useState(false)
    
  useEffect(() => {
    setProductsReady(false)
    fetchProducts().then(data => {
      setProductMap(data)
      setProductsReady(true)
    })
  }, [])

  return { productMap, productsReady }
}

const rawDataCache = {}

async function fetchData(periodType, years){
  let periods = []
  periodType === 'quarter'
  ? periods = ['h1', 'h2']
  : periods = ['0102', '0304', '0506', '0708', '0910', '1112']
  const fetchedData = []
  for (const year of years) {
    for (const period of periods){
      const cacheKey = `${year}-${periodType}-${period}`

      if(rawDataCache[cacheKey]) {
        fetchedData.push(...rawDataCache[cacheKey])
        continue
      }

      try {
        const dbVar = {
          report: 'r0004',
          periodType: periodType,
          year: year.toString(),
          period: period,
        }
        const dbVarJSON = JSON.stringify(dbVar)

        const queryResponse = await API.graphql({
          query: dbGetItem,
          variables: {
            db: 'grm.productmix',
            dbVariables: dbVarJSON
          }
        })
        console.log('queryResponse fetch', queryResponse)
        const periodData = JSON.parse(JSON.parse(queryResponse.data.dbGetItem.statusItem))

        rawDataCache[cacheKey] = []

        if(periodData !== null) {
          fetchedData.push(...periodData)
        }            
      } catch (err) {
        console.log(`Error fetching ${cacheKey}`, err)
      }
    }
  }
  return fetchedData
}

export function useQuery(periodType, years) {
  const [dataLoading, setDataLoading] = useState(true)
  const [rawQuarterData, setRawQuarterData] = useState(null)
  const [rawQuarterDataReady, setRawQuarterDataReady] = useState(false)
  const [rawMonthData, setRawMonthData] = useState(null)
  const [rawMonthDataReady, setRawMonthDataReady] = useState(false)
  const [hasFetchedMonth, setHasFetchedMonth] = useState(false)

  useEffect(() => {
    // Load Quarter data upon loading
    if(years != null && years.length > 0){
      setDataLoading(true)
      setRawQuarterDataReady(false)
      fetchData('quarter', years).then(data => {
        setRawQuarterData(data)
        setRawQuarterDataReady(true)
      })
    }
  }, [])
  
  useEffect(() => {
    if(periodType === 'month' && !hasFetchedMonth && years != null) {
      // Load Month data if user selects Month view
      setDataLoading(true)
      setRawMonthDataReady(false)
      fetchData('month', years).then(data => {
        setRawMonthData(data)
        setRawMonthDataReady(true)
      })
      setHasFetchedMonth(true)
    } 
  }, [periodType])  
  
  return {
    rawQuarterData,
    rawQuarterDataReady,
    rawMonthData,
    rawMonthDataReady,
    dataLoading,
    setDataLoading,
  }
}

async function fetchStores(user) {
  const { role, username } = user

  const storeObj = {}

  try {
    const queryResponse = await API.graphql({
      query: listAreas2,
      variables: {
        report: 'store-admin',
        user: username,
        role: role
      }
    })
    console.log('fetchStores queryResponse', queryResponse)
    const areaData = queryResponse.data.listAreas2

    for(const area of areaData){
      for(const store of area.stores){
        storeObj[store.shortName] = { areaName: area.areaName }
      }
    }
  } catch (err) {
    console.log('Error fetching Areas data', err)
  }

  return storeObj
}

export function useStores() {
  const { user } = useUser()
  
  const [storeMap, setStoreMap] = useState({})  
  const [storesReady, setStoresReady] = useState(false)

  useEffect(() => {
    setStoresReady(false)
    fetchStores(user).then(data => {
      setStoreMap(data)
      setStoresReady(true)
    })
  }, [])

  return { storeMap, storesReady }
}

export function useViews() {
  const [periodType, setPeriodType] = useState('quarter')
  const [reportType, setReportType] = useState('sales')
  const [title, setTitle] = useState(`Product Mix Report - ${toTitleCase(reportType)} by ${toTitleCase(periodType)}`)

  useEffect(() => {
    setTitle(`Product Mix Report - ${toTitleCase(reportType)} by ${toTitleCase(periodType)}`)
  }, [periodType, reportType])

  return {
    periodType,
    setPeriodType,
    reportType,
    setReportType,
    title,
  }
}

async function fetchYears(){
  try {
    const dbVar = {}
    const dbVarJSON = JSON.stringify(dbVar)
    const queryResponse = await API.graphql({
      query: dbGetItem,
      variables: {
        db: 'grm.productmix',
        dbVariables: dbVarJSON,
      }
    })
    console.log('fetchYears queryResponse', queryResponse)
    const years = JSON.parse(JSON.parse(queryResponse.data.dbGetItem.statusItem))
    return years
  } catch (err) {
    console.log('Error fetching years', err)
  }
}

export function useYears(){
  const [years, setYears] = useState(null)
  
  useEffect(() => {
    const yearArr = fetchYears()
    setYears(yearArr)
  }, [])

  return {years}
}