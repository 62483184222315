import PropTypes from 'prop-types'
import { Stack } from '@mui/material'
import MDTypography from 'components/MDTypography'
import SwitchDemDark from 'components/RDSwitch/SwitchDemDark'

function DollarPercentSwitch(props){

  const toggleSwitch = (event) => {
    props.handler(event.target.checked)    
  }

  let textColor = null
  let textWeight = 'bold'

  if(!props.active){
    textColor = '#b9ad9c'
    textWeight = 'normal'
  }

  return (
    <Stack direction='column' alignItems='center'>
      <MDTypography
        variant='body2'
        sx={{
          color: '#726B61',
          fontWeight: 'regular'
        }}
      >
        { props.title }
      </MDTypography>
      <Stack 
        direction='row'
        spacing={0}
        alignItems='center'
        justifyContent='center'
      >
        <MDTypography
          variant='button'
          sx={{
            color: textColor,
            fontWeight: textWeight
          }}
        >
          $
        </MDTypography>
        <SwitchDemDark
          checked={ props.value }
          onChange={ toggleSwitch }
          disabled={ !props.active }
        />
        <MDTypography
          variant='button'
          sx={{
            color: textColor,
            fontWeight: textWeight
          }}
        >
          %
        </MDTypography>
      </Stack>
    </Stack>
  )
}

DollarPercentSwitch.propTypes = {
  active: PropTypes.bool,
  handler: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.bool
}

export default DollarPercentSwitch