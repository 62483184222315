import { styled } from '@mui/system'
import Select from '../Select'

const SelectGrm = styled(Select)(({theme}) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#a44c0e'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: '2px solid #a44c0e'
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '2px solid #a44c0e'
  },
  
  '& .MuiFormLabel-root': {
    color: '#a44c0e'
  },
  '& .MuiInputBase-input': {
    color: '#29261f'
    // color: '#a44c0e'
  },
  '& .MuiInputBase-root': {
    paddingTop: 10,
    paddingBottom: 10
  }
  
}))

export default SelectGrm