import React, { useState } from 'react'

// @mui material components
import { Autocomplete, Divider, Stack, TextField } from '@mui/material'

// Material Dashboard 2 components
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDDatePicker from 'components/MDDatePicker'
import MDTypography from 'components/MDTypography'

import { addDays, compareAsc, differenceInCalendarDays, startOfWeek, subDays, subWeeks } from 'date-fns';

// RadixDash components
import Switch_GrmDark from 'components/RDSwitch/Switch_GrmDark'
import DayParts from 'clients/grm/components/DayParts'
import utils from '../R0002Utils'

// Initialize date settings for report config
const yearOptions = utils.getYears(new Date().getFullYear())
const initialPrevYear = yearOptions[0]

export default function R0002Config(props) {
  // console.log('R0002Config', props)
  const [prevYear, setPrevYear] = useState(initialPrevYear)
  const [prevYearOptions, setPrevYearOptions] = useState(yearOptions)

  function handleCurRange(curRange){
    if(curRange.length === 2){
      let prevStart = subWeeks(curRange[0], 52)
      let prevEnd = subWeeks(curRange[1], 52)
      if(compareAsc(prevStart, utils.minDate) === -1){
        // Start date is before minDate
        prevStart = utils.minDate
        prevEnd = addDays(prevStart, differenceInCalendarDays(prevEnd, prevStart))
      }
      const compRange = [prevStart, prevEnd]
      props.setCur(curRange, compRange)
    }
  }

  function handleCompRange(compRange){
    return compRange.length === 2 ? props.setComp(compRange) : null
  }

  function handlePrevYear(year){
    const currentYear = props.curDateRange[0].getFullYear()
    const weekOffset = 52 * (currentYear - year.year)
    const [prevStart, prevEnd] = [
      subWeeks(props.curDateRange[0], weekOffset),
      subWeeks(props.curDateRange[1], weekOffset)
    ]
    setPrevYear(year)
    props.setComp([prevStart, prevEnd])
  }

  return (
    
    <MDBox pt={0.5} pb={3} px={3}>
      <Stack
        direction='column'
        spacing={2} 
      >    
        <DatePicker 
          id='currentDatePicker'
          title='Current Period (c)'
          dateRange={ props.curDateRange }
          onChange={ handleCurRange }
        />
        <DatePicker 
          id='compDatePicker'
          title='Previous Period (p)'
          dateRange={ props.compDateRange }
          onChange={ handleCompRange }
        />
        <Autocomplete 
          options={ prevYearOptions }
          getOptionLabel={ (option) => option.year }
          value={ prevYear }
          defaultValue={ initialPrevYear.year }
          renderInput={(params) => (
            <TextField 
              {...params}
              label='Previous Period Year'
              variant='outlined'
            />
          )}
          disableClearable={true}
          size='small'
          onChange={(event, newValue) => {
            handlePrevYear(newValue)
          }}
        />
        <MDBox
          display='flex'
          justifyContent='center'
        >
          <DayParts 
            shifts={ props.dayParts }
            shiftChange={ props.dayPartChange}
            active={ props.dayPartActive }
          />
        </MDBox>
        <MDButton
          variant='contained'
          color='info'
          onClick={ props.updateReport }
        >
          Update Report
        </MDButton>
      </Stack>
      <Divider />
      <MDBox
        sx={{
          mt: 2
        }}
      >
        <MDTypography 
          variant='h6'
          align='center'
        >
          Store Type
        </MDTypography>
            
          
        <Stack 
          direction='row'
          spacing={0}
          alignItems='center'
          justifyContent='center'
        >
          <MDTypography
            variant='button'
            sx={{
              fontWeight: '400'
            }}
          >
            Company
          </MDTypography>
          <Switch_GrmDark
            checked={ props.storeType }
            onChange={ (event) => props.setToggle(event) }
          />
          <MDTypography
            variant='button'
            sx={{
              fontWeight: '400'
            }}
          >
            Franchise
          </MDTypography>
          
        </Stack>
        {/* { props.storeType && 
          <div>
            <Divider />
            <MDTypography 
              variant='h6'
              align='center'
            >
              Comparison Type
            </MDTypography>
            <Stack 
              direction='row'
              spacing={0}
              alignItems='center'
              justifyContent='center'
            >
              <MDTypography
                variant='button'
                sx={{
                  fontWeight: '400'
                }}
              >
                Previous Period
              </MDTypography>
              <Switch_GrmDark
                checked={ props.compStoreSwitch }
                onChange={ (event) => props.setCSSwitch(event) }
              />
              <MDTypography
                variant='button'
                sx={{
                  fontWeight: '400'
                }}
              >
                Company Stores
              </MDTypography>
              
            </Stack>
          </div>
        } */}
      </MDBox> 
    </MDBox>    
  )
}

function DatePicker(props){
  return (
    <Stack
      direction='column'
      spacing={.5}
      alignItems='center'
      justifyContent='center'
    >
      <MDTypography
        variant='h6'
      >
        {props.title}
      </MDTypography>
      <MDDatePicker 
        id={ props.id }
        value={ props.dateRange }
        options={{
          mode: 'range',
          minDate: utils.minDate,
          maxDate: utils.maxDate,
          'locale': {
            'firstDayOfWeek': 1
          }
        }}
        fullWidth
        onChange={ range => props.onChange(range)}
      />
    </Stack>
  )
}
