/* eslint react/prop-types: 0 */

import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Icon, InputAdornment, Stack, Tab, Tabs, TextField, Tooltip } from '@mui/material'
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography'

// RadixDash components
import Button_GrmDark from 'components/RDButton/Button_GrmDark'
import Checkbox_GrmDark from 'components/Checkbox/Checkbox_GrmDark';
import RDTable from 'components/RDTable'
import SSCellStyle from './SSCellStyle'
import { ThemeProvider } from '@mui/material/styles'
import grmTheme from '../../grmTheme'

function TabPanel(props){
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={ value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <MDBox sx={{ p: 1 }}>
          {children}
        </MDBox>
      )}
    </div>
  )
}

export default function SelectStoreDialog(props){
  // console.log('props', props)
  const areas = props.data.map(x => x.areaName)
  areas.sort()
  const initialAreaRowIds = areas.reduce((acc, curr) => (acc[curr]={}, acc), {})
  const initialAreaStores = areas.reduce((acc, curr) => (acc[curr]=[], acc), {})
  

  const [activeTab, setActiveTab] = useState(0)
  const [selectedRowIds, setselectedRowIds] = useState({})
  const [selectedStoreRowIds, setSelectedStoreRowIds] = useState(props.rowIds)
  const [selectedStores, setSelectedStores] = useState(props.stores)
  const [storeList, setStoreList] = useState(props.storeList)
  const [clearStores, setClearStores] = useState(false)

  useEffect(() => {
    if(selectedStoreRowIds !== props.rowIds){
      setSelectedStoreRowIds(props.rowIds)
    }
    if(selectedStores !== props.stores){
      setSelectedStores(props.stores)
    }
    if(storeList !== props.storeList){
      setStoreList(props.storeList)
    }
  }, [props.rowIds, props.stores, props.storeList])

  const columns = useMemo(() => [
    {
      id: 'selector',
      width: '20%',
      align: 'center',
      alignCell: 'center',
      columnStyle: {
        backgroundColor: '#f9e9d3',
        color: '#536178',
        fontSize: '1rem',
        borderBottom: '.5px solid #a44c0e',
      },
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <ThemeProvider theme={grmTheme}>
          <MDBox>
            <Checkbox_GrmDark
              sx={{
                padding: 0,
                '& .MuiSvgIcon-root': {
                  fontSize: '.9rem'
                }
              }}
              {
                ...getToggleAllRowsSelectedProps()
              }
            />
          </MDBox>
        </ThemeProvider>
      ),
      Cell: ({ row }) => 
        <ThemeProvider theme={grmTheme}>
          <MDBox >
            <Checkbox_GrmDark 
              sx={{
                padding: 0,
                '& .MuiSvgIcon-root': {
                  fontSize: '.9rem'
                }
              }} 
              {...row.getToggleRowSelectedProps()} 
            />
          </MDBox>
        </ThemeProvider>,
    },
    {
      Header: 'Stores',
      accessor: 'store',
      disableGroupBy: true,
      disableSortBy: true,
      align: 'left',
      alignCell: 'right',
      columnStyle: {
        backgroundColor: '#f9e9d3',
        color: '#536178',
        fontSize: '1rem',
        borderBottom: '.5px solid #a44c0e',
      },
    },
  ], [])

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab)
  }

  function handleSubmit(){
    props.submit(selectedStoreRowIds, selectedStores, storeList)
  }

  function handleSelectedRows(rowIds, flatRows){
    const area = areas[activeTab]
    props.onSelection(rowIds, flatRows, area)
  }

  useEffect(() => {
    const storeArr = Object.values(selectedStores).flat()
    storeArr.sort()
  }, [selectedStores])

  function handleClear(){
    props.clear()
  }

  
  return (
    <Dialog
      open={ props.open }
      onClose={ props.close } 
      maxWidth='xs'
    >
      <DialogTitle
        sx={{ 
          backgroundColor: '#29261f',
          color: '#cf9e0b'
        }}
      >
        Select Stores
      </DialogTitle>
      <DialogContent
        sx={{
          backgroundColor: '#f9e9d3'
        }}
      >
        <MDBox
          sx={{ 
            flexGrow: 1,
            display: 'flex',
          }}
        >
          <Tabs 
            value={activeTab}
            onChange={handleTabChange}
            orientation='vertical'
            sx={{ 
              backgroundColor: '#f9e9d3',
              pt: 1
              // borderRight: '1px solid #536178',
              // borderRadius: 0
            }}
          >
            { areas.map( area => { 
              return (
                <Tab label={area} />
              )
            })}
          </Tabs>
          {
            areas.map( (area, index) => {
              const areaIdx = props.data.findIndex(x => x.areaName === area)
              let stores 
              if(areaIdx !== -1){
                stores = props.data[areaIdx].stores
                .filter(x => x.status !== 'Closed')
                .map((x) => (
                  { 'store': x.shortName }
                ))
              }
              return (
                <TabPanel value={activeTab} index={index} >
                  <RDTable 
                    table={{
                      columns: columns,
                      rows: stores
                    }}
                    editable={{
                      onRowAdd: null,
                      onRowEdit: null,
                      onRowDelete: null
                    }}
                    showTotalEntries={false}
                    entriesPerPage={{defaultValue: 10}}
                    cellStyle={ SSCellStyle }
                    // selectedRows={ selectedStoreRowIds[areas[activeTab]] }
                    selectedRows={ props.rowIds[areas[activeTab]] }
                    setSelectedRows={ handleSelectedRows }
                    clearSelectedRows={ clearStores }
                  />
                </TabPanel>
              )
            })
          }
          
        </MDBox>
        <MDBox sx={{ borderTop: '1px solid #000', pt: 1}}>
          <MDBox>
            <MDTypography variant='caption'>
              Note: Minimum 3 stores must be selected
            </MDTypography>
          </MDBox>
          <MDBox>
            <MDTypography variant='header5'>
              Selected Stores ({storeList.length})
            </MDTypography>
          </MDBox>
          <MDBox sx={{ fontSize: '.8rem'}}>
            { storeList.map(store => `${store}, `)}
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: '#f9e9d3',
          // borderTop: '1px solid #a44c0e'
        }}
      >
        <Button_GrmDark
          onClick={ props.cancel }
        >
          Cancel
        </Button_GrmDark>
        <Button_GrmDark
          onClick={ props.clear }
        >
          Clear
        </Button_GrmDark>
        <Button_GrmDark
          onClick={ handleSubmit } 
          disabled={ props.storeList.length < 3 }
        >
          Submit
        </Button_GrmDark>
      </DialogActions>
    </Dialog>
  )
}

SelectStoreDialog.propTypes = {
  cancel: PropTypes.func,
  clear: PropTypes.func,
  data: PropTypes.array,
  onSelection: PropTypes.func,
  open: PropTypes.bool,
  rowIds: PropTypes.object,
  storeList: PropTypes.array,
  stores: PropTypes.object,
  submit: PropTypes.func,
}