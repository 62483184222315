import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// AWS Amplify components
import { API } from 'aws-amplify'

// GraphQL
import { dbGetItem, getReportv2 } from 'graphql/queries'

// @mui material components
import { Card, Grid, Tooltip } from '@mui/material'
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setOpenConfigurator } from "context";

// Material Dashboard 2 PRO React components
import MDAlert from 'components/MDAlert'
import MDBox from "components/MDBox";
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'layouts/DashboardLayout'

import { format } from 'date-fns';

// RadixDash components
import DashboardNavbar from 'components/RDNavbars/DashboardNavbar';
import Progress from 'components/Progress/Progress'
import RDConfigurator from 'components/RDConfigurator'
import RDCSVExport from 'components/RDCSVExport'
import RDFooter from  'components/RDFooter'
import R0002Config from './Config/R0002Config'
import R0002Table from './R0002Table';
import AreaSelector from './R0002AreaSelector'
import R0002ExportPdf from './R0002ExportPdf'
import utils from './R0002Utils'
import getToastData from './ToastData/getToastData'
import combinePOSData from './ToastData/combinePOSData'
import groupAndSortTableData from './groupAndSortTableData'
import preparePageData from './PreparePageData/preparePageData'
import rearrangeRows from './ToastData/rearrangeRows'
import createOutputStructure from './createOutputStructure'
import { deepClone } from "utils/helperUtils"
import { useUser } from 'components/RDAuthContext'
import useAreas from 'hooks/clients/grm/useAreas'
import mergePOSData from './PreparePageData/mergePOSData2'
// import transformMergedData from './PreparePageData/transformMergedData2'
import transformMergedData from 'pages/clients/grm/reports/R0002/services/transformers'
import { useTransformData } from '../../../../pages/clients/grm/reports/R0002/hooks/useTransformData'

const filteredStoreCache = {}

function getShiftString(dayParts){
  let shiftString = ''
  if(dayParts.lunch){
    shiftString += 'L'
  }
  if(dayParts.social){
    shiftString += 'S'
  }
  if(dayParts.dinner){
    shiftString += 'D'
  }
  return shiftString
}

export default function R0002Main(){
  console.log('R0002Main')
  const { permissions, user } = useUser()
  const { storeMap } = useAreas()
    
  const [alert, setAlert] = useState(null)
  const [alertMessage, setAlertMessage] = useState({
    type: 'none',
    message: ''
  })
  const [dataLoading, setDataLoading] = useState(false)
  const [curDateRange, setCurDateRange] = useState([utils.initialStartDate, utils.maxDate])
  const [compDateRange, setCompDateRange] = useState([utils.compStartDate, utils.compEndDate])
  const [area, setArea] = useState('All')
  const [title, setTitle] = useState('Summary Report')
  const [priceIncrease, setPriceIncrease] = useState(0)
  const [priceIncreaseSwitch, setPriceIncreaseSwitch] = useState(false)
  const [storeTypes, setStoreTypes] = useState([])
  const [storeTypeSwitch, setStoreTypeSwitch] = useState(false)
  const [compStoreSwitch, setCompStoreSwitch] = useState(false)
  const [data, setData] = useState([])
  const [toastData, setToastData] = useState([])
  const [dataReady, setDataReady] = useState(false)
  const [filteredStoreList, setFilteredStoreList] = useState(data)
  const [rawData, setRawData] = useState([])
  const [pageType, setPageType] = useState(null)
  const [pages, setPages] = useState({
    company: [],
    franchise: []
  })
  const [viewPermission, setViewPermission] = useState('')
  const [thresholds, setThresholds] = useState([])
  const [thresholdsReady, setThresholdsReady] = useState(false)
  const [dayParts, setDayParts] = useState({
    lunch: true,
    social: true,
    dinner: true
  })

  // const transform = useTransformData()

  function handleDayParts(dayPart){
    
    setDayParts({ ...dayParts, [dayPart.target.name]: dayPart.target.checked })
    
  }

  const dayPartActive = {
    lunch: true,
    social: true,
    dinner: true
  }
  
  useEffect(() => {
    console.log('permissions', permissions)
    const r0002Permission = permissions.find(x => x.report === 'R0002')

    if(r0002Permission){
      setViewPermission(r0002Permission.viewPermissions)
    } else {
      setViewPermission('no-permission')
    }

    getThresholds()
  }, [])

  async function getThresholds(){
    const {client} = user
  
    const dbVar = {
      client: client,
      report: 'R0002',
      itemType: 'thresholds',
      type: []
    }
    const dbVarJSON = JSON.stringify(dbVar)
  
    try {
      const queryResponse = await API.graphql({
        query: dbGetItem,
        variables: {
          db: 'grm.reportadmins',
          dbVariables: dbVarJSON
        }
      })
      const responseData = JSON.parse(JSON.parse(queryResponse.data.dbGetItem.statusItem))
      setThresholds(responseData)
    } catch (err) {
      console.log('Error fetching THRESHOLDS', err)
    }
  }

  useEffect(() => {
    thresholds.length > 0 ? setThresholdsReady(true) : setThresholdsReady(false)
  }, [thresholds])

  useEffect(() => {
    switch(viewPermission){
      case 'view-all-areas':
        setStoreTypes(['company', 'franchise'])
        setPageType('Company')
        break;
      case 'view-company-store-areas':
        setStoreTypes(['company'])
        setPageType('Company')
        break;
      default: // view-own-area
        setStoreTypes(['franchise'])
        setPageType('Franchise')
        break;
    }
  }, [viewPermission])

  /******************************** 
   * CONFIG handling
   ********************************/

  // Configurator State controls
  const [controller, dispatch] = useMaterialUIController();  
  const {
    openConfigurator,
  } = controller;
  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  function handleCurRange(curRange, compRange){
    setCurDateRange(curRange)
    setCompDateRange(compRange)
  }

  function handleCompRange(range){
    setCompDateRange(range)
  }

// compStoreSwitch
  const toggleSTSwitch = (event) => {
    setStoreTypeSwitch(event.target.checked)
    setArea('All')
  }

  const toggleCSSwitch = (event) => {
    setCompStoreSwitch(event.target.checked)
  }

  useEffect(() => {
    const newData = storeTypeSwitch ? pages.franchise : pages.company
    const newPageType = storeTypeSwitch ? 'Franchise' : 'Company'
    setData(newData)
    setPageType(newPageType)
  }, [storeTypeSwitch])

  async function fetchR0002Data(){
    console.log('fetchR0002Data', new Date())
    // Close the configurator panel
    handleConfiguratorOpen()

    setDataLoading(true)
    setDataReady(false)
    setArea('All')

    // Format dates to match Query format
    const curSDate = format(curDateRange[0], 'yyyy-MM-dd')
    const curEDate = format(curDateRange[1], 'yyyy-MM-dd')
    const compSDate = format(compDateRange[0], 'yyyy-MM-dd')
    const compEDate = format(compDateRange[1], 'yyyy-MM-dd')

    const shiftString = getShiftString(dayParts)

    try {
      // Check if curSDate is 11/4/24 or later - the first date no stores in Aloha
      const targetDate = new Date('2024-11-04')
      const currentDate = new Date(curSDate)

      // Initialize date variables
      let effectiveStartDate = curSDate
      let effectiveEndDate = curEDate

      // Update dates if current date is on or after target date
      if (currentDate >= targetDate) {
        effectiveStartDate = compSDate
        effectiveEndDate = compEDate
      }
    
      const vars = {
        curStartDate: effectiveStartDate,
        curEndDate: effectiveEndDate,
        compStartDate: compSDate,
        compEndDate: compEDate,
        shifts: [shiftString],
        storeTypes: storeTypes
      }

      const varsJSON = JSON.stringify(vars)
      console.log('varsJSON', varsJSON)

      const getReportResponse2 = await API.graphql(
        {
          query: getReportv2,
          variables: {
            userName: user.userName,
            clientId: user.client,
            reportId: 'r0002',
            reportVariables: varsJSON
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      )
      console.log('Aloha Data Fetched', getReportResponse2)

      const clonedResponse = deepClone(getReportResponse2)

      const rawStatusItem = clonedResponse.data.getReportv2.statusItem
      const rawParsedOnce = JSON.parse(rawStatusItem)
      const rawParsedTwice = JSON.parse(rawParsedOnce)
      console.log('rawParsedTwice', rawParsedTwice)
      // Convert cLabor/pLabor to cLaborDollars/pLaborDollars
      const rawConverted = utils.transformAlohaKeys(rawParsedTwice)



      const response2 = currentDate >= targetDate ? modifyResponseData(rawConverted) : rawConverted
      // const response2 = currentDate >= targetDate ? modifyResponseData(rawParsedTwice) : rawParsedTwice

      /********************************************************
      /* MetricData 
       ********************************************************/
      console.log('storeMap', storeMap)

      const toastMetrics = await getToastData(curSDate, curEDate, storeMap, shiftString)

      console.log('toastMetrics', toastMetrics)

      setToastData(toastMetrics)
    /*******************************************************/

      const firstYear = format(compDateRange[0], 'yyyy')
      const lastYear = format(curDateRange[1], 'yyyy')
      let yearArr = []
      for(let i=lastYear; i > firstYear; i--){
        yearArr.push(i.toString())
      }
      yearArr.sort()

      getThresholds(user.client)

      setRawData(response2)
    
    } catch (err) {
      console.log('Error fetching R0002 Data...', err)
      setDataLoading(false)
      setData([])
      setTitle(`Summary Report: Error Loading Data for ${format(curDateRange[0], 'M/dd/yyyy')} - ${format(curDateRange[1], 'M/dd/yyyy')}`)
      setAlertMessage({
        type: 'error',
        message: 'Error loading data'
      })
    }    
  }

  useEffect(() => {

    const mergedData = mergePOSData(rawData, toastData, storeMap)

    // const transformedData = transform(mergedData, storeMap, thresholds)
    const transformedData = transformMergedData(mergedData, storeMap, thresholds)

    setPages(transformedData)
  }, [rawData, toastData]);
  

  useEffect(() => {
    if(pageType === 'Company' && pages.company.length > 0){
      setData(pages.company)
    } else if(pageType === 'Franchise' && pages.franchise.length > 0){
      setData(pages.franchise)
    }
  }, [pages])

  useEffect(() => {
    setDataLoading(false)
    setDataReady(data.length > 1)
    setFilteredStoreList(data)
  }, [data])

  useEffect(() => {
    if(dataReady) {
      const shiftString = getShiftString(dayParts)
      setTitle(`Summary Report: ${format(curDateRange[0], 'M/dd/yyyy')} - ${format(curDateRange[1], 'M/dd/yyyy')} [ ${shiftString} ]`)
    } else {
      setTitle('Summary Report')
    }
  }, [dataReady])

  const handleArea = (selectedArea, filterList) => {
    setArea(selectedArea)
    filterStoreList(selectedArea, filterList)
  }

  function filterStoreList(selectedArea, includeRows){
    if(selectedArea === 'All'){
      // include all the rows
      setFilteredStoreList(data)
      return;
    } 

    // Generate a cache key base on selectedArea, includeRows, and shiftString
    const shiftString = getShiftString(dayParts)
    const cacheKey = `${selectedArea}-${includeRows.join('-')}-${curDateRange.join('-')}-${compDateRange.join('-')}-${shiftString})`

    if(filteredStoreCache[cacheKey]){
      // Retrieve the filtered rows from the cache
      console.log('set from cache')
      setFilteredStoreList(filteredStoreCache[cacheKey])
    } else {
      // include only the rows that match 'includeRows' array
      console.log('new filter')
      const includeSet = new Set(includeRows)
      const filteredData = data.filter(x => includeSet.has(x.store))

      // Store the filtered rows in the cache
      filteredStoreCache[cacheKey] = filteredData
      setFilteredStoreList(filteredData) 
    }
  }

  function exportPDF() {
    R0002ExportPdf( filteredStoreList, curDateRange[0], curDateRange[1], compDateRange[0], compDateRange[1], priceIncrease, priceIncreaseSwitch)
  }

  /****************************
   * ALERT Functions
   ****************************/
  // Determine which alert to trigger
  useEffect(() => {
    switch(alertMessage.type){
      case 'error':
        errorAlert()
        break;
      case 'success':
        successAlert()
        break;
      default:
        // No alert triggered
    }
  }, [alertMessage.type])

  // Close the alert
  const hideAlert = () => {
    
    setAlert(null)
    setAlertMessage('')
  }

  const successAlert = () => {
    console.log('successAlert...')
    setAlert(
      <MDAlert color='success' dismissible>
        { alertMessage.message }
      </MDAlert>
    )
    setTimeout(function() {hideAlert()}, 3000)
  }

  const errorAlert = () => {
    console.log('errorAlert()')
    setAlert(
      <MDAlert color='error' dismissible>
        { alertMessage.message }
      </MDAlert>
    )
    setTimeout(function() {hideAlert()}, 3000)
  }

  return (
    <DashboardLayout>
      <DashboardNavbar /> 
      {
        dataLoading ?
        <Progress /> :
        
        <MDBox 
          mb={3} 
          sx={{ 
            width: 'calc(1290px + 1rem)',
            mx: 'auto',
          }}
        >
          {alert}
          <Card >            
            <ReportHeader 
              area={area}
              dataReady={dataReady}
              exportPDF={exportPDF}
              filteredStoreList={filteredStoreList}
              handleArea={handleArea}
              handleConfiguratorOpen={handleConfiguratorOpen}
              pageType={pageType}
              thresholdsReady={thresholdsReady}
              title={title}
            />
            <ReportBody 
              filteredStoreList={filteredStoreList}
              storeTypeSwitch={ storeTypeSwitch }
              compStoreSwitch={ compStoreSwitch }
              thresholds={ thresholds }
            />
          </Card>
          <RDConfigurator
            report='R0002'
            component={ 
              <R0002Config
                curDateRange={ curDateRange } 
                compDateRange={ compDateRange }
                setCur={ handleCurRange } 
                setComp={ handleCompRange } 
                updateReport={ fetchR0002Data }
                storeType={ storeTypeSwitch }
                setToggle={ toggleSTSwitch }
                compStoreSwitch={ compStoreSwitch }
                setCSSwitch={ toggleCSSwitch }
                dayParts={ dayParts }
                dayPartChange={ handleDayParts }
                dayPartActive={ dayPartActive }
              /> 
            }
            // onClick={() => fetchR0002Data()}
          />
        </MDBox>        
      }
      <RDFooter />
    </DashboardLayout>
  )
}

const ReportHeader = ({
  area,
  dataReady,
  exportPDF,
  filteredStoreList,
  handleArea,
  handleConfiguratorOpen,
  pageType,
  thresholdsReady,
  title,
}) => {
  return (
    <MDBox pt={3} pl={3} pb={2} sx={{backgroundColor: '#29261F'}} >
      <Grid container justify-content='space-between'  direction='row' >
        <Grid item xs={7}>
          <MDTypography variant='h5' fontWeight='medium' style={{color: '#CF9E0B'}}>
            { title }
          </MDTypography>      
        </Grid>
        <Grid item xs={5} container justify-content='space-between' spacing={0}>
          <Grid item xs={6} >
            { dataReady && 
              <AreaSelector 
                area={ area }
                onChange={ handleArea }
                pageType={ pageType }
              />
            }
          </Grid>
          <Grid item xs={2} pl={2} textAlign='center'>
            { dataReady && 
              <RDCSVExport 
                data={filteredStoreList}
                headers={utils.r0002Columns}
                filename={title}
                style={{
                  background: '#cf9e0b',
                  backgroundHover: '#906e07',
                  color: '#29261f'
                }}
              />
            }
          </Grid>
          <Grid item xs={2}  textAlign='center'>
            { dataReady && 
              <Tooltip title='Export to PDF' >
                <MDButton
                  variant='gradient'
                  onClick={ exportPDF }
                  iconOnly
                  sx={{
                    bgcolor: '#cf9e0b',
                    color: '#29261f',
                    '&:hover': {
                      bgcolor: '#906e07'
                    },
                    '&:focus:not(:hover)': {
                      bgcolor: '#906e07'
                    }
                  }}
                >
                  PDF
                </MDButton>
              </Tooltip>
            }
          </Grid>
          <Grid item pl={2} pr={2} >                
            <Tooltip title='Report Settings'>
              <MDButton 
                variant='gradient' 
                onClick={handleConfiguratorOpen} 
                iconOnly
                sx={{
                  bgcolor: '#CF9E0B',
                  color: '#29261F',
                  '&:hover': {
                    bgcolor: '#906e07',
                  }
                }}
                disabled={!thresholdsReady}
              >
                <Icon fontSize="large" color="inherit">
                  settings
                </Icon>
              </MDButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>          
    </MDBox>
  )
}

const ReportBody = ({
  filteredStoreList,
  storeTypeSwitch,
  compStoreSwitch,
  thresholds
}) => {
  return (
    <MDBox
      position='relative'
      sx={{
        backgroundColor: '#e0e0e0'
      }}
    >
      <R0002Table 
        data={ filteredStoreList }
        storeTypeSwitch={ storeTypeSwitch }
        compStoreSwitch={ compStoreSwitch }
        thresholds={ thresholds }
      />
    </MDBox>
  )
}

const modifyResponseData = (response2) => {
  // Deep clone to avoid modifying original object
  const modifiedResponse = JSON.parse(JSON.stringify(response2));

  // Loop through each page
  modifiedResponse.forEach((page, i) => {
    if (page.data && Array.isArray(page.data)) {
      // Loop through each row in data
      page.data.forEach((row, j) => {
        if (row.rowData) {
          // Loop through each property in rowData
          Object.keys(row.rowData).forEach(key => {
            // Check if property starts with 'c'
            if (key.startsWith('c')) {
              const value = row.rowData[key];
              // Determine the appropriate zero format based on the original value format
              if (typeof value === 'string') {
                if (value.includes('%')) {
                  row.rowData[key] = '0.00%';
                } else if (value.includes('$')) {
                  row.rowData[key] = '$0';
                } else {
                  row.rowData[key] = '0';
                }
              } else if (typeof value === 'number') {
                row.rowData[key] = 0;
              }
            }
          });
        }
      });
    }
  });

  return modifiedResponse;
}

R0002Main.propTypes = {
  setComp: PropTypes.func, 
  setCur: PropTypes.func,
  updateReport: PropTypes.func
}