import React, { useEffect, useState } from 'react'

// @mui components
import { Autocomplete, TextField } from '@mui/material'

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import isoCountries from 'utils/country-codes'

export default function RDCountrySelector(props){

  const [value, setValue] = useState(null)
  const [inputValue, setInputValue] = useState('')

  function setCountry(country){
    setValue(country)
    props.setCountry(country.alpha3)
  }

  useEffect(() => {
    const index = isoCountries.findIndex(x => x.alpha3 === 'USA')
    if(index === -1){
      setValue(isoCountries[0])
    } else {
      setValue(isoCountries[index])
    }
  }, [])

  return (
    <Autocomplete
      id='country-selector'
      value={ value }
      onChange={(event, newValue) => {
        setCountry(newValue)
      }}
      inputValue={ inputValue }
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      sx={{ width: 350 }}
      size='small'
      disableClearable
      options={ isoCountries }
      getOptionLabel={(option) => option.alpha3 + ' | ' + option.name}
      renderOption={(props, option) => (
        <MDBox 
          component='li'
          sx={{
            '& > img': { mr: 2, flexShrink: 0 }
          }}
          {...props}
        >
          {option.alpha3} | {option.name}
        </MDBox>
      )}
      renderInput={(params) => (
        <TextField 
          {...params}
          label='Choose a country'
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password'
          }}
        />
      )}
    />
  )
}