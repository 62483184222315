import { options } from "dropzone"

export default function R0002CellStyle(row, cell, styleOptions){
  const value = row.original
  const column = cell.column.id
  let priceIncrease
  const priceIncreaseSwitch = styleOptions[1].value
  let cur, comp, cellBGColor

  let highTH = 0
  let lowTH = 0
  let diff 

  if(priceIncreaseSwitch){
    priceIncrease = styleOptions[0].value*100
  } else {
    priceIncrease = 0
  }  

  // priceIncrease = styleOptions[0].value*100

  let setting = {}
  const rowType = value.rowType

  // switch(value.store){
  //   case 'Athena':
  //   case 'Brad':
  //   case 'James':
  //   case 'Jessica':
  //   case 'John':
  //   case 'Ryan':
  //   case 'Franchise 01':
  //   case 'Others':
  //     rowType = 'managerRow'
  //     break;
  //   case 'Athena Total':
  //   case 'Brad Total':
  //   case 'James Total':
  //   case 'Jessica Total':
  //   case 'John Total':
  //   case 'Ryan Total':
  //   case 'Franchise 01 Total':
  //   case 'Total Comp':
  //   case 'Total':
  //     rowType = 'totalRow'      
  //     break;
  //   case 'SSS %':
  //   case 'Stores Increase':
  //   case '% CY Net':
  //     rowType = 'summaryRow'
  //     break;
  //   case 'SSS % WTD':
  //     rowType = 'bottomBorderRow'
  //     break;
  //   case 'Fashion Show':
  //   case 'El Segundo':
  //   case 'Domain':
  //     rowType = 'otherStoreRow'
  //     break;
  //   default:
  //     rowType = 'storeRow'
  // }

  // cell setting variables -> default values

  let bottomBorder = '1px solid #c0c0c0'
  let leftBorder = ''
  let topBorder = ''
  let cellColor = 'white'
  let padding = 0
  let paddingLeft = 0
  let textColor = 'black'
  let fontWeight = ''
  let textAlign = 'center'
  let tempColor
  let width = 50

  switch(column){
    case 'store':
      highTH = 6
      lowTH = 0
      width = 75
      switch(rowType){
        case 'bottomBorderRow':
          bottomBorder = '3px solid #000'
          fontWeight = 'bold'
          break;
        case 'managerRow':
          cellColor = '#A44C0E'
          fontWeight = 'bold'
          textColor = 'white'
          // textColor = '#F9E9D3'
          break;
        case 'totalRow':
          fontWeight = 'bold'
          topBorder = '3px solid #000'
          break;
        case 'storeRow':
          paddingLeft = '10px'
          textAlign = 'left'
          break;
        case 'otherStoreRow':
          paddingLeft = '10px'
          textAlign = 'left'
          break;
        default:
          fontWeight = 'bold'
      }
      break;
    case 'pNSTotal':
      switch(rowType){
        case 'bottomBorderRow':
          bottomBorder = '3px solid #000'
          leftBorder = '3px solid #000'
          fontWeight = 'bold'
          break;
        case 'managerRow':
          cellColor = '#A44C0E'
          fontWeight = ''
          leftBorder = '3px solid #000'
          padding = 0
          paddingLeft = 0
          textAlign = ''
          textColor = ''
          topBorder = ''
          break;
        case 'totalRow':
          cellColor = 'white'
          fontWeight = 'bold'
          leftBorder = '3px solid #000'
          padding = 0
          paddingLeft = 0
          textAlign = 'center'
          textColor = 'black'
          topBorder = '3px solid #000'
          break;
        case 'storeRow':
          cellColor = 'white'
          fontWeight = ''
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = 'black'
          // topBorder = ''
          break;
        case 'otherStoreRow':
          cellColor = 'white'
          fontWeight = ''
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = '#606060'
          // topBorder = ''
          break;
        default:
          cellColor = 'white'
          fontWeight = 'bold'
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = 'black'
          topBorder = ''
      }
      break;
    case 'cNSTotal':
      cur = parseInt(toNum(value.cNSTotal))
      comp = parseInt(toNum(value.tipNSTotal))
      highTH = 6
      lowTH = 0
      switch(rowType){
        case 'bottomBorderRow':
          bottomBorder = '3px solid #000'
          fontWeight = 'bold'
          break;
        case 'managerRow':
          cellColor = '#A44C0E'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = ''
          textColor = ''
          topBorder = ''
          break;
        case 'totalRow':
          if(value.store === 'Total'){
            cellColor = 'white'
          } else {
            // cellColor = getCellBGColor(cur, comp, false)
            let pct
            if(value.store !== '% CY Net'){
              pct = (cur - comp)/comp * 100
              if(pct >= highTH && value.store !== '% CY Net'){
                cellColor = 'green'
              } else if(pct > lowTH && pct < highTH){
                cellColor = 'yellow'
              } else {
                cellColor = 'red'
              }
            }
          }
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = '3px solid #000'
          break;
        case 'storeRow':
          // cellColor = getCellBGColor(cur, comp, false)
          let pct
          if(value.store !== '% CY Net'){
            pct = (cur - comp)/comp * 100
            if(pct >= highTH && value.store !== '% CY Net'){
              cellColor = 'green'
            } else if(pct > lowTH && pct < highTH){
              cellColor = 'yellow'
            } else {
              cellColor = 'red'
            }
          }
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = ''
          break;
        case 'otherStoreRow':
          cellColor = 'white'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textColor = '#606060'
          textAlign = 'center'
          topBorder = ''
          break;
        default:
          cellColor = 'white'
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = 'black'
          topBorder = ''
      }
      break;
    case 'pct':
      let cNS = parseInt(toNum(value.cNSTotal))
      let pNS = parseInt(toNum(value.tipNSTotal))
      let pct
      highTH = 6
      lowTH = 0
      // if(value.store !== '% CY Net'){
      //   console.log('store', value.store)
      //   pct = (cNS - pNS)/pNS * 100
      //   console.log('pct', pct)

      //   if(pct >= 0.06 && value.store !== '% CY Net'){
      //     cellBGColor = 'green'
      //   } else if(pct <= 0){
      //     cellBGColor = 'red'
      //   } else {
      //     cellBGColor = 'yellow'
      //   }
      // } else {
      //   cellBGColor = ''
      // }
      // console.log('cellBGColor', cellBGColor)
      switch(rowType){
        case 'bottomBorderRow':
          bottomBorder = '3px solid #000'
          fontWeight = 'bold'
          break;
        case 'managerRow':
          cellColor = '#A44C0E'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = ''
          textColor = ''
          topBorder = ''
          break;
        case 'totalRow':
          if(value.store !== '% CY Net' && value.store !== 'Total'){
            pct = (cNS - pNS)/pNS * 100
            
            if(pct >= highTH && value.store !== '% CY Net'){
              cellColor = 'green'
            } else if(pct > lowTH && pct < highTH){
              cellColor = 'yellow'
            } else {
              cellColor = 'red'
            }            
          }
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = '3px solid #000'
          break;
        case 'storeRow':
          if(value.store !== '% CY Net'){
            pct = (cNS - pNS)/pNS * 100
            if(pct >= highTH && value.store !== '% CY Net'){
              cellColor = 'green'
            } else if(pct > lowTH && pct < highTH){
              cellColor = 'yellow'
            // } else if(pct <= 0){
            //   cellColor = 'red'
            } else {
              cellColor = 'red'
            }
          }
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = ''
          break;
        case 'otherStoreRow':
          cellColor = 'white'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textColor = '#606060'
          textAlign = 'center'
          topBorder = ''
          break;
        default:
          cellColor = 'white'
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = 'black'
          topBorder = ''
      }
      break;
    case 'cNSDineIn':
      cur = parseInt(toNum(value.cNSDineIn))
      comp = parseInt(toNum(value.tipNSDineIn))
      highTH = 6
      lowTH = 0
      switch(rowType){
        case 'bottomBorderRow':
          bottomBorder = '3px solid #000'
          leftBorder = '3px solid #000'
          fontWeight = 'bold'
          break;
        case 'managerRow':
          cellColor = '#A44C0E'
          fontWeight = ''
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = ''
          textColor = ''
          topBorder = ''
          break;
        case 'totalRow':
          if(value.store !== 'Total'){
            // cellColor = getCellBGColor(cur, comp, false, 'ignore')
            let pct            
            pct = (cur - comp)/comp * 100
            if(pct >= highTH){
              cellColor = 'green'
            } else if(pct > lowTH && pct < highTH){
              cellColor = 'yellow'
            } else {
              cellColor = 'red'
            }
          }
          fontWeight = 'bold'
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = '3px solid #000'
          break;
        case 'storeRow':
          // cellColor = getCellBGColor(cur, comp, false, 'ignore')
          let pct            
          pct = (cur - comp)/comp * 100
          if(pct >= highTH){
            cellColor = 'green'
          } else if(pct > lowTH && pct < highTH){
            cellColor = 'yellow'
          } else {
            cellColor = 'red'
          }
          fontWeight = ''
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = ''
          break;
        case 'otherStoreRow':
          cellColor = 'white'
          fontWeight = ''
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = '#606060'
          topBorder = ''
          break;
        default:
          cellColor = 'white'
          fontWeight = 'bold'
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = 'black'
          topBorder = ''
      }
      break;
    case 'cNSToGo':
      cur = parseInt(toNum(value.cNSToGo))
      comp = parseInt(toNum(value.tipNSToGo))
      highTH = 6
      lowTH = 0
      switch(rowType){
        case 'bottomBorderRow':
          bottomBorder = '3px solid #000'
          fontWeight = 'bold'
          break;
        case 'managerRow':
          cellColor = '#A44C0E'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = ''
          textColor = ''
          topBorder = ''
          break;
        case 'totalRow':
          if(value.store !== 'Total'){
            // cellColor = getCellBGColor(cur, comp, false, 'ignore')            
            let pct            
            pct = (cur - comp)/comp * 100
            if(pct >= highTH){
              cellColor = 'green'
            } else if(pct > lowTH && pct < highTH){
              cellColor = 'yellow'
            } else {
              cellColor = 'red'
            }
          }
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = '3px solid #000'
          break;
        case 'storeRow':
          // cellColor = getCellBGColor(cur, comp, false, 'ignore')
          let pct            
          pct = (cur - comp)/comp * 100
          if(pct >= highTH){
            cellColor = 'green'
          } else if(pct > lowTH && pct < highTH){
            cellColor = 'yellow'
          } else {
            cellColor = 'red'
          }
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = ''
          break;
        case 'otherStoreRow':
          cellColor = 'white'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = '#606060'
          topBorder = ''
          break;
        default:
          cellColor = 'white'
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = 'black'
          topBorder = ''
      }
      break;
    case 'cNSDelivery':
      cur = parseInt(toNum(value.cNSDelivery))
      comp = parseInt(toNum(value.tipNSDelivery))
      highTH = 6
      lowTH = 0
      switch(rowType){
        case 'bottomBorderRow':
          bottomBorder = '3px solid #000'
          fontWeight = 'bold'
          break;
        case 'managerRow':
          cellColor = '#A44C0E'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = ''
          textColor = ''
          topBorder = ''
          break;
        case 'totalRow':
          if(value.store !== 'Total'){
            // cellColor = getCellBGColor(cur, comp, false, 'ignore')
            let pct            
            pct = (cur - comp)/comp * 100
            if(pct >= highTH){
              cellColor = 'green'
            } else if(pct > lowTH && pct < highTH){
              cellColor = 'yellow'
            } else {
              cellColor = 'red'
            }
          }
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = '3px solid #000'
          break;
        case 'storeRow':
          // cellColor = getCellBGColor(cur, comp, false, 'ignore')
          let pct            
          pct = (cur - comp)/comp * 100
          if(pct >= highTH){
            cellColor = 'green'
          } else if(pct > lowTH && pct < highTH){
            cellColor = 'yellow'
          } else {
            cellColor = 'red'
          }
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = ''
          break;
        case 'otherStoreRow':
          cellColor = 'white'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = '#606060'
          topBorder = ''
          break;
        default:
          cellColor = 'white'
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = 'black'
          topBorder = ''
      }
      break;
    case 'cGSDelivery':
      cur = parseInt(toNum(value.cGSDelivery))
      comp = parseInt(toNum(value.tipGSDelivery))
      highTH = 6
      lowTH = 0
      switch(rowType){
        case 'bottomBorderRow':
          bottomBorder = '3px solid #000'
          fontWeight = 'bold'
          break;
        case 'managerRow':
          cellColor = '#A44C0E'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = ''
          textColor = ''
          topBorder = ''
          break;
        case 'totalRow':
          if(value.store !== 'Total'){
            // cellColor = getCellBGColor(cur, comp, false, 'ignore')
            let pct            
            pct = (cur - comp)/comp * 100
            if(pct >= highTH){
              cellColor = 'green'
            } else if(pct > lowTH && pct < highTH){
              cellColor = 'yellow'
            } else {
              cellColor = 'red'
            }
          }
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = '3px solid #000'
          break;
        case 'storeRow':
          // cellColor = getCellBGColor(cur, comp, false, 'ignore')
          let pct            
          pct = (cur - comp)/comp * 100
          if(pct >= highTH){
            cellColor = 'green'
          } else if(pct > lowTH && pct < highTH){
            cellColor = 'yellow'
          } else {
            cellColor = 'red'
          }
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = ''
          break;
        case 'otherStoreRow':
          cellColor = 'white'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = '#606060'
          topBorder = ''
          break;
        default:
          cellColor = 'white'
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = 'black'
          topBorder = ''
      }
      break;
    case 'cCCTotal':
      cur = parseInt(toNum(value.cCCTotal))
      comp = parseInt(toNum(value.tipCCTotal))
      switch(rowType){
        case 'bottomBorderRow':
          bottomBorder = '3px solid #000'
          leftBorder = '3px solid #000'
          fontWeight = 'bold'
          break;
        case 'managerRow':
          cellColor = '#A44C0E'
          fontWeight = ''
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = ''
          textColor = ''
          topBorder = ''
          break;
        case 'totalRow':
          if(value.store !== 'Total'){
            cellColor = getCellBGColor(cur, comp, false, 'ignore')
          }
          fontWeight = 'bold'
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = '3px solid #000'
          break;
        case 'storeRow':
          cellColor = getCellBGColor(cur, comp, false, 'ignore')
          fontWeight = ''
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = ''
          break;
        case 'otherStoreRow':
          cellColor = 'white'
          fontWeight = ''
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = '#606060'
          topBorder = ''
          break;
        default:
          cellColor = 'white'
          fontWeight = 'bold'
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = 'black'
          topBorder = ''
      }
      break;
    case 'cCCDineIn':
      cur = parseInt(toNum(value.cCCDineIn))
      comp = parseInt(toNum(value.tipCCDineIn))
      switch(rowType){
        case 'bottomBorderRow':
          bottomBorder = '3px solid #000'
          fontWeight = 'bold'
          break;
        case 'managerRow':
          cellColor = '#A44C0E'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = ''
          textColor = ''
          topBorder = ''
          break;
        case 'totalRow':
          if(value.store !== 'Total'){
            cellColor = getCellBGColor(cur, comp, false, 'ignore')
          }
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = '3px solid #000'
          break;
        case 'storeRow':
          cellColor = getCellBGColor(cur, comp, false, 'ignore')
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = ''
          break;
        case 'otherStoreRow':
          cellColor = 'white'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = '#606060'
          topBorder = ''
          break;
        default:
          cellColor = 'white'
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = 'black'
          topBorder = ''
      }
      break;
    case 'cCCToGo':
      cur = parseInt(toNum(value.cCCToGo))
      comp = parseInt(toNum(value.tipCCToGo))
      switch(rowType){
        case 'bottomBorderRow':
          bottomBorder = '3px solid #000'
          fontWeight = 'bold'
          break;
        case 'managerRow':
          cellColor = '#A44C0E'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = ''
          textColor = ''
          topBorder = ''
          break;
        case 'totalRow':
          if(value.store !== 'Total'){
            cellColor = getCellBGColor(cur, comp, false, 'ignore')
          }
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = '3px solid #000'
          break;
        case 'storeRow':
          cellColor = getCellBGColor(cur, comp, false, 'ignore')
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = ''
          break;
        case 'otherStoreRow':
          cellColor = 'white'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = '#606060'
          topBorder = ''
          break;
        default:
          cellColor = 'white'
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = 'black'
          topBorder = ''
      }
      break;
    case 'cCCDelivery':
      cur = parseInt(toNum(value.cCCDelivery))
      comp = parseInt(toNum(value.tipCCDelivery))
      switch(rowType){
        case 'bottomBorderRow':
          bottomBorder = '3px solid #000'
          fontWeight = 'bold'
          break;
        case 'managerRow':
          cellColor = '#A44C0E'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = ''
          textColor = ''
          topBorder = ''
          break;
        case 'totalRow':
          if(value.store !== 'Total'){
            cellColor = getCellBGColor(cur, comp, false, 'ignore')
          }
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = '3px solid #000'
          break;
        case 'storeRow':
          cellColor = getCellBGColor(cur, comp, false, 'ignore')
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = ''
          break;
        case 'otherStoreRow':
          cellColor = 'white'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = '#606060'
          topBorder = ''
          break;
        default:
          cellColor = 'white'
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = 'black'
          topBorder = ''
      }
      break;
    case 'cACTotal':
      cur = parseInt(toNum(value.cACTotal))
      comp = parseInt(toNum(value.tipACTotal))
      switch(rowType){
        case 'bottomBorderRow':
          bottomBorder = '3px solid #000'
          leftBorder = '3px solid #000'
          fontWeight = 'bold'
          break;
        case 'managerRow':
          cellColor = '#A44C0E'
          fontWeight = ''
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = ''
          textColor = ''
          topBorder = ''
          break;
        case 'totalRow':
          if(value.store !== 'Total'){
            cellColor = getCellBGColor(cur, comp, false, 'ignore', priceIncrease)
          }
          fontWeight = 'bold'
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = '3px solid #000'
          break;
        case 'storeRow':
          cellColor = getCellBGColor(cur, comp, false, 'ignore', priceIncrease)
          fontWeight = ''
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = ''
          break;
        case 'otherStoreRow':
          cellColor = 'white'
          fontWeight = ''
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = '#606060'
          topBorder = ''
          break;
        default:
          cellColor = 'white'
          fontWeight = 'bold'
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = 'black'
          topBorder = ''
      }
      break;
    case 'cACDineIn':
      cur = parseInt(toNum(value.cACDineIn))
      comp = parseInt(toNum(value.tipACDineIn))
      switch(rowType){
        case 'bottomBorderRow':
          bottomBorder = '3px solid #000'
          fontWeight = 'bold'
          break;
        case 'managerRow':
          cellColor = '#A44C0E'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = ''
          textColor = ''
          topBorder = ''
          break;
        case 'totalRow':
          if(value.store !== 'Total'){
            cellColor = getCellBGColor(cur, comp, false, 'ignore', priceIncrease)
          }
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = '3px solid #000'
          break;
        case 'storeRow':
          cellColor = getCellBGColor(cur, comp, false, 'ignore', priceIncrease)
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = ''
          break;
        case 'otherStoreRow':
          cellColor = 'white'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = '#606060'
          topBorder = ''
          break;
        default:
          cellColor = 'white'
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = 'black'
          topBorder = ''
      }
      break;
    case 'cACToGo':
      cur = parseInt(toNum(value.cACToGo))
      comp = parseInt(toNum(value.tipACToGo))
      switch(rowType){
        case 'bottomBorderRow':
          bottomBorder = '3px solid #000'
          fontWeight = 'bold'
          break;
        case 'managerRow':
          cellColor = '#A44C0E'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = ''
          textColor = ''
          topBorder = ''
          break;
        case 'totalRow':
          if(value.store !== 'Total'){
            cellColor = getCellBGColor(cur, comp, false, 'ignore', priceIncrease)
          }
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = '3px solid #000'
          break;
        case 'storeRow':
          cellColor = getCellBGColor(cur, comp, false, 'ignore', priceIncrease)
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = ''
          break;
        case 'otherStoreRow':
          cellColor = 'white'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = '#606060'
          topBorder = ''
          break;
        default:
          cellColor = 'white'
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = 'black'
          topBorder = ''
      }
      break;
    case 'cACDelivery':
      cur = parseInt(toNum(value.cACDelivery))
      comp = parseInt(toNum(value.tipACDelivery))
      switch(rowType){
        case 'bottomBorderRow':
          bottomBorder = '3px solid #000'
          fontWeight = 'bold'
          break;
        case 'managerRow':
          cellColor = '#A44C0E'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = ''
          textColor = ''
          topBorder = ''
          break;
        case 'totalRow':
          if(value.store !== 'Total'){
            cellColor = getCellBGColor(cur, comp, false, 'ignore', priceIncrease)
          }
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = '3px solid #000'
          break;
        case 'storeRow':
          cellColor = getCellBGColor(cur, comp, false, 'ignore', priceIncrease)
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = ''
          break;
        case 'otherStoreRow':
          cellColor = 'white'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = '#606060'
          topBorder = ''
          break;
        default:
          cellColor = 'white'
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = 'black'
          topBorder = ''
      }
      break;
    case 'cSPLHTotal':
      cur = parseInt(toNum(value.cSPLHTotal))
      comp = parseInt(toNum(value.tipSPLHTotal))
      diff = cur - comp
      highTH = 4
      lowTH = 0
      switch(rowType){
        case 'bottomBorderRow':
          bottomBorder = '3px solid #000'
          leftBorder = '3px solid #000'
          fontWeight = 'bold'
          break;
        case 'managerRow':
          cellColor = '#A44C0E'
          fontWeight = ''
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = ''
          textColor = ''
          topBorder = ''
          break;
        case 'totalRow':
          if(value.store !== 'Total'){
            // cellColor = getCellBGColor(cur, comp, false, 'ignore')
            if(diff >= highTH){
              cellColor = 'green'
            } else if(diff > lowTH && diff < highTH){
              cellColor = 'yellow'
            } else {
              cellColor = 'red'
            }
          }
          fontWeight = 'bold'
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = '3px solid #000'
          break;
        case 'storeRow':
          // cellColor = getCellBGColor(cur, comp, false, 'ignore')
          if(diff >= highTH){
            cellColor = 'green'
          } else if(diff > lowTH && diff < highTH){
            cellColor = 'yellow'
          } else {
            cellColor = 'red'
          }
          fontWeight = ''
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = ''
          break;
        case 'otherStoreRow':
          cellColor = 'white'
          fontWeight = ''
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = '#606060'
          topBorder = ''
          break;
        default:
          cellColor = 'white'
          fontWeight = 'bold'
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = 'black'
          topBorder = ''
      }
      break;
    case 'cFOH':
      cur = parseInt(toNum(value.cFOH))
      comp = parseInt(toNum(value.tipFOH))
      diff = cur - comp
      highTH = 4
      lowTH = 0
      switch(rowType){
        case 'bottomBorderRow':
          bottomBorder = '3px solid #000'
          fontWeight = 'bold'
          break;
        case 'managerRow':
          cellColor = '#A44C0E'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = ''
          textColor = ''
          topBorder = ''
          break;
        case 'totalRow':
          if(value.store !== 'Total'){
            // cellColor = getCellBGColor(cur, comp, false, 'ignore')
            if(diff >= highTH){
              cellColor = 'green'
            } else if(diff > lowTH && diff < highTH){
              cellColor = 'yellow'
            } else {
              cellColor = 'red'
            }
          }
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = '3px solid #000'
          break;
        case 'storeRow':
          // cellColor = getCellBGColor(cur, comp, false, 'ignore')
          if(diff >= highTH){
            cellColor = 'green'
          } else if(diff > lowTH && diff < highTH){
            cellColor = 'yellow'
          } else {
            cellColor = 'red'
          }
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = ''
          break;
        case 'otherStoreRow':
          cellColor = 'white'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = '#606060'
          topBorder = ''
          break;
        default:
          cellColor = 'white'
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = 'black'
          topBorder = ''
      }
      break;
    case 'cBOH':
      cur = parseInt(toNum(value.cBOH))
      comp = parseInt(toNum(value.tipBOH))
      diff = cur - comp
      highTH = 4
      lowTH = 0
      switch(rowType){
        case 'bottomBorderRow':
          bottomBorder = '3px solid #000'
          fontWeight = 'bold'
          break;
        case 'managerRow':
          cellColor = '#A44C0E'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = ''
          textColor = ''
          topBorder = ''
          break;
        case 'totalRow':
          if(value.store !== 'Total'){
            // cellColor = getCellBGColor(cur, comp, false, 'ignore')
            if(diff >= highTH){
              cellColor = 'green'
            } else if(diff > lowTH && diff < highTH){
              cellColor = 'yellow'
            } else {
              cellColor = 'red'
            }
          }
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = '3px solid #000'
          break;
        case 'storeRow':
          // cellColor = getCellBGColor(cur, comp, false, 'ignore')
          if(diff >= highTH){
            cellColor = 'green'
          } else if(diff > lowTH && diff < highTH){
            cellColor = 'yellow'
          } else {
            cellColor = 'red'
          }
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = ''
          break;
        case 'otherStoreRow':
          cellColor = 'white'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = '#606060'
          topBorder = ''
          break;
        default:
          cellColor = 'white'
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = 'black'
          topBorder = ''
      }
      break;
    case 'cOTTotal':
      cur = parseInt(toNum(value.cOTTotal))
      comp = parseInt(toNum(value.tipOTTotal))
      switch(rowType){
        case 'bottomBorderRow':
          bottomBorder = '3px solid #000'
          leftBorder = '3px solid #000'
          fontWeight = 'bold'
          break;
        case 'managerRow':
          cellColor = '#A44C0E'
          fontWeight = ''
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = ''
          textColor = ''
          topBorder = ''
          break;
        case 'totalRow':
          if(value.store !== 'Total'){
            cellColor = getCellBGColor(cur, comp, true, 'compare')
          }
          fontWeight = 'bold'
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = '3px solid #000'
          break;
        case 'storeRow':
          cellColor = getCellBGColor(cur, comp, true, 'compare')
          fontWeight = ''
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = ''
          break;
        case 'otherStoreRow':
          cellColor = 'white'
          fontWeight = ''
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = '#606060'
          topBorder = ''
          break;
        default:
          cellColor = 'white'
          fontWeight = 'bold'
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = 'black'
          topBorder = ''
      }
      break;
    case 'cOTFOH':
      cur = parseInt(toNum(value.cOTFOH))
      comp = parseInt(toNum(value.tipOTFOH))
      switch(rowType){
        case 'bottomBorderRow':
          bottomBorder = '3px solid #000'
          fontWeight = 'bold'
          break;
        case 'managerRow':
          cellColor = '#A44C0E'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = ''
          textColor = ''
          topBorder = ''
          break;
        case 'totalRow':
          if(value.store !== 'Total'){
            cellColor = getCellBGColor(cur, comp, true, 'compare')
          }
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = '3px solid #000'
          break;
        case 'storeRow':
          cellColor = getCellBGColor(cur, comp, true, 'compare')
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = ''
          break;
        case 'otherStoreRow':
          cellColor = 'white'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = '#606060'
          topBorder = ''
          break;
        default:
          cellColor = 'white'
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = 'black'
          topBorder = ''
      }
      break;
    case 'cOTBOH':
      cur = parseInt(toNum(value.cOTBOH))
      comp = parseInt(toNum(value.tipOTBOH))
      switch(rowType){
        case 'bottomBorderRow':
          bottomBorder = '3px solid #000'
          fontWeight = 'bold'
          break;
        case 'managerRow':
          cellColor = '#A44C0E'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = ''
          textColor = ''
          topBorder = ''
          break;
        case 'totalRow':
          if(value.store !== 'Total'){
            cellColor = getCellBGColor(cur, comp, true, 'compare')
          }
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = '3px solid #000'
          break;
        case 'storeRow':
          cellColor = getCellBGColor(cur, comp, true, 'compare')
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = ''
          break;
        case 'otherStoreRow':
          cellColor = 'white'
          fontWeight = ''
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = '#606060'
          topBorder = ''
          break;
        default:
          cellColor = 'white'
          fontWeight = 'bold'
          leftBorder = ''
          padding = 0
          textAlign = 'center'
          textColor = 'black'
          topBorder = ''
      }
      break;
    case 'cLaborPct':
      cur = parseInt(toNum(value.cLaborPct))
      comp = parseInt(toNum(value.tipLaborPct))
      switch(rowType){
        case 'bottomBorderRow':
          bottomBorder = '3px solid #000'
          leftBorder = '3px solid #000'
          fontWeight = 'bold'
          break;
        case 'managerRow':
          cellColor = '#A44C0E'
          fontWeight = ''
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = ''
          textColor = ''
          topBorder = ''
          break;
        case 'totalRow':
          if(value.store !== 'Total'){
            cellColor = getCellBGColor(cur, comp, true, 'compare')
          }
          // tempColor = getCellBGColor(cur, comp)
          // if(tempColor === 'red'){
          //   cellColor = 'green'
          // } else if(tempColor === 'green'){
          //   cellColor = 'red'
          // } else {
          //   cellColor = tempColor
          // }          
          fontWeight = 'bold'
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = '3px solid #000'
          break;
        case 'storeRow':
          cellColor = getCellBGColor(cur, comp, true, 'compare')
          fontWeight = ''
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = cellColor === 'red' || cellColor === 'green' ? 'white' : 'black'
          topBorder = ''
          break;
        case 'otherStoreRow':
            cellColor = 'white'
            // tempColor = getCellBGColor(cur, comp)
            // if(tempColor === 'red'){
            //   cellColor = 'green'
            // } else if(tempColor === 'green'){
            //   cellColor = 'red'
            // } else {
            //   cellColor = tempColor
            // }
            fontWeight = ''
            leftBorder = '3px solid #000'
            padding = 0
            textAlign = 'center'
            textColor = '#606060'
            topBorder = ''
            break;
        default:
          cellColor = 'white'
          fontWeight = 'bold'
          leftBorder = '3px solid #000'
          padding = 0
          textAlign = 'center'
          textColor = 'black'
          topBorder = ''
      }
      break;
    default:
  }


  setting = {
    textAlign: textAlign,
    fontSize: '.7rem',
    padding: padding,
    paddingLeft: paddingLeft,
    backgroundColor: cellColor,
    fontWeight: fontWeight,
    borderBottom: bottomBorder,
    borderLeft: leftBorder,
    borderTop: topBorder,
    color: textColor,
    position: 'relative',
    // width: width
  }
  
  return setting
}

function getCellBGColor(cur, comp, reverse, zero, threshold){
  let cellBGColor
  let highTH

  if(threshold === null || threshold === undefined){
    highTH = 0
  } else {
    highTH = threshold
  }

  if(zero === 'ignore'){
    if(cur === 0){
      cellBGColor = 'grey'
      return cellBGColor
    }
  }

  if(cur <= comp){
    if(reverse){
      cellBGColor = 'green'
    } else {
      cellBGColor = 'red'
    }
  } else if(cur > (comp + highTH)){
    if(reverse){
      cellBGColor = 'red'
    } else {
      cellBGColor = 'green'
    }
  } else if(cur > comp && cur <= (comp + highTH)){
    if(reverse){
      cellBGColor = 'yellow'
    } else {
      cellBGColor = 'yellow'
    }
  } else {
    cellBGColor = ''
  }

  // if(cur === comp){
  //   if(reverse){
  //     cellBGColor = 'green'
  //   } else {
  //     cellBGColor = 'red'
  //   }
  // } else if(cur > comp){
  //   if(reverse){
  //     cellBGColor = 'red'
  //   } else {
  //     cellBGColor = 'green'
  //   }
  // } else if(cur < comp){
  //   if(reverse){
  //     cellBGColor = 'green'
  //   } else {
  //     cellBGColor = 'red'
  //   }
  // } else {
  //   cellBGColor = ''
  // }
  return cellBGColor
}

function toNum(str){
  if(str == null){
    return str
  } else {
    let newStr = str.replace(/\D/g,'')
    return newStr
  }
}