import { styled } from '@mui/system'
import { TextField } from '@mui/material'
import Textfield from 'components/FormsUI/Textfield'
import { useField } from 'formik'

const TextField_GrmStyled = styled(TextField)(({theme}) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#a44c0e'
    }
  },
  '& .MuiOutlinedInput-root:focus': {
    '& fieldset': {
      borderColor: '#a44c0e'
    }
  },
  '& .MuiInputBase-input': {
    color: '#a44c0e',
  },
  '& .Mui-focused ': {
    borderColor: '#a44c0e'
  }
}))

const TextField_Grm = ({
  name,
  ...otherProps
}) => {

  const [field, meta] = useField(name)

  const configTextField = {
    ...field,
    ...otherProps,
    fullWidth: true,
    size: 'small',
    variant: 'outlined'
  }

  if(meta && meta.touched && meta.error){
    configTextField.error = true
    configTextField.helperText = meta.error
  }

  return (
    <TextField_GrmStyled {...configTextField} />
  )
}

export default TextField_Grm