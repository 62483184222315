import { Box, Stack } from '@mui/material'

import ThresholdProfiles from './ThresholdProfiles/ThresholdProfiles'
import ThresholdAssignments from './ThresholdAssignments/ThresholdAssignments'

export default function R0005Settings(props){
  console.log('R0005Settings', props)
  const componentWidth = 600

  return (
    <Box
      mx='auto'
      my={3}
      sx={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Stack
        direction='column'
        spacing={2}
      >
        <ThresholdProfiles 
          width={componentWidth}
        />
        <ThresholdAssignments 
          width={componentWidth}
        />
      </Stack>
    </Box>
  )
}