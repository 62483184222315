import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// @mui components
import { Box, Dialog, DialogTitle, DialogContent, Grid, InputAdornment, Stack
} from '@mui/material'

// formik components
import { Formik, useFormikContext,  Form } from 'formik'
import Select_Rd from 'components/FormsUI/Select/Select_Rd'
import Select from 'components/FormsUI/Select'
import Textfield from 'components/FormsUI/Textfield'
import * as Yup from 'yup'

// Material Dashboard Pro 2 components
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export default function DialogEditAssignment(props){

  console.log('DialogEditAssignment2', props)

  const thresholds = props.thresholds

  let netSalesOptions = []
  let avgCheckOptions = []
  let splhTotalOptions = []
  let splhBOHOptions = []
  let splhFOHOptions = []

  for(let i = 0; i < thresholds.length; i++){

    const options = thresholds[i].thresholds.reduce(
      (obj, item) => Object.assign(obj, { [item._id]: item.name}), {}
    )
    
    switch (thresholds[i].type) {
      case 'netSales':
        netSalesOptions = {...options}
        break;
      case 'avgCheck':
        avgCheckOptions = {...options}
        break;
      case 'splhTotal':
        splhTotalOptions = {...options}
        break;
      case 'splhBOH':
        splhBOHOptions = {...options}
        break;
      case 'splhFOH':
        splhFOHOptions = {...options}
        break;
      default:
        console.log(`Invalid Threshold type: ${thresholds[i].type}`)
    }
  }

  function handleSubmit(values){
    console.log('values', values)
    props.submit({
      oldData: props.data.original,
      newData: values
    })
  }
  
  const original = props.data.original
    
  const INITIAL_FORM_STATE = {
    netSales: original.netSalesId,
    avgCheck: original.avgCheckId,
    splhTotal: original.splhTotalId,
    splhBOH: original.splhBOHId,
    splhFOH: original.splhFOHId,
  }

  const FORM_VALIDATION = Yup.object().shape({
    netSales: Yup.string()
    .required('Required'),
    avgCheck: Yup.string()
    .required('Required'),
    splhTotal: Yup.string()
    .required('Required'),
    splhBOH: Yup.string()
    .required('Required'),
    splhFOH: Yup.string()
    .required('Required'),
  })

  const getTH = (thType, thId, thField) => {
    const thIdx = thresholds.findIndex(x => x.type === thType)
    const thArr = thresholds[thIdx].thresholds
    const thArrIdx = thArr.findIndex(x => x._id === thId)
    if(thArrIdx !== -1){
      if(thField === 'low'){
        return thArr[thArrIdx].lowValue
      } else {
        return thArr[thArrIdx].highValue
      }
    } else {
      return null
    }
  }

  return (
    <Dialog
      open={ props.open }
    >
      <DialogTitle>
        Select Thresholds for {original.store}
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={INITIAL_FORM_STATE}
          validationSchema={FORM_VALIDATION}
          enableReinitialize={ true }
          onSubmit={ (values) => { handleSubmit(values) }}
        >
          {({ isValid, dirty, values }) => (
            <Form>
              <Grid container spacing={2} alignItems='center'>
                <Box
                  component={Grid}
                  item
                  xs={6}
                  sm={4}
                  display={{
                    // xs: 'none',
                    sm:'block',
                    textAlign: 'center'
                  }}
                >
                </Box>
                <Box
                  component={Grid}
                  item
                  xs={6}
                  sm={4}
                  display={{
                    xs: 'none',
                    sm:'block',
                    textAlign: 'center'
                  }}
                >
                  Threshold
                </Box>
                <Box
                  component={Grid}
                  item
                  xs={3}
                  sm={2}
                  display={{
                    // xs: 'none',
                    sm:'block',
                    textAlign: 'center'
                  }}
                >
                  Low
                </Box>
                <Box
                  component={Grid}
                  item
                  xs={3}
                  sm={2}
                  display={{
                    // xs: 'none',
                    sm:'block',
                    textAlign: 'center'
                  }}
                >
                  High
                </Box>
                <Grid 
                  item 
                  xs={12} 
                  sm={4}
                >
                  <MDTypography variant='h6'>
                    Net Sales (%)
                  </MDTypography>
                </Grid>
                <Grid 
                  item 
                  xs={6} 
                  sm={4}
                  display='flex'
                  justifyContent='center'
                >
                  <Select_Rd 
                    name='netSales'
                    label='Net Sales'
                    options={netSalesOptions}
                  />
                </Grid>
                <Grid 
                  item 
                  xs={3} 
                  sm={2}
                  display='flex'
                  justifyContent='center'
                >
                  <MDTypography variant='body2'>
                    {/* {original.netSalesLow + '%'} */}
                    {
                      getTH('netSales', values.netSales, 'low' ) + '%'
                    }
                  </MDTypography>
                </Grid>
                <Grid 
                  item 
                  xs={3} 
                  sm={2}
                  display='flex'
                  justifyContent='center'
                >
                  <MDTypography variant='body2'>
                  {
                      getTH('netSales', values.netSales, 'high' ) + '%'
                    }
                  </MDTypography>
                </Grid>

                <Grid
                  item 
                  xs={12} 
                  sm={4}
                >
                  <MDTypography variant='h6'>
                    Average Check ($)
                  </MDTypography>
                </Grid>
                <Grid 
                  item 
                  xs={6} 
                  sm={4}
                  display='flex'
                  justifyContent='center'
                >
                  <Select_Rd 
                    name='avgCheck'
                    label='Average Check'
                    options={avgCheckOptions}
                  />
                </Grid>
                <Grid 
                  item 
                  xs={3} 
                  sm={2}
                  display='flex'
                  justifyContent='center'
                >
                  <MDTypography variant='body2'>
                    {formatter.format(
                      getTH('avgCheck', values.avgCheck, 'low' ) 
                    /100)}
                  </MDTypography>
                </Grid>
                <Grid 
                  item 
                  xs={3} 
                  sm={2}
                  display='flex'
                  justifyContent='center'
                >
                  <MDTypography variant='body2'>
                    {formatter.format(
                      getTH('avgCheck', values.avgCheck, 'high' ) 
                    /100)}
                  </MDTypography>
                </Grid>

                <Grid 
                  item 
                  xs={12} 
                  sm={4}
                >
                  <MDTypography variant='h6'>
                    SPLH Total ($)
                  </MDTypography>
                </Grid>
                
                <Grid 
                  item 
                  xs={6} 
                  sm={4}
                  display='flex'
                  justifyContent='center'
                >
                  <Select_Rd 
                    name='splhTotal'
                    label='SPLH Total'
                    options={splhTotalOptions}
                  />
                </Grid>
                <Grid 
                  item 
                  xs={3} 
                  sm={2}
                  display='flex'
                  justifyContent='center'
                >
                  <MDTypography variant='body2'>
                    {formatter.format(
                      getTH('splhTotal', values.splhTotal, 'low' ) 
                    /100)}
                  </MDTypography>
                </Grid>
                <Grid 
                  item 
                  xs={3} 
                  sm={2}
                  display='flex'
                  justifyContent='center'
                >
                  <MDTypography variant='body2'>
                    {formatter.format(
                      getTH('splhTotal', values.splhTotal, 'high' ) 
                    /100)}
                  </MDTypography>
                </Grid>

                <Grid 
                  item 
                  xs={12} 
                  sm={4}
                >
                  <MDTypography variant='h6'>
                    SPLH BOH ($)
                  </MDTypography>
                </Grid>
                
                <Grid 
                  item 
                  xs={6} 
                  sm={4}
                  display='flex'
                  justifyContent='center'
                >
                  <Select_Rd 
                    name='splhBOH'
                    label='SPLH BOH'
                    options={splhBOHOptions}
                  />
                </Grid>
                <Grid 
                  item 
                  xs={3} 
                  sm={2}
                  display='flex'
                  justifyContent='center'
                >
                  <MDTypography variant='body2'>
                    {formatter.format(
                      getTH('splhBOH', values.splhBOH, 'low' ) 
                    /100)}
                  </MDTypography>
                </Grid>
                <Grid 
                  item 
                  xs={3} 
                  sm={2}
                  display='flex'
                  justifyContent='center'
                >
                  <MDTypography variant='body2'>
                    {formatter.format(
                      getTH('splhBOH', values.splhBOH, 'high' ) 
                    /100)}
                  </MDTypography>
                </Grid>

                <Grid 
                  item 
                  xs={12} 
                  sm={4}
                >
                  <MDTypography variant='h6'>
                    SPLH FOH ($)
                  </MDTypography>
                </Grid>
                
                <Grid 
                  item 
                  xs={6} 
                  sm={4}
                  display='flex'
                  justifyContent='center'
                >
                  <Select_Rd 
                    name='splhFOH'
                    label='SPLH FOH'
                    options={splhFOHOptions}
                  />
                </Grid>
                <Grid 
                  item 
                  xs={3} 
                  sm={2}
                  display='flex'
                  justifyContent='center'
                >
                  <MDTypography variant='body2'>
                    {formatter.format(
                      getTH('splhFOH', values.splhFOH, 'low' ) 
                    /100)}
                  </MDTypography>
                </Grid>
                <Grid 
                  item 
                  xs={3} 
                  sm={2}
                  display='flex'
                  justifyContent='center'
                >
                  <MDTypography variant='body2'>
                    {formatter.format(
                      getTH('splhFOH', values.splhFOH, 'high' ) 
                    /100)}
                  </MDTypography>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction='row'
                    spacing={2}
                    justifyContent='flex-end'
                    alignItems='center'
                  >
                    <MDButton 
                      variant='contained'
                      color='info'
                      onClick={ props.close }
                    >
                      Cancel
                    </MDButton>
                    <MDButton 
                      variant='contained'
                      color='info' 
                      type='submit' 
                      disabled={!(dirty && isValid)}
                    >
                      Submit
                    </MDButton>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

// AddRoleDialog.propTypes = {
//   close: PropTypes.func,
//   existingRoles: PropTypes.array,
//   open: PropTypes.bool,
//   submit: PropTypes.func,
// }