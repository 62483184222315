import PropTypes from "prop-types";

import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import GradingIcon from '@mui/icons-material/Grading';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import TableChartIcon from '@mui/icons-material/TableChart'

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography'

import { useUser } from 'components/RDAuthContext'

export default function R0003Actions(props){

  const { permissions, user } = useUser()
  console.log('permissions', permissions)
  console.log('user', user)
  const role = user.role
  console.log('role', role)

  let viewIncentives = false

  const incentiveIndex = permissions.findIndex(x => x.report === 'R0003_Incentive')
  if(incentiveIndex !== -1){
    if(permissions[incentiveIndex].viewPermissions !== 'no-permission'){
      viewIncentives = true
    } else {
      viewIncentives = false
    }
  }

  return (    
    <MDBox 
      textAlign='center'       
      sx={{ bgcolor: '#f9e9d3' }}
    >
      <MDBox textAlign='center' sx={{ backgroundColor: '#29261F'}} py={2}>      
        <MDTypography variant='h2'
          sx={{
            color: '#CF9E0B'
          }}
        >
          SPLH Report Menu
        </MDTypography>
      </MDBox>
      <MDBox textAlign='left' mx={6} my={2} sx={{ bgcolor: '#f9e9d3'}}>
        <List>
          { role === 'Admin' && 
            <ListItem disablePadding >
              <ListItemButton onClick={() => props.setPage('projections-admin')} >
                <ListItemIcon 
                  sx={{ color: '#a44c0e' }}
                >
                  <SettingsIcon fontSize='medium'/> 
                </ListItemIcon>
                <ListItemText primary='Set Weekly Projections - Admin' 
                  sx={{
                    '& .MuiListItemText-primary': {
                      color: '#a44c0e'
                    }                    
                  }}
                />
              </ListItemButton>
            </ListItem>
          }
          <ListItem disablePadding >
            <ListItemButton onClick={() => props.setPage('projections')} >
              <ListItemIcon 
                sx={{ color: '#a44c0e' }}
              >
                <SettingsIcon fontSize='medium'/> 
              </ListItemIcon>
              <ListItemText primary='Set Weekly Projections' 
                sx={{
                  '& .MuiListItemText-primary': {
                    color: '#a44c0e'
                  }                    
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding >
            <ListItemButton onClick={() => props.setPage('training')} >
              <ListItemIcon 
                sx={{ color: '#a44c0e' }}
              >
                <MenuBookIcon fontSize='medium'/> 
              </ListItemIcon>
              <ListItemText primary='Training Hours' 
                sx={{
                  '& .MuiListItemText-primary': {
                    color: '#a44c0e'
                  }                    
                }}
              />
            </ListItemButton>
          </ListItem>
          { viewIncentives && 
            <ListItem disablePadding >
              <ListItemButton onClick={() => props.setPage('incentive')} >
                <ListItemIcon 
                  sx={{ color: '#a44c0e' }}
                >
                  <GradingIcon fontSize='medium'/> 
                </ListItemIcon>
                <ListItemText primary='SPLH Incentive Report' 
                  sx={{
                    '& .MuiListItemText-primary': {
                      color: '#a44c0e'
                    }                    
                  }}
                />
              </ListItemButton>
            </ListItem>
          }
          <ListItem disablePadding>
            <ListItemButton onClick={() => props.setPage('report')} >
              <ListItemIcon 
                sx={{ color: '#a44c0e' }}
              >
                <TableChartIcon fontSize='medium' />
              </ListItemIcon>
              <ListItemText 
                primary='SPLH Report'
                sx={{
                  '& .MuiListItemText-primary': {
                    color: '#a44c0e'
                  }                    
                }} 
              />
            </ListItemButton>
          </ListItem>
        </List>
      </MDBox>
    </MDBox>    
  )
}

R0003Actions.propTypes = {
  setPage: PropTypes.func
}