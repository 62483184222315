import PropTypes from 'prop-types'
import MDBox from 'components/MDBox'

export default function RDStickyBodyCell({
  children,
  style, 
  width,
  styleFunc,
  options,
  ...rest
}){
  const row = children.props.row
  const cell = children.props.cell
  const styleOptions = options.style
    
  const cellStyle = styleFunc(row, cell, styleOptions)
  
  return (
    <MDBox
      component='td'
      display='flex'  
      alignItems='center'   
      justifyContent={cellStyle.textAlign}
      style={cellStyle}
      width={width}
    >
      <MDBox
        {...rest}
        textAlign={cellStyle.textAlign}
        color={cellStyle.color}
        // sx={{
        //   fontSize: '.62rem',
        // }}
      >
        {children}
      </MDBox>
    </MDBox>
  )

}

RDStickyBodyCell.defaultProps = {
  width: 'auto'
}

RDStickyBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  options: PropTypes.object,
  style: PropTypes.object,
  styleFunc: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}