// @mui icons
import Icon from '@mui/material/Icon'

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";


import Checkpoints from 'clients/afa92/views/Checkpoints'
import LocationTracker from 'clients/afa92/views/LocationTracker'

import UserAdmin from 'clients/admin/UserAdmin/UserAdmin'

/************************************************
 * Client-specific pages
 ************************************************/
import PlaceHolder from 'clients/PlaceHolder'


const reportViews = {
  reportPH: {
    name: 'Place Holder',
    key: 'repPH',
    route: '/reports/repPH',
    component: <PlaceHolder />
  }
}

const adminViews = {
  userAdmin: {
    name: 'User Admin',
    key: 'user-admin',
    route: '/userAdmin',
    component: <UserAdmin />
  }
}

export default function afa92Routes(displayName, icon, role, permissions) {
  let dashboardViewArr = [
    {
      type: 'collapse',
      name: displayName,
      key: displayName,
      href: '#',
      icon: <MDAvatar src={icon} alt={displayName} size='sm' />,
      noCollapse: true,
    },
    { type: 'divider', key: 'divider-1' },
    {
      type: 'collapse',
      name: 'Dashboards',
      key: 'dashboards',
      icon: <Icon fontSize='medium'>dashboard</Icon>,
      collapse: [
        {
          name: "Location Tracker",
          key: 'location',
          route: '/dashboards/home',
          component: <LocationTracker />
        },
        {
          name: "Checkpoints",
          key: 'checkpoints',
          route: '/dashboards/checkpoints',
          component: <Checkpoints />
        }
      ]
    }
  ]
  
  let reportViewArr = []
  let adminViewArr = []

  // Setup Admin Views
  switch(role){
    case 'Admin':
      adminViewArr[0] = adminViews.userAdmin
      break;
    default:
      // No action, leave as empty array
  }

  // Setup Report Views
  let arrIdx = 0
  for(let i = 0; i < permissions.length; i++){
    let report = permissions[i].report
    report = report.toLowerCase()
    if(report in reportViews){
      let viewPermissions = permissions[i].viewPermissions
      if(viewPermissions !== 'no-permission'){
        reportViewArr[arrIdx] = reportViews[report]
        arrIdx += 1
      }
    }
  }

  // Create Routes
  // let routes = []
  let routes = dashboardViewArr

  if(reportViewArr.length > 0){
    let reportRoutes = {
      type: 'collapse',
      name: 'Reports',
      key: 'reports',
      icon: <Icon fontSize='medium'>format_list_numbered_icon</Icon>,
      collapse: reportViewArr
    }
    routes.push(reportRoutes)
  }

  if(adminViewArr.length > 0){
    let adminRoutes = {
      type: 'collapse',
      name: "Admin Tools",
      key: 'admin-tools',
      icon: <Icon fontSize='medium'>content_paste</Icon>,
      collapse: adminViewArr,
    }
    routes.push(adminRoutes)
  }

  let finalDivider = {
    type: 'divider',
    key: 'final-divider'
  }
  routes.push(finalDivider)

  return routes
}