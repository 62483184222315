import palette from './palette' 

const components = {
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: palette.autocomplete.header.border,
        },
        '&:hover':{
          backgroundColor: palette.autocomplete.header.hoverBackground,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.autocomplete.header.hoverBorder
          }  
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: palette.header.hover
        },
        // '& .MuiAutocomplete-inputFocused': {
        //   backgroundColor: palette.header.contrastText
        // },
        '& .MuiAutocomplete-endAdornment': {
          '& .MuiIconButton-root': {
            color: palette.header.contrastText
          }
        },
        '& .MuiFormLabel-root': {
          // Label Text Color
          color: palette.header.contrastText
        },
        '& .MuiInputBase-input': {
          // Input Text Color
          color: palette.header.contrastText
        }
      }
    }
  },
  // MuiLink: {
  //   styleOverrides: {
  //     root: {} // Global styling
  //   },
  //   variants: [
  //     {
  //       props: { name: 'csv-link' },
  //       style: {
  //         color: '#fff',
  //         // color: palette.headerButton.contrastText,
          
  //       }
  //     }
  //   ]
  // }
}

export default components