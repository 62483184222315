import React from 'react'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

const CellTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}  placement='left' />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'yellow',
    color: 'black',
    fontWeight: 'bold',
    fontSize: '.7rem'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'yellow'
  }
}))

const fontSize = '.7rem'

const headerStyleLight = {
  backgroundColor: '#E0E0E0',
  color: '#181818',
  fontSize: fontSize,
  padding: '0px 5px',
  margin: 'auto'
}

const headerStyleDark = {
  backgroundColor: '#BDBDBD',
  color: '#181818',
  fontSize: fontSize,
  padding: '0px 5px',
  margin: 'auto'
}


export const columns = [
  {
    Header: 's',
    headerStyle: {
      backgroundColor: '#E0E0E0',
      color: '#E0E0E0',
      fontSize: fontSize,
      padding: '0px 5px'
    },
    width: '100px',
    columns: [
      {
        Header: 'Store',
        accessor: 'store',
        align: 'center',
        alignCell: 'left',
        width: '100px',
        headerStyle: {
          backgroundColor: '#E0E0E0',
          color: '#181818',
          borderBottom: '1px solid #000',
          fontSize: fontSize,
          padding: '0px 5px',
          margin: 'auto'
        },
        cellStyle: {
          fontSize: '.7rem',
          backgroundColor: 'blue',
          textAlign: 'left',
        },
      }
    ]
  },
  {
    Header: 'Net Sales',
    align: 'center',
    width: 438,
    headerStyle: {
      ...headerStyleDark,
      borderLeft: '3px solid #000',
      borderBottom: '1px solid #000'
    },
    columns: [
      {
        Header: 'PY Net',
        accessor: 'pNSTotal',
        align: 'center',
        width: 65,
        headerStyle: {
          ...headerStyleDark,
          borderLeft: '3px solid #000',
          borderBottom: '1px solid #000'
        },
        cellStyle: {
          fontSize: '.7rem'
        },
      },
      {
        Header: 'CY Net',
        accessor: 'cNSTotal',
        align: 'center',
        width: 65,
        headerStyle: {
          ...headerStyleDark,
          borderBottom: '1px solid #000'
        },
        cellStyle: {
          fontSize: '.7rem'
        },
      },
      {
        Header: '%',
        accessor: 'pct',
        align: 'center',
        headerStyle: {
          ...headerStyleDark,
          borderBottom: '1px solid #000'
        },
        cellStyle: {
          fontSize: '.7rem'
        },
      },
      {
        Header: 'Dine In',
        accessor: 'cNSDineIn',
        align: 'center',
        width: 65,
        headerStyle: {
          ...headerStyleDark,
          borderLeft: '3px solid #000',
          borderBottom: '1px solid #000'
        },
        cellStyle: {
          fontSize: '.7rem'
        },
        Cell: ({row}) => {
          const data = row.original
          switch(data.store){
            case 'SSS %':
            case 'Stores Increase':
            case 'SSS % WTD':
              return (
                <div>{data.cNSDineIn}</div>
              )
            default:
              return (
                <CellTooltip title={data.tipNSDineIn} >
                  <div>{data.cNSDineIn}</div>
                </CellTooltip> 
              )
          }
        }
      },
      {
        Header: 'To Go',
        accessor: 'cNSToGo',
        align: 'center',
        width: 65,
        headerStyle: {
          ...headerStyleDark,
          borderBottom: '1px solid #000'
        },
        cellStyle: {
          fontSize: '.7rem'
        },
        Cell: ({row}) => {
          const data = row.original
          switch(data.store){
            case 'SSS %':
            case 'Stores Increase':
            case 'SSS % WTD':
              return (
                <div>{data.cNSToGo}</div>
              )
            default:
              return (
                <CellTooltip title={data.tipNSToGo} >
                  <div>{data.cNSToGo}</div>
                </CellTooltip> 
              )
          }
        }
      },
      {
        Header: 'Net Del',
        accessor: 'cNSDelivery',
        align: 'center',
        width: 65,
        headerStyle: {
          ...headerStyleDark,
          borderBottom: '1px solid #000'
        },
        cellStyle: {
          fontSize: '.7rem'
        },
        Cell: ({row}) => {
          const data = row.original
          switch(data.store){
            case 'SSS %':
            case 'Stores Increase':
            case 'SSS % WTD':
              return (
                <div>{data.cNSDelivery}</div>
              )
            default:
              return (
                <CellTooltip title={data.tipNSDelivery} >
                  <div>{data.cNSDelivery}</div>
                </CellTooltip> 
              )
          }
        }
      },
      {
        Header: 'Grs Del',
        accessor: 'cGSDelivery',
        align: 'center',
        width: 65,
        headerStyle: {
          ...headerStyleDark,
          borderBottom: '1px solid #000'
        },
        cellStyle: {
          fontSize: '.7rem'
        },
        Cell: ({row}) => {
          const data = row.original
          switch(data.store){
            case 'SSS %':
            case 'Stores Increase':
            case 'SSS % WTD':
              return (
                <div>{data.cGSDelivery}</div>
              )
            default:
              return (
                <CellTooltip title={data.tipGSDelivery} >
                  <div>{data.cGSDelivery}</div>
                </CellTooltip> 
              )
          }
        }
      },
    ]
  },
  {
    Header: 'Check Counts',
    align: 'center',
    width: '200px',
    headerStyle: {
      ...headerStyleLight,
      borderLeft: '3px solid #000',
      borderBottom: '1px solid #000',
    },
    columns: [
      {
        Header: 'Total',
        accessor: 'cCCTotal',
        align: 'center',
        headerStyle: {
          ...headerStyleLight,
          borderLeft: '3px solid #000',
          borderBottom: '1px solid #000',
        },
        cellStyle: {
          fontSize: '.7rem'
        },
        Cell: ({row}) => {
          const data = row.original
          switch(data.store){
            case 'SSS %':
            case 'Stores Increase':
            case 'SSS % WTD':
              return (
                <div>{data.cCCTotal}</div>
              )
            default:
              return (
                <CellTooltip title={data.tipCCTotal} >
                  <div>{data.cCCTotal}</div>
                </CellTooltip> 
              )
          }
        }
      },
      {
        Header: 'Dine In',
        accessor: 'cCCDineIn',
        align: 'center',
        headerStyle: {
          ...headerStyleLight,
          borderBottom: '1px solid #000',
        },
        cellStyle: {
          fontSize: '.7rem'
        },
        Cell: ({row}) => {
          const data = row.original
          switch(data.store){
            case 'SSS %':
            case 'Stores Increase':
            case 'SSS % WTD':
              return (
                <div>{data.cCCDineIn}</div>
              )
            default:
              return (
                <CellTooltip title={data.tipCCDineIn} >
                  <div>{data.cCCDineIn}</div>
                </CellTooltip> 
              )
          }
        }
      },
      {
        Header: 'To Go',
        accessor: 'cCCToGo',
        align: 'center',
        headerStyle: {
          ...headerStyleLight,
          borderBottom: '1px solid #000',
        },
        cellStyle: {
          fontSize: '.7rem'
        },
        Cell: ({row}) => {
          const data = row.original
          switch(data.store){
            case 'SSS %':
            case 'Stores Increase':
            case 'SSS % WTD':
              return (
                <div>{data.cCCToGo}</div>
              )
            default:
              return (
                <CellTooltip title={data.tipCCToGo} >
                  <div>{data.cCCToGo}</div>
                </CellTooltip> 
              )
          }
        }
      },
      {
        Header: 'Del',
        accessor: 'cCCDelivery',
        align: 'center',
        headerStyle: {
          ...headerStyleLight,
          borderBottom: '1px solid #000',
        },
        cellStyle: {
          fontSize: '.7rem'
        },
        Cell: ({row}) => {
          const data = row.original
          switch(data.store){
            case 'SSS %':
            case 'Stores Increase':
            case 'SSS % WTD':
              return (
                <div>{data.cCCDelivery}</div>
              )
            default:
              return (
                <CellTooltip title={data.tipCCDelivery} >
                  <div>{data.cCCDelivery}</div>
                </CellTooltip> 
              )
          }
        }
      }
    ]
  },
  {
    Header: 'Average Check',
    align: 'center',
    width: '200px',
    headerStyle: {
      ...headerStyleDark,
      borderLeft: '3px solid #000',
      borderBottom: '1px solid #000',
    },
    columns: [
      {
        Header: 'Total',
        accessor: 'cACTotal',
        align: 'center',
        headerStyle: {
          ...headerStyleDark,
          borderLeft: '3px solid #000',
          borderBottom: '1px solid #000',
        },
        cellStyle: {
          fontSize: '.7rem'
        },
        Cell: ({row}) => {
          const data = row.original
          switch(data.store){
            case 'SSS %':
            case 'Stores Increase':
            case 'SSS % WTD':
              return (
                <div>{data.cACTotal}</div>
              )
            default:
              return (
                <CellTooltip title={data.tipACTotal} >
                  <div>{data.cACTotal}</div>
                </CellTooltip> 
              )
          }
        }
      },
      {
        Header: 'Dine In',
        accessor: 'cACDineIn',
        align: 'center',
        headerStyle: {
          ...headerStyleDark,
          borderBottom: '1px solid #000',
        },
        cellStyle: {
          fontSize: '.7rem'
        },
        Cell: ({row}) => {
          const data = row.original
          switch(data.store){
            case 'SSS %':
            case 'Stores Increase':
            case 'SSS % WTD':
              return (
                <div>{data.cACDineIn}</div>
              )
            default:
              return (
                <CellTooltip title={data.tipACDineIn} >
                  <div>{data.cACDineIn}</div>
                </CellTooltip> 
              )
          }
        }
      },
      {
        Header: 'To Go',
        accessor: 'cACToGo',
        align: 'center',
        headerStyle: {
          ...headerStyleDark,
          borderBottom: '1px solid #000',
        },
        cellStyle: {
          fontSize: '.7rem'
        },
        Cell: ({row}) => {
          const data = row.original
          switch(data.store){
            case 'SSS %':
            case 'Stores Increase':
            case 'SSS % WTD':
              return (
                <div>{data.cACToGo}</div>
              )
            default:
              return (
                <CellTooltip title={data.tipACToGo} >
                  <div>{data.cACToGo}</div>
                </CellTooltip> 
              )
          }
        }
      },
      {
        Header: 'Del',
        accessor: 'cACDelivery',
        align: 'center',
        headerStyle: {
          ...headerStyleDark,
          borderBottom: '1px solid #000',
        },
        cellStyle: {
          fontSize: '.7rem'
        },
        Cell: ({row}) => {
          const data = row.original
          switch(data.store){
            case 'SSS %':
            case 'Stores Increase':
            case 'SSS % WTD':
              return (
                <div>{data.cACDelivery}</div>
              )
            default:
              return (
                <CellTooltip title={data.tipACDelivery} >
                  <div>{data.cACDelivery}</div>
                </CellTooltip> 
              )
          }
        }
      },
    ]
  },
  {
    Header: 'SPLH',
    align: 'center',
    width: '150px',
    headerStyle: {
      ...headerStyleLight,
      borderLeft: '3px solid #000',
      borderBottom: '1px solid #000',
    },
    columns: [
      {
        Header: 'Total',
        accessor: 'cSPLHTotal',
        align: 'center',
        headerStyle: {
          ...headerStyleLight,
          borderLeft: '3px solid #000',
          borderBottom: '1px solid #000',
        },
        cellStyle: {
          fontSize: '.7rem'
        },
        Cell: ({row}) => {
          const data = row.original
          switch(data.store){
            case 'SSS %':
            case 'Stores Increase':
            case 'SSS % WTD':
              return (
                <div>{data.cSPLHTotal}</div>
              )
            default:
              return (
                <CellTooltip title={data.tipSPLHTotal} >
                  <div>{data.cSPLHTotal}</div>
                </CellTooltip> 
              )
          }
        }
      },
      {
        Header: 'FOH',
        accessor: 'cFOH',
        align: 'center',
        headerStyle: {
          ...headerStyleLight,
          borderBottom: '1px solid #000',
        },
        cellStyle: {
          fontSize: '.7rem'
        },
        Cell: ({row}) => {
          const data = row.original
          switch(data.store){
            case 'SSS %':
            case 'Stores Increase':
            case 'SSS % WTD':
              return (
                <div>{data.cFOH}</div>
              )
            default:
              return (
                <CellTooltip title={data.tipFOH} >
                  <div>{data.cFOH}</div>
                </CellTooltip> 
              )
          }
        }
      },
      {
        Header: 'BOH',
        accessor: 'cBOH',
        align: 'center',
        headerStyle: {
          ...headerStyleLight,
          borderBottom: '1px solid #000',
        },
        cellStyle: {
          fontSize: '.7rem'
        },
        Cell: ({row}) => {
          const data = row.original
          switch(data.store){
            case 'SSS %':
            case 'Stores Increase':
            case 'SSS % WTD':
              return (
                <div>{data.cBOH}</div>
              )
            default:
              return (
                <CellTooltip title={data.tipBOH} >
                  <div>{data.cBOH}</div>
                </CellTooltip> 
              )
          }
        }
      },
    ]
  },
  {
    Header: 'Overtime',
    align: 'center',
    width: '150px',
    headerStyle: {
      ...headerStyleDark,
      borderLeft: '3px solid #000',
      borderBottom: '1px solid #000',
    },
    columns: [
      {
        Header: 'Total',
        accessor: 'cOTTotal',
        align: 'center',
        headerStyle: {
          ...headerStyleDark,
          borderLeft: '3px solid #000',
          borderBottom: '1px solid #000',
        },
        cellStyle: {
          fontSize: '.7rem'
        },
        Cell: ({row}) => {
          const data = row.original
          switch(data.store){
            case 'SSS %':
            case 'Stores Increase':
            case 'SSS % WTD':
              return (
                <div>{data.cOTTotal}</div>
              )
            default:
              return (
                <CellTooltip title={data.tipOTTotal} >
                  <div>{data.cOTTotal}</div>
                </CellTooltip> 
              )
          }
        }
      },
      {
        Header: 'FOH',
        accessor: 'cOTFOH',
        align: 'center',
        headerStyle: {
          ...headerStyleDark,
          borderBottom: '1px solid #000',
        },
        cellStyle: {
          fontSize: '.7rem'
        },
        Cell: ({row}) => {
          const data = row.original
          switch(data.store){
            case 'SSS %':
            case 'Stores Increase':
            case 'SSS % WTD':
              return (
                <div>{data.cOTFOH}</div>
              )
            default:
              return (
                <CellTooltip title={data.tipOTFOH} >
                  <div>{data.cOTFOH}</div>
                </CellTooltip> 
              )
          }
        }
      },
      {
        Header: 'BOH',
        accessor: 'cOTBOH',
        align: 'center',
        headerStyle: {
          ...headerStyleDark,
          borderBottom: '1px solid #000',
        },
        cellStyle: {
          fontSize: '.7rem'
        },
        Cell: ({row}) => {
          const data = row.original
          switch(data.store){
            case 'SSS %':
            case 'Stores Increase':
            case 'SSS % WTD':
              return (
                <div>{data.cOTBOH}</div>
              )
            default:
              return (
                <CellTooltip title={data.tipOTBOH} >
                  <div>{data.cOTBOH}</div>
                </CellTooltip> 
              )
          }
        }
      },
    ]
  },
  {
    Header: 'l',
    width: 60,
    headerStyle: {
      backgroundColor: '#E0E0E0',
      color: '#E0E0E0',
      borderLeft: '3px solid #000',
      marginRight: 'auto',
      fontSize: fontSize
    },
    
    columns: [
      {
        Header: 'Labor %',
        align: 'center',
        alignCell: 'center',
        accessor: 'cLaborPct',
        width: 60,
        headerStyle: {
          ...headerStyleLight,
          borderLeft: '3px solid #000',
          borderBottom: '1px solid #000',
          fontSize: '.7rem'
        },
        cellStyle: {
          fontSize: '.7rem'
        },
        Cell: ({row}) => {
          const data = row.original
          switch(data.store){
            case 'SSS %':
            case 'Stores Increase':
            case 'SSS % WTD':
              return (
                <div>{data.cLaborPct}</div>
              )
            default:
              return (
                <CellTooltip title={data.tipLaborPct} >
                  <div>
                    {data.cLaborPct}
                  </div>
                </CellTooltip> 
              )
          }
        }
      }
    ]
  },  
]