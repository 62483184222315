import React, { useEffect, useState } from 'react'

// @mui material components
import { Grid } from '@mui/material'

// AWS Amplify components
import { API } from 'aws-amplify'

// GraphQL
import { listRoles } from '../../../graphql/queries'


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'layouts/DashboardLayout';

// RadixDash components
import DashboardNavbar from 'components/RDNavbars/DashboardNavbar';
import Progress from 'components/RDProgress'
import RDFooter from  'components/RDFooter'
import RolesTable from './RolesTable'
import { useUser } from 'components/RDAuthContext'

export default function RolesAdmin(){
  const { user } = useUser()
  const clientId = user.client
  
  const [rolesLoading, setRolesLoading] = useState(true)
  const [roles, setRoles] = useState([])
  
  // Set rolesList when component loads
  useEffect(() => {
    getRoles()
  }, [])

  async function getRoles() {
    console.log('getRoles()...')
    try {
      const queryResponse = await API.graphql({
        query: listRoles,
        variables: {
          clientId: clientId
        }
      })
      const clientRoles = queryResponse.data.listRoles.map(({clientId, ...include}) => include)

      console.log('clientRoles', clientRoles)
      setRoles(clientRoles)
    } catch (err) {
      console.log('Error fetching roles', err)
    }
  } 

  useEffect(() => {
    // Set flag to turn Progress off
    if(roles.length >= 1){
      setRolesLoading(false)
    }
  }, [roles])


  return (
    <DashboardLayout>
      <DashboardNavbar />
      { 
        rolesLoading ?
        <Progress /> :
        <MDBox 
          sx={{ 
            maxWidth: 1000,
            mx: 'auto'
          }}
        >      
          <MDBox pt={6} pb={3}>
            <Grid container>
              <Grid item xs={12}>
                <MDBox>                  
                  <RolesTable
                    roles={ roles }
                  />                  
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <RDFooter />
        </MDBox>
      }
    </DashboardLayout>

  )
}
