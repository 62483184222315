import { useMemo } from 'react'

// GraphQL
import { dbGetItem } from 'graphql/queries'

import { addDays, format } from 'date-fns';

import useQuery from 'hooks/useQuery'

export default function useActualSales(query){
  console.log('*** useActualSales ***')
  // const query = useMemo(() => getActualSalesQuery(date), [date])
  return useQuery(query)
}

function getActualSalesQuery(startDate){

  console.log('getActualSalesQuery', startDate)

  const sDate = new Date(startDate)

  const eDate = addDays(sDate,7)

  const endDate = format(eDate, 'yyyy-MM-dd')

  const dbVar = JSON.stringify({
    report: 'r0005_KPI',
    startDate: startDate,
    endDate: endDate,
    filter: ['netSales'],
    stores: [],
  })

  const query = {
    query: dbGetItem,
    variables: {
      db: 'grm.reports',
      dbVariables: dbVar,
    }
  }

  return query  
}