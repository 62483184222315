import { styled } from '@mui/material/styles'
import MDButton from 'components/MDButton'

const GrmDarkButton = styled(MDButton)(({theme}) => ({
  backgroundColor: '#a44c0e',
  color: '#f9e9d3',
  '&: focus:not(:hover)': {
    backgroundColor: '#a44c0e',
    color: 'f9e9d3'
  },
  '&: hover': {
    backgroundColor: '#cf9e0b',
    color: '#29261f'
  },
  '&: disabled': {
    backgroundColor: '#c48b64',
    color: '#29261f'
  }
}))

export default GrmDarkButton