import { styled } from '@mui/system'
import MDButton from 'components/MDButton'

const Button_DemDark = styled(MDButton)(({theme}) => ({
  backgroundColor: '#0091d5',
  color: '#f1f1f1',
  '&: focus:not(:hover)': {
    backgroundColor: '#0091d5',
    color: '#f1f1f1'
  },
  '&: hover': {
    backgroundColor: '#f1f1f1',
    color: '#0091d5',
    border: '2px solid #0091d5'
  },
  '&: disabled': {
    backgroundColor: '#b9ad9c',
    color: '#29261f'
  }
}))

export default Button_DemDark