import { Box, Stack, Typography } from '@mui/material'

import { USDollar } from 'utils/reportUtils'

export default function SampleFormatBox({
  backgroundColor,
  textColor,
  fontWeight,
  fontStyle,
  fontSize,
  description,
  width
}){
  
  return (
    <Stack 
      direction='column'
      spacing={0}
      justifyContent='center'
      alignItems='center'
    >
      <Typography variant='caption'>
        {description}
      </Typography>
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      sx={{
        backgroundColor: backgroundColor,
        color: textColor,
        fontWeight: fontWeight,
        fontStyle: fontStyle,
        fontSize: fontSize,
        border: '2px solid black',
        width: width || 100,
        mb: 2
      }}
    >
      {USDollar.format(1000)}
    </Box>
    </Stack>
  )
}