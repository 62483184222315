import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Material Dashboard 2 Pro React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

function convertStrToHTML(str){
  let parser = new DOMParser()
  let msg = parser.parseFromString(str, 'text/html')
  return msg.body
}

function RDMessageBox(props){

  const [notes, setNotes] = useState([])

  useEffect(() => {
    console.log('notes', notes)
  }, [notes])

  useEffect(() => {
    console.log('props.messages', props.messages)
    
      let notesArr = props.messages.map(x => x.note)
      setNotes(notesArr)
    
  }, props.messages)
  

  return (
    <MDBox
      borderRadius='xl'
      width='100%'
      sx={{
        bgcolor: '#fff',
        p: 2,
        border: '2px solid grey'
      }}
    >
      <MDTypography
        variant='h4'
      >
        Message Board
      </MDTypography>
      { 
        notes.map( note => 
          <MDBox
            borderRadius='xl'
            sx={{
              px: 4,
              py: 1,
              my: 1,
              bgcolor: props.noteColor,
              color: props.textColor,
              fontSize: '70%'
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: note }} />
          </MDBox>
        )
      }
    </MDBox>
  )

}

RDMessageBox.propTypes = {
  messages: PropTypes.array,
  noteColor: PropTypes.string,
  textColor: PropTypes.string,
}

export default RDMessageBox