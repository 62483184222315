import React from 'react'
import PropTypes from 'prop-types'

// @mui components
import { Dialog, DialogTitle, DialogContent, Grid, Stack } from '@mui/material'

// formik components
import { Formik, Form } from 'formik'
import Textfield from 'components/FormsUI/Textfield'
import * as Yup from 'yup'

// Material Dashboard Pro 2 components
import MDButton from 'components/MDButton'

import LocationAutoComplete from './LocationAutoComplete'

export default function AddMember(props){

  const INITIAL_FORM_STATE = {
    classId: '',
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    location: ''
  }

  const FORM_VALIDATION = Yup.object().shape({
    classId: Yup.string()
      .required('Required'),
    firstName: Yup.string()
      .required('Required'),
    middleName: Yup.string(),
    lastName: Yup.string()
      .required('Required'),
    suffix: Yup.string(),
    location: Yup.string()
      .required('Required')
  })

  function handleSubmit(values){
    console.log('submit clicked', values)
    props.submit(values)
  }

  return (
    <Dialog
      open={ props.open }
    >
      <DialogTitle>Add Member</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={ INITIAL_FORM_STATE }
          validationSchema={ FORM_VALIDATION }
          onSubmit={ (values) => {
            handleSubmit(values)
          }}
        >
          {({ isValid, dirty }) => (
            <Form>
              <Stack
                direction='column'
                spacing={2}
              >
                <Stack
                  direction='column'
                  spacing={2}
                  alignItems='center'
                  justifyContent='flex-start'
                  sx={{
                    mt: 2
                  }}
                >
                  <Textfield
                    name='classId'
                    label='Class ID'
                  />
                  <Textfield
                    name='firstName'
                    label='First Name'
                  />
                  <Textfield
                    name='middleName'
                    label='Middle Name'
                  />
                  <Textfield
                    name='lastName'
                    label='Last Name'
                  />
                  <Textfield
                    name='suffix'
                    label='Suffix'
                  />
                  <LocationAutoComplete 
                    name='location'
                    setLocationData={props.setLocationData}
                    initialAddress=''
                  />
                </Stack>
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='flex-end'
                  spacing={2}
                >
                  <MDButton
                    variant='contained'
                    color='info'
                    onClick={ props.close }
                  >
                    Cancel
                  </MDButton>
                  <MDButton
                    variant='contained'
                    color='info'
                    type='submit'
                    disabled={!(dirty && isValid)}
                  >
                    Submit
                  </MDButton>
                </Stack>
              </Stack>
            </Form>
          )}
          
        </Formik>
        
      </DialogContent>
    </Dialog>
  )
}

AddMember.propTypes = {
  close: PropTypes.func,
  open: PropTypes.bool,
  submit: PropTypes.func,
}