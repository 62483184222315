import React from 'react'

// @mui components
import { IconButton, Stack, Table, TableBody, TableContainer, TableRow } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from "components/MDTypography";

// react-table components
import { useTable } from 'react-table'

export default function RDSimpleTable({
  columns,
  data,
  editable,
  loading
}) {
  
  const tableHooks = (hooks) => {
    if(editable.onRowEdit || editable.onRowDelete){
      let cellStyle = {}
      let headerStyle = {}
      if(columns.length > 0){
        headerStyle = columns[0].headerStyle
        cellStyle = columns[0].cellStyle
      }
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: 'actions',
          Header: ' ',
          align: 'center',
          width: '3%',
          headerStyle: headerStyle,
          cellStyle: cellStyle,
          Cell: ({row}) => ( //eslint-disable-line
            <Stack
              direction='row'
              spacing={1}
              alignItems='center'
              justifyContent='center'
            >
              {
                editable.onRowEdit &&
                <IconButton
                  color='info'
                  sx={{
                    padding: 0,
                    '&: hover': {
                      color: 'rgb(78, 39, 102)'
                    }
                  }}
                  onClick={() => editable.onRowEdit(row)} // eslint-disable-line
                >
                  <EditIcon />
                </IconButton>
              }
              {
                editable.onRowDelete &&
              
                <IconButton
                  color='info'
                  sx={{
                    padding: 0,
                    '&: hover': {
                      color: 'rgb(78, 39, 102)'
                    }
                  }}
                  onClick={() => editable.onRowDelete(row)} // eslint-disable-line
                >
                  <DeleteIcon />
                </IconButton>
              }
            </Stack>
          )
        }
      ])
    }
  }

  const tableInstance = useTable(
    {
      columns,
      data
    },
    tableHooks,
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  return (
    <TableContainer
      sx={{
        boxShadow: 'none'
      }}
    >
      { editable.onRowAdd ? 
        (
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            spacing={1}
            mx={3}
            my={1}
          >
            <MDBox
              width='10rem'
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
              }}
            >
              <MDButton
                variant='contained'
                size='small'
                color='info'
                onClick={ editable.onRowAdd }
              >
                Add
              </MDButton>
            </MDBox>
          </Stack>
        ) : null
      }
      <Table {...getTableProps()}>
        <MDBox component='thead'>
          { headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([
                    {
                      style: column.headerStyle,
                      width: column.width
                    },
                  ])}
                >
                  {column.render('Header')}
                </th>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {rows.length > 0 && !loading ? (
            rows.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td 
                        {...cell.getCellProps()}
                        align={cell.column.alignCell ? cell.column.alignCell : 'left'}
                        style={ cell.column.cellStyle}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })
          ) : (
            <div>loading...</div>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}