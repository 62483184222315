import { useEffect, useState } from 'react'
import PropTypes from "prop-types";

// AWS Amplify components
import { API } from 'aws-amplify'
import config from 'aws-exports'

// GraphQL
import { createCognitoUser, createUser, deleteCognitoUser, deleteUser, updateCognitoUser, updateUser } from 'graphql/mutations'

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDAlert from 'components/MDAlert'

// RadixDash components
import { useUser } from 'components/RDAuthContext'
import RDTable from 'components/RDTable'
import AddUserDialog from './AddUserDialog';
import EditUserDialog from './EditUserDialog';
import UserCellStyle from './UserCellStyle';

export default function UserTable(props){
  console.log('props', props)

  console.log('config', config)

  const { user } = useUser()
  const clientId = user.client

  const [data, setData] = useState(props.userData)
  const [rowToEdit, setRowToEdit] = useState(null)
  const [openAdd, setOpenAdd] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const roles = props.roles
  
  const [alert, setAlert] = useState(null)
  const [alertMessage, setAlertMessage] = useState({
    type: 'none',
    message: ''
  })

  useEffect(() => {
    console.log('openEdit', openEdit)
  }, [openEdit])

  const columns = [
    {
      Header: 'First Name',
      accessor: 'firstName',
      align: 'center',
      disableGroupBy: true,
      disableSortBy: true,
      columnStyle: {
        backgroundColor: '#e0e0e0',
        color: '#181818',
        borderBottom: '1px solid #000',
        fontSize: '.7rem',
        fontWeight: 'bold',
        paddingTop: '5px'
      } 
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
      align: 'center',
      disableGroupBy: true,
      disableSortBy: true,
      columnStyle: {
        backgroundColor: '#e0e0e0',
        color: '#181818',
        borderBottom: '1px solid #000',
        fontSize: '.7rem',
        fontWeight: 'bold',
        paddingTop: '5px'
      } 
    },
    {
      Header: 'User Name',
      accessor: 'userName',
      align: 'center',
      disableGroupBy: true,
      disableSortBy: true,
      columnStyle: {
        backgroundColor: '#e0e0e0',
        color: '#181818',
        borderBottom: '1px solid #000',
        fontSize: '.7rem',
        fontWeight: 'bold',
        paddingTop: '5px'
      } 
    },
    {
      Header: 'Email',
      accessor: 'email',
      align: 'center',
      disableGroupBy: true,
      disableSortBy: true,
      columnStyle: {
        backgroundColor: '#e0e0e0',
        color: '#181818',
        borderBottom: '1px solid #000',
        fontSize: '.7rem',
        fontWeight: 'bold',
        paddingTop: '5px'
      } 
    },
    {
      Header: 'Role',
      accessor: 'role',
      align: 'center',
      disableSortBy: true,
      columnStyle: {
        backgroundColor: '#e0e0e0',
        color: '#181818',
        borderBottom: '1px solid #000',
        fontSize: '.7rem',
        fontWeight: 'bold',
        paddingTop: '5px'
      } 
    },
  ]

  if(clientId === 'rdx'){
    const clientCol = {
      Header: 'Client ID',
      accessor: 'client',
      align: 'center',
      disableSortBy: true,
      columnStyle: {
        backgroundColor: '#e0e0e0',
        color: '#181818',
        borderBottom: '1px solid #000',
        fontSize: '.7rem',
        fontWeight: 'bold',
        paddingTop: '5px'
      }
    }
    columns.push(clientCol)
  }

  /********************************************************
   * 
   * ADD USER
   * 
   * ******************************************************/  
  function openAddDialog(){
    console.log('openAddDialog()...')
    setOpenAdd(true)
  }

  async function addUser(values){
    console.log('addUser()', values)

    let errMessage = null

    const newUser = {
      client: values.clientId,
      userName: values.userName.toLowerCase(),
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      role: values.role
    }

    console.log('newUser', newUser)

    try {
      // Add user to Cognito
      let cognitoResult = await API.graphql(
        {
          query: createCognitoUser,
          variables: 
          {
            userPool: config.aws_user_pools_id,
            userName: newUser.userName,
            email: newUser.email,
          }
        }
      )
      console.log('Successfully added user to Cognito', cognitoResult)
      
      // Add user to User DB
      try {
        let userDbResult = await API.graphql(
          {
            query: createUser,
            variables: {
              input: newUser
            }
          }
        )
        console.log('Successfully updated user to User DB', userDbResult)

        newUser.id = userDbResult.data.createUser.id
    
        // Update dataTable
        let newData = [...data, newUser]
        newData.sort((a, b) => (a.userName > b.userName) ? 1 : -1)    
        
        // Update the data table with the new data
        setData([...newData])
        
        // Notify the Admin that user creation was successful
        setAlertMessage({
          type: 'success',
          message: 'An email with a temporary password has been sent to your new User'
        })
      } catch (err) {
        console.log('Error adding new user to User DB', err)
        errMessage = 'Error adding user to User DB - please contact RadixDash support.'

        // Delete user from Cognito
        let deleteResult = await API.graphql(
          {
            query: deleteCognitoUser,
            variables: {
              userPool: config.aws_user_pools_id,
              userName: newUser.userName
            }
          }
        )
        console.log('deleteResult', deleteResult)
      }
    } catch (err) {
      console.log('Error adding new user to Cognito', err)
      errMessage = 'Error adding user to Cognito - please contact RadixDash support.'
      
      
    } finally {
      setOpenAdd(false)
      if(errMessage){
        setAlertMessage({
          type: 'error',
          message: errMessage
        })
      }
    }
  }

  /********************************************************
   * 
   * EDIT USER
   * 
   * ******************************************************/
   function openEditDialog(row){
    console.log('Edit row clicked', row)
    console.log('Data', data)
    console.log('props', props)
    // Get company name
    const clientArr = props.clients
    const selectedRowClient = row.original.client
    const clientItem = clientArr.filter(x => x.clientId === selectedRowClient)
    const companyName = clientItem[0].clientName
    row.companyName = companyName
    setRowToEdit(row)
  }

  useEffect(() => {
    if(rowToEdit){
      console.log('rowToEdit', rowToEdit)
      setOpenEdit(true)
    }
  }, [rowToEdit])

  async function modifyUser(values){
    console.log('modifyUser()...', values)
    setOpenEdit(false)
    const index = values.index
    const newData = values.newData
    const oldData = values.oldData

    let errMessage = null
    let cognitoUpdate = 'NA'

    if(newData.email !== oldData.email){
      // Update Cognito
      console.log('email changed - updating Cognito...')
      let result
      try {
        result = await API.graphql(
          {
            query: updateCognitoUser,
            variables: {
              userPool: config.aws_user_pools_id,
              userName: oldData.userName,
              email: newData.email,
            }
          }
        )
        console.log('Successfully updated user to Cognito', result)
        cognitoUpdate = 'Success'
      } catch (err) {
        console.log('Error updating user email to Cognito', err)
        console.log('result in err', result)
        errMessage = 'Error: User update failed [Cognito]'
        cognitoUpdate = 'Fail'
      
        // Notify Admin that user update failed
        setAlertMessage({
          type: 'error',
          message: errMessage
        })
      }
    }

    if(cognitoUpdate === 'NA' || cognitoUpdate === 'Success'){
      console.log('cognitoUpdate', cognitoUpdate)
      // Update User Database
      // Set clientId
      let queryClientId
      if(clientId === 'rdx'){
        queryClientId = oldData.clientId
        newData.clientId = queryClientId
      } else {
        queryClientId = clientId
      }
      console.log('queryClientId', queryClientId)

      // Get selected user ID
      
      console.log('rowToEdit', rowToEdit)
      const userId = rowToEdit.original.id
      console.log('userId', userId)

      const input = {
        client: queryClientId,
        email: newData.email,
        firstName: newData.firstName,
        id: userId,
        lastName: newData.lastName,
        role: newData.role,
        userName: oldData.userName
      }

      try {
        let result = await API.graphql(
          {
            query: updateUser,
            variables: {
              input: input
            }
          }
        )
        console.log('Successfully updated user to User DB', result)

        // Update dataTable
        const dataUpdate = [...data]
        dataUpdate[index] = input
        dataUpdate.sort((a, b) => (a.userName > b.userName) ? 1 : -1)
        setData([...dataUpdate])
        
      
        // Notify Admin that user update was successful
        setAlertMessage({
          type: 'success',
          message: 'User successuflly updated'
        })
      } catch (err) {
        console.log('Error updating user to User DB', err)
        let errMessage = 'Error updating User DB'

        // Revert change to Cognito if it was updated
        if(cognitoUpdate === 'Success'){
          console.log('reverting cognito update')
          let deleteResult = await API.graphql(
            {
              query: updateCognitoUser,
              variables: {
                userPool: config.aws_user_pools_id,
                userName: oldData.userName,
                email: oldData.email,
              }
            }
          )
          console.log('Successfully reverted user email to Cognito', deleteResult)
        }
        
        // Notify Admin that user update failed
        setAlertMessage({
          type: 'error',
          message: errMessage
        })
      }
    }
  }

  /********************************************************
   * 
   * Remove User Function
   * 
   * ******************************************************/
  async function removeUser(row) {
    console.log('removeUser()...', row)
    const index = row.index
    const userNameToDelete = row.values.userName
    const userIdToDelete = row.original.id
    
    if(userNameToDelete !== user.userName){
      console.log(`deleting ${userNameToDelete}`)
      try {
        let cognitoResult = await API.graphql(
          {
            query: deleteCognitoUser,
            variables: {
              userPool: config.aws_user_pools_id,
              userName: userNameToDelete
            }
          }
        )
        console.log('Successfully deleted User from Cognito', cognitoResult)

        // Delete user from User Database
        try {
          let userDbResult = await API.graphql(
            {
              query: deleteUser,
              variables: {
                input: {
                  id: userIdToDelete
                }
              }
            }
          )
          console.log('Successfully deleted User from User DB', userDbResult)

        } catch (err) {
          console.log('Error deleting user from User DB', err)

          setAlertMessage({
            type: 'error',
            message: `Error deleting ${userNameToDelete} from User Database`
          })
        }

        // Remove User from dataTable
        const dataDelete = [...data]
        dataDelete.splice(index, 1)
        setData([...dataDelete])

        // Notify Admin that Use successfully deleted
        setAlertMessage({
          type: 'success',
          message: `${userNameToDelete} successfully deleted`
        })

      } catch (err) {
        console.log('Error deleting user from Cognito', err)
        // Notify Admin that User deletion failed
        setAlertMessage({
          type: 'error',
          message: `Error deleting ${userNameToDelete} from Cognito`
        })
      }
    } else {
      setAlertMessage({
        type: 'error',
        message: 'Error: Cannot delete yourself'
      })
    }
  }

  /********************************************************
   * 
   * ALERT Functions
   * 
   * ******************************************************/
  
  // Determine which alert to trigger
  useEffect(() => {
    console.log('alertMessage type:', alertMessage.type)
    switch(alertMessage.type){
      case 'error':
        errorAlert()
        break;
      case 'success':
        successAlert()
        break;
      default:
        console.log('Invalid alertMessage type', alertMessage)
    }
  }, [alertMessage.type])

  // Close the alert
  const hideAlert = () => {
    
    setAlert(null)
    setAlertMessage('')
  }

  const successAlert = () => {
    console.log('successAlert...', alertMessage)
    setAlert(
      <MDAlert color='success' dismissible>
        { alertMessage.message }
      </MDAlert>
    )
    setTimeout(function() {hideAlert()}, 3000)
  }

  const errorAlert = () => {
    console.log('errorAlert()', alertMessage)
    setAlert(
      <MDAlert color='error' dismissible>
        { alertMessage.message }
      </MDAlert>
    )
    setTimeout(function() {hideAlert()}, 3000)
  }

  /********************************************************
   * 
   * RENDER PAGE
   * 
   * ******************************************************/
  
  return (
    <MDBox>
      { alert }
      {
        openAdd &&
        <AddUserDialog 
          open={ openAdd }
          close={ () => setOpenAdd(false) }
          submit={ addUser }
          existingUsers={ data.map( a => a.userName )}
          roles={ roles }
          clients={ props.clients }
          clientId={ clientId }
        />
      }
      {
        openEdit && 
        <EditUserDialog 
          open={ openEdit }
          data={ rowToEdit }
          close={ () => setOpenEdit(false) }
          submit={ modifyUser }
          existingUsers={ data.map( a => a.userName )}
          roles={ roles }
          clientId={ rowToEdit.original.client }
        />
      }
      <RDTable 
        table={{
          columns: columns,
          rows: data
        }}
        editable={{
          onRowAdd: () => openAddDialog(),
          onRowEdit: row => openEditDialog(row),
          onRowDelete: row => removeUser(row)
        }}
        canSearch
        showEntriesPerPage
        tableType='User'
        cellStyle={ UserCellStyle }
      />
    </MDBox>
  )
}

UserTable.propTypes = {
  clients: PropTypes.array,
  roles: PropTypes.object,
  userData: PropTypes.array
}