import React, {useEffect, useState} from 'react'

// AWS Amplify components
import { API } from 'aws-amplify'

// GraphQL
import { dbAddItem, dbDeleteItem, dbEditItem } from 'graphql/mutations'

// MUI components
import { IconButton, Stack } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

// RadixDash components
import DialogEditAssignment from './DialogEditAssignment'
import RDTable from 'components/RDTable'

import THAssignmentCellStyle from './THAssignmentCellStyle'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export default function THAssignmentTable(props){
  console.log('THAssignmentTable', props)

  const headerBG = '#e0e0e0'
  const headerTextColor = '#181818'
  const headerFontSize = '.7rem'

  const [openEdit, setOpenEdit] = useState(false)
  const [rowToEdit, setRowToEdit] = useState(null)

  const columns = [
    {
      Header: 's',
      columnStyle: {
        backgroundColor: headerBG,
        color: headerBG,
        fontSize: headerFontSize,
        borderBottom: 'none'
      },
      columns: [
        {
          Header: 'Store',
          accessor: 'store',
          align: 'center',
          alignCell: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            borderBottom: '1px solid #000',
            fontSize: headerFontSize,
          }
        },
      ]
    },
    {
      Header: 'Net Sales',
      align: 'center',
      columnStyle: {
        backgroundColor: headerBG,
        color: headerTextColor,
        borderBottom: '1px solid #000',
        borderLeft: '1px solid #000',
        fontSize: headerFontSize,
      },
      columns: [
        {
          Header: 'Name',
          accessor: 'netSalesName',
          width: 10,
          align: 'center',
          alignCell: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            borderBottom: '1px solid #000',
            borderLeft: '1px solid #000',
            fontSize: headerFontSize,
          }
        },
        {
          Header: 'Low',
          accessor: 'netSalesLow',
          align: 'center',
          alignCell: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            borderBottom: '1px solid #000',
            fontSize: headerFontSize,
          },
          Cell: ({row}) => {
            const data = row.original
            return (data.store !== data.area ? data.netSalesLow + '%' : null)
          }
        },
        {
          Header: 'High',
          accessor: 'netSalesHigh',
          align: 'center',
          alignCell: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            borderBottom: '1px solid #000',
            fontSize: headerFontSize,
          },
          Cell: ({row}) => {
            const data = row.original
            return (data.store !== data.area ? data.netSalesHigh + '%' : null)
          }
        },
      ]
    },
    {
      Header: 'Average Check',
      align: 'center',
      columnStyle: {
        backgroundColor: headerBG,
        color: headerTextColor,
        borderBottom: '1px solid #000',
        borderLeft: '1px solid #000',
        fontSize: headerFontSize,
      },
      columns: [
        {
          Header: 'Name',
          accessor: 'avgCheckName',
          align: 'center',
          alignCell: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            borderBottom: '1px solid #000',
            borderLeft: '1px solid #000',
            fontSize: headerFontSize,
          }
        },
        {
          Header: 'Low',
          accessor: 'avgCheckLow',
          align: 'center',
          alignCell: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            borderBottom: '1px solid #000',
            fontSize: headerFontSize,
          },
          Cell: ({row}) => {
            const data = row.original
            return (data.store !== data.area ? formatter.format(data.avgCheckLow/100) : null)
          }
        },
        {
          Header: 'High',
          accessor: 'avgCheckHigh',
          align: 'center',
          alignCell: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            borderBottom: '1px solid #000',
            fontSize: headerFontSize,
          },
          Cell: ({row}) => {
            const data = row.original
            return (data.store !== data.area ? formatter.format(data.avgCheckHigh/100) : null)
          }
        },
      ]
    },
    {
      Header: 'SPLH Total',
      align: 'center',
      columnStyle: {
        backgroundColor: headerBG,
        color: headerTextColor,
        borderBottom: '1px solid #000',
        borderLeft: '1px solid #000',
        fontSize: headerFontSize,
      },
      columns: [
        {
          Header: 'Name',
          accessor: 'splhTotalName',
          align: 'center',
          alignCell: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            borderBottom: '1px solid #000',
            borderLeft: '1px solid #000',
            fontSize: headerFontSize,
          }
        },
        {
          Header: 'Low',
          accessor: 'splhTotalLow',
          align: 'center',
          alignCell: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            borderBottom: '1px solid #000',
            fontSize: headerFontSize,
          },
          Cell: ({row}) => {
            const data = row.original
            return (data.store !== data.area ? formatter.format(data.splhTotalLow/100) : null)
          }
        },
        {
          Header: 'High',
          accessor: 'splhTotalHigh',
          align: 'center',
          alignCell: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            borderBottom: '1px solid #000',
            fontSize: headerFontSize,
          },
          Cell: ({row}) => {
            const data = row.original
            return (data.store !== data.area ? formatter.format(data.splhTotalHigh/100) : null)
          }
        },
      ]
    },
    {
      Header: 'SPLH BOH',
      align: 'center',
      columnStyle: {
        backgroundColor: headerBG,
        color: headerTextColor,
        borderBottom: '1px solid #000',
        borderLeft: '1px solid #000',
        fontSize: headerFontSize,
      },
      columns: [
        {
          Header: 'Name',
          accessor: 'splhBOHName',
          align: 'center',
          alignCell: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            borderBottom: '1px solid #000',
            borderLeft: '1px solid #000',
            fontSize: headerFontSize,
          }
        },
        {
          Header: 'Low',
          accessor: 'splhBOHLow',
          align: 'center',
          alignCell: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            borderBottom: '1px solid #000',
            fontSize: headerFontSize,
          },
          Cell: ({row}) => {
            const data = row.original
            return (data.store !== data.area ? formatter.format(data.splhBOHLow/100) : null)
          }
        },
        {
          Header: 'High',
          accessor: 'splhBOHHigh',
          align: 'center',
          alignCell: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            borderBottom: '1px solid #000',
            fontSize: headerFontSize,
          },
          Cell: ({row}) => {
            const data = row.original
            return (data.store !== data.area ? formatter.format(data.splhBOHHigh/100) : null)
          }
        },
      ]
    },
    {
      Header: 'SPLH FOH',
      align: 'center',
      columnStyle: {
        backgroundColor: headerBG,
        color: headerTextColor,
        borderBottom: '1px solid #000',
        borderLeft: '1px solid #000',
        fontSize: headerFontSize,
      },
      columns: [
        {
          Header: 'Name',
          accessor: 'splhFOHName',
          align: 'center',
          alignCell: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            borderBottom: '1px solid #000',
            borderLeft: '1px solid #000',
            fontSize: headerFontSize,
          }
        },
        {
          Header: 'Low',
          accessor: 'splhFOHLow',
          align: 'center',
          alignCell: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            borderBottom: '1px solid #000',
            fontSize: headerFontSize,
          },
          Cell: ({row}) => {
            const data = row.original
            return (data.store !== data.area ? formatter.format(data.splhFOHLow/100) : null)
          }
        },
        {
          Header: 'High',
          accessor: 'splhFOHHigh',
          align: 'center',
          alignCell: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            borderBottom: '1px solid #000',
            fontSize: headerFontSize,
          },
          Cell: ({row}) => {
            const data = row.original
            return (data.store !== data.area ? formatter.format(data.splhFOHHigh/100) : null)
          }
        },
      ]
    },
    {
      Header: 'e',
      align: 'center',
      columnStyle: {
        backgroundColor: headerBG,
        color: headerBG,
        borderBottom: 'none',
        borderLeft: '1px solid #000',
        fontSize: headerFontSize,
      },
      columns: [
        {
          Header: 'en',
          accessor: 'edit',
          align: 'center',
          alignCell: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerBG,
            borderBottom: '1px solid #000',
            borderLeft: '1px solid #000',
            fontSize: headerFontSize,
          },
          Cell: ({row}) => {
            if(row.original.store !== row.original.area){
              return (              
                <IconButton
                  size='small'
                  color='info'
                  sx={{
                    padding: 0,
                    // color: iconColor
                  }}
                  onClick={() => openEditDialog(row)}
                >
                  <EditIcon />
                </IconButton>
              )
            } else {
              return null
            }
          }
        },
      ]
    },
  ]

  function openEditDialog(row){
    console.log('Edit row clicked', row)
    setRowToEdit(row)
  }

  useEffect(() => {
    if(rowToEdit){
      setOpenEdit(true)
    }
  }, [rowToEdit])

  async function modifyAssignment(values){
    console.log('values', values)

    const oldData = values.oldData
    const newData = values.newData

    const vars = {
      area: oldData.area,
      storeId: oldData.storeId,
      avgCheckTH: newData.avgCheck,
      netSalesTH: newData.netSales,
      splhBOHTH: newData.splhBOH,
      splhFOHTH: newData.splhFOH,
      splhTotalTH: newData.splhTotal
    }

    console.log('vars', vars)
    const varsJSON = JSON.stringify(vars)

    try {
      let result = await API.graphql(
        {
          query: dbEditItem,
          variables: {
            db: 'grm.areas',
            dbItemId: oldData.storeId,
            dbItem: varsJSON
          }
        }
      )
      console.log('Successfully updated threshold assignment', result)

      const newData = JSON.parse(JSON.parse(result.data.dbEditItem.statusItem))

      props.update( newData )

      setOpenEdit(false)

    } catch (err) {

    }
  }

  return (
    <MDBox
      sx={{
        mt: 2,
        mb: 3,
      }}
    >
      {
        openEdit && 
        <DialogEditAssignment 
          open={ openEdit }
          data={ rowToEdit }
          thresholds={ props.thresholds }
          close={ () => setOpenEdit(false)}
          submit={ modifyAssignment }
          // title={ props.title }
          // tableType={ props.tableType }
        />
      }
      <RDTable 
        table={{
          columns: columns,
          rows: props.data
        }}
        editable={{
          onRowAdd: null,
          onRowEdit: null,
          onRowDelete: null
        }}
        cellStyle={ THAssignmentCellStyle }
        showTotalEntries={false}
        entriesPerPage={{defaultValue: props.data.length}}
      />
    </MDBox>
  )
}