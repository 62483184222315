// @mui icons
import Icon from '@mui/material/Icon'

import ClientAdmin from 'clients/admin/ClientAdmin/ClientAdmin'
import UserAdmin from 'clients/admin/UserAdmin/UserAdmin'

/************************************************
 * Client-specific pages
 ************************************************/
import PlaceHolder from 'clients/PlaceHolder'

const reportViews = {
  reportPH: {
    name: 'Place Holder',
    key: 'repPH',
    route: '/reports/repPH',
    component: <PlaceHolder />
  }
}

const adminViews = {
  clientAdmin: {
    name: 'Client Admin',
    key: 'client-admin',
    route: '/clientAdmin',
    component: <ClientAdmin />
  },
  userAdmin: {
    name: 'User Admin',
    key: 'user-admin',
    route: '/userAdmin',
    component: <UserAdmin />
  }
}

export default function rdxRoutes(role, permissions) {
  let reportViewArr = []
  let adminViewArr = []

  // Setup Admin Views
  switch(role){
    case 'Admin':
      adminViewArr[0] = adminViews.clientAdmin
      adminViewArr[1] = adminViews.userAdmin
      break;
    default:
      // No action, leave as empty array
  }

  // Setup Report Views
  let arrIdx = 0
  for(let i = 0; i < permissions.length; i++){
    let report = permissions[i].report
    report = report.toLowerCase()
    if(report in reportViews){
      let viewPermissions = permissions[i].viewPermissions
      if(viewPermissions !== 'no-permission'){
        reportViewArr[arrIdx] = reportViews[report]
        arrIdx += 1
      }
    }
  }

  // Create Routes
  let routes = []

  if(reportViewArr.length > 0){
    let reportRoutes = {
      type: 'collapse',
      name: 'Reports',
      key: 'reports',
      icon: <Icon fontSize='medium'>format_list_numbered_icon</Icon>,
      collapse: reportViewArr
    }
    routes.push(reportRoutes)
  }

  if(adminViewArr.length > 0){
    let adminRoutes = {
      type: 'collapse',
      name: "Admin Tools",
      key: 'admin-tools',
      icon: <Icon fontSize='medium'>content_paste</Icon>,
      collapse: adminViewArr,
    }
    routes.push(adminRoutes)
  }

  let finalDivider = {
    type: 'divider',
    key: 'final-divider'
  }
  routes.push(finalDivider)

  return routes
}