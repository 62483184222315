import utils from 'clients/grm/views/R0002/R0002Utils'
import { AGGREGATION_TYPES, METRIC_TYPES, PAGE_TYPES, STORE_TYPES } from '../../constants'
import { KPI_CONFIGS } from './MetricsProcessor/KPIConfigs';
import { processSSSRow } from './utils';
import { parseValue } from 'utils/helperUtils'
import { formatMoney } from '../../utils/formatters'

export default function transformMergedData(mergedData, storeMap, thresholds) {
  console.log('mergedData', mergedData)
  const result = {
    company: [],
    franchise: []
  };

  const processDataset = (pageId, data, storeType) => {
    if (pageId.includes(PAGE_TYPES.EMPTY)) {
      return []
    }

    const processedData = processData2(data)

    return utils.buildR0002Pages(
      processedData,
      thresholds,
      storeType
    )
  }

  mergedData.forEach(dataset => {
    const { pageId, data } = dataset;

    if (pageId.includes(PAGE_TYPES.COMPANY)) {
      result.company = processDataset(pageId, data, STORE_TYPES.COMPANY)
    } else if (pageId.includes(PAGE_TYPES.FRANCHISE)) {
      result.franchise = processDataset(pageId, data, STORE_TYPES.FRANCHISE)
    }
  });

  return result;
};

// Helper function to identify if a metric is a current year metric
const isCurrentYearMetric = (metric) => metric.startsWith('c') || metric === 'pct';

// Helper function to get the corresponding previous year metric
const getPreviousYearMetric = (metric) => {
  if (!metric.startsWith('c')) return null;
  return `p${metric.substring(1)}`;
};

// Updated calculate aggregates function to handle both current and previous year values
const calculateAggregates = (stores, kpiConfigs) => {
  const aggregates = {};
  
  // First pass: Calculate sums for both current and previous year metrics
  Object.entries(kpiConfigs).forEach(([metric, config]) => {
    if (config.type === AGGREGATION_TYPES.SUM) {
      // Only process if it's a current year metric or special case
      if (isCurrentYearMetric(metric)) {
        const currentMetric = metric;
        const previousMetric = getPreviousYearMetric(metric);
        
        // Calculate current year sum
        const currentSum = stores.reduce((sum, store) => {
          const value = store.rowData[currentMetric];
          if (!value) return sum;
          
          return sum + parseValue(value)
        }, 0);

        // Calculate previous year sum
        if (previousMetric) {
          const previousSum = stores.reduce((sum, store) => {
            const value = store.rowData[previousMetric];
            if (!value) return sum;
            
            return sum + parseValue(value)
          }, 0);

          aggregates[previousMetric] = formatMetricValue(previousSum, config.metricType)
        }

        // Format current year/special case value
        aggregates[currentMetric] = formatMetricValue(currentSum, config.metricType)
      }
    }
  });
  
  // Second pass: Calculate derived metrics for both current and previous year
  Object.entries(kpiConfigs).forEach(([metric, config]) => {
    if (config.type === AGGREGATION_TYPES.CALCULATED && isCurrentYearMetric(metric)) {
      const previousMetric = getPreviousYearMetric(metric);
      
      // Calculate current year derived metrics
      const currentInputValues = config.inputs.map(input => {
        const value = aggregates[input];
        return parseValue(value)
      });
      aggregates[metric] = config.calculate(...currentInputValues);
      
      // Calculate previous year derived metrics
      if (previousMetric) {
        const previousInputValues = config.inputs.map(input => {
          const previousInput = getPreviousYearMetric(input);
          const value = aggregates[previousInput];
          return parseValue(value)
        });
        aggregates[previousMetric] = config.calculate(...previousInputValues);
      }
    }
  });
  
  return aggregates;
};

// Helper function to format metric values based on type
const formatMetricValue = (value, metricType) => {
  switch (metricType) {
    case METRIC_TYPES.CHECK_COUNTS:
      return Math.round(value).toLocaleString();
    case METRIC_TYPES.NET_SALES:
      return formatMoney(value, 0);
    case METRIC_TYPES.HOURS:
      return value;  // Store raw number for hours
    case METRIC_TYPES.OVERTIME:
      return formatMoney(value, 0);
    case METRIC_TYPES.LABOR:
      return value;
    default:
      console.warn(`Unknown metric type: ${metricType}`);
      return value;
  }
};

const compareValues = (current, previous) => {
  const currentVal = parseValue(current)
  const previousVal = parseValue(previous)

  return !isNaN(currentVal) && !isNaN(previousVal) && currentVal > previousVal
}

const processData2 = (data) => {
  const processedData = [];
  let establishedStores = [];
  let allStores = [];
  const storeIncreases = initializeStoreIncreases();

  // First, organize stores by manager
  const storesByManager = new Map();
  data.forEach(row => {
    if (row.rowType === 'storeRow') {
      const manager = row.rowData.manager;
      if (!storesByManager.has(manager)) {
        storesByManager.set(manager, []);
      }
      storesByManager.get(manager).push(row);
      establishedStores.push(row);
      allStores.push(row);
      updateStoreIncreases(storeIncreases, row.rowData);
    } else if (row.rowType === 'otherStoreRow') {
      allStores.push(row);
    }
  });

  // Sort managers alphabetically and process each group
  Array.from(storesByManager.entries())
    .sort(([managerA], [managerB]) => managerA.localeCompare(managerB))
    .forEach(([manager, stores]) => {
      // Add manager row
      processedData.push({
        rowType: 'managerRow',
        rowData: { store: manager === 'John' ? 'Kevin' : manager }
      })

      // Sort stores alphabetically within manager group
      stores.sort((a, b) => a.rowData.store.localeCompare(b.rowData.store))
        .forEach(store => {
          processedData.push(store)
        })

      // Add total row for this manager's stores
      processedData.push({
        rowType: 'totalRow',
        rowData: {
          store: `${manager === 'John' ? 'Kevin' : manager} Total`,
          ...calculateAggregates(stores, KPI_CONFIGS)
        }
      })
    })

  // Add Total Comp row
  const totalCompRow = {
    rowType: 'totalRow',
    rowData: {
      store: 'Total Comp',
      ...calculateAggregates(establishedStores, KPI_CONFIGS)
    }
  };
  processedData.push(totalCompRow);

  // Add SSS % row
  processedData.push(processSSSRow(totalCompRow.rowData));

  // Add Stores Increase row
  processedData.push({
    rowType: 'bottomBorderRow',
    rowData: {
      store: 'Stores Increase',
      ...storeIncreases
    }
  });

  // Add Others stores (sorted by store name)
  const otherStores = data
    .filter(row => row.rowType === 'otherStoreRow')
    .sort((a, b) => a.rowData.store.localeCompare(b.rowData.store));
  
  otherStores.forEach(store => {
    processedData.push(store);
  });

  // Add Overall Total row
  processedData.push({
    rowType: 'totalRow',
    rowData: {
      store: 'Overall Total',
      ...calculateAggregates(allStores, KPI_CONFIGS)
    }
  });

  return processedData;
};

const initializeStoreIncreases = () => ({
  cNSTotal: 0,
  cNSDineIn: 0,
  cNSToGo: 0,
  cNSDelivery: 0,
  cGSDelivery: 0,
  cCCTotal: 0,
  cCCDineIn: 0,
  cCCToGo: 0,
  cCCDelivery: 0,
  cACTotal: 0,
  cACDineIn: 0,
  cACToGo: 0,
  cACDelivery: 0,
  cSPLHTotal: 0,
  cFOH: 0,
  cBOH: 0,
  cLaborPct: 0
});

const updateStoreIncreases = (increases, rowData) => {
  Object.keys(increases).forEach(metric => {
    const currentMetric = rowData[metric];
    const previousMetric = rowData[`p${metric.substring(1)}`];
    
    if (compareValues(currentMetric, previousMetric)) {
      if (metric !== 'cLaborPct') {
        increases[metric]++;
      }
    } else if (metric === 'cLaborPct') {
      increases[metric]++;
    }
  });
};