import React from 'react'
import PropTypes from 'prop-types'

// @mui components
import { Box, Dialog, DialogTitle, DialogContent, Grid, InputAdornment
} from '@mui/material'

// formik components
import { Formik, Form } from 'formik'
import Textfield from 'components/FormsUI/Textfield'
import * as Yup from 'yup'

// Material Dashboard Pro 2 components
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export default function DialogEditTH(props){

  console.log('DialogEditTH', props)

  function handleSubmit(values){
    console.log('values', values)
    let newValues = {...values}
    switch(props.tableType){
      case 'avgCheck':
      case 'splhTotal':
      case 'splhBOH':
      case 'splhFOH':
        newValues.lowValue = values.lowValue * 100
        newValues.highValue = values.highValue * 100
        break;
      default:
        // no action
    }
    props.submit({
      index: props.data.index,
      oldData: props.data.original,
      newData: newValues
    })
  }

  let initialLowValue = props.data.original.lowValue
  let initialHighValue = props.data.original.highValue
  if(props.tableType !== 'netSales'){
    initialLowValue = (initialLowValue/100).toFixed(2)
    initialHighValue = (initialHighValue/100).toFixed(2)
  }

  const INITIAL_FORM_STATE = {
    name: props.data.original.name,
    lowValue: initialLowValue,
    highValue: initialHighValue
  }

  const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string()
      .required('Required'),
    lowValue: Yup.number()
      .required('Required'),
    highValue: Yup.number()
      .required('Required')
  })

  return (
    <Dialog
      open={ props.open }
    >
      <DialogTitle>
        Edit Threshold for {props.title}
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={INITIAL_FORM_STATE}
          validationSchema={FORM_VALIDATION}
          onSubmit={ (values) => { handleSubmit(values) }}
        >
          {({ isValid, dirty }) => (
            <Form>
              <Box
                sx={{
                  maxWidth: '200px',
                  mx: 'auto'
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} 
                    // Included to give spacing to 'role' Texfield
                  />

                  <Grid item xs={12}>
                    <Textfield 
                      name='name'
                      label='Name'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield 
                      name='lowValue'
                      label='Low Value'
                      InputProps={
                        props.tableType === 'netSales' ?
                        {                     
                          endAdornment: <InputAdornment position='start'>%</InputAdornment> 
                        } :
                        { 
                          startAdornment: <InputAdornment position='start'>$</InputAdornment>
                        }
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Textfield 
                      name='highValue'
                      label='High Value'
                      InputProps={
                        props.tableType === 'netSales' ?
                        {                     
                          endAdornment: <InputAdornment position='start'>%</InputAdornment> 
                        } :
                        { 
                          startAdornment: <InputAdornment position='start'>$</InputAdornment>
                        }
                      }
                    />
                  </Grid>
              
                  <Grid item xs={6}>
                    <MDButton variant='contained' color='info' onClick={ props.close }>
                      Cancel
                    </MDButton>
                  </Grid>
                  <Grid item xs={6}>
                    <MDButton 
                      variant='contained' 
                      color='info' 
                      type='submit' 
                      disabled={!(dirty && isValid)}
                    >
                      Submit
                    </MDButton>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

// AddRoleDialog.propTypes = {
//   close: PropTypes.func,
//   existingRoles: PropTypes.array,
//   open: PropTypes.bool,
//   submit: PropTypes.func,
// }