import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Card } from '@mui/material'

// Material Dashboard 2 PRO React 
import { useMaterialUIController, setOpenConfigurator } from "context";
import DashboardLayout from 'layouts/DashboardLayout'
import MDBox from 'components/MDBox'

// RadixDash components
import DashboardNavbar from 'components/RDNavbars/DashboardNavbar';
import Progress from 'components/Progress/Progress'
import RDConfigurator from 'components/RDConfigurator'
import RDReportHeader from 'components/RDReportHeader';

// R0004 Hooks
import { useProducts, useQuery, useStores, useViews, useYears } from './r0004Hooks';

// R0004 components
import R0004Chart from './R0004Chart';
import R0004Config from './R0004Config';
import R0004SelectDialog from './R0004SelectDialog';
import R0004Table from './R0004Table';

import { addProductCategories2, calculateReportHeight, flattenToCSV, getCacheKey, getChartData, getCSVColumns, prepareProductSelectList, prepareStoreSelectList, prepareStoreTableData, prepareTableData } from './r0004Utils'

import { getYearsArray } from 'utils/reportUtils';

const dataCache = {}

export default function R0004Main(){
  const { productMap, productsReady } = useProducts()
  const { storeMap, storesReady } = useStores()
  
  const {
    periodType,
    setPeriodType,
    reportType,
    setReportType,
    title,
  } = useViews()

  const years = getYearsArray(2022, new Date().getFullYear())

  const { 
    rawQuarterData, 
    rawQuarterDataReady,
    rawMonthData, 
    rawMonthDataReady,
    dataLoading,
    setDataLoading
  } = useQuery(periodType, years)
    
  const [allQuarterData, setAllQuarterData] = useState(null)
  const [allMonthData, setAllMonthData] = useState(null)
  const [chartData, setChartData] = useState({})
  const [pageWidth, setPageWidth] = useState('calc(1378px + 1rem')
  const [products, setProducts] = useState({
    selected: [],
    unSelected: []
  })
  const [reportHeight, setReportHeight] = useState(0)
  const [stores, setStores] = useState({
    selected: [],
    unSelected: []
  })
  const [tableData, setTableData] = useState([])
  
  /**********************************************
   **********************************************
   *
   * CONFIGURATOR & FILTER MANAGEMENT
   * 
   **********************************************
   **********************************************/

  /**********************************************
   * Configurator handling
   **********************************************/
  const [controller, dispatch] = useMaterialUIController();  
  
  const {
    openConfigurator,
  } = controller;
  
  // Change the openConfigurator state
  const handleConfiguratorOpen = useCallback(() => {
    setOpenConfigurator(dispatch, !openConfigurator);
  }, [])
  
  /**********************************************
   * View Selection Handling
   **********************************************/  
  
  const handlePeriodTypeChange = (newPeriodType) => {
    setPeriodType(newPeriodType)
  }

  const handleTypeChange = (newType) => {
    setReportType(newType)
  }

  /**********************************************
   * Data Filter Handling
   **********************************************/
  const [areasActive, setAreasActive] = useState('yesAreas')
  const [selectedYears, setSelectedYears] = useState(years)
  const [selectedProducts, setSelectedProducts] = useState({
    selected: [],
    unSelected: []
  })
  const [selectedStores, setSelectedStores] = useState({
    selected: [],
    unSelected: []
  })   

  const filters = useMemo(() =>{
    return {
      areasActive,
      selectedYears,
      selectedProducts,
      selectedStores,
    }
  }, [areasActive, selectedYears, selectedProducts, selectedStores])
  
  // Product Filter Handling
  const [openProductDialog, setOpenProductDialog] = useState(false)
  
  const selectProducts = () => {
    setOpenProductDialog(true)
  }

  const cancelProductDialog = () => {
    setOpenProductDialog(false)
  }

  const handleProductSelections = (items) => {
    setSelectedProducts({
      selected: items.selected,
      unSelected: items.unSelected
    })
    setOpenProductDialog(false)
  }

  // Store Filter Handling
  const [openStoreDialog, setOpenStoreDialog] = useState(false)
  
  const selectStores = () => {
    setOpenStoreDialog(true)
  }

  const cancelStoreDialog = () => {
    setOpenStoreDialog(false)
  }

  const handleStoreSelections = (items) => {
    setSelectedStores({
      selected: items.selected.sort(),
      unSelected: items.unSelected.sort()
    })

    const storeTableDataSelected = prepareStoreTableData(items.selected)
    const storeTableDataUnSelected = prepareStoreTableData(items.unSelected)
    setStores({
      selected: storeTableDataSelected,
      unSelected: storeTableDataUnSelected
    })
    setOpenStoreDialog(false)
  }

  const handleAreasActiveChange = (areaSwitchValue) => {
    setAreasActive(areaSwitchValue)
  }

  useEffect(() => {
    const pageHeight = calculateReportHeight('report-header')
    setReportHeight(pageHeight)    
  }, [])

  useEffect(() => {
    if(productsReady) {
      const productSelectList = prepareProductSelectList(productMap)
      setSelectedProducts({
        selected: productSelectList,
        unSelected: []
      })
    }
  }, [productsReady])

  useEffect(() => {
    if(storesReady) {
      const storeList = prepareStoreSelectList(storeMap)
      setSelectedStores({
        selected: storeList,
        unSelected: []
      })
    }
  }, [storesReady])

  useEffect(() => {
    if(productsReady && storesReady){
      if(rawQuarterDataReady){
        const categorizedQuarterData = addProductCategories2(rawQuarterData, productMap)
        setAllQuarterData(categorizedQuarterData)
        setDataLoading(false)
      }
      
      if(rawMonthDataReady){
        const categorizedMonthData = addProductCategories2(rawMonthData, productMap)
        setAllMonthData(categorizedMonthData)
        setDataLoading(false)
      }      
    }
  }, [productsReady, storesReady, rawQuarterDataReady, rawMonthDataReady])

  useEffect(() => {
    if(allQuarterData != null && periodType === 'quarter') {
      const cacheKey = getCacheKey(filters, 'quarter')
      const cachedData = dataCache[cacheKey]
      if(cachedData) {
        setTableData(dataCache[cacheKey])
      } else {
        const quarterTableData = prepareTableData(allQuarterData, 'quarter', filters, storeMap, areasActive)
        dataCache[cacheKey] = quarterTableData
        setTableData(quarterTableData)
      }
    }   
  }, [allQuarterData, filters, periodType])
  
  useEffect(() => {
    if(allMonthData != null && periodType === 'month') {
      const cacheKey = getCacheKey(filters, 'month')
      const cachedData = dataCache[cacheKey]
      if(cachedData) {
        setTableData(dataCache[cacheKey])
      } else {
        const monthTableData = prepareTableData(allMonthData, 'month', filters, storeMap, areasActive)
        dataCache[cacheKey] = monthTableData
        setTableData(monthTableData)
      }
    }
  }, [allMonthData, filters, periodType])  

  const [reportHeaderControls, setReportHeaderControls] = useState([
    {
      type: 'configButton',
      disabled: false,
      onClick: handleConfiguratorOpen
    },
    {
      type: 'csvButton',
      data: [],
      columns: [],
    },
  ])
  
  useEffect(() => {
    if(tableData.length > 0){
      const processedData = getChartData(tableData, periodType, reportType)
      setChartData(processedData)

      const csvColumns = getCSVColumns('yesAreas')
      const csvKey = `${periodType}${reportType}`  

      const csvData = flattenToCSV(tableData, periodType, 'yesAreas')

      setReportHeaderControls(prevControls => {
        return prevControls.map(control => {
          if(control.type === 'csvButton'){
            return {
              ...control,
              data: csvData,
              columns: csvColumns[csvKey],
            }
          } else {
            return control
          }
        })
      })
    }    
  }, [periodType, reportType, tableData])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {
        dataLoading ?
        <Progress /> :
        <MDBox
          mb={3}
          sx={{
            width: pageWidth,
            mx: 'auto'
          }}
        >
          <Card>
            <div id='report-header'>
              <RDReportHeader 
                style={{
                  bgColor: '#29261f',
                  hoverColor: '#906e07',
                  textColor: '#cf9e0b'
                }}
                title={ title }
                dataReady={true}
                controls={ reportHeaderControls }
              />
            </div>
            <MDBox
              sx={{
                mx: 2,
                mb: 3,
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <R0004Chart 
                icon={{
                  component: 'insights'
                }}
                chart={chartData}
                height={ reportHeight * .33}
              />
              <R0004Table 
                data={tableData} 
                periodType={periodType}
                reportType={reportType}
                height={ reportHeight * .67 }
              />
            </MDBox>
          </Card>
          <RDConfigurator 
            report='R0004'
            component={
              <R0004Config 
                products={ products }
                selectedYears={ selectedYears }
                setSelectedYears={ setSelectedYears }
                period={ periodType }
                setPeriod={ handlePeriodTypeChange }
                reportType={ reportType }
                setReportType={ handleTypeChange }
                selectStores={ selectStores }
                selectProducts={
                  selectProducts
                }
                stores={ stores }
                years={ years }
                areasActive={ areasActive }
                setAreasActive={ handleAreasActiveChange }
              />
            }
          />
          <R0004SelectDialog 
            open={ openProductDialog }
            cancel={ cancelProductDialog }
            submit={ handleProductSelections }
            title='Select Products'
            items={ selectedProducts }
            nested={ false }
          />
          <R0004SelectDialog 
            open={ openStoreDialog }
            cancel={ cancelStoreDialog }
            submit={ handleStoreSelections }
            title='Select Stores'
            items={ selectedStores }
            nested={ true }
          />
        </MDBox>
      }
    </DashboardLayout>
  )
}
