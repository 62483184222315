import React, { useCallback, useEffect, useState } from 'react'

import L from 'leaflet'
import "./ShowClusters.css"

import { Marker, Popup, useMap } from 'react-leaflet'

import useSupercluster from 'use-supercluster'

import afIcon from 'assets/images/afa92/af2.png'

import MDBox from 'components/MDBox'

const icons = {}
const fetchIcon = (count, size) => {
  
  if(!icons[count]) {
    icons[count] = L.divIcon({
      // html: `<div
      //     style='color: #fff; background-color: #1978c8; border-radius: 50%; width: 18px; height: 18px;  display: flex; justify-content: center; align-items: center; font-size: xx-small; border: 2px solid blue'
      //   >
      //     ${count}
      //   </div>`,
      html: `<div class='cluster-marker' style='width: ${size}px; height: ${size}px;'>
        ${count}
      </div>`
    })
  }
  return icons[count]
}

const af = new L.Icon({
  iconUrl: afIcon,
  iconSize: [18, 18]
})


export default function ShowClusters({ data }){
  console.log('data', data)
  

  const maxZoom = 22
  const [bounds, setBounds] = useState(null)
  const [zoom, setZoom] = useState(12)
  const map = useMap()

  // check for state changes
  useEffect(() => {
    console.log('zoom changed', zoom)
  }, [zoom])
  useEffect(() => {
    console.log('bounds changed', bounds)
  }, [bounds])

  // get map bounds
  function updateMap() {
    console.log('updating')
    const b = map.getBounds()
    setBounds([
      b.getSouthWest().lng,
      b.getSouthWest().lat,
      b.getNorthEast().lng,
      b.getNorthEast().lat,
    ])
    setZoom(map.getZoom())
  }

  const onMove = useCallback(() => {
    updateMap()
  }, [map])

  useEffect(() => {
    updateMap()
  }, [map])

  useEffect(() => {
    map.on('move', onMove)
    return () => {
      map.off('move', onMove)
    }
  }, [map, onMove])

  const points = data.map((x) => ({
    type: 'Feature',
    properties: { 
      cluster: false, 
      id: x.id, 
      category: x.category,
      title: x.title
    },
    geometry: {
      type: 'Point',
      coordinates: [
        parseFloat(x.position.long),
        parseFloat(x.position.lat),
      ]
    }
  }))

  console.log('points', points)

  const { clusters, supercluster } = useSupercluster({
    points: points,
    bounds: bounds,
    zoom: zoom,
    options: {
      radius: 100,
      maxZoom: 19,
    }
  })

  return (
    <>
      {clusters.map((cluster) => {
        // every cluster point has coordinates
        const [longitude, latitude] = cluster.geometry.coordinates
        // the point may be either a cluster or single point
        const { cluster: isCluster, point_count: pointCount } = cluster.properties
        
        // we have a cluster to render
        if(isCluster){
          return (
            <Marker 
              key={`cluster-${cluster.id}`}
              position={[latitude, longitude]}
              icon={fetchIcon(
                pointCount,
                10 + (pointCount / points.length) * 40
              )}
              eventHandlers={{
                click: () => {
                  const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(cluster.id),
                    maxZoom
                  )
                  map.setView([latitude, longitude], expansionZoom, {
                    animate: true
                  })
                }
              }}
            />
          )
        } 

        // we have a single point to render
        return (
          <Marker 
            key={`member-${cluster.properties.id}`}
            position={[latitude, longitude]}
            icon={af}
          >
            <Popup>
              <h4>{cluster.properties.title}</h4>
            </Popup>
          </Marker>
        )
      })}
    </>
  )
}