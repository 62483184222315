import { AGGREGATION_TYPES, METRIC_TYPES } from '../../../../constants';
import { formatPercent } from '../../../../utils/formatters';

export const laborMetrics = {
  cLaborDollars: {
    type: AGGREGATION_TYPES.SUM,
    metricType: METRIC_TYPES.LABOR
  },
  cLaborPct: {
    type: AGGREGATION_TYPES.CALCULATED,
    metricType: METRIC_TYPES.LABOR,
    inputs: ['cLaborDollars', 'cNSTotal'],
    calculate: (ld, ns) => formatPercent( ld / ns * 100)
  }
}