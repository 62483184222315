import { useEffect, useState } from 'react'

// AWS Amplify
import { Auth, Hub } from 'aws-amplify'

// @mui material components
import { Card, Stack } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/background-01.jpg";


const initialFormState = {
  username: '',
  password: '',
  newPassword: '',
  email: '',
  authCode: '',
  formType: 'signIn'
}

function RDLogin() {
  const [formState, updateFormState] = useState(initialFormState)
  const [newUser, setNewUser] = useState(null)

  function onChange(event) {
    event.persist()
    updateFormState(() => ({...formState, [event.target.name]: event.target.value }))
  }

  const { formType } = formState

  async function signIn() {
    const { username, password } = formState
    await Auth.signIn(username, password)
    .then(user => {
      if(user.challengeName === 'NEW_PASSWORD_REQUIRED'){
        setNewUser(user)
        updateFormState(() => ({...formState, formType: 'confirmSignUp'}))
      } else {
        updateFormState(() => ({...formState, formType: 'signedIn'
        }))
      }
    }).catch(err => {
      console.log('error signing in', err)
      if(err.name === 'PasswordResetRequiredException'){
        resetPassword()
      }
    })
  }

  function resetPassword() {
    updateFormState(() => ({
      ...formState, formType: 'forgotPassword'
    }))
  }

  function backToSignIn() {
    updateFormState(() => ({
      ...formState, formType: 'signIn'
    }))
  }

  async function confirmSignUp() {
    const { newPassword } = formState
    await Auth.completeNewPassword(
      newUser,
      newPassword,
    ).then(user => {
      updateFormState(() => ({...formState, formType: 'signedIn'
      }))
    }).catch(err => {
      console.log('error completing new password', err)
    })
  }

  async function forgotPassword() {
    const { username } = formState
    await Auth.forgotPassword(username)
      .then(data => {
        updateFormState(() => ({...formState, formType: 'forgotPasswordSubmit'}))
      })
      .catch(err => console.log('error requesting password reset code', err))
  }

  async function forgotPasswordSubmit() {
    const { username, authCode, newPassword } = formState
    await Auth.forgotPasswordSubmit(
      username,
      authCode,
      newPassword
    ).then(data => {
      updateFormState(() => ({
        ...formState, formType: 'signIn'
      }))
    }).catch(err => console.log('error resetting password', err))
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        {
          formType === 'signIn' && (
            <MDBox>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography 
                  variant="h4" fontWeight="medium" color="white" mt={1}
                >
                  Sign In
                </MDTypography>          
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox
                  component='form'
                  role='form'
                >
                  <MDBox mb={2}>
                    <MDInput 
                      type='text'
                      name='username' 
                      onChange={onChange} 
                      // placeholder='Enter your username'
                      fullWidth 
                      required={true}
                      label='Username'
                    />
                  </MDBox>
                  <MDBox >
                    <MDInput 
                      type='password'
                      name='password' 
                      onChange={onChange} 
                      fullWidth
                      required={true}
                      label='Password' 
                    />
                  </MDBox>
                  <MDBox>
                    <Stack direction='row' alignItems='center'>
                      <MDTypography variant='caption'>
                        Forgot your password?
                      </MDTypography>
                      <MDButton 
                        onClick={resetPassword} 
                        variant='text'
                        color='info'
                        size='small'
                        sx={{
                          textTransform: 'none',
                          paddingLeft: 1
                        }}
                      >
                        Reset Password
                      </MDButton>
                    </Stack>
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton 
                      variant='gradient'
                      color='info'
                      fullWidth
                      onClick={signIn}
                    >
                      Sign In
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
          )
        }
        {
          formType === 'confirmSignUp' && (
            <MDBox>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography 
                  variant="h4" fontWeight="medium" color="white" mt={1}
                >
                  Change Password
                </MDTypography>          
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox
                  component='form'
                  role='form'
                >
                  <MDBox mb={2}>
                    <MDInput 
                      type='password'
                      name='newPassword' 
                      onChange={onChange}
                      fullWidth
                      required={true}
                      label='New Password'
                    />
                  </MDBox>
                  <MDBox>
                    <Stack direction='row' justifyContent='space-between'>
                      <MDButton
                        variant='text'
                        color='info'
                        size='small'
                        sx={{
                          textTransform: 'none',
                          paddingLeft: 1
                        }}
                        onClick={backToSignIn}
                      >
                        Back to Sign In
                      </MDButton>
                      <MDButton 
                        variant='gradient'
                        color='info'
                        onClick={confirmSignUp}
                      >
                        Change
                      </MDButton>
                    </Stack>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
          )
        }
        {
          formType === 'forgotPassword' &&
          (
            <MDBox>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography 
                  variant="h4" fontWeight="medium" color="white" mt={1}
                >
                  Reset Password
                </MDTypography>          
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox
                  component='form'
                  role='form'
                >
                  <MDBox mb={2}>
                    <MDInput 
                      type='text'
                      name='username' 
                      onChange={onChange}
                      fullWidth
                      required={true}
                      label='Username'
                    />
                  </MDBox>
                  <MDBox>
                    <Stack direction='row' justifyContent='space-between'>
                      <MDButton
                        variant='text'
                        color='info'
                        size='small'
                        sx={{
                          textTransform: 'none',
                          paddingLeft: 1
                        }}
                        onClick={backToSignIn}
                      >
                        Back to Sign In
                      </MDButton>
                      <MDButton 
                        variant='gradient'
                        color='info'
                        onClick={forgotPassword}
                      >
                        Send Code
                      </MDButton>
                    </Stack>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
          )
        }
        {
          formType === 'forgotPasswordSubmit' &&
          (
            <MDBox>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography 
                  variant="h4" fontWeight="medium" color="white" mt={1}
                >
                  Reset Password
                </MDTypography>          
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox
                  component='form'
                  role='form'
                >
                  <MDBox mb={2}>
                    <MDInput 
                      type='text'
                      name='authCode' 
                      onChange={onChange}
                      fullWidth
                      required={true}
                      label='Verification Code'
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput 
                      type='password'
                      name='newPassword' 
                      onChange={onChange}
                      fullWidth
                      required={true}
                      label='New Password'
                    />
                  </MDBox>
                  <MDBox>
                    <Stack direction='row' justifyContent='space-between'>
                      <MDButton
                        variant='text'
                        color='info'
                        size='small'
                        sx={{
                          textTransform: 'none',
                          paddingLeft: 1
                        }}
                        onClick={backToSignIn}
                      >
                        Back to Sign In
                      </MDButton>
                      <MDButton 
                        variant='gradient'
                        color='info'
                        onClick={forgotPasswordSubmit}
                      >
                        Submit
                      </MDButton>
                    </Stack>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
          )
        }
      </Card>
    </BasicLayout>
  )

}

export default RDLogin