import React, { useState } from 'react'

import { Container, Grid } from '@mui/material'

// Material Dashboard 2 Pro React components
import MDBox from 'components/MDBox'
import MDProgress from 'components/MDProgress'
import MDTypography from 'components/MDTypography'

import THSettingTable from './THSettingTable'

import ThresholdScale from './ThresholdScale'

export default function ThresholdSettings(props){
  console.log('ThresholdSettings', props)

  const dataArr = props.data

  const [netSalesLoading, setNetSalesLoading] = useState(false)

  let netSalesData = []
  let avgCheckData = []
  let splhBOHData = []
  let splhFOHData = []
  let splhTotalData = []

  // Extract thresholds
  const convertArrtoObj = (data, key) => {
    const initialValue = {}
    return data.reduce((obj, item) => {
      const newTH = item.thresholds.map(x => ({...x, type: item.type}))
      item.thresholds = newTH
      return {
        ...obj,
        [item[key]]: item,
      }
    }, initialValue)
  }

  if(dataArr.length > 0){
    const thObj = convertArrtoObj(dataArr, 'type')

    console.log('thObj', thObj)

    netSalesData = thObj.netSales.thresholds
    avgCheckData = thObj.avgCheck.thresholds
    splhBOHData = thObj.splhBOH.thresholds
    splhFOHData = thObj.splhFOH.thresholds
    splhTotalData = thObj.splhTotal.thresholds
  }

  function setLoading(values){
    switch(values.type){
      case 'netSales':
        setNetSalesLoading(values.setting)
        break;
      default:
        // no action
    }
  }

  return (
    <Container 
      maxWidth='lg'
      sx={{
        pb: 4,
      }}
    >
      <MDBox
        sx={{
          backgroundColor: '#e0e0e0',
          borderRadius: '16px',
        }}
      >
        <MDBox py={1} px={3}>
          <MDTypography variant='h5'>
            Threshold Settings
          </MDTypography>
        </MDBox>
        <MDBox
          sx={{
            backgroundColor: '#fff',
            borderRadius: '0 0 16px 16px',
            width: '100%',
            p: 2,
            flexGrow: 1
          }}
        >
          <Grid 
            container 
            columnSpacing={{xs: 1, lg: 3}}
            px={2}
          >
            <Grid 
              item 
              xs={12}
              // lg={6}
            >
              <ThresholdScale />
            </Grid>
            <Grid item xs={12} lg={6}>
              { 
                netSalesLoading &&
                <MDProgress />
              }
              <THSettingTable 
                data={netSalesData}
                title={'Net Sales'}
                width={'100%'}
                tableType={'netSales'}
                update={ props.update }
                setLoading={ setLoading }
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <THSettingTable 
                data={avgCheckData}
                title={'Average Check'}
                width={'100%'}
                tableType={'avgCheck'}
                update={ props.update }
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <THSettingTable 
                data={splhTotalData}
                title={'SPLH Total'}
                width={'100%'}
                tableType={'splhTotal'}
                update={ props.update }
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <THSettingTable 
                data={splhBOHData}
                title={'SPLH BOH'}
                width={'100%'}
                tableType={'splhBOH'}
                update={ props.update }
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <THSettingTable 
                data={splhFOHData}
                title={'SPLH FOH'}
                width={'100%'}
                tableType={'splhFOH'}
                update={ props.update }
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>      
    </Container>
  )
}