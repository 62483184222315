import React from 'react'
import PropTypes from 'prop-types'

// @mui components
import { Dialog, DialogTitle, DialogContent, Grid } from '@mui/material'

// formik components
import { Formik, Form } from 'formik'
import Select from 'components/FormsUI/Select'
import Textfield from 'components/FormsUI/Textfield'
import * as Yup from 'yup'

// Material Dashboard Pro 2 components
import MDButton from 'components/MDButton'
import Select_Rd from 'components/FormsUI/Select/Select_Rd'


export default function AddAreaDialog(props){
  const managerList = props.areaMgrs

  const existingAreas = props.existingAreas
  
  function handleSubmit(values){
    props.submit(values)
  }

  const INITIAL_FORM_STATE = {
    areaName: '',
    areaManager: ''
  }

  const FORM_VALIDATION = Yup.object().shape({
    areaName: Yup.string()
      .notOneOf(existingAreas, 'Area already defined.')
      .required('Required'),
    areaManager: Yup.string()
      .required('Required')
  })

  return (
    <Dialog
      open={ props.open }
    >
      <DialogTitle>Add Area</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={INITIAL_FORM_STATE}
          validationSchema={FORM_VALIDATION}
          onSubmit={ (values) => { handleSubmit(values) }}
        >
          {({ isValid, dirty }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} 
                  // Included to give spacing to 'role' Texfield
                />

                <Grid item xs={12}>
                  <Textfield 
                    name='areaName'
                    label='Area'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Select_Rd 
                    name='areaManager'
                    options={ managerList }
                    label='Area Manager'
                  />
                </Grid>
                
                <Grid item xs={6} />
                <Grid item xs={3}>
                  <MDButton variant='contained' color='info' onClick={ props.close }>
                    Cancel
                  </MDButton>
                </Grid>
                <Grid item xs={3}>
                  <MDButton variant='contained' color='info' type='submit' disabled={!(dirty && isValid)}>
                    Submit
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

AddAreaDialog.propTypes = {
  close: PropTypes.func,
  existingAreas: PropTypes.array,
  areaMgrs: PropTypes.object,
  open: PropTypes.bool,
  submit: PropTypes.func,
}