import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// AWS Amplify components
import { API } from 'aws-amplify'

// GraphQL
import { listAreas2 } from 'graphql/queries'

import { Autocomplete, Stack, TextField } from '@mui/material'

import AutocompleteDemDark from 'components/RDAutocomplete/AutocompleteDemDark'
import { useUser } from 'components/RDAuthContext'

import { createTheme, ThemeProvider } from '@mui/material/styles'

function StoreSelector(props){
  console.log('StoreSelector props', props)

  const { user } = useUser()
  const role = user.role

  const [areas, setAreas] = useState([])
  const [area, setArea] = useState('All')
  const [storeData, setStoreData] = useState(props.storeData)
  const [stores, setStores] = useState([''])
  const [store, setStore] = useState('')
  const [selectedStores, setSelectedStores] = useState(props.storeArr)

  useEffect(() => {
    setStoreData(props.storeData)
  }, [props.storeData])
  // Get data when component loads
  // useEffect(() => {
  //   getAreas()
  // }, [])

  useEffect(() => {
    setSelectedStores(props.storeArr)
  }, [props.storeArr])

  // Set areas for dropdown
  useEffect(() => {
    // console.log('selectedStores', selectedStores)
    let areaArr = []
    if(storeData.length > 0){
      if(selectedStores.length === 0){
        // All stores
        areaArr = storeData.map(x => x.areaName)
        areaArr = areaArr.filter(area => area !== 'Ghost Kitchen')
        areaArr.push('All')
        areaArr.sort()
        console.log('areaArr', areaArr)
        setAreas(areaArr)
        setArea('All')
      } else {
        // Selected stores
        console.log('selectedStores', selectedStores)
        selectedStores.forEach((store) => {
          console.log('store', store)
          storeData.forEach((area, index) => {
            console.log('area', area)
            const storeIndex = area.stores.findIndex(x => x.shortName === store)
            if(storeIndex !== -1){
              const areaIndex = areaArr.findIndex(x => x === area.areaName)
              if(areaIndex === -1){
                areaArr.push(area.areaName)
              }
            }
          })
        })
        console.log('areaArr', areaArr)
        if(areaArr.length > 1){
          areaArr.push('All')
          areaArr.sort()
          setArea('All')
        } else {
          setArea(areaArr[0])
        }
        setAreas(areaArr)
      }
    }
  }, [storeData, selectedStores])

  // useEffect(() => {
  //   console.log('areas', areas)
  // }, [areas])

  // useEffect(() => {
  //   console.log('area', area)
  // }, [area])

  // async function getAreas(){
  //   console.log('getAreas()...')
  //   try {
  //     const queryResponse = await API.graphql({
  //       query: listAreas2,
  //       variables: {
  //         report: 'r0001',
  //         user: user.username,
  //         role: role
  //       }
  //     })
  //     // console.log('queryResponse', queryResponse)
  //     let areas2 = queryResponse.data.listAreas2.map(x => x.area)
  //     // console.log('areas2', areas2)
  //     areas2.push('All')
  //     areas2.sort()
  //     setAreas(areas2)
  //     setStoreData(queryResponse.data.listAreas2)
  //   } catch (err) {
  //     console.log('Error fetching Areas data', err)
  //   }
  // }  

  // useEffect(() => {
  //   console.log('areas', areas)
  // }, [areas])

  useEffect(() => {
    let storeArr = []
    if(storeData.length > 0){
      if(selectedStores.length === 0){
        // All Stores
        if(area === 'All'){
          storeData.forEach( x => {
            const { stores } = x
            console.log('stores', stores)
            stores.forEach( store => {
              if(store.type !== 'Ghost Kitchen'){
                storeArr.push(store.shortName)
              }
            })
          })
          storeArr.sort()
          setStores(storeArr)
        } else {
          const storeIdx = storeData.findIndex( x => x.areaName === area)
          if(storeIdx !== -1){
            const sdStores = storeData[storeIdx].stores
            sdStores.forEach( store => {
              storeArr.push(store.shortName)
            })
            storeArr.sort()
            setStores(storeArr)
          }
        }
      } else {
        if(area === 'All'){
          setStores(selectedStores)
        } else {
          const areaIdx = storeData.findIndex( x => x.areaName === area)
          if(areaIdx !== -1){
            const sdStores = storeData[areaIdx].stores
            selectedStores.forEach( store => {
              const storeIdx = sdStores.findIndex(x => x.shortName === store)
              if(storeIdx !== -1){
                storeArr.push(sdStores[storeIdx].shortName)
              }
            })
            storeArr.sort()
            setStores(storeArr)
          }
        }
      }
    }

  }, [area, storeData, selectedStores])

  useEffect(() => {
    if(stores[0] !== '' && stores.length > 0){
      const storeIdx = stores.findIndex(x => x === store)
      if(storeIdx === -1){
        setStore(stores[0])
      }
    }

  }, [stores])

  function handleStore(newStore){
    setStore(newStore)
  }

  useEffect(() => {
    props.onChange(store)
  }, [store])

  return (
    <Stack direction='column' spacing={2}>
      <AutocompleteDemDark
        id='areaSelector'
        options={ areas }
        onChange={(event, newArea) => {
          setArea(newArea)
        }}
        size='small'
        variant='outlined'
        defaultValue={ area }
        // value={area}
        disableClearable={true}
        sx={{ width: 275 }}
        renderInput={(params) => 
          <TextField 
            {...params} 
            label='Area' 
            variant='outlined' 
            
          />
        }
        disabled={ !props.active }
      />
      <AutocompleteDemDark
        id='storeSelector'
        options={ stores }
        value={ store }
        size='small'
        variant='outlined'
        onChange={(event, newStore) => {
          handleStore(newStore)
        }}
        disableClearable={true}
        sx={{ width: 275 }}
        renderInput={(params) => 
          <TextField 
            {...params} 
            label='Store' 
            variant='outlined' 
            
          />
        }
        disabled={ !props.active }
      />
    </Stack>
    
  )

}

StoreSelector.propTypes = {
  active: PropTypes.bool,
  onChange: PropTypes.func,
  storeArr: PropTypes.array,
  storeData: PropTypes.array,
}

export default StoreSelector