// @mui components
import { Button, Dialog, DialogTitle, DialogContent, Stack, Typography } from '@mui/material'

// formik components
import { Formik, Form } from 'formik'
import Textfield from 'components/FormsUI/Textfield'
import * as Yup from 'yup'

import RulesBox from './RulesBox'

export default function EditProfileDialog({
  close,
  existingNames,
  open,
  profile,
  submit
}){
  console.log('EditProfileDialog', profile)

  const INITIAL_FORM_STATE = {
    name: profile.name,
    rules: profile.thresholdBands
  }
  
  const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string()
      .required('Required')
      .test(
        'Unique',
        'Name already exists',
        async name => {
          if(existingNames.includes(name)){
            return false;
          }
          return true;
        }
      ),
    rules: Yup.array()
      .of(
        Yup.object().shape({
          operator: Yup.string().required('Operator Required'),
          numberValue: Yup.array()
            .of(Yup.number())
            .min(1, 'Must have at least one value')
            .required('Value Required'),
          backgroundColor: Yup.string().required('Background Color Required'),
          color: Yup.string().required('Text Color Required'),
          fontWeight: Yup.string().required('Font Weight Required'),
        })
      )
      .min(1, 'Must have at least one rule')
  })

  function handleSubmit(values){
    
    values.id = profile.id
    
    submit(values)
    close()
  }
  
  return (
    <Dialog
      open={open}
      fullWidth={true}
    >
      <DialogTitle
        variant='subtitle2'
      >
        Edit Threshold Profile
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={INITIAL_FORM_STATE}
          validationSchema={FORM_VALIDATION}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          {
            formik => {
              const { isValid, dirty, errors } = formik
              console.log('formik', formik)

              return (
                <Form>
                  <Stack 
                    direction='column'
                    spacing={2}
                    sx={{
                      mt: 2
                    }}
                  >
                    <Textfield 
                      name='name'
                      label='Profile Name'
                    />
                    <Typography variant='caption'>
                      Rules are applied in the order shown.
                    </Typography>
                    <RulesBox 
                      rules={ formik.values.rules }
                      setRules={ (newRules) => formik.setFieldValue('rules', newRules)}
                    />
                    <Stack
                      direction='row'
                      justifyContent='flex-end'
                      spacing={2}
                    >
                      <Button
                        variant='contained'
                        onClick={close }
                        sx={{
                          width: 100
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant='contained'
                        type='submit'
                        disabled={
                          !(dirty && isValid)
                        }
                        sx={{
                          width: 100
                        }}
                      >
                        Submit
                      </Button>
                    </Stack>
                    <div>
                      {console.log('errors', errors)}
                    </div>
                  </Stack>
                </Form>
              )
            }
          }
        </Formik>
      </DialogContent>
    </Dialog>
  )
}