export const DAYS_OF_WEEK = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
export const WEEKDAYS = ['Mon', 'Tue', 'Wed', 'Thu']
export const NUM_DATA_ROWS = 11

export const THEO_HOURS_CONFIG = {
  foh: {
    weekday: 316,
    weekend: 230
  },
  boh: {
    weekday: 345,
    weekend: 316
  }
}