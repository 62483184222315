import { useEffect, useState } from 'react'
import PropTypes from "prop-types";

// AWS Amplify
import { API } from 'aws-amplify'
import { getReport } from 'graphql/queries'

// @mui material components
import { Grid, Icon, IconButton, Stack, TextField, Tooltip } from '@mui/material'

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDDatePicker from 'components/MDDatePicker'
import MDTypography from 'components/MDTypography'
import { useMaterialUIController, setOpenConfigurator } from 'context';

import ReactDatePicker from 'react-datepicker';

import { formatISO, getISOWeeksInYear, getISOWeek, getISOWeekYear, getYear, setWeek, nextMonday, startOfQuarter } from 'date-fns';

// RadixDash components
import Autocomplete_GrmHead from 'components/RDAutocomplete/Autocomplete_GrmHead'
import Button_GrmHead from 'components/RDButton/Button_GrmHead'
import Button_GrmDark from 'components/RDButton/Button_GrmDark'
import RDConfigurator from 'components/RDConfigurator'
import R0003IncentiveCellStyle from './R0003IncentiveCellStyle'
import R0003IncentivePDF from './R0003IncentivePDF'
import PeriodSelector from './R0003PeriodSelector'
import RDCSVExport from 'components/RDCSVExport'
import Progress from 'components/Progress/Progress'
import RDProgress from 'components/RDProgress'
import RDTable from 'components/RDTable'

import { useUser} from 'components/RDAuthContext'

function getYears(){
  // console.log('function: getYears')
  const currentYear = getISOWeekYear(new Date())
  let years = []
  for(let i = currentYear; i >= 2021; i--){
    years.push(i.toString())
  }
  return years
}

const csvHeaders = [
  {label: 'Week', key: 'week'},
  {label: 'FOH Theo', key: 'fTheo'},
  {label: 'FOH Sched', key: 'fSched'},
  {label: 'FOH Sched%', key: 'fSchedPct'},
  {label: 'FOH Act', key: 'fAct'},
  {label: 'FOH Act%', key: 'fActPct'},
  {label: 'BOH Theo', key: 'bTheo'},
  {label: 'BOH Sched', key: 'bSched'},
  {label: 'BOH Sched%', key: 'bSchedPct'},
  {label: 'BOH Act', key: 'bAct'},
  {label: 'BOH Act%', key: 'bActPct'},
]

export default function R0003Incentive(props){
  // console.log('component: R0003Incentive(props)', props)

  const { permissions, user } = useUser()

  const emptyRow = [
    {
      week: '',
      fTheo: '',
      fSched: '',
      fSchedPct: '',
      fAct: '',
      fActPct: '',
      bTheo: '',
      bSched: '',
      bSchedPct: '',
      bAct: '',
      bActPct: '',
      rowType: '', 
    },
  ]

  const q1Start = 1
  const q2Start = 14
  const q3Start = 27
  const q4Start = 40
  const today = new Date()
  const currentYear = getISOWeekYear(today)
  const currentWeek = getISOWeek(today)
  let currentQuarter
  if(currentWeek >= q1Start && currentWeek < q2Start){
    currentQuarter = 'Q1'
  } else if(currentWeek >= q2Start && currentWeek < q3Start) {
    currentQuarter = 'Q2'
  } else if(currentWeek >= q3Start && currentWeek < q4Start) {
    currentQuarter = 'Q3'
  } else {
    currentQuarter = 'Q4'
  }

  const [csvData, setCsvData] = useState([{}])
  const [data, setData] = useState(null)
  const [dataLoading, setDataLoading] = useState(true)
  const [tableData, setTableData] = useState(emptyRow)
  const [title, setTitle] = useState('SPLH Incentive Report')
  // const [period, setPeriod] = useState({year: currentYear.toString(), quarter: currentQuarter})
  const [quarter, setQuarter] = useState(currentQuarter)
  const [selectedStore, setSelectedStore] = useState(null)
  const [stores, setStores] = useState([''])
  const [year, setYear] = useState(currentYear.toString())

  useEffect(() => {
    console.log('dataLoading changed to', dataLoading)
  }, [dataLoading])

  useEffect(() => {
    // console.log('year or quarter changed changed, setting title')
    setTitle(
      `SPLH Incentive Report:
      ${quarter} ${year} ${selectedStore === null ? '': selectedStore}`)
    fetchData()
  }, [year, quarter])

  useEffect(() => {
    // Reset Title when user filters on Store
    setTitle(`SPLH Incentive Report:
    ${quarter} ${year} ${selectedStore === null ? '': selectedStore}`)
  }, [selectedStore])

  useEffect(() => {
    // console.log('data changed', data)
    if(data !== null){
      // get list of stores from data
      const storeList = data.map(x => x.store)
      storeList.sort()
      setStores(storeList)
    }
  }, [data])

  useEffect(() => {
    if(stores[0] !== ''){
      setSelectedStore(stores[0])
    }
  }, [stores])

  useEffect(() => {
    if(selectedStore !== null){
      const index = data.findIndex(row => row.store === selectedStore)
      if(index !== -1){
        const filteredData = data[index].values
        let exportData = [...filteredData]
        exportData.push({week: `Store: ${selectedStore}` })
        setTableData(filteredData)
        setCsvData(exportData)
      }
    }
  }, [selectedStore])

  useEffect(() => {
    console.log('tableData changed', tableData)
    if(tableData.length > 1){
      setDataLoading(false)
    }
  }, [tableData])

  const defaultWidth = 75
  const threshold = .05

  const headerStyle = {
    backgroundColor: '#bdbdbd',
    color: '#181818',
    borderLeft: '1px solid #fff',
    borderBottom: '1px solid #fff',
    padding: '0px 5px',
    fontSize: '.65re'
  }

  const columns = [
    {
      Header: 'w1',
      align: 'center',
      width: defaultWidth + 25,
      columnStyle: {
        backgroundColor: '#bdbdbd',
        color: '#bdbdbd',
        borderRight: '1px solid #fff',
        borderBottom: 'none',
        padding: '2px',
        fontSize: '.65rem'
      },
      columns: [
        {
          Header: 'w2',
          align: 'center',
          width: defaultWidth,
          columnStyle: {
            backgroundColor: '#bdbdbd',
            color: '#bdbdbd',
            borderRight: '1px solid #fff',
            borderBottom: 'none',
            padding: '2px',
            fontSize: '.65rem'
          },
          columns: [
            {
              Header: 'Week',
              accessor: 'week',
              disableGroupBy: true,
              disableSortBy: true,
              align: 'center',
              width: defaultWidth,
              columnStyle: {
                backgroundColor: '#bdbdbd',
                color: '#000',
                borderRight: '1px solid #fff',
                borderBottom: '1px solid #fff',
                padding: '2px',
                fontSize: '.65rem'
              }
            }
          ]
        }
      ]
    },
    {
      Header: 'Sales',
      align: 'center',
      width: defaultWidth,
      columnStyle: {
        backgroundColor: '#bdbdbd',
        color: '#000',
        borderRight: '1px solid #fff',
        borderBottom: '1px solid #fff',
        padding: '2px',
        fontSize: '.65rem'
      },
      columns: [
        {
          Header: 's1',
          align: 'center',
          width: defaultWidth,
          columnStyle: {
            backgroundColor: '#bdbdbd',
            color: '#bdbdbd',
            borderRight: '1px solid #fff',
            borderBottom: 'none',
            padding: '2px',
            fontSize: '.65rem'
          },
          columns: [
            {
              Header: 'Actual',
              accessor: 'nsAct',
              disableGroupBy: true,
              disableSortBy: true,
              align: 'center',
              width: defaultWidth,
              columnStyle: {
                backgroundColor: '#bdbdbd',
                color: '#000',
                borderRight: '1px solid #fff',
                borderBottom: '1px solid #fff',
                padding: '2px',
                fontSize: '.65rem'
              },
              Cell: ({cell}) => {
                if(cell.value !== undefined){
                  const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })
                  return (
                    formatter.format(cell.value)
                  )
                }
              }
            },
          ],
        },
        {
          Header: 's2',
          align: 'center',
          width: defaultWidth,
          columnStyle: {
            backgroundColor: '#bdbdbd',
            color: '#bdbdbd',
            borderRight: '1px solid #fff',
            borderBottom: 'none',
            padding: '2px',
            fontSize: '.65rem'
          },
          columns: [
            {
              Header: 'Forecast',
              accessor: 'nsForecast',
              disableGroupBy: true,
              disableSortBy: true,
              align: 'center',
              width: defaultWidth,
              columnStyle: {
                backgroundColor: '#bdbdbd',
                color: '#000',
                borderRight: '1px solid #fff',
                borderBottom: '1px solid #fff',
                padding: '2px',
                fontSize: '.65rem'
              },
              Cell: ({cell}) => {
                if(cell.value !== undefined){
                  const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })
                  return (
                    formatter.format(cell.value)
                  )
                }
              }
            },
          ],
        },
        {
          Header: 's3',
          align: 'center',
          width: defaultWidth,
          columnStyle: {
            backgroundColor: '#bdbdbd',
            color: '#bdbdbd',
            borderRight: '1px solid #fff',
            borderBottom: 'none',
            padding: '2px',
            fontSize: '.65rem'
          },
          columns: [
            {
              Header: 'Act %',
              accessor: 'nsActPct',
              disableGroupBy: true,
              disableSortBy: true,
              align: 'center',
              width: defaultWidth,
              columnStyle: {
                backgroundColor: '#bdbdbd',
                color: '#000',
                borderRight: '1px solid #fff',
                borderBottom: '1px solid #fff',
                padding: '2px',
                fontSize: '.65rem'
              }
            },
          ]
        }
      ]
    },
    {
      Header: 'FOH',
      align: 'center',
      width: defaultWidth * 6,
      columnStyle: {
        backgroundColor: '#bdbdbd',
        color: '#000',
        borderRight: '1px solid #fff',
        borderBottom: '1px solid #fff',
        padding: '2px',
        fontSize: '.65rem'
      },
      columns: [
        {
          Header: 'MODEL',
          align: 'center',
          width: defaultWidth * 3,
          columnStyle: {
            backgroundColor: '#bdbdbd',
            color: '#000',
            borderRight: '1px solid #fff',
            borderBottom: '1px solid #fff',
            padding: '2px',
            fontSize: '.65rem'
          },
          columns: [
            {
              Header: 'Theo',
              accessor: 'fTheo',
              disableGroupBy: true,
              disableSortBy: true,
              align: 'center',
              width: defaultWidth,
              columnStyle: {
                backgroundColor: '#bdbdbd',
                color: '#000',
                borderRight: '1px solid #fff',
                borderBottom: '1px solid #fff',
                padding: '2px',
                fontSize: '.65rem'
              }
            },
            {
              Header: 'Sched',
              accessor: 'fSched',
              disableGroupBy: true,
              disableSortBy: true,
              align: 'center',
              width: defaultWidth,
              columnStyle: {
                backgroundColor: '#bdbdbd',
                color: '#000',
                borderRight: '1px solid #fff',
                borderBottom: '1px solid #fff',
                padding: '2px',
                fontSize: '.65rem'
              }
            },
            {
              Header: 'Sched %',
              accessor: 'fSchedPct',
              disableGroupBy: true,
              disableSortBy: true,
              align: 'center',
              width: defaultWidth,
              columnStyle: {
                backgroundColor: '#bdbdbd',
                color: '#000',
                borderRight: '1px solid #fff',
                borderBottom: '1px solid #fff',
                padding: '2px',
                fontSize: '.65rem'
              }
            },
          ]
        },
        {
          Header: 'ACTUAL',
          align: 'center',
          width: defaultWidth * 3,
          columnStyle: {
            backgroundColor: '#bdbdbd',
            color: '#000',
            borderRight: '1px solid #fff',
            borderBottom: '1px solid #fff',
            padding: '2px',
            fontSize: '.65rem'
          },
          columns: [
            {
              Header: 'Theo',
              accessor: 'fAdjTheo',
              disableGroupBy: true,
              disableSortBy: true,
              align: 'center',
              width: defaultWidth,
              columnStyle: {
                backgroundColor: '#bdbdbd',
                color: '#000',
                borderRight: '1px solid #fff',
                borderBottom: '1px solid #fff',
                padding: '2px',
                fontSize: '.65rem'
              }
            },
            {
              Header: 'Act',
              accessor: 'fAct',
              disableGroupBy: true,
              disableSortBy: true,
              align: 'center',
              width: defaultWidth,
              columnStyle: {
                backgroundColor: '#bdbdbd',
                color: '#000',
                borderRight: '1px solid #fff',
                borderBottom: '1px solid #fff',
                padding: '2px',
                fontSize: '.65rem'
              }
            },
            {
              Header: 'Act %',
              accessor: 'fActPct',
              disableGroupBy: true,
              disableSortBy: true,
              align: 'center',
              width: defaultWidth,
              columnStyle: {
                backgroundColor: '#bdbdbd',
                color: '#000',
                borderRight: '1px solid #fff',
                borderBottom: '1px solid #fff',
                padding: '2px',
                fontSize: '.65rem'
              }
            },
          ]
        }
      ]
    },
    {
      Header: 'BOH',
      align: 'center',
      width: defaultWidth * 6,
      columnStyle: {
        backgroundColor: '#bdbdbd',
        color: '#000',
        borderBottom: '1px solid #fff',
        padding: '2px',
        fontSize: '.65rem'
      },
      columns: [
        {
          Header: 'MODEL',
          align: 'center',
          width: defaultWidth * 3,
          columnStyle: {
            backgroundColor: '#bdbdbd',
            color: '#000',
            borderRight: '1px solid #fff',
            borderBottom: '1px solid #fff',
            padding: '2px',
            fontSize: '.65rem'
          },
          columns: [
            {
              Header: 'Theo',
              accessor: 'bTheo',
              disableGroupBy: true,
              disableSortBy: true,
              align: 'center',
              width: defaultWidth,
              columnStyle: {
                backgroundColor: '#bdbdbd',
                color: '#000',
                borderRight: '1px solid #fff',
                borderBottom: '1px solid #fff',
                padding: '2px',
                fontSize: '.65rem'
              }
            },
            {
              Header: 'Sched',
              accessor: 'bSched',
              disableGroupBy: true,
              disableSortBy: true,
              align: 'center',
              width: defaultWidth,
              columnStyle: {
                backgroundColor: '#bdbdbd',
                color: '#000',
                borderRight: '1px solid #fff',
                borderBottom: '1px solid #fff',
                padding: '2px',
                fontSize: '.65rem'
              }
            },
            {
              Header: 'Sched %',
              accessor: 'bSchedPct',
              disableGroupBy: true,
              disableSortBy: true,
              align: 'center',
              width: defaultWidth,
              columnStyle: {
                backgroundColor: '#bdbdbd',
                color: '#000',
                borderRight: '1px solid #fff',
                borderBottom: '1px solid #fff',
                padding: '2px',
                fontSize: '.65rem'
              }
            },
          ]
        },
        {
          Header: 'ACTUAL',
          align: 'center',
          width: defaultWidth * 3,
          columnStyle: {
            backgroundColor: '#bdbdbd',
            color: '#000',
            borderRight: '1px solid #fff',
            borderBottom: '1px solid #fff',
            padding: '2px',
            fontSize: '.65rem'
          },
          columns: [
            {
              Header: 'Theo',
              accessor: 'bAdjTheo',
              disableGroupBy: true,
              disableSortBy: true,
              align: 'center',
              width: defaultWidth,
              columnStyle: {
                backgroundColor: '#bdbdbd',
                color: '#000',
                borderRight: '1px solid #fff',
                borderBottom: '1px solid #fff',
                padding: '2px',
                fontSize: '.65rem'
              }
            },
            {
              Header: 'Act',
              accessor: 'bAct',
              disableGroupBy: true,
              disableSortBy: true,
              align: 'center',
              width: defaultWidth,
              columnStyle: {
                backgroundColor: '#bdbdbd',
                color: '#000',
                borderRight: '1px solid #fff',
                borderBottom: '1px solid #fff',
                padding: '2px',
                fontSize: '.65rem'
              }
            },
            {
              Header: 'Act %',
              accessor: 'bActPct',
              disableGroupBy: true,
              disableSortBy: true,
              align: 'center',
              width: defaultWidth,
              columnStyle: {
                backgroundColor: '#bdbdbd',
                color: '#000',
                borderRight: '1px solid #fff',
                borderBottom: '1px solid #fff',
                padding: '2px',
                fontSize: '.65rem'
              },
            },
          ]
        }
      ]
    },
  ]

  function getWeeks(year, quarter){
    const numWeeks = getISOWeeksInYear(new Date(year, 6, 1))
    let firstWeekNum
    let lastWeekNum
    switch(quarter){
      case 'Q1':
        firstWeekNum = 1
        lastWeekNum = 13
        break;
      case 'Q2':
        firstWeekNum = 14
        lastWeekNum = 26
        break;
      case 'Q3':
        firstWeekNum = 27
        lastWeekNum = 39
        break;
      case 'Q4':
        firstWeekNum = 40
        lastWeekNum = numWeeks
        break;
      default:
        // Invalid quarter, default to Q1
        firstWeekNum = 1
        lastWeekNum = 13
    }
    const firstWeek = setWeek(nextMonday(new Date(year, 0, 22)), firstWeekNum, {
      weekStartsOn: 1,
      firstWeekContainsDate: 4,
    })
    const lastWeek = setWeek(nextMonday(new Date(year, 0, 22)), lastWeekNum, {
      weekStartsOn: 1,
      firstWeekContainsDate: 4,
    })

    const firstWeekISO = formatISO(firstWeek, { representation: 'date' })    
    const lastWeekISO = formatISO(lastWeek, { representation: 'date' })
    return { firstWeek: firstWeekISO, lastWeek: lastWeekISO }
  }

  async function fetchData(){
    console.log('fetchData')
    // Clear table
    setSelectedStore(null)
    setData(null)
    setTableData(emptyRow)
    // Start progress
    setDataLoading(true)    

    const weeks = getWeeks(year, quarter)

    try {
      const variableObj = {
        threshold: threshold,
        firstWeek: weeks.firstWeek,
        lastWeek: weeks.lastWeek
      }
      
      const reportVariables = [
        {
          name: 'arguments',
          value: JSON.stringify(variableObj)
        }
      ]
      const queryResponse = await API.graphql(
        {
          query: getReport,
          variables: {
            userName: user.userName,
            clientId: user.client,
            reportId: 'splhIncentive',
            reportVariables: reportVariables
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      )

      console.log('queryResponse', queryResponse)

      const responseDataJSON = queryResponse.data.getReport[0].data[0].rowCells[0].value

      const responseData = JSON.parse(responseDataJSON)

      for(let i=0; i < responseData.length; i++){
        const expandedData = responseData[i].values.map(x => ({...x, threshold: threshold}))
        responseData[i].values = expandedData
      }

      setData(responseData)

    } catch (err) {
      console.log('Error fetching SPLH Incentive data', err)
      setData(null)
      setTableData(emptyRow)
      setSelectedStore(null)
      setCsvData([{}])
    }
  }

   /******************************** 
   * CONFIG handling
   ********************************/

  // Configurator State controls
  const [controller, dispatch] = useMaterialUIController();  
  const {
    openConfigurator,
  } = controller;
  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  function exportPDF() {
    console.log('exportPDF')
    R0003IncentivePDF(
      tableData,
      title,
    )
  }

  /******************************** 
   * CONFIG renderer
   ********************************/
  function Config(props) {
    console.log('component: Config(props)', props)
    
   return (
      <MDBox 
        pt={0.5}
        pb={3}
        px={3}
      >
        <Stack direction='column'>
          <PeriodSelector 
            onSubmit={ handlePeriod }
            quarter={ quarter }
            year={ year }
          />
          
        </Stack>

      </MDBox>
    )
  }

  function handlePeriod(newYear, newQuarter){
    console.log(`handlePeriod: year: ${newYear}, quarter: ${newQuarter}`)
    
    setYear(newYear)
    setQuarter(newQuarter)
    handleConfiguratorOpen()
  }

  function handleStoreSelection(event, newStore){
    setSelectedStore(newStore)
  }

  return (
    <>
    { dataLoading ?
      <Progress /> :
      <MDBox textAlign='center'>
        {/* Report Header */}
        <MDBox 
          px={3} 
          pt={1}
          sx={{
            backgroundColor: '#29261f'
          }}
        >
          <Grid container pt={2}>
            <Grid
              item
              xs={5}
              sx={{
                display: 'flex',
                justifyContent: 'flex-start'
              }}
            >
              <MDTypography
                variant='h6'
                fontWeight='medium'
                style={{
                  color: '#cf9e0b'
                }}
              >
                { title }
              </MDTypography>
            </Grid>
            <Grid item xs={7}>
              <Stack 
                direction='row'
                justifyContent='space-between'
                spacing={3}
                mb={0}
              >
                <Autocomplete_GrmHead 
                  id='storeSelector'
                  options={ stores }
                  value={ selectedStore }
                  onChange={ handleStoreSelection }
                  disableClearable={true}
                  style={{ width: 200 }}
                  size='small'
                  renderInput={(params) => 
                    <TextField 
                      {...params}
                      label='Store'
                      variant='outlined'
                    />
                  }
                />
                <Stack
                  direction='row'
                  justifyContent='flex-end'
                  spacing={3}
                >
                  <RDCSVExport 
                    data={ csvData }
                    headers={ csvHeaders }
                    filename={ title }
                    style={{
                      background: '#cf9e0b',
                      backgroundHover: '#906e07',
                      color: '#29261f'
                    }}
                  />
                  <Tooltip title='Export to PDF'>
                    <Button_GrmHead
                      variant='gradient'
                      iconOnly
                      onClick={ exportPDF }
                    >
                      PDF
                    </Button_GrmHead>
                  </Tooltip>
                  <Tooltip title='Menu'>
                    <Button_GrmHead
                      variant='gradient'
                      iconOnly
                      onClick={() => props.setPage('actions')}
                    >
                      <Icon fontSize='large'>
                        menu
                      </Icon>
                    </Button_GrmHead>
                  </Tooltip>
                  <Tooltip title='Report Settings'>
                    <Button_GrmHead
                      variant='gradient'
                      iconOnly
                      onClick={ handleConfiguratorOpen}
                    >
                      <Icon fontSize='large'>
                        settings
                      </Icon>
                    </Button_GrmHead>
                  </Tooltip>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          
        </MDBox>

        {/* Report Body */}
        <MDBox textAlign='left'>
          <MDBox sx={{ backgroundColor: '#e0e0e0'}}>
            <RDTable 
              table={{
                columns: columns,
                rows: tableData
              }}
              editable={{
                onRowAdd: null,
                onRowEdit: null,
                onRowDelete: null
              }}
              cellStyle={ R0003IncentiveCellStyle }
              showTotalEntries={false}
              entriesPerPage={{defaultValue: 15}}
            />
          </MDBox>
        </MDBox>
        
        {/* Configurator */}
        <RDConfigurator 
          report='SPLH Incentive'
          component={
            <Config 
            />
          }
        />
      </MDBox> 
    }
    </>
  )
}

const testData = [
  {
    store: 'Domain',
    values: [  
      {
        week: '2022-10-03',
        fTheo: '298.49',
        fSched: '328.00',
        fSchedPct: '9.9%',
        fAct: '328.50',
        fActPct: '10.1%',
        bTheo: '289.86',
        bSched: '310.50',
        bSchedPct: '7.1%',
        bAct: '324.58',
        bActPct: '12.0%',
        rowType: 'weekRow', 
      },
      {
        week: '2022-10-10',
        fTheo: '303.84',
        fSched: '327.00',
        fSchedPct: '7.6%',
        fAct: '323.30',
        fActPct: '6.4%',
        bTheo: '293.46',
        bSched: '350.00',
        bSchedPct: '19.3%',
        bAct: '320.03',
        bActPct: '9.1%',
        rowType: 'weekRow', 
      },
      {
        week: '2022-10-17',
        fTheo: '304.68',
        fSched: '326.00',
        fSchedPct: '7.0%',
        fAct: '351.15',
        fActPct: '15.3%',
        bTheo: '294.29',
        bSched: '328.00',
        bSchedPct: '11.5%',
        bAct: '313.12',
        bActPct: '6.4%',
        rowType: 'weekRow', 
      },
      {
        week: '2022-10-24',
        fTheo: '350.48',
        fSched: '449.00',
        fSchedPct: '28.1%',
        fAct: '355.79',
        fActPct: '1.5%',
        bTheo: '329.72',
        bSched: '370.50',
        bSchedPct: '12.4%',
        bAct: '327.32',
        bActPct: '-0.7%',
        rowType: 'weekRow', 
      },
      {
        week: '2022-10-31',
        fTheo: '314.89',
        fSched: '342.50',
        fSchedPct: '8.8%',
        fAct: '369.02',
        fActPct: '17.2%',
        bTheo: '301.77',
        bSched: '343.00',
        bSchedPct: '13.7%',
        bAct: '314.30',
        bActPct: '4.2%',
        rowType: 'weekRow', 
      },
      {
        week: '2022-11-07',
        fTheo: '314.18',
        fSched: '355.50',
        fSchedPct: '13.2%',
        fAct: '353.31',
        fActPct: '12.5%',
        bTheo: '301.47',
        bSched: '360.50',
        bSchedPct: '19.6%',
        bAct: '323.03',
        bActPct: '7.2%',
        rowType: 'weekRow', 
      },
      {
        week: '2022-11-14',
        fTheo: '311.27',
        fSched: '383.75',
        fSchedPct: '23.3%',
        fAct: '362.77',
        fActPct: '16.5%',
        bTheo: '299.66',
        bSched: '313.25',
        bSchedPct: '4.5%',
        bAct: '254.37',
        bActPct: '-15.1%',
        rowType: 'weekRow', 
      },
      {
        week: '2022-11-21',
        fTheo: '311.27',
        fSched: '360.75',
        fSchedPct: '15.9%',
        fAct: '355.45',
        fActPct: '-17.9%',
        bTheo: '299.66',
        bSched: '297.25',
        bSchedPct: '-0.8%',
        bAct: '256.07',
        bActPct: '-14.5%',
        rowType: 'weekRow', 
      },
      {
        week: '2022-11-28',
        fTheo: '351.04',
        fSched: '336.00',
        fSchedPct: '-4.3%',
        fAct: '323.62',
        fActPct: '-7.8%',
        bTheo: '332.09',
        bSched: '339.00',
        bSchedPct: '2.1%',
        bAct: '340.21',
        bActPct: '2.4%',
        rowType: 'weekRow', 
      },
      {
        week: '2022-12-05',
        fTheo: '314.66',
        fSched: '326.50',
        fSchedPct: '3.8%',
        fAct: '425.38',
        fActPct: '35.2%',
        bTheo: '302.58',
        bSched: '364.50',
        bSchedPct: '20.5%',
        bAct: '328.33',
        bActPct: '26.4%',
        rowType: 'weekRow', 
      },
      {
        week: '2022-12-12',
        fTheo: '314.80',
        fSched: '333.25',
        fSchedPct: '5.9%',
        fAct: '0.00',
        fActPct: '-100.0%',
        bTheo: '302.31',
        bSched: '395.50',
        bSchedPct: '30.8%',
        bAct: '0.00',
        bActPct: '-5.0%',
        rowType: 'weekRow', 
      },
      {
        week: '2022-12-19',
        rowType: 'weekRow'
      },
      {
        week: '2022-12-26',
        rowType: 'weekRow'
      },
      {
        week: 'Score',
        fSchedPct: '2',
        fActPct: '1',
        bSchedPct: '3',
        bActPct: '3',
        rowType: 'scoreRow'
      }
    ]
  },  
  {
    store: 'Grapevine',
    values: [  
      {
        week: '2022-10-03',
        fTheo: '298.49',
        fSched: '328.00',
        fSchedPct: '9.9%',
        fAct: '328.50',
        fActPct: '10.1%',
        bTheo: '289.86',
        bSched: '310.50',
        bSchedPct: '7.1%',
        bAct: '324.58',
        bActPct: '12.0%',
        rowType: 'weekRow', 
      },
      {
        week: '2022-10-10',
        fTheo: '303.84',
        fSched: '327.00',
        fSchedPct: '7.6%',
        fAct: '323.30',
        fActPct: '6.4%',
        bTheo: '293.46',
        bSched: '350.00',
        bSchedPct: '19.3%',
        bAct: '320.03',
        bActPct: '9.1%',
        rowType: 'weekRow', 
      },
      {
        week: '2022-10-17',
        fTheo: '304.68',
        fSched: '326.00',
        fSchedPct: '7.0%',
        fAct: '351.15',
        fActPct: '15.3%',
        bTheo: '294.29',
        bSched: '328.00',
        bSchedPct: '11.5%',
        bAct: '313.12',
        bActPct: '6.4%',
        rowType: 'weekRow', 
      },
      {
        week: '2022-10-24',
        fTheo: '350.48',
        fSched: '449.00',
        fSchedPct: '28.1%',
        fAct: '355.79',
        fActPct: '1.5%',
        bTheo: '329.72',
        bSched: '370.50',
        bSchedPct: '12.4%',
        bAct: '327.32',
        bActPct: '-0.7%',
        rowType: 'weekRow', 
      },
      {
        week: '2022-10-31',
        fTheo: '314.89',
        fSched: '342.50',
        fSchedPct: '8.8%',
        fAct: '369.02',
        fActPct: '17.2%',
        bTheo: '301.77',
        bSched: '343.00',
        bSchedPct: '13.7%',
        bAct: '314.30',
        bActPct: '4.2%',
        rowType: 'weekRow', 
      },
      {
        week: '2022-11-07',
        fTheo: '314.18',
        fSched: '355.50',
        fSchedPct: '13.2%',
        fAct: '353.31',
        fActPct: '12.5%',
        bTheo: '301.47',
        bSched: '360.50',
        bSchedPct: '19.6%',
        bAct: '323.03',
        bActPct: '7.2%',
        rowType: 'weekRow', 
      },
      {
        week: '2022-11-14',
        fTheo: '311.27',
        fSched: '383.75',
        fSchedPct: '23.3%',
        fAct: '362.77',
        fActPct: '16.5%',
        bTheo: '299.66',
        bSched: '313.25',
        bSchedPct: '4.5%',
        bAct: '254.37',
        bActPct: '-15.1%',
        rowType: 'weekRow', 
      },
      {
        week: '2022-11-21',
        fTheo: '311.27',
        fSched: '360.75',
        fSchedPct: '15.9%',
        fAct: '355.45',
        fActPct: '-17.9%',
        bTheo: '299.66',
        bSched: '297.25',
        bSchedPct: '-0.8%',
        bAct: '256.07',
        bActPct: '-14.5%',
        rowType: 'weekRow', 
      },
      {
        week: '2022-11-28',
        fTheo: '351.04',
        fSched: '336.00',
        fSchedPct: '-4.3%',
        fAct: '323.62',
        fActPct: '-7.8%',
        bTheo: '332.09',
        bSched: '339.00',
        bSchedPct: '2.1%',
        bAct: '340.21',
        bActPct: '2.4%',
        rowType: 'weekRow', 
      },
      {
        week: '2022-12-05',
        fTheo: '314.66',
        fSched: '326.50',
        fSchedPct: '3.8%',
        fAct: '425.38',
        fActPct: '35.2%',
        bTheo: '302.58',
        bSched: '364.50',
        bSchedPct: '20.5%',
        bAct: '328.33',
        bActPct: '26.4%',
        rowType: 'weekRow', 
      },
      {
        week: '2022-12-12',
        fTheo: '314.80',
        fSched: '333.25',
        fSchedPct: '5.9%',
        fAct: '0.00',
        fActPct: '-100.0%',
        bTheo: '302.31',
        bSched: '395.50',
        bSchedPct: '30.8%',
        bAct: '0.00',
        bActPct: '-100.0%',
        rowType: 'weekRow', 
      },
      {
        week: '2022-12-19',
        rowType: 'weekRow'
      },
      {
        week: '2022-12-26',
        rowType: 'weekRow'
      },
      {
        week: 'Score',
        fSchedPct: '2',
        fActPct: '10',
        bSchedPct: '3',
        bActPct: '3',
        rowType: 'scoreRow'
      }
    ]
  },  
]

R0003Incentive.propTypes = {
  setPage: PropTypes.func
}