import React from 'react'
import PropTypes from 'prop-types'

// @mui components
import { Dialog, DialogTitle, DialogContent, Stack, TextField } from '@mui/material'

// formik components
import { Field, FieldArray, Formik, Form } from 'formik'
import Select from 'components/FormsUI/Select'
import Textfield from 'components/FormsUI/Textfield'
import * as Yup from 'yup'

// Material Dashboard Pro 2 components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// RadixDash components
import Button_GrmDark from 'components/RDButton/Button_GrmDark'
import Progress from 'components/Progress/Progress'
import Select_Rd from 'components/FormsUI/Select/Select_Rd'



export default function R0003EditTrainingDialog(props){
  console.log('R0003EditTrainingDialog', props)
  const rowData = props.rowData.values

  const typeObj = { BOH: 'BOH', FOH: 'FOH' }

  const INITIAL_FORM_STATE = {
    name: rowData.name,
    type: rowData.type,
    hours: rowData.hours
  }

  const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string()
      .required('Required'),
    type: Yup.string()
      .required('Required'),
    hours: Yup.number()
      .positive('Positive values only')
      .required('Required')
  })

  function handleSubmit(values){
    console.log('props.rowData', props.rowData)
    console.log('values', values)
    props.submit({
      index: props.rowData.index,
      oldData: props.rowData.original,
      newData: values
    })
  }

  return (
    <Dialog
      open={ props.open }
    >
      <DialogTitle>
        Edit Training Record
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={ INITIAL_FORM_STATE }
          validationSchema={ FORM_VALIDATION }
          enableReinitialize={ true }
          onSubmit={(values) => {
            console.log('values', values)
            handleSubmit(values)
          }}
        >
          {({ values, isValid, dirty }) => (
            <Form>
              <Stack
                direction='column'
                spacing={2}
                sx={{
                  mt: 1
                }}
              >
                <MDBox
                  sx={{
                    mb: 1,
                    mx: 'auto'
                  }}
                >
                  <MDTypography 
                    variant='subtitle2'
                    fontWeight='bold'
                  >
                    Week: {props.week}              
                  </MDTypography>
                  <MDTypography
                    variant='subtitle2'
                    fontWeight='bold'
                  >
                    Day: {props.day}              
                  </MDTypography>
                  <MDTypography
                    variant='subtitle2'
                    fontWeight='bold'
                  >
                    Store: {props.store}              
                  </MDTypography>
                </MDBox>
                <Textfield
                    name='name'
                    label='Name'
                />
                <Select_Rd 
                  name='type'
                  options={ typeObj }
                />
                <Textfield 
                  name='hours'
                  label='Hours'
                />
        
                <Stack 
                  direction='row'
                  spacing={2}
                  justifyContent='flex-end'
                >
                  <Button_GrmDark onClick={ props.cancel }>
                    Cancel
                  </Button_GrmDark>
                  <Button_GrmDark     
                    type='submit'
                    disabled={!isValid || !dirty}
                  >
                    Submit
                  </Button_GrmDark>
                </Stack>
              
              </Stack>
            </Form>
          )}
        </Formik>
      </DialogContent>

    </Dialog>
  )
}