export function processToastStoreData(toastDayData) {
  if (!toastDayData) return null

  return {
    actSales: parseFloat(toastDayData.netSales) || 0,
    actCheckCount: parseInt(toastDayData.checkCount) || 0,
    actFOH: parseFloat(toastDayData.actFOH) || 0,
    actBOH: parseFloat(toastDayData.actBOH) || 0,
    laborDollars: parseFloat(toastDayData.bohRegularDollars + toastDayData.bohOvertimeDollars + toastDayData.fohRegularDollars + toastDayData.fohOvertimeDollars) || 0,
    laborHours: parseFloat(toastDayData.bohRegularHours + toastDayData.bohOvertimeHours + toastDayData.fohRegularHours + toastDayData.fohOvertimeHours)
  }
}

export function processAlohaStoreData(dayData) {
  return {
    actSales: parseFloat((dayData.actSales || '').replace(/[$,\s]/g, '')) || 0,
    actCheckCount: parseFloat(dayData.actCheckCount) || 0,
    actFOH: parseFloat(dayData.actFOH || 0),
    actBOH: parseFloat(dayData.actBOH || 0)
  }
}