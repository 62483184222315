function R0003CellStyle(row, cell){

  const value = row.original
  const column = cell.column.id

  let leftBorder = ''
  let topBorder = '1px solid #e0e0e0'
  let cellColor = 'white'
  let fontSize = '.7rem'
  let padding = 0
  let paddingTop = '1px'
  let paddingRight = 0
  let paddingBottom = '1px'
  let paddingLeft = 0
  let textColor = 'black'
  let fontStyle = 'normal'
  let fontWeight = 'normal'
  let textAlign = 'center'
  let width = 75
  let tempColor

  // Determine Row Type
  let rowType
  switch(value.day){
    case 'Mon':
    case 'Tue':
    case 'Wed':
    case 'Thu':
    case 'Fri':
    case 'Sat':
    case 'Sun':
      rowType = 'dayRow'
      break;
    case 'Totals':
      rowType = 'totalRow'
      fontWeight = 'bold'
      textAlign = 'center'
      topBorder = '2px solid #000'
      break;
    case 'Proj Avg Wage':
    case 'Prev Week Proj Avg Wage':
      rowType = 'avgWageRow'
      fontWeight = 'bold'
      textAlign = 'center'
      break;
    case '':
      rowType = 'pctRow'
      fontWeight = 'bold'
      textAlign = 'center'
      break;
    default:
      rowType = 'storeRow'
      cellColor = '#A44C0E'
      fontWeight = 'bold'
      textColor = 'white'
      // textColor = '#F9E9D3'
      textAlign = 'left'
      paddingLeft = '10px'
  }

  switch(column){
    // DAY COLUMN
    case 'day':
      switch(rowType){
        case 'dayRow':
          paddingTop = 0
          paddingRight = 0
          paddingBottom = 0
          paddingLeft = 0
          break;
        // case 'pctRow':
        //   cellColor = 'grey'
        //   break;
        case 'totalRow':
        case 'avgWageRow':
        case 'pctRow':
          textAlign = 'left'
          paddingLeft = '10px'
          break;
        default:
      }
      break;

    // PROJECTED VALUE COLUMNS
    case 'projSales':
      switch(rowType){
        case 'dayRow':
          cellColor = '#f9e9d3'
          fontStyle = 'italic'
          break;
        case 'totalRow':
          cellColor = '#f9e9d3'
          break;  
        // case 'pctRow':
        //   cellColor = 'grey'
        //   break;
        default:
      }
      break;
    case 'projCheckCount':
    case 'theoFOH':
      switch(rowType){
        case 'dayRow':
        case 'totalRow':
          cellColor = '#f9e9d3'
          break;
      }
      break;
    case 'projAvgCheck':
    case 'theoBOH':
      switch(rowType){
        case 'dayRow':
        case 'totalRow':
          cellColor = '#f9e9d3'
          break;
        case 'avgWageRow':
        case 'pctRow':
          cellColor = 'grey'
          topBorder = 'none'
          break;
      }
      break;
    case 'projSPLH':
      width = 50
      switch(rowType){
        case 'dayRow':
        case 'totalRow':
          cellColor = '#f9e9d3'
          break;
        case 'avgWageRow':
        case 'pctRow':
          cellColor = 'grey'
          topBorder = 'none'
          break;
      }
      break; 

    // SCHEDULED VALUE COLUMNS
    case 'schedFOH':
    case 'schedBOH':
      width = 75
      switch(rowType){
        case 'dayRow':
          fontStyle = 'italic'
          break;
        case 'avgWageRow':
        case 'pctRow':
          cellColor = 'grey'
          topBorder = 'none'
          break;
      }
      break;
    case 'schedSPLH':
      width = 50
      switch(rowType){
        case 'avgWageRow':
        case 'pctRow':
          cellColor = 'grey'
          topBorder = 'none'
          break;
      }
      break;

    // ACTUAL VALUE COLUMNS
    case 'actSales':
      switch(rowType){
        case 'dayRow':
        case 'totalRow':
          cellColor = '#f9e9d3'
          break;
        case 'avgWageRow':
        case 'pctRow':
          cellColor = 'grey'
          topBorder = 'none'
          break;
      }
      break;
    case 'actCheckCount':
    case 'actAvgCheck':
      switch(rowType){
        case 'dayRow':
        case 'totalRow':
          cellColor = '#f9e9d3'
          break;
      }
      break;
    case 'actFOH':
    case 'actBOH':
      width = 75
      switch(rowType){
        case 'dayRow':
        case 'totalRow':
          cellColor = '#f9e9d3'
          break;
        case 'avgWageRow':
        case 'pctRow':
          cellColor = 'grey'
          topBorder = 'none'
          break;
      }
      break;
    case 'theoSPLH':
    case 'actSPLH':
      switch(rowType){
        case 'dayRow':
        case 'totalRow':
          cellColor = '#f9e9d3'
          break;
        case 'avgWageRow':
          cellColor = 'grey'
          topBorder = 'none'
          break;
      }
      break;
    case 'adjTheoFOH':
    case 'adjTheoBOH':
      switch(rowType){
        case 'dayRow':
        case 'totalRow':
          cellColor = '#f9e9d3'
          break;
      }
      break;
    case 'fohActVsTheo':
    case 'bohActVsTheo':
      switch(rowType){
        case 'dayRow':
        case 'totalRow':
          cellColor = '#f9e9d3'
          break;
        case 'avgWageRow':
        case 'pctRow':
          cellColor = 'grey'
          topBorder = 'none'
          break;
      }
      break;
    // case 'bohActVsTheo':
    //   switch(rowType){
    //     case 'dayRow':
    //     case 'totalRow':
    //       cellColor = '#f9e9d3'
    //       break;
    //     // case 'avgWageRow':
    //     case 'pctRow':
    //       cellColor = 'grey'
    //       topBorder = 'none'
    //       break;
    //   }
    //   break;

    // TRAINING COLUMNS
    case 'trainingFOH':
    case 'trainingBOH':
      switch(rowType){
        // case 'storeRow':
        //   paddingTop = 0
        //   paddingRight = 0
        //   paddingBottom = 0
        //   paddingLeft = 0
        //   textAlign = 'center'
        //   break;
        case 'dayRow':
        case 'totalRow':
          paddingLeft = '4px'
          paddingRight = '2px'
          textAlign = 'center'
          fontSize = '.7rem'
          break;
        case 'avgWageRow':
        case 'pctRow':
          cellColor = 'grey'
          topBorder = 'none'
          break;
      }    
      break;
      
    // NOTES COLUMN
    case 'notes':
      width = 150
      // if(rowType === 'storeRow'){
      //   paddingTop = 0
      //   paddingRight = 0
      //   paddingBottom = 0
      //   paddingLeft = 0
      //   textAlign = 'center'
      // }
      switch(rowType){
        case 'storeRow':
          paddingTop = 0
          paddingRight = 0
          paddingBottom = 0
          paddingLeft = 0
          textAlign = 'center'
          break;
        case 'dayRow':
          paddingLeft = '4px'
          paddingRight = '2px'
          textAlign = 'left'
          fontSize = '.7rem'
          cellColor = '#f9e9d3'
          break;
        case 'totalRow':
          cellColor = 'grey'
          break;
        case 'avgWageRow':
        case 'pctRow':
          cellColor = 'grey'
          topBorder = 'none'
          break;
      }    
  }

  const setting = {
    backgroundColor: cellColor,
    borderLeft: leftBorder,
    borderTop: topBorder,
    color: textColor,
    fontSize: fontSize,
    fontStyle: fontStyle,
    fontWeight: fontWeight,
    paddingTop: paddingTop,
    paddingRight: paddingRight,
    paddingBottom: paddingBottom,
    paddingLeft: paddingLeft,
    textAlign: textAlign,   
    width: width 
  }

  return setting

}

export default R0003CellStyle