import React, { useState } from 'react';
import { Autocomplete, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';

export default function FilterDropdown({
  options,
  value,
  onChange,
  label,
  defaultValue,
  ...selectProps
}) {
  
  const handleChange = (newValue) => {
    console.log('handleChange', newValue);
    // const newValue = event.target.value;
    // setValue(newValue);
    onChange(newValue);
  };

  return (
    <div>
    <Autocomplete 
      options={options}
      value={value}
      onChange={(event, newValue) => handleChange(newValue)}
      disableClearable={true}
      size='small'
      renderInput={(params) => (
        <TextField 
          {...params} 
          label={label} variant='outlined' 
        />
      )}
    />
    </div>
  )
  
  // return (
  //   <FormControl fullWidth variant='outlined'>
  //     <InputLabel>{label}</InputLabel>
  //     <Select
  //       value={value}
  //       label={label}
  //       onChange={handleChange}
  //       {...selectProps}
  //     >
  //       {data.map((item) => (
  //         <MenuItem key={item} value={item}>
  //           {item}
  //         </MenuItem>
  //       ))}
  //     </Select>
  //   </FormControl>
  // );
}

FilterDropdown.defaultProps = {
  onChange: () => {},
  defaultValue: '',
  label: 'Select...',
};