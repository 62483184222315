// @mui components
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import SampleFormatBox from 'components/common/elements/SampleFormatBox';

import { getRuleStatement } from '../r0005AdminUtil';

export default function RuleBoxRow({
  rule,
  deleteRule,
  editRule,
  setPosition,
  viewOnly
}){

  const ruleStatement = getRuleStatement(rule)

  return (
    <Grid 
      container 
      spacing={1}
      sx={{
        my: 1
      }}
    >
      <Grid item xs={viewOnly ? 10 : 8} >
        <Box
          sx={{
            pl: 1
          }}
        >
          <Typography variant='subtitle2'>
            {ruleStatement}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <SampleFormatBox 
          backgroundColor={rule.backgroundColor}
          textColor={rule.color}
          fontWeight={rule.fontWeight}
          fontSize='14px'
          width={75}
        />
      </Grid>
      {!viewOnly &&
        <Grid item xs={2}>
          <Stack direction='row' spacing={1}>
            <IconButton
              size='small'
              onClick={() => editRule(rule) }
            >
              <EditIcon />
            </IconButton>
            <IconButton
              size='small'
              onClick={() => deleteRule(rule)}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        </Grid>
      }
    </Grid>
  )
}