import { useEffect, useState } from 'react'

// @mui material components
import { Card, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'

// Material Dashboard 2 components
import DashboardLayout from 'layouts/DashboardLayout'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// RadixDash components
import DashboardNavbar from 'components/RDNavbars/DashboardNavbar'
import RDFooter from 'components/RDFooter'
import ReportAdminHeader from './ReportAdminHeader'
import ReportList from './ReportList/ReportList'
import R0002Settings from './R0002/R0002Settings'
import R0003Settings from './R0003/R0003Settings'
import R0005Settings from './R0005/R0005Settings'
import { useUser } from 'components/RDAuthContext'

export default function ReportAdmin(){
  const { user } = useUser()
  const [page, setPage] = useState('reportList')
  const [title, setTitle] = useState('Report Admin')

  // 'button' is used to toggle whether to include the 'list report' button
  // in the ReportAdminHeader component
  const [button, setButton] = useState(false)
  
  useEffect(() => {
    switch(page) {
      case 'reportList':
        setButton(false)
        setTitle('Report Admin')
        break;
      case 'r0002':
        setButton(true)
        setTitle('Report Admin | R0002: Summary Report')
        break;
      case 'r0003':
        setButton(true)
        setTitle('Report Admin | R0003: SPLH Report')
        break;
      case 'r0005':
        setButton(true)
        setTitle('Report Admin | R0005: Revenue Projection Report')
        break;
      default:
        setButton(false)
        setTitle('Report Admin')
    }
  }, [page])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        sx={{
          maxWidth: 1200,
          mx: 'auto'
        }}
      >
        <Card
          sx={{
            bgcolor: '#f9e9d3'
          }}
        >  
          <ReportAdminHeader 
            title={title}
            button={button}
            setPage={ (page) => setPage(page)}
          />
          <Page 
            page={page}
            setPage={ (page) => setPage(page)}
            user={user}
          />
        </Card>
      </MDBox>
      <RDFooter />
    </DashboardLayout>
  )
}

const Page = ({
  page,
  setPage,
  user
}) => {

  const userName = user.userName
  
  switch(page){
    case 'reportList':
      return (
        <ReportList 
          setPage={
          (page) => setPage(page)
          } 
          userName={userName}
        />  
      )
    case 'r0002':
      return (
        <R0002Settings 
          setPage={
          (page) => setPage(page)
          } 
          user={user}
        />  
      )
    case 'r0003':
      return (
        <R0003Settings 
          setPage={
            (page) => setPage(page)
          }
          user={user}
        />
      )
    case 'r0005':
      return (
        <R0005Settings 
          setPage={
          (page) => setPage(page)
          } 
          user={user}
        />  
      )

  }
}