export const buildPage = (rowData, rowObj) => {
  let pageArr = []

  rowData.forEach(row => {
    let newRow = {...rowObj}
    newRow.rowType = row.rowType
    row.rowCells.forEach(cell => {
      newRow[cell.colName] = cell.value
    })
    pageArr.push(newRow)
  })

  return pageArr
}

export const buildPage2 = (rowData, rowObj) => {
  let pageArr = []

  rowData.forEach(row => {
    let newRow = {...row.rowData}
    newRow.rowType = row.rowType
    if(row.rowType === 'storeRow'){
      newRow.thresholds = {...row.thresholds}
    }
    if(row.rowType === 'managerRow'){
      newRow.thresholds = {...row.threshold}
    }
    pageArr.push(newRow)
  })
  console.log('pageArr', pageArr)
  return pageArr
}

export const getAccessors = (columns) => {
  let accessorObj = {}
  columns.forEach(col => {
    const accessors = col.columns.map(x => x.accessor)
    accessors.forEach(accessor => {
      accessorObj = {...accessorObj, [accessor]: ''}
    })
  })
  return accessorObj
}

export const getNumHeaderRows = (columnData) => {
  return Array.isArray(columnData.columns) ?
  1 + Math.max(0, ...columnData.columns.map(getNumHeaderRows)) :
  0
}

export function calculateComplementColor(color) {
  const hex = color.slice(1)
  const complementHex = `#${(0xFFFFFF ^ parseInt(hex, 16)).toString(16)}`
  return complementHex
}

/**
 * Returns an array of years within the specified range, sorted in the specified order.
 * @param {number} startYear - The starting year of the range.
 * @param {number} endYear - The ending year of the range.
 * @param {string} sort - The sorting order of the resulting array. Defaults to 'ascending'.
 *                        Possible values: 'ascending', 'descending'.
 * @returns {number[]} An array of years within the specified range, sorted in the specified order.
 */
export function getYearsArray(startYear, endYear, sort = 'ascending') {
  const yearsArray = [];

  for (let year = startYear; year <= endYear; year++) {
    yearsArray.push(year);
  }

  if (sort === 'ascending') {
    yearsArray.sort((a, b) => a - b);
  } else if (sort === 'descending') {
    yearsArray.sort((a, b) => b - a);
  }

  return yearsArray;
}

export const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export function formatCurrency(amount, digits = 0){
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  }).format(amount)
}
