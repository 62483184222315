import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { API } from 'aws-amplify'
import { dbGetItem, getR0001Reportv2, getReportv2, listAreas2 } from 'graphql/queries'

import { Card, Grid, Icon, Stack, Tooltip, Typography } from '@mui/material'

import { format, parseISO, subDays, startOfWeek } from 'date-fns'

// Material Dashboard 2 PRO React components
import { useMaterialUIController, setOpenConfigurator } from "context";
import MDBox from "components/MDBox";
import MDButton from 'components/MDButton'
import MDDatePicker from 'components/MDDatePicker'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'layouts/DashboardLayout'

// RadixDash components
import Button_DemHead from 'components/RDButton/Button_DemHead'
import Button_GrmHead from 'components/RDButton/Button_GrmHead'
import DashboardNavbar from 'components/RDNavbars/DashboardNavbar';
import GoalPlanner from './GoalPlanner'
// import GoalPlanner2 from './GoalPlanner2'
import Progress from 'components/Progress/Progress'
import R0001Config from './R0001Config'
import R0001Message from './R0001Message'
import R0001Table from './R0001Table'
import RDConfigurator from 'components/RDConfigurator'
import RDFooter from  'components/RDFooter'
import SelectStoreDialog from './SelectStoreDialog'

import { useUser } from 'components/RDAuthContext'

export default function R0001Main(){

  const { user } = useUser()
  console.log('user', user)
  const role = user.role
  const userName = user.userName
  const client = user.client

  /********************************************************* 
   * CONFIGURATOR CONTROL STATE
   *********************************************************/
   const [controller, dispatch] = useMaterialUIController();

  /********************************************************* 
   * REPORT CONFIGURATION STATES
   *********************************************************/
  // Date Range
  const today = new Date()
  // const maxDate = subDays(today, 1)
  const maxDate = new Date(2022, 1, 27)
  let initialStartDate = startOfWeek(maxDate, {weekStartsOn: 1})
  const [dateRange, setDateRange] = useState([format(initialStartDate, 'yyyy-MM-dd'), format(maxDate, 'yyyy-MM-dd')])
  const [activeDateRange, setActiveDateRange] = useState(dateRange)
  // Selected Stores
  const [enableSelectStores, setEnableSelectStores] = useState(false)
  const [openSelectStoresDialog, setOpenSelectStoresDialog] = useState(false)
  const [selectedStoreRows, setSelectedStoreRows] = useState({})
  const [activeSelectedStoreRows, setActiveSelectedStoreRows] = useState(selectedStoreRows)
  const [selectedStores, setSelectedStores] = useState({})
  const [activeSelectedStores, setActiveSelectedStores] = useState(selectedStores)
  const [selectedStoreNames, setSelectedStoreNames] = useState([])
  const [activeSelectedStoreNames, setActiveSelectedStoreNames] = useState(selectedStoreNames)
  // Report Readiness
  const [r0001Ready, setR0001Ready] = useState(false)
  
  /********************************************************* 
   * REPORT FILTER STATES
   *********************************************************/
  const [cvpSwitchSetting, setCvpSwitchSetting] = useState(false)
  const [dataLoading, setDataLoading] = useState(false)
  const [filteredData, setFilteredData] = useState([])
  const [goal, setGoal] = useState(false)
  const [netSalesSwitchSetting, setNetSalesSwitchSetting] = useState(false)
  const [disableGoalPlannerButton, setDisableGoalPlannerButton] = useState(true)
  const [openGoalPlanner, setOpenGoalPlanner] = useState(false)
  const [r0001Data, setR0001Data] = useState(null)
  const [shifts, setShifts] = useState({
    lunch: true,
    social: true,
    dinner: true
  })
  const [shiftStatus, setShiftStatus] = useState({
    lunch: false,
    social: false,
    dinner: false
  })
  const [showMessage, setShowMessage] = useState(true)
  const [store, setStore] = useState('')
  const [storeData, setStoreData] = useState([])
  const [storeList, setStoreList] = useState([])
  const [title, setTitle] = useState('KPI Report')
  const [clearSelections, setClearSelections] = useState(false)
  const [selectedRowIds, setselectedRowIds] = useState({})
  const [selectedRowData, setselectedRowData] = useState([])

  /* END OF STATE DECLARATIONS *****************************/

  /********************************************************* 
   * INITIALIZE STORE DATA
   *********************************************************/
  useEffect(() => {
    getStoreData()
  }, [])

  async function getStoreData(){
    console.log('getAreas()...')
    try {
      const dbVar = {
        report: 'r0001',
        user: userName,
        role: role
      }
      const dbVarJSON = JSON.stringify(dbVar)
      console.log('dbVarJSON', dbVarJSON)
      const queryResponse = await API.graphql({
        query: dbGetItem,
        variables: {
          db: 'dem.areas',
          dbVariables: dbVarJSON
        }
      })
      // const queryResponse = await API.graphql({
      //   query: listAreas2,
      //   variables: {
      //     report: 'r0001',
      //     user: user.username,
      //     role: role
      //   }
      // })
      console.log('queryResponse', queryResponse)
      const responseAreas = JSON.parse(JSON.parse(queryResponse.data.dbGetItem.statusItem))
      console.log('responseAreas', (responseAreas))
      setStoreData(responseAreas)
      // setStoreData(queryResponse.data.listAreas2)
    } catch (err) {
      console.log('Error fetching Areas data', err)
    }
  }
  
  useEffect(() => {
    const areas = storeData.map(x => x.areaName)
    const initialAreaRowIds = areas.reduce((acc, curr) => (acc[curr]={}, acc), {})
    const initialAreaStores = areas.reduce((acc, curr) => (acc[curr]=[], acc), {})

    setSelectedStoreRows(initialAreaRowIds)
    setSelectedStores(initialAreaStores)
  
  }, [storeData])

  useEffect(() => {
    // console.log('storeList', storeList)
  }, [storeList])

  
  /********************************************************* 
   * CONFIGURATOR MANAGEMENT
   *********************************************************/
  const {
    openConfigurator,
  } = controller
  
  const handleConfiguratorOpen = () => {
    if(openConfigurator === false){
      console.log('enableSelectStores',enableSelectStores)
      // Reset dateRange to the dateRange of the current report
      setDateRange(activeDateRange)
    }
    setOpenConfigurator(dispatch, !openConfigurator)
  }

  /********************************************************* 
   * REPORT FILTER HANDLING
   *********************************************************/
  
  
  /********************************************************* 
   * GOAL PLANNER HANDLING
   *********************************************************/
  useEffect(() => {
    // console.log('selectedRowData changed', selectedRowData)
    if(selectedRowData.length > 0){
      setDisableGoalPlannerButton(false)
    } else {
      setDisableGoalPlannerButton(true)
    }
  }, [selectedRowData]) 
  

  useEffect(() => {
    // console.log('selectedStoreRows', selectedStoreRows)
  }, [selectedStoreRows])
  useEffect(() => {
    // console.log('selectedStores', selectedStores)
    const storeArray = Object.values(selectedStores).flat()
    storeArray.sort()
    setSelectedStoreNames(storeArray)
  }, [selectedStores])

  

  useEffect(() => {
    // console.log('R0001Main selectedRowIds changed', selectedRowIds)
  }, [selectedRowIds])

  function handleDateChange(range){
    console.log('handleDateChange', range)
    console.log('range.length', range.length)
    const startDate = format(range[0], 'yyyy-MM-dd')
    const endDate = format(range[1], 'yyyy-MM-dd')

    console.log('startDate', startDate)
    console.log('endDate', endDate)
    setDateRange([startDate, endDate])
  }

  function handleStoreSelectSwitch(event){
    setEnableSelectStores(event.target.checked)    
  }
  useEffect(() => {
    // console.log('enableSelectStores', enableSelectStores)
    // Clear any previously selected stores
    if(!enableSelectStores){
      setSelectedStoreRows({})
      setSelectedStores({})
      setSelectedStoreNames([])
    } else {
      setSelectedStoreRows(activeSelectedStoreRows)
      setSelectedStores(activeSelectedStores)
      setSelectedStoreNames(activeSelectedStoreNames)
    }
  }, [enableSelectStores])
  useEffect(() => {
    // console.log('selectedStores', selectedStores)
  }, [selectedStores])

  function handleStoreSelection(rowIds, flatRows, area){
    // console.log('rowIds', rowIds)
    // console.log('flatRows', flatRows)
    // console.log('area', area)
    setSelectedStoreRows(prevState => {
      return ({
        ...prevState,
        [area]: rowIds
      })
    })
    setSelectedStores(prevState => {
      const storeArray = flatRows.map(row => row.original.store)
      return ({
        ...prevState,
        [area]: storeArray
      })
    })
  }

  function handleSelectStores(storeRows, storeObj, storeList){
    
    setSelectedStoreRows(storeRows)
    
    setSelectedStores(storeObj)
    
    setSelectedStoreNames(storeList)
    setOpenSelectStoresDialog(false)
  }
  
  
  function handleSelectStoresCancel(){
    setSelectedStoreRows(activeSelectedStoreRows)
    setSelectedStores(activeSelectedStores)
    setSelectedStoreNames(activeSelectedStoreNames)
    setOpenSelectStoresDialog(false)
  }

  function handleSelectStoresClear(){
    // console.log('handleSelectStoresClear')
    setSelectedStoreRows({})
    setSelectedStores({})
    setSelectedStoreNames([])
  }

  /********************************************************* 
   * FETCH REPORT
   *********************************************************/   
  async function fetchR0001Data() {
    console.log('fetchR0001Data()')
    handleConfiguratorOpen()
    setTitle('KPI Report')
    setR0001Ready(false)
    setDataLoading(true)    
    setShowMessage(false)
    setGoal(false)

    try {
      console.log('selectedStoreNames', selectedStoreNames)

      // const queryResponse = await API.graphql({
      //   query: getR0001Reportv2,
      //   variables: {
      //     startDate: dateRange[0],
      //     endDate: dateRange[1],
      //     stores: selectedStoreNames
      //   },
      //   authMode: 'AMAZON_COGNITO_USER_POOLS'
      // })
      const reportVar = {
        startDate: dateRange[0],
        endDate: dateRange[1],
        stores: selectedStoreNames
      }

      const reportVarJSON = JSON.stringify(reportVar)
      console.log('reportVarJSON', reportVarJSON)
      
      const queryResponse = await API.graphql({
        query: getReportv2,
        variables: {
          clientId: client,
          reportId: 'r0001',
          userName: userName,
          reportVariables: reportVarJSON
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })

      console.log('queryResponse', queryResponse)
      const queryData = JSON.parse(JSON.parse(queryResponse.data.getReportv2.statusItem))
      console.log('queryData', queryData)
      setR0001Data(queryData)
      setActiveDateRange(dateRange)
      // const sDate = format(parseISO(dateRange[0]), 'MM/dd/yyyy')
      // const eDate = format(parseISO(dateRange[1]), 'MM/dd/yyyy')
      // setTitle(`KPI Report: ${sDate} - ${eDate}`)
      setActiveSelectedStoreNames(selectedStoreNames)
      setActiveSelectedStoreRows(selectedStoreRows)
      setActiveSelectedStores(selectedStores)
      setDataLoading(false)

    } catch (err) {
      console.log('Error fetching R0001 data', err)
      setDateRange(activeDateRange)
      setR0001Ready(false)
      setDataLoading(false)
    }

  }

  useEffect(() => {
    if(r0001Data !== null){
      const sDate = format(parseISO(dateRange[0]), 'MM/dd/yyyy')
      const eDate = format(parseISO(dateRange[1]), 'MM/dd/yyyy')
      if(Object.keys(r0001Data).length > 0 && r0001Data.shifts.length > 0 ){
        setTitle(`KPI Report: ${sDate} - ${eDate}`)
      } else {
        if(!showMessage  && !dataLoading){
          setTitle(`No Data for ${sDate} - ${eDate}`)
        }
      }
    }
  }, [activeDateRange, r0001Data, showMessage])

  useEffect(() => {
    console.log('r0001Data changed', r0001Data)
    // console.log('r0001Data.getR0001Reportv2.shifts.length', r0001Data.getR0001Reportv2.shifts.length)
    if(r0001Data !== null){
      console.log('Object.keys(r0001Data)', Object.keys(r0001Data))
      if(Object.keys(r0001Data).length > 0){
        if(r0001Data.shifts.length > 0){
          console.log('r0001Data changed, call filterData')
          const data = filterData()
          console.log('data', data)
          setR0001Ready(true)
          setFilteredData(data)
        }
      }
    }
  }, [r0001Data])

  useEffect(() => {
    if(r0001Ready){
      setDayPartStatus()
    } else {
      // r0001Ready == false, so disable all Day Part checkboxes
      const disableShifts = {
        lunch: false,
        social: false,
        dinner: false
      }
      setShiftStatus(disableShifts)
    }
  }, [r0001Ready])

  function setDayPartStatus(){
    let newShiftStatus = {}
    if(r0001Ready){
      const shiftValues = Object.values(shifts)
      const trueArr = shiftValues.filter(x => x === true)      
      if(trueArr.length > 1){
        newShiftStatus = {
          lunch: true,
          social: true,
          dinner: true
        }
      } else {
        newShiftStatus = {
          lunch: !shifts.lunch,
          social: !shifts.social,
          dinner: !shifts.dinner
        }
      }
      setShiftStatus(newShiftStatus)
    } else {
      newShiftStatus = {
        lunch: false,
        social: false,
        dinner: false
      }
    }
  }

  function filterData(){
    console.log('In filterData()')
    const fullData = r0001Data
    console.log('fullData', fullData)

    // Create shift string
    let shift = ''
    if(shifts.lunch){
      shift = 'L'
    }
    if(shifts.social){
      shift += 'S'
    }
    if(shifts.dinner){
      shift += 'D'
    }

    console.log('shift', shift)
    console.log('store', store)
    
    // if(fullData !== undefined){
    if(fullData !== null){
      const shiftIdx = fullData.shifts.findIndex((s) => s.shift === shift)
      console.log('shiftIdx', shiftIdx)
      let storeIdx = fullData.shifts[shiftIdx].store.findIndex((s) => s.name === store)
      
      setStore(fullData.shifts[shiftIdx].store[storeIdx].name)
      const kpis = fullData.shifts[shiftIdx].store[storeIdx].kpis
      console.log('kpis', kpis)
      return kpis
    } else {
      console.log('return null')
      return null
    }
  }
  useEffect(() => {
    console.log('filteredData', filteredData)
  }, [filteredData])

  function handleShifts(shift){
    
    setShifts({ ...shifts, [shift.target.name]: shift.target.checked })
    
  }

  useEffect(() => {
    const newData = filterData()
    setDayPartStatus()
    setClearSelections(true)
    if(filteredData.length !== 0){
      setFilteredData(newData)
    }
  }, [shifts])

  function handleStore(store){
    setClearSelections(true)
    setStore(store)
  }

  useEffect(() => {
    console.log('store changed', store)
    const newData = filterData()
    console.log('newData', newData)
    if(filteredData.length !== 0){
      setFilteredData(newData)
    }
  }, [store])

  function handleNetSales(event){
    setNetSalesSwitchSetting(event)
  }

  const handleCVP = (event) => {
    setCvpSwitchSetting(event)
    const dataCopy = {...r0001Data}
    console.log('dataCopy', dataCopy)
    setR0001Data(dataCopy)
  }

  const handleGoalSwitch = (event) => {
    setClearSelections(true)
    setGoal(event.target.checked)
  }
  
  function handleSetGoalsButton(){
    console.log('Set Goals Button clicked')
    setOpenGoalPlanner(true)
  }

  function handleGoalPlannerClose(){
    // setselectedRowIds({})
    // setselectedRowData([])
    setClearSelections(true)
    setOpenGoalPlanner(false)
  }

  useEffect(() => {
    setClearSelections(false)
  }, [clearSelections])

  function handleSelectedRows(rowIds, flatRows){
    // Setup flatRows for Goal Planning Table
    const rawData = flatRows.map(x => x.original)
    const cleanData = rawData.map(({low, mid, top, percentToMid, percentToTop, valueToMid, valueToTop, ...rest}) => rest)
    const gpData = cleanData.map(x => ({...x, dollarGoal: '', dollarOpp: '', percentGoal: '', percentOpp: '', tactics: '' }))
    console.log('gpData', gpData)
    setselectedRowData(gpData)
    setselectedRowIds(rowIds)    
  }
 
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox 
        mb={3} 
        sx={{ 
          maxWidth: 1200,
          mx: 'auto'
        }}
      >
        <Card
          sx={{
            bgcolor: '#e0e0e0',
            // bgcolor: '#f9e9d3',
          }}
        >
      
          {/* Report Header */}
          <MDBox pt={2} pl={3} pb={2} sx={{
            backgroundColor: '#1c4e80',
            // backgroundColor: '#29261f',
          }}>
            <Grid container >
              <Grid item xs={7} sx={{
                display: 'flex',
                justifyContent: 'flex-start'
              }}
              >
                <MDTypography variant='h5' fontWeight='medium' style={{ color: '#f1f1f1'}}>
                  { title }
                </MDTypography>
              </Grid>
              <Grid item xs={5}>
                <Stack direction='row' justifyContent='end' spacing={3} pr={3}>
                  <Tooltip title='Report Settings' >
                    <Button_DemHead
                      onClick={ handleConfiguratorOpen }
                      iconOnly
                    >
                      <Icon
                        fontSize='large'
                        color='inherit'
                      >
                        settings
                      </Icon>
                    </Button_DemHead>
                  </Tooltip>
                </Stack>
              </Grid>
            </Grid>
          </MDBox>

          {
            showMessage &&
            <R0001Message />
          }
          {
            dataLoading && 
            <Progress />
          }
          {
            r0001Ready &&
            <R0001Table 
              data={ filteredData }
              store={ store }
              netSalesSetting={ netSalesSwitchSetting }
              cvpSetting={ cvpSwitchSetting }
              goalSetting={ goal }
              selectedRows={ selectedRowIds }
              selectedFlatRows={ selectedRowData }
              setSelectedRows={ handleSelectedRows }
              clearSelectedRows={ clearSelections }
            />
          }
          <GoalPlanner
            open={ openGoalPlanner }
            close={ handleGoalPlannerClose }
            data={ selectedRowData }
            dateRange={ dateRange }
            allData={ filteredData }
            store={ store }
          />
          <SelectStoreDialog 
            open={ openSelectStoresDialog }
            rowIds={ selectedStoreRows }
            stores={ selectedStores }
            storeList={ selectedStoreNames }
            onSelection={ (rowIds, flatRows, area) => handleStoreSelection(rowIds, flatRows, area) }
            submit={ (storeRows, storeObj, storeList) => handleSelectStores(storeRows, storeObj, storeList) }
            cancel={ handleSelectStoresCancel }
            clear={ handleSelectStoresClear }
            data={ storeData }
          />
        </Card>
        <RDConfigurator
          report='R0001'
          component={ 
            <R0001Config 
              storeData={ storeData }
              dateRange={ dateRange }
              maxDate={ maxDate }
              dateChange={ newDates => handleDateChange(newDates) }
              handleStoreSelectSwitch={ value => handleStoreSelectSwitch(value) }
              enableSelectStores={ enableSelectStores }
              selectStores={ () => setOpenSelectStoresDialog(true) }
              updateReport={ fetchR0001Data }

              shifts={ shifts }
              shiftChange={ handleShifts }
              shiftStatus={ shiftStatus }

              // stores={ storeList }
              stores={ selectedStoreNames }
              storeChange={ handleStore }

              netSalesSwitchSetting={ netSalesSwitchSetting }
              netSalesChange={ handleNetSales }

              cvpSwitchSetting={ cvpSwitchSetting }
              cvpChange={ handleCVP }

              gpOpen={ goal }
              disableGPButton={ disableGoalPlannerButton }
              gpSwitch={ handleGoalSwitch }
              setGoalsButton={ handleSetGoalsButton }

              r0001Ready={ r0001Ready }
            /> 
          }
          dividerColor={'#a44c0e'}
          // onClick={() => fetchR0003Data()}
          onClick={() => console.log('config set')}
        />
        { activeSelectedStoreNames.length > 0 &&
          <MDBox mt={1}>
            <MDBox>
              <MDTypography variant='h6'>
                Selected Stores
              </MDTypography>
            </MDBox>
            <MDBox>
              <MDTypography variant='body2'>
                { 
                  activeSelectedStoreNames.map((store, index, {length}) => {
                    if(index === length -1) {
                      return `${store}`
                    } else {
                      return `${store}, `
                    }                  
                  }) 
                }
              </MDTypography>
            </MDBox>
          </MDBox>
        }
      </MDBox>
      <RDFooter />
    </DashboardLayout>
  )
}

R0001Main.propTypes = {

}