import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// AWS Amplify components
import { API } from 'aws-amplify'

// GraphQL
import { dbGetItem } from 'graphql/queries'

import { Autocomplete, TextField } from '@mui/material'

import { useUser } from 'components/RDAuthContext'


export default function AreaSelector(props){

  const userName = useUser.userName
  const role = useUser.role
  
  const [areaList, setAreaList] = useState([])
  const [companyAreas, setCompanyAreas] = useState([])
  const [franchiseAreas, setFranchiseAreas] = useState([])
  const [filterList, setFilterList] = useState({})
  const [data, setData] = useState(null)

  async function getAreaList(){
    console.log('getAreaList...')

    try {
      const dbVar = {
        report: 'r0001',
        user: userName,
        role: role
      }
      const dbVarJSON = JSON.stringify(dbVar)
      console.log('dbVarJSON', dbVarJSON)
      const queryResponse = await API.graphql({
        query: dbGetItem,
        variables: {
          db: 'dem.areas',
          dbVariables: dbVarJSON
        }
      })
      console.log('queryResponse', queryResponse)
      const responseAreas = JSON.parse(JSON.parse(queryResponse.data.dbGetItem.statusItem))
      console.log('responseAreas', (responseAreas))
      setData(responseAreas)

    } catch (err) {
      console.log('Error fetching Areas data', err)
    }
  }

  useEffect(() => {
    getAreaList()
  }, [])

  useEffect(() => {
    
    if(data){
      // Set areaList
      const company_Areas = data.filter(x => x.areaType === 'Company').map(y => y.areaName).sort()
      const franchise_Areas = data.filter(x => x.areaType === 'Franchise').map(y => y.areaName).sort()
      company_Areas.unshift('All')
      franchise_Areas.unshift('All')
      setCompanyAreas(company_Areas)
      setFranchiseAreas(franchise_Areas)
      
      if(props.pageType === 'Company'){
        setAreaList(company_Areas)
      } else if(props.pageType === 'Franchise'){
        setAreaList(franchise_Areas)
      }

      // Set filterList
      let storeList = data.reduce((arr, manager) => {
        const { areaName, stores } = manager
        
        let storeNames = stores.filter(x => (x.status !== 'Closed' && x.status !== 'New')).map(x => x.shortName)
        storeNames.push(areaName)
        storeNames.push(`${areaName} Total`)
        return {...arr, [areaName]: storeNames}
      }, {})
      setFilterList(storeList)
    }
  }, [data])

  useEffect(() => {
    if(props.pageType === 'Company'){
      setAreaList(companyAreas)
    } else if(props.pageType === 'Franchise'){
      setAreaList(franchiseAreas)
    }
  }, [props.pageType])  

  function handleAreaSelection(event, area){
    if(area !== 'All'){
      props.onChange(area, filterList[area])
    } else {
      props.onChange(area, filterList)
    }
  }  

  return (
    <div>
      <Autocomplete 
        id='r0002AreaSelector'
        options={ areaList }
        value={ props.area }
        onChange={ handleAreaSelection }
        defaultValue='All'
        disableClearable={true}
        size='small'        
        renderInput={(params) => 
          <TextField 
            {...params} 
            label='Area' 
            variant='outlined' 
            fullWidth
            sx={{
              '& .MuiFormLabel-root': {               
                color: '#f1f1f1'
              },
              '& .MuiInputBase-input': {
                color: '#f1f1f1',
              }
            }}
          /> 
        }
        sx={{
          '& .MuiAutocomplete-inputRoot': {
            color: 'white',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#f1f1f1',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#f1f1f1'
            },
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#f1f1f1'
            },
            '& .MuiAutocomplete-inputFocused': {
              bgcolor: '#1c4e80'
            },
            '& .MuiAutocomplete-endAdornment': {
              '& .MuiIconButton-root': {
                color: '#f1f1f1'
              }
            },
          }
        }}
      />
    </div>
  )
}

AreaSelector.propTypes = {
  area: PropTypes.string,
  onChange: PropTypes.func,
  pageType: PropTypes.string,
}