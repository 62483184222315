import { useEffect, useState } from 'react'
import { getISOWeek, getISOWeekYear, getWeek, getYear } from 'date-fns'

import { Autocomplete, Stack, TextField } from '@mui/material'

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import { isPropsEqual } from '@fullcalendar/react';

import Autocomplete_GrmDark from 'components/RDAutocomplete/Autocomplete_GrmDark'
import Button_GrmDark from 'components/RDButton/Button_GrmDark'


function getYears(){
  const currentYear = getISOWeekYear(new Date())
  
  let years = []
  for(let i = currentYear; i >= 2021; i--){
    years.push(i.toString())
  }
  return years
}


export default function PeriodSelector(props){

  // console.log('component: PeriodSelector(props)', props)
  
  const [quarter, setQuarter] = useState(props.quarter)
  const [quarters, setQuarters] = useState(['Q1', 'Q2', 'Q3', 'Q4'])
  const [year, setYear] = useState(props.year)
  const [years, setYears] = useState([])

  useEffect(() => {
    const yearArr = getYears()
    setYears(yearArr)
  }, [])

  // useEffect(() => {
  //   setYear(props.period.year)
  //   setQuarter(props.period.quarter)
  // }, [props.period])

  useEffect(() => {
    // Set the Quarter options
    const today = new Date()
    const currentYear = getISOWeekYear(today)
    let quarterArr = ['Q1']
    let quarterToDisplay = 'Q1'
      
    if(parseInt(year) == currentYear){
      const q1Start = 1
      const q2Start = 14
      const q3Start = 27
      const q4Start = 40
      const currentWeek = getISOWeek(today)
      if(currentWeek >= q2Start){
        quarterArr.push('Q2')
        quarterToDisplay = 'Q2'
        if(currentWeek >= q3Start){
          quarterArr.push('Q3')
          quarterToDisplay = 'Q3'
          if(currentWeek >= q4Start){
            quarterArr.push('Q4')
            quarterToDisplay = 'Q4'
          }
        }
      } 
    } else {
      quarterArr = ['Q1', 'Q2', 'Q3', 'Q4']
    }
    setQuarters(quarterArr)
    setQuarter(quarterToDisplay)
  }, [year])

  function handleYearSelection(event, newYear){
    setYear(newYear)
  }
  
  function handleQuarterSelection(event, newQuarter){
    setQuarter(newQuarter)
  }
  
  function handleSubmit(){
    props.onSubmit(year, quarter)
  }

  return (
    <MDBox>
      <Stack direction='column'>
        <Autocomplete_GrmDark
          id='r0003YearSelector'
          options={ years }
          value={ year }
          onChange={ handleYearSelection }
          disableClearable={ true }
          size='small'
          renderInput={(params) => 
            <TextField 
              {...params}
              label='Year'
              variant='outlined'
              // fullWidth
            />
          }
        />
        <Autocomplete_GrmDark
          id='r0003QuarterSelector'
          options={quarters  }
          value={ quarter }
          onChange={ handleQuarterSelection }
          disableClearable={ true }
          size='small'
          renderInput={(params) => 
            <TextField 
              {...params}
              label='Quarter'
              variant='outlined'
              // fullWidth
            />
          }
        />
        <Button_GrmDark
          onClick={ handleSubmit }
        >
          Run Report
        </Button_GrmDark>
      </Stack>
    </MDBox>
  )
}