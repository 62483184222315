import React, { useEffect, useState } from 'react'

// AWS Amplify components
import { API } from 'aws-amplify'
import { LocationSearch } from '@aws-amplify/ui-react'
// import { LocationSearch, MapView } from '@aws-amplify/ui-react'
import { getReport } from 'graphql/queries'
import '@aws-amplify/ui-react/styles.css'

// import { Marker, Popup } from 'react-map-gl'

// react-leaflet components
// import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet'

// Material Dashboard Pro 2 components
import MDBox from 'components/MDBox'

// RadixDash components
import LocationMap from './LocationMap'
import LocationTable from './LocationTable'
import MarkerWithPopup from 'components/RDMap/MarkerWithPopup'
import RDDashboard from 'components/RDDashboard'
import RDMessageBox from 'components/RDMessageBox'
import { buildPage } from 'utils/reportUtils'
import { useUser } from 'components/RDAuthContext'

import LocationTracker2 from '../LocationTracker2'
import Clusters from 'components/RDMap/Clusters'

// Images
import backgroundImage from "assets/images/afa92/dashboard-bg-1.png";
// import backgroundImage from "assets/images/afa92/dashboard-bg-2.png";
import logoImage from 'assets/images/afa92b-icon.png'

// TEST DATA
import testDataJSON from './usafa92v3.json'

function generateFeatures(data){
  const features = data.map(x => (    
    {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [
          x.longitude,
          x.latitude
        ]
      },
      'id': x.classId,
      'cluster': false,
      'properties': {
        'title': `${x.firstName} ${x.middleName ? x.middleName : ''} ${x.lastName}`,
        'description': `${x.municipality ? x.municipality + ',' : ''} ${x.region}, ${x.country}`
      }
    }
  ))
  console.log('features', features)
  return features
}

const emptyTableRow = [
  {
    classId: '',
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    location: '',
    freshmanSquadron: '',
    graduationSquadron: '',
    latitude: '',
    longitude: '',
    municipality: '',
    region: '',
    country: ''
  }
]

const markerTestData = [
  {
    id: '920001',
    title: 'USAFA',
    category: 'member',
    position: {
      lat: 39.008306,
      long: -104.888728
    }
  },
  {
    id: '920002',
    title: 'USAFA 2',
    category: 'member',
    position: {
      lat: 39.008306,
      long: -105.898728
    }
  },
  {
    id: '920003',
    title: 'Lyndon B. Johnson Space Center',
    category: 'member',
    position: {
      lat: 29.558907,
      long: -95.089891
    }
  }
] 

export default function LocationTracker(){

  /********************************************************/
  const TEST = false
  /********************************************************/
  const { user } = useUser()
  const clientId = user.client

  const [viewState, setViewState] = useState({
    latitude: 39.008306,
    longitude: -97.380979,
    zoom: 3,
  })
  const [fullData, setFullData] = useState([])
  const [tableData, setTableData] = useState(emptyTableRow)
  const [markerData, setMarkerData] = useState(null)
  const [showData, setShowData] = useState(false)

  useEffect(() => {
    let memberData
    if(TEST){
      memberData = buildMemberData()
      setMarkerData(markerTestData)
    } else {
      memberData = fetchMemberData()
    }
    
  }, [])

  useEffect(() => {
    console.log('Table Data changed', tableData)
    updateMarkerData(tableData)
  }, [tableData])

  useEffect(() => {
    console.log('markerData changed', markerData)
    markerData ? setShowData(true) : setShowData(false)
  }, [markerData])

  function buildMemberData(){
    console.log('buildMemberData()')
    setTableData(testDataJSON)
  }

  async function fetchMemberData(){
    console.log('fetchMemberData()')
    console.log('user.userName', user.userName)
    console.log('user.client', user.client)

    try {
      const response = await API.graphql(
        {
          query: getReport,
          variables: {
            userName: user.userName,
            clientId: user.client,
            reportId: 'location_01',
            reportVariables: [
              {
                name: 'dummy',
                value: 0
              }
            ]
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      )
      console.log('response', response)
      const rawData = JSON.parse(response.data.getReport[0].data[0].rowCells[0].value)
      console.log('rawData', rawData)
      const preparedData = rawData.map(x => (
        {
          _id: x._id,
          classId: x.classId,
          firstName: x.firstName,
          middleName: x.middleName,
          lastName: x.lastName,
          suffix: x.suffix,
          location: x.location.label,
          freshmanSquadron: x.freshmanSquadron,
          graduationSquadron: x.graduationSquadron,
          latitude: x.location.latitude,
          longitude: x.location.longitude,
          municipality: x.location.municipality,
          region: x.location.region,
          country: x.location.country,
          postalCode: x.location.postalCode,
          street: x.location.street
        }
      ))
      console.log('preparedData', preparedData)

      preparedData.sort((a, b) => a.classId - b.classId)
      
      // set tableData
      setTableData(preparedData)
      
      // set markerData
      // const markers = preparedData.map(x => (
      //   {
      //     id: x.classId,
      //     title: `${x.firstName} ${x.middleName ? x.middleName : ''} ${x.lastName}`,
      //     description: `${x.municipality ? x.municipality + ',' : ''} ${x.region}, ${x.country}`,
      //     longitude: x.longitude,
      //     latitude: x.latitude        
      //   }
      // ))

      // const features = generateFeatures(preparedData)
      // const markers = {
      //   'type': 'FeatureCollection',
      //   'title': 'afa92 markers',
      //   'features': features
      // }
      // console.log('markers', markers)
      // setMarkerData(markers)
    } catch (err) {
      console.log('Error fetching member data', err)
    }    
  }

  function updateMarkerData(memberData){
    const features = generateFeatures(memberData)
    const markers = {
      'type': 'FeatureCollection',
      'title': 'afa92 markers',
      'features': features
    }
    console.log('markers', markers)
    setMarkerData(markers)
  }

  function addTableData(newMember){
    console.log('addTableData', newMember)
    const newData = [...tableData, newMember]
    newData.sort((a, b) => a.classId - b.classId)
    setTableData(newData)
  }

  function updateTableData(member, index){
    console.log('updateTableData', member)
    const dataUpdate = [...tableData]
    dataUpdate[index] = member
    console.log('dataUpdate new', dataUpdate)
    setTableData([...dataUpdate])
  }

  return(
    <RDDashboard 
      headerBg={backgroundImage}
      logo={logoImage}
    >
      <MDBox
        sx={{
          height: '45vh',
          width: '95%',
          mx: 'auto',
          p: 1,
          backgroundColor: 'blue',
          mb: 2
        }}
      >        
        {/* <MapView 
          // initialViewState={{
          //   latitude: 39.008306,
          //   longitude: -97.380979,
          //   zoom: 3,
          // }}
          {...viewState}
          onMove={evt => setViewState(evt.viewState)}
          interactiveLayerIds={['points']}
          style={{ height: '43vh', width: '100%', mx: 'auto'}}
        >
          { showData ? (
              <Clusters 
                data={markerData} 
              />
            ) : null
          }
        </MapView> */}
      </MDBox>
      <MDBox
        sx={{
          width: '95%',
          mx: 'auto',
          mt: 1
        }}
      >
        <LocationTable 
          data={ tableData }
          setData={ addTableData }
          updateData={ updateTableData }
          setTable={ (x) => setTableData(x) }
          setMarkers={ (x) => setMarkerData(x) }
        />
      </MDBox>
      
    </RDDashboard>
  )
}