import { averageCheckMetrics } from './averageCheckMetrics';
import { checkCountMetrics } from './checkCountMetrics';
import { laborMetrics } from './laborMetrics';
import { netSalesMetrics } from './netSalesMetrics';
import { overtimeMetrics } from './overtimeMetrics';
import { splhMetrics } from './splhMetrics';

export const KPI_CONFIGS = {
  ...netSalesMetrics,
  ...checkCountMetrics,
  ...averageCheckMetrics,
  ...splhMetrics,
  ...overtimeMetrics,
  ...laborMetrics
}