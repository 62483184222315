export function transformToastData(currentWeekData, priorWeekData, storeIdMap) {
  if (!storeIdMap || storeIdMap.size === 0) {
    console.warn('StoreIdMap is empty or undefined')
    return {}
  }
  
  const dailySummaries = {}
  const priorWeekSummaries = {}

  console.log('storeIdMap', storeIdMap)
  console.log('Sample store map entry', Array.from(storeIdMap.entries())[0])
  
  // Helper function to initialize store day data
  const initializeDayData = () => ({
    netSales: 0, 
    checkCount: 0, 
    fohRegularHours: 0, 
    bohRegularHours: 0, 
    fohOvertimeHours: 0, 
    bohOvertimeHours: 0, 
    fohRegularDollars: 0, 
    bohRegularDollars: 0, 
    fohOvertimeDollars: 0, 
    bohOvertimeDollars: 0
  })

  // Helper function to process metrics for a week
  const processWeekData = (weekData, summariesObj) => {
    weekData.forEach(item => {
      const { storeId, date, metricName, numberValue } = item

      if (!storeIdMap.has(storeId)) {
        console.warn(`Missing store mapping for ID: ${storeId}`)
      }

      const storeName = storeIdMap.get(storeId)?.shortName || `Unknown Store: ${storeId}`
      const area = storeIdMap.get(storeId)?.area || 'Unknown Area'
  
      if (!summariesObj[area]) summariesObj[area] = {}
      if (!summariesObj[area][storeName]) summariesObj[area][storeName] = {}
      if (!summariesObj[area][storeName][date]) {
        summariesObj[area][storeName][date] = initializeDayData()
      }
  
      summariesObj[area][storeName][date][metricName] += numberValue
    })
  }

  // Process current week data
  processWeekData(currentWeekData, dailySummaries)
  
  // Process prior week data
  if (priorWeekData?.length) {
    processWeekData(priorWeekData, priorWeekSummaries)
  }

  // Calculate additional metrics for both weeks
  const processWeekCalculations = (summariesObj) => {
    Object.keys(summariesObj).forEach(area => {
      Object.keys(summariesObj[area]).forEach(store => {
        Object.keys(summariesObj[area][store]).forEach(date => {
          const dayData = summariesObj[area][store][date]
          const { netSales, checkCount, bohOvertimeHours, bohRegularHours, 
                 fohOvertimeHours, fohRegularHours, bohOvertimeDollars, 
                 bohRegularDollars, fohOvertimeDollars, fohRegularDollars } = dayData

          // Calculate average check
          dayData.avgCheck = checkCount > 0 ? netSales / checkCount : 0
          
          // Calculate actual hours
          dayData.actFOH = fohOvertimeHours + fohRegularHours
          dayData.actBOH = bohOvertimeHours + bohRegularHours

          // Calculate total labor dollars
          dayData.laborDollars = bohOvertimeDollars + bohRegularDollars + 
                                fohOvertimeDollars + fohRegularDollars
        })

        // Calculate weekly totals for prior week data
        if (summariesObj === priorWeekSummaries) {
          const storeDays = Object.values(summariesObj[area][store])
          const weekTotals = {
            laborDollars: storeDays.reduce((sum, day) => sum + day.laborDollars, 0),
            laborHours: storeDays.reduce((sum, day) => 
              sum + day.actFOH + day.actBOH, 0),
            actFOH: storeDays.reduce((sum, day) => sum + day.actFOH, 0),
            actBOH: storeDays.reduce((sum, day) => sum + day.actBOH, 0)
          }
          summariesObj[area][store].weekTotals = weekTotals
        }
      })
    })
  }

  // Calculate metrics for both weeks
  processWeekCalculations(dailySummaries)
  if (Object.keys(priorWeekSummaries).length > 0) {
    processWeekCalculations(priorWeekSummaries)
  }

  // Add prior week totals to current week data structure
  Object.keys(dailySummaries).forEach(area => {
    Object.keys(dailySummaries[area]).forEach(store => {
      if (priorWeekSummaries[area]?.[store]?.weekTotals) {
        dailySummaries[area][store].priorWeek = 
          priorWeekSummaries[area][store].weekTotals
      }
    })
  })

  return dailySummaries
}