import React from 'react'
import { CircularProgress } from '@mui/material'
import MDBox from 'components/MDBox'


export default function Progress() {
  return (
    <MDBox sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '80vh' }}>    
      <CircularProgress size={100} color='info' />      
    </MDBox>
  )
}