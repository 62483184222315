import { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { createR0003StoreNotes } from 'graphql/mutations';

export const useNotes = (tableData, setTableData) => {
  const [notes, setNotes] = useState({
    monNotes: '',
    tueNotes: '',
    wedNotes: '',
    thuNotes: '',
    friNotes: '',
    satNotes: '',
    sunNotes: ''
  });
  const [openAddNote, setOpenAddNote] = useState(false);
  const [store, setStore] = useState('');

  useEffect(() => {
    if (store !== '') setOpenAddNote(true);
  }, [store]);

  const openAddNoteDialog = (selectedStore) => {
    const storeIdx = tableData.findIndex(row => row.day === selectedStore);
    const initialNotes = {
      monNotes: tableData[storeIdx + 1].notes,
      tueNotes: tableData[storeIdx + 2].notes,
      wedNotes: tableData[storeIdx + 3].notes,
      thuNotes: tableData[storeIdx + 4].notes,
      friNotes: tableData[storeIdx + 5].notes,
      satNotes: tableData[storeIdx + 6].notes,
      sunNotes: tableData[storeIdx + 7].notes
    };
    setNotes(initialNotes);
    setStore(selectedStore);
  };

  const submitNote = async (values, week) => {
    let tempData = [...tableData];
    const storeIdx = tempData.findIndex(row => row.day === store);
    
    ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].forEach((day, index) => {
      tempData[storeIdx + index + 1].notes = values[`${day}Notes`];
    });

    try {
      await API.graphql({
        query: createR0003StoreNotes,
        variables: { 
          input: {
            week,
            store,
            notes: values
          }
        }
      });
      setTableData(tempData);
      closeDialog();
    } catch(err) {
      console.log('Store Notes Upload Error', err);
      throw err;
    }    
  };

  const closeDialog = () => {
    setOpenAddNote(false);
    setStore('');
  };

  return {
    notes,
    openAddNote,
    store,
    openAddNoteDialog,
    submitNote,
    closeDialog
  };
};