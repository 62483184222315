import { API } from 'aws-amplify'
import { deleteThreshold } from 'graphql/mutations'

export default async function deleteProfile(profileId){
  console.log('deleteProfile', profileId)
  
  const deleteThresholdInput = {
    id: profileId
  }

  try {
    const response = await API.graphql({
      query: deleteThreshold,
      variables: {
        input: deleteThresholdInput,
      }
    })
    console.log('response', response)
  } catch (err){
    console.log('Error deleting profile', err)
  }
}