import { Container, Link } from '@mui/material'

// react-router components
// import { Link } from "react-router-dom";

// Material Dashboard 2 Pro React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography';

function LoginNavBar(){

  return (
    <Container>
      <MDBox
        py={1}
        px={{ xs: 4, sm: 3, lg: 2 }}
        my={3}
        mx={3}
        width='calc(100% - 48px)'
        shadow='md'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        position='absolute'
        left={0}
        zIndex={3}
      >
        <Link href='https://radixdash.com' >
          <MDTypography variant='h3' color='white'>
            RadixDash
          </MDTypography>
        </Link>
      </MDBox>
    </Container>
  )
}

export default LoginNavBar