// @mui material components
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'

export default function ReportListItem({
  page,
  setPage,
  title
}){

  return (
    <ListItem disablePadding>
      <ListItemButton
        onClick={
          () => setPage(page)
        }
      >
        <ListItemIcon
          sx={{
            color: '#a44c0e'
          }}
        >
          <SettingsIcon 
            fontSize='medium'
          />
        </ListItemIcon>
        <ListItemText 
          primary={title}
          sx={{
            '& .MuiListItemText-primary': {
              color: '#a44c0e'
            }
          }}
        />
      </ListItemButton>
    </ListItem>
  )
}