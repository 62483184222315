/* eslint-disable  */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogActions, DialogContent, DialogTitle, Icon, InputAdornment, Stack, TextField, Tooltip } from '@mui/material'
import { differenceInDays, parseISO } from 'date-fns';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from 'components/MDButton'

// RadixDash components
import Button_GrmDark from 'components/RDButton/Button_GrmDark'
import GPCellStyle from './GPCellStyle'
import RDTable from 'components/RDTable'

import goalPlannerExportPdf from './goalPlannerExportPdf';
import { getAnnualOpportunity } from './r0001Util'


function validateData(value, column, compValue){
  
  let result
  let valNum
  let compNum

  const digitsOnly = (value) => /^\d*[\.{1}\d*]\d*$/.test(value) || value.length === 0
  const pctDigitsOnly = (value) => /^\d?\d(?:\.\d*)?$|^100$/.test(value) || value.length === 0
  
  switch(column){
    case 'dollarGoal':
      // check that value is a number
      if(digitsOnly(value)){
        if(value.length === 0){
          result = 'valid'
        } else {
          // check that value is greater than compValue
          valNum = Number(value.replace(/[^0-9.-]+/g,""))
          compNum = Number(compValue.replace(/[^0-9.-]+/g,""))
          valNum > compNum ? result = 'valid' : result = `Number must be greater than ${compNum}`
        }
      } else {
        result = 'Invalid number'
      }
      break;
    case 'percentGoal':
      // check that value is a number between 0 and 100
      if(pctDigitsOnly(value)){
        if(value.length === 0){
          result = 'valid'
        } else {
          // check that value is greater than compValue
          valNum = Number(value.replace(/[^0-9.-]+/g,""))
          compNum = Number(compValue.replace(/[^0-9.-]+/g,""))
          valNum > compNum ? result = 'valid' : result = `Number must be greater than ${compNum}`
        }
      } else {
        result = `Enter number from ${Number(compValue.replace(/[^0-9.-]+/g,""))} - 100`
      }
      break;
    default:
      // column is 'tactics'
      result = 'valid'
  }
  
  return result
      
}


// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index, original },
  column: { id },
  updateCellData, // This is a custom function that we supplied to our table instance
}) => {

  // EditableCell styling variables
  let width
  // id === 'tactics' ? width = 150 : width = 75

  let textAlign
  // id === 'tactics' ? textAlign = 'left' : textAlign = 'center'

  let multiline

  if(id === 'tactics'){
    width = 150
    textAlign = 'left'
    multiline = true
  } else {
    width = 75
    textAlign = 'center'
    multiline = false
  }

  const kpi = original.kpi
  switch(kpi){
    case 'Net Sales Food':
    case 'Net Sales Starters':
    case 'Net Sales Pizza':
    case 'Net Sales Desserts':
    case 'Net Sales Beverage':
    case 'Net Sales Beer':
    case 'Net Sales Liquor':
    case 'Net Sales Wine':
    // case 'Comp Count':
    case 'Comp Dollars':
    // case 'Void Count':
    case 'Void Dollars':
    case 'Void Wrong Made':
    // case 'Promo Count':
    case 'Promo Dollars':
      // No action taken
      break;
    default:
      if(id === 'percentGoal') return null
  }
  

  
  const [value, setValue] = useState(initialValue)
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState(' ')


  useEffect(() => {
    let compValue
    switch(id){
      case 'dollarGoal':
        compValue = original.actualValue
        break;
      case 'percentGoal':
        compValue = original.actualPercent
        break;
      default:
        compValue = null
    }
  
    const validationResult = validateData(value, id, compValue)
    
    if(validationResult === 'valid'){
      setError(false)
      setErrorMsg(' ')
    } else {
      setError(true)
      setErrorMsg(validationResult)
    }
  }, [value])

  // We need to keep and update the state of the cell normally
  
  const onChange = e => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateCellData(index, id, value)
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  let startAdornment 
  let endAdornment
  switch(id){
    case 'dollarGoal':
      switch(kpi){
        case 'Check Count':
        case 'Dine-In Check Count':
        case 'Delivery Check Count (Gross/Net)':
        case 'To Go Check Count':
        case 'Comp Count':
        case 'Void Count':
        case 'Promo Count':
          startAdornment = null
          break;
        default:
          startAdornment = 
            <InputAdornment position='start'>$</InputAdornment>    
      }      
      endAdornment = null
      break;
    case 'percentGoal':
      endAdornment = <InputAdornment position='end'>%</InputAdornment>
      break;
    default:
      startAdornment = null
      endAdornment = null
  }
  

  return (
    <MDBox mt={2}>
      <TextField 
        error={ error }
        helperText={ errorMsg }
        value={value} 
        onChange={onChange} 
        onBlur={onBlur} 
        multiline={ multiline }
        hiddenLabel
        variant='standard'
        size='small'
        sx={{
          '& .MuiInputBase-root': {
            width: width,
            // height: '1rem',
            fontSize: '.7rem',
            paddingBottom: 0,
            
            '& input': {
              textAlign: textAlign
            }
          },
          '& .MuiInputAdornment-root': {
            '& .MuiTypography-root': {
              fontSize: '.7rem',
              fontWeight: 'bold'
            }
          }
        }}
        InputProps={{
          startAdornment: startAdornment,
          endAdornment: endAdornment
        }}
      />

    </MDBox>
  )
}

function GoalPlanner(props){
  
  const [data, setData] = useState(props.data)
  const [clearSelections, setClearSelections] = useState(false)

  const daysInPeriod = differenceInDays(parseISO(props.dateRange[1]), parseISO(props.dateRange[0])) + 1
  
  useEffect(() => {
    setData(props.data)
  }, [props.data])

  const headerBG = '#e0e0e0'
  const headerTextColor = '#181818'
  const headerFontSize = '.7rem'

  /***** Columns */
  const columns = [
    {
      Header: 'k',
      columnStyle: {
        backgroundColor: headerBG,
        color: headerBG,
        width: 350,
        fontSize: headerFontSize,
        borderBottom: 'none'
      },
      columns: [
        {
          Header: 'KPI',
          accessor: 'kpi',
          align: 'center',
          height: '1.25rem',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            fontSize: headerFontSize,
            borderBottom: '1px solid #000',
          },
        }
      ]
    },
    {
      Header: 'Dollar Goal',
      align: 'center',
      columnStyle: {
        backgroundColor: headerBG,
        color: headerTextColor,
        fontSize: headerFontSize,
        borderBottom: 'none',
        borderLeft: '1px solid #000'
      },
      columns: [
        {
          Header: 'Current',
          accessor: 'actualValue',
          align: 'center',
          alignCell: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            fontSize: headerFontSize,
            borderBottom: '1px solid #000',
            borderLeft: '1px solid #000'
          },
        },
        {
          Header: 'Rank',
          accessor: 'valueRank',
          align: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            fontSize: headerFontSize,
            borderBottom: '1px solid #000',
          },
        },
        {
          Header: 'Goal',
          accessor: 'dollarGoal',
          align: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            fontSize: headerFontSize,
            borderBottom: '1px solid #000',
          },
          Cell: EditableCell,
        },
        {
          Header: 'Annual Opportunity',
          accessor: 'dollarOpp',
          align: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            fontSize: headerFontSize,
            borderBottom: '1px solid #000',
          },
        },
      ]
    },
    {
      Header: 'Percentage Goal',
      align: 'center',
      columnStyle: {
        backgroundColor: headerBG,
        color: headerTextColor,
        fontSize: headerFontSize,
        borderBottom: 'none',
        borderLeft: '1px solid #000'
      },
      columns: [
        {
          Header: 'Current',
          accessor: 'actualPercent',
          align: 'center',
          alignCell: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            fontSize: headerFontSize,
            borderBottom: '1px solid #000',
            borderLeft: '1px solid #000'
          },
          Cell: ({row, value}) => {
            const data = row.original
            switch(data.kpi){
              case 'Net Sales Food':
              case 'Net Sales Starters':
              case 'Net Sales Pizza':
              case 'Net Sales Desserts':
              case 'Net Sales Beverage':
              case 'Net Sales Beer':
              case 'Net Sales Liquor':
              case 'Net Sales Wine':
              case 'Comp Dollars':
              case 'Void Dollars':
              case 'Void Wrong Made':
              case 'Promo Dollars':
                return String(value)
              default:
                return null
            }
          }
        },
        {
          Header: 'Rank',
          accessor: 'percentRank',
          align: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            fontSize: headerFontSize,
            borderBottom: '1px solid #000',
          },
          Cell: ({row, value}) => {
            const data = row.original
            switch(data.kpi){
              case 'Net Sales Food':
              case 'Net Sales Starters':
              case 'Net Sales Pizza':
              case 'Net Sales Desserts':
              case 'Net Sales Beverage':
              case 'Net Sales Beer':
              case 'Net Sales Liquor':
              case 'Net Sales Wine':
              case 'Comp Dollars':
              case 'Void Dollars':
              case 'Void Wrong Made':
              case 'Promo Dollars':
                return String(value)
              default:
                return null
            }
          }
        },
        {
          Header: 'Goal',
          accessor: 'percentGoal',
          align: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            fontSize: headerFontSize,
            borderBottom: '1px solid #000',
          },
          Cell: EditableCell
        },
        {
          Header: 'Annual Opportunity',
          accessor: 'percentOpp',
          align: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            fontSize: headerFontSize,
            borderBottom: '1px solid #000',
          },
          Cell: ({row, value}) => {
            const data = row.original
            switch(data.kpi){
              case 'Net Sales Food':
              case 'Net Sales Starters':
              case 'Net Sales Pizza':
              case 'Net Sales Desserts':
              case 'Net Sales Beverage':
              case 'Net Sales Beer':
              case 'Net Sales Liquor':
              case 'Net Sales Wine':
              case 'Comp Dollars':
              case 'Void Dollars':
              case 'Void Wrong Made':
              case 'Promo Dollars':
                return String(value)
              default:
                return null
            }
          }
        }
      ]
    },
    {
      Header: 't',
      align: 'center',
      columnStyle: {
        backgroundColor: headerBG,
        color: headerBG,
        width: 350,
        fontSize: headerFontSize,
        borderLeft: '1px solid #000',
        borderBottom: 'none'
      },
      columns: [
        {
          Header: 'Tactics',
          accessor: 'tactics',
          align: 'center',
          alignCell: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            fontSize: headerFontSize,
            borderBottom: '1px solid #000',
            borderLeft: '1px solid #000'
          },
          Cell: EditableCell,
        }
      ]
    }    
  ]

  const updateCellData = async (rowIndex, columnId, value) => {         
    setData(old => old.map((row, index) => {   
      let updatedRow = []   
      
      if(index === rowIndex) {
        switch(columnId) {
          case 'dollarGoal':
            if(value){
              let dollarOpp = getAnnualOpportunity(
                row.kpi, 
                props.allData,
                value,
                'dollarOpp',
                daysInPeriod
              )
              
              updatedRow = {
                ...old[rowIndex],
                [columnId]: value,
                ['dollarOpp']: dollarOpp
              }
            } else {
              return row
            }
            break;
          case 'percentGoal':
            if(value){
              let percentOpp = getAnnualOpportunity(
                row.kpi, 
                props.allData,
                value,
                'percentOpp',
                daysInPeriod
              )
              updatedRow = {
                ...old[rowIndex],
                [columnId]: value,
                ['percentOpp']: percentOpp
              }
            } else {
              return row
            }
            break;
          case 'tactics':
            if(value){
              updatedRow = {
                ...old[rowIndex],
                [columnId]: value
              }
            } else {
              return row
            }
            break;
          default:
            return row
        }        
        return updatedRow          
      } 
      return row
    }))  
  }

  function handleClose(){
    console.log('handleClose')
    // toggleAllRowsSelected(false)
    
    props.close()
  }

  useEffect(() => {
    console.log('clearSelections', clearSelections)
  }, [clearSelections])

  const title = `${props.store} Goal Planning | ${props.dateRange[0]} - ${props.dateRange[1]}`

  function exportPDF() {
    console.log('exportPDF')
    goalPlannerExportPdf(data, title)
  }
  

  return (
    <Dialog
      open={ props.open }
      onClose={ props.close }
      aria-labelledby='form-dialog-title'
      maxWidth='lg'
    >
      <DialogTitle id='form-dialog-title' 
        sx={{ 
          backgroundColor: '#29261f',
          color: '#cf9e0b'
        }}
      >
        <Stack direction='row' justifyContent='space-between'>
          { title }
          <Tooltip title='Export to PDF' >
            <MDButton
              variant='gradient'
              onClick={ exportPDF }
              iconOnly
              sx={{
                bgcolor: '#cf9e0b',
                color: '#29261f',
                '&:hover': {
                  bgcolor: '#906e07'
                }
              }}
            >
              <Icon fontSize='large' color='inherit'>
                {/* picture_as_pdf */}
                download
              </Icon>
            </MDButton>
          </Tooltip>
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          backgroundColor: '#f9e9d3',
        }}
      >
        <MDBox>
          <RDTable 
            table={{
              columns: columns,
              rows: data
            }}
            editable={{
              onRowAdd: null,
              onRowEdit: null,
              onRowDelete: null
            }}
            showTotalEntries={false}
            entriesPerPage={{defaultValue: data.length + 1}}
            cellStyle={ GPCellStyle }
            updateCellData={updateCellData}
            // tableOptions={{
            //   error: errorArray
            // }}
          />
        </MDBox>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: '#f9e9d3',
        }}
      >
        <Button_GrmDark
          onClick={ handleClose }
        >
          Close
        </Button_GrmDark>
      </DialogActions>
    </Dialog>
  )
}

EditableCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object,
  column: PropTypes.object,
  updateCellData: PropTypes.func,
}

GoalPlanner.propTypes = {
  allData: PropTypes.array,
  close: PropTypes.func,
  data: PropTypes.array,
  dateRange: PropTypes.array,
  open: PropTypes.bool,
  store: PropTypes.string,
}

export default GoalPlanner