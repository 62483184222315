import React, { createContext, useContext, useEffect, useState } from 'react'
import { Auth, API, Hub } from 'aws-amplify'
import PropTypes from "prop-types";

// GraphQL
import { listUserPermissions, listUsers } from '../../graphql/queries'

// initialize context with an empty object
const UserContext = createContext({})

export default function RDAuthContext({children}) {
  const [cognitoUser, setCognitoUser] = useState(null)
  const [user, setUser] = useState(null)
  const [permissions, setPermissions] = useState(null)

  useEffect(() => {
    console.log('initial useEffect -> checkUser()')
    checkUser()
  }, [])

  useEffect(() => {
    Hub.listen('auth', () => {
      //console.log('Hub.listen useEffect -> checkUser()')
      checkUser()
    })
  }, [])

  async function checkUser() {
    console.log('checkUser...')
    try {
      // Get the current authenticated user
      const user = await Auth.currentUserPoolUser()
      if(user) {
        setCognitoUser(user)
      }
    } catch (err) {
      console.log('no cognitoUser')
      // set the user to null if there is no user
      setCognitoUser(null)
    }
  }

  useEffect(() => {
    console.log('cognitoUser changed', cognitoUser)
    if(cognitoUser !== null){
      // getPermissions()
      getUserData()
    } else {
      setUser(null)
    }
  }, [cognitoUser])

  async function getUserData(){
    console.log('getUserData')    
    try {
      const users = []

      const filter = {
        userName: {
          eq: cognitoUser.username
        }
      }

      let nextToken

      do {
        const queryResponse = await API.graphql({
          query: listUsers,
          variables: {
            filter: filter,
            nextToken: nextToken
          }
        })
        console.log('queryResponse', queryResponse)
        users.push(...queryResponse.data.listUsers.items)
        nextToken = queryResponse.data.listUsers.nextToken
      } while (nextToken)
      setUser(users[0])
    } catch (err) {
      console.log('error fetching userData', err)
      setUser(null)
    }    
  }

  useEffect(() => {
    if(user !== null){
      getPermissions()
    }
  }, [user])

  async function getPermissions() {
    console.log('getPermissions()...') 
    // let key = 'custom:role'
    // let role = user.attributes[key]
    try {
      const permissionResponse = await API.graphql({
        query: listUserPermissions,
        variables: {
          role: user.role
        }
      })
      // console.log('permissionResponse', permissionResponse)
      setPermissions(permissionResponse.data.listUserPermissions)
    } catch (err) {
      console.log('Error fetching Permissions data', err)
      console.log('user', user)
    }
  }

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        permissions,
      }}
    >
      { children }
    </UserContext.Provider>
  )
}

RDAuthContext.propTypes = {
  children: PropTypes.node
}

export const useUser = () => useContext(UserContext)