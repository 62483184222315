import React from 'react'

// @mui components
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

// Material Dashboard Pro 2 components
import MDTypography from 'components/MDTypography'

// RadixDash components
import CompStoreSelector from '../CompStoreSelector'

export default function ComparisonStoreAccordion({
  companyStores,
  expanded,
  handleChange,
  selectedStores,
  setSelections,
}){
  return (
    <Accordion 
      expanded={ expanded === 'CompStores' } 
      onChange={ handleChange('CompStores') }
    >
      <AccordionSummary 
        expandIcon={<ExpandMoreIcon fontSize='medium' />}
      >
        <MDTypography variant='h6'>
          Comparison Stores [{selectedStores.length} Stores Selected]
        </MDTypography>
      </AccordionSummary>
      <AccordionDetails>
        <CompStoreSelector 
          allStores={companyStores}
          selectedStores={selectedStores}
          setSelectedStores={setSelections}
        />
      </AccordionDetails>
    </Accordion>
  )
}