import React, { useState }  from 'react'
import PropTypes from "prop-types";
// material-ui components
import { makeStyles } from '@material-ui/core/styles'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from '@material-ui/core/Slide'
import Button from "components/CustomButtons/Button.js";
// @material-ui/icons
import Close from "@material-ui/icons/Close";

// RadixDash components
import EditUserForm from './EditUserForm'
import styles from 'assets/jss/material-dashboard-pro-react/clients/admin/editUserModalStyle'

const useStyles = makeStyles(styles)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

export default function EditUserModal(props) {
  console.log('props', props)
  const classes = useStyles()

  // // Get userName
  // let userName = props.user.Username

  // // Get firstName
  // let idx = props.user.Attributes.findIndex( x => x.Name === 'custom:firstName')
  
  // let firstName = ''
  // if(idx !== -1){
  //   firstName = props.user.Attributes[idx].Value
  // }

  // // Get lastName
  // idx = props.user.Attributes.findIndex( x => x.Name === 'custom:lastName')
  
  // let lastName = ''
  // if(idx !== -1){
  //   lastName = props.user.Attributes[idx].Value
  // }  

  // // Get Role
  // idx = props.user.Attributes.findIndex( x => x.Name === 'custom:role')
  
  // let role = ''
  // if(idx !== -1){
  //   role = props.user.Attributes[idx].Value
  // }  
  
  // // Get Email
  // idx = props.user.Attributes.findIndex( x => x.Name === 'email')
  
  // let email = ''
  // if(idx !== -1){
  //   email = props.user.Attributes[idx].Value
  // }  

  async function updateUser(values){
    console.log('Update User', values.firstName)
    props.submit(values)
  }
  
  async function handleSubmit(values){
    console.log('submit pressed')
    props.submit(values)
  }

  function handleClose() {
    console.log('closed pressed')
    props.close()
  }

  function handleCancel() {
    console.log('cancel pressed')
    props.cancel()
  }

  async function handleDelete(){
    props.delete()
  }

  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal
      }}
      open={props.open}
      transition={Transition}
      keepMounted
      // onClose={() => setModal(false)}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogTitle
        id='classic-modal-slide-title'
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key='close'
          aria-label='Close'
          color='transparent'
          onClick={ handleClose }
        >
          <Close className={classes.modalClose} />
        </Button>
        <h3 className={classes.modalTitle}>Edit User Profile</h3>
      </DialogTitle>
      <DialogContent
        id='modal-slide-description'
        className={classes.modalBody}
      >
        <EditUserForm 
          userData={ props.user } 
          submit={ updateUser } 
          cancel={ handleCancel }
          delete={ handleDelete }
        />
        
      </DialogContent>
    </Dialog>
  )
}

EditUserModal.propTypes = {
  submit: PropTypes.func,
  close: PropTypes.func,
  cancel: PropTypes.func,
  delete: PropTypes.func,
  user: PropTypes.string,
  open: PropTypes.bool
}