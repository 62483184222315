import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// @mui components
import { Autocomplete, Dialog, DialogTitle, DialogContent, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

// formik components
import { Formik, Form } from 'formik'
import Textfield from 'components/FormsUI/Textfield'
import * as Yup from 'yup'

// Material Dashboard Pro 2 components
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

import Select_Rd from 'components/FormsUI/Select/Select_Rd'


export default function AddUserDialog(props){

  const existingUsers = props.existingUsers
  console.log('props.clients', props.clients)
  const clients = props.clients.map(x => x.clientId)
  const clientId = props.clientId

  console.log('clients', clients)

  const [client, setClient] = useState(null)
  const [roles, setRoles] = useState({})
  const [clientButtonOpen, setClientButtonOpen] = useState(false)

  useEffect(() => {
    if(clientId === 'rdx'){
      setClient(null)
    } else {
      setClient(clientId)
    }
  }, [])

  useEffect(() => {
    console.log('client changed', client)
    console.log('props.roles', props.roles)
    if(client !== null){
      if(client in props.roles){
        setRoles(props.roles[client])
      } else {
        console.log('client not in roles database')
      }
    }
  }, [client])

  useEffect(() => {
    console.log('roles changed', roles)
  }, [roles])
  
  function handleSubmit(values){
    console.log('client', client)
    values.clientId = client
    console.log('values', values)
    props.submit(values)
  }

  const INITIAL_FORM_STATE = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    role: ''
  }

  const FORM_VALIDATION = Yup.object().shape({
    firstName: Yup.string()
      .required('Required'),
    lastName: Yup.string()
      .required('Required'),
    userName: Yup.string()
      .notOneOf(existingUsers, 'User Name already assigned.')
      .required('Required'),
    email: Yup.string()
      .email('Invalid email')
      .required('Required'),
    role: Yup.string()
      .required('Required')
  })

  // function handleSubmit(values) {
  //   props.submit(values)
  // }

  const handleClientChange = (event, selection) => {
    console.log('event', event)
    console.log('selection', selection)
    setClient(selection)
  }

  return (
    <Dialog
      open={ props.open }
      fullWidth
      maxWidth={'xs'}
    >
      <DialogTitle>Add User</DialogTitle>
      <DialogContent>
        { clientId === 'rdx' &&
          <Autocomplete
            id='clientSelector'
            options={ clients } 
            value={ client }
            onChange={ handleClientChange }
            disableClearable={ true }
            size='small'
            renderInput={(params) => 
              <TextField
                {...params}
                label='Client ID'
                variant='outlined'
                fullWidth
              />
            }
            sx={{
              marginTop: 1
            }}
          />
        }        
        
        <Formik
          initialValues={INITIAL_FORM_STATE}
          validationSchema={FORM_VALIDATION}
          onSubmit={ (values) => { handleSubmit(values) }}
        >
          {({ isValid, dirty }) => (
            <Form>
              <Stack
                direction='column'
                sx={{
                  mt: 2
                }}
              >
                { client !== null &&
                  <Stack
                    direction='column'
                    spacing={2}
                  >
                    <Textfield 
                      name='firstName'
                      label='First Name'
                    />
                    <Textfield 
                      name='lastName'
                      label='Last Name'
                    />
                    <Textfield 
                      name='userName'
                      label='User Name'
                      helperText='User Name will automatically save in lowercase'
                    />
                    <Textfield 
                      name='email'
                      label='Email'
                    />
                    <Select_Rd 
                      name='role'
                      options={ roles }
                      label='Roles'
                    />
                  </Stack>
                }
                <Stack
                  direction='row'
                  spacing={2}
                  justifyContent='flex-end'
                  alignItems='center'
                  sx={{
                    mt: 2
                  }}
                >
                  <MDButton variant='contained' color='info' onClick={ props.close }>
                    Cancel
                  </MDButton>
                  <MDButton variant='contained' color='info' type='submit' disabled={!(dirty && isValid)}>
                    Submit
                  </MDButton>
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

AddUserDialog.propTypes = {
  clientId: PropTypes.string,
  clients: PropTypes.array,
  close: PropTypes.func,
  existingUsers: PropTypes.array,
  open: PropTypes.bool,
  roles: PropTypes.object,
  submit: PropTypes.func,
}