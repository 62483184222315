import { useState, useEffect, useMemo } from 'react'

import { HookStatus } from 'hooks/hookStatus'

import { getActualSalesQuery, getProjectedSalesQuery } from '../r0005Fetch'

import useActualSales from './useActualSales';
import useAreas from 'hooks/clients/grm/useAreas';
import useProjectedSales from './useProjectedSales';
import useStableQuery from './useStableQuery';
// import useTotalsRow from './useTotalsRow';

export default function useTableData(
  selectedDate
){
  console.log('**** useTableData ****')
  
  const [status, setStatus] = useState(HookStatus.PENDING)

  const actualSalesQuery = useStableQuery('actualSales', selectedDate, () => getActualSalesQuery(selectedDate))
  
  const { data: actualSalesData, ...actualSalesState } = useActualSales(actualSalesQuery)

  const projectedSalesQuery = useStableQuery('projectedSales', selectedDate, () => getProjectedSalesQuery(selectedDate))

  const { data: projectedSalesData, ...projectedSalesState } = useProjectedSales(projectedSalesQuery)

  const { data: areaData, companyAreas, franchiseAreas, ...areaState } = useAreas()

  const dataReady = useMemo(() => {
    return (
      actualSalesState.status === 'success' && 
      projectedSalesState.status === 'success' && 
      areaState.status === 'success'
    )
  }, [actualSalesState.status, projectedSalesState.status, areaState.status])

  // Transform the data
  const data = useMemo(() => {
    if( !dataReady ){
      return null        
    }
    return transformData(
      actualSalesData, 
      projectedSalesData, 
      areaData
      )

  }, [dataReady, actualSalesData, projectedSalesData, areaData])

  // const { totalsRowData } = useTotalsRow(transformedData, )

  useEffect(() => {
    if(data !== null){
      console.log('!*** data', data)
      setStatus(HookStatus.SUCCESS)
    }
  }, [data])

  return {
    data,
    status
  }

}

function transformData(actualSales, forecastSales, areas) {
  
  const testData = [];
  
  areas.forEach(area => {
    area.stores.forEach(store => {
      
      const storeData = {
        areaId: area.areaId,
        area: area.areaName,
        store: store.shortName,  
      };
      
      const actualValues = actualSales.find(s => s.store === store.longName)?.values;
      const forecastStore = forecastSales[0].stores.find(s => s.store === store.shortName);
      const forecastValues = forecastStore?.values;
      
      if (actualValues && forecastValues) {
        for (let i = 0; i < 7; i++) {          
          storeData[`act${i+1}`] = actualValues[i]?.value;
          storeData[`fcst${i+1}`] = forecastValues[i]?.value;
        }
      }
      
      testData.push(storeData);
    });
  });

  // Add totals column
  testData.forEach(item => {
    let actTotal = 0
    let fcstTotal = 0

    for(let i = 1; i <= 7; i++) {
      actTotal += item[`act${i}`] || 0
      fcstTotal += item[`fcst${i}`] || 0
    }

    item.actTotal = actTotal
    item.fcstTotal = fcstTotal
  })

  // Add totals row
  const totals = {}

  for (let i = 1; i <= 7; i++) {
    
    const actKey = `act${i}`
    const fcstKey = `fcst${i}`

    testData.forEach(item => {
      if(!totals[item.area]) {
        totals[item.area] = {}
      }

      if(!totals[item.area][actKey]) {
        totals[item.area][actKey] = 0
      }
      totals[item.area][actKey] += item[actKey]
    

      if(!totals[item.area][fcstKey]){
        totals[item.area][fcstKey] = 0
      }
      totals[item.area][fcstKey] += item[fcstKey]

    })
  }

  // Create total objects
  const totalObjects = Object.keys(totals).map(area => {
    return {
      area,
      store: `${area} Totals`,
      ...totals[area]
    }
  })

  // Create total Act and Fcst for totals row
  totalObjects.forEach(item => {
    let actTotal = 0
    let fcstTotal = 0

    for (let i = 1; i <=7; i++) {
      actTotal += item[`act${i}`] || 0;
      fcstTotal += item[`fcst${i}`] || 0;
    }
    
    item.actTotal = actTotal; 
    item.fcstTotal = fcstTotal;
  })

  return testData
    .concat(totalObjects)
    .sort((a,b) => {
      if(a.area === b.area) {
        if(a.store.includes('Totals')) return 1
        if(b.store.includes('Totals')) return -1
      }
      return a.area.localeCompare(b.area) || a.store.localeCompare(b.store)
    })
}