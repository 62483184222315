import React from 'react'
import PropTypes from 'prop-types'

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

export default function RDStickyHeadCell({
  align,
  children,
  colSpan,
  left,
  rowSpan,
  top,
  width,
  style,
  ...rest
}) {
  return (
    <MDBox
      component='th'
      width={width}
      colSpan={colSpan}
      style={style}
    >
      <MDBox
        {...rest}
        color={style.color}
        textAlign={align}
      >
        {children}
      </MDBox>
    </MDBox>
  )

}

RDStickyHeadCell.defaultProps = {
  width: "auto",
  align: "center",
};

RDStickyHeadCell.propTypes = {
  align: PropTypes.oneOf(['left', 'right', 'center']),
  children: PropTypes.node.isRequired,
  colSpan: PropTypes.number,
  left: PropTypes.string,
  rowSpan: PropTypes.number,
  style: PropTypes.object,
  top: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}