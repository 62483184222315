import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// AWS Amplify components
import { API } from 'aws-amplify'

// GraphQL
import { listUserPermissions, getReport, getReportv2 } from '../../../../graphql/queries'


// @mui material components
import { Autocomplete, Card, Divider, Grid, IconButton, Stack, Switch, TextField, ThemeProvider, Tooltip } from '@mui/material'
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Material Dashboard 2 PRO React components
import MDAlert from 'components/MDAlert'
import MDBox from "components/MDBox";
import MDButton from 'components/MDButton'
import MDDatePicker from 'components/MDDatePicker'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'layouts/DashboardLayout'

import { addDays, compareAsc, differenceInCalendarDays, format, startOfWeek, subDays, subWeeks } from 'date-fns';

import { CSVLink } from 'react-csv'

// RadixDash components
import Button_DemDark from 'components/RDButton/Button_DemDark'
import Button_DemHead from 'components/RDButton/Button_DemHead'
import DashboardNavbar from 'components/RDNavbars/DashboardNavbar';
import Progress from 'components/Progress/Progress'
import RDConfigurator from 'components/RDConfigurator'
import RDFooter from  'components/RDFooter'
import R0002Table from './R0002Table';
import AreaSelector from './R0002AreaSelector'
import R0002ExportPdf from './R0002ExportPdf'
// import Switch_GrmDark from 'components/RDSwitch/Switch_GrmDark'
import { buildPage, buildPage2 } from 'utils/reportUtils'
import demTheme from 'clients/dem/demTheme'
// const columnNames = require('./columns')

import { useUser } from 'components/RDAuthContext'


function getYears(cyYear){
  let startYear = 2019
  const years = []
  while(startYear <= cyYear - 1){
    years.push( {year: startYear.toString() })
    startYear++
  }
  years.sort((a,b) => (a.year > b.year) ? -1 : ((b.year > a.year) ? 1 : 0))
  return years
}

const csvHeaders = [
  {label: 'Store', key: 'store'},
  {label: 'PY Net', key: 'pNSTotal'},
  {label: 'CY Net', key: 'cNSTotal'},
  {label: '%', key: 'pct'},
  {label: 'Dine In', key: 'cNSDineIn'},
  {label: 'To Go', key: 'cNSToGo'},
  {label: 'Net Del', key: 'cNSDelivery'},
  {label: 'Grs Del', key: 'cGSDelivery'},
  {label: 'CC Total', key: 'cCCTotal'},
  {label: 'CC Dine In', key: 'cCCDineIn'},
  {label: 'CC To Go', key: 'cCCToGo'},
  {label: 'CC Del', key: 'cCCDelivery'},
  {label: 'AC Total', key: 'cACTotal'},
  {label: 'AC Dine In', key: 'cACDineIn'},
  {label: 'AC To Go', key: 'cACToGo'},
  {label: 'AC Del', key: 'cACDelivery'},
  {label: 'SPLH Total', key: 'cSPLHTotal'},
  {label: 'SPLH FOH', key: 'cFOH'},
  {label: 'SPLH BOH', key: 'cBOH'},
  {label: 'OT Total', key: 'cOTTotal'},
  {label: 'OT FOH', key: 'cOTFOH'},
  {label: 'OT BOH', key: 'cOTBOH'},
  {label: 'Labor %', key: 'cLaborPct'},
]

const emptyRow = {
  cACDelivery: "",
  cACDineIn: "",
  cACToGo: "",
  cACTotal: "",
  cBOH: "",
  cCCDelivery: "",
  cCCDineIn: "",
  cCCToGo: "",
  cCCTotal: "",
  cFOH: "",
  cGSDelivery: "",
  cLaborPct: "",
  cNSDelivery: "",
  cNSDineIn: "",
  cNSToGo: "",
  cNSTotal: "",
  cOTBOH: "",
  cOTFOH: "",
  cOTTotal: "",
  cSPLHTotal: "",
  pACDelivery: "",
  pACDineIn: "",
  pACToGo: "",
  pACTotal: "",
  pBOH: "",
  pCCDelivery: "",
  pCCDineIn: "",
  pCCToGo: "",
  pCCTotal: "",
  pFOH: "",
  pGSDelivery: "",
  pLaborPct: "",
  pNSDelivery: "",
  pNSDineIn: "",
  pNSToGo: "",
  pNSTotal: "",
  pOTBOH: "",
  pOTFOH: "",
  pOTTotal: "",
  pSPLHTotal: "",
  compACDelivery: "",
  compACDineIn: "",
  compACToGo: "",
  compACTotal: "",
  compBOH: "",
  compCCDelivery: "",
  compCCDineIn: "",
  compCCToGo: "",
  compCCTotal: "",
  compFOH: "",
  compGSDelivery: "",
  compLaborPct: "",
  compNSDelivery: "",
  compNSDineIn: "",
  compNSToGo: "",
  compNSTotal: "",
  compOTBOH: "",
  compOTFOH: "",
  compOTTotal: "",
  compSPLHTotal: "",
  tipACDelivery: "",
  tipACDineIn: "",
  tipACToGo: "",
  tipACTotal: "",
  tipBOH: "",
  tipCCDelivery: "",
  tipCCDineIn: "",
  tipCCToGo: "",
  tipCCTotal: "",
  tipFOH: "",
  tipGSDelivery: "",
  tipLaborPct: "",
  tipNSDelivery: "",
  tipNSDineIn: "",
  tipNSToGo: "",
  tipNSTotal: "",
  tipOTBOH: "",
  tipOTFOH: "",
  tipOTTotal: "",
  tipSPLHTotal: "",
  pct: "",
  rowType: "",
  store: ""
}


export default function R0002Main(){

  const { permissions, user } = useUser()
  const role = user.role
  
  // Initialize date settings for report config
  const yearOptions = getYears(new Date().getFullYear())
  const initialPrevYear = yearOptions[0]
  const today = new Date()
  const maxDate = new Date(2022, 1, 28)
  const minDate = new Date(2022, 0, 1)
  const initialStartDate = new Date(2022, 1, 1)
  const initialEndDate = new Date(2022, 1, 7)
  const initialCompStartDate = new Date(2022, 0, 1)
  const initialCompEndDate = new Date(2022, 0, 7)
  // Set comparison period to 1 year prior to current period
  const curDiffdays = differenceInCalendarDays(maxDate, initialStartDate)
  const compSDate = subDays(initialStartDate, 364)
  const compEDate = addDays(compSDate, curDiffdays)

  
  const [alert, setAlert] = useState(null)
  const [alertMessage, setAlertMessage] = useState({
    type: 'none',
    message: ''
  })
  const [dataLoading, setDataLoading] = useState(false)
  const [curStartDate, setCurStartDate] = useState(initialStartDate)
  const [curEndDate, setCurEndDate] = useState(maxDate)
  const [curDateRange, setCurDateRange] = useState([initialStartDate, initialEndDate])
  const [compStartDate, setCompStartDate] = useState(compSDate)
  const [compEndDate, setCompEndDate] = useState(compEDate)
  const [compDateRange, setCompDateRange] = useState([initialCompStartDate, initialCompEndDate])
  const [prevYear, setPrevYear] = useState(initialPrevYear)
  const [prevYearOptions, setPrevYearOptions] = useState(yearOptions)
  const [area, setArea] = useState('All')
  const [title, setTitle] = useState('Summary Report')
  const [fullData, setFullData] = useState({})
  const [priceIncrease, setPriceIncrease] = useState(0)
  const [priceIncreaseSwitch, setPriceIncreaseSwitch] = useState(false)
  const [storeTypes, setStoreTypes] = useState([])
  const [storeTypeSwitch, setStoreTypeSwitch] = useState(false)
  const [compStoreSwitch, setCompStoreSwitch] = useState(false)
  const [data, setData] = useState([emptyRow])
  const [dataReady, setDataReady] = useState(false)
  const [filteredStoreList, setFilteredStoreList] = useState(data)
  const [rawData, setRawData] = useState([])
  const [pagePermissions, setPagePermissions] = useState({
    company: false,
    franchise: false
  })
  const [pageType, setPageType] = useState(null)
  const [pages, setPages] = useState({
    company: [],
    franchise: []
  })
  const [viewPermission, setViewPermission] = useState('')
  
  useEffect(() => {
    const idx = permissions.findIndex(x => x.report === 'R0002')
    const r0002Permission = permissions[idx].viewPermissions
    setViewPermission(r0002Permission)
  }, [])

  useEffect(() => {
    console.log('viewPermission changed', viewPermission)
    switch(viewPermission){
      case 'view-all-areas':
        setStoreTypes(['company', 'franchise'])
        setPagePermissions({
          company: true,
          franchise: true
        })
        setPageType('Company')
        break;
      case 'view-company-store-areas':
        setStoreTypes(['company'])
        setPagePermissions({
          company: true,
          franchise: false
        })
        setPageType('Company')
        break;
      default: // view-own-area
        setStoreTypes(['franchise'])
        setPagePermissions({
          company: false,
          franchise: true
        })
        setPageType('Franchise')
        break;
    }
  }, [viewPermission])

  /******************************** 
   * CONFIG handling
   ********************************/

  // Configurator State controls
  const [controller, dispatch] = useMaterialUIController();  
  const {
    openConfigurator,
  } = controller;
  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  function handleCurRange(range){
    if(range.length === 2){    
      setCurDateRange(range)

      const curYear = range[0].getFullYear()

      // Set Prev Year to 1 year before Current Year
      let pYear = {
        year: (curYear - 1).toString()
      }

      setPrevYear(pYear)

      // Reset Previous Period Year options
      let years = getYears(curYear)
      setPrevYearOptions(years)

      // Set Previous Period to 52 weeks earlier than Current Period
      let prevStart = subWeeks(range[0], 52)
      let prevEnd = subWeeks(range[1], 52)
      if(compareAsc(prevStart, minDate) === -1){
        // Start date is before minDate
        prevStart = minDate
        prevEnd = addDays(prevStart, differenceInCalendarDays(prevEnd, prevStart))
      }
      setCompDateRange([prevStart, prevEnd])
    }
  }

  function handleCompRange(range){
    if(range.length === 2){
      setCompDateRange(range)
    }
  }

  function handlePrevYear(year){
    setPrevYear(year)
    const currentYear = curDateRange[0].getFullYear()
    const yearDiff = currentYear - year.year
    const weekOffset = 52 * yearDiff
    const prevStart = subWeeks(curDateRange[0], weekOffset)
    const prevEnd = subWeeks(curDateRange[1], weekOffset)
    setCompDateRange([prevStart, prevEnd])
  }

  // const togglePISwitch = (event) => {
  //   setPriceIncreaseSwitch(event.target.checked)
  //   const dataCopy = [...data]
  //   setData(dataCopy)
  // }

// compStoreSwitch
  const toggleSTSwitch = (event) => {
    setStoreTypeSwitch(event.target.checked)
    setArea('All')
    // const dataCopy = [...data]
    // setData(dataCopy)
  }

  const toggleCSSwitch = (event) => {
    setCompStoreSwitch(event.target.checked)
  }

  useEffect(() => {
    console.log('storeTypeSwitch changed', storeTypeSwitch)
    console.log('pages', pages)
    if(storeTypeSwitch){
      setData(pages.franchise)
      setPageType('Franchise')
    } else {
      setData(pages.company)
      setPageType('Company')
    }
  }, [storeTypeSwitch])

  /******************************** 
   * CONFIG renderer
   ********************************/
  function Config(props) {

    return (
      
      <MDBox pt={0.5} pb={3} px={3}>
        <Grid container>
          <Grid item xs={12} textAlign="center" pb={1}>
            <MDTypography variant='h6'>Current Period</MDTypography>
          </Grid>
          
          <Grid item xs={12}>
            <MDDatePicker 
              id='currentDatePicker'
              value={ curDateRange } 
              options={{
                mode: 'range',
                minDate: minDate,
                maxDate: maxDate,
                'locale': {
                  'firstDayOfWeek': 1
                }
              }}
              fullWidth 
              onChange={ range => props.setCur(range)}    // eslint-disable-line
            />
          </Grid>
          <Grid item xs={12} textAlign="center" pb={1} pt={3}>
            <MDTypography variant='h6'>Comparison Period</MDTypography>
          </Grid>
          
          <Grid item xs={12} >
            <MDDatePicker 
              value={ compDateRange } 
              options={{
                mode: 'range',
                maxDate: maxDate,
                minDate: minDate,
                'locale': {
                  'firstDayOfWeek': 1
                }
              }}
              fullWidth  
              onChange={ range => props.setComp(range)}            // eslint-disable-line
            />
          </Grid>
        </Grid>
        {/* <Grid item xs={12} textAlign='center' pt={3} pb={1}>
          <Autocomplete 
            options={ prevYearOptions }
            getOptionLabel={ (option) => option.year }
            value={ prevYear }
            defaultValue={ initialPrevYear.year }
            renderInput={(params) => (
              <TextField 
                {...params}
                label='Previous Period Year'
                variant='outlined'
              />
            )}
            disableClearable={true}
            size='small'
            onChange={(event, newValue) => {
              handlePrevYear(newValue)
            }}
          />
        </Grid> */}
        <Grid item xs={12} pt={3} textAlign='center'>
          <Button_DemDark
            // variant='contained'
            // color='info'
            onClick={ props.updateReport }  // eslint-disable-line
          >
            Update Report
          </Button_DemDark>
        </Grid>
        {/* <Divider /> */}
        {/* <Grid item xs={12} textAlign='center' pt={3} pb={0}> */}
          {/* <Stack direction='column' spacing={0}>
            <MDTypography variant='h6'>Average Check</MDTypography>
            <MDTypography variant='h6'>Price Increase Adjustment</MDTypography>
          
        
            <Stack 
              direction='row'
              spacing={0}
              alignItems='center'
              justifyContent='center'
            >
              <MDTypography
                variant='button'
                sx={{
                  fontWeight: '400'
                }}
              >
                Off
              </MDTypography>
              <Switch_GrmDark
                checked={ priceIncreaseSwitch }
                onChange={ togglePISwitch }
              />
              <MDTypography
                variant='button'
                sx={{
                  fontWeight: '400'
                }}
              >
                On
              </MDTypography>
              
            </Stack>
            {
              priceIncreaseSwitch &&
              <MDTypography variant='caption'>
                Adjustment:  $ {Number(priceIncrease).toFixed(2)}
              </MDTypography>
            }

            { pagePermissions.company && pagePermissions.franchise &&
              <div>
                <Divider />
                <MDTypography variant='h6'>Store Type</MDTypography>
              
            
                <Stack 
                  direction='row'
                  spacing={0}
                  alignItems='center'
                  justifyContent='center'
                >
                  <MDTypography
                    variant='button'
                    sx={{
                      fontWeight: '400'
                    }}
                  >
                    Company
                  </MDTypography>
                  <Switch_GrmDark
                    checked={ storeTypeSwitch }
                    onChange={ toggleSTSwitch }
                  />
                  <MDTypography
                    variant='button'
                    sx={{
                      fontWeight: '400'
                    }}
                  >
                    Franchise
                  </MDTypography>
                  
                </Stack>
                { storeTypeSwitch && 
                  <div>
                    <Divider />
                    <MDTypography variant='h6'>Comparison Type</MDTypography>
                    <Stack 
                      direction='row'
                      spacing={0}
                      alignItems='center'
                      justifyContent='center'
                    >
                      <MDTypography
                        variant='button'
                        sx={{
                          fontWeight: '400'
                        }}
                      >
                        Previous Period
                      </MDTypography>
                      <Switch_GrmDark
                        checked={ compStoreSwitch }
                        onChange={ toggleCSSwitch }
                      />
                      <MDTypography
                        variant='button'
                        sx={{
                          fontWeight: '400'
                        }}
                      >
                        Company Stores
                      </MDTypography>
                      
                    </Stack>
                  </div>
                }
              </div>
            }
          </Stack> */}
        {/* </Grid>         */}
      </MDBox>
      
    )
  }

  function objToString(o){
    console.log('o in', o)
    Object.keys(o).map((k) => {
      console.log('k', k)
      console.log('o[k]', o[k])
      if(typeof o[k] === 'number'){    
        console.log('number')    
        o[k] = '' + o[k]
      }
    })
    console.log('o out', o)
    return o
  }

  async function fetchR0002Data(){
    console.log('fetchR0002Data')
    console.log('user', user)
    // Close the configurator panel
    handleConfiguratorOpen()

    setDataLoading(true)
    setArea('All')

    // Format dates to match Query format
    const curSDate = format(curDateRange[0], 'yyyy-MM-dd')
    const curEDate = format(curDateRange[1], 'yyyy-MM-dd')
    const compSDate = format(compDateRange[0], 'yyyy-MM-dd')
    const compEDate = format(compDateRange[1], 'yyyy-MM-dd')

    try {

      const reportVariablesObj = {
        curStartDate: curSDate,
        curEndDate: curEDate,
        compStartDate: compSDate,
        compEndDate: compEDate,
        shifts: ['LSD'],
        storeTypes: storeTypes
      }

      const reportVariables = JSON.stringify(reportVariablesObj)

      console.log('reportVariables', reportVariables)
      
      const getReportResponse = await API.graphql(
        {
          query: getReportv2,
          variables: {
            userName: user.userName,
            clientId: user.client,
            reportId: 'r0002',
            reportVariables: reportVariables
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      )

      console.log('getReportResponse', getReportResponse)

      const reportData = JSON.parse(JSON.parse(getReportResponse.data.getReportv2.statusItem))

      console.log('reportData', reportData)
      // convert numbers in Stores Increase row to string
      const pageIdx = reportData.findIndex(x => x.pageId === 'r0002_LSD_company')
      if(pageIdx !== -1){
        const pageData = reportData[pageIdx].data
        for(let i = 0; i < pageData.length; i++){
          if(pageData[i].rowType === 'summaryRow'){
            const stringRowData = objToString(pageData[i].rowData)
            reportData[pageIdx].data[i].rowData = stringRowData
          }
        }
      }

      setRawData(reportData)

      const firstYear = format(compDateRange[0], 'yyyy')
      const lastYear = format(curDateRange[1], 'yyyy')
      let yearArr = []
      for(let i=lastYear; i > firstYear; i--){
        yearArr.push(i.toString())
      }
      yearArr.sort()
      // try {
      //   console.log('fetching Price Increase')
      //   const priceQueryResponse = await API.graphql(
      //     {
      //       query: getPriceIncrease,
      //       variables: {
      //         client: 'grm',
      //         years: yearArr
      //       }
      //     }
      //   )
      //   console.log('priceQueryResponse', priceQueryResponse)
      //   setPriceIncrease(priceQueryResponse.data.getPriceIncrease/100)
      //   setPriceIncreaseSwitch(true)
      // } catch (err) {
      //   console.log('Error fetching Price Increase', err)
      // }      
    } catch (err) {
      console.log('Error fetching R0002 Data...', err)
      setDataLoading(false)
      setFullData({})
      setData([emptyRow])
      setTitle(`Summary Report: Error Loading Data for ${format(curDateRange[0], 'M/dd/yyyy')} - ${format(curDateRange[1], 'M/dd/yyyy')}`)
      setAlertMessage({
        type: 'error',
        message: 'Error loading data'
      })
    }    
  }

  useEffect(() => {
    console.log('rawData changed:', rawData)
    if(rawData.length > 0){
      console.log('rawData.length > 0', rawData.length)
      console.log('pageType', pageType)


      console.log('pages', pages)
      let tempPages = {...pages}
      console.log('tempPages', tempPages)
      for(let i=0; i < rawData.length; i++){
        console.log('for loop index', i)
        
        switch(rawData[i].pageId){
          case 'r0002_LSD_company':
            console.log('rawData[i].pageId = r0002_LSD_b&m', rawData[i].pageId)
            const page_company = buildPage2(rawData[i].data, emptyRow)
            console.log('page_company', page_company)
            tempPages.company = page_company
            break;
          case 'r0002_LSD_franchise':
            console.log('rawData[i].pageId = r0002_LSD_franchise', rawData[i].pageId)
            const page_franchise = buildPage2(rawData[i].data, emptyRow)
            page_franchise[0].store = 'Franchise 01'
            console.log('page_franchise', page_franchise)
            tempPages.franchise = page_franchise
            break;
          default:
            console.log('rawData[i].pageId default', rawData[i].pageId)
            // Do nothing
        }
      }
      console.log('tempPages', tempPages)
      setPages({...tempPages})
      
      // const page_LSD = buildPage(rawData[0].data, emptyRow)
      // console.log('page_LSD', page_LSD)
      // setData(page_LSD)
      setTitle(`Summary Report: ${format(curDateRange[0], 'M/dd/yyyy')} - ${format(curDateRange[1], 'M/dd/yyyy')}`)
    }
  }, [rawData])

  useEffect(() => {
    console.log('pages changed', pages)
    if(pageType === 'Company' && pages.company.length > 0){
      setData(pages.company)
    }
    if(pageType === 'Franchise' && pages.franchise.length > 0){
      setData(pages.franchise)
    }
  }, [pages])

  useEffect(() => {
    console.log('data changed', data)
    setDataLoading(false)
    if(data.length > 1){
      setDataReady(true)
    } else {
      setDataReady(false)
    }
    setFilteredStoreList(data)
  }, [data])

  useEffect(() => {
    console.log('filteredStoreList', filteredStoreList)
  }, [filteredStoreList])

  const handleArea = (selectedArea, filterList) => {
    setArea(selectedArea)
    filterStoreList(selectedArea, filterList)
  }

  function filterStoreList(selectedArea, includeRows){
    if(selectedArea === 'All'){
      // include all the rows
      setFilteredStoreList(data)
    } else {
      // include only the rows that match 'includeRows' array
      const filteredData = data.filter(x => includeRows.includes(x.store))
      setFilteredStoreList(filteredData) 
    }
  }

  function exportPDF() {
    console.log('exportPDF')
    R0002ExportPdf( filteredStoreList, curDateRange[0], curDateRange[1], compDateRange[0], compDateRange[1], priceIncrease, priceIncreaseSwitch)
  }

  /****************************
   * ALERT Functions
   ****************************/
  // Determine which alert to trigger
  useEffect(() => {
    console.log('alertMessage type:', alertMessage.type)
    switch(alertMessage.type){
      case 'error':
        errorAlert()
        break;
      case 'success':
        successAlert()
        break;
      default:
        // No alert triggered
    }
  }, [alertMessage.type])

  // Close the alert
  const hideAlert = () => {
    
    setAlert(null)
    setAlertMessage('')
  }

  const successAlert = () => {
    console.log('successAlert...')
    setAlert(
      <MDAlert color='success' dismissible>
        { alertMessage.message }
      </MDAlert>
    )
    setTimeout(function() {hideAlert()}, 3000)
  }

  const errorAlert = () => {
    console.log('errorAlert()')
    setAlert(
      <MDAlert color='error' dismissible>
        { alertMessage.message }
      </MDAlert>
    )
    setTimeout(function() {hideAlert()}, 3000)
  }

  const csvReport = {
    filename: title,
    headers: csvHeaders,
    data: filteredStoreList
  }

  console.log('csvReport', csvReport)


  return (
    <DashboardLayout>
      <DashboardNavbar /> 
      {
        dataLoading ?
        <Progress /> :
        
        <MDBox 
          mb={3} 
          sx={{ 
            width: 'calc(1290px + 1rem)',
            mx: 'auto',
          }}
        >
          {alert}
          <Card >
            <MDBox pt={3} pl={3} pb={2} sx={{backgroundColor: '#1c4e80'}} >
              <Grid container justify-content='space-between'  direction='row' >
                <Grid item xs={7}>
                  <MDTypography variant='h5' fontWeight='medium' style={{color: '#f1f1f1'}}>
                    { title }
                  </MDTypography>      
                </Grid>
                <Grid item xs={5} container justify-content='space-between' spacing={0}>
                  <Grid item xs={6} >
                    { dataReady && 
                      <AreaSelector 
                        area={ area }
                        onChange={ handleArea }
                        pageType={ pageType }
                      />
                    }
                  </Grid>
                  <Grid item xs={2} pl={2} textAlign='center'>
                    { dataReady && 
                      <Tooltip title='Export to CSV' >
                        <Button_DemHead
                          iconOnly
                        >
                          <CSVLink {...csvReport} style={{ color: '#f1f1f1' }}>
                            CSV
                          </CSVLink>
                        </Button_DemHead>
                      </Tooltip>
                    }
                  </Grid>
                  <Grid item xs={2}  textAlign='center'>
                    { dataReady && 
                      <Tooltip title='Export to PDF' >
                        <Button_DemHead
                          onClick={ exportPDF }
                          iconOnly
                        >
                          PDF
                          {/* <Icon fontSize='large' color='inherit'>
                            picture_as_pdf
                            download 
                          </Icon> */}
                        </Button_DemHead>
                      </Tooltip>
                    }
                  </Grid>
                  <Grid item pl={2} pr={2} >                
                    <Tooltip title='Report Settings'>
                      <Button_DemHead 
                        onClick={handleConfiguratorOpen} 
                        iconOnly
                      >
                        <Icon fontSize="large" color="inherit">
                          settings
                        </Icon>
                      </Button_DemHead>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>          
            </MDBox>
            <MDBox position='relative' sx={{backgroundColor: '#e0e0e0'}}>
              <R0002Table 
                data={ filteredStoreList } 
                priceIncrease={ priceIncrease }
                priceIncreaseSwitch={ priceIncreaseSwitch }
                storeTypeSwitch={ storeTypeSwitch }
                compStoreSwitch={ compStoreSwitch }
              />
            </MDBox>
          </Card>
          <RDConfigurator
            report='R0002'
            component={ 
              <Config 
                setCur={ handleCurRange } 
                setComp={ handleCompRange } 
                updateReport={ fetchR0002Data }
              /> 
            }
            onClick={() => fetchR0002Data()}
          />
        </MDBox>
        
      }
      <RDFooter />
    </DashboardLayout>
  )
}

R0002Main.propTypes = {
  setComp: PropTypes.func, 
  setCur: PropTypes.func,
  updateReport: PropTypes.func
}