import jsPDF from 'jspdf'
import GPCellStyle from './GPCellStyle'
import { format } from 'date-fns'

function goalPlannerExportPdf( data, title ){
  console.log('data', data)

  let doc = new jsPDF({
    orientation: 'landscape',
    unit: 'pt',
    format: 'letter'
  })

  let exportDataArr = []

  data.forEach( x => {
    
    // Blank out percent values for non-percent KPIs
    let actualPercent
    let percentRank
    let percentGoal
    let percentOpp
    
    switch(x.kpi){
      case 'Net Sales Food':
      case 'Net Sales Starters':
      case 'Net Sales Pizza':
      case 'Net Sales Desserts':
      case 'Net Sales Beverage':
      case 'Net Sales Beer':
      case 'Net Sales Liquor':
      case 'Net Sales Wine':
      case 'Comp Dollars':
      case 'Void Dollars':
      case 'Void Wrong Made':
      case 'Promo Dollars':
        actualPercent = x.actualPercent
        percentRank = x.percentRank
        percentGoal = x.percentGoal
        percentOpp = x.percentOpp
        break; 
      default:
        actualPercent = null
        percentRank = null
        percentGoal = null
        percentOpp = null
    }

    // Prepare the row array
    let exportData = [
      x.kpi,
      x.actualValue,
      x.valueRank,
      x.dollarGoal,
      x.dollarOpp,
      actualPercent,
      percentRank,
      percentGoal,
      percentOpp,
      x.tactics
    ]

    // Add the row array to the full array
    exportDataArr.push(exportData)
  })

  const headerStyle = {
    valign: 'bottom',
    halign: 'center',
    fillColor: [224, 224, 224],
    textColor: [0, 0, 0],
    cellPadding: { top: 2, right: 2, bottom: 2, left: 4 },
    fontStyle: 'bold',
  }

  doc.autoTable({
    head: [
      [
        {
          content: title,
          colSpan: 10,
          styles: {
            halign: 'left',
            fillColor: [41, 38, 31],
            textColor: [207, 158, 11],
            cellPadding:  { top: 4, right: 0, bottom: 4, left: 10 },
            fontSize: 10
          }
        }
      ],
      [
        {
          content: 'KPI',
          rowSpan: 2,
          styles: headerStyle
        },
        {
          content: 'Dollar Goal',
          colSpan: 4,
          styles: headerStyle,
        },
        {
          content: 'Percentage Goal',
          colSpan: 4,
          styles: headerStyle,
        },
        {
          content: 'Tactics',
          rowSpan: 2,
          styles: headerStyle,
        }
      ],
      [
        {
          content: 'Current',
          styles: headerStyle,
        },
        {
          content: 'Rank',
          styles: headerStyle,
        },
        {
          content: 'Goal',
          styles: headerStyle,
        },
        {
          content: 'Annual Opportunity',
          styles: headerStyle,
        },
        {
          content: 'Current',
          styles: headerStyle,
        },
        {
          content: 'Rank',
          styles: headerStyle,
        },
        {
          content: 'Goal',
          styles: headerStyle,
        },
        {
          content: 'Annual Opportunity',
          styles: headerStyle,
        },
      ]
    ],
    body: exportDataArr,
    headStyles: {
      fontSize: 5,
    },
    bodyStyles: {
      fontSize: 6,
    },
    columnStyles: {
      0: {
        cellWidth: 100
      },
      1: {
        halign: 'center',
        cellWidth: 40
      },
      2: {
        halign: 'center',
        cellWidth: 40
      },
      3: {
        halign: 'center',
        cellWidth: 40,
        fontStyle: 'bold',
        textColor: 'red'
      },
      4: {
        halign: 'center',
        cellWidth: 40
      },
      5: {
        halign: 'center',
        cellWidth: 40
      },
      6: {
        halign: 'center',
        cellWidth: 40
      },
      7: {
        halign: 'center',
        cellWidth: 40,
        fontStyle: 'bold',
        textColor: 'red'
      },
      8: {
        halign: 'center',
        cellWidth: 40
      },
      9: {
        halign: 'left',
        cellWidth: 'wrap'
      }
    }
  })

  doc.save('output.pdf')
}

export default goalPlannerExportPdf