import React, { useState } from 'react'
import PropTypes from 'prop-types'

// @mui components
import { Dialog, DialogTitle, DialogContent, FormControl, FormControlLabel, Grid, IconButton, Stack, Switch, Tooltip } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

// formik components
import { FieldArray, Formik, Form } from 'formik'
import Select_Rd from 'components/FormsUI/Select/Select_Rd'
import Textfield from 'components/FormsUI/Textfield'
import * as Yup from 'yup'

// Material Dashboard Pro 2 components
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography';

// RadixDash components
import Progress from 'components/Progress/Progress'


export default function AddStoreDialog(props){
  // const managerList = props.areaMgrs

  // const existingAreas = props.existingAreas
  console.log('props', props)
  const areas = props.areas.reduce((obj, item) => Object.assign(obj, { [item.areaName]: item.areaName}), {})
  console.log('areas', areas)
  const [openProgress, setProgress] = useState(props.openProgress)

  const [dailyTheo, setDailyTheo] = useState(false)

  let typeObj = {}
  typeObj['B&M'] = "B&M"
  typeObj['Ghost Kitchen'] = 'Ghost Kitchen'
  const statusObj = { New: 'New', Established: 'Established', Closed: 'Closed'}
  
  function handleSubmit(values){
    props.submit(values)
  }

  const INITIAL_FORM_STATE = {
    areaName: '',
    storeName: '',
    managers: [''],
    status: '',
    type: '',
    dailyTheoSwitch: false,
    defaultFOH: 28,
    defaultBOH: 30,
    monFOH: 28,
    tueFOH: 28,
    wedFOH: 28,
    thuFOH: 28,
    friFOH: 28,
    satFOH: 28,
    sunFOH: 28,
    monBOH: 30,
    tueBOH: 30,
    wedBOH: 30,
    thuBOH: 30,
    friBOH: 30,
    satBOH: 30,
    sunBOH: 30
  }

  const FORM_VALIDATION = Yup.object().shape({
    areaName: Yup.string()
      .required('Required'),
    storeName: Yup.string()
      .required('Required'),
    managers: Yup.array()
      .required('Required'),
    status: Yup.string()
      .required('Required'),
    type: Yup.string()
      .required('Required'),
    dailyTheoSwitch: Yup.boolean()
      .default(false)
      .required('Required'),
    defaultFOH: Yup.number()
      .when('setDailyTheo', {
        is: false,
        then: Yup.number()
          .integer('Integer values only')
          .positive('Positive values only')
          .required('Required'),
        otherwise: Yup.number()
          .notRequired()
      }),
    defaultBOH: Yup.number()
      .when('setDailyTheo', {
        is: false,
        then: Yup.number()
          .integer('Integer values only')
          .positive('Positive values only')
          .required('Required'),
        otherwise: Yup.number()
          .notRequired()
      }),
    monFOH: Yup.number()
      .when('setDailyTheo', {
        is: true,
        then: Yup.number()
          .integer('Integer values only')
          .positive('Positive values only')
          .required('Required'),
        otherwise: Yup.number()
          .notRequired()
      }),
    tueFOH: Yup.number()
      .when('setDailyTheo', {
        is: true,
        then: Yup.number()
          .integer('Integer values only')
          .positive('Positive values only')
          .required('Required'),
        otherwise: Yup.number()
          .notRequired()
      }),
    wedFOH: Yup.number()
      .when('setDailyTheo', {
        is: true,
        then: Yup.number()
          .integer('Integer values only')
          .positive('Positive values only')
          .required('Required'),
        otherwise: Yup.number()
          .notRequired()
      }),
    thuFOH: Yup.number()
      .when('setDailyTheo', {
        is: true,
        then: Yup.number()
          .integer('Integer values only')
          .positive('Positive values only')
          .required('Required'),
        otherwise: Yup.number()
          .notRequired()
      }),
    friFOH: Yup.number()
      .when('setDailyTheo', {
        is: true,
        then: Yup.number()
          .integer('Integer values only')
          .positive('Positive values only')
          .required('Required'),
        otherwise: Yup.number()
          .notRequired()
      }),
    satFOH: Yup.number()
      .when('setDailyTheo', {
        is: true,
        then: Yup.number()
          .integer('Integer values only')
          .positive('Positive values only')
          .required('Required'),
        otherwise: Yup.number()
          .notRequired()
      }),
    sunFOH: Yup.number()
      .when('setDailyTheo', {
        is: true,
        then: Yup.number()
          .integer('Integer values only')
          .positive('Positive values only')
          .required('Required'),
        otherwise: Yup.number()
          .notRequired()
      }),
    monBOH: Yup.number()
      .when('setDailyTheo', {
        is: true,
        then: Yup.number()
          .integer('Integer values only')
          .positive('Positive values only')
          .required('Required'),
        otherwise: Yup.number()
          .notRequired()
      }),
    tueBOH: Yup.number()
      .when('setDailyTheo', {
        is: true,
        then: Yup.number()
          .integer('Integer values only')
          .positive('Positive values only')
          .required('Required'),
        otherwise: Yup.number()
          .notRequired()
      }),
    wedBOH: Yup.number()
      .when('setDailyTheo', {
        is: true,
        then: Yup.number()
          .integer('Integer values only')
          .positive('Positive values only')
          .required('Required'),
        otherwise: Yup.number()
          .notRequired()
      }),
    thuBOH: Yup.number()
      .when('setDailyTheo', {
        is: true,
        then: Yup.number()
          .integer('Integer values only')
          .positive('Positive values only')
          .required('Required'),
        otherwise: Yup.number()
          .notRequired()
      }),
    friBOH: Yup.number()
      .when('setDailyTheo', {
        is: true,
        then: Yup.number()
          .integer('Integer values only')
          .positive('Positive values only')
          .required('Required'),
        otherwise: Yup.number()
          .notRequired()
      }),
    satBOH: Yup.number()
      .when('setDailyTheo', {
        is: true,
        then: Yup.number()
          .integer('Integer values only')
          .positive('Positive values only')
          .required('Required'),
        otherwise: Yup.number()
          .notRequired()
      }),
    sunBOH: Yup.number()
      .when('setDailyTheo', {
        is: true,
        then: Yup.number()
          .integer('Integer values only')
          .positive('Positive values only')
          .required('Required'),
        otherwise: Yup.number()
          .notRequired()
      }),
  })

  function toggleDailyTheo(){
    setDailyTheo(!dailyTheo)
  }

  return (
    <Dialog
      open={ props.open }
    >
      <DialogTitle>
        Add New Store
      </DialogTitle>
      <DialogContent>
        { openProgress ?
          <Progress /> :
        
        <Formik
          initialValues={INITIAL_FORM_STATE}
          validationSchema={FORM_VALIDATION}
          onSubmit={ (values) => { handleSubmit(values) }}
        >
          {({ values, isValid, dirty }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} 
                  // Included to give spacing to 'role' Texfield
                />

                <Grid item xs={6}>
                  <Select_Rd 
                    name='areaName'
                    options={ areas }
                    label='Area'
                  />
                </Grid>
                <Grid item xs={6}>
                  <Textfield 
                    name='storeName'
                    label='Store'
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select_Rd 
                    name='type'
                    options={ typeObj }
                    label='Store Type'
                  />
                </Grid><Grid item xs={6}>
                  <Select_Rd 
                    name='status'
                    options={ statusObj }
                    label='Store Status'
                  />
                </Grid>
                <Grid item xs={12}>
                  <MDTypography variant='h6' style={{ textAlign: 'center' }}>Managers</MDTypography>
                </Grid>
                <Grid item xs={12}>
                  <FieldArray name='managers'>
                    {({ push, remove }) => (
                      <div>
                        {
                          values.managers.length > 0 &&
                          values.managers.map((manager, index, {length}) => (
                            <div>
                              <Stack direction='row' sx={{ mb: 2 }}>
                                <Select_Rd
                                  name={`managers.${index}`}
                                  options={ props.managers }
                                  label={`Manager-${index + 1}`}
                                />
                                {
                                  index > 0 ?
                                  (
                                    <Tooltip title='Delete Manager'>
                                      <IconButton onClick={() => remove(index)}><RemoveIcon /></IconButton>
                                    </Tooltip>
                                  ) :
                                  <IconButton disabled><RemoveIcon/></IconButton>
                                }
                                {
                                  ((index + 1) === length) &&
                                  <Tooltip title='Add Manager'>
                                    <IconButton onClick={() => push('')}><AddIcon/></IconButton>
                                  </Tooltip>
                                }
                              </Stack>
                            </div>
                          ))
                        }
                      </div>
                    )}
                  </FieldArray>
                </Grid>
                <Grid item xs={6}>
                  <h4>Theoretical Hours</h4>
                </Grid>
                <Grid item xs={6}>
                  <FormControl>
                    <FormControlLabel 
                      control={
                        <Switch 
                          checked={dailyTheo}
                          onChange={(event) => toggleDailyTheo(event.target.checked)}
                          name='dailyTheoSwitch'
                          defaultValue={dailyTheo}
                          
                        />
                      }
                      label='Set Daily Values'
                    />
                  </FormControl>
                </Grid>
                { dailyTheo ?
                  (
                    <>
                      <Grid item xs={2}>
                        Mon
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='monFOH'
                          label='Mon FOH'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='monBOH'
                          label='Mon BOH'
                        />
                      </Grid>
                      <Grid item xs={2}>                  
                      </Grid>
                      <Grid item xs={2}>
                        Tue
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='tueFOH'
                          label='Tue FOH'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='tueBOH'
                          label='Tue BOH'
                        />
                      </Grid>
                      <Grid item xs={2}>                  
                      </Grid>
                      <Grid item xs={2}>
                        Wed
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='wedFOH'
                          label='Wed FOH'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='wedBOH'
                          label='Wed BOH'
                        />
                      </Grid>
                      <Grid item xs={2}>                  
                      </Grid>
                      <Grid item xs={2}>
                        Thu
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='thuFOH'
                          label='Thu FOH'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='thuBOH'
                          label='Thu BOH'
                        />
                      </Grid>
                      <Grid item xs={2}>                  
                      </Grid>
                      <Grid item xs={2}>
                        Fri
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='friFOH'
                          label='Fri FOH'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='friBOH'
                          label='Fri BOH'
                        />
                      </Grid>
                      <Grid item xs={2}>                  
                      </Grid>
                      <Grid item xs={2}>
                        Sat
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='satFOH'
                          label='Sat FOH'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='satBOH'
                          label='Sat BOH'
                        />
                      </Grid>
                      <Grid item xs={2}>                  
                      </Grid>
                      <Grid item xs={2}>
                        Sat
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='satFOH'
                          label='Sat FOH'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='satBOH'
                          label='Sat BOH'
                        />
                      </Grid>
                      <Grid item xs={2}>                  
                      </Grid>
                      <Grid item xs={2}>
                        Sun
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='sunFOH'
                          label='Sun FOH'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='sunBOH'
                          label='Sun BOH'
                        />
                      </Grid>
                      <Grid item xs={2}>                  
                      </Grid>
                    </>
                  ) :
                  (
                    <>
                      <Grid item xs={2}>
                        Default
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield 
                          name='defaultFOH'
                          label='FOH'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield 
                          name='defaultBOH'
                          label='BOH'
                        />
                      </Grid>
                      <Grid item xs={2}>   
                      </Grid>
                    </>
                  )

                }

                <Grid item xs={6} />
                <Grid item xs={3}>
                  <MDButton variant='contained' color='info' onClick={ props.close }>
                    Cancel
                  </MDButton>
                </Grid>
                <Grid item xs={3}>
                  <MDButton variant='contained' color='info' type='submit' disabled={!(dirty && isValid)}>
                    Submit
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        }
      </DialogContent>
    </Dialog>
  )
}

AddStoreDialog.propTypes = {
  areas: PropTypes.object,
  close: PropTypes.func,
  managers: PropTypes.object,
  open: PropTypes.bool,
  openProgress: PropTypes.bool,
  submit: PropTypes.func,
}