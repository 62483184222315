import { AGGREGATION_TYPES, METRIC_TYPES } from '../../../../constants';
import { formatMoney } from '../../../../utils/formatters';

export const splhMetrics = {
  cFOHHours: { 
    type: AGGREGATION_TYPES.SUM,
    metricType: METRIC_TYPES.HOURS
  },
  cBOHHours: {
    type: AGGREGATION_TYPES.SUM,
    metricType: METRIC_TYPES.HOURS
  },
  cSPLHTotal: {
    type: AGGREGATION_TYPES.CALCULATED,
    metricType: METRIC_TYPES.SPLH,
    inputs: ['cNSTotal', 'cFOHHours', 'cBOHHours'],
    calculate: (ns, foh, boh) => {
      const totalHours = (foh + boh) || 0
      return totalHours === 0 ? '$0' : formatMoney((ns / totalHours), 0)
    }
  },
  cFOH: {
    type: AGGREGATION_TYPES.CALCULATED,
    metricType: METRIC_TYPES.SPLH,
    inputs: ['cNSTotal', 'cFOHHours'],
    calculate: (ns, foh) => {      
      return foh === 0 
        ? '$0' 
        : formatMoney((ns / foh), 0)
    },
  },
  cBOH: {
    type: AGGREGATION_TYPES.CALCULATED,
    metricType: METRIC_TYPES.SPLH,
    inputs: ['cNSTotal', 'cBOHHours'],
    calculate: (ns, boh) => {      
      return boh === 0 
        ? '$0' 
        : formatMoney((ns / boh), 0)
    },
  },
}