import PropTypes from 'prop-types'

// @mui components
import { Card, Grid } from '@mui/material'

// Material Dashboard 2 Pro React components
import MDAvatar from 'components/MDAvatar'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// RadixDash components
import { useUser } from 'components/RDAuthContext'

function RDHeader(props, { children }) {
  
  const { user } = useUser()
  const firstName = user.firstName
  const lastName = user.lastName
  const userName = user.userName

  return (
    <MDBox
      position='relative'
      mb={3}
    >
      <MDBox
        display='flex'
        alignItems='center'
        position='relative'
        minHeight='18.75rem'
        borderRadius='xl'
        sx={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, .9) , rgba(255, 255, 255, 0.0) 30%), url(${props.bgImage})`,
          // backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, .7) 20%, rgba(55, 55, 55, 0.1) 30%, rgba(255, 255, 255, 0.0)), url(${props.bgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: '50%',
          overflow: 'hidden'
        }}
      />
      <Card
        sx={{
          position: 'relative',
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems='center'>
          <Grid item>
            <MDAvatar 
              src={props.logo}
              alt='profile-image'
              size='xl'
              shadow='sm'
            />
          </Grid>
          <Grid item>
            <MDBox
              height='100%'
              mt={0.5}
              lineHeight={1}
            >
              <MDTypography
                variant='h5'
                fontWeight='medium'
              >
                {firstName} {lastName}
              </MDTypography>
              <MDTypography
                variant='button'
                color='text'
                fontWeight='regular'
              >
                Username: {userName}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </Card>
      

    </MDBox>
  )
}

RDHeader.defaultProps = {
  children: '',
}

RDHeader.propTypes = {
  children: PropTypes.node,
  bgImage: PropTypes.string,
  logo: PropTypes.string,
}

export default RDHeader