// MUI components
import { Stack } from '@mui/material'

// Material Dashboard 2 components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

export default function ThresholdScale(){

  const redString = 'Cur <= Comp + LV'
  const greenString = 'Cur >= Comp + HV'

  return (
    <MDBox
      borderBottom={1}
      borderColor='grey.500'
      pb={3}
    >
      <Stack direction='column' >
        <Stack 
          direction='row'        
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <MDBox
            width='10%'
          />
          <MDBox
            width='20%'
          >
            <MDTypography 
              variant='body2'
              textAlign='center'
            >
              Comp + LV
            </MDTypography>
          </MDBox>
          
          <MDBox
            width='20%'
          >
            <MDTypography 
              variant='body2'
              textAlign='center'
            >
              Comp + HV
            </MDTypography>
          </MDBox>
          <MDBox width='10%'/>
        </Stack>
      <Stack 
        direction='row'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >      
        <ColorBox 
          color='red'
          width='20%'
          text={ redString }
        />
        <ColorBox 
          color='yellow'
          width='20%'
          text='.'
        />
        <ColorBox 
          color='green'
          width='20%'
          text={ greenString }
        />
      </Stack>
      </Stack>
    </MDBox>
  )
}

const ColorBox = (props) => {
  return (
    <MDBox
      bgColor={ props.color }
      width={ props.width }
    >
      <MDTypography
        variant='body2'
        textAlign='center'
        color='white'
        fontWeight='bold'
        fontSize='.7rem'
      >
        { props.text }
      </MDTypography>
    </MDBox>
  )
}