import React from 'react';
import { Box } from '@mui/material'

function R0005HeadCell({
  align,
  headerStyle,
  colSpan,
  children,
  ...rest
}){
  return (
    <Box
      component='th'
      style={ headerStyle }
      colSpan={ colSpan }
    >
      <Box
        {...rest}
        position='relative'
        textAlign={ align }
      >
        { children }
      </Box>
    </Box>
  )
}

export default React.memo(R0005HeadCell)
