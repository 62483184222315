/* eslint react/prop-types: 0 */

import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types"; 

import { Icon } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ThemeProvider } from '@mui/material/styles'
import demTheme from '../../demTheme';

import { getToggleAllRowsSelected } from 'react-table'

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import CheckboxDemDark from 'components/Checkbox/CheckboxDemDark'
import Button_GrmDark from 'components/RDButton/Button_GrmDark';
import RDTable from 'components/RDTable'
import R0001CellStyle from './R0001CellStyle';

// const IndeterminateCheckbox = React.forwardRef(
//   ({ indeterminate, ...rest }, ref) => {
//     const defaultRef = React.useRef()
//     const resolvedRef = ref || defaultRef

//     React.useEffect(() => {
//       resolvedRef.current.indeterminate = indeterminate
//     }, [resolvedRef, indeterminate])

//     return (
//       <>
//         <input type="checkbox" ref={resolvedRef} {...rest} />
//       </>
//     )
//   }
// )

function R0001Table(props){

  const [data, setData] = useState(props.data)
  useEffect(() => {
    setData(props.data)
  }, [props.data])
  
  const [totalRows, setTotalRows] = useState(0)
  useEffect(() => {
    const numRows = data.reduce((count, current) => count + 1 + current.subRows.length, 0)
    setTotalRows(numRows)
  }, [data])

  // useEffect(() => {
  //   console.log('props.data', props.data)
  //   // Place subRows in the correct place
  //   const nsFoodIndex = props.data.findIndex(x => x.kpi === 'Net Sales Food')
  //   console.log('nsFoodIndex', nsFoodIndex)
  //   if(nsFoodIndex !== -1){
  //     const subRows = [
  //       props.data[nsFoodIndex + 1],
  //       props.data[nsFoodIndex + 2],
  //       props.data[nsFoodIndex + 3]
  //     ]
  //     let newData = [...props.data]
  //     newData[nsFoodIndex].subRows = subRows
  //     newData.splice(nsFoodIndex + 1, 3)
  //     console.log('newData', newData)
  //     setData(newData)
  //   }
    
  // }, [props.data])

  const testData = [
    {
      actualPercent: "60",
      actualValue: "60",
      kpi: "Void Count",
      low: "47",
      mid: "95",
      percentRank: "Low",
      percentToMid: "n/a",
      percentToTop: "n/a",
      subRows: [],
      top: "173",
      valueRank: "Low",
      valueToMid: "n/a",
      valueToTop: "n/a",
    },
    {
      actualPercent: "4.0%",
      actualValue: "$427",
      kpi: "Void Dollars",
      low: "$354",
      mid: "$673",
      percentRank: "Top",
      percentToMid: "n/a",
      percentToTop: "n/a",
      subRows: [
        {
          actualPercent: "0.1%",
          actualValue: "$13",
          kpi: "Void Wrong Made",
          low: "$38",
          mid: "$126",
          percentRank: "Low",
          percentToMid: "n/a",
          percentToTop: "n/a",
          top: "$223",
          valueRank: "Low",
          valueToMid: "n/a",
          valueToTop: "n/a",
        }
      ]
    }
  ]

  const headerBG = '#e0e0e0'
  const headerTextColor = '#181818'
  const headerFontSize = '.7rem'

  const cvpPct = props.cvpSetting
  const netSalesPct = props.netSalesSetting

  // This table has two header rows.
  const columns = [
    {
      Header: 's',
      columnStyle: {
        backgroundColor: headerBG,
        color: headerBG,
        width: '2%',
        fontSize: headerFontSize,
        borderBottom: 'none'
      },
      columns: [
        {
          // Header: 'ss',
          accessor: 'selection',
          show: props.goalSetting,
          align: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerBG,
            fontSize: headerFontSize,
            borderBottom: '1px solid #000'
          },
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <ThemeProvider theme={demTheme}>
              <MDBox >
                <CheckboxDemDark sx={{ padding: 0, '& .MuiSvgIcon-root': { fontSize: '.9rem' } }} {...getToggleAllRowsSelectedProps()} />
              </MDBox>
            </ThemeProvider>
          ),
          Cell: ({ row }) => (
            <ThemeProvider theme={demTheme}>
              <MDBox >
                <CheckboxDemDark sx={{ padding: 0, '& .MuiSvgIcon-root': { fontSize: '.9rem' } }} {...row.getToggleRowSelectedProps()} />
              </MDBox>
            </ThemeProvider>
          ),
        },
      ]
    },
    {   
      Header: 'k',
      columnStyle: {
        backgroundColor: headerBG,
        color: headerBG,
        width: 350,
        fontSize: headerFontSize,
        borderBottom: 'none'
      },
      columns: [
        {
          Header: 'KPI',
          accessor: 'kpi',
          align: 'center',
          alignCell: 'left',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            fontSize: headerFontSize,
            borderBottom: '1px solid #000'
          },
          Cell: ({row}) => {
            if(row.canExpand){
              return (
                <span {...row.getToggleRowExpandedProps({
                  style: {
                    fontWeight: 600,
                  },
                })}>
                  {row.isExpanded ? 
                    <div>
                      {row.original.kpi} <ExpandLessIcon/>
                    </div> : 
                    <div>
                      {row.original.kpi} <ExpandMoreIcon/>
                    </div>
                  }
                </span>
              )              
            } else {
              return <div>{row.original.kpi}</div>
            }            
          },
        },
      ]        
    },    
    {
      Header: 'All Restaurants Average',
      align: 'center',
      columnStyle: {
        backgroundColor: headerBG,
        color: headerTextColor,
        fontSize: headerFontSize,
        borderBottom: 'none',
        borderLeft: '1px solid #000'
      },
      columns: [
        {
          Header: 'Low',
          accessor: 'low',
          align: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            fontSize: headerFontSize,
            borderBottom: '1px solid #000',
            borderLeft: '1px solid #000'
          },
        },
        {
          Header: 'Mid',
          accessor: 'mid',
          align: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            fontSize: headerFontSize,
            borderBottom: '1px solid #000',
          },
        },
        {
          Header: 'Top',
          accessor: 'top',
          align: 'center',
          alignCell: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            fontSize: headerFontSize,
            borderBottom: '1px solid #000',
          },
        },
      ]
    },
    {
      Header: props.store,
      align: 'center',
      columnStyle: {
        backgroundColor: '#1c4e80',
        color: '#f1f1f1',
        fontSize: headerFontSize,
        borderBottom: 'none',
        borderLeft: '7px solid #0091d5'
      },
      columns: [
        {
          Header: 'Actual',
          accessor: 'actualValue',
          align: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: '#1c4e80',
            color: '#f1f1f1',
            fontSize: headerFontSize,
            borderBottom: '1px solid #000',
            borderLeft: '7px solid #0091d5'
          },
          Cell: ({row}) => {
            const data = row.original
            switch(data.kpi){
              case 'Net Sales Food':
              case 'Net Sales Starters':
              case 'Salad Dollars':
              case 'Bruschetta Dollars':
              case 'Net Sales Pizza':
              case 'Net Sales Desserts':
              case 'Cheese Cake Dollars':
              case 'Cannoli Dollars':
              case 'Dessert Trio Dollars':
              case 'Net Sales Beverage':
              case 'Net Sales Beer':
              case 'Net Sales Liquor':
              case 'Net Sales Wine':
                if(netSalesPct){
                  return <div>{data.actualPercent}</div>
                } else {
                  return <div>{data.actualValue}</div>
                }
                break;
              case 'Comp Count':
              case 'Comp Dollars':
              case 'Comp Service Count':
              case 'Comp Service Dollars':
              case 'Comp Quality Count':
              case 'Comp Quality Dollars':
              case 'Void Count':
              case 'Void Dollars':
              case 'Void Wrong Made':
              case 'Void Guest No Show':
              case 'Void 3rd Party No Show':
              case 'Promo Count':
              case 'Promo Dollars':
                if(cvpPct){
                  return <div>{data.actualPercent}</div>
                } else {
                  return <div>{data.actualValue}</div>
                }
                break;
              default:
                return <div>{data.actualValue}</div>
            }
          }
        },
        {
          Header: 'Rank',
          accessor: 'valueRank',
          align: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: '#1c4e80',
            color: '#f1f1f1',
            fontSize: headerFontSize,
            borderBottom: '1px solid #000',
          },
          Cell: ({row}) => {
            const data = row.original
            let styleSetting = {}
            switch(data.kpi){
              case 'Net Sales Food':
              case 'Net Sales Starters':
              case 'Net Sales Pizza':
              case 'Net Sales Desserts':
              case 'Net Sales Beverage':
              case 'Net Sales Beer':
              case 'Net Sales Liquor':
              case 'Net Sales Wine':
                if(netSalesPct){
                  return <div>{data.percentRank}</div>
                } else {
                  return <div>{data.valueRank}</div>
                }
                break;
              case 'Comp Count':
              case 'Comp Dollars':
              case 'Void Count':
              case 'Void Dollars':
              case 'Void Wrong Made':
              case 'Promo Count':
              case 'Promo Dollars':
                if(cvpPct){
                  return <div cvp='true' >{data.percentRank}</div>
                } else {
                  return <div cvp='false'>{data.valueRank}</div>
                }
                break;
              default:
                return <div>{data.valueRank}</div>
            }
          }
        },
      ]
    },
    {
      Header: 'Annual Opportunity',
      align: 'center',
      columnStyle: {
        backgroundColor: headerBG,
        color: headerTextColor,
        fontSize: headerFontSize,
        borderBottom: 'none',
        borderLeft: '7px solid #0091d5'
      },
      columns: [
        {
          Header: 'To Mid',
          accessor: 'valueToMid',
          align: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            fontSize: headerFontSize,
            borderBottom: '1px solid #000',
            borderLeft: '7px solid #0091d5'
          },
          Cell: ({row}) => {
            const data = row.original
            switch(data.kpi){
              case 'Net Sales Food':
              case 'Net Sales Starters':
              case 'Net Sales Pizza':
              case 'Net Sales Desserts':
              case 'Net Sales Beverage':
              case 'Net Sales Beer':
              case 'Net Sales Liquor':
              case 'Net Sales Wine':
                if(netSalesPct){
                  return <div>{data.percentToMid}</div>
                } else {
                  return <div>{data.valueToMid}</div>
                }
                break;
              case 'Comp Count':
              case 'Comp Dollars':
              case 'Void Count':
              case 'Void Dollars':
              case 'Void Wrong Made':
              case 'Promo Count':
              case 'Promo Dollars':
                if(cvpPct){
                  return <div>{data.percentToMid}</div>
                } else {
                  return <div>{data.valueToMid}</div>
                }
                break;
              default:
                return <div>{data.valueToMid}</div>
            }
          }
        },
        {
          Header: 'To Top',
          accessor: 'valueToTop',
          align: 'center',
          disableGroupBy: true,
          disableSortBy: true,
          columnStyle: {
            backgroundColor: headerBG,
            color: headerTextColor,
            fontSize: headerFontSize,
            borderBottom: '1px solid #000',
          },
          Cell: ({row}) => {
            const data = row.original
            switch(data.kpi){
              case 'Net Sales Food':
              case 'Net Sales Starters':
              case 'Net Sales Pizza':
              case 'Net Sales Desserts': 
              case 'Net Sales Beverage':
              case 'Net Sales Beer':
              case 'Net Sales Liquor':
              case 'Net Sales Wine':
                if(netSalesPct){
                  return <div>{data.percentToTop}</div>
                } else {
                  return <div>{data.valueToTop}</div>
                }
                break;
              case 'Comp Count':
              case 'Comp Dollars':
              case 'Void Count':
              case 'Void Dollars':
              case 'Void Wrong Made':
              case 'Promo Count':
              case 'Promo Dollars':
                if(cvpPct){
                  return <div>{data.percentToTop}</div>
                } else {
                  return <div>{data.valueToTop}</div>
                }
                break;
              default:
                return <div>{data.valueToTop}</div>
            }
          }
        },
      ]
    }
  ]

  return (
    <MDBox>
      
      <RDTable
        table={{
          columns: columns,
          rows: data
        }}
        editable={{
          onRowAdd: null,
          onRowEdit: null,
          onRowDelete: null
        }}
        showTotalEntries={false}
        entriesPerPage={{defaultValue: totalRows + 1}}
        cellStyle={ R0001CellStyle }
        tableOptions={{
          style: {
            cvpPct: props.cvpSetting,
            nsPct: props.netSalesSetting
          }
        }}
        selectedRows={ props.selectedRows }
        setSelectedRows={ props.setSelectedRows }
        clearSelectedRows={ props.clearSelectedRows }
      />
    </MDBox>
  )
}

R0001Table.propTypes = {
  clearSelectedRows: PropTypes.bool,
  cvpSetting: PropTypes.bool,
  data: PropTypes.array,
  selectedRows: PropTypes.object,
  setSelectedRows: PropTypes.func,
  goalSetting: PropTypes.bool,
  netSalesSetting: PropTypes.bool,
  store: PropTypes.string
}

export default R0001Table