import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// AWS Amplify components
import { API, Auth } from 'aws-amplify'

// GraphQL
import { createArea, deleteArea, updateArea } from '../../../graphql/mutations'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDAlert from 'components/MDAlert'

// RadixDash components
import RDTable from 'components/RDTable'
import AddAreaDialog from './AddAreaDialog'

// Template components
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem';
import SweetAlert from 'react-bootstrap-sweetalert'

import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle';
import EditAreaDialog from './EditAreaDialog'
import AreaCellStyle from './AreaCellStyle'

const useStyles = makeStyles(styles)

export default function AreaTable(props){
  const classes = useStyles()

  console.log('props', props)
  const [data, setData] = useState(props.areas)
  const [rowToEdit, setRowToEdit] = useState(null)
  const [alert, setAlert] = useState(null)
  const [alertMessage, setAlertMessage] = useState({
    type: 'none',
    message: ''
  }) 
  const [openAdd, setOpenAdd] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)

  const columns = [
    { 
      Header: 'Area', 
      accessor: 'areaName',
      align: 'center',
      alignCell: 'center',
      disableGroupBy: true,
      columnStyle: {
        backgroundColor: '#e0e0e0',
        color: '#181818',
        borderBottom: '1px solid #000',
        fontSize: '.7rem',
        fontWeight: 'bold',
        paddingTop: '5px'
      } 
    },
    { 
      Header: 'Area Manager', 
      accessor: 'amName',
      align: 'center',
      alignCell: 'center',
      disableGroupBy: true,
      columnStyle: {
        backgroundColor: '#e0e0e0',
        color: '#181818',
        borderBottom: '1px solid #000',
        fontSize: '.7rem',
        fontWeight: 'bold',
        paddingTop: '5px'
      } 
    },
    { 
      Header: 'AM Username', 
      accessor: 'areaManager',
      align: 'center',
      alignCell: 'center',
      disableGroupBy: true,
      columnStyle: {
        backgroundColor: '#e0e0e0',
        color: '#181818',
        borderBottom: '1px solid #000',
        fontSize: '.7rem',
        fontWeight: 'bold',
        paddingTop: '5px'
      } 
    },
    {
      Header: 'Area Type',
      accessor: 'areaType',
      align: 'center',
      alignCell: 'center',
      columnStyle: {
        backgroundColor: '#e0e0e0',
        color: '#181818',
        borderBottom: '1px solid #000',
        fontSize: '.7rem',
        fontWeight: 'bold',
        paddingTop: '5px'
      } 
    }    
  ]

  useEffect(() => {
    console.log('props.areas', props.areas)
    let areas = [...props.areas]
    setData(areas)
  }, [props.areas])

  useEffect(() => {
    console.log('data', data)
  }, [data])

  useEffect(() => {
    console.log('rowToEdit changed', rowToEdit)
    if(rowToEdit){
      setOpenEdit(true)
    }
  }, [rowToEdit])

  async function getAMName(username){
    try{
      let firstName = ''
      let lastName = ''
      let fullName = ''
      let index = null
      let attributeArr = []

      // Set GET parameters
      let apiName = 'AdminQueries'
      let path = '/getUser'
      let myInit = {
        queryStringParameters: {
          'username': username
        },
        headers: {
          'Content-Type' : 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
      }
      const queryResponse = await API.get(apiName, path, myInit)
      attributeArr = queryResponse.UserAttributes
      
      // Get firstName
      index = attributeArr.findIndex((a) => a.Name === 'custom:firstName')
      firstName = attributeArr[index].Value
      
      // Get lastName
      index = attributeArr.findIndex((a) => a.Name === 'custom:lastName')
      lastName = attributeArr[index].Value
      
      fullName = firstName + ' ' + lastName
      console.log('fullName', fullName)
      return fullName
    } catch (err) {
      console.log(`Error getting user data for ${username}`, err)
      return 'Set in User Admin'
    }
  }

  async function addArea(newArea) {
    console.log('addArea()...', newArea)
    

    let tempArea = newArea

    try {
      let result = await API.graphql(
        {
          query: createArea,
          variables: {
            areaName: newArea.areaName,
            areaManager: newArea.areaManager,
            areaType: 'Company'
          }
        }
      )
      console.log('Successfully added new Area to DB', result)

      // Get AM full name
      const tempName = props.areaMgrs[newArea.areaManager].split('[')[0]
      tempArea.amName = tempName

      // let fullName = await getAMName(tempArea.areaManager)
      // console.log('fullName', fullName)
      // tempArea.amName = fullName       
      
      
      // Add new Area to existing table data and sort by Area
      let newData = [...data, tempArea ]
      newData.sort((a, b) => (a.areaName > b.areaName) ? 1 : -1)

      // Update the display table with the new Area
      setData([...newData])
      setOpenAdd(false)

      // Notify Admin that the Area creation was successful
      setAlertMessage({
        type: 'success',
        message: `${newArea.area} area has been created`
      })

    } catch (err) {
      console.log('Error adding Area', err)
      setOpenAdd(false)
      // Notify Admin that Area creation failed
      setAlertMessage({
        type: 'error',
        message: err.errors[0].message
      })
    }

  }

  async function modifyArea(values){
    console.log('modifyArea()...', values)

    const index = values.index
    const newData = values.newData
    const oldData = values.oldData
    
    try {
      let result = await API.graphql(
        {
          query: updateArea,
          variables: {
            areaId: oldData.areaId,
            areaName: newData.areaName,
            areaManager: newData.areaManager,
            areaType: newData.areaType
          }
        }
      )
      console.log('Successfully updated Area', result)

      // Set areaId for newData
      newData.areaId = oldData.areaId
      
      // get AM name for new data
      const newAmName = props.areaMgrs[newData.areaManager].split('[')[0]
      newData.amName = newAmName
      
      // Update data
      const dataUpdate = [...data]
      dataUpdate[index] = newData
      setData([...dataUpdate])
      setOpenEdit(false)

      // Notify Admin that Area change was successful
      setAlertMessage({
        type: 'success',
        message: 'Area successfully updated'
      })

    } catch (err) {
      console.log('Error updating Area', err.errors)
      setOpenEdit(false)
      // Notify Admin that role modification failed
      setAlertMessage({
        type: 'error',
        message: 'Error modifying Area'
      })
    }
  }

  async function removeArea(row){
    console.log('removeArea()...', row)
    const index = row.index
    const areaName = row.values.areaName
    console.log('areaName', areaName)
    const areaId = row.original.areaId
    console.log('areaId', areaId)
    try {
      let result = await API.graphql(
        {
          query: deleteArea,
          variables: {
            areaId: areaId
          }
        }
      )
      console.log('Successfully deleted Area from DB', result)

      // Remove Area from table
      const dataDelete = [...data]
      dataDelete.splice(index, 1)
      setData([...dataDelete])

      // Notify Admin that Area deletion was successful
      setAlertMessage({
        type: 'success',
        message: `${areaName} successfuly deleted`
      })

    } catch (err) {
      console.log('Error deleting Area', err)
      // Notify Admin that Area deletion failed
      setAlertMessage({
        type: 'error', 
        message: err.errors[0].message
      })
    }
  }

   /************************************
   * ALERT Functions
   * **********************************/
  // Determine which alert to trigger
  useEffect(() => {
    console.log('alertMessage type:', alertMessage.type)
    switch(alertMessage.type){
      case 'error':
        errorAlert()
        break;
      case 'success':
        successAlert()
        break;
      default:
        // No alert triggered
    }
  }, [alertMessage.type])

  // Close the alert
  const hideAlert = () => {    
    setAlert(null)
    setAlertMessage('')
  }

  const successAlert = () => {
    console.log('successAlert...')
    setAlert(
      <MDAlert color='success' dismissible>
        { alertMessage.message }
      </MDAlert>
    )
    setTimeout(function() {hideAlert()}, 3000)
  }  

  const errorAlert = () => {
    console.log('errorAlert()')
    setAlert(
      <MDAlert color='error' dismissible>
        { alertMessage.message }
      </MDAlert>
    )
    setTimeout(function() {hideAlert()}, 3000)
  }

  return (
    <MDBox>
      { alert }
      { openAdd &&
        <AddAreaDialog
          open={ openAdd }
          close={ () => setOpenAdd(false) }
          submit={ addArea }
          existingAreas={ data.map( a => a.area )}
          areaMgrs={ props.areaMgrs }
        />
      }
      { openEdit &&
        <EditAreaDialog 
          open={ openEdit }
          data={ rowToEdit }
          close={ () => setOpenEdit(false) }
          submit={ modifyArea }
          areaMgrs={ props.areaMgrs }
        />
      }
      <RDTable
        table={{
          columns: columns,
          rows: data
        }}
        editable={{ // Temporarily block ability for viewers to add and edit entries
          onRowAdd: null,
          onRowEdit: null,
          onRowDelete: null
        }}
        // editable={{
        //   onRowAdd: () => setOpenAdd(true),
        //   onRowEdit: row => setRowToEdit(row),
        //   onRowDelete: null
        //   // onRowDelete: row => removeArea(row)
        // }}
        tableType='Area'
        cellStyle={ AreaCellStyle }
      />
    </MDBox>

  )
}

AreaTable.propTypes = {
  areas: PropTypes.array,
  areaMgrs: PropTypes.object
}