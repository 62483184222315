// AWS Amplify components
import { API } from 'aws-amplify'

// GraphQL
import { dbGetItem } from 'graphql/queries'

import { addDays, format } from 'date-fns';

export function getActualSalesQuery(startDate){
  const sDate = new Date(startDate)

  const eDate = addDays(sDate,7)

  const endDate = format(eDate, 'yyyy-MM-dd')

  const dbVar = JSON.stringify({
    report: 'r0005_KPI',
    startDate: startDate,
    endDate: endDate,
    filter: ['netSales'],
    stores: [],
  })

  const query = {
    query: dbGetItem,
    variables: {
      db: 'grm.reports',
      dbVariables: dbVar,
    }
  }

  return query
}

export function getProjectedSalesQuery(startDate){
  
  const dbVar = JSON.stringify({
    report: 'r0005_ProjectedSales',
    startDates: [startDate]
  })

  const query = {
    query: dbGetItem,
    variables: {
      db: 'grm.r0003reports',
      dbVariables: dbVar,
    }
  }

  return query
}

export function testFetchFn(startDate){
  return (
    [
      {
        store: 'Store',
        values: [
          {
            day: '2023-12-11',
            value: 2798.64
          }
        ]
      }
    ]
  )
}

export async function fetchNetSales(
  startDate, 
){

  const sDate = new Date(startDate)

  const eDate = addDays(sDate,7)

  const endDate = format(eDate, 'yyyy-MM-dd')

  const dbVar = JSON.stringify({
    report: 'r0005_KPI',
    startDate: startDate,
    endDate: endDate,
    filter: ['netSales'],
    stores: [],
  })

  try {
    const response = await API.graphql({
      query: dbGetItem,
      variables: {
        db: 'grm.reports',
        dbVariables: dbVar,
      }
    })
    console.log('fetchNetSales response', response)
    const netSalesData = JSON.parse(JSON.parse(response.data.dbGetItem.statusItem))
    return netSalesData
  } catch (err) {
    console.log('Error fetching Actual Net Sales', err)
    throw err
  }
}

export async function fetchProjectedSales(
  startDate, // The first day of the target week
){
    const dbVar = JSON.stringify({
      report: 'r0005_ProjectedSales',
      startDates: [startDate]
    })
  
    try {
      const response = await API.graphql({
        query: dbGetItem,
        variables: {
          db: 'grm.r0003reports',
          dbVariables: dbVar,
        }
      })
      console.log('fetchProjectedSales response', response)
      const projectedSalesData = JSON.parse(JSON.parse(response.data.dbGetItem.statusItem))
      return projectedSalesData
  } catch (err) {
    console.log('Error fetching Projected Sales', err)
    throw err
  }
}

// Query Cache
const queryCache = {
  actualSales: {},
  projectedSales: {}
}

export function getQuery(namespace, key, callback){
  const cache = queryCache[namespace]
  
  if(!cache[key]){
    cache[key] = callback()
  }
  return cache[key]
}