/************************************************
 * Client Specific Route Settings
 ************************************************/
// @mui icons
import Icon from "@mui/material/Icon";

 // Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";
// import Analytics from "layouts/dashboards/analytics";

/************************************************ 
 * NEW CLIENT:  Import client specific routes file
 ************************************************/
// import demRoutes from 'clients/dem/demRoutes'
import afa92Routes from 'clients/afa92/afa92Routes'
import demRoutes from 'clients/dem/demRoutes'
import grmRoutes from 'clients/grm/grmRoutes'
import rdxRoutes from 'clients/rdx/rdxRoutes'

/************************************************ 
 * NEW CLIENT:  Import icon for new client
 ************************************************/
import afa92Icon from 'assets/images/afa92b-icon.png'
import demIcon from 'assets/images/dem/dem-icon.png'
import grmIcon from 'assets/images/grimaldis-icon.png'
import rdxIcon from 'assets/images/rd-logo-white.png'
// import divider from "./assets/theme/components/divider";

import Dashboard from 'clients/rdx/views/Dashboard'

export default function setUserRoutes(user, permissions) {
  console.log('setUserRoutes')
  // let client = user.attributes['custom:clientId']
  // let role = user.attributes['custom:role']
  // let firstName = user.attributes['custom:firstName']
  // let lastName = user.attributes['custom:lastName']
  let client = user.client
  let role = user.role
  let firstName = user.firstName
  let lastName = user.lastName
  let displayName = user.username
  if(firstName){
    displayName = `${firstName} ${lastName}`
  }

  let routes = []
  let icon 

  /**********************************************
   * NEW CLIENT:  Add case for selecting client
   * icon.
   **********************************************/
  switch(client) {
    case 'afa92':
      icon = afa92Icon
      break;
    case 'dem':
      // Demo
      icon = demIcon
      break;
    case 'grm':
      // Grimaldi's
      icon = grmIcon
      break;
    case 'rdx':
      // RadixDash
      icon = rdxIcon
      break;
    default:
      icon = rdxIcon
      // Use empty routes
  }

  let initialRoute = [
    {
      type: 'collapse',
      name: displayName,
      key: displayName,
      icon: <MDAvatar src={icon} alt={displayName} size='sm' />,
      collapse: [
        {
          name: 'Home',
          key: 'home',
          route: '/dashboards/home',
          component: <Dashboard />
        }
      ]
    },
    { type: 'divider', key: 'divider-1' },
    {
      type: 'collapse',
      name: 'Dashboards',
      key: 'dashboards',
      icon: <Icon fontSize='medium'>dashboard</Icon>,
      collapse: [
        {
          name: "Home Base",
          key: 'home-base',
          route: '/dashboards/home',
          component: <Dashboard />
        }
      ]
    }
  ]
  
  let initialRoute2 = [
    {
      type: 'collapse',
      name: displayName,
      key: displayName,
      href: '#',
      icon: <MDAvatar src={icon} alt={displayName} size='sm' />,
      noCollapse: true,
    },
    { type: 'divider', key: 'divider-1' },
    {
      type: 'collapse',
      name: 'Dashboards',
      key: 'dashboards',
      icon: <Icon fontSize='medium'>dashboard</Icon>,
      collapse: [
        {
          name: "Home Base",
          key: 'home-base',
          route: '/dashboards/home',
          component: <Dashboard />
        }
      ]
    }
  ]

  
  routes = [...initialRoute2]
  
  /**********************************************
   * NEW CLIENT:  Add case for selecting client
   * specific routes.
   **********************************************/
  let clientRoutes
  switch(client) {
    case 'afa92':
      if(permissions !== null){
        clientRoutes = afa92Routes(displayName, icon, role, permissions)
        routes = [...clientRoutes]
      }
      break;
    case 'dem':
      if(permissions !== null){
        clientRoutes = demRoutes(displayName, icon, role, permissions)
        routes = [...clientRoutes]
      }
      break;
    case 'grm':
      if(permissions !== null){
        clientRoutes = grmRoutes(displayName, role, permissions)
        routes = [...routes, ...clientRoutes]
      }      
      break;
    case 'rdx':
      if(permissions !== null){
        clientRoutes = rdxRoutes(role, permissions)
        routes = [...routes, ...clientRoutes]
      }
      break;
    default:
      console.log('No client-specific routes')
  }

  return routes
}