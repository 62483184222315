import React, { useEffect, useState } from 'react'

import { Autocomplete, TextField } from '@mui/material'

export default function THAssignmentAreaSelector(props){

  console.log('area selector', props)
  const [areaList, setAreaList] = useState(['All'])

  // Set Area List
  useEffect(() => {
    const uniqueAreas = [...new Set( props.data.map(x => x.area)) ];
    uniqueAreas.sort()
    uniqueAreas.unshift('All')
    setAreaList(uniqueAreas)
  }, [props.data])  

  // Handle Area Selection
  function handleAreaSelection(event, newArea){
    console.log('Area selected is', newArea)
    props.onChange(newArea)
  }

  return (
    <div>
      <Autocomplete
        id='areaSelector'
        options={ areaList }
        value={ props.area }
        onChange={ handleAreaSelection }
        defaultValue='All'
        disableClearable={ true }
        size='small'
        renderInput={(params) => 
          <TextField
            {...params}
            label='Area'
            variant='outlined'
            fullWidth
            sx={{
              '& .MuiFormLabel-root': {               
                color: 'info'
              },
              '& .MuiInputBase-input': {
                color: 'info',
                // '& .MuiAutocomplete-inputFocused': {
                //   bgcolor: 'red'
                // }
              }
            }}
          />
        }
        sx={{
          width: 150,
          '& .MuiAutocomplete-inputRoot': {
            color: 'white',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'info',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'info'
              // borderColor: '#906e07'
            },
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'info'
              // borderColor: '#906e07'
            },
            '& .MuiAutocomplete-inputFocused': {
              bgcolor: '#e0e0e0'
              // bgcolor: '#29261F'
            },
            '& .MuiAutocomplete-endAdornment': {
              '& .MuiIconButton-root': {
                color: 'info'
              }
            },
          }
        }}
      />
    </div>
  )
}