export default function AreaCellStyle(row, cell){
  let styleSetting = {
    fontSize: '.8rem',
    borderTop: '1px solid #e0e0e0',
    borderBottom: '1px solid #e0e0e0',
    paddingTop: 0,
    paddingBottom: 0,
    alignText: 'center'
  }

  return styleSetting
}