import { useEffect, useState } from 'react';
import { flexRender, useReactTable, getCoreRowModel, } from '@tanstack/react-table';

import { Box, CircularProgress, Table, TableContainer, TableHead, TableBody, TableRow, TableCell  } from '@mui/material';

import useAreaThresholds from 'hooks/clients/grm/useAreaThresholds';

import R0005HeadCell from './R0005HeadCell';
import R0005BodyCell from './R0005BodyCell';

import { getColumns } from '../r0005Utils';

function R0005Table({
  data,
  startDate,
  status
}) {
  console.log('**** R0005Table ****')

  const { data: mapData, profiles, status: thStatus } = useAreaThresholds();
  const [styles, setStyles] = useState([])

  useEffect(() => {
    
    if(thStatus === 'success'){
      const enhancedData = mapData.map(item => {
        const profile = profiles.find(p => p.id === item.profileId)

        const simpleBands = profile?.thresholdBands.map(band => {
          return {
            position: band.position,
            operator: band.operator,
            numberValue: band.numberValue,
            backgroundColor: band.backgroundColor,
            color: band.color,
            fontWeight: band.fontWeight
          }
        })

        return {
          ...item,
          thresholdBands: simpleBands ? simpleBands : null
        }
      })
      setStyles(enhancedData)
    }
  }, [mapData, profiles, thStatus])

  const isLoading = !data && status !== 'error'

  const columns = getColumns(startDate)

  const numColumns = columns.reduce((total, col) => {
    if(col.columns) {
      return total + col.columns.length
    }
    return total
  }, 0)

  const headerStyles = {
    backgroundColor: '#bdbdbd'
  }

  const table = useReactTable({  
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),

    rootProps: {
      style: {
        ...headerStyles
      }
    }
  })

  
  return (
    <TableContainer>
      <Table
        enableRowAndCellStyles
        sx={{
          width: '100%',
          overflowX: 'auto'
        }}
      >
        <TableHead
          sx={{
            display: 'table-header-group'
          }}
        >
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id} >
              {headerGroup.headers.map(header => (
                <R0005HeadCell
                  key={header.id}
                  align={header.column.columnDef.align}
                  headerStyle={header.column.columnDef.headerStyle}
                  colSpan={header.column.columnDef.colSpan}
                >
                  {
                    header.isPlaceholder ? null :
                    flexRender( header.column.columnDef.header, header.getContext()
                  )}  
                </R0005HeadCell>
              ))}
            </TableRow>
          ))}
        </TableHead>

        <TableBody>
          {isLoading && 
            <TableRow>
              <TableCell 
                colSpan={numColumns}
                cellProps={{
                  align: 'left'
                }}

              >
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <CircularProgress color='success'/>
                </Box>
              </TableCell>
            </TableRow>
          }
          
          {!isLoading && table.getRowModel().rows.map(row => {
            let isTotalRow = false
            if(row.original.store.includes('Totals')){
              isTotalRow = true
            }
            const area = row.original.area
            const rowStyle = styles.filter(a => a.area === area)
            return (
              <TableRow key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <R0005BodyCell
                    key={cell.id}
                    isTotalRow={isTotalRow}
                    cellProps={cell.column.columnDef.cellProps}
                    cell={cell}
                    style={rowStyle[0]?.thresholdBands}
                  >
                    {cell.renderValue()}
                  </R0005BodyCell>
                ))}
              </TableRow>
          )})}
        </TableBody>
      </Table>
    </TableContainer>
  )

}

export default R0005Table

function useTotalsRow(data, columns) {
  const [totalsRowData, setTotalsRowData] = useState({});

  useEffect(() => {
    const calculatedTotals = {};
    for (const column of columns) {
      const columnId = column.id;
      if (columnId !== 'store') {
        calculatedTotals[columnId] = data.reduce((sum, row) => sum + row[columnId], 0);
      }
    }
    setTotalsRowData({ store: '', ...calculatedTotals });
  }, [data, columns]);

  return totalsRowData;
}


const NestedTableBody = ({ rows, columns, isTotalsRow }) => (
  <table>
    <tbody>
      {rows.map((row) => (
        <TableRow key={row.id}>
          {columns.map((column) => {
            console.log('row', row)
            console.log('column', column)
            console.log('isTotalsRow', isTotalsRow)
            const value = isTotalsRow
              ? row.original[column.accessorKey + 'Total']
              : row.original[column.accessorKey];
              console.log('value', value)
            return (
              <TableCell key={column.id}>
                {isTotalsRow && value !== undefined
                  ? value.toFixed(2)
                  : <span>{value}</span>}
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </tbody>
  </table>
);

