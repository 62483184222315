export default function StoreCellStyle(row, cell){
  let styleSetting = {
    fontSize: '.8rem',
    borderTop: '1px solid #e0e0e0',
    borderBottom: '1px solid #e0e0e0',
    paddingTop: '3px',
    paddingBottom: '3px'
  }

  return styleSetting
}