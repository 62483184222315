import generateTableData from "./generateTableData";

function addProductCategories2(tableData, productMap) {
  const tempData = JSON.parse(JSON.stringify(tableData))
  const outputArray = []
  for(const period of tempData){
    const outputObj = {...period, products: []}
    for(const product of period.products) {
      const productInfo = productMap.find((p) => p.product === product.product)
      const category = productInfo 
      ? productInfo.category.major 
      : 'Undefined'
      product.category = category
      outputObj.products.push(product)
    }
    outputArray.push(outputObj)
  }
  return outputArray
}

export function calculateReportHeight(headerId){
  const viewPortHeight = window.innerHeight
  // console.log('viewport height', viewPortHeight)
  const reportHeaderElement = document.getElementById(headerId)
  if(reportHeaderElement){
    const bottomMargin = 185
    const rect = reportHeaderElement.getBoundingClientRect()
    const positionFromTop = rect.top
    // console.log(`positionFromTop: ${positionFromTop}`)
    const reportHeight = viewPortHeight - positionFromTop - bottomMargin
    // console.log(`reportHeight: ${reportHeight}`)
    return reportHeight
  } else {
    console.log('Report Header Element not found')
    return 0
  }
}

function flattenToCSV(data, periodType, areasActive) {

  const csv = [];

  const quarters = ['q1', 'q2', 'q3', 'q4']
  const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']

  function flatten(obj, parentYear, parentCategory, parentProduct, parentArea) {
    
    let year = ''
    let category = ''
    let product = '' 
    let area = ''
    let store = ''

    switch(obj.rowType) {
      case 'yearRow':
        year = obj.product
        break;
      case 'categoryRow':
        year = parentYear
        category = obj.product
        break;
      case 'productRow':
        year = parentYear
        category = parentCategory
        product = obj.product
        break;
      case 'areaRow':
        year = parentYear
        category = parentCategory
        product = parentProduct
        area = obj.product
        break;
      default:
        year = parentYear
        category = parentCategory
        product = parentProduct
        area = parentArea
        store = obj.product
    }    

    let csvObj = {}
    if(areasActive === 'yesAreas'){
      csvObj = {
        year: year,
        category: category,
        product: product,
        area: area,
        store: store,
      };
    } else {
      csvObj = {
        year: year,
        category: category,
        product: product,
        store: store,
      };
    }

    if(periodType === 'quarter'){
      for (const quarter of quarters){
        csvObj[`${quarter}Count`] = obj[`${quarter}Count`]
        csvObj[`${quarter}Sales`] = obj[`${quarter}Sales`]
      }
    } else if(periodType === 'month'){
      for (const month of months){
        csvObj[`${month}Count`] = obj[`${month}Count`]
        csvObj[`${month}Sales`] = obj[`${month}Sales`]
      }
    }
    
    csv.push(csvObj);
    
    if (obj.subRows) {
      obj.subRows.forEach(subObj => {
        flatten(subObj, year, category, product, area);
      });
    }
  }
  
  data.forEach(item => flatten(item));

  return csv;

}

function getCacheKey(filters, periodType){
  const years = filters.selectedYears.slice().sort()
  const products = filters.selectedProducts.selected.slice().sort()
  const stores = filters.selectedStores.selected.slice().sort()
  const areas = filters.areasActive
  return `${periodType}-${years.join('-')}-${products.join('-')}-${stores.join('-')}-${areas}`
}

function getCSVColumns(areasActive){
  const quarters = ['q1', 'q2', 'q3', 'q4']
  const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
  let commonColumns = []
  if(areasActive === 'yesAreas'){
    commonColumns = [
      {
        key: 'year',
        label: 'Year',
      },
      {
        key: 'category',
        label: 'Category',
      },
      {
        key: 'product',
        label: 'Product',
      },
      {
        key: 'area',
        label: 'Area',
      },
      {
        key: 'store',
        label: 'Store',
      }
    ]
  } else {
    commonColumns = [
      {
        key: 'year',
        label: 'Year',
      },
      {
        key: 'category',
        label: 'Category',
      },
      {
        key: 'product',
        label: 'Product',
      },
      {
        key: 'store',
        label: 'Store',
      }
    ]
  }

  const quartercount = [...commonColumns]
  const quartersales = [...commonColumns]
  const monthcount = [...commonColumns]
  const monthsales = [...commonColumns]

  for (const quarter of quarters){
    const label = toTitleCase(quarter)
    const countObject = {
      key: `${quarter}Count`,
      label: label
    }
    const salesObject = {
      key: `${quarter}Sales`,
      label: label
    }
    quartercount.push(countObject)
    quartersales.push(salesObject)
  }

  for (const month of months){
    const label = toTitleCase(month)
    const countObject = {
      key: `${month}Count`,
      label: label
    }
    const salesObject = {
      key: `${month}Sales`,
      label: label
    }
    monthcount.push(countObject)
    monthsales.push(salesObject)
  }

  const csvColumns = {
    monthcount: monthcount,
    monthsales: monthsales,
    quartercount: quartercount,
    quartersales: quartersales
  }

  return csvColumns
}

function prepareTableData(data, periodType, filters, storeMap, includeAreas){
  if(data != null){
    const filteredData = filterData(data, filters)
    const preparedData = generateTableData(filteredData, periodType, storeMap, filters.areasActive )
    return preparedData
  } else {
    return null
  }
  
}

function getTableData1(inputData, periodType, valueType, years,storeList, productList) {
  
  const output = []

  const periodKeys = getPeriods(periodType, valueType)
  
  inputData.forEach(year => {
    const yearObj = {
      product: year.year
    };

    periodKeys.forEach(key => {
      yearObj[key] = 0
    })
    
    year.products.forEach(product => {
      let productObj = {}
      if(productList.selected.length !== 0 && productList.selected.includes(product.product)){
        productObj = processProduct(product, periodKeys, periodType, valueType, storeList)

        if (!yearObj.subRows) {
          yearObj.subRows = [];
        }
        yearObj.subRows.push(productObj);
  
        periodKeys.forEach(key => {
          yearObj[key] += productObj[key]
        })
      }
      
    });

    output.push(yearObj);
  });

  return output;
}

function getTableData2(rawData){
  console.log('getTableData2')
  const months = [
    'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'
  ]

  const quarters = [
    'q1', 'q2', 'q3', 'q4'
  ]

  const tableData = [];

  rawData.forEach(yearData => {
    yearData.products.forEach(productData => {
      productData.areas.forEach(areaData => {
        areaData.stores.forEach(storeData => {
          const rowData = {
            year: yearData.year,
            product: productData.product,
            area: areaData.area,
            store: storeData.store,
          }

          months.forEach(month => {
            const monthData = storeData.month.find(m => m.period === month)
            rowData[`${month}Count`] = monthData ? monthData.count : 0
            rowData[`${month}Sales`] = monthData ? monthData.sales : 0
          })

          quarters.forEach(quarter => {
            const quarterData = storeData.quarter.find(q => q.period === quarter)
            rowData[`${quarter}Count`] = quarterData ? quarterData.count : 0
            rowData[`${quarter}Sales`] = quarterData ? quarterData.sales : 0
          })

          tableData.push(rowData)
        })
      })
    })
  })
  return tableData
}

function filterData(data, filters){
  const years = filters.selectedYears
  const products = filters.selectedProducts.selected
  const productNames = products.map(p => p.split('::')[1])
  const stores = filters.selectedStores.selected
  const storeNames = stores.map(s => s.split('::')[1])
  
  const yearsArr = data.map(item => item.period.slice(0,4))

  const yearsFiltered = data.filter((item, index) => years.includes(Number(yearsArr[index])))
  
  const productsFiltered = yearsFiltered.map(item => {
    return {
      ...item,
      products: item.products.filter(p => {
        const cleanProduct = trimExtraQuotes(p.product)   
        return productNames.includes(cleanProduct)
      }).map(p => ({
        ...p,
        product: trimExtraQuotes(p.product)
      }))
    }
  })

  const storesFiltered = productsFiltered.map(item => {
    const filteredProducts = item.products.map(p => {
      return {
        ...p,
        stores: p.stores.filter(s => storeNames.includes(s.store))
      }
    })
    return {
      ...item,
      products: filteredProducts
    }
  })
  return storesFiltered
}

function trimExtraQuotes(str){
  return str.replace(/"+$/, '"')
}

function getPeriods(periodType, valueType){
  const quarterlyPeriods = ['q1', 'q2', 'q3', 'q4']
  const monthlyPeriods = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']

  let periods = []

  if(periodType === 'quarter'){
    quarterlyPeriods.forEach(quarter => {
      periods.push(`${quarter}${valueType.charAt(0).toUpperCase() + valueType.slice(1)}`)
    })
  } else {
    monthlyPeriods.forEach(month => {
      periods.push(`${month}${valueType.charAt(0).toUpperCase() + valueType.slice(1)}`)
    })
  }
  return periods
}

function processProduct(product, periodKeys, periodType, valueType, stores){
  const productObj = {
    product: product.product
  }  

  periodKeys.forEach(key => {
    productObj[key] = 0
  })

  const selectedAreas = {}

  product.areas.forEach(area => {
    let areaHasSelectedStore = false

    area.stores.forEach(store => {
      if(stores.selected.some(s => s.stores.includes(store.store))){
        areaHasSelectedStore = true
      }
    })
    if(areaHasSelectedStore){
      selectedAreas[area.area] = area
    }
  })

  productObj.subRows = Object.values(selectedAreas).map(area => {
    const areaObj = processArea(area, periodKeys, periodType, valueType, stores)
    periodKeys.forEach(key => {
      productObj[key] += areaObj[key]
    })
    return areaObj;
  })
  
  return productObj;
}

function processArea(area, periodKeys, periodType, valueType, stores){
  const areaObj = {
    product: area.area
  }
  periodKeys.forEach(key => {
    areaObj[key] = 0
  })

  area.stores.forEach(store => {
    let storeObj = {}
    if(stores.selected.length!== 0) {
      const storesIndex = stores.selected.findIndex(store => store.area === area.area)
      if(storesIndex!== -1) {
        if(stores.selected[storesIndex].stores.includes(store.store)){
          storeObj = processStore(store, periodKeys, periodType, valueType)
          
          if(!areaObj.subRows) {
            areaObj.subRows = []
          }
          
          areaObj.subRows.push(storeObj)
          
          periodKeys.forEach(key => {
            areaObj[key] += storeObj[key]
          })
        }
      }
    }
  })


  const selectedStores = area.stores.filter(store => stores.selected.some(s => s.stores.includes(store.store)))
  areaObj.subRows = selectedStores.map(store => processStore(store, periodKeys, periodType, valueType))
  return areaObj
}

function processStore(store, periodKeys, periodType, valueType){
  const storeObj = {
    product: store.store
  }

  periodKeys.forEach(key => {
    storeObj[key] = 0
  })
  const periodData = store[periodType.toLowerCase()]
  
  periodData.forEach(period => {
    storeObj[`${period.period}${valueType.charAt(0).toUpperCase() + valueType.slice(1)}`] += period[valueType]
  })
  return storeObj
}

/**
 * Generates chart data from input data for a given time period.
 * 
 * @param {Object[]} inputData - Array of data objects 
 * @param {string} timePeriod - Time period for labels - 'quarter' or 'month'
 * 
 * @returns {Object} Chart data object 
 * @returns {string[]} chartData.labels - Labels for x axis
 * @returns {Object[]} chartData.datasets - Chart datasets  
 */
function getChartData(inputData, timePeriod, reportType){

  const labels = getChartLabels(timePeriod)

  // Set datasets
  const datasets = getChartDataset(inputData, reportType)

  return {
    labels: labels,
    datasets: datasets
  }
}

const getChartLabels = (timePeriod) => {
  if(timePeriod === 'quarter'){
    return ['Q1', 'Q2', 'Q3', 'Q4']
  } else {
    return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  }
}

const getChartDataset = (inputData, reportType) => {
  const lineColors = [
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark'
  ]

  if(inputData.length > 0){
    const keys = Object.keys(inputData[0]).filter(k => {
      if(reportType === 'count'){
        return k !== 'product' && k !== 'subRows' && k.includes('Count') 
      } else if (reportType === 'sales') {
        return k !== 'product' && k !== 'subRows' && k.includes('Sales') 
      }
    })
    return inputData.map((year, index) => (
      {
        label: year.product.toString(),
        color: lineColors[index % lineColors.length],
        data: keys.map(k => year[k])
      }
    ))
  } else {
    return null
  }
}

function getProductList(inputData){
  console.log('getProductList', inputData)
  const allProducts = inputData.flatMap(item => {
    return item.products.map(p => p.product)
  })

  return [...new Set(allProducts)]
}

function getStoreList(inputData){
  console.log('getStoreList', inputData)

  const output = [];
  const areas = {}

  for (const store in inputData){
    const areaName = inputData[store].areaName

    if(!areas[areaName]){
      areas[areaName] = []
    }

    areas[areaName].push(store)
  }

  for (const areaName in areas){
    output.push({
      area: areaName,
      stores: areas[areaName]
    })
  }

  // inputData.forEach(year => {
  //   year.products.forEach(product => {
  //     product.areas.forEach(area => {
  //       const areaName = area.area
  //       const existingArea = output.find(o => o.area === areaName)
  //       if(existingArea){
  //         area.stores.forEach(store => {
  //           const storeName = store.store
  //           const existingStore = existingArea.stores.includes(storeName)

  //           if(!existingStore){
  //             console.log(`${store} not found in ${existingArea.area}`)
              
  //           }
  //         })
  //       } else {
  //         output.push({
  //           area: areaName,
  //           stores: area.stores.map(x => x.store)
  //         })
  //       }
  //     })
  //   });
  // });
  return output
}

function sortArray(arr, order){
  let sortedArr = [...arr]
  if(order === 'descending'){
    return sortedArr.sort((a, b) => b - a)
  } else {
    return sortedArr.sort((a, b) => a - b)
  }
}

function tableSort(arr, key, direction){
  if(!arr.length) return []

  arr.sort((a, b) => {
    if(a[key] < b[key]) {
      return direction === 'ascending' ? -1 : 1
    }
    if(a[key] > b[key]) {
      return direction === 'ascending' ? 1 : -1
    } 
    return 0
  })

  arr.forEach(item => {
    if(item.subRows) {
      item.subRows = tableSort(item.subRows, key, direction)
    }
  })

  return arr
}

function toTitleCase(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function prepareProductSelectList(productMap){
  if(Object.keys(productMap).length > 0){
    const productArray = []

    for (const item of productMap){
      const category = item.category.major
      productArray.push(`${category}::${item.product}`)
    }
    const uniqueProducts = [...new Set(productArray)];
    uniqueProducts.sort()
    return uniqueProducts
  } else {
    return null
  }
}

function prepareStoreSelectList(storeMap){
  if(Object.keys(storeMap).length > 0) {
    const finalData = []

    for (const store in storeMap) {
      const areaName = storeMap[store].areaName
      finalData.push(`${areaName}::${store}`)
    }

  // console.log('prepareStoreSelectList', initialData)
  // if(initialData.length !== 0){
  //   const finalData = [];

  //   initialData.forEach(area => {
  //     area.stores.forEach(store => {
  //       const storeItem = area.area + '::' + store;
  //       finalData.push(storeItem);
  //     });
  //   });

    finalData.sort()
    return finalData;
  } else {
    return null
  }
}

function prepareStoreTableData(stores){
  const output = [];

  stores.forEach(store => {
    const [area, name] = store.split('::');
    
    const areaIndex = output.findIndex(o => o.area === area);
    
    if(areaIndex !== -1) {
      output[areaIndex].stores.push(name);
    } else {
      output.push({
        area, 
        stores: [name]  
      });
    }
  });

  return output;
}

function filterNodes(node, filters){
  switch(node.nodeType){
    case 'year':
      if(!filters.years.includes(parseInt(node.name, 10))){
        return null
      }
      break;
    case 'product':
      if(!filters.products.includes(node.name)){
        return null
      }
      break;
    case 'store':
      if(!filters.stores.includes(node.name)){
        return null
      }
      break;
    default:
      // No action
  }

  node.children = node.children.filter(child => {
    const keptChild = filterNodes(child, filters)

    if(keptChild){
      return true
    } else {
      return false
    }
  })

  return node
}

function removeFilterGroup(filter){
  const newArray = []
  for(const string of filter){
    const parts = string.split('::')
    newArray.push(parts[1])
  }
  return newArray
}

function copyTree(node, periodType){
  const copy = node.clone(periodType)
  copy.values = JSON.parse(JSON.stringify(node.values))

  for(let child of node.children){
    copy.children.push(copyTree(child, periodType))
  }
  return copy
}

export { 
  addProductCategories2,
  filterData,
  flattenToCSV,
  getCacheKey,
  getChartData,
  getCSVColumns,
  getProductList,
  getStoreList,
  getTableData2,
  sortArray,
  tableSort,
  toTitleCase,
  prepareProductSelectList,
  prepareStoreSelectList,
  prepareStoreTableData,
  prepareTableData,
}