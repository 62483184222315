import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";

// @mui material components
import { Grid } from '@mui/material'

// AWS Amplify components
import { API } from 'aws-amplify'

// GraphQL
import { listClients, listRoles, listUsers } from '../../../graphql/queries';
import { createUser } from 'graphql/mutations'

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from 'components/MDButton'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'layouts/DashboardLayout';

// RadixDash components
import { useUser } from 'components/RDAuthContext'
import DashboardNavbar from 'components/RDNavbars/DashboardNavbar';
import Progress from 'components/Progress/Progress';
import RDFooter from  'components/RDFooter'
import UserTable from 'clients/admin/UserAdmin/UserTable'
import EditUserModal from './EditUserModal'

const UserList1 = require('../../../userlist_us-east-1_wKBdXUI3G_1.json')
const UserList2 = require('../../../userlist_us-east-1_wKBdXUI3G_2.json')

export default function UserAdmin(){
  const { user } = useUser()
  const clientId = user.client
  const [clients, setClients] = useState([])
  const [selectedUser, setSelectedUser] = useState({})
  const [editUser, setEditUser] = useState(false)
  const [userListLoading, setUserListLoading] = useState(true)
  const [roles, setRoles] = useState([])
  const [userList, setUserList] = useState(null)
  const [tableData, setTableData] = useState([])
  const [userProfile, setUserProfile] = useState({
    userName: '',
    firstName: '',
    lastName: '',
    email: '',
    role: ''
  })

  console.log('clientId', clientId)

  // Set userList when component loads
  useEffect(() => {
    console.log('Setting userList')
    
    async function fetchUserList(){      
      try {
        const users = []

        const filter = {
          client: {
            eq: clientId
          }
        }

        let nextToken

        do {
          const queryResponse = await API.graphql({
            query: listUsers,
            variables: {
              filter: filter,
              nextToken: nextToken
            }
          })
          console.log({queryResponse})
          console.log('listUsers =>', queryResponse.data.listUsers.items)
          const userArr = queryResponse.data.listUsers.items.map(({
            createdAt,
            updatedAt,
            ...keep
          }) => keep)

          users.push(...userArr)
          nextToken = queryResponse.data.listUsers.nextToken
        } while (nextToken)

        users.sort((a, b) => (a.userName > b.userName) ? 1 : -1)
        setTableData(users)
        
        // const filteredArr = userArr.filter(x => {
        //   if(clientId !== 'rdx'){
        //     return x.client === clientId
        //   } else {
        //     return x
        //   }
        // })
        // filteredArr.sort((a, b) => (a.userName > b.userName) ? 1 : -1)
        // setTableData(filteredArr)
      } catch (err) {
        console.log('Error fetching userList', err)
      }
    }

    fetchUserList()
  }, [])

  // Fetch roles for this client
  useEffect(() => {
    getClients()
    getRoles()
  }, [])

  useEffect(() => {
    console.log('clients changed', clients)
    
  }, [clients])

  async function getClients(){
    console.log('getClients()...')
    try {
      const queryResponse = await API.graphql({
        query: listClients
      })
      console.log('clients', queryResponse.data.listClients)
      const clientArr = queryResponse.data.listClients.items
      setClients(clientArr)
    } catch (err) {
      console.log('Error fetching clients', err)
    }
  }

  async function getRoles(){
    console.log('getRoles()...')
    try {
      const queryResponse = await API.graphql({
        query: listRoles,
        variables: {
          clientId: clientId
        }
      })

      console.log('getRoles queryResponse', queryResponse)
      
      const clientRoles = queryResponse.data.listRoles.map(x => x.role)
      console.log('clientRoles', clientRoles)

      const newRoleObj = queryResponse.data.listRoles.reduce((prev, cur) => {
        
        let prevCopy = {...prev}
        
        if(cur.clientId in prevCopy){
          const roleCopy = { ...prevCopy[cur.clientId], [cur.role]:cur.role }
          prevCopy[cur.clientId] = roleCopy
        } else {
          prevCopy[cur.clientId] = { [cur.role]:cur.role }
        }
        return prevCopy
      }, {})

      console.log('newRoleObj', newRoleObj)
      
      const roleObj = clientRoles.reduce((a, v) => ({...a, [v]:v}),{})
      console.log('roleObj', roleObj)

      setRoles(newRoleObj)
      
    } catch (err) {
      console.log('Error fetching roles', err)
    }
  }

  useEffect( () => {
    console.log('tableData changed', tableData)
    if(tableData.length >= 1){
      setUserListLoading(false)
    }

  }, [tableData])

  useEffect(() => {
    console.log('selectedUser', selectedUser)
    if( selectedUser &&
        Object.keys(selectedUser).length === 0 &&
        Object.getPrototypeOf(selectedUser) === Object.prototype){
      console.log('selectedUser is empty', selectedUser)
    } else {
      console.log('selectedUser', selectedUser)
      
    }
  }, [selectedUser])

  useEffect(() => {
    if(userProfile.userName !== ''){
      setEditUser(true)
    }
  }, [userProfile])

  async function handleJsonButton(){
    await addJson(UserList1.Users)
    await addJson(UserList2.Users)
  }

  async function addJson(userArr){
    console.log('userArr', userArr)

    userArr.forEach(async (element) => {
      // UserDB input object
      let newUser = {
        client: '',
        userName: element.Username,
        firstName: '',
        lastName: '',
        email: '',
        role: ''
      }
      // extract attributes
      for(let i = 0; i < element.Attributes.length; i++){
        const name = element.Attributes[i].Name
        const value = element.Attributes[i].Value
        switch (name) {
          case 'custom:clientId':
            newUser.client = value
            break;
          case 'custom:firstName':
            newUser.firstName = value
            break;
          case 'custom:lastName':
            newUser.lastName = value
            break;
          case 'email':
            newUser.email = value
            break;
          case 'custom:role':
            newUser.role = value
            break;
          default:
            break;
        }
      }
      console.log('newUser', newUser)

      try {
        let result = await API.graphql(
          {
            query: createUser,
            variables: {
              input: newUser
            }
          }
        )
        console.log('result', result)
      } catch (err) {
        console.log(`Error loading ${newUser.userName} to DB`, err)
      }
    })
  }

  async function submitEdit(values) {
    console.log('Submit Edit', values)
    setEditUser(false)
  }

  const cancelEdit = () => {
    setEditUser(false)
  }

  const closeEdit = () => {
    setEditUser(false)
  }

  async function deleteUser() {
    console.log('User deleted', selectedUser)
    setEditUser(false)
  }

  const setUser = (user) => {
    setSelectedUser(user)
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {
        userListLoading ? 
        <Progress /> :
        <MDBox>
          {
            clientId === 'rdx' &&
          
            <MDButton 
              variant='contained'
              color='info'
              onClick={ handleJsonButton }
              sx={{
                mb: 2
              }}
            >
              JSON Upload
            </MDButton>
          }
          <UserTable
            userData={ tableData }
            setUser={ setUser }
            roles={ roles }
            clients={ clients }
          />
        </MDBox> 
      }
        
      { editUser && 
        <EditUserModal
          open={ editUser }
          submit={ submitEdit }
          cancel={ cancelEdit }
          delete= { deleteUser }
          close={ closeEdit }
          user={ userProfile }
        />
      }
      <RDFooter />
    </DashboardLayout>
  )
}

UserAdmin.propTypes = {
  submit: PropTypes.func,
  close: PropTypes.func,
  cancel: PropTypes.func,
  user: PropTypes.object
}