import { styled } from '@mui/system'
import { Autocomplete } from '@mui/material'

const Autocomplete_GrmHead = styled(Autocomplete)(({theme}) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#cf9e0b'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#906e07'
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#906e07'
  },
  '& .MuiAutocomplete-inputFocused': {
    bgcolor: '#cf9e0b'
  },
  '& .MuiAutocomplete-endAdornment': {
    '& .MuiIconButton-root': {
      color: '#cf9e0b'
    }
  },
  '& .MuiFormLabel-root': {               
    color: '#CF9E0B'
  },
  '& .MuiInputBase-input': {
    color: '#CF9E0B',
  },
}))

export default Autocomplete_GrmHead