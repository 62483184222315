
import { parse, format, addDays } from 'date-fns'
import { fetchMetricDataByClientDateAndName } from 'services/metricData/fetchMetricDataByClientDateAndName';

export async function fetchToastData(startDate){
  try {
    // This report always fetches 1 week of data beginning on the startDate
    const endDate = getEndDate(startDate)

    const metricNames = {
      CHECK_COUNT: 'checkCount',
      NET_SALES: 'netSales',
      BOH_OVERTIME_HOURS: 'bohOvertimeHours',
      BOH_REGULAR_HOURS: 'bohRegularHours',
      BOH_OVERTIME_DOLLARS: 'bohOvertimeDollars',
      BOH_REGULAR_DOLLARS: 'bohRegularDollars',
      FOH_OVERTIME_HOURS: 'fohOvertimeHours',
      FOH_REGULAR_HOURS: 'fohRegularHours',
      FOH_OVERTIME_DOLLARS: 'fohOvertimeDollars',
      FOH_REGULAR_DOLLARS: 'fohRegularDollars',
    }

    const metricData = await fetchMetricDataByClientDateAndName('grm', startDate, endDate, metricNames)

    console.log(`Fetched ${metricData.length} metric data points`);
    // Process the fetched data as needed
    return metricData;
  } catch (err) {
    console.log('Error fetching Toast data')
    throw err
  }
}

function getEndDate(startDate) {
  // Parse the input string to a Date object
  const parsedDate = parse(startDate, 'yyyy-MM-dd', new Date());
    
  // Add 6 days to the parsed date
  const endDate = addDays(parsedDate, 6);

  // Format the result back to 'yyyy-MM-dd' string
  return format(endDate, 'yyyy-MM-dd');
}

