import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// @mui components
import { Dialog, DialogTitle, DialogContent, Stack, Switch } from '@mui/material'

// MD2 components
import MDButton from 'components/MDButton'
import MDEditor from 'components/MDEditor'
import MDTypography from 'components/MDTypography'

// RadixDash components
import Switch_GrmDark from 'components/RDSwitch/Switch_GrmDark'


export default function EditMessageDialog(props){

  const [content, setContent] = useState(props.data.original.note)
  const [status, setStatus] = useState(
    props.data.original.status === 'active' ? true : false
  )

  const onChange = (content, delta, source, editor) => {
    setContent(editor.getHTML())
  }

  function saveMessage(){
    
    props.submit({
      note: content,
      status: status ? 'active' : 'inactive'
    })
  }

  function toggleSwitch(event) {
    console.log('event', event.target.checked)
    setStatus(event.target.checked)
  }

  return (
    <Dialog
      open={ props.open }
    >
      <DialogTitle>Edit Your Message...</DialogTitle>
      <DialogContent>
        <Stack
          direction='column'
          spacing={2}
        >
          <MDEditor 
            value={content}
            onChange={onChange}
          />
          <Stack
            direction='row'
            spacing={0}
            alignItems='center'
            justifyContent='flex-start'
          >
            <MDTypography
              variant='button'
              sx={{
                fontWeight: 'bold'
              }}
            >
              Inactive
            </MDTypography>
            <Switch 
              checked={status}
              onChange={ toggleSwitch }
              sx={{
                '& .MuiSwitch-thumb': {
                  backgroundColor: '#8643B0'
                },
              }}
            />
            <MDTypography
              variant='button'
              sx={{
                fontWeight: 'bold'
              }}
            >
              Active
            </MDTypography>
            
          </Stack>
          
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='flex-end'
            spacing={2}
          >
            <MDButton
              variant='contained'
              color='info'
              onClick={ props.close }
            >
              Cancel
            </MDButton>
            <MDButton
              variant='contained'
              color='info'
              onClick={ saveMessage }
            >
              Post
            </MDButton>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

EditMessageDialog.propTypes = {
  close: PropTypes.func,
  data: PropTypes.object,
  open: PropTypes.bool,
  submit: PropTypes.func
}