/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// AWS Amplify components
import { API } from 'aws-amplify'

// GraphQL
import { deleteMessage, updateMessage } from 'graphql/mutations'

// MD2 components
import MDBox from "components/MDBox";

// RadixDash components
import { useUser } from 'components/RDAuthContext'
import MessageCellStyle from './MessageCellStyle'
import EditMessageDialog from './EditMessageDialog';
import NewMessageDialog from './NewMessageDialog'
import RDTable from 'components/RDTable'

export default function MessageTable(props){

  const { user } = useUser()
  const clientId = user.client


  const [data, setData] = useState([...props.messages])
  const [rowToEdit, setRowToEdit] = useState(null)
  // const [openAdd, setOpenAdd] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)

  useEffect(() => {
    if(props.messages[0] !== {}){
      setData([...props.messages])
    }
  }, [props.messages])

  const columns = [
    {
      Header: 'Created',
      accessor: 'createdAt',
      align: 'center',
      width: 150,
      disableGroupBy: true,
      columnStyle: {
        backgroundColor: '#e0e0e0',
        color: '#181818',
        borderBottom: '1px solid #000',
        fontSize: '.7rem',
        fontWeight: 'bold',
        paddingTop: '5px'
      },
      Cell: ({row}) => {
        let createDate = ''
        if(Object.keys(row.original).length !== 0 ){
          createDate = row.original.createdAt.slice(0, 10)
        } 
        return(
          <div>
            { createDate }
          </div>
        )
      }
    },
    {
      Header: 'Message',
      accessor: 'note',
      align: 'center',
      minWidth: 200,
      maxWidth: 800,
      width: 500,
      disableGroupBy: true,
      columnStyle: {
        backgroundColor: '#e0e0e0',
        color: '#181818',
        borderBottom: '1px solid #000',
        fontSize: '.7rem',
        fontWeight: 'bold',
        paddingTop: '5px'
      },
      Cell: ({row}) => <span dangerouslySetInnerHTML={{__html: row.original.note}} />
    },
  ]
  
  function openEditDialog(row){
    console.log('Edit row clicked', row)
    setRowToEdit(row)
  }

  useEffect(() => {
    if(rowToEdit){
      setOpenEdit(true)
    }
  }, [rowToEdit])

  async function modifyMessage(values) {
    console.log('modifyMessage', values)

    // Update database
    try {
      let result = await API.graphql(
        {
          query: updateMessage,
          variables: {
            clientId: clientId,
            messageId: rowToEdit.original.messageId,
            note: values.note,
            position: rowToEdit.original.position,
            status: values.status
          }
        }
      )
      // console.log('result', result)
      const updatedMessage = {
        createdAt: rowToEdit.original.createdAt,
        messageId: rowToEdit.original.messageId,
        note: values.note,
        position: rowToEdit.original.position,
        status: values.status
      }

      if(values.status === rowToEdit.original.status){
        console.log('Update message in current table')
        let tempArr = [...data]
        tempArr[rowToEdit.index] = updatedMessage
        setData([...tempArr])
      } else {
        console.log('Move message to the other table')
        const index = rowToEdit.index
        // Remove message from table
        const dataDelete = [...data]
        dataDelete.splice(index, 1)
        setData([...dataDelete])
        props.setExternal(updatedMessage)
      }
    } catch (err) {
      console.log('Error updating message to database', err)
      setOpenEdit(false)
    }
    // Update table
    setOpenEdit(false)
  }

  async function removeMessage(row) {
    console.log('deleteMessage', row)
    const index = row.index
    const messageId = row.original.messageId

    try {
      let result = await API.graphql(
        {
          query: deleteMessage,
          variables: {
            clientId: clientId,
            messageId: messageId
          }
        }
      )

      console.log('Successfully deleted Message from DB', result)

      // Remove message from table
      const dataDelete = [...data]
      dataDelete.splice(index, 1)
      setData([...dataDelete])
    } catch (err) {
      console.log('Error deleting message', err)
    }
  }

  return (
    <MDBox>
      {
        openEdit &&
        <EditMessageDialog 
          open={ openEdit }
          data={ rowToEdit }
          close={ () => setOpenEdit(false)}
          submit={ modifyMessage }
        />
      }
      <RDTable
        table={{
          columns: columns,
          rows: data
        }}
        showTotalEntries={false}
        addRowButton='New Message'
        editable={{
          onRowAdd: null,
          onRowEdit: row => openEditDialog(row),
          onRowDelete: row => removeMessage(row)
        }}
        cellStyle={ MessageCellStyle }
      />
    </MDBox>
  )
}

MessageTable.propTypes = {
  messages: PropTypes.array,
  setExternal: PropTypes.func,
  tableType: PropTypes.string,
}