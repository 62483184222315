export default function R0003TrainingCellStyle(row, cell){
  let cellColor = 'white'
  let fontSize = '.7rem'
  let fontStyle = 'normal'
  let fontWeight = 'normal'
  let leftBorder = ''
  let padding = 0
  let paddingTop = '1px'
  let paddingRight = 0
  let paddingBottom = '1px'
  let paddingLeft = 0
  let textAlign = 'center'
  let textColor = 'black'
  let topBorder = '1px solid #e0e0e0'
  let width = 75

  const setting = {
    backgroundColor: cellColor,
    borderLeft: leftBorder,
    borderTop: topBorder,
    color: textColor,
    fontSize: fontSize,
    fontStyle: fontStyle,
    fontWeight: fontWeight,
    paddingTop: paddingTop,
    paddingRight: paddingRight,
    paddingBottom: paddingBottom,
    paddingLeft: paddingLeft,
    textAlign: textAlign,
    width: width
  }

  return setting
}