import { useMemo } from 'react'

import { getQuery } from '../r0005Fetch'

export default function useStableQuery(
  namespace, 
  key, 
  callback
){
  console.log('**** useStableQuery ****')

  return useMemo(() => getQuery(namespace, key, callback), [key] )
}