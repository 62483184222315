import React, {useEffect, useState} from 'react'

// AWS Amplify components
import { API } from 'aws-amplify'

// GraphQL
import { dbAddItem, dbDeleteItem, dbEditItem } from 'graphql/mutations'

// MUI components
import { Stack } from '@mui/material'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

// RadixDash components
import DialogAddTH from './DialogAddTH'
import DialogEditTH from './DialogEditTH'
import RDSimpleTable from 'components/RDTable/RDSimpleTable'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

const headerStyle = {
  backgroundColor: '#e0e0e0',
  color: '#181818',
  borderBottom: '1px solid #000',
  fontSize: '.7rem',
  fontWeight: 'bold',
  paddingTop: '5px'
}

const cellStyle = {
  fontSize: '.8rem',
  borderTop: '1px solid #e0e0e0',
  borderBottom: '1px solid #e0e0e0',
  paddingTop: '3px',
  paddingBottom: '3px',
  alignText: 'center'
}

export default function THSettingTable(props){

  const [openAdd, setOpenAdd] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [rowToEdit, setRowToEdit] = useState(null)

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      align: 'center',
      alignCell: 'center',
      disableGroupBy: true,
      disableSortBy: true,
      headerStyle: headerStyle,
      cellStyle: cellStyle,
    },
    {
      Header: 'Low Value',
      accessor: 'lowValue',
      align: 'center',
      alignCell: 'center',
      disableGroupBy: true,
      disableSortBy: true,
      headerStyle: headerStyle,
      cellStyle: cellStyle,
      Cell: ({row}) => {
        const data = row.original
        let displayValue
        switch(data.type){
          case 'netSales':
            displayValue = data.lowValue + '%'
            break;
          default:
            let value = data.lowValue/100
            displayValue = formatter.format(value)
        }
        return (
          <div>{displayValue}</div>
        )
      }
    },
    {
      Header: 'High Value',
      accessor: 'highValue',
      align: 'center',
      alignCell: 'center',
      disableGroupBy: true,
      disableSortBy: true,
      headerStyle: headerStyle,
      cellStyle: cellStyle,
      Cell: ({row}) => {
        const data = row.original
        let displayValue
        switch(data.type){
          case 'netSales':
            displayValue = data.highValue + '%'
            break;
          default:
            let value = data.highValue/100
            displayValue = formatter.format(value)
        }
        return (
          <div>{displayValue}</div>
        )
      }
    },
  ]

  async function addTH(newTH){
    console.log('addTH', newTH)
    let thType = props.tableType

    const vars = {
      type: thType,
      report: 'R0002',
      name: newTH.name,
      lowValue: newTH.lowValue,
      highValue: newTH.highValue
    }

    const varsJSON = JSON.stringify(vars)

    try {
      let result = await API.graphql(
        {
          query: dbAddItem,
          variables: {
            db: 'grm.reportadmins',
            dbItem: varsJSON
          }
        }
      )
      console.log('Successfully add new threshold to DB', result)

      const newData = JSON.parse(JSON.parse(result.data.dbAddItem.statusItem))

      props.update( newData )

    } catch (err) {
      console.log('Error adding threshold')
    }
    
    setOpenAdd(false)
  }

  function openEditDialog(row){
    setRowToEdit(row)
  }

  useEffect(() => {
    if(rowToEdit){
      setOpenEdit(true)
    }
  }, [rowToEdit])

  async function modifyTH(values){

    const vars = {
      _id: values.oldData._id,
      type: values.oldData.type,
      report: 'R0002',
      name: values.newData.name,
      lowValue: Number(values.newData.lowValue),
      highValue: Number(values.newData.highValue)
    }

    const varsJSON = JSON.stringify(vars)

    try {
      let result = await API.graphql(
        {
          query: dbEditItem,
          variables: {
            db: 'grm.reportadmins',
            dbItemId: values.oldData._id,
            dbItem: varsJSON
          }
        }
      )
      console.log('Successfully updated threshold', result)

      const newData = JSON.parse(JSON.parse(result.data.dbEditItem.statusItem))

      props.update( newData )

    } catch (err) {
      console.log('Error updating threshold', err)
    }
    setOpenEdit(false)
  }

  async function removeTH(row) {
    console.log('removeTH', row)
    // props.setLoading({
    //   type: 'netSales',
    //   setting: true
    // })
    
    const vars = {
      _id: row.original._id,
      type: row.original.type,
      report: 'R0002'
    }

    const varsJSON = JSON.stringify(vars)

    try {
      let result = await API.graphql(
        {
          query: dbDeleteItem,
          variables: {
            db: 'grm.reportadmins',
            dbItemId: row.original._id,
            dbItem: varsJSON
          }
        }
      )

      console.log('Successfully deleted threshold', result)

      const newData = JSON.parse(JSON.parse(result.data.dbDeleteItem.statusItem))
      console.log('newData', newData)

      props.update( newData )
      // props.setLoading({
      //   type: 'netSales',
      //   setting: false
      // })

    } catch (err) {
      console.log('Error deleting threshold', err)
    }
  }

  return (
    <MDBox
      sx={{
        width: props.width,
        mt: 2,
        mb: 3,
      }}
    >
      {
        openAdd &&
        <DialogAddTH 
          open={openAdd}
          close={ () => setOpenAdd(false)}
          submit={ addTH }
          title={ props.title }
          tableType={ props.tableType }
        />
      }
      {
        openEdit && 
        <DialogEditTH 
          open={ openEdit }
          data={ rowToEdit }
          close={ () => setOpenEdit(false)}
          submit={ modifyTH }
          title={ props.title }
          tableType={ props.tableType }
        />
      }
      <Stack 
        direction='row'
        alignItems='center'
        justifyContent='space-around'
        sx={{
          mb: 1
        }}
      >
        <MDTypography variant='body1'>
          {props.title}
        </MDTypography>
        <MDButton
          variant='contained'
          size='small'
          color='info'
          onClick={ () => setOpenAdd(true)}
        >
          Add
        </MDButton>
      </Stack>
      
      <RDSimpleTable 
        columns={ columns }
        data={ props.data }
        editable={{
          onRowAdd: null,
          onRowEdit: row => openEditDialog(row),
          onRowDelete: row => removeTH(row)
        }}
        loading={ props.loading }
      />
    </MDBox>
  )
}