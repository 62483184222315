export default function SSCellStyle(row, cell, options){
  let setting = {}

  let bgColor = '#f9e9d3'
  let borderLeft = null
  let fontSize = '.9rem'
  let fontWeight = 'normal'
  let textAlign = 'left'
  let textColor = '#536178'
  let textIndent = 0
  let borderTop = null
  let padding = '0px 10px'

  if(cell.column.id === 'selector'){
    textAlign = 'center'
  }

  setting = {
    backgroundColor: bgColor,
    borderLeft: borderLeft,
    borderTop: borderTop,
    color: textColor,
    fontSize: fontSize,
    fontWeight: fontWeight,
    padding: padding,
    textAlign: textAlign,
    textIndent: textIndent,
    width: 200
  }

  return setting
}