import React from 'react'

// @mui components
import { Dialog, DialogTitle, DialogContent, Grid, Stack } from '@mui/material'

// formik components
import { Formik, Form } from 'formik'
import Textfield from 'components/FormsUI/Textfield'
import * as Yup from 'yup'

// Material Dashboard Pro 2 components
import MDButton from 'components/MDButton'

import LocationAutoComplete from './LocationAutoComplete'

export default function EditMember(props){
  console.log('EditMember(props)', props)

  const originalData = props.data.original
  const locationData = {
    country: originalData.country,
    label: originalData.location,
    geometry: {
      point: [originalData.longitude, originalData.latitude]
    },
    // latitude: originalData.latitude,
    // longitude: originalData.longitude,
    municipality: originalData.municipality,
    region: originalData.region,
    street: originalData.street,
  }

  const INITIAL_FORM_STATE = {
    classId: props.data.values.classId,
    firstName: props.data.values.firstName,
    middleName: props.data.values.middleName,
    lastName: props.data.values.lastName,
    suffix: props.data.values.suffix,
    location: props.data.values.location
  }

  const FORM_VALIDATION = Yup.object().shape({
    classId: Yup.string()
      .required('Required'),
    firstName: Yup.string()
      .required('Required'),
    middleName: Yup.string(),
    lastName: Yup.string()
      .required('Required'),
    suffix: Yup.string(),
    location: Yup.string()
      .required('Required')
  })

  function handleSubmit(values){
    props.submit({
      index: props.data.index,
      oldData: props.data.values,
      newData: values
    })
  }

  return (
    <Dialog
      open={ props.open }
    >
      <DialogTitle>Edit Member</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={INITIAL_FORM_STATE}
          validationSchema={FORM_VALIDATION}
          onSubmit={ (values) => {
            handleSubmit(values)
          }}
        >
          {({ errors, isValid, dirty }) => (
            <Form>
              <Stack
                direction='column'
                spacing={2}
                sx={{
                  mt: 2
                }}
              >
                <Textfield
                    name='classId'
                    label='Class ID'
                  />
                  <Textfield
                    name='firstName'
                    label='First Name'
                  />
                  <Textfield
                    name='middleName'
                    label='Middle Name'
                  />
                  <Textfield
                    name='lastName'
                    label='Last Name'
                  />
                  <Textfield
                    name='suffix'
                    label='Suffix'
                  />
                  <LocationAutoComplete 
                    name='location'
                    setLocationData={props.setLocationData}
                    initialAddress={locationData}
                  />
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='flex-end'
                  spacing={2}
                >
                  <MDButton
                    variant='contained'
                    color='info'
                    onClick={ props.close }
                  >
                    Cancel
                  </MDButton>
                  <MDButton
                    variant='contained'
                    color='info'
                    type='submit'
                    disabled={!(dirty && isValid)}
                  >
                    {/* { console.log('validation errors:', errors)
                    } */}
                    Submit
                  </MDButton>
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}