import { Box } from '@mui/material'

import CreatePredictor from './CreatePredictor/CreatePredictor'

export default function R0003Settings(props){

  return (
    <Box
      mx='auto'
      my={3}
      sx={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <CreatePredictor />
    </Box>
  )
}