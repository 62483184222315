import * as React from 'react'

// RadixDash components
import { useUser } from 'components/RDAuthContext'
import AuthenticatedApp from 'AuthenticatedApp'
import UnauthenticatedApp from 'UnauthenticatedApp'

function App() {
  let authenticated = false
  
  const user = useUser( )
  
  user.user == null ? authenticated = false : authenticated = true
  
  return authenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />
}

export default App