import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types'

import { API } from 'aws-amplify'

import { createR0003Projection, updateR0003Projection } from 'graphql/mutations'
import { listAreas2, getR0003ProjectionData } from 'graphql/queries'

// @mui material components
import { Grid, Stack,  } from '@mui/material'

import { addWeeks, format, startOfWeek } from 'date-fns'

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography'

// formik components
import { Formik, Form,  } from 'formik'
import * as Yup from 'yup'

// RadixDash components
import Button_GrmDark from 'components/RDButton/Button_GrmDark'
import Progress from 'components/Progress/Progress'
import R0003ProjectionSettings from "../R0003ProjectionSettings";
import Textfield from 'components/FormsUI/Textfield'
import { cleanProjectionData } from './projectionUtils'
import { useUser } from 'components/RDAuthContext'

function setWeeks(){
  /* Function to set the week values to use in the
   * dropdown for the user to select the week for
   * the projections to be set
   */
  console.log('setWeeks()')
  const today = new Date()
  const currentMonday = startOfWeek(today, { weekStartsOn: 1})
  const nextMonday = addWeeks(currentMonday, 1)
  const followingMonday = addWeeks(currentMonday, 2)

  const weeks = [
    format(currentMonday, 'yyyy-MM-dd'),
    format(nextMonday, 'yyyy-MM-dd'),
    format(followingMonday, 'yyyy-MM-dd')
  ]
  
  return weeks
}

const initialTheoHours = {
  Mon: 0,
  Tue: 0,
  Wed: 0,
  Thu: 0,
  Fri: 0,
  Sat: 0,
  Sun: 0
}

const FORM_VALIDATION = Yup.object().shape({
  week: Yup.string()
    .required('Required'),
  store: Yup.string()
    .required('Required'),
  avgWage: Yup.number()
    .required('Required'),
  monProjSales: Yup.number()
  .required('Required'),
  monProjAvgCheck: Yup.number()
  .required('Required'),
  monSchedFOH: Yup.number()
  .required('Required'),
  monSchedBOH: Yup.number()
  .required('Required'),
  tueProjSales: Yup.number()
  .required('Required'),
  tueProjAvgCheck: Yup.number()
  .required('Required'),
  tueSchedFOH: Yup.number()
  .required('Required'),
  tueSchedBOH: Yup.number()
  .required('Required'),
  wedProjSales: Yup.number()
  .required('Required'),
  wedProjAvgCheck: Yup.number()
  .required('Required'),
  wedSchedFOH: Yup.number()
  .required('Required'),
  wedSchedBOH: Yup.number()
  .required('Required'),
  thuProjSales: Yup.number()
  .required('Required'),
  thuProjAvgCheck: Yup.number()
  .required('Required'),
  thuSchedFOH: Yup.number()
  .required('Required'),
  thuSchedBOH: Yup.number()
  .required('Required'),
  friProjSales: Yup.number()
  .required('Required'),
  friProjAvgCheck: Yup.number()
  .required('Required'),
  friSchedFOH: Yup.number()
  .required('Required'),
  friSchedBOH: Yup.number()
  .required('Required'),
  satProjSales: Yup.number()
  .required('Required'),
  satProjAvgCheck: Yup.number()
  .required('Required'),
  satSchedFOH: Yup.number()
  .required('Required'),
  satSchedBOH: Yup.number()
  .required('Required'),
  sunProjSales: Yup.number()
  .required('Required'),
  sunProjAvgCheck: Yup.number()
  .required('Required'),
  sunSchedFOH: Yup.number()
  .required('Required'),
  sunSchedBOH: Yup.number()
  .required('Required'),
})

export default function R0003ProjectionsOriginal(props){
  console.log('R0003ProjectionsOriginal...', props)
  
  const { user: cognitoUser } = useUser()
  const user = cognitoUser.userName
  const role = cognitoUser.role

  const [dataLoading, setDataLoading] = useState(false)
  const [openSettings, setOpenSettings] = useState(true)
  const [newProjection, setNewProjection] = useState(false)
  const [storeConfig, setStoreConfig] = useState([{}])
  const [week, setWeek] = useState(null)
  const [store, setStore] = useState(null)
  const [fohBaseTheoHrs, setFohBaseTheoHrs] = useState(initialTheoHours)
  const [bohBaseTheoHrs, setBohBaseTheoHrs] = useState(initialTheoHours)
  const [data, setData] = useState({
    week: '',
    store: '',
    avgWage: '',
    monProjSales: '',
    monProjAvgCheck: '',
    monSchedFOH: '',
    monSchedBOH: '',
    tueProjSales: '',
    tueProjAvgCheck: '',
    tueSchedFOH: '',
    tueSchedBOH: '',
    wedProjSales: '',
    wedProjAvgCheck: '',
    wedSchedFOH: '',
    wedSchedBOH: '',
    thuProjSales: '',
    thuProjAvgCheck: '',
    thuSchedFOH: '',
    thuSchedBOH: '',
    friProjSales: '',
    friProjAvgCheck: '',
    friSchedFOH: '',
    friSchedBOH: '',
    satProjSales: '',
    satProjAvgCheck: '',
    satSchedFOH: '',
    satSchedBOH: '',
    sunProjSales: '',
    sunProjAvgCheck: '',
    sunSchedFOH: '',
    sunSchedBOH: ''
  })

  /********************************************************  
   Set the values for Week and Store dropdowns that the user can select from
   ********************************************************/
  
   const [weeks] = useState(() => {
    const weeksArr = setWeeks()
    return weeksArr
  })
  const [stores, setStores] = useState(['Fetching stores...'])

  async function setStoreDropdown(){
    try {
      const queryResponse = await API.graphql(
        {
          query: listAreas2,
          variables: {
            report: 'r0003_store_dropdown',
            user: user,
            role: role
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      )

      const results = queryResponse.data.listAreas2[0].stores
      console.log('results', results)
      setStoreConfig(results) 
      const storeArr = results.map(x => x.shortName)
      setStores(storeArr)
    } catch (err) {
      console.log('Error fetching store dropdown data', err)
      setStores(['Error: Unable to Load Stores'])
    }
  }

  useEffect(() => {
    setStoreDropdown()
  }, [])  

  

  function handleCancel(){
    setOpenSettings(false)
    props.setPage('actions')
  }

  async function handleSelection(selection){
    const selectedWeek = weeks[selection.week]
    const selectedStore = stores[selection.store]
    setWeek(selectedWeek)
    setStore(selectedStore)   
    setOpenSettings(false)
    setDataLoading(true)
    
    // Set Base Theo Hours
    const storeIdx = storeConfig.findIndex(x => x.shortName === selectedStore)
    const foh = storeConfig[storeIdx].fohBaseTheoHrs
    const boh = storeConfig[storeIdx].bohBaseTheoHrs
    setFohBaseTheoHrs(foh)
    setBohBaseTheoHrs(boh)    

    // Query the DB to determine if data already exists
    try {
      const result = await API.graphql({
        query: getR0003ProjectionData,
        variables: { week: selectedWeek, store: selectedStore } ,
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })
      console.log('Query Result', result.data.getR0003ProjectionData)

      if(result.data.getR0003ProjectionData.store == null){
        console.log('New Projection')
        setNewProjection(true)
        setData({ 
          ...data, 
          week: selectedWeek, 
          store: selectedStore
        })
        
      } else {
        console.log('Existing Projection')
        setNewProjection(false)
        let tempObj = JSON.parse(JSON.stringify(result.data.getR0003ProjectionData))
        
        const keyToRemove = '__typename'
        if (tempObj.hasOwnProperty(keyToRemove)) {
          delete tempObj[keyToRemove]
        } 
        console.log('tempObj post', tempObj)
        setData(tempObj)
        
      }
    } catch(err) {
      console.log('Query Error', err)
      console.log('New Projection')
      setNewProjection(true)
      setData({ 
        ...data, 
        week: selectedWeek,
        store: selectedStore
      })
      
    }  
  }

  useEffect(() => {
    if(data.week !== ''){
      setDataLoading(false)
    }
  }, [data])

  async function handleSubmitProjections(formValues){
    console.log('handleSubmitProjections', formValues)
    
    let result

    try {
      const values = cleanProjectionData(formValues)
      console.log('values', values)
      if(newProjection){
        console.log('new projection', values)
        result = await API.graphql({
          query: createR0003Projection,
          variables: { input: values }
        })
        console.log('Successfully created projection', result)
      } else {
        console.log('existing projection', values)
        result = await API.graphql({
          query: updateR0003Projection,
          variables: { input: values }
        })
        console.log('Successfully updated projection', result)
      }
      
      props.setPage('actions')

    } catch (err) {
      console.log('Create Projection error', err)
      console.log('err.errors', err.errors)
      console.log('user', user)
      console.log('role', role)
    }
  }

  function handleTextFieldChange(event){
    let dataCopy = {...data}
    const field = event.target.name
    dataCopy[field] = event.target.value
    setData(dataCopy)
  }

  function getTextFieldValue(day, field){
 
    const fohWeekday = 316
    const fohWeekend = 230
    const bohWeekday = 345
    const bohWeekend = 316

    const shortDay = `${day.substring(0, 3)}`
    const projSalesKey = `${shortDay.toLowerCase()}ProjSales`    
    let productivityLevel
    const baseTheoHours = field === 'TheoFOH' ? fohBaseTheoHrs[shortDay] : bohBaseTheoHrs[shortDay]
    
    switch(day){
      case 'Monday':
      case 'Tuesday':
      case 'Wednesday':
      case 'Thursday':
        productivityLevel = field === 'TheoFOH' ? fohWeekday : bohWeekday
        break;
      default:
        productivityLevel = field === 'TheoFOH' ? fohWeekend : bohWeekend
    }
    return (data[projSalesKey] / productivityLevel + baseTheoHours).toFixed(2)    
  }

  return (
    
      <MDBox textAlign='center' >
        <R0003ProjectionSettings 
          open={ openSettings }
          cancel={ handleCancel }
          weeks={ weeks }
          stores={ stores }
          close={ handleSelection}
        />        
        
        <ReportHeader store={store} week={week}/>

        { dataLoading ?
          <Progress /> :   
          <ReportBody 
            data={data}
            getTextFieldValue={getTextFieldValue}
            handleTextFieldChange={handleTextFieldChange} 
            handleSubmitProjections={handleSubmitProjections} 
            handleCancel={handleCancel}
          />    
        }            
      </MDBox>
  )
}

const ReportHeader = ({ store, week }) => {
  return (
    <MDBox
      py={2}
      px={3}
      sx={{ backgroundColor: '#29261f' }}
    >
      <Stack direction='row' justifyContent='space-between'>
        <Stack direction='column'>
          <MDTypography variant='h6' fontWeight='medium' style={{ color: '#CF9E0B' }}>
            SPLH: Set Weekly Projections
          </MDTypography>
          <MDTypography variant='h6' fontWeight='medium' style={{ color: '#CF9E0B' }}>
            {store}
          </MDTypography>
        </Stack>
        <Stack direction='column'>
          <MDTypography variant='h6' fontWeight='medium' style={{ color: '#CF9E0B' }}>
            Week Starting
          </MDTypography>
          <MDTypography variant='h6' fontWeight='medium' style={{ color: '#CF9E0B' }}>
            {week}
          </MDTypography>
        </Stack>
      </Stack>
    </MDBox>
  )
}

const ReportBody = ({ 
  data, 
  getTextFieldValue, 
  handleTextFieldChange, 
  handleSubmitProjections,
  handleCancel 
}) => {
  const days = useMemo(
    () => ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    []
  )

  return (
    <MDBox textAlign='left' mx={4} my={3}>
      <Formik
        initialValues={{ ...data }}
        validationSchema={FORM_VALIDATION}
        validateOnMount
        enableReinitialize
        onSubmit={values => {
          handleSubmitProjections(values);
        }}
      >
        {({ isValid }) => (
          <Form>
            <Grid container spacing={2}>
              {days.map(day => (
                <React.Fragment key={day}>
                  <Grid item xs={2}>
                    <MDTypography variant='body2' sx={{ fontWeight: 'bold' }}>
                      {day}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={10}>
                    <Grid container spacing={1}>
                      {['ProjSales', 'ProjAvgCheck', 'TheoFOH', 'TheoBOH', 'SchedFOH', 'SchedBOH'].map(field => (
                        <Grid item xs key={`${day}-${field}`}>
                          <SPLHField
                            day={day}
                            field={field}
                            value={getTextFieldValue(day, field)}
                            onChange={handleTextFieldChange}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}

              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container spacing={1}>
                  <Grid item xs>
                    <Textfield name='avgWage' label='Average Wage' />
                  </Grid>
                  <Grid item xs></Grid>
                  <Grid item xs></Grid>
                  <Grid item xs></Grid>
                  <Grid item xs></Grid>
                  <Grid item xs></Grid>
                </Grid>
              </Grid>

              <Grid item xs={8}></Grid>
              <Grid item xs={4}>
                <Stack direction='row' justifyContent='space-between' spacing={2}>
                  <Button_GrmDark fullWidth onClick={handleCancel}>
                    Cancel
                  </Button_GrmDark>
                  <Button_GrmDark type='submit' disabled={!isValid} fullWidth>
                    Submit
                  </Button_GrmDark>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </MDBox>
  )
}

function SPLHField(props){
  switch(props.field){
    case 'TheoFOH':
    case 'TheoBOH':
      return (
        <Textfield 
          name={`${props.day.toLowerCase()}${props.field}`}
          label={ getFieldLabel(props.field) }
          value={ props.value}
          disabled={true}
        />
      )
    default:
      return (
        <Textfield 
          name={`${props.day.substring(0,3).toLowerCase()}${props.field}`}
          label={ getFieldLabel(props.field) }
          onChange={ props.onChange }
        />
      )
  }
}

function getFieldLabel(field){
    switch(field){
      case 'ProjSales':
        return 'Projected Sales'
      case 'ProjAvgCheck':
        return 'Projected Avg Check'
      case 'TheoFOH':
        return 'Theoretical Hours FOH'
      case 'TheoBOH':
        return 'Theoretical Hours BOH'
      case 'SchedFOH':
        return 'Scheduled FOH Hours'
      case 'SchedBOH':
        return 'Scheduled BOH Hours'
      default:
        return null
    }
}

R0003ProjectionsOriginal.propTypes = {
  setPage: PropTypes.func
}