import { API } from 'aws-amplify'
import { updateThresholdMapping } from 'graphql/mutations'

export default async function updateAssignment(assignment){
  console.log('updateAssignment', assignment)

  try {
    const response = await API.graphql({
      query: updateThresholdMapping,
      variables: {
        input: assignment
      }
    })
    console.log('response', response)
  } catch (err) {
    console.log('Error updating profile mapping', err)
  }
  
}