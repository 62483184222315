// import React from 'react'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import R0003IncentiveCellStyle from './R0003IncentiveCellStyle'
import { format } from 'date-fns'

export default function R0003IncentivePDF(
  data,
  title,
){

  let fullDataArr = []
  
  for(let i=0; i < data.length; i++){
    const fullData = [
      data[i].week,
      data[i].fTheo,
      data[i].fSched,
      data[i].fSchedPct,
      data[i].fAct,
      data[i].fActPct,
      data[i].bTheo,
      data[i].bSched,
      data[i].bSchedPct,
      data[i].bAct,
      data[i].bActPct
    ]
    fullDataArr.push(fullData)
  }

  let doc = new jsPDF({
    orientation: 'landscape',
    unit: 'pt',
    format: 'letter'
  })

  const titleStyle = {
    halign: 'left',
    fillColor: [41, 38, 31],
    textColor: [207, 158, 11],
    cellPadding: 1,
    fontSize: 12
  }

  const headerStyle = {
    valign: 'bottom',
    halign: 'center',
    fillColor: [224, 224, 224],
    textColor: [0, 0, 0],
    cellPadding: .5,
    fontStyle: 'bold'
  }

  doc.autoTable({
    head: [
      [
        {
          content: title,
          colSpan: 11,
          styles: titleStyle
        }
      ],
      [
        {
          content: 'Week',
          rowSpan: 2,
          styles: headerStyle
        },
        {
          content: 'FOH',
          colSpan: 5,
          styles: headerStyle
        },
        {
          content: 'BOH',
          colSpan: 5,
          styles: headerStyle
        }
      ],
      [
        {
          content: 'Theo',
          styles: headerStyle
        },
        {
          content: 'Sched',
          styles: headerStyle
        },
        {
          content: 'Sched %',
          styles: headerStyle
        },
        {
          content: 'Act',
          styles: headerStyle
        },
        {
          content: 'Act %',
          styles: headerStyle
        },
        {
          content: 'Theo',
          styles: headerStyle
        },
        {
          content: 'Sched',
          styles: headerStyle
        },
        {
          content: 'Sched %',
          styles: headerStyle
        },
        {
          content: 'Act',
          styles: headerStyle
        },
        {
          content: 'Act %',
          styles: headerStyle
        },
      ]
    ],
    body: fullDataArr,
    headStyles: {
      fontSize: 5,
    },
    bodyStyles: {
      fontSize: 6,
    },
    didParseCell: function(data) {
      if(data.row.section === 'body'){        
        const dataObj = {}
        for(let i = 0; i < data.row.raw.length; i++){
          const field = getField(i)
          dataObj[field] = data.row.raw[i]
        }
        if(dataObj.week === 'Score'){
          dataObj['rowType'] = 'scoreRow'
        } else {
          dataObj['rowType'] = 'weekRow'
        }
        dataObj['threshold'] = .05

        const dataObj2 = {
          original: dataObj
        }
        
        const columnNum = data.column.index
        const field = getField(columnNum)
        const cell = {
          column: {
            id: field
          },
          value: data.cell.raw
        }
        const setting = R0003IncentiveCellStyle(dataObj2, cell)
        data.cell.styles.cellPadding = .5
        data.cell.styles.fontStyle = setting.fontWeight
        data.cell.styles.halign = setting.textAlign
        data.cell.styles.lineColor = [40, 40, 40]
        data.cell.styles.lineWidth = .25

        switch(setting.backgroundColor){
          case 'grey':
            data.cell.styles.fillColor = [189, 189, 189]
            data.cell.styles.textColor = '#000000'
            break;
          case 'green':
            data.cell.styles.fillColor = [0, 128, 0]
            data.cell.styles.textColor = '#ffffff'
            break;
          case 'red':
            data.cell.styles.fillColor = [255, 0, 0]
            data.cell.styles.textColor = '#ffffff'
            break;
          case 'white':
          case '':
            data.cell.styles.fillColor = [255, 255, 255]
            data.cell.styles.textColor = '#000000'
            break;
          default:
            data.cell.styles.fillColor = setting.backgroundColor
            if(setting.color !== ''){
              data.cell.styles.textColor = setting.color
              // data.cell.styles.lineColor = setting.color
              // data.cell.styles.lineWidth = 0            
            } else {
              data.cell.styles.textColor = '#000000'
            }
        }
      }
    }
  })

  doc.save('splh_incentive.pdf')
}

function getField(colIdx){
  switch(colIdx){
    case 0: return 'week'
    case 1: return 'fTheo'
    case 2: return 'fSched'
    case 3: return 'fSchedPct'
    case 4: return 'fAct'
    case 5: return 'fActPct'
    case 6: return 'bTheo'
    case 7: return 'bSched'
    case 8: return 'bSchedPct'
    case 9: return 'bAct'
    case 10: return 'bActPct'
  }
}