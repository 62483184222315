import React, { useEffect, useState } from 'react'

// @mui material components
import { Grid } from '@mui/material'

// AWS Amplify components
import { API } from 'aws-amplify'

// GraphQL
import { listClients } from '../../../graphql/queries'


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/RDDashboardLayout';

// RadixDash components
import ClientTable from './ClientTable'
import DashboardNavbar from 'components/RDNavbars/DashboardNavbar';
import Progress from 'components/Progress/Progress'
import RDFooter from  'components/RDFooter'
import { useUser } from 'components/RDAuthContext'

export default function ClientAdmin(){
  const { user } = useUser()
  const clientId = user.client

  const [clientsLoading, setClientsLoading] = useState(true)
  const [clients, setClients] = useState([{}])

   // Set client list when component loads
  useEffect(() => {
    getClients()
  }, [])

  async function getClients(){
    console.log('getClients()...')
    try {
      const queryResponse = await API.graphql({
        query: listClients,        
      })
      console.log('clients', queryResponse.data.listClients.items)
      const clientArr = queryResponse.data.listClients.items
      setClients(clientArr)
    } catch (err) {
      console.log('Error fetching clients')
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ClientTable 
        clientData={clients}
      />
      <RDFooter />
    </DashboardLayout>
  )
}