import { useEffect, useState } from 'react'

import { API, graphqlOperation } from 'aws-amplify'
import { listThresholds } from 'graphql/queries'
import { onCreateThreshold, onUpdateThreshold, onDeleteThreshold } from 'graphql/subscriptions'

import { QueryStatus } from 'utils/queryUtils'

import { sortObjectArray } from 'utils/helperUtils'

export default function useProfiles(){
  const [status, setStatus] = useState(QueryStatus.IDLE);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null); 

  useEffect(() => {
    let didCancel = false;
    setStatus(QueryStatus.PENDING)
    setData([])

    const fetchData = async () => {
      try {
        
        const profiles = []
        let nextToken

        do {
          const response = await API.graphql({
            query: listThresholds,
            variables: {
              nextToken: nextToken,
            }
          });
          console.log('response', {response})
          
          const profileArr = response.data.listThresholds.items.map(({
            createdAt,
            updatedAt,
            ...keep
          }) => keep)

          profiles.push(...profileArr)
          nextToken = response.data.listThresholds.nextToken
        } while (nextToken)
        
        const sortedProfiles = sortObjectArray(profiles, 'name')

        if(!didCancel) {
          setData(sortedProfiles)
          setStatus(QueryStatus.SUCCESS)
        }
      } catch (err) {
        console.log('Error fetching data', err)
        if(!didCancel){
          setError(err)
          setStatus(QueryStatus.ERROR)
        }
        throw err
      }
    }
    
    fetchData();

    resubscribe()   

    return () => {
      didCancel = true;
    };

  }, []);

  function resubscribe() {
    const createSub = API.graphql(graphqlOperation(onCreateThreshold)).subscribe({
      next: (subData) => {
        const { createdAt, updatedAt, ...keep } = subData.value.data.onCreateThreshold
        
        const newProfile = {...keep}
        
        setData(existingData => {
          let updatedData = JSON.parse(JSON.stringify(existingData))
          updatedData.push(newProfile)
          updatedData = sortObjectArray(updatedData, 'name')
          return updatedData
          // const sortedData = sortObjectArray(existingData, 'name')
          // return [...existingData, newProfile]
        })
      },
      error: (err) => {
        // console.log('onCreateThreshold err', err)
      }
    })

    const updateSub = API.graphql(graphqlOperation(onUpdateThreshold)).subscribe({
      next: (subData) => {

        const { createdAt, updatedAt, ...keep } = subData.value.data.onUpdateThreshold

        const newProfile = {...keep}

        setData(prevData => {
          const dataCopy = JSON.parse(JSON.stringify(prevData))
          replaceObject(dataCopy, newProfile)
          return dataCopy
        })
      },
      error: (err) => {
        // console.log('onUpdateThreshold err', err)
        createSub.unsubscribe()
        updateSub.unsubscribe()
        deleteSub.unsubscribe()
        setTimeout(() => {
          resubscribe()
        }, 5000)
      }
    })

    function replaceObject(arr, newObj){
      for(let i = 0; i < arr.length; i++) {
        if(arr[i].id === newObj.id) {
          arr[i] = newObj
          break;
        }
      }
    }

    const deleteSub = API.graphql(graphqlOperation(onDeleteThreshold)).subscribe({
      next: (subData) => {
        const { id } = subData.value.data.onDeleteThreshold
        
        setData(existingData => {
          return existingData.filter(t => t.id !== id)
        })
      },
      error: (err) => {
        // console.log('onDeleteThreshold err', err)
      }
    }) 

    return {
      unsubscribe: () => {
        createSub.unsubscribe()
        updateSub.unsubscribe()
        deleteSub.unsubscribe()
      }
    }
  }

  return {
    status,
    data,
    error,
  };
}

function sortData(data){
  const sortedData = data.sort((a, b) => {
    if(a.name < b.name) return -1;
    if(a.name > b.name) return 1;
    return 0;
  })
  return sortedData
}