import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// AWS Amplify components
import { API } from 'aws-amplify'

// GraphQL
import { createClient, createRole, deleteClient, updateClient } from '../../../graphql/mutations'

// @mui components
import { Alert, Snackbar } from '@mui/material'

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDAlert from 'components/MDAlert'

// RadixDash components
import { useUser } from 'components/RDAuthContext'
import AddClientDialog from './AddClientDialog';
import EditClientDialog from './EditClientDialog'
import RDTable from 'components/RDTable'
import ClientCellStyle from './ClientCellStyle';

export default function ClientTable(props){
  const [alert, setAlert] = useState({
    open: false,
    severity: 'info',
    message: '',
  })
  
  const [data, setData] = useState(props.clientData)
  const [openAdd, setOpenAdd] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [rowToEdit, setRowToEdit] = useState(null)

  useEffect(() => {
    setData(props.clientData)
  }, [props.clientData])

  const headerStyle = {
    backgroundColor: '#e0e0e0',
    color: '#181818',
    borderBottom: '1px solid #000',
    fontSize: '.7rem',
    fontWeight: 'bold',
    paddingTop: '5px'
  }

  const columns = [
    {
      Header: 'Client ID',
      accessor: 'clientId',
      align: 'center',
      alignCell: 'center',
      disableGroupBy: true,
      columnStyle: headerStyle
    },
    {
      Header: 'Client Name',
      accessor: 'clientName',
      align: 'center',
      alignCell: 'center',
      disableGroupBy: true,
      columnStyle: headerStyle
    },
    {
      Header: 'Contact',
      accessor: 'contactName',
      align: 'center',
      alignCell: 'center',
      disableGroupBy: true,
      columnStyle: headerStyle
    },
    {
      Header: 'Email',
      accessor: 'contactEmail',
      align: 'center',
      alignCell: 'center',
      disableGroupBy: true,
      columnStyle: headerStyle
    },
    {
      Header: 'Phone',
      accessor: 'contactPhone',
      align: 'center',
      alignCell: 'center',
      disableGroupBy: true,
      columnStyle: headerStyle
    }
  ]

  /************************************
   * Add Client Functions
   * **********************************/  
   function openAddDialog(){
    console.log('openAddDialog()...')
    setOpenAdd(true)
  }

  async function addClient(values){
    console.log('addClient(values)', values)

    const newClient = {
      clientId: values.clientId,
      clientName: values.clientName,
      contactName: values.contactName,
      contactEmail: values.contactEmail,
      contactPhone: values.contactPhone
    }
    console.log('newClient', newClient)

    try {
      let result = await API.graphql({
        query: createClient,
        variables: {
          input: newClient
        }
      })
      console.log('Successfully added new client to the DB', result)
      

      // Add Admin role for new client
      console.log(`Adding Admin role for ${newClient.clientId}`)
      console.log('newClient.clientId', newClient.clientId)
      result = await API.graphql(
        {
          query: createRole,
          variables: {
            clientId: newClient.clientId,
            role: 'Admin',
            description:  `Admin role for ${newClient.clientName}`
          }
        }
      )
      console.log('Successfully added new Admin client', result)

      // Add new client to dataTable
      let newData = [...data, newClient]
      newData.sort((a, b) => (a.clientId > b.clientId) ? 1 : -1)
      setData([...newData])
      setAlert({
        open: true,
        severity: 'success',
        message: `${newClient.clientId} successfully created.`
      })
      setOpenAdd(false)
    } catch (err) {
      console.log('Error adding new client', err)
      setAlert({
        open: true,
        severity: 'error',
        message: 'Error adding new client.'
      })
      setOpenAdd(false)
    }
  }

  /************************************
   * Modify Client Functions
   * **********************************/
  function openEditDialog(row){
    console.log('openEditDialog(row)', row)
    setRowToEdit(row)
  }

  useEffect(() => {
    console.log('rowToEdit changed', rowToEdit)
    if(rowToEdit){
      setOpenEdit(true)
    }
  }, [rowToEdit])

  async function editClient(values){
    console.log('editClient(values)', values)

    const index = values.index
    const oldData = values.oldData
    let inputData = values.newData
    inputData.id = oldData.id

    try {
      let result = await API.graphql({
        query: updateClient,
        variables: { input: inputData }
      })
      console.log('Successfully updated client', result)

      // Update dataTable
      const dataUpdate = [...data]
      dataUpdate[index] = values.newData
      setData([...dataUpdate])
      setAlert({
        open: true,
        severity: 'success',
        message: `${inputData.clientId} successfully changed.`
      })
      setOpenEdit(false)
    } catch (err) {
      console.log('Error editing client', err)
      setAlert({
        open: true,
        severity: 'error',
        message: 'Error editing client.'
      })
      setOpenEdit(false)
    }
  }

  /************************************
   * Remove Client Functions
   ************************************/
  async function removeClient(row){
    console.log('removeClient(row)', row)
    const index = row.index
    const databaseId = row.original.id
    const clientId = row.values.clientId

    try {
      let result = await API.graphql({
        query: deleteClient,
        variables: {
          input: { id: databaseId }
        }
      })
      console.log('Successfully deleted client', result)

      // Remove client from dataTable
      const dataDelete = [...data]
      dataDelete.splice(index, 1)
      setData([...dataDelete])
      setAlert({
        open: true,
        severity: 'success',
        message: `${clientId} successfully deleted.`
      })
    } catch (err) {
      console.log('Error deleting client')
      setAlert({
        open: true,
        severity: 'error',
        message: 'Error deleting client.'
      })
    }
  }

  /************************************
   * Notification Management
   ************************************/
  function handleAlertClose(event, reason){
    if(reason === 'clickaway'){
      return
    }
    setAlert({
      open: false,
      severity: 'success',
      message: ' '
    })
  }

  /************************************
   * RENDER COMPONENT
   ************************************/
  return (
    <MDBox>
      {
        openAdd &&
        <AddClientDialog 
          open={ openAdd }
          close={ () => setOpenAdd(false) }
          submit={ addClient }
          existingClients={ data.map( a => a.clientId )}
        />
      }
      {
        openEdit &&
        <EditClientDialog 
          open={ openEdit }
          data={ rowToEdit }
          close={ () => setOpenEdit(false) }
          submit={ editClient }
          existingClients={ data.map( a => a.clientId )}
        />
      }
      <Snackbar 
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert 
          onClose={handleAlertClose}
          severity={alert.severity}
          sx={{
            width: '100%'
          }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <RDTable 
        table={{
          columns: columns,
          rows: data
        }}
        editable={{
          onRowAdd: () => openAddDialog(),
          onRowEdit: row => openEditDialog(row),
          onRowDelete: row => removeClient(row)
        }}
        canSearch
        showEntriesPerPage
        cellStyle={ ClientCellStyle }
      />
    </MDBox>
  )

}

ClientTable.propTypes = {
  clientData: PropTypes.array,
}