import { API, graphqlOperation } from 'aws-amplify'
import { metricDataByClientDate } from 'graphql/queries'

const METRIC_NAMES = {
  CHECK_COUNT: 'checkCount',
  BOH_OVERTIME_DOLLARS: 'bohOvertimeDollars',
  BOH_OVERTIME_HOURS: 'bohOvertimeHours',
  BOH_REGULAR_DOLLARS: 'bohRegularDollars',
  BOH_REGULAR_HOURS: 'bohRegularHours',
  FOH_OVERTIME_DOLLARS: 'fohOvertimeDollars',
  FOH_OVERTIME_HOURS: 'fohOvertimeHours',
  FOH_REGULAR_DOLLARS: 'fohRegularDollars',
  FOH_REGULAR_HOURS: 'fohRegularHours',
  GROSS_SALES: 'grossSales',
  NET_SALES: 'netSales',
}

function createDateFilter(startDate, endDate) {
  return startDate === endDate
    ? { eq: startDate }
    : { between: [startDate, endDate] }
}

function createMetricFilter() {
  return {
    or: Object.values(METRIC_NAMES).map(metricName => ({
      metricName: { eq: metricName }
    }))
  }
}

async function fetchToastData(startDate, endDate, { batchSize = 1000, maxRetries = 3 } = {}){
  console.log('startDate', startDate)
  console.log('endDate', endDate)

  // const bohFohFilter = {
  //   or: Object.values(METRIC_NAMES)
  //     .filter(name => name.startsWith('boh') || name.startsWith('foh'))
  //     .map(metricName => ({
  //       metricName: { eq: metricName }
  //     }))
  // }

  // console.log('BOH/FOH metrics being queried:',
  //   Object.values(METRIC_NAMES).filter(name => name.startsWith('boh') || name.startsWith('foh'))
  // )

  // const bohFohVariables = {
  //   clientId: 'grm',
  //   date: createDateFilter(startDate, endDate),
  //   filter: bohFohFilter,
  //   limit: batchSize
  // }

  // console.log('Attempting BOH/FOH only query with filter:', JSON.stringify(bohFohVariables.filter, null, 2));

  // try {
  //   const bohFohResponse = await API.graphql(graphqlOperation(metricDataByClientDate, bohFohVariables))
  //   const bohFohItems = bohFohResponse.data.metricDataByClientDate.items

  //   console.log('BOH/FOH Query Results:', {
  //     totalItems: bohFohItems.length,
  //     metrics: Object.groupBy(bohFohItems, item => item.metricName),
  //     uniqueMetrics: [...new Set(bohFohItems.map(item => item.metricName))],
  //     uniqueDates: [...new Set(bohFohItems.map(item => item.date))].sort()
  //   })
  // } catch (error) {
  //   console.error('Error inBOH/FOH test query:', error)
  // }

  const variables = {
    clientId: 'grm',
    date: createDateFilter(startDate, endDate),
    filter: createMetricFilter(),
    limit: batchSize,
  }

  let allToastData = []
  let nextToken = null
  let batchCount = 0

  const metricTracker = new Map()
  Object.values(METRIC_NAMES).forEach(metric => metricTracker.set(metric, 0))

  try {
    do {
      batchCount++
      // console.log(`Fetching batch ${batchCount}`)

      const response = await API.graphql(graphqlOperation(metricDataByClientDate, {
        ...variables,
        nextToken
      }))

      const { items, nextToken: newNextToken } = response.data.metricDataByClientDate

      items.forEach(item => {
        metricTracker.set(item.metricName, (metricTracker.get(item.metricName) || 0) + 1)
      })

      // log batch details
      // const batchMetrics = Object.groupBy(items, item => item.metricName)

      // console.log('batchMetrics', batchMetrics)
      
      // console.log('Batch Analysis:', {
      //   batchNumber: batchCount,
      //   totalItems: items.length,
      //   metricsPresent: Object.keys(batchMetrics),
      //   bohMetrics: Object.keys(batchMetrics).filter(m => m.startsWith('boh')),
      //   fohMetrics: Object.keys(batchMetrics).filter(m => m.startsWith('foh')),        itemsPerMetric: Object.fromEntries(
      //     Object.entries(batchMetrics).map(([metric, items]) => [metric, items.length])
      //   ),

      //   // Object.fromEntries(
      //   //   Object.entries(metricCounts).map(([metric, items]) => [metric, items.length])
      //   // ),
      //   hasMoreData: !!newNextToken
      // })

      allToastData = allToastData.concat(items)
      nextToken = newNextToken

    } while (nextToken)

    // Final Summary
    // const finalMetricCounts = Object.groupBy(allToastData, item => item.metricName)
    // console.log('Final fetch summary:', {
    //   totalBatches: batchCount,
    //   totalItems: allToastData.length,
    //   itemsPerMetric: Object.fromEntries(
    //     Object.entries(finalMetricCounts).map(([metric, items]) => [metric, items.length])
    //   ),
    //   dateRange: { startDate, endDate }
    // })

    return allToastData
  } catch (error) {
    console.error('Error in fetchToastData:', error)
    throw error
  }
  
}

export { METRIC_NAMES, fetchToastData }