import React from 'react'
import PropTypes from 'prop-types'

// @mui components
import { Dialog, DialogTitle, DialogContent, Grid, Stack } from '@mui/material'

// formik components
import { Formik, Form } from 'formik'
import Textfield from 'components/FormsUI/Textfield'
import * as Yup from 'yup'

// Material Dashboard Pro 2 components
import MDButton from 'components/MDButton'

import Select_Rd from 'components/FormsUI/Select/Select_Rd'

export default function AddClientDialog(props){

  // console.log('existingClients', props.existingClients)

  const INITIAL_FORM_STATE = {
    clientId: '',
    clientName: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
  }

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const FORM_VALIDATION = Yup.object().shape({
    clientId: Yup.string()
      .required('Required')
      .notOneOf(props.existingClients, 'Client ID already assigned.'),
    clientName: Yup.string()
      .required('Required'),
    contactName: Yup.string()
      .required('Required'),
    contactEmail: Yup.string()
      .required('Required'),
    contactPhone: Yup.string()
      .matches(phoneRegExp, 'Invalid phone number'),
  })

  function addClient(values){
    props.submit(values)
  }

  return (
    <Dialog
      open={ props.open }
    >
      <DialogTitle>Add Client</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={INITIAL_FORM_STATE}
          validationSchema={FORM_VALIDATION}
          onSubmit={ (values) => { addClient(values) }}
        >
          {({ isValid, dirty }) => (
            <Form>
              {/* <Stack direction='column' spacing={2}> */}
              <Grid container xs={12} spacing={2}>
                <Grid item xs={12}>
                <Textfield 
                  name='clientId'
                  label='Client ID'
                />
                </Grid>
                <Grid item xs={12}>
                <Textfield 
                  name='clientName'
                  label='Client Name'
                />
                </Grid>
                <Grid item xs={12}>
                <Textfield 
                  name='contactName'
                  label='Contact'
                />
                </Grid>
                <Grid item xs={12}>
                <Textfield 
                  name='contactEmail'
                  label='Email'
                />
                </Grid>
                <Grid item xs={12}>
                <Textfield 
                  name='contactPhone'
                  label='Phone'
                />
                </Grid>
                {/* <Stack direction='row' spacing={2}> */}
                <Grid item xs={6}>
                  <MDButton
                    variant='contained'
                    color='info'
                    onClick={ props.close }
                  >
                    Cancel
                  </MDButton>
                </Grid>
                <Grid item xs={6}>
                  <MDButton
                    variant='contained'
                    color='info'
                    type='submit'
                    // disabled={
                    //   !(dirty && isValid)
                    // }
                  >
                    Submit
                  </MDButton>
                </Grid>
              </Grid>
                {/* </Stack> */}
              {/* </Stack> */}
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )

}

AddClientDialog.propTypes = {
  close: PropTypes.func,
  existingClients: PropTypes.array,
  open: PropTypes.bool,
  submit: PropTypes.func,
}