import { styled } from '@mui/system'
import { Autocomplete } from '@mui/material'

const Autocomplete_GrmDark = styled(Autocomplete)(({theme}) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#a44c0e'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: '2px solid #a44c0e',
  },
  '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #b9ad9c'
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '2px solid #a44c0e'
  },
  '& .MuiAutocomplete-inputFocused': {
    bgcolor: '#a44c0e'
  },
  '& .MuiAutocomplete-endAdornment': {
    '& .MuiIconButton-root': {
      color: '#a44c0e'
    },
    '& .Mui-disabled': {
      color: '#b9ad9c'
    }
  },
  '& .MuiFormLabel-root': {               
    color: '#a44c0e'
  },
  '& .MuiInputBase-input': {
    color: '#a44c0e',
  },
  '& .Mui-disabled': {
    color: '#b9ad9c'
  }
}))

export default Autocomplete_GrmDark