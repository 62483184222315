import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Stack, Switch } from '@mui/material'
import Button_GrmDark from 'components/RDButton/Button_GrmDark'
import Switch_GrmDark from 'components/RDSwitch/Switch_GrmDark'

function GoalPlannerControl(props){

  // const toggleSwitch = (event) => {
  //   setOpen(event.target.checked)    
  // }

  return (
    <Stack direction='row' spacing={1}>
      <Switch_GrmDark
        checked={ props.open }
        onChange={ props.switch }
        name='goal'
        label = 'Set Goals'
        disabled={ !props.active }
      />

      <Button_GrmDark
        disabled={ props.disableButton }
        onClick={ props.button }
      >
        Set Goals
      </Button_GrmDark>
      
    </Stack>
  )
}

GoalPlannerControl.propTypes = {
  active: PropTypes.bool,
  disableButton: PropTypes.bool,
  open: PropTypes.bool,
  button: PropTypes.func,
  switch: PropTypes.func,
}

export default GoalPlannerControl