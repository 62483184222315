export default function GPCellStyle(row, cell, options){
  let setting = {}

  let bgColor = '#fff'
  let borderLeft = null
  let fontSize = '.7rem'
  let fontWeight = 'normal'
  let textAlign = 'center'
  let textColor = '#000'
  let textIndent = 0
  let borderTop = null
  let padding = '0px 10px'

  setting = {
    backgroundColor: bgColor,
    borderLeft: borderLeft,
    borderTop: borderTop,
    color: textColor,
    fontSize: fontSize,
    fontWeight: fontWeight,
    padding: padding,
    textAlign: textAlign,
    textIndent: textIndent,
    width: 200
  }

  return setting
}