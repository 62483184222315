import { useEffect, useState } from 'react'

// @mui material components
import { Card, Grid } from '@mui/material'

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'layouts/DashboardLayout'

// RadixDash components
import DashboardNavbar from 'components/RDNavbars/DashboardNavbar';
import R0003Actions from './R0003Actions';
import R0003Incentive from './R0003Incentive';
import R0003Projections from './Projections/R0003Projections'
import R0003ProjectionsOriginal from './Projections/R0003ProjectionsOriginal';
import R0003Report from './Report/R0003Report'
import R0003Training from './R0003Training';
import RDFooter from 'components/RDFooter'
import { useUser } from 'components/RDAuthContext'



export default function R0003Main(){

  const { user } = useUser()
  const role = user.role
  
  let initialPage
  switch(role){
    case 'Admin':
    case 'AreaMgr':
    case 'GenMgr':
    case 'FranchiseAreaMgr':
    case 'FranchiseGenMgr':
      initialPage = 'actions'
      break;
    default:
      initialPage = 'report'
  }

  // if(role === 'Admin' || role ==='AreaMgr' || role === 'GenMgr'){
  //   initialPage = 'actions'
  // } else {
  //   initialPage = 'report'
  // }

  const [r0003Page, setR0003Page] = useState(initialPage)
  const [pageWidth, setPageWidth] = useState('calc(1378px + 1rem')

  useEffect(() => {
    console.log('r0003Page', r0003Page)
    if(r0003Page === 'incentive'){
      const width = (75 * 11)
      const calcWidth = `${width}px`
      setPageWidth(calcWidth)
    } else {
      setPageWidth('calc(1378px + 1rem')
    }
  }, [r0003Page])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox 
        sx={{ 
          width: pageWidth,
          // width: 'calc(1225px + 1rem)',
          mx: 'auto'
        }}
      >
        <Card
          sx={{
            bgcolor: '#f9e9d3'
          }}
        >
          { 
            r0003Page === 'actions' && 
            <MDTypography variant='h1'>
              <R0003Actions setPage={(page) => setR0003Page(page)}/>
            </MDTypography>
          }
          {
            r0003Page === 'projections-admin' &&
            <MDTypography variant='h1'>
              <R0003Projections setPage={(page) => setR0003Page(page)}/>
            </MDTypography>
          }
          {
            r0003Page === 'projections' &&
            <MDTypography variant='h1'>
              <R0003ProjectionsOriginal setPage={(page) => setR0003Page(page)}/>
            </MDTypography>
          }
          {
            r0003Page === 'training' &&
            <MDTypography variant='h1'>
              <R0003Training setPage={(page) => setR0003Page(page)}/>
            </MDTypography>
          }
          {
            r0003Page === 'incentive' &&
            <MDTypography variant='h1'>
              <R0003Incentive setPage={(page) => setR0003Page(page)}/>
            </MDTypography>
          }
          {
            r0003Page === 'report' &&
            <MDTypography variant='h1'>
              <R0003Report setPage={(page) => setR0003Page(page)}/>
            </MDTypography>
          }
        </Card>
      </MDBox>
      <RDFooter />
    </DashboardLayout>
  )
}