import { useMemo } from "react";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import configs from './configs'

// Material Dashboard 2 PRO React base styles
import colors from "assets/theme/base/colors";


export default function R0004Chart({
  chart,
  icon,
  height,
}){

  const chartDatasets = chart.datasets 
    ? chart.datasets.map((dataset) => ({
        ...dataset,
        tension: 0,
        pointRadius: 2,
        borderWidth: 3,
        backgroundColor: 'transparent',
        fill: true,
        pointBackgroundColor: colors[dataset.color] 
          ? colors[dataset.color || 'dark'].main
          : colors.dark.main,
        borderColor: colors[dataset.color]
          ? colors[dataset.color || 'dark'].main
          : colors.dark.main,
        maxBarThickness: 6,
      }))
    : [];

  const { data, options } = configs(chart.labels || [], chartDatasets)

  const renderChart =  (
    <MDBox
      py={2}
      pr={2}
      pl={1}
      width='100%'
      sx={{
        // display: 'flex',
        // flex: '1 1 50%'
      }}
    >
      {useMemo(
        () => (
          // <MDBox height={height}>
          <MDBox 
            sx={{ 
              height: height === 0 ? '300px' : height,
              width: '100%'
            }}
          >
            <Line data={data} options={options} />
          </MDBox>
        ),
        [chart]
      )}
    </MDBox>
  )

  return renderChart
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  interaction: {
    intersect: false,
    mode: "index",
  },
  scales: {
    y: {
      grid: {
        drawBorder: false,
        display: true,
        drawOnChartArea: true,
        drawTicks: false,
        borderDash: [5, 5],
        color: "#c1c4ce5c",
      },
      ticks: {
        display: true,
        padding: 10,
        color: "#9ca2b7",
        font: {
          size: 14,
          weight: 300,
          family: "Roboto",
          style: "normal",
          lineHeight: 2,
        },
      },
    },
    x: {
      grid: {
        drawBorder: false,
        display: true,
        drawOnChartArea: true,
        drawTicks: true,
        borderDash: [5, 5],
        color: "#c1c4ce5c",
      },
      ticks: {
        display: true,
        color: "#9ca2b7",
        padding: 10,
        font: {
          size: 14,
          weight: 300,
          family: "Roboto",
          style: "normal",
          lineHeight: 2,
        },
      },
    },
  },
}