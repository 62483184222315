import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// AWS Amplify components
import { API } from 'aws-amplify'
import { dbGetItem, getPriceIncreaseList } from 'graphql/queries'

// @MUI Components
import { Tab, Tabs } from '@mui/material'

// Material Dashboard 2 components
import MDBox from 'components/MDBox'

// RadixDash components
import PriceIncrease from './PriceIncrease'
import ReportAdminHeader from "../ReportAdminHeader"
import RDTabPanel from 'components/RDTabPanel'
import ThresholdSettings from './Thresholds/ThresholdSettings'
import ThresholdAssignments from './Thresholds/ThresholdAssignments'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

function getTHObj(thresholdArr, thresholdId){
  const thIndex = thresholdArr.findIndex(x => x._id === thresholdId)

  const thItem = thresholdArr[thIndex]

  let thObj = {}
  if(thIndex !== -1){
    thObj._id = thItem._id
    thObj.name = thItem.name
    thObj.lowValue = thItem.lowValue
    thObj.highValue = thItem.highValue
  }
  return thObj
}

function buildAssignmentTable(rawData, thresholds){
  console.log('buildAssignmentTable rawData', rawData)
  console.log('buildAssignmentTable thresholds', thresholds)

  let tableArr = []
  let tableArrIdx = 0

  for(let i = 0; i < rawData.length; i++){
    // Build Area Title Row
    const area = rawData[i].areaName
    tableArr[tableArrIdx] = {
      area: area,
      store: area
    }
    tableArrIdx += 1
    // Build Store Rows
    const storeArr = rawData[i].stores
    for(let k = 0; k < storeArr.length; k++){
      const store = storeArr[k]

      // Get the threshold values
      let avgCheck
      let netSales
      let splhBOH
      let splhFOH
      let splhTotal

      for(let j = 0; j < thresholds.length; j++){
        const thresholdArr = thresholds[j].thresholds
        switch(thresholds[j].type){
          case 'avgCheck':
            avgCheck = getTHObj(thresholdArr, store.avgCheck)
            break;
          case 'netSales':
            netSales = getTHObj(thresholdArr, store.netSales)
            break;
          case 'splhBOH':
            splhBOH = getTHObj(thresholdArr, store.splhBOH)
            // console.log('splhBOH', splhBOH)
            break;
          case 'splhFOH':
            splhFOH = getTHObj(thresholdArr, store.splhFOH)
            // console.log('splhFOH', splhFOH)
            break;
          case 'splhTotal':
            splhTotal = getTHObj(thresholdArr, store.splhTotal)
            break;
          default:
            console.log('error: invalid TH type')
        }
      }

      // Build Store Row
      tableArr[tableArrIdx] = {
        area: area,
        storeId: store.storeId,
        store: store.store,
        netSalesId: netSales._id,
        netSalesName: netSales.name,
        netSalesLow: netSales.lowValue,
        netSalesHigh: netSales.highValue,
        avgCheckId: avgCheck._id,
        avgCheckName: avgCheck.name,
        avgCheckLow: avgCheck.lowValue,
        avgCheckHigh: avgCheck.highValue,
        splhTotalId: splhTotal._id,
        splhTotalName: splhTotal.name,
        splhTotalLow: splhTotal.lowValue,
        splhTotalHigh: splhTotal.highValue,
        splhBOHId: splhBOH._id,
        splhBOHName: splhBOH.name,
        splhBOHLow: splhBOH.lowValue,
        splhBOHHigh: splhBOH.highValue,
        splhFOHId: splhFOH._id,
        splhFOHName: splhFOH.name,
        splhFOHLow: splhFOH.lowValue,
        splhFOHHigh: splhFOH.highValue,
      }
      tableArrIdx += 1
    }
  }
  console.log('tableArr', tableArr)
  return tableArr
}

export default function R0002Settings(props){

  const [area, setArea] = useState('All')
  const [thresholds, setThresholds] = useState([])
  const [rawAssignmentData, setRawAssignmentData] = useState([])
  const [assignments, setAssignments] = useState([])
  const [filteredAssignments, setFilteredAssignments] = useState([])
  const [tabValue, setTabValue] = useState(0)

  useEffect(() => {
    getThresholds()
    // getPrices()
  }, [])

  async function getAssignmentData(){
    console.log('getAssignmentData')
    const assignmentVars = {
      report: "R0002-Threshold-Assignments"
    }

    const assignmentVarsJSON = JSON.stringify(assignmentVars)
    try {
      const queryResponse = await API.graphql({
        query: dbGetItem,
        variables: {
          db: 'grm.areas',
          dbVariables: assignmentVarsJSON
        }
      })
      console.log('queryResponse', queryResponse)
      const responseData = JSON.parse(JSON.parse(queryResponse.data.dbGetItem.statusItem))
      console.log('responseData', responseData)
      setRawAssignmentData(responseData)
      
    } catch (err) {
      console.log('Error fetching THRESHOLDS', err)
    }
  }

  useEffect(() => {
    console.log('thresholds changed', thresholds)
    if(thresholds.length > 0){      
      getAssignmentData()      
    }
  }, [thresholds])

  useEffect(() => {
    console.log('rawAssignmentData changed', rawAssignmentData)
    if(rawAssignmentData.length > 0){
      const tableData = buildAssignmentTable(rawAssignmentData, thresholds)
      console.log('tableData', tableData)
      setAssignments(tableData)
    }
  }, [rawAssignmentData])

  useEffect(() => {
    console.log('assignments changed', assignments)
    if(assignments.length > 0){
      if(area === 'All'){
        setFilteredAssignments(assignments)
      } else {
        const tableData = assignments.filter(x => x.area === area)
        console.log('tableData', tableData)
        setFilteredAssignments(tableData)
      }
    }
  }, [area, assignments])

  const dbVariablesTH = {
    client: props.user.client,
    report: 'R0002',
    itemType: 'thresholds',
    type: []
  }

  const dbVarJSON = JSON.stringify(dbVariablesTH)

  async function getThresholds(){
    console.log('getThresholds()...')
    try {
      const queryResponse = await API.graphql({
        query: dbGetItem,
        variables: {
          db: 'grm.reportadmins',
          dbVariables: dbVarJSON
        }
      })
      console.log('queryResponse', queryResponse)
      const responseData = JSON.parse(JSON.parse(queryResponse.data.dbGetItem.statusItem))
      console.log('responseData', responseData)
      setThresholds(responseData)
    } catch (err) {
      console.log('Error fetching THRESHOLDS', err)
    }
  }

  function updateThresholds(newThresholds){
    console.log('updateThresholds', newThresholds)
    setThresholds(newThresholds)
  }

  function updateAssignments(newAssignments){
    console.log('updateAssignments', newAssignments)
    const updatedArea = newAssignments[0].areaName
    console.log('updatedArea', updatedArea)
    let tempAssignments = [...rawAssignmentData]
    console.log('tempAssignments', tempAssignments)
    const tempIdx = tempAssignments.findIndex(x => x.areaName === updatedArea)
    console.log('tempIdx', tempIdx)
    tempAssignments[tempIdx] = newAssignments[0]
    console.log('tempAssignments NEW', tempAssignments)
    setRawAssignmentData(tempAssignments)
  }

  function handleAreaChange(newArea){
    console.log('area changed to', newArea)
    setArea(newArea)
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  return (
    <MDBox component='div'>
      <MDBox
        mx='auto'
        my={3}
        // width='350px'
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          centered
        >
          <Tab
            label='Threshold Settings'
          />
          <Tab
            label='Threshold Assignments'
          />
        </Tabs>
        <RDTabPanel
          value={tabValue}
          index={0}
        >
          <ThresholdSettings 
            data={ thresholds } 
            update={ updateThresholds }
          />
        </RDTabPanel>
        <RDTabPanel
          value={tabValue}
          index={1}
        >
          <ThresholdAssignments 
            area={ area }
            data={ assignments }
            filteredData={ filteredAssignments }
            handleAreaChange={ handleAreaChange }
            thresholds={ thresholds }
            update={ updateAssignments }
          />
        </RDTabPanel>
      </MDBox>
    </MDBox>
  )
}

R0002Settings.propTypes = {
  setPage: PropTypes.func
}