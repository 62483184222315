import React from 'react'
import PropTypes from 'prop-types'

// @mui components
import { Dialog, DialogTitle, DialogContent, Grid } from '@mui/material'

// formik components
import { Formik, Form } from 'formik'
import Textfield from 'components/FormsUI/Textfield'
import * as Yup from 'yup'

// Material Dashboard Pro 2 components
import MDButton from 'components/MDButton'

export default function DialogAddTH(props){

  function handleSubmit(values){
    let newValues = {...values}
    switch(props.tableType){
      case 'avgCheck':
      case 'splhTotal':
      case 'splhBOH':
      case 'splhFOH':
        newValues.lowValue = values.lowValue * 100
        newValues.highValue = values.highValue * 100
        break;
      default:
        // no action
    }
    props.submit(newValues)
  }

  const INITIAL_FORM_STATE = {
    name: '',
    lowValue: '',
    highValue: ''
  }

  const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string()
      .required('Required'),
    lowValue: Yup.number()
      .required('Required'),
    highValue: Yup.number()
      .required('Required')
  })

  return (
    <Dialog
      open={ props.open }
    >
      <DialogTitle>Add Threshold for {props.title}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={INITIAL_FORM_STATE}
          validationSchema={FORM_VALIDATION}
          onSubmit={ (values) => { handleSubmit(values) }}
        >
          {({ isValid, dirty }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} 
                  // Included to give spacing to 'role' Texfield
                />

                <Grid item xs={12}>
                  <Textfield 
                    name='name'
                    label='Name'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Textfield 
                    name='lowValue'
                    label='Low Value'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Textfield 
                    name='highValue'
                    label='High Value'
                  />
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={3}>
                  <MDButton variant='contained' color='info' onClick={ props.close }>
                    Cancel
                  </MDButton>
                </Grid>
                <Grid item xs={3}>
                  <MDButton variant='contained' color='info' type='submit' disabled={!(dirty && isValid)}>
                    Submit
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

// AddRoleDialog.propTypes = {
//   close: PropTypes.func,
//   existingRoles: PropTypes.array,
//   open: PropTypes.bool,
//   submit: PropTypes.func,
// }