import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

// MUI components
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'

// react-table components
import {
  useBlockLayout,
  useTable
} from 'react-table'

// react-window components
import {
  VariableSizeList
} from 'react-window'

import { useWindowResize } from './useWindowResize'

// RadixDash components
import RDStickyBodyCell from './RDStickyBodyCell'
import RDStickyHeadCell from './RDStickyHeadCell'
import scrollbarWidth from './scrollbarWidth'



export default function RDReportTable({
  columns,
  data,
  rowSizes,
  sizeFunc,
  styleFunc,
  cellOptions,
}){

  const listRef = useRef({})
  const rowHeights = useRef({})

  useEffect(() => {
    const heightObj = rowSizes.reduce( (prev, cur, idx) => {
      return ({...prev, [idx]:cur})
    }, {} )
    rowHeights.current = {...heightObj}
    listRef.current.resetAfterIndex(0)
  }, [rowSizes])

  function getRowHeight(index){
    return rowHeights.current[index] || 18
  }

  const defaultColumn = useMemo(() => ({
    width: 50,
  }), [])

  const scrollBarSize = useMemo(() => scrollbarWidth(), [])

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useBlockLayout
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    totalColumnsWidth,
    prepareRow
  } = tableInstance

  const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

  const getItemSize = index => {
    console.log('getItemSize')
    const rowData = rows[index].original

    let size = sizeFunc(rowData)
    
    return size
  }

  const RenderRow = useCallback(
    ({
      // data,
      index,
      // setSize,
      // windowWidth,
      style
    }) => {
      const row = rows[index]
      if(row.original.store === ''){
        return null
      } else {
        prepareRow(row)
        return (
          <TableRow 
            {...row.getRowProps({ style })}
          >
            {row.cells.map( (cell, index) => {
              return (
                <RDStickyBodyCell
                  {...cell.getCellProps()}
                  width={cell.column.width}
                  styleFunc={styleFunc}
                  options={cellOptions}
                >
                  {cell.render('Cell')}
                </RDStickyBodyCell>
              )
            })}
          </TableRow>
        )
      }    
    }, [prepareRow, rows]
  )

  return (
    <TableContainer>
      <Table
        { ...getTableProps() }
        className='table'
        sx={{
          backgroundColor: '#e0e0e0'
        }}
      >
        <TableHead
          sx={{
            padding: 0,
            width: 'calc(100% - 1rem)',
          }}
        >
          {headerGroups.map(headerGroup => (
            <TableRow
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map(column => (
                <RDStickyHeadCell 
                  {...column.getHeaderProps()}
                  style={column.columnStyle}
                  width={column.width}
                  colSpan={column.colSpan}
                  sx={{
                    padding: '0px',
                    textAlign: 'center'
                  }}
                >
                  {column.render('Header')}
                </RDStickyHeadCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody
          {...getTableBodyProps()}
        >
          <VariableSizeList
            height={Math.max(vh - 300, 450)}
            itemCount={rows.length}
            width={totalColumnsWidth + scrollBarSize}
            itemSize={ getRowHeight }
            overscanCount={10}
            ref={ listRef }
          >
            { RenderRow }
          </VariableSizeList>
        </TableBody>
      </Table>
    </TableContainer>
  )

}

RDReportTable.propTypes = {
  cellOptions: PropTypes.object,
  columns: PropTypes.array,
  data: PropTypes.array,
  rowSizes: PropTypes.array,
  sizeFunc: PropTypes.func,
  styleFunc: PropTypes.func,
}