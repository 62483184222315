import React from 'react'
import { Box } from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

import { USDollar } from '../r0005Utils'
import { sortObjectArray } from 'utils/helperUtils'

function R0005BodyCell({
  cellProps,
  isTotalRow,
  cell,
  style,
  children,
  ...rest
}){
  const row = cell.row.original
  const columnId = cell.column.id

  const format = getCellFormat(row, columnId, style)
  
  return (
    <Box
      component='td'
      style={ cellProps }
      py={.5}
      px={.5}
      sx={{
        fontWeight: isTotalRow ? 'bold' : format.fontWeight,
        borderTop: isTotalRow ? '2px solid black' : null,
        borderBottom: isTotalRow ? '2px solid black' : '1px dashed grey',
        backgroundColor: format.backgroundColor,
        color: format.color,
      }}
    >
      <Box
        {...rest}
        position='relative'
        textAlign={ cellProps.align }
        sx={{
          verticalAlign: 'middle',
        }}
      >
        {columnId.startsWith('fcst') ? (
          <CellTooltip title={format.varianceStr}>
            <div>
              {isNaN(children) && columnId !== 'store' ? USDollar.format(0) : cellProps.currency ? USDollar.format(children) : children}
            </div>
          </CellTooltip>
        ) : (
          isNaN(children) && columnId !== 'store' ? USDollar.format(0) : cellProps.currency ? USDollar.format(children) : children
        )}
      </Box>
    </Box>
  )
}

export default React.memo(R0005BodyCell)

function getCellFormat(rowObject, cellId, bands){
  // Baseline styles
  let backgroundColor = '#fff';
  let color = '#000'
  let fontWeight = 'normal'
  
  const { variancePct, varianceStr } = getVariance(rowObject, cellId)

  if(cellId.startsWith('fcst') && bands && variancePct !== null){
    const sortedBands = sortObjectArray(bands, 'position')    

    for(let i = 0; i < sortedBands.length; i++){
      const band = sortedBands[i]
      
      let matchFound = false

      switch (band.operator) {
        case 'EQ':
          if(variancePct === band.numberValue[0]){
            matchFound = true
          }
          break;
        case 'NEQ':
          if(variancePct !== band.numberValue[0]){
            matchFound = true
          }
          break;
        case 'GT':
          if(variancePct > band.numberValue[0]){
            matchFound = true
          }
          break;
        case 'GTE':
          if(variancePct >= band.numberValue[0]){
            matchFound = true
          }
          break;
        case 'LT':
          if(variancePct < band.numberValue[0]){
            matchFound = true
          }
          break;
        case 'LTE':
          if(variancePct <= band.numberValue[0]){
            matchFound = true
          }
          break;
        default: // BTW
          if( variancePct >= band.numberValue[0] &&
              variancePct <= band.numberValue[1]){
              matchFound = true
          }
      }

      if(matchFound) {
        backgroundColor = band.backgroundColor
        color = band.color
        fontWeight = band.fontWeight
        break;
      }
    }    
  }

  return {
    backgroundColor,
    color,
    fontWeight,
    varianceStr,
  }

}

function getVariance(row, cellId){
  const actKey = cellId.replace('fcst', 'act')
  const actValue = row[actKey]
  const fcstValue = row[cellId]

  let variancePct

  if(actValue === undefined || isNaN(actValue)){
    variancePct = null
  } else {
    variancePct = (fcstValue - actValue) / actValue * 100
  }

  let varianceStr
  if(variancePct){
    varianceStr = `${variancePct.toFixed(0)}%`;
  } else {
    varianceStr = ''
  }

  return {
    variancePct,
    varianceStr
  }
}

const CellTooltip = styled(({ className, ...props }) => (
  <Tooltip 
    {...props} 
    classes={{ popper: className }}  
    placement='top-start' 
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'yellow',
    color: 'black',
    fontWeight: 'bold',
    fontSize: '.7rem',
    boxShadow: '0 0 0 2px black'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'yellow'
  }
}))