import React from 'react'
import PropTypes from "prop-types";
import { TextField, MenuItem } from '@mui/material'
// import { TextField, MenuItem } from '@material-ui/core'
import { useField, useFormikContext } from 'formik'

const SelectWrapper = ({
  name,
  options,
  ...otherProps
}) => {
  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(name)

  const handleChange = evt => {
    const { value } = evt.target
    setFieldValue(name, value)
  }

  const configSelect = {
    ...field,
    ...otherProps,
    select: true,
    variant: 'outlined',
    fullWidth: true,
    size: 'medium',
    onChange: handleChange
  }

  if(meta && meta.touched && meta.error){
    configSelect.error = true
    configSelect.helperText = meta.error
  }

  return (
    <TextField {...configSelect} >
      { Object.keys(options).map((item, pos) => {
        return (
          <MenuItem key={pos} value={item}>
            {options[item]}
          </MenuItem>
        )
      })}
    </TextField>
  )
}

export default SelectWrapper

SelectWrapper.propTypes = {
  name: PropTypes.string,
  options: PropTypes.object,
}