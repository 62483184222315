import { API } from 'aws-amplify'
import { createThresholdMapping } from 'graphql/mutations'

export default async function createAssignment(assignment) {
  console.log('assignment', assignment)

  try {
    const response = await API.graphql({
      query: createThresholdMapping,
      variables: {
        input: assignment
      }
    })

    console.log('response', response)
  } catch (err) {
    console.log('Error creating assignment', err)
  }
}