import React, { useState } from 'react'
import PropTypes from 'prop-types'

// @mui components
import { Dialog, DialogTitle, DialogContent, Grid, Stack } from '@mui/material'

// Formik components
import { Formik, Form, useFormik } from 'formik'
import * as Yup from 'yup'

// Material Dashboard Pro 2 components
import MDTypography from 'components/MDTypography'

// RadixDash components
import Button_GrmDark from 'components/RDButton/Button_GrmDark'
import Textfield from 'components/FormsUI/Textfield'

export default function R0003AddNoteDialog(props){

  const [data, setData] = useState({
    monNotes: '',
    tueNotes: '',
    wedNotes: '',
    thuNotes: '',
    friNotes: '',
    satNotes: '',
    sunNotes: ''
  })

  const FORM_VALIDATION = Yup.object().shape({

  })

  async function handleSubmit(values) {
    props.submit(values)
  }

  function handleCancel() {
    props.cancel()
  }

  return (
    <Dialog
      open={ props.open }
      maxWidth='xs'
      fullWidth={ true }
    >
      <DialogTitle sx={{ backgroundColor: '#29261F'}}>
        <MDTypography variant='h5' style={{color: '#CF9E0B'}}>
          Notes: { props.store }
        </MDTypography>
      </DialogTitle>
      <DialogContent sx={{ bgcolor: '#f9e9d3' }}>
        <Formik
          initialValues={ props.notes }
          validationSchema={ FORM_VALIDATION }
          enableReinitialize={ true }
          onSubmit={ (values) => {
            handleSubmit(values)
          }}
        >
          { props => (
            <Form>
              <Stack direction='column' spacing={2} mx={3} pt={2}>
                <Textfield 
                  name='monNotes'
                  label='Monday Notes'
                  multiline
                  maxRows={3}
                />
                <Textfield 
                  name='tueNotes'
                  label='Tuesday Notes'
                  multiline
                  maxRows={3}
                />
                <Textfield 
                  name='wedNotes'
                  label='Wednesday Notes'
                  multiline
                  maxRows={3}
                />
                <Textfield 
                  name='thuNotes'
                  label='Thursday Notes'
                  multiline
                  maxRows={3}
                />
                <Textfield 
                  name='friNotes'
                  label='Friday Notes'
                  multiline
                  maxRows={3}
                />
                <Textfield 
                  name='satNotes'
                  label='Saturday Notes'
                  multiline
                  maxRows={3}
                />
                <Textfield 
                  name='sunNotes'
                  label='Sunday Notes'
                  multiline
                  maxRows={3}
                />
                <Stack direction='row' spacing={2} justifyContent='flex-end'>
                  <Button_GrmDark onClick={ handleCancel } fullWidth>Cancel</Button_GrmDark>
                  <Button_GrmDark type='submit' fullWidth>Submit</Button_GrmDark>
                </Stack>
              </Stack>
            </Form>
          )}

        </Formik>
      </DialogContent>

    </Dialog>
  )
}

R0003AddNoteDialog.propTypes = {
  cancel: PropTypes.func,
  notes: PropTypes.object,
  open: PropTypes.bool,
  store: PropTypes.string,
  submit: PropTypes.func,
}