import React from 'react'
import PropTypes from 'prop-types'

// @mui components
import { CircularProgress, Dialog, DialogTitle, DialogContent, Grid } from '@mui/material'

import MDBox from 'components/MDBox'

export default function RDProgress(props) {

  return (
    <Dialog
      open={ props.open }
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none'
        }
      }}
    >
      <MDBox sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        width: '80vw',
        height: '80vh' }}>    
        <CircularProgress size={100} color='info' />      
      </MDBox>
      {/* <DialogTitle>RDProgress</DialogTitle> */}
    </Dialog>
  )
}

RDProgress.propTypes = {
  open: PropTypes.bool
}