import { AGGREGATION_TYPES, METRIC_TYPES } from '../../../../constants';
import { formatPercent } from '../../../../utils/formatters';

export const netSalesMetrics = {
  cNSTotal: { 
    type: AGGREGATION_TYPES.SUM,
    metricType: METRIC_TYPES.NET_SALES 
  },
  pNSTotal: {
    type: AGGREGATION_TYPES.SUM,
    metricType: METRIC_TYPES.NET_SALES
  },
  cNSDineIn: {
    type: AGGREGATION_TYPES.SUM,
    metricType: METRIC_TYPES.NET_SALES 
  },
  cNSToGo: { 
    type: AGGREGATION_TYPES.SUM,
    metricType: METRIC_TYPES.NET_SALES 
  },
  cNSDelivery: { 
    type: AGGREGATION_TYPES.SUM,
    metricType: METRIC_TYPES.NET_SALES 
  },
  cGSDelivery: { 
    type: AGGREGATION_TYPES.SUM,
    metricType: METRIC_TYPES.NET_SALES 
  },
  pct: {
    type: AGGREGATION_TYPES.CALCULATED,
    metricType: METRIC_TYPES.NET_SALES,
    inputs: ['pNSTotal', 'cNSTotal'],
    calculate: (pns, cns) => formatPercent( (cns - pns) / pns * 100)
  }
}