import { styled } from '@mui/system'
import { Autocomplete } from '@mui/material'

const AutocompleteDemDark = styled(Autocomplete)(({theme}) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#0091d5'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: '2px solid #0091d5',
  },
  '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #b9ad9c'
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '2px solid #0091d5'
  },
  '& .MuiAutocomplete-inputFocused': {
    bgcolor: '#0091d5'
  },
  '& .MuiAutocomplete-endAdornment': {
    '& .MuiIconButton-root': {
      color: '#0091d5'
    },
    '& .Mui-disabled': {
      color: '#b9ad9c'
    }
  },
  '& .MuiFormLabel-root': {               
    color: '#0091d5'
  },
  '& .MuiInputBase-input': {
    color: '#0091d5',
  },
  '& .Mui-disabled': {
    color: '#b9ad9c'
  }
}))

export default AutocompleteDemDark