// import React from 'react'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import R0002CellStyle from './R0002CellStyle'
import { format } from 'date-fns'

function R0002ExportPdf( data, curStart, curEnd, compStart, compEnd, priceIncrease, priceIncreaseSwitch ){

  let sDate = format(curStart, 'M/d/yy')
  let eDate = format(curEnd, 'M/d/yy')

  const title = `Summary Report: ${sDate} - ${eDate}`
  let doc = new jsPDF({
    orientation: 'landscape',
    unit: 'pt',
    format: 'letter'
  })

  let fullDataArr = []
  console.log('data', data)
  
  for(let i = 0; i < data.length; i++){
    const fullData = [
      data[i].store,
      data[i].tipNSTotal,
      data[i].cNSTotal,
      data[i].pct,
      data[i].cNSDineIn,
      data[i].cNSToGo,
      data[i].cNSDelivery,
      data[i].cGSDelivery,
      data[i].cCCTotal,
      data[i].cCCDineIn,
      data[i].cCCToGo,
      data[i].cCCDelivery,
      data[i].cACTotal,
      data[i].cACDineIn,
      data[i].cACToGo,
      data[i].cACDelivery,
      data[i].cSPLHTotal,
      data[i].cFOH,
      data[i].cBOH,
      data[i].cLaborPct,
      data[i].tipNSDineIn,
      data[i].tipNSToGo,
      data[i].tipNSDelivery,
      data[i].tipGSDelivery,
      data[i].tipCCTotal,
      data[i].tipCCDineIn,
      data[i].tipCCToGo,
      data[i].tipCCDelivery,
      data[i].tipACTotal,
      data[i].tipACDineIn,
      data[i].tipACToGo,
      data[i].tipACDelivery,
      data[i].tipSPLHTotal,
      data[i].tipFOH,
      data[i].tipBOH,
      data[i].tipLaborPct,
      data[i].rowType
    ]
    fullDataArr.push(fullData)
  }

  console.log('fullDataArr', fullDataArr)

  doc.autoTable({
    head: [
      [
        {
          content: title,
          colSpan: 20,
          styles: {
            // valign: 'center',
            halign: 'left',
            fillColor: [41, 38, 31],
            textColor: [207, 158, 11],
            cellPadding: 1,
            fontSize: 15
          }
        }
      ],
      [
        {
          content: 'Store',
          rowSpan: 2,
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [224, 224, 224],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold'
          }
        },
        {
          content: 'NET SALES',
          colSpan: 7,
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        
        },
        {
          content: 'CHECK COUNTS',
          colSpan: 4,
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [224, 224, 224],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          }
        },
        {
          content: 'AVERAGE CHECK',
          colSpan: 4,
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        {
          content: 'SALES PER LABOR HOUR',
          colSpan: 3,
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [224, 224, 224],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          }
        },
        {
          content: 'Labor %',
          rowSpan: 2,
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        }
      ],
      [
        { 
          content: 'PY Net',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          }  
        },
        { 
          content: 'CY Net',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          }  
        },
        { 
          content: '%',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          }  
        },
        { 
          content: 'Dine In',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { 
          content: 'To Go',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { 
          content: 'Net Delvry',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { 
          content: 'Grs Delvry',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { 
          content: 'Total',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [224, 224, 224],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { 
          content: 'Dine In',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [224, 224, 224],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { 
          content: 'To Go',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [224, 224, 224],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { 
          content: 'Delvry',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [224, 224, 224],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { 
          content: 'Total',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { 
          content: 'Dine In',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { 
          content: 'To Go',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { 
          content: 'Delvry',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { 
          content: 'Total',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [224, 224, 224],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { content: 'FOH',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [224, 224, 224],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { content: 'BOH',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [224, 224, 224],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
      ]
    ],
    body: fullDataArr,
    headStyles: {
      fontSize: 5,
    },
    bodyStyles: {
      fontSize: 6,
    },
    columnStyles: {
      1: {
        lineColor: '576574',
        lineWidth: { left: 1 }
      }
    },
    didParseCell: function(data) {
      
      if(data.row.section === 'body'){
        const dataObj = {}
        for(let i = 0; i < data.row.raw.length; i++){
          const field = getField(i)
          dataObj[field] = data.row.raw[i]
        }

        const dataObj2 = {
          original: dataObj
        }
        
        const columnNum = data.column.index
        const field = getField(columnNum)
        const cell = {
          column: {
            id: field
          }
        }
        const options = [
          {
            option: 'priceIncrease',
            value: priceIncrease
          },
          {
            option: 'priceIncreaseSwitch',
            value: priceIncreaseSwitch
          }
        ]
        const setting = R0002CellStyle(dataObj2, cell, options)

        data.cell.styles.cellPadding = .5
        data.cell.styles.fontStyle = setting.fontWeight
        data.cell.styles.halign = setting.textAlign
        data.cell.styles.lineColor = [40, 40, 40]
        data.cell.styles.lineWidth = .25

        switch(setting.backgroundColor){
          case 'grey':
            data.cell.styles.fillColor = [189, 189, 189]
            data.cell.styles.textColor = '#000000'
            break;
          case 'green':
            data.cell.styles.fillColor = [0, 128, 0]
            data.cell.styles.textColor = '#ffffff'
            break;
          case 'red':
            data.cell.styles.fillColor = [255, 0, 0]
            data.cell.styles.textColor = '#ffffff'
            break;
          case 'white':
          case '':
            data.cell.styles.fillColor = [255, 255, 255]
            data.cell.styles.textColor = '#000000'
            break;
          default:
            data.cell.styles.fillColor = setting.backgroundColor
            if(setting.color !== ''){
              data.cell.styles.textColor = setting.color
              // data.cell.styles.lineColor = setting.color
              // data.cell.styles.lineWidth = 0            
            } else {
              data.cell.styles.textColor = '#000000'
            }
        }



      }
    }
  })

  doc.save('output.pdf')

}

export default R0002ExportPdf

function getField(colIdx){
  
  switch(colIdx){
    case 0:  return 'store'      
    case 1:  return 'tipNSTotal'   
    case 2:  return 'cNSTotal'   
    case 3:  return 'pct'        
    case 4:  return 'cNSDineIn'
    case 5:  return 'cNSToGo'
    case 6:  return 'cNSDelivery'
    case 7: return 'cGSDelivery'
    case 8: return 'cCCTotal'
    case 9: return 'cCCDineIn'
    case 10: return 'cCCToGo'
    case 11: return 'cCCDelivery'
    case 12: return 'cACTotal'
    case 13: return 'cACDineIn'
    case 14: return 'cACDelivery'
    case 15: return 'cACToGo'
    case 16: return 'cSPLHTotal'
    case 17: return 'cFOH'
    case 18: return 'cBOH'
    case 19: return 'cLaborPct'
    case 20:  return 'tipNSDineIn'
    case 21:  return 'tipNSToGo'
    case 22:  return 'tipNSDelivery'
    case 23: return 'tipGSDelivery'
    case 24: return 'tipCCTotal'
    case 25: return 'tipCCDineIn'
    case 26: return 'tipCCToGo'
    case 27: return 'tipCCDelivery'
    case 28: return 'tipACTotal'
    case 29: return 'tipACDineIn'
    case 30: return 'tipACToGo'
    case 31: return 'tipACDelivery'
    case 32: return 'tipSPLHTotal'
    case 33: return 'tipFOH'
    case 34: return 'tipBOH'
    case 35: return 'tipLaborPct'
    case 36: return 'rowType'
    default: return ''
  }
}
