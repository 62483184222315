export default function mergePOSData(rawData, toastData, storeMap) {
  // Handle case where rawData is empty/null/undefined
  const safeRawData = Array.isArray(rawData) ? rawData : [];

  const shortNameLookup = new Map()
  storeMap.forEach((value) => {
    shortNameLookup.set(value.shortName, value)
  })

  // Initialize result array
  const result = [];

  // Process company and franchise data separately
  ['company', 'franchise'].forEach(type => {
    const rawItem = safeRawData.find(item => item.pageId?.includes(`r0002_LSD_${type}`));
    const toastItem = toastData.find(item => item.pageId?.includes(`r0002_LSD_${type}`));

    // Check if both sources have data
    const hasRawData = rawItem && !rawItem.pageId.includes('_empty') && rawItem.data && rawItem.data.length > 0;
    const hasToastData = toastItem && !toastItem.pageId.includes('_empty') && toastItem.data && toastItem.data.length > 0;

    // If neither source has data, add empty result
    if (!hasRawData && !hasToastData) {
      result.push({
        pageId: `r0002_LSD_${type}_empty`,
        data: []
      });
      return;
    }

    // Initialize merged data with raw data structure
    const mergedData = {
      pageId: hasRawData ? rawItem.pageId : `r0002_LSD_${type}`,
      data: []
    };

    const processStoreRow = (item) => {
      if (item.rowType === 'storeRow'  || item.rowType === 'otherStoreRow') {
        const storeInfo = shortNameLookup.get(item.rowData.store)
        const isEstablished = storeInfo?.status === 'Established'

        return {
          rowType: isEstablished ? 'storeRow' : 'otherStoreRow',
          rowData: {
            ...item.rowData,
            manager: isEstablished ? storeInfo.area : 'Others'
          }
        }
      }
      return item
    }

    if (hasRawData) {

      // First process the store rows to set correct types and managers
      mergedData.data = rawItem.data.map(processStoreRow)

      // If we have Toast data, merge it with the copied raw data
      if (hasToastData) {
        toastItem.data.forEach(toastStoreItem => {
          if (toastStoreItem.rowType === 'storeRow' || toastStoreItem.rowType === 'otherStoreRow') {
            const existingStoreIndex = mergedData.data.findIndex(
              item => (item.rowType === 'storeRow' || item.rowType === 'otherStoreRow') && 
                      item.rowData.store === toastStoreItem.rowData.store
            );

            if (existingStoreIndex !== -1) {
              // Merge Toast current year data with existing store data
              const existingStore = mergedData.data[existingStoreIndex];
              const mergedRowData = {
                ...existingStore.rowData, // Preserve all existing data (including previous year)
                // Only update current year fields from Toast
                cCCTotal: toastStoreItem.rowData.cCCTotal,
                cCCDineIn: toastStoreItem.rowData.cCCDineIn,
                cCCToGo: toastStoreItem.rowData.cCCToGo,
                cCCDelivery: toastStoreItem.rowData.cCCDelivery,
                cNSTotal: toastStoreItem.rowData.cNSTotal,
                cNSDineIn: toastStoreItem.rowData.cNSDineIn,
                cNSToGo: toastStoreItem.rowData.cNSToGo,
                cNSDelivery: toastStoreItem.rowData.cNSDelivery,
                cGSDelivery: toastStoreItem.rowData.cGSDelivery,
                cSPLHTotal: toastStoreItem.rowData.cSPLHTotal,
                cBOH: toastStoreItem.rowData.cBOH,
                cFOH: toastStoreItem.rowData.cFOH,
                cOTTotal: toastStoreItem.rowData.cOTTotal,
                cOTBOH: toastStoreItem.rowData.cOTBOH,
                cOTFOH: toastStoreItem.rowData.cOTFOH,
                cLaborPct: toastStoreItem.rowData.cLaborPct,
                cLaborDollars: toastStoreItem.rowData.cLaborDollars,
                cFOHHours: toastStoreItem.rowData._fohOvertimeHours + toastStoreItem.rowData._fohRegularHours,
                cBOHHours: toastStoreItem.rowData._bohOvertimeHours + toastStoreItem.rowData._bohRegularHours
              };

              // Calculate new metrics for current year data
              const parsedData = {
                ...mergedRowData,
                cCCTotal: parseNumberString(mergedRowData.cCCTotal),
                cCCDineIn: parseNumberString(mergedRowData.cCCDineIn),
                cCCToGo: parseNumberString(mergedRowData.cCCToGo),
                cCCDelivery: parseNumberString(mergedRowData.cCCDelivery),
                cNSTotal: convertCurrencyStringToNumber(mergedRowData.cNSTotal),
                cNSDineIn: convertCurrencyStringToNumber(mergedRowData.cNSDineIn),
                cNSToGo: convertCurrencyStringToNumber(mergedRowData.cNSToGo),
                cNSDelivery: convertCurrencyStringToNumber(mergedRowData.cNSDelivery),
                cGSDelivery: convertCurrencyStringToNumber(mergedRowData.cGSDelivery),
                cSPLHTotal: convertCurrencyStringToNumber(mergedRowData.cSPLHTotal),
                cBOH: convertCurrencyStringToNumber(mergedRowData.cBOH),
                cFOH: convertCurrencyStringToNumber(mergedRowData.cFOH),
                cOTTotal: convertCurrencyStringToNumber(mergedRowData.cOTTotal),
                cOTBOH: convertCurrencyStringToNumber(mergedRowData.cOTBOH),
                cOTFOH: convertCurrencyStringToNumber(mergedRowData.cOTFOH),
                cLaborPct: parsePercentString(mergedRowData.cLaborPct),
                pNSTotal: convertCurrencyStringToNumber(existingStore.rowData.pNSTotal)
              };

              // Calculate averages for current year
              parsedData.cACTotal = calculateAverageCheck(parsedData.cNSTotal, parsedData.cCCTotal);
              parsedData.cACDineIn = calculateAverageCheck(parsedData.cNSDineIn, parsedData.cCCDineIn);
              parsedData.cACToGo = calculateAverageCheck(parsedData.cNSToGo, parsedData.cCCToGo);
              parsedData.cACDelivery = calculateAverageCheck(parsedData.cNSDelivery, parsedData.cCCDelivery);

              parsedData.pct = calculatePercentageChange(parsedData.cNSTotal, parsedData.pNSTotal)

              // Format and update the store data
              mergedData.data[existingStoreIndex].rowData = formatStoreData(parsedData);
            }
          }
        });
      }
    } else if (hasToastData) {
      // If we only have Toast data, use its structure but process the data
      mergedData.data = toastItem.data.map(mapItem => {
        const item = processStoreRow(mapItem)
        if (item.rowType === 'storeRow' || item.rowType === 'otherStoreRow') {
          const parsedData = {
            ...item.rowData,
            cCCTotal: parseNumberString(item.rowData.cCCTotal),
            cCCDineIn: parseNumberString(item.rowData.cCCDineIn),
            cCCToGo: parseNumberString(item.rowData.cCCToGo),
            cCCDelivery: parseNumberString(item.rowData.cCCDelivery),
            cNSTotal: convertCurrencyStringToNumber(item.rowData.cNSTotal),
            cNSDineIn: convertCurrencyStringToNumber(item.rowData.cNSDineIn),
            cNSToGo: convertCurrencyStringToNumber(item.rowData.cNSToGo),
            cNSDelivery: convertCurrencyStringToNumber(item.rowData.cNSDelivery),
            cGSDelivery: convertCurrencyStringToNumber(item.rowData.cGSDelivery),
            cSPLHTotal: convertCurrencyStringToNumber(item.rowData.cSPLHTotal),
            cBOH: convertCurrencyStringToNumber(item.rowData.cBOH),
            cFOH: convertCurrencyStringToNumber(item.rowData.cFOH),
            cOTTotal: convertCurrencyStringToNumber(item.rowData.cOTTotal),
            cOTBOH: convertCurrencyStringToNumber(item.rowData.cOTBOH),
            cOTFOH: convertCurrencyStringToNumber(item.rowData.cOTFOH),
            cLaborPct: parsePercentString(item.rowData.cLaborPct),
            pNSTotal: convertCurrencyStringToNumber(item.rowData.pNSTotal),
            cFOHHours: item.rowData._fohOvertimeHours + item.rowData._fohRegularHours,
            cBOHHours: item.rowData._bohOvertimeHours + item.rowData._bohRegularHours
          };

          // Calculate averages
          parsedData.cACTotal = calculateAverageCheck(parsedData.cNSTotal, parsedData.cCCTotal);
          parsedData.cACDineIn = calculateAverageCheck(parsedData.cNSDineIn, parsedData.cCCDineIn);
          parsedData.cACToGo = calculateAverageCheck(parsedData.cNSToGo, parsedData.cCCToGo);
          parsedData.cACDelivery = calculateAverageCheck(parsedData.cNSDelivery, parsedData.cCCDelivery);

          parsedData.pct = calculatePercentageChange(parsedData.cNSTotal, parsedData.pNSTotal)

          // const storeStatus = storeMap.get(item.rowData.store)?.status

          return {
            // rowType: storeStatus === 'Established' ? 'storeRow' : 'otherStoreRow',
            rowType: item.rowType,
            rowData: formatStoreData(parsedData)
          };
        }
        return item;
      });
    }

    result.push(mergedData);
  });

  return result;
}

// Helper function to convert data to number
function convertCurrencyStringToNumber(currencyString) {
  // Check if the input is a string
  if (typeof currencyString === 'string') {
    // Remove currency symbol and commas, then convert to a float
    return parseFloat(currencyString.replace('$', '').replace(/,/g, ''));
  } else if (typeof currencyString === 'number') {
    // If it's already a number, just return it
    return currencyString;
  } else {
    // If it's neither a string nor a number (e.g., null or undefined), return 0
    return 0;
  }
}

function parseNumberString(numString) {
  if (typeof numString === 'string') {
    return parseInt(numString.replace(/,/g, ''))
  }
  if (typeof numString === 'number') {
    return numString
  }
  return 0
}

const parsePercentString = (percentString) => {
  if (typeof percentString === 'number') {
    return percentString / 100; // Already a number, just convert to decimal
  }
  if (typeof percentString === 'string') {
    // Remove % and convert to float, then divide by 100
    return parseFloat(percentString.replace('%', '')) / 100;
  }
  return 0; // Handle null/undefined/invalid input
}

// Helper function to calculate average check
const calculateAverageCheck = (netSales, checkCount) => {  
  return checkCount === 0 ? 0 : (netSales / checkCount)  
};

// Helper function to calculate percentage change
const calculatePercentageChange = (current, prior) => {
  if (prior === 0) return 0;
  return ((current - prior) / prior) * 100;
};


// Helper function to format store data
const formatStoreData = (data) => {
  return {
    ...data,
    cCCTotal: data.cCCTotal.toString(),
    cCCDineIn: data.cCCDineIn.toString(),
    cCCToGo: data.cCCToGo.toString(),
    cCCDelivery: data.cCCDelivery.toString(),
    cNSTotal: `$${data.cNSTotal.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`,
    pNSTotal: `$${data.pNSTotal.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`,
    cNSDineIn: `$${data.cNSDineIn.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`,
    cNSToGo: `$${data.cNSToGo.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`,
    cNSDelivery: `$${data.cNSDelivery.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`,
    cGSDelivery: `$${data.cGSDelivery.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`,
    cACTotal: `$${data.cACTotal.toFixed(2)}`,
    cACDineIn: `$${data.cACDineIn.toFixed(2)}`,
    cACToGo: `$${data.cACToGo.toFixed(2)}`,
    cACDelivery: `$${data.cACDelivery.toFixed(2)}`,
    cSPLHTotal: `$${data.cSPLHTotal.toFixed(0)}`,
    cFOH: `$${data.cFOH.toFixed(0)}`,
    cBOH: `$${data.cBOH.toFixed(0)}`,
    cOTTotal: `$${data.cOTTotal.toFixed(0)}`,
    cOTFOH: `$${data.cOTFOH.toFixed(0)}`,
    cOTBOH: `$${data.cOTBOH.toFixed(0)}`,
    cLaborPct: `${(data.cLaborPct * 100).toFixed(2)}%`,
    pct: `${(data.pct).toFixed(2)}%`
  };
};