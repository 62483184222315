import React, { useMemo, useRef, useState } from 'react'

// @mui components
import { IconButton, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MDBox from 'components/MDBox'

// Tanstack React-Table v8
import { useReactTable, flexRender, getCoreRowModel, getExpandedRowModel, getFilteredRowModel } from '@tanstack/react-table'
import { useVirtual } from 'react-virtual'

const quarterSalesCols = generateColumns('quarter', 'sales')
const quarterCountCols = generateColumns('quarter','count')
const monthSalesCols = generateColumns('month', 'sales')
const monthCountCols = generateColumns('month', 'count')


export default function R0004Table({
  data,
  periodType,
  reportType,
  height
}){
  // console.log('render R0004Table')
  // console.log('periodType', periodType)
  // console.log('reportType', reportType)
  // console.log('Table height', height)
  console.log('quarterSalesCols', quarterSalesCols)

  const columns = useMemo(() =>
    periodType === 'quarter'
    ? reportType === 'sales'
      ? quarterSalesCols
      : quarterCountCols
    : reportType === 'sales'
      ? monthSalesCols
      : monthCountCols
    , [periodType, reportType]
  )

  const [expanded, setExpanded] = useState({})

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    // getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  })

  const tableContainerRef = useRef()
  // const tableContainerRef = React.useRef<HTMLDivElement>(null)

  const { rows } = table.getRowModel()
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  })
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0
  const paddingBottom = virtualRows.length > 0
    ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) 
    : 0

  return (
    <MDBox 
      sx={{ 
        // display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        width: '100%',
        // flex: '1 1 50%'
      }}
    >
      <TableContainer 
        ref={ tableContainerRef }
        component={Paper}
        style={{
          maxHeight: height === 0 ? '400px' : height,
          // overflow: 'auto'
        }}
      >
      {/* <div ref={tableContainerRef} > */}
        <Table aria-label='r0004 table' stickyHeader={true}>
          <TableHead 
            sx={{ 
              display: 'table-header-group',
            }}
          >
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => 
                  <R0004HeadCell 
                    key={header.id}
                    align={header.column.columnDef.align}
                    headerStyle={header.column.columnDef.headerStyle}
                  >
                    {flexRender(
                      header.column.columnDef.header, header.getContext()
                    )}
                  </R0004HeadCell>
                )}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {paddingTop > 0 && (
              <tr>
                <td style={{ height: `${paddingTop}px`}} />
              </tr>
            )}
            {virtualRows.map(virtualRows => {
              const row = rows[virtualRows.index] 
              const index = virtualRows.index
              return (
                <TableRow
                  key={row.id}
                  style={{
                    backgroundColor: index % 2 === 0 ? 'white' : '#f9e9d3'
                  }}
                >
                  {row.getVisibleCells().map(cell => {
                    return (
                      <R0004BodyCell
                        key={cell.id}
                        align={cell.column.columnDef.cellAlign}
                        style={{
                          color: '#000',
                          fontSize: '.7rem'
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell, 
                          cell.getContext()
                        )}
                      </R0004BodyCell>
                    )
                  })}
                </TableRow>
              )
            })}
            {paddingBottom > 0 && (
              <tr>
                <td style={{ height: `${paddingBottom}px`}} />
              </tr>
            )}
            {/* {table.getRowModel().rows.map((row, index) => (
              <TableRow 
                key={row.id}
                style={{
                  backgroundColor: index % 2 === 0 ? 'white' : '#f9e9d3'
                }}
              >
                {row.getVisibleCells().map(cell => (
                  <R0004BodyCell 
                    key={cell.id}
                    align={cell.column.columnDef.cellAlign}
                    style={{
                      color: '#000',
                      fontSize: '.7rem'
                    }}
                  >
                    {flexRender(
                      cell.column.columnDef.cell, cell.getContext()
                    )}
                  </R0004BodyCell>
                ))}
              </TableRow>
            ))} */}
          </TableBody>
        </Table>
      </TableContainer>
      {/* </div> */}
    </MDBox>
  )
}

function R0004HeadCell({
  align,
  headerStyle,
  children,
  ...rest
}){
  return (
    <MDBox
      component='th'
      style={ headerStyle }
    >
      <MDBox
        {...rest}
        position='relative'
        textAlign={ align }
      >
        { children }
      </MDBox>
    </MDBox>
  )
}

function R0004BodyCell({
  align,
  style,
  children
}){
  return (
    <MDBox
      component='td'
      textAlign={ align }
      py={.5}
      px={.5}
      style={ style }
    >
      <MDBox
        display='inline-block'
        color={style ? style.color : 'text'}
        sx={{ verticalAlign: 'middle'}}
      >
        { children }
      </MDBox>
    </MDBox>
  )
}

function generateColumns(periodType, reportType){
  const headerFontSize = '.9rem'
  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })
  let USNumber = new Intl.NumberFormat('en-US')
  const productCol = {
    accessorKey: 'product',
    header: ({ table }) => (
      <>
        <IconButton
          aria-label='expand button'
          size='small'
          onClick={table.getToggleAllRowsExpandedHandler()}
        >
          {
            table.getIsAllRowsExpanded() ?
            <ExpandLessIcon /> : 
            <ExpandMoreIcon />
          }
        </IconButton>
        {' '}
        Product
      </>
    ),
    cell: ({row, getValue }) => (
      <div
        style={{
          paddingLeft: `${row.depth * 2}rem`,
        }}
      >
        <>
          {
            row.getCanExpand() ? (
              <IconButton 
                aria-label='expand button'
                size='small'
                onClick={row.getToggleExpandedHandler()}
              >
                {
                  row.getIsExpanded() ?
                  <ExpandLessIcon /> :
                  <ExpandMoreIcon />
                }
              </IconButton> 
          ) : (
            // '🔵'
            null
          )}{' '}
          {getValue()}
        </>
      </div>
    ),
    footer: (props) => props.column.id,
    align: 'left',
    headerStyle: {
      paddingLeft: 4,
      fontSize: headerFontSize,
      backgroundColor: '#e0e0e0'
    }
  }

  const columns = [
    productCol,
  ]

  let periods = []
  if(periodType === 'quarter'){
    periods = ['q1', 'q2', 'q3', 'q4']
  } else {
    periods = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
  }

  for(const period of periods){
    const periodCol = {
      accessorKey: `${period}${reportType.charAt(0).toUpperCase() + reportType.slice(1)}`,
      header: `${period.charAt(0).toUpperCase() + period.slice(1)}`,
      footer: `${period.charAt(0).toUpperCase() + period.slice(1)}`,
      headerStyle: {
        fontSize: headerFontSize,
        backgroundColor: '#e0e0e0',
      },
      align: 'center',
      cellAlign: 'center',
      cell: ({ getValue }) => (
        <MDBox>
          {reportType === 'sales' ? USDollar.format(getValue()) : USNumber.format(getValue())}
        </MDBox>
      )
    }
    columns.push(periodCol)
  }
  return columns
}