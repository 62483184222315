import { styled } from '@mui/material/styles'
import { Switch } from '@mui/material'

const SwitchDemDark = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-thumb': {
    backgroundColor: '#0091d5',
  },
  '& .Mui-disabled' : {
    '& .MuiSwitch-thumb': {
      backgroundColor: '#b9ad9c'
    }
  }
}))

export default SwitchDemDark