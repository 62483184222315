import React, { forwardRef, useMemo, useEffect, useRef, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useAsyncDebounce, useExpanded, useGlobalFilter, useGroupBy, useMountedLayoutEffect, usePagination, useRowSelect, useSortBy, useTable } from "react-table";

// @mui material components
import { Autocomplete, Icon, IconButton, Pagination, Stack, Table, TableBody, TableContainer, TableRow, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from 'components/MDButton'
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 PRO React example components
import RDTableHeadCell from 'components/RDTable/RDTableHeadCell';
import RDTableBodyCell from 'components/RDTable/RDTableBodyCell';


function RDTable({
  entriesPerPage,
  showEntriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  editable,
  tableType,
  cellStyle,
  rowSelect,
  tableOptions,
  setSelectedRows,
  selectedRows,
  updateCellData,
  clearSelectedRows,
}) {

  if(selectedRows === undefined){
    selectedRows = {}
  }

  if(typeof tableOptions === 'undefined'){
    tableOptions = { 
      error: {},
      style: {} 
    }
  }
  
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableHooks = (hooks) => {
    if(editable.onRowEdit && editable.onRowDelete){
      
      let columnStyle = {}      
      if(columns.length > 0){
        columnStyle = columns[0].columnStyle
      }
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: 'actions',
          Header: ' ',
          align: 'center',
          // width: '10px',
          width: '3%',
          columnStyle: columnStyle,
          Cell: ({row}) => (  // eslint-disable-line
            <Stack 
              direction='row' 
              spacing={1}
              alignItems='center'
              justifyContent='center'
            > 
              {/* <MDButton 
                iconOnly 
                color='info' 
                variant='text' 
                size='small'
                sx={{backgroundColor: 'red', margin: 0}}
                // onClick={() => handleEdit(row)}
                onClick={() => editable.onRowEdit(row)}
              >
                  <Icon sx={{fontSize: 'large'}}>edit</Icon>
              </MDButton> */}
              
              <IconButton
                color='info'
                sx={{
                  padding: 0,
                  '&: hover': {
                    color: 'rgb(78, 39, 102)'
                  }
                }}
                onClick={() => editable.onRowEdit(row)}  // eslint-disable-line
              >
                <EditIcon />
              </IconButton>
              
              <IconButton
                color='info'
                sx={{
                  padding: 0,
                  '&: hover': {
                    color: 'rgb(78, 39, 102)'
                  }
                }}
                onClick={() => editable.onRowDelete(row)}  // eslint-disable-line
              >
                <DeleteIcon />
              </IconButton>
              {/* <MDButton
                iconOnly
                color='info'
                variant='text'
                size='large'
                // onClick={() => handleDelete(row)}
                onClick={() => editable.onRowDelete(row)}
              >
                <Icon
                  sx={{
                    padding: 0
                  }}
                >
                  delete
                </Icon>
              </MDButton> */}
            </Stack>
          )
        },
      ])
    } else if(editable.onRowEdit){
      let columnStyle = {}      
      if(columns.length > 0){
        columnStyle = columns[0].columnStyle
      }
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: 'actions',
          Header: ' ',
          width: '3%',
          columnStyle: columnStyle,
          Cell: ({row}) => (  // eslint-disable-line
            <Stack direction='row' spacing={0}> 
              <MDButton 
                iconOnly 
                color='info' 
                variant='text' 
                size='large'
                // onClick={() => handleEdit(row)}
                onClick={() => editable.onRowEdit(row)}  // eslint-disable-line
              >
                  <Icon>edit</Icon>
              </MDButton>
            </Stack>
          )
        },
      ])
    } else if(editable.onRowDelete) {
      let columnStyle = {}      
      if(columns.length > 0){
        columnStyle = columns[0].columnStyle
      }
      hooks.visibleColumns.push((columns) => [
        ...columns,
        {
          id: 'actions',
          Header: ' ',
          width: '3%',
          columnStyle: columnStyle,
          Cell: ({row}) => (  // eslint-disable-line
            <Stack direction='row' spacing={0}> 
              <MDButton
                iconOnly
                color='info'
                variant='text'
                size='large'
                // onClick={() => handleDelete(row)}
                onClick={() => editable.onRowDelete(row)}  // eslint-disable-line
              >
                <Icon>delete</Icon>
              </MDButton>
            </Stack>
          )
        },
      ])
    }
  }

  const hiddenCols = []

  const tableInstance = useTable(
    { 
      columns, 
      data, 
      initialState: { 
        pageIndex: 0,
        // hiddenColumns: columns.map(column => getHiddenCols(column))
        hiddenColumns: hiddenCols,
        selectedRowIds: selectedRows,
      },
      updateCellData,
      tableOptions,
      useExpanded, // Added
    },
    tableHooks,
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    selectedFlatRows,
    state: { pageIndex, pageSize, globalFilter, groupBy, expanded, selectedRowIds },
    setHiddenColumns,
    toggleAllRowsSelected
  } = tableInstance;

  useMountedLayoutEffect(() => {
    setSelectedRows && setSelectedRows(selectedRowIds, selectedFlatRows)
  }, [selectedRowIds]);

  useEffect(() => {
    if(clearSelectedRows){
      toggleAllRowsSelected(false)
    }
  }, [clearSelectedRows])

  const getHiddenCols = (col) => {
    if('show' in col){
      if(col.show === false){
        hiddenCols.push(col.accessor)
      }
    }
    // recursive call
    col.columns && col.columns.forEach(getHiddenCols)
  }

  useEffect(() => {
    columns.forEach(getHiddenCols)
    setHiddenColumns(hiddenCols)
    
  }, [columns])


  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // const [tablePage, setTablePage] = useState(1)

  function handlePageChange(event, value){
    gotoPage(Number(value))
    // setTablePage(value)
  }



  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }
    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      { canSearch || showEntriesPerPage || editable.onRowAdd ? (
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1} mx={3} my={1}>
          <MDBox>
            {
              showEntriesPerPage && (
                <MDBox display="flex" justifyContent='flex-start' alignItems="center">
                  <Autocomplete
                    disableClearable
                    value={pageSize.toString()}
                    options={entries}
                    onChange={(event, newValue) => {
                      setEntriesPerPage(parseInt(newValue, 10));
                    }}
                    size="small"
                    sx={{ width: "5rem" }}
                    renderInput={(params) => <MDInput {...params} />}
                  />
                  <MDTypography variant="caption" color="secondary">
                    &nbsp;&nbsp;entries per page
                  </MDTypography>
                </MDBox>  
              )
            }
          </MDBox>        
          <MDBox>
            <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={1}>
              {
                canSearch && (
                <MDBox width="12rem" ml='auto'>
                  <MDInput
                    placeholder="Search..."
                    value={search}
                    size="small"
                    fullWidth
                    onChange={({ currentTarget }) => {
                      setSearch(search);
                      onSearchChange(currentTarget.value);
                    }}
                  />
                </MDBox>
              )}
            
              {
                editable.onRowAdd && (
                <MDBox width="10rem" sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}} >
                  <MDButton variant='contained' size='small' color='info' onClick={editable.onRowAdd}>
                    Add {tableType}
                  </MDButton>
                </MDBox>                
              )}
            </Stack>
          </MDBox>
        </Stack>
      ) : null
      }
      <Table {...getTableProps()} >
        <MDBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <RDTableHeadCell
                  {...column.getHeaderProps([
                    {
                      style: column.headerStyle
                    },                    
                    isSorted && column.getSortByToggleProps()
                  ])}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  py={column.py ? column.py : 0}
                  px={column.px ? column.px : 0}
                  sorted={setSortedValue(column)}
                  canSort={column.canSort}
                  columnStyle={column.columnStyle}
                  rowSpan={column.rowSpan}
                >
                  {column.canGroupBy ? (
                    // If the column can be grouped, let's add a toggle
                    <span {...column.getGroupByToggleProps()}>
                      {column.isGrouped ? <Icon sx={{ fontWeight: 'bold', mx: 0.5 }}>code</Icon> : <Icon sx={{ fontWeight: 'bold', mx: 0.5 }} > keyboard_double_arrow_right </Icon> }
                      {/* {column.isGrouped ? '🛑 ' : '👊 ' } */}
                    </span>
                  ) : null }
                  {column.render("Header")}
                </RDTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <RDTableBodyCell
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align={cell.column.alignCell ? cell.column.alignCell : "left"}
                    {...cell.getCellProps()}
                    style={ cellStyle(row, cell, tableOptions.style)}
                  >                    
                    {
                      cell.isGrouped ? 
                      (
                        // If it's a grouped cell, add an expander and row count
                        <>
                          <span {...row.getToggleRowExpandedProps()}>
                            
                            {row.isExpanded ? <Icon fontSize='medium'>expand_more</Icon> : <Icon fontSize='medium'>chevron_right</Icon> }
                            {/* {row.isExpanded ? '👇' : '👉' } */}
                          </span>{' '}
                          {cell.render('Cell')} ({row.subRows.length})
                        </>
                      ) : cell.isAggregated ?
                      (
                        // If the cell is aggregated, use the Aggregated renderer for cell
                        cell.render('Aggregated')
                      ) : cell.isPlaceholder ? null :
                      (
                        // For cells with repeated values, render null, otherwise, just render the regular cell
                      
                        cell.render("Cell")
                      )
                    } 
                  </RDTableBodyCell>
                  
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography variant="button" color="secondary" fontWeight="regular">
              Showing {entriesStart} to {rows.length < entriesEnd ? rows.length : entriesEnd} of {rows.length} entries
              {/* Showing {entriesStart} to {entriesEnd} of {rows.length} entries */}
            </MDTypography>
          </MDBox>
        )}
        {pageOptions.length > 1 && (
          // <Stack spacing={2}>
          //   <Pagination 
          //     count={pageOptions.length}
          //     page={page - 1}
          //     boundaryCount={2}
          //     onChange={handlePageChange}
          //   />
          // </Stack>
          <MDPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <MDPagination item onClick={() => previousPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination.length > 6 ? (
              <MDBox width="5rem" mx={1}>
                <MDInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </MDBox>
            ) : (
              renderPagination
            )} 
            
            {canNextPage && (
              <MDPagination item onClick={() => nextPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    </TableContainer>
  );
}

// IndeterminateCheckBox.propTypes = {
//   indeterminate: PropTypes.bool
// }


// Setting default values for the props of RDTable
RDTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the RDTable
RDTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  getToggleAllRowsSelectedProps: PropTypes.func,
  showEntriesPerPage: PropTypes.bool,
  canSearch: PropTypes.bool,
  addRowButton: PropTypes.string,
  handleAdd: PropTypes.func,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  tableType: PropTypes.string,
  row: PropTypes.object,
  editable: PropTypes.object,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  selectedRows: PropTypes.object,
  setSelectedRows: PropTypes.func,
  type: PropTypes.string,
  cellStyle: PropTypes.func,
  rowSelect: PropTypes.bool,
  rowSpan: PropTypes.number,
  tableOptions: PropTypes.object,
  updateCellData: PropTypes.func,
  clearSelectedRows: PropTypes.bool,
};

export default RDTable;