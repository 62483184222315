import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

// MUI components
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'

// react-table components
import {
  useBlockLayout,
  useTable
} from 'react-table'

// react-window components
import {
  FixedSizeList
} from 'react-window'

// RadixDash components
import RDStickyBodyCell from './RDStickyBodyCell'
import RDStickyHeadCell from './RDStickyHeadCell'
import scrollbarWidth from './scrollbarWidth'

export default function RDStickyTable({
  columns,
  data,
  styleFunc,
  cellOptions,
}){

  const defaultColumn = useMemo(
    () => ({
      width: 50,
    }),
    []
  )

  const scrollBarSize = useMemo(
    () => scrollbarWidth(), []
  )

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useBlockLayout,
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    totalColumnsWidth,
    prepareRow
  } = tableInstance

  const rowHeight = 18
  // Set table height based on browser viewport height
  const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index]
      if(row.original.store === ''){
        return null
      } else {
        prepareRow(row)
        return (
          <TableRow 
            {...row.getRowProps({ style })}
          >
            {row.cells.map( (cell, index) => {
              return (
                <RDStickyBodyCell
                  {...cell.getCellProps()}
                  width={cell.column.width}
                  styleFunc={styleFunc}
                  options={cellOptions}
                >
                  {cell.render('Cell')}
                </RDStickyBodyCell>
              )
            })}
          </TableRow>
        )
      }
    },
    [prepareRow, rows]
  )

  return (
    <TableContainer >
      <Table 
        {...getTableProps()} 
        className='table'
        sx={{ backgroundColor: '#e0e0e0'}}
      >
        <TableHead  
          sx={{
            padding: 0, 
            width: 'calc(100% - 1rem)',
          }} 
        >
          {headerGroups.map(headerGroup => (
            <TableRow 
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map(column => (
                <RDStickyHeadCell
                  {...column.getHeaderProps()}
                  style={column.columnStyle}
                  width={column.width}
                  colSpan={column.colSpan}
                  sx={{
                    // fontSize: '.62rem',
                    padding: '0px',
                    textAlign: 'center'
                  }}
                >
                  {column.render('Header')}
                </RDStickyHeadCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}  >
          <FixedSizeList
            height={Math.max(vh - 300, 450)}
            itemCount={rows.length}
            itemSize={rowHeight}
            width={totalColumnsWidth + scrollBarSize}
            overscanCount={10}
          >
            { RenderRow }
          </FixedSizeList>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

RDStickyTable.propTypes = {
  cellOptions: PropTypes.object,
  columns: PropTypes.array,
  data: PropTypes.array,
  styleFunc: PropTypes.func,
}