import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// AWS Amplify components
import { API  } from 'aws-amplify'

// GraphQL
import { listAreas2 } from 'graphql/queries'

// @mui components
import { Dialog, DialogTitle, DialogContent, Stack, TextField } from '@mui/material'

// Formik components
import { Field, Formik, Form } from 'formik'
import * as Yup from 'yup'

// Material Dashboard Pro 2 components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// RadixDash components
import Button_GrmDark from 'components/RDButton/Button_GrmDark'
import Autocomplete_GrmDark from 'components/RDAutocomplete/Autocomplete_GrmDark'
import Select_Rd from 'components/FormsUI/Select'
import Textfield from 'components/FormsUI/Textfield'
import Textfield_Grm from 'components/FormsUI/Textfield/Textfield_Grm'

export default function R0003AddTrainingDialog(props) {
  
 const [name, setName] = useState(null)
  const [numHours, setNumHours] = useState(null)
  const [trainingType, setTrainingType] = useState(null)

  const [data, setData] = useState({
    store: 'Loading...',
    day: '',
    name: '',
    type: '',
    hours: null
  })

  const typeObj = { BOH: 'BOH', FOH: 'FOH' }


  const INITIAL_FORM_STATE = {
    name: '',
    type: 'BOH',
    hours: null
  }

  const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string()
      .required('Required'),
    type: Yup.string()
      .required('Required'),
    hours: Yup.number()
      .positive('Positive values only')
      .required('Required')
  })

  useEffect(() => {
    setName(null)
    setTrainingType(null)
    setNumHours(null)
  }, [])

  // useEffect(() => {
  //   if(name && trainingType && numHours){
  //     setIsValid(true)
  //   } else {
  //     setIsValid(false)
  //   }
  // }, [name, trainingType, numHours])
  
  

  function handleNameChange(event){
    setName(event.target.value)
  }

  function handleTypeChange(event, newType){
    setTrainingType(newType)
  }

  function handleHoursChange(event){
    setNumHours(event.target.value)
  }

  function handleSubmit(values){
    props.submit(values)
  }

  function clearValues(){
    // setSelectedStore(null)
    // setSelectedDay(null)
    setName(null)
    setTrainingType(null)
    setNumHours(null)
  }

  function handleCancel() {
    props.cancel()
  }

  return (
    <Dialog
      open={ props.open }
      maxWidth='xs'
      fullWidth={ true }
    >
      <DialogTitle
        sx={{ bgcolor: '#29261F' }}
      >
        <MDTypography 
          variant='h5'
          style={{ color: '#cf9e0b'}}
        >
          Add Training
        </MDTypography>
      </DialogTitle>
      <DialogContent
        sx={{ bgcolor: '#f9e9d3'}}
      >
        <Formik
          initialValues={ INITIAL_FORM_STATE }
          validationSchema={ FORM_VALIDATION }
          enableReinitialize={ true }
          onSubmit={(values) => {
            console.log('values', values)
            handleSubmit(values)
          }}
        >
          {({ isValid, dirty }) => (
            <Form>
              <Stack
                direction='column'
                spacing={2}
                mx={3}
                pt={2}
              >
                <MDBox
                  sx={{
                    mb: 1,
                    mx: 'auto'
                  }}
                >
                  <MDTypography 
                    variant='subtitle2'
                    fontWeight='bold'
                  >
                    Week: {props.week}              
                  </MDTypography>
                  <MDTypography
                    variant='subtitle2'
                    fontWeight='bold'
                  >
                    Day: {props.day}              
                  </MDTypography>
                  <MDTypography
                    variant='subtitle2'
                    fontWeight='bold'
                  >
                    Store: {props.store}              
                  </MDTypography>
                </MDBox>
                <Textfield 
                  name='name'
                  label='Employee Name'
                />
                {/* <TextField
                  name='name'
                  label='Employee Name'
                  value={name}
                  onChange={ handleNameChange }
                /> */}
                <Select_Rd 
                  name='type'
                  options={ typeObj }
                />
                {/* <Autocomplete_GrmDark 
                  id='typeSelector'
                  
                  options={ ['BOH', 'FOH'] }
                  value={ trainingType }
                  onChange={ handleTypeChange }
                  disableClearable={true}
                  renderInput={(params) => 
                    <TextField 
                      {...params}
                      name='type'
                      label='Training Type'
                      variant='outlined'
                      size='small'
                    />
                  }
                /> */}
                <Textfield 
                  name='hours'
                  label='Hours'
                />
                {/* <TextField 
                  label='Hours'
                  value={numHours}
                  onChange={handleHoursChange}
                /> */}
                <Stack
                  direction='row'
                  spacing={2}
                  justifyContent='flex-end'
                >
                  <Button_GrmDark
                    onClick={ handleCancel }
                    fullWidth
                  >
                    Cancel
                  </Button_GrmDark>
                  
                  <Button_GrmDark
                    fullWidth
                    type='submit'
                    disabled={!isValid || !dirty}
                  >
                    Submit
                  </Button_GrmDark>
                  
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>      
      </DialogContent>
    </Dialog>
  )
}