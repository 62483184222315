import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { IconButton } from '@mui/material';

const DEFAULT_WIDTH = 75;

const STYLES = {
  header: {
    backgroundColor: '#bdbdbd',
    color: '#181818',
    borderLeft: '1px solid #fff',
    borderBottom: '1px solid #fff',
    padding: '0px 5px',
    fontSize: '.65rem',
  },
  hiddenHeader: {
    backgroundColor: '#bdbdbd',
    color: '#bdbdbd',
    borderRight: '1px solid #fff',
    borderBottom: 'none',
    padding: '2px',
    fontSize: '.65rem'
  }
};

const createBaseColumn = (accessor, header, width = DEFAULT_WIDTH, customStyle = {}) => ({
  Header: header,
  accessor,
  disableGroupBy: true,
  disableSortBy: true,
  align: 'center',
  width,
  columnStyle: { ...STYLES.header, ...customStyle }
});

const createHiddenColumn = (accessor, header, width = DEFAULT_WIDTH) =>
  createBaseColumn(accessor, header, width, { color: '#bdbdbd' })

const createColumnGroup = (header, columns, width, customStyle = {}) => ({
  Header: header,
  align: 'center',
  width,
  columnStyle: { ...STYLES.header, ...customStyle },
  columns
});

const projectedColumns = [
  createBaseColumn('projCheckCount', 'Check Count'),
  createBaseColumn('projAvgCheck', 'Avg Check'),
  createBaseColumn('projSales', 'Sales'),
  createBaseColumn('theoFOH', 'Theo FOH'),
  createBaseColumn('theoBOH', 'Theo BOH'),
  createBaseColumn('projSPLH', 'SPLH', 50)
];

const scheduledColumns = [
  createBaseColumn('schedFOH', 'Sched FOH'),
  createBaseColumn('schedBOH', 'Sched BOH'),
  createBaseColumn('schedSPLH', 'SPLH', 50)
];

const actualColumns = [
  createBaseColumn('actCheckCount', 'Check Count'),
  createBaseColumn('actAvgCheck', 'Avg Check'),
  createBaseColumn('actSales', 'Sales'),
  createBaseColumn('adjTheoFOH', 'Adj Theo FOH'),
  createBaseColumn('adjTheoBOH', 'Adj Theo BOH'),
  createBaseColumn('actFOH', 'Act FOH'),
  createBaseColumn('actBOH', 'Act BOH'),
  createBaseColumn('fohActVsTheo', 'FOH Act - Theo'),
  createBaseColumn('bohActVsTheo', 'BOH Act - Theo'),
  createBaseColumn('theoSPLH', 'Total Theo SPLH'),
  createBaseColumn('actSPLH', 'Total Act SPLH')
];

const trainingColumns = [
  createBaseColumn('trainingFOH', 'FOH'),
  createBaseColumn('trainingBOH', 'BOH')
];

const createNotesCell = (openAddNoteDialog) => ({ value, row }) => {
  if (row.original.day !== '' && 
      (row.original.theoFOH === '' || 
      row.original.theoFOH === undefined)) {
    return (
      <IconButton
        aria-label='Add Note'
        color='inherit'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        onClick={() => openAddNoteDialog(row.original.day)}
      >
        <NoteAddIcon fontSize='small' />
      </IconButton>
    );
  }
  return <div>{value}</div>;
};

export const getColumns = (openAddNoteDialog) => [
  createColumnGroup('d', [
    createHiddenColumn('day', 'day')
  ], DEFAULT_WIDTH, STYLES.hiddenHeader),
  
  createColumnGroup('Projected', projectedColumns, 
    (5 * DEFAULT_WIDTH) + 50),
  
  createColumnGroup('Scheduled', scheduledColumns, 
    DEFAULT_WIDTH * 2 + 50),
  
  createColumnGroup('Actual', actualColumns, 
    (11 * DEFAULT_WIDTH)),
  
  createColumnGroup('Training', trainingColumns, 150),
  
  createColumnGroup('n', [{
    ...createBaseColumn('notes', 'Notes', 150),
    Cell: createNotesCell(openAddNoteDialog)
  }], 150, STYLES.hiddenHeader)
];

export const TABLES = {
  empty: [{ day: 'Data Loading' }],
  loading: [{ day: 'Data Loading...' }],
  noData: [{ day: 'No Data' }]
};