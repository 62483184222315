import React from 'react'

import { Container, Stack } from '@mui/material'

// Material Dashboard 2 Pro React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

import THAssignmentAreaSelector from './THAssignmentAreaSelector'
import THAssignmentTable from './THAssignmentTable'

export default function ThresholdAssignments(props){
  console.log('ThresholdAssignments', props)
  return (
    <Container 
      maxWidth='lg'
      sx={{
        pb: 4,
      }}
    >
      <MDBox
        sx={{
          backgroundColor: '#e0e0e0',
          borderRadius: '16px',
        }}
      >
        <MDBox py={1} px={3}>
          <Stack
            direction='row'
            justifyContent='space-between'
          >
            <MDTypography variant='h5'>
              Threshold Assignments
            </MDTypography>
            <THAssignmentAreaSelector 
              area={ props.area }
              data={ props.data } 
              onChange={ props.handleAreaChange }
            />
          </Stack>
        </MDBox>
        <MDBox
          sx={{
            backgroundColor: '#fff',
            borderRadius: '0 0 16px 16px',
            width: '100%',
          }}
        >
          <MDBox
            sx={{
              mx: 2,
              py: 2
            }}
          >
            <THAssignmentTable 
              data={ props.filteredData }
              thresholds={ props.thresholds }
              update={ props.update }
            />
          </MDBox>
        </MDBox>
      </MDBox>
      
    </Container>
  )
}