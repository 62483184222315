import React, { useState } from 'react'

// @mui components
import { Dialog, DialogTitle, DialogContent, Grid, Stack } from '@mui/material'

// RadixDash components
import Button_GrmDark from 'components/RDButton/Button_GrmDark'
import RDTransferList from 'components/RDTransferList'
import MDTypography from '../../../../components/MDTypography'

import grmTheme from '../../grmTheme'


export default function R0004SelectDialog({
  cancel,
  items,
  nested,
  open,
  submit,
  title,
}){
  const handleSubmit = (selections) => {
    submit(selections)
  }

  return (
    <Dialog
      open={ open }
      maxWidth='md'
      fullWidth={ true }
    >
      <DialogTitle
        sx={{
          backgroundColor: '#29261f'
        }}
      >
        <MDTypography
          variant='h5'
          style={{ color: '#cf9e0b' }}
        >
          { title }
        </MDTypography>
      </DialogTitle>
      <DialogContent>
        <Stack
          direction='column'
          spacing={2}
          sx={{
            mt: 3
          }}
        >
          <RDTransferList 
            style={{
              bgColor: '#a44c0e',
              color: '#f9e9d3',
              bgColorHover: '#cf9e0b',
              bcColor: '#29261f'
            }}
            theme={ grmTheme }
            cancel={ cancel }
            submit={ handleSubmit }
            items={ items }
            emptySubmitAllowed={ false }
            nested={ nested }
          />
          
        </Stack>
      </DialogContent>
    </Dialog>
  )
}