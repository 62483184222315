import { styled } from '@mui/system'
import MDButton from 'components/MDButton'

const Button_GrmHead = styled(MDButton)(({theme}) => ({
  backgroundColor: '#cf9e0b',
  color: '#29261f',
  '&: focus:not(:hover)': {
    backgroundColor: '#cf9e0b',
    color: '#29261f'
  },
  '&: hover': {
    backgroundColor: '#906e07',
  },
  '&: disabled': {
    backgroundColor: '#c48b64',
    color: '#29261f'
  }
}))

export default Button_GrmHead