import { Card, CardContent, Divider } from '@mui/material'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

function R0001Message(props){

  let message

  return (
    <MDBox
      sx={{
        display: 'flex',
        alignContent: 'center',
        height: '75vh'
      }}
    >
      <Card 
        sx={{
          maxWidth: 343,
          margin: 'auto',
          padding: '12px',
          backgroundColor: '#29261f',
        }}
      >
        { props.message === 'noDataMessage' &&
          <NoDataContent />
        }
        {
          props.message === 'attentionMessage' &&
          <AttentionContent />
        }
        {/* <CardContent>
          <MDTypography 
            variant='h3'
            sx={{
              textAlign: 'center',
              color: '#cf9e0b'
            }}
          >Attention</MDTypography>
          <Divider 
            sx={{
              background: '#cf9e0b',
              margin: '8px',
              marginBottom: '24px'
            }}
          />
          <MDTypography 
            variant='body2'
            sx={{
              color: '#fff'
            }}
          >
            The Metric Planning Tool values will differ from the Aloha Drill Down viewer because the Metric Planning Tool removes all $0 check counts from its calculations.
          </MDTypography>
          <MDTypography 
            variant='body2'
            sx={{
              color: '#cf9e0b',
              margin: '16px'
            }}
          >
            To Continue:  Please set the reporting period and click the &apos;Update Report&apos; button.
          </MDTypography>

        </CardContent> */}
      </Card>
    </MDBox>
  )
}

function NoDataContent(){
  return (
    <CardContent>
      <MDTypography 
        variant='h3'
        sx={{
          textAlign: 'center',
          color: '#cf9e0b'
        }}
      >Attention</MDTypography>
      <Divider 
        sx={{
          background: '#cf9e0b',
          margin: '8px',
          marginBottom: '24px'
        }}
      />
      <MDTypography 
        variant='body2'
        sx={{
          color: '#fff'
        }}
      >
        No Data Available.
        You are not assigned as an Area Manager or General Manager.
        Please contact your manager if you should have access to this data.
      </MDTypography>
    </CardContent>
  )
}

function AttentionContent(){
  return (
    <CardContent>
      <MDTypography 
        variant='h3'
        sx={{
          textAlign: 'center',
          color: '#cf9e0b'
        }}
      >Attention</MDTypography>
      <Divider 
        sx={{
          background: '#cf9e0b',
          margin: '8px',
          marginBottom: '24px'
        }}
      />
      <MDTypography 
        variant='body2'
        sx={{
          color: '#fff'
        }}
      >
        The Metric Planning Tool values will differ from the Aloha Drill Down viewer because the Metric Planning Tool removes all $0 check counts from its calculations.
      </MDTypography>
      <MDTypography 
        variant='body2'
        sx={{
          color: '#cf9e0b',
          margin: '16px'
        }}
      >
        To Continue:  Please set the reporting period and click the &apos;Update Report&apos; button.
      </MDTypography>

    </CardContent>
  )
}

export default R0001Message