import colors from 'assets/theme/base/colors'

const palette = {
  ...colors,

  primary: {
    main: '#a44c0e',
    contrastText: '#f9e9d3',
  },
  secondary: {
    main: '#c4960c',
    contrastText: '#723509',
  },
  header: {
    main: '#29261f',
    contrastText: '#cf9e0b',
    hover: '#906e07',
  },
  autocomplete: {
    header: {
      background: '#29261f',
      border: '#cf9e0b',
      hoverBackground: '#3e372f',
      hoverBorder: '#fdd302', 
    }
  },
  headerAutocomplete: {
    background: '#29261f',
    border: '#cf9e0b',
    hoverBackground: '#3e372f',
    hoverBorder: '#fdd302',
  },
  headerButton: {
    main: '#c4960c',
    contrastText: '#29261f',
    hover: '#906e07',
  },
  disabled: {
    main: '#b9ad9c',
    contrastText: '#a29889',
  }
}

export default palette