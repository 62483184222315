import { useEffect } from 'react'
import PropTypes from 'prop-types'

// react-router-dom components
import { useLocation } from 'react-router-dom'

// Material Dashboard 2 Pro context
import { useMaterialUIController, setLayout } from 'context'

// Material Dashboard 2 Pro components
import MDBox from 'components/MDBox'

export default function RDDashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController()
  const { miniSidenav } = controller
  const { pathname } = useLocation()

  useEffect(() => {
    setLayout(dispatch, 'dashboard')
  }, [pathname])

  return (
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: 'relative',

        [breakpoints.up('xl')]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(['margin-left', 'margin-right'], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      { children }
    </MDBox>
  )
}

RDDashboardLayout.propTypes = {
  children: PropTypes.node.isRequired
}