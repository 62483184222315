import { useEffect, useState } from 'react'

// AWS Amplify
import { API } from 'aws-amplify'
import { dbAddItem, dbDeleteItem, dbEditItem } from 'graphql/mutations'
import { getReport, listAreas2 } from 'graphql/queries'

// @mui material components
import { Divider, Grid, Icon, IconButton, Stack, TextField, Tooltip } from '@mui/material'

import { addWeeks, format, formatISO, getISOWeeksInYear, getISOWeek, getISOWeekYear, getYear, setWeek, nextMonday, startOfQuarter } from 'date-fns';

// Material Dashboard 2 PRO React components
import MDAlert from 'components/MDAlert'
import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography'
import { useMaterialUIController, setOpenConfigurator } from 'context';

// RadixDash components
import R0003AddTrainingDialog from './R0003AddTrainingDialog'
import R0003EditTrainingDialog from './R0003EditTrainingDialog'
import Autocomplete_GrmHead from 'components/RDAutocomplete/Autocomplete_GrmHead'
import Button_GrmHead from 'components/RDButton/Button_GrmHead'
import PeriodSelector from './R0003PeriodSelector';
import Progress from 'components/Progress/Progress'
import RDConfigurator from 'components/RDConfigurator'
import RDTable from 'components/RDTable'
import R0003TrainingCellStyle from './R0003TrainingCellStyle';
import R0003SumCellStyle from './R0003SumCellStyle'

import { useUser} from 'components/RDAuthContext'


export default function R0003Training(props){

  const { user } = useUser()

  
  // const [weeks] = useState(() => {
  //   const weeksArray = generateWeeks(new Date(2021, 2, 22))
  //   return weeksArray
  // })
  const weeks = generateWeeks(new Date(2021, 2, 22))

  const initialSumData = [
    {
      type: 'BOH',
      total: 0,
      mon: 0,
      tue: 0,
      wed: 0,
      thu: 0,
      fri: 0,
      sat: 0,
      sun: 0,
    },
    {
      type: 'FOH',
      total: 0,
      mon: 0,
      tue: 0,
      wed: 0,
      thu: 0,
      fri: 0,
      sat: 0,
      sun: 0,
    }
  ]

  const [addStoreList, setAddStoreList] = useState([])
  const [dataLoading, setDataLoading] = useState(false)
  const [data, setData] = useState(null)
  const [day, setDay] = useState('Monday')
  const [noData, setNoData] = useState(true)
  const [openAdd, setOpenAdd] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [recordsTitle, setRecordsTitle] = useState('')
  const [rowToEdit, setRowToEdit] = useState(null)
  const [selectedStore, setSelectedStore] = useState(null)
  const [selectedStoreData, setSelectedStoreData] = useState(null)
  const [stores, setStores] = useState([])
  const [sumData, setSumData] = useState(initialSumData)
  const [sumTitle, setSumTitle] = useState('')
  const [title, setTitle] = useState('Training Hours')
  const [week, setWeek] = useState(weeks[0])

  const emptyRow = [
    {
      name: `Click "Add" to enter ${day} training hours for ${selectedStore}.`,
      type: '',
      hours: ''
    }
  ]

  const [tableData, setTableData] = useState(emptyRow)
  
  useEffect(() => {
    fetchData(week)
  }, [week])

  useEffect(() => {
    setSumTitle(`${selectedStore} Training Hours for week of ${week}`)
  }, [selectedStore, week])

  useEffect(() => {
    getStores()
  }, [])

  async function getStores() {
    console.log('getStores()')
    try {
      const queryResponse = await API.graphql({
        query: listAreas2,
        variables: {
          report: 'store-admin',
          user: 'N/A',
          role: 'N/A'
        }
      })
      console.log('queryResponse', queryResponse )
      let storeData = queryResponse.data.listAreas2
      if(storeData.length > 0){
        let rawStoreData = []
        for(let i = 0; i < storeData.length; i++){
          let storesArr = storeData[i].stores
          let storeArr = storesArr.map(x => x.shortName)
          rawStoreData.push(...storeArr)
        }
        rawStoreData.sort()
        setStores(rawStoreData)
      }
    } catch (err) {
      console.log('Error fetching stores data', err)
    }
  }

  function handleStoreSelection(event, newStore){
    setSelectedStore(newStore)
  }

  function handleWeek(event, newWeek){
    setWeek(newWeek)
  }

  function handleDay(event, newDay){
    setDay(newDay)
  }

  /****************************
   * FETCH DATA
   ****************************/
  async function fetchData(wk){
    console.log('fetchData', wk)
    // Clear table
    setData(null)
    setTableData(emptyRow)
    // Start progress
    setDataLoading(true)

    const storeArr = ['all']

    try {

      const valueObj = {
        weekOf: wk,
        stores: storeArr
      }

      const reportVariables = [
        {
          name: 'arguments',
          value: JSON.stringify(valueObj)
          // value: valueObj
        }
      ]

      const queryResponse = await API.graphql(
        {
          query: getReport,
          variables: {
            userName: user.userName,
            clientId: user.client,
            reportId: 'trainingHours',
            reportVariables: reportVariables
          },
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
      )

      console.log('queryResponse', queryResponse)
      let queryData = JSON.parse(queryResponse.data.getReport[0].data[0].rowCells[0].value)
      
      setData(queryData)

    } catch (err) {
      console.log('Error fetching training hour data', err)
      setData(null)
      setTableData(emptyRow)
      setSelectedStore(null)
      setDataLoading(false)
    }
  }

  useEffect(() => {
    if(data !== null){
      setDataLoading(false)
    }
  }, [data])

  useEffect(() => {
    if(stores.length > 0){
      setSelectedStore(stores[0])
    } else {
      setSelectedStore(null)
    }
  }, [stores])

  useEffect(() => {
    if(selectedStore !== null && data !== null){
      const index = data.findIndex(row => row.store === selectedStore)
      if(index !== -1){
        const storeData = data[index].data
        setSelectedStoreData([...storeData])
      } else {
        setSelectedStoreData(null)
        setTableData(emptyRow)
        setNoData(true)
      }
    }    
  }, [data, selectedStore])

  useEffect(() => {
    // Update Summary Table when data or selected store changes
    if(selectedStoreData !== null){
      let sumObjectBOH = {
        type: 'BOH',
        total: 0,
        mon: 0,
        tue: 0,
        wed: 0,
        thu: 0,
        fri: 0,
        sat: 0,
        sun: 0
      }
      let sumObjectFOH = {
        type: 'FOH',
        total: 0,
        mon: 0,
        tue: 0,
        wed: 0,
        thu: 0,
        fri: 0,
        sat: 0,
        sun: 0
      }
      let weekTotalBOH = 0
      let weekTotalFOH = 0
      
      selectedStoreData.forEach((day) => {
        let dayTotalBOH = 0
        let dayTotalFOH = 0
        let dayHoursArr = day.hours
        dayHoursArr.forEach((record) => {
          if(record.type === 'BOH'){
            dayTotalBOH += record.hours
            weekTotalBOH += record.hours
          } else {
            dayTotalFOH += record.hours
            weekTotalFOH += record.hours
          }
        })
        let dayOfWeek = day.day.substring(0,3).toLowerCase()
        sumObjectBOH[dayOfWeek] = dayTotalBOH
        sumObjectFOH[dayOfWeek] = dayTotalFOH
      })
      sumObjectBOH.total = weekTotalBOH
      sumObjectFOH.total = weekTotalFOH
      setSumData([sumObjectBOH, sumObjectFOH])     
    } else {
      setSumData(initialSumData)
    } 
  }, [selectedStoreData])

  useEffect(() => {
    setRecordsTitle(`Training Records for ${day} | ${selectedStore}`)
  }, [day, selectedStore])

  useEffect(() => {
    if(selectedStoreData !== null){
      const dayIdx = selectedStoreData.findIndex(row => row.day === day)
      if(dayIdx !== -1){
        if(selectedStoreData[dayIdx].hours.length > 0){
          setTableData([...selectedStoreData[dayIdx].hours])
          setNoData(false)
        } else {
          setTableData(emptyRow)
          setNoData(true)
        }
      } else {
        setTableData(emptyRow)
        setNoData(true)
      }
    }
  }, [day, selectedStoreData])

  async function submitTraining(values){
    console.log('submitTraining', values)
    // Add values to DB
    let trainingRecordObj = {
      weekOf: week,
      store: selectedStore,
      day: day,
      record: {
        name: values.name,
        type: values.type,
        hours: values.hours
      }
    }

    let trainingRecord = JSON.stringify(trainingRecordObj)

    try {
      let result = await API.graphql({
        query: dbAddItem,
        variables: {
          db: 'grm.trainings',
          dbItem: trainingRecord
        }
      })
      console.log('Successful training record upload', result)
      const uploadedItem = JSON.parse(result.data.dbAddItem.statusItem)
      const uploadedObject = JSON.parse(uploadedItem)
      
      /** UPDATE TABLE DATA **/
      // Update raw data
      let dataIdx = data.findIndex(x => x.store === selectedStore)
      if(dataIdx === -1){
        console.log(`${selectedStore} does not have training records | data: ${data}`)
        // Store does not have any training records
        let newRecord = {
          store: selectedStore,
          data: [
            {
              day: day,
              hours: [
                {
                  name: uploadedObject.name,
                  type: uploadedObject.type,
                  hours: uploadedObject.hours,
                  _id: uploadedObject._id
                }
              ]
            }
          ]
        }
        let newData = [...data]
        newData.push(newRecord)
        setData(newData)
      } else {
        // Store already has some training records
        const newRecord = {
          name: uploadedObject.name,
          type: uploadedObject.type,
          hours: uploadedObject.hours,
          _id: uploadedObject._id
        }
        const storeData = data[dataIdx].data
        // Check if target day has records
        let dayIdx = storeData.findIndex(x => x.day === day)
        if(dayIdx !== -1){
          // day exists in hours
          storeData[dayIdx].hours.push(newRecord)
        } else {
          // need to add day to hours
          let dayRecord = {
            day: day,
            hours: [newRecord]
          }
          storeData.push(dayRecord)
        }
        let newData = [...data]
        newData[dataIdx].data = storeData
        setData(newData)
      }

    } catch(err) {
      console.log('Error submitting training', err)
    }
    // Update FE table
    setOpenAdd(false)
  }

  const cancelTraining = () => {
    setOpenAdd(false)
    setOpenEdit(false)
  }

  /***************************
   ** EDIT ROW FUNCTIONS
   ***************************/

  useEffect(() => {
    if(rowToEdit){
      setOpenEdit(true)
    }
  }, [rowToEdit])

  async function modifyRecord(values){
    console.log('modifyRecord', values)
    const index = values.index
    const newData = values.newData
    const oldData = values.oldData

    const recordId = oldData._id

    const trainingRecord = {
      name: newData.name,
      type: newData.type,
      hours: parseInt(newData.hours, 10)
    }

    // Update the database
    let databaseObj = {
      weekOf: week,
      store: selectedStore,
      day: day,
      record: trainingRecord
    }

    let databaseRecord = JSON.stringify(databaseObj)
    
    try {
      let result = await API.graphql({
        query: dbEditItem,
        variables: {
          db: 'grm.trainings',
          dbItem: databaseRecord,
          dbItemId: recordId
        }
      })
      console.log('Successful training record update', result)
      const updatedItem = JSON.parse(result.data.dbEditItem.statusItem)
      const updatedObject = JSON.parse(updatedItem)
      
      /** UPDATE TABLE DATA **/
      // Update raw data
      // Find the store that record belongs to
      let dataCopy = [...data]
      let dataIdx = dataCopy.findIndex(x => x.store === selectedStore)
      if(dataIdx !== -1){
        // Find the day that the record belongs to
        let dayIdx = dataCopy[dataIdx].data.findIndex(x => x.day === day)
        if(dayIdx !== -1){
          let hoursIdx = dataCopy[dataIdx].data[dayIdx].hours.findIndex(x => x._id === recordId)
          if(hoursIdx !== -1){
            dataCopy[dataIdx].data[dayIdx].hours[hoursIdx] = trainingRecord
            setData(dataCopy)
          } else {
            console.log('Error: record to update not found')
          }
        } else {
          console.log('Error: day for updated record not found')
        }
      } else {
        console.log('Error: store for updated record not found')
      }
    } catch (err) {
      console.log('Error modifying training record', err)
    }
    setOpenEdit(false)
  }

  const cancelEdit = () => {
    setRowToEdit(null)
    setOpenEdit(false)
  }

  async function removeTraining(row){
    console.log('Delete', row)
    const recordId = row.original._id
    const values = row.values

    // Update the database
    const trainingRecord = {
      name: values.name,
      type: values.type,
      hours: parseInt(values.hours, 10)
    }

    let databaseObj = {
      weekOf: week,
      store: selectedStore,
      day: day,
      record: trainingRecord
    }

    let databaseRecord = JSON.stringify(databaseObj)

    try {
      let result = await API.graphql({
        query: dbDeleteItem,
        variables: {
          db: 'grm.trainings',
          dbItem: databaseRecord,
          dbItemId: recordId
        }
      })
      console.log('Successfully deleted record from database', result)

      /** UPDATE TABLE DATA **/
      // Update raw data
      // Find the store that record belongs to
      let dataCopy = [...data]
      let dataIdx = dataCopy.findIndex(x => x.store === selectedStore)
      if(dataIdx !== -1){
        // Find the day that the record belongs to
        let dayIdx = dataCopy[dataIdx].data.findIndex(x => x.day === day)
        if(dayIdx !== -1){
          let hoursIdx = dataCopy[dataIdx].data[dayIdx].hours.findIndex(x => x._id === recordId)
          if(hoursIdx !== -1){
            dataCopy[dataIdx].data[dayIdx].hours.splice(hoursIdx, 1)
            
            setData(dataCopy)
          } else {
            console.log('Error: record to update not found')
          }
        } else {
          console.log('Error: day for updated record not found')
        }
      } else {
        console.log('Error: store for updated record not found')
      }

    } catch (err) {
      console.log('Error deleting record', err)
      
    }
  }

  return(
    <>
    { dataLoading ?
      <Progress />:
      <MDBox textAlign='center'>
        {/* Report Header */}
        <MDBox
          px={3}
          pt={1}
          sx={{
            backgroundColor: '#29261f'
          }}
        >
          <Grid container pt={2}>
            <Grid
              item
              xs={4}
              sx={{
                display: 'flex',
                justifyContent: 'flex-start'
              }}
            >
              <MDTypography
                variant='h6'
                fontWeight='medium'
                style={{
                  color: '#cf9e0b'
                }}
              >
                { title }
              </MDTypography>
            </Grid>
            <Grid item xs={8}>
              <Stack
                direction='row'
                justifyContent='space-between'
                spacing={3}
                mb={0}
              >
                <Autocomplete_GrmHead 
                  id='weekSelector'
                  options={ weeks }
                  value={ week }
                  onChange={ handleWeek }
                  disableClearable={ true }
                  style={{ width: 120 }}
                  renderInput={(params) => 
                    <TextField 
                      {...params}
                      label='Week'
                      variant='outlined'
                      size='small'
                    />
                  }
                />
                <Autocomplete_GrmHead 
                  id='daySelector'
                  options={[
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                    'Saturday',
                    'Sunday'
                  ]}
                  value={ day }
                  onChange={ handleDay }
                  disableClearable={ true }
                  style={{ width: 120 }}
                  renderInput={(params) => 
                    <TextField 
                      {...params}
                      label='Day'
                      variant='outlined'
                      size='small'
                    />
                  }
                />
                <Autocomplete_GrmHead
                  id='storeSelector'
                  options={ stores }
                  value={ selectedStore }
                  onChange={ handleStoreSelection }
                  disableClearable={true}
                  style={{ width: 200 }}
                  size='small'
                  renderInput={(params) => 
                    <TextField 
                      {...params}
                      label='Store'
                      variant='outlined'
                    />
                  }
                />
                <Stack
                  direction='row'
                  justifyContent='flex-end'
                  spacing={3}
                >
                  <Tooltip title='Menu'>
                    <Button_GrmHead
                      variant='gradient'
                      iconOnly
                      onClick={() => props.setPage('actions')}
                    >
                      <Icon fontSize='large'>
                        menu
                      </Icon>
                    </Button_GrmHead>
                  </Tooltip>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </MDBox>
        {/* Report Body */}
        <MDBox textAlign='left'>
          { openAdd &&
            <R0003AddTrainingDialog 
              open={ openAdd }
              submit={ submitTraining }
              cancel={ cancelTraining }
              week={ week }
              day={ day }
              store={ selectedStore }
            />
          }
          {
            openEdit &&
            <R0003EditTrainingDialog
              open={ openEdit }
              submit={ modifyRecord }
              cancel={ cancelEdit }
              rowData={ rowToEdit }
              week={ week }
              day={ day }
              store={ selectedStore }
            />
          }
          <MDBox sx={{
            backgroundColor: '#f9e9d3'
          }}>
            <MDBox
              px={3}
              py={1}
              sx={{
                backgroundColor: '#f9e9d3',
                color: '#000'
              }}
            >
              <MDTypography variant='h6'>
                { sumTitle }
              </MDTypography>
              <RDTable 
                table={{
                  columns: sumColumns,
                  rows: sumData
                }}
                editable={{
                  onRowAdd: null,
                  onRowEdit: null,
                  onRowDelete: null
                }}
                cellStyle={
                  R0003SumCellStyle
                }
                tableOptions={{
                  style: {
                    day: day
                  }
                }}
                showTotalEntries={false}
              />
            </MDBox>

            <Divider />

            <MDBox
              px={3}
              py={1}
              sx={{
                backgroundColor: '#f9e9d3',
                color: '#000'
              }}
            >
              <MDTypography variant='h6'>
                { recordsTitle }
              </MDTypography>
              <RDTable 
                table={{
                  columns: columns,
                  rows: tableData
                }}
                editable={{
                  onRowAdd: () => setOpenAdd(true),
                  onRowEdit: noData ? null : row => setRowToEdit(row),
                  onRowDelete: noData ? null : row => removeTraining(row)
                }}
                cellStyle={
                  R0003TrainingCellStyle
                }
                showTotalEntries={false}
                entriesPerPage={{
                  defaultValue: 15
                }}
              />
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    }
    </>
  )
}

function generateWeeks(startWeek){
  const today = new Date()
  let week = new Date(startWeek)
  const oneWeekFromToday = addWeeks(today, 1)

  let weeks = []
  while( week < oneWeekFromToday ) {
    weeks.unshift(format(week, 'yyyy-MM-dd'))
    week = addWeeks(week, 1)
  }
  return weeks
}

const headerStyle = {
  backgroundColor: '#bdbdbd',
  color: '#000000',
  borderRight: '1px solid #fff',
  borderBottom: 'none',
  padding: '2px',
  fontSize: '.65rem'
}

const sumColumns = [
  {
    Header: 'Type',
    accessor: 'type',
    disableGroupBy: true,
    disableSortBy: true,
    align: 'center',
    columnStyle: headerStyle
  },
  {
    Header: 'Total Hours',
    accessor: 'total',
    disableGroupBy: true,
    disableSortBy: true,
    align: 'center',
    columnStyle: headerStyle
  },
  {
    Header: 'Mon',
    accessor: 'mon',
    disableGroupBy: true,
    disableSortBy: true,
    align: 'center',
    columnStyle: headerStyle
  },
  {
    Header: 'Tue',
    accessor: 'tue',
    disableGroupBy: true,
    disableSortBy: true,
    align: 'center',
    columnStyle: headerStyle
  },
  {
    Header: 'Wed',
    accessor: 'wed',
    disableGroupBy: true,
    disableSortBy: true,
    align: 'center',
    columnStyle: headerStyle
  },
  {
    Header: 'Thu',
    accessor: 'thu',
    disableGroupBy: true,
    disableSortBy: true,
    align: 'center',
    columnStyle: headerStyle
  },
  {
    Header: 'Fri',
    accessor: 'fri',
    disableGroupBy: true,
    disableSortBy: true,
    align: 'center',
    columnStyle: headerStyle
  },
  {
    Header: 'Sat',
    accessor: 'sat',
    disableGroupBy: true,
    disableSortBy: true,
    align: 'center',
    columnStyle: headerStyle
  },
  {
    Header: 'Sun',
    accessor: 'sun',
    disableGroupBy: true,
    disableSortBy: true,
    align: 'center',
    columnStyle: headerStyle
  },
]

const columns = [
  {
    Header: 'Type',
    accessor: 'type',
    disableGroupBy: false,
    disableSortBy: false,
    align: 'center',
    width: 75,
    columnStyle: headerStyle
  },
  {
    Header: 'Name',
    accessor: 'name',
    disableGroupBy: true,
    disableSortBy: false,
    align: 'center',
    width: 200,
    columnStyle: headerStyle
  },
  {
    Header: 'Hours',
    accessor: 'hours',
    disableGroupBy: true,
    disableSortBy: false,
    align: 'center',
    width: 75,
    columnStyle: headerStyle
  },
]