import { memo } from 'react'
import PropTypes from 'prop-types'
import MDBox from 'components/MDBox'

export default function RDFormatBodyCell({
  children,
  style = {}, 
  width = 'auto',
  conditions,
  compType,
  ...rest
}){
  // console.log('children', children)
  const column = children?.props?.column?.id
  const { row } = children?.props || {}
  // const { rowType } = row.original
  const value = row?.original  

  // Default cell styles if none are provided
  const defaultCellStyle = {
    textAlign: 'center',
    bgColorCompany: '#fff',
    bgColorPrevious: '#fff',
    borderBottom: 'none',
    borderLeft: 'none',
    borderTop: 'none',
    fontWeight: 'normal',
    paddingLeft: 0,
    textColorCompany: '#000',
    textColorPrevious: '#000'
  }

  // console.log('column', column)
  // console.log('row', row)

  // let bgColor = '#fff'
  // let borderBottom = null
  // let borderLeft = null
  // let borderTop = null
  // let textColor = '#000'
  // let fontWeight = null
  // let paddingLeft = 0
  // let textAlign = 'center'

  // let cellFormats = {
  //   bgColor: '#fff',
  //   textColor: '#000'
  // }

  // let thresholdType = null
  // let compName, curName

  // //Common Formatting for table
  // switch(rowType){
  //   case 'bottomBorderRow':
  //     borderBottom = '3px solid #000'
  //     fontWeight = 'bold'
  //     break;
  //   case 'managerRow':
  //     bgColor = '#a44c0e'
  //     textColor = '#fff'
  //     fontWeight = 'bold'
  //     textAlign = 'center'
  //     break;
  //   case 'storeRow':
  //     // textAlign = 'left'
  //     paddingLeft = '10px'
  //     textAlign = column === 'store' ? 'left' : 'center'
  //     break;
  //   case 'summaryRow':
  //     fontWeight = 'bold'
  //     break;
  //   case 'totalRow':
  //     fontWeight = 'bold'
  //     borderTop = '3px solid #000'
  //     break;
  //   default:
  // }

  // switch(column){
  //   case 'pNSTotal':
  //   case 'cNSDineIn':
  //   case 'cCCTotal':
  //   case 'cACTotal':
  //   case 'cSPLHTotal':
  //   case 'cCCTotal':
  //   case 'cOTTotal':
  //   case 'cLaborPct':
  //     borderLeft = '3px solid #000'
  //     break;
  //   default:
  //     // No action
  // }

  // // config settings for conditionally formated cells
  // let tipType
  // switch(rowType){
  //   case 'storeRow':
  //   case 'totalRow':
  //     tipType = value.tipType
  //     switch(column){
  //       case 'cNSTotal':
  //       case 'pct':
  //       case 'cNSDineIn':
  //       case 'cNSToGo':
  //       case 'cNSDelivery':
  //       case 'cGSDelivery':
  //         thresholdType = 'netSales'
  //         break;
  //       case 'cCCTotal':
  //       case 'cCCDineIn':
  //       case 'cCCToGo':
  //       case 'cCCDelivery':
  //         thresholdType = 'checkCounts'
  //         break;
  //       case 'cACTotal':
  //       case 'cACDineIn':
  //       case 'cACToGo':
  //       case 'cACDelivery':
  //         thresholdType = 'avgCheck'
  //         break;
  //       case 'cSPLHTotal':
  //       case 'cFOH':
  //       case 'cBOH':
  //         thresholdType = 'splh'
  //         break;
  //       case 'cOTTotal':
  //       case 'cOTFOH':
  //       case 'cOTBOH':
  //         thresholdType = 'overtime'
  //         break;
  //       case 'cLaborPct':
  //         thresholdType = 'labor'
  //         break;
  //       default:
  //         thresholdType = null
  //     }
  //     break;
  //   default:
  //     thresholdType = null
  // }

  // if(thresholdType !== null){    
    
  //   if(column === 'pct'){
  //     if(tipType === 'company'){
  //       curName = 'cNSTotal'
  //       compName = 'compNSTotal'
  //     } else {
  //       curName = 'cNSTotal'
  //       compName = 'pNSTotal'
  //     }
  //   } else {
  //     curName = column
  //     compName = getCompName(curName, tipType)
  //   }
    
  //   cellFormats = getCellFormats(thresholdType, curName, compName, value, conditions)

  //   bgColor = cellFormats.bgColor
  //   textColor = cellFormats.textColor
  // }

  // const tdSetting = {
  //   backgroundColor: bgColor,
  //   borderBottom: borderBottom,
  //   borderLeft: borderLeft,
  //   borderTop: borderTop,
  //   fontWeight: fontWeight,
  //   paddingLeft: paddingLeft,
  // }

  const cellStyle = value?.styles?.[column] || defaultCellStyle
  
  return (
    <MDBox
      component='td'
      display='flex'  
      alignItems='center'   
      justifyContent={cellStyle.textAlign}
      style={{
        backgroundColor: compType ? cellStyle.bgColorCompany : cellStyle.bgColorPrevious,
        borderBottom: cellStyle.borderBottom,
        borderLeft: cellStyle.borderLeft,
        borderTop: cellStyle.borderTop,
        fontWeight: cellStyle.fontWeight,
        paddingLeft: cellStyle.paddingLeft,
      }}
      width={width}
    >
      <MDBox
        {...rest}
        color={compType ? cellStyle.textColorCompany : cellStyle.textColorPrevious}
        sx={{
          fontSize: style.fontSize
        }}
      >
        {children}
      </MDBox>
    </MDBox>
  )
  
}

function getCellFormats(
  type,
  curName,
  compName,
  values,
  conditions,
){
  const store = values.store  
  let bgColor = '#fff'
  let textColor = '#000'
  if(store !== 'Total'){
    const cur = parseInt(toNum(values[curName]))
    const comp = parseInt(toNum(values[compName]))

    let cIdx, tIdx, thresholdId
    
    switch(type){
      case 'avgCheck':
        thresholdId = values.thresholds[type]
        
        const thresholds = getThresholds(type, thresholdId, conditions, store)
        
        if(cur === 0){
          bgColor = 'grey'
        } else if(cur >= comp + thresholds.highTH){
          bgColor = 'green'
          textColor = '#fff'
        } else if(cur > comp && cur < comp + thresholds.highTH){
          bgColor = 'yellow'
        } else {
          bgColor = 'red'
          textColor = '#fff'
        }
        break;
      case 'checkCounts':
        if(store !== 'Total'){
          bgColor = cur >= comp ? 'green' : 'red'
          textColor = '#fff'
        }
        break;
      case 'netSales':
        let pct = (comp !== 0) ? (cur - comp)/comp * 100 : 0 

        const assignedTH = values.thresholds[type]
        
        cIdx = conditions.findIndex(x => x.type === type)
        if(cIdx !== -1){
          tIdx = conditions[cIdx].thresholds.findIndex(x => x._id === assignedTH)
          if(tIdx !== -1){
            const { lowValue, highValue } = conditions[cIdx].thresholds[tIdx]
            if(pct >= highValue || (pct === 0 && cur > 0)){
              bgColor = 'green'
              textColor = '#fff'
            } else if(pct > lowValue && pct < highValue){
              bgColor = 'yellow'
            } else {
              bgColor = 'red'
              textColor = '#fff'
            }
          } 
        } 
        break;
      case 'splh':        
        let thName
        switch(curName){
          case 'cSPLHTotal':
            thName = 'splhTotal'
            break;
          case 'cBOH':
            thName = 'splhBOH'
            break;
          default:
            thName = 'splhFOH'
        }
        thresholdId = values.thresholds[thName]
        const { lowTH, highTH } = getThresholds(thName, thresholdId, conditions)
        
        const diff = cur - comp

        if(diff >= highTH / 100){
          bgColor = 'green'
          textColor = '#fff'
        } else if(diff > lowTH /100 && diff < highTH /100 ){
          bgColor = 'yellow'
        } else {
          bgColor = 'red'
          textColor = '#fff'
        }
        
        break;
      case 'overtime':
      case 'labor':
        // GREEN and RED are reversed        
        bgColor = cur <= comp ? 'green' : 'red'
        textColor = '#fff'        
        break;
      default:
        bgColor = '#fff'
        textColor = '#000'
    }
  }

  return {
    bgColor: bgColor,
    textColor: textColor
  }
}

function getCompName(curName, tipType){
  if(curName.length === 0){
    return curName
  }
  if(tipType === 'company'){
    return 'comp' + curName.slice(1)
  } else {
    return 'p' + curName.slice(1)
  }
}

function getThresholds(type, assigned, options){
  
  const cIdx = options.findIndex(x => x.type === type)
  if(cIdx === -1){
    return { lowTH: null, highTH: null }
  }

  const tIdx = options[cIdx].thresholds.findIndex(x => x._id === assigned)
  if(tIdx === -1){
    return {lowTH: null, highTH: null }
  }
  
  const { lowValue, highValue } = options[cIdx].thresholds[tIdx]  
  
  return {
    lowTH: lowValue ?? null,
    highTH: highValue ?? null
  }
}

function toNum(str){
  if(str == null){
    return str
  } else {
    let newStr = str.replace(/\D/g,'')
    return newStr
  }
}

// RDFormatBodyCell.defaultProps = {
//   width: 'auto'
// }

// RDFormatBodyCell.propTypes = {
//   children: PropTypes.node.isRequired,
//   options: PropTypes.object,
//   style: PropTypes.object,
//   styleFunc: PropTypes.func,
//   width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
// }

// Add PropTypes for better development experience and documentation
RDFormatBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.shape({
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  conditions: PropTypes.array,
  compType: PropTypes.bool
}

// Add default props
RDFormatBodyCell.defaultProps = {
  style: {},
  width: 'auto',
  conditions: [],
  compType: false
}