import { Box, Link, Stack, Typography } from '@mui/material';

import { useTheme } from '@mui/material/styles';

// Material Dashboard 2 PRO React base styles
import typography from "assets/theme/base/typography";

export default function FooterBase({
  left,  
  center, 
  right,
  styles
}) {

  const theme = useTheme()

  return (
    <Box 
      sx={{
        ...styles, 
        display: 'flex', 
        bgcolor: theme.palette.grey[200] 
      }}
    >
      <Stack 
        direction={{
          xs: 'column',
          md: 'row'
        }}
        spacing={.5}
        sx={{ width: '100%'}} 
      >

        <Box sx={{flex: 1, textAlign: 'left'}}>  
          {left === 'copyright' ? <Copyright /> : left }
        </Box>

        <Box sx={{flex: 1, textAlign: 'center'}}>
          {center === 'copyright' ? <Copyright /> : center}
        </Box>

        <Box sx={{flex: 1, textAlign: 'right', pr: 1 }}>
          {right === 'copyright' ? <Copyright /> : right} 
        </Box>
      
      </Stack>
    </Box>
  );

}

const Copyright = () => {
  
  const { size } = typography

  return (
    <Box
      sx={{
        fontSize: size.sm
      }}
    >
      Copyright &copy; {new Date().getFullYear()} | 
      <Link 
        href={'https://radixdash.com'} 
        target='_blank'
      >
        <Typography variant='button' fontWeight='medium'>
          &nbsp; RadixDash LLC &nbsp;
        </Typography>
      </Link>
    </Box>
  )
}