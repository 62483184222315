// @mui icons
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// Admin Pages
import UserAdmin from 'clients/admin/UserAdmin/UserAdmin'
import RolesAdmin from 'clients/admin/RolesAdmin/RolesAdmin'
import StoreAdmin from 'clients/admin/StoreAdmin/StoreAdmin2'
import ReportAdmin from 'clients/admin/ReportAdmin/ReportAdmin'
import DashboardAdmin from 'clients/admin/DashboardAdmin/DashboardAdmin'

/************************************************
 * Client-specific pages
 ************************************************/
import Home from 'clients/dem/views/Home'
import R0001Main from 'clients/dem/views/R0001/R0001Main'
import R0002Main from 'clients/dem/views/R0002/R0002Main'

const reportViews = {
  r0001: {
    name: 'KPI Report',
    key: 'r0001',
    route: '/reports/r0001',
    component: <R0001Main />
  },
  r0002: {
    name: 'Summary Report',
    key: 'r0002',
    route: '/reports/r0002',
    component: <R0002Main />
  }
}

const adminViews = {
  userAdmin: {
    name: "User Admin",
    key: 'user-admin',
    route: "/userAdmin",
    component: <UserAdmin />,
  },
  storeAdmin: {
    name: "Store Admin",
    key: 'store-admin',
    route: "/storeAdmin",
    component: <StoreAdmin />,
  },
  reportAdmin: {
    name: "Report Admin",
    key: 'report-admin',
    route: "/reportAdmin",
    component: <ReportAdmin />,
  },
  dashboardAdmin: {
    name: "Dashboard Admin",
    key: 'dashboard-admin',
    route: '/dashboardAdmin',
    component: <DashboardAdmin />
  },
  rolesAdmin: {
    name: "Roles Admin",
    key: 'roles-admin',
    route: '/rolesAdmin',
    component: <RolesAdmin />
  },
}

export default function demRoutes(displayName, icon, role, permissions) {
  
  let reportViewArr = []
  let adminViewArr = []

  let dashboardViewArr = [
    {
      type: 'collapse',
      name: displayName,
      key: displayName,
      href: '#',
      icon: <MDAvatar src={icon} alt={displayName} size='sm' />
    },
    {
      type: 'divider', key: 'divider-1'
    },
    {
      type: 'collapse',
      name: 'Dashboards',
      key: 'dashboards',
      icon: <Icon fontSize='medium'>dashboard</Icon>,
      collapse: [
        {
          name: 'Home',
          key: 'home',
          route: '/dashboards/home',
          component: <Home />
        }
      ]
    }
  ]

  // Setup Admin Views
  switch(role) {
    case 'Admin':
      adminViewArr[0] = adminViews.userAdmin
      adminViewArr[1] = adminViews.storeAdmin
      adminViewArr[2] = adminViews.reportAdmin
      adminViewArr[3] = adminViews.dashboardAdmin
      adminViewArr[4] = adminViews.rolesAdmin
      break;
    case 'AreaMgr':
    case 'GenMgr':
      adminViewArr[0] = adminViews.storeAdmin
      break;
    default:
      // No action, leave as empty array
  }
  
  // Setup Report Views
  let arrIdx = 0
  for(let i = 0; i < permissions.length; i++){
    let report = permissions[i].report
    report = report.toLowerCase()
    if(report in reportViews){
      let viewPermissions = permissions[i].viewPermissions
      if(viewPermissions !== 'no-permission'){
        reportViewArr[arrIdx] = reportViews[report]
        arrIdx += 1
      }
    }
  }
 
  // Create Routes
  let routes = dashboardViewArr

  if(reportViewArr.length > 0){
    let reportRoutes = {
      type: 'collapse',
      name: 'Reports',
      key: 'reports',
      icon: <Icon fontSize='medium'>format_list_numbered_icon</Icon>,
      collapse: reportViewArr,  
    }
    routes.push(reportRoutes)
  }

  if(adminViewArr.length > 0) {
    let adminRoutes = {
      type: 'collapse',
      name: "Admin Tools",
      key: 'admin-tools',
      icon: <Icon fontSize='medium'>content_paste</Icon>,
      collapse: adminViewArr,
    }
    routes.push(adminRoutes)
  }

  let finalDivider = {
    type: 'divider', key: 'final-divider'
  }

  routes.push(finalDivider)
  
  return routes
}