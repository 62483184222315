import { useEffect, useState } from 'react'

import useAreas from './useAreas'
import useProfiles from 'clients/admin/ReportAdmin/R0005/ThresholdProfiles/useProfiles'
import useThresholdMap from 'hooks/useThresholdMap'

import { QueryStatus } from 'utils/queryUtils'

import { sortObjectArray } from 'utils/helperUtils'

export default function useAreaThresholds(){

  const [data, setData] = useState([])
  const [status, setStatus] = useState(QueryStatus.IDLE)

  const { data: areas, status: areaStatus, error: areaError } = useAreas()
  const { data: profiles, status: profileStatus, error: profileError } = useProfiles()
  const { data: map, status: mapStatus, error: mapError } = useThresholdMap()

  useEffect(() => {
    setStatus(QueryStatus.PENDING)
  }, [])

  useEffect(() => {
    if( areaStatus === 'success' &&
        profileStatus === 'success' &&
        mapStatus === 'success'){
          
      let assignmentArr = []

      areas.forEach(area => {
        
        // get profileId assigned to this area
        const mapItem = map?.filter(p => p.extEntityId === area.areaId)

        // get profileName of assigned profileId
        let profileName = null
        let thresholdId = null
        if(mapItem.length > 0){
          thresholdId = mapItem[0].thresholdId
          const profile = profiles.filter(p => p.id === thresholdId)
          if(profile.length > 0){   
            profileName = profile[0].name 
          } else {
            // profile doesn't exist
            profileName = null 
            thresholdId = 'invalid'
          }
        }

        // create assignment object
        let assignmentObj = {
          id: mapItem.length > 0 ? mapItem[0].id : null,
          areaId: area.areaId,
          area: area.areaName,
          profile: profileName,
          profileId: thresholdId
        }
        

        assignmentArr.push(assignmentObj)
        
      })

      assignmentArr = sortObjectArray(assignmentArr, 'area')
      setData(assignmentArr)
      setStatus(QueryStatus.SUCCESS)
    }    

  }, [areas, profiles, map])

  return {
    data,
    profiles,
    status
  }
  
}