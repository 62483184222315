import { useEffect, useState } from 'react'

// @mui components
import { Box, Button, Dialog, DialogTitle, DialogContent, Stack, Typography } from '@mui/material'

// formik components
import { FieldArray, Field, Formik, Form } from 'formik'
import Select_Rd from 'components/FormsUI/Select/Select_Rd'
import Textfield from 'components/FormsUI/Textfield'
import * as Yup from 'yup'

import { MuiColorInput } from 'mui-color-input';

import SampleFormatBox from 'components/common/elements/SampleFormatBox';


const operators = {
  EQ: 'EQ',
  NEQ: 'NEQ',
  GT: 'GT',
  GTE: 'GTE',
  LT: 'LT',
  LTE: 'LTE',
  BTW: 'BTW',
}

const fontWeights = {
  normal: 'normal',
  bold: 'bold',
}

export default function RuleDialog({
  close,
  open,
  rule,
  onAddRule,
  onEditRule,
}){
  console.log('rule', rule)
  
  const [newRule, setNewRule] = useState(null)

  useEffect(() => {
    if(rule == null){
      setNewRule(true)
    } else {
      setNewRule(false)
    }
  }, [rule])

  const INITIAL_FORM_STATE = {
    position: rule !== null ? rule.position : '',
    operator: rule !== null ? rule.operator : '',
    numberValue: rule !== null ? rule.numberValue : [],
    backgroundColor: rule !== null ? rule.backgroundColor : '#ffffff',
    color: rule !== null ? rule.color : '#000000',
    fontWeight: rule !== null ? rule.fontWeight : 'normal',
  }

  const FORM_VALIDATION = Yup.object().shape({
    operator: Yup.string()
      .required('Operator Required'),
    numberValue: Yup.array()
      .of(Yup.number())
      .min(1, 'Must have at least one value')
      .when('operator', {
        is: 'BTW',
        then: Yup.array()
          .required()
          .length(2, 'Between operator must have upper and lower bound')
          .test({
            name: 'lessThan',
            exclusive: true,
            message: 'Lower bound must be less than upper bound',
            test: (value) => value[0] < value[1]
          })
      })
      .required('Value Required'),
    backgroundColor: Yup.string()
      .required('Background Color Required'),
    color: Yup.string()
      .required('Text Color Required'),
    fontWeight: Yup.string()
      .required('Font Style Required')
  })

  function handleSubmit(values){
    console.log('handleSubmit', values) 
    newRule ? 
      onAddRule(values) :
      onEditRule(values)
  }

  return (
    <Dialog
      open={open}
    >
      <DialogTitle>
        Rule Editor
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={INITIAL_FORM_STATE}
          validationSchema={FORM_VALIDATION}
          onSubmit={(values) => {handleSubmit(values)}}
        >
          { formik => {
            const { isValid, dirty, errors } = formik
          
            return (
            <Form>
              <Stack
                direction='column'
                spacing={2}
                sx={{
                  mt: 2
                }}
              >
                <Select_Rd 
                  name='operator'
                  options={operators}
                  label='Operator'
                />
                <FieldArray name='numberValue'>
                  {() => {
                    const showUpperBound = formik.values.operator === 'BTW';

                    return (
                      <>
                        <Field name='numberValue[0]'>
                          {({field}) => (
                            <Textfield 
                              {...field} 
                              label={showUpperBound ? 'Percent Lower Bound' : 'Percent'} 
                            />
                          )}
                        </Field>
                        {showUpperBound && (
                          <Field name='numberValue[1]'>
                            {({field}) => (
                              <Textfield 
                                {...field} 
                                label='Percent Upper Bound'
                              />
                            )}
                          </Field>
                        )}
                      </>
                    )
                  }}
                </FieldArray>
                <Stack direction='column' spacing={0}>
                  <Typography variant='caption'>
                    Background Color
                  </Typography>
                  <MuiColorInput 
                  value={formik.values.backgroundColor}
                  format='hex'
                  onChange={color => formik.setFieldValue('backgroundColor', color)}
                />
                </Stack>
                <Stack direction='column' spacing={0}>
                  <Typography variant='caption'>
                    Text Color
                  </Typography>
                  <MuiColorInput 
                  value={formik.values.color === null ? (formik.setFieldValue('color', '#000000')) : (formik.values.color)}
                  // value={formik.values.color}
                  format='hex'
                  onChange={color => formik.setFieldValue('color', color)}
                  />  
                </Stack>

                <Select_Rd 
                  name='fontWeight'
                  options={fontWeights}
                  label='Font Weight'
                />
                
                <SampleFormatBox 
                  backgroundColor={formik.values.backgroundColor}
                  textColor={formik.values.color === null ? ('black') : (formik.values.color)}
                  fontWeight={formik.values.fontWeight}
                />
                <div>{console.log('formik', formik)}</div>
                
                <Stack
                  direction='row'
                  spacing={2}
                  justifyContent='space-around'
                >
                  <Button
                    variant='contained'
                    onClick={close}
                    sx={{
                      width: 100
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant='contained'
                    type='submit'
                    disabled={!(dirty && isValid)}
                    sx={{
                      width: 100
                    }}
                  >
                    Save
                  </Button>
                </Stack>
              </Stack>
            </Form>
          )}}
        </Formik>        
      </DialogContent>
    </Dialog>
  )
}
