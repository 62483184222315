export default function R0003SumCellStyle(row, cell, tableStyle){
  // console.log('row', row)
  // console.log('cell', cell)
  // console.log('tableStyle', tableStyle)
  
  let leftBorder = ''
  let topBorder = '1px solid #e0e0e0'
  let cellColor = 'white'
  let fontSize = '.7rem'
  let padding = 0
  let paddingTop = '1px'
  let paddingRight = 0
  let paddingBottom = '1px'
  let paddingLeft = 0
  let textColor = 'black'
  let fontStyle = 'normal'
  let fontWeight = 'normal'
  let textAlign = 'center'
  let width = 75

  const day = tableStyle.day.substring(0,3)

  switch(cell.column.Header){
    case day:
      cellColor = '#29261f'
      textColor = '#cf9e0b'
      break;
    case 'Total Hours':
      fontWeight = 'bold'
      break;
    case 'Type':
      cellColor = '#bdbdbd'
      textColor = '#000000'
      fontWeight = 'bold'
    default:
      // No Action
  }
  // if(cell.column.Header === day){
  //   cellColor = '#29261f'
  //   textColor = '#cf9e0b'
  // }

  const setting = {
    backgroundColor: cellColor,
    borderLeft: leftBorder,
    borderTop: topBorder,
    color: textColor,
    fontSize: fontSize,
    fontStyle: fontStyle,
    fontWeight: fontWeight,
    paddingTop: paddingTop,
    paddingRight: paddingRight,
    paddingBottom: paddingBottom,
    paddingLeft: paddingLeft,
    textAlign: textAlign, 
  }

  return setting
}