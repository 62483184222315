import { useEffect } from 'react'

import { API, graphqlOperation } from 'aws-amplify'

import { onCreateThreshold, onCreateThresholdMapping, onUpdateThreshold, onUpdateThresholdMapping, onDeleteThreshold, onDeleteThresholdMapping } from 'graphql/subscriptions'

import { Autocomplete, Box, CircularProgress, Button, Stack, TextField, Typography } from '@mui/material'

import useAreaThresholds from 'hooks/clients/grm/useAreaThresholds'

import createAssignment from './createAssignment'
import updateAssignment from './updateAssignment'

export default function ThresholdAssignments({
  width
}){
  console.log('ThresholdAssignments', width)

  return (
    <Box
      sx={{
        width: width,
      }}
    >
      <TAHeader />
      <TAList />
    </Box>
  )
}

function TAHeader(){
  const title = 'Threshold Profile Assignments'

  return (
    <Box
      sx={{
        pl: 2,
        pt: 1,
        width: '100%',
        height: '50px',
        bgcolor: '#ffffff',
        borderBottom: '1px solid #000',
        borderRadius: '5px 5px 0px 0px'
      }}
    >
      <Typography >
        {title}
      </Typography>
    </Box>
  )
}

function TAList(){
  
  const { data, profiles, status } = useAreaThresholds()

  useEffect(() => {
    const interval = setInterval(() => {
      API.graphql(graphqlOperation(onCreateThreshold))
      API.graphql(graphqlOperation(onCreateThresholdMapping)) 
      API.graphql(graphqlOperation(onUpdateThreshold))
      API.graphql(graphqlOperation(onUpdateThresholdMapping)) 
      API.graphql(graphqlOperation(onDeleteThreshold))
      API.graphql(graphqlOperation(onDeleteThresholdMapping)) 
    }, 90 * 1000)

    return () => clearInterval(interval)
  }, [])

  const isLoading = !(data.length > 0) && status === 'pending'

  const options = profiles.map(p => {
    return ({
      id: p.id,
      label: p.name,
    })
  })

  function handleProfileSelection(event, profile, map){
    
    let assignment = {
      thresholdId: profile.id
    }

    if(map.profileId !== null){
      assignment.id = map.id
      updateAssignment(assignment)
    } else {
      assignment.extEntityId = map.areaId
      createAssignment(assignment)
    }
  }
  
  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: '#ffffff',
        borderRadius: '0px 0px 5px 5px',
        boxShadow: 1,
        py: 2
      }}
    >
      {
        isLoading ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CircularProgress 
              color='success'
            />
          </Box>
        ) : (
          <Box
            width='70%'
            sx={{
              mx: 'auto',
            }}
          >
            <Stack 
              direction='column'
              spacing={2}
            >
              { data.map(map => (
                <Autocomplete 
                  key={map.id}
                  options={options}
                  value={map.profile}
                  disableClearable
                  size='small'
                  onChange={(event, profile) => handleProfileSelection(event, profile, map)}
                  renderInput={(params) => (
                    <TextField 
                      {...params}
                      label={`${map.area} Profile`}
                      variant='outlined'
                    />
                  )}
                />
              ))}
            </Stack>
          </Box>
        )
      }
    </Box>
  )
}