import React, { useEffect, useState } from 'react'

import { Button, Checkbox, Grid, IconButton, InputAdornment, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Paper, Stack, TextField } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { ThemeProvider } from '@mui/material/styles'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import Checkbox_GrmDark from 'components/Checkbox/Checkbox_GrmDark'

function not(a, b){
  return a.filter((value) => b.indexOf(value) === -1)
}

function intersection(a, b){
  return a.filter((value) => b.indexOf(value) !== -1)
}

const SearchBar = ({
  disabled,
  searchQuery, 
  setSearchQuery
}) =>{

  const clearSearch = () => {
    setSearchQuery('')
  }

  return (
    <form>
      <TextField 
        id='search-bar'
        className='text'
        onChange={(e) => {
          setSearchQuery(e.target.value)
        }}
        label='Search'
        variant='outlined'
        placeholder='Search...'
        size='small'
        value={ searchQuery }
        style={{ width: '100%' }}
        disabled={ disabled }
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              { searchQuery && (
                <IconButton onClick={clearSearch}>
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          )
        }}
      />
    </form>
  )
}

export default function RDTransferList({
  style,
  theme,
  cancel,
  submit,
  items,
  emptySubmitAllowed,
  nested,
}) {
  const [checked, setChecked] = useState([])
  const [left, setLeft] = useState(items.unSelected)
  const [right, setRight] = useState(items.selected)
  const [disabled, setDisabled] = useState(false)
  const [searchTermLeft, setSearchTermLeft] = useState('')
  const [searchResultsLeft, setSearchResultsLeft] = useState([])  
  const [searchTermRight, setSearchTermRight] = useState('')
  const [searchResultsRight, setSearchResultsRight] = useState([])

  
  useEffect(() => {
    if(right.length === 0) {
      emptySubmitAllowed
      ? setDisabled(false)
      : setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [right])

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const buttonStyle = { 
    my: 0.5,
    backgroundColor: style.bgColor,
    color: style.color,
    '&: hover': {
      backgroundColor: style.bgColorHover,
      color: style.colorHover
    },
    '&: disabled': {
      backgroundColor: '#b9ad9c',
      color: '#29261f'
    },
    width: '6rem',
    height: '2rem'
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if(currentIndex === -1){
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleAllRight = () => {
    setRight(right.concat(left))
    setLeft([])
  }

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const handleSubmit = () => {
    submit({
      selected: right,
      unSelected: left
    })
  }

  const filterData = (query, data) => {
    if(!query){
      return data
    } else {
      return data.filter((d) => d.toLowerCase().includes(query))
    }
  }

  const leftFiltered = filterData(searchTermLeft, left)
  const rightFiltered = filterData(searchTermRight, right)

  const customList = (items) => {
    return (
    <Paper sx={{ width: 300, height: 400, overflow: 'auto' }}>
      <List 
        dense 
        component="div" 
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <ThemeProvider theme={ theme }>
                  <Checkbox_GrmDark
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </ThemeProvider>
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  )};

  return (
    <>
    <Stack
      direction='column'
      spacing={2}
    >
      <Stack
        direction='row'
        spacing={2}
        justifyContent='center'
        alignItems='center'
      >
        <Stack
          direction='column'
          spacing={1}
        >
          <SearchBar 
            disabled={ left.length === 0 }
            searchQuery={searchTermLeft}
            setSearchQuery={setSearchTermLeft}
          />
          {customList(leftFiltered)}
        </Stack>
        <Stack
          direction='column'
          pt={2}
        >
          <MDButton
            sx={ buttonStyle }
            variant="gradient"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </MDButton>
          <MDButton
            sx={ buttonStyle }
            variant="contained"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </MDButton>
          <MDButton
            sx={ buttonStyle }
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </MDButton>
          <MDButton
            sx={ buttonStyle }
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </MDButton>
        </Stack>
        <Stack
          direction='column'
          spacing={1}
        >
          <SearchBar 
            disabled={ right.length === 0 } 
            searchQuery={searchTermRight} 
            setSearchQuery={setSearchTermRight}
          />
          {customList(rightFiltered)}
        </Stack>
      </Stack>
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={3}
      >
        <MDButton
          sx={ buttonStyle }
          variant="gradient"
          size="medium"
          onClick={ cancel }
          aria-label="cancel"
        >
          Cancel
        </MDButton>
        <MDButton
          sx={ buttonStyle }
          variant="gradient"
          size="medium"
          onClick={ handleSubmit }
          aria-label="submit"
          disabled={ disabled }
        >
          Submit
        </MDButton>
      </Stack>

    </Stack>
    </>
  );
}