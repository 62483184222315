import { useState } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { format, startOfWeek } from 'date-fns'
import MDDatePicker from 'components/MDDatePicker'

import { getWeekRange, minDate, maxDate } from './r0005Utils'

function R0005Config({
  selectedWeek,
  onChange,
}){
  console.log('*** R0005 Config ***')

  const [targetWeek, setTargetWeek] = useState(selectedWeek)
  
  const reportPeriod = getWeekRange(targetWeek)

  function handleDateSelection(date){
    const monday = startOfWeek(new Date(date), {weekStartsOn: 1})
    setTargetWeek(format(monday, 'yyyy-MM-dd'))
  }

  function handleClick(){
    onChange(targetWeek)
  }

  return (
    <Box >
    <Stack
      direction='column'
      justifyContent='center'
      alignItems='center'
      spacing={2}
      sx={{
        mb: 3
      }}
    >
      <DatePicker
        date={selectedWeek}
        onChange={handleDateSelection}
      />
        <Stack direction='column' alignItems='center'>
          <Typography variant='h6'>
            Target Week
          </Typography>
          <Typography variant='h6'>
          {reportPeriod.from} - {reportPeriod.to}
          </Typography>
        
        </Stack>
      <Button
        variant='contained'
        onClick={handleClick}
        disabled={selectedWeek === targetWeek}
        sx={{
          margin: 5
        }}
      >
        Update Data
      </Button>
    </Stack>
    </Box>
  )
}

export default R0005Config

function DatePicker(props){
  return (
    <Stack
      direction='column'
      spacing={.5}
      alignItems='center'
      justifyContent='center'
      marginTop={2}
    >
      <Typography variant='h6'>
        Select day in target week
      </Typography>
      <MDDatePicker
        value={props.date}
        fullWidth={false}
        options={{
          minDate: minDate,
          maxDate: maxDate,
          'locale': {
            'firstDayOfWeek': 1
          },
          weekNumbers: true,
        }} 
        onChange={ date => props.onChange(date)}
      />
    </Stack>
  )
}