/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const dbAddItem = /* GraphQL */ `
  mutation DbAddItem($db: String!, $dbItem: AWSJSON!) {
    dbAddItem(db: $db, dbItem: $dbItem) {
      statusState
      statusMessage
      statusItem
      __typename
    }
  }
`;
export const dbDeleteItem = /* GraphQL */ `
  mutation DbDeleteItem($db: String!, $dbItemId: String!, $dbItem: AWSJSON) {
    dbDeleteItem(db: $db, dbItemId: $dbItemId, dbItem: $dbItem) {
      statusState
      statusMessage
      statusItem
      __typename
    }
  }
`;
export const dbEditItem = /* GraphQL */ `
  mutation DbEditItem($db: String!, $dbItemId: String!, $dbItem: AWSJSON!) {
    dbEditItem(db: $db, dbItemId: $dbItemId, dbItem: $dbItem) {
      statusState
      statusMessage
      statusItem
      __typename
    }
  }
`;
export const createArea = /* GraphQL */ `
  mutation CreateArea(
    $areaName: String!
    $areaManager: String!
    $areaType: String!
  ) {
    createArea(
      areaName: $areaName
      areaManager: $areaManager
      areaType: $areaType
    ) {
      areaId
      areaName
      areaManager
      areaType
      stores {
        storeId
        shortName
        longName
        fohBaseTheoHrs {
          Mon
          Tue
          Wed
          Thu
          Fri
          Sat
          Sun
          __typename
        }
        bohBaseTheoHrs {
          Mon
          Tue
          Wed
          Thu
          Fri
          Sat
          Sun
          __typename
        }
        gm
        status
        type
        compStores {
          storeId
          shortName
          __typename
        }
        thresholds {
          avgCheck
          netSales
          splhTotal
          splhFOH
          splhBOH
          __typename
        }
        toastId
        __typename
      }
      __typename
    }
  }
`;
export const createCognitoUser = /* GraphQL */ `
  mutation CreateCognitoUser(
    $userPool: String!
    $userName: String!
    $email: String
    $tempPassword: String
  ) {
    createCognitoUser(
      userPool: $userPool
      userName: $userName
      email: $email
      tempPassword: $tempPassword
    ) {
      email
      userName
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $clientId: String!
    $note: String!
    $status: String!
    $position: Int!
  ) {
    createMessage(
      clientId: $clientId
      note: $note
      status: $status
      position: $position
    ) {
      messageId
      note
      status
      position
      createdAt
      __typename
    }
  }
`;
export const createPriceIncrease = /* GraphQL */ `
  mutation CreatePriceIncrease(
    $client: String!
    $year: String!
    $priceIncrease: Int!
  ) {
    createPriceIncrease(
      client: $client
      year: $year
      priceIncrease: $priceIncrease
    ) {
      year
      amount
      __typename
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole(
    $clientId: String!
    $role: String!
    $description: String!
  ) {
    createRole(clientId: $clientId, role: $role, description: $description) {
      clientId
      role
      description
      __typename
    }
  }
`;
export const createR0003Projection = /* GraphQL */ `
  mutation CreateR0003Projection($input: R0003ProjectionInput) {
    createR0003Projection(input: $input) {
      week
      store
      avgWage
      monProjSales
      monProjAvgCheck
      monSchedFOH
      monSchedBOH
      tueProjSales
      tueProjAvgCheck
      tueSchedFOH
      tueSchedBOH
      wedProjSales
      wedProjAvgCheck
      wedSchedFOH
      wedSchedBOH
      thuProjSales
      thuProjAvgCheck
      thuSchedFOH
      thuSchedBOH
      friProjSales
      friProjAvgCheck
      friSchedFOH
      friSchedBOH
      satProjSales
      satProjAvgCheck
      satSchedFOH
      satSchedBOH
      sunProjSales
      sunProjAvgCheck
      sunSchedFOH
      sunSchedBOH
      __typename
    }
  }
`;
export const createR0003StoreNotes = /* GraphQL */ `
  mutation CreateR0003StoreNotes($input: R0003StoreNotesInput) {
    createR0003StoreNotes(input: $input) {
      week
      store
      notes {
        monNotes
        tueNotes
        wedNotes
        thuNotes
        friNotes
        satNotes
        sunNotes
        __typename
      }
      __typename
    }
  }
`;
export const createStore = /* GraphQL */ `
  mutation CreateStore($input: CreateStoreInput) {
    createStore(input: $input) {
      storeId
      shortName
      longName
      fohBaseTheoHrs {
        Mon
        Tue
        Wed
        Thu
        Fri
        Sat
        Sun
        __typename
      }
      bohBaseTheoHrs {
        Mon
        Tue
        Wed
        Thu
        Fri
        Sat
        Sun
        __typename
      }
      gm
      status
      type
      compStores {
        storeId
        shortName
        __typename
      }
      thresholds {
        avgCheck
        netSales
        splhTotal
        splhFOH
        splhBOH
        __typename
      }
      toastId
      __typename
    }
  }
`;
export const deleteArea = /* GraphQL */ `
  mutation DeleteArea($areaId: String!) {
    deleteArea(areaId: $areaId) {
      areaId
      areaName
      areaManager
      areaType
      stores {
        storeId
        shortName
        longName
        fohBaseTheoHrs {
          Mon
          Tue
          Wed
          Thu
          Fri
          Sat
          Sun
          __typename
        }
        bohBaseTheoHrs {
          Mon
          Tue
          Wed
          Thu
          Fri
          Sat
          Sun
          __typename
        }
        gm
        status
        type
        compStores {
          storeId
          shortName
          __typename
        }
        thresholds {
          avgCheck
          netSales
          splhTotal
          splhFOH
          splhBOH
          __typename
        }
        toastId
        __typename
      }
      __typename
    }
  }
`;
export const deleteCognitoUser = /* GraphQL */ `
  mutation DeleteCognitoUser($userPool: String!, $userName: String!) {
    deleteCognitoUser(userPool: $userPool, userName: $userName)
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage($clientId: String!, $messageId: String!) {
    deleteMessage(clientId: $clientId, messageId: $messageId) {
      messageId
      note
      status
      position
      createdAt
      __typename
    }
  }
`;
export const deletePriceIncrease = /* GraphQL */ `
  mutation DeletePriceIncrease($client: String!, $year: String!) {
    deletePriceIncrease(client: $client, year: $year) {
      year
      amount
      __typename
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole($clientId: String!, $role: String!) {
    deleteRole(clientId: $clientId, role: $role) {
      clientId
      role
      description
      __typename
    }
  }
`;
export const deleteStore = /* GraphQL */ `
  mutation DeleteStore($areaId: String!, $storeId: String!) {
    deleteStore(areaId: $areaId, storeId: $storeId) {
      storeId
      shortName
      longName
      fohBaseTheoHrs {
        Mon
        Tue
        Wed
        Thu
        Fri
        Sat
        Sun
        __typename
      }
      bohBaseTheoHrs {
        Mon
        Tue
        Wed
        Thu
        Fri
        Sat
        Sun
        __typename
      }
      gm
      status
      type
      compStores {
        storeId
        shortName
        __typename
      }
      thresholds {
        avgCheck
        netSales
        splhTotal
        splhFOH
        splhBOH
        __typename
      }
      toastId
      __typename
    }
  }
`;
export const updateArea = /* GraphQL */ `
  mutation UpdateArea(
    $areaId: String!
    $areaName: String
    $areaManager: String
    $areaType: String
  ) {
    updateArea(
      areaId: $areaId
      areaName: $areaName
      areaManager: $areaManager
      areaType: $areaType
    ) {
      areaId
      areaName
      areaManager
      areaType
      stores {
        storeId
        shortName
        longName
        fohBaseTheoHrs {
          Mon
          Tue
          Wed
          Thu
          Fri
          Sat
          Sun
          __typename
        }
        bohBaseTheoHrs {
          Mon
          Tue
          Wed
          Thu
          Fri
          Sat
          Sun
          __typename
        }
        gm
        status
        type
        compStores {
          storeId
          shortName
          __typename
        }
        thresholds {
          avgCheck
          netSales
          splhTotal
          splhFOH
          splhBOH
          __typename
        }
        toastId
        __typename
      }
      __typename
    }
  }
`;
export const updateCognitoUser = /* GraphQL */ `
  mutation UpdateCognitoUser(
    $userPool: String!
    $userName: String!
    $email: String
  ) {
    updateCognitoUser(userPool: $userPool, userName: $userName, email: $email) {
      email
      userName
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $clientId: String!
    $messageId: String!
    $note: String
    $status: String
    $position: Float
  ) {
    updateMessage(
      clientId: $clientId
      messageId: $messageId
      note: $note
      status: $status
      position: $position
    ) {
      messageId
      note
      status
      position
      createdAt
      __typename
    }
  }
`;
export const updatePriceIncrease = /* GraphQL */ `
  mutation UpdatePriceIncrease(
    $client: String!
    $year: String!
    $priceIncrease: Int!
  ) {
    updatePriceIncrease(
      client: $client
      year: $year
      priceIncrease: $priceIncrease
    ) {
      year
      amount
      __typename
    }
  }
`;
export const updateR0003Projection = /* GraphQL */ `
  mutation UpdateR0003Projection($input: R0003ProjectionInput) {
    updateR0003Projection(input: $input) {
      week
      store
      avgWage
      monProjSales
      monProjAvgCheck
      monSchedFOH
      monSchedBOH
      tueProjSales
      tueProjAvgCheck
      tueSchedFOH
      tueSchedBOH
      wedProjSales
      wedProjAvgCheck
      wedSchedFOH
      wedSchedBOH
      thuProjSales
      thuProjAvgCheck
      thuSchedFOH
      thuSchedBOH
      friProjSales
      friProjAvgCheck
      friSchedFOH
      friSchedBOH
      satProjSales
      satProjAvgCheck
      satSchedFOH
      satSchedBOH
      sunProjSales
      sunProjAvgCheck
      sunSchedFOH
      sunSchedBOH
      __typename
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole(
    $clientId: String!
    $role: String!
    $newRole: String
    $description: String
  ) {
    updateRole(
      clientId: $clientId
      role: $role
      newRole: $newRole
      description: $description
    ) {
      clientId
      role
      description
      __typename
    }
  }
`;
export const updateStore = /* GraphQL */ `
  mutation UpdateStore($input: UpdateStoreInput) {
    updateStore(input: $input) {
      storeId
      shortName
      longName
      fohBaseTheoHrs {
        Mon
        Tue
        Wed
        Thu
        Fri
        Sat
        Sun
        __typename
      }
      bohBaseTheoHrs {
        Mon
        Tue
        Wed
        Thu
        Fri
        Sat
        Sun
        __typename
      }
      gm
      status
      type
      compStores {
        storeId
        shortName
        __typename
      }
      thresholds {
        avgCheck
        netSales
        splhTotal
        splhFOH
        splhBOH
        __typename
      }
      toastId
      __typename
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      clientId
      clientName
      contactName
      contactPhone
      contactEmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      clientId
      clientName
      contactName
      contactPhone
      contactEmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      clientId
      clientName
      contactName
      contactPhone
      contactEmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createForecastedSales = /* GraphQL */ `
  mutation CreateForecastedSales(
    $input: CreateForecastedSalesInput!
    $condition: ModelForecastedSalesConditionInput
  ) {
    createForecastedSales(input: $input, condition: $condition) {
      id
      storeName
      date
      forecastedSales
      storeId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateForecastedSales = /* GraphQL */ `
  mutation UpdateForecastedSales(
    $input: UpdateForecastedSalesInput!
    $condition: ModelForecastedSalesConditionInput
  ) {
    updateForecastedSales(input: $input, condition: $condition) {
      id
      storeName
      date
      forecastedSales
      storeId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteForecastedSales = /* GraphQL */ `
  mutation DeleteForecastedSales(
    $input: DeleteForecastedSalesInput!
    $condition: ModelForecastedSalesConditionInput
  ) {
    deleteForecastedSales(input: $input, condition: $condition) {
      id
      storeName
      date
      forecastedSales
      storeId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStoreRecord = /* GraphQL */ `
  mutation CreateStoreRecord(
    $input: CreateStoreRecordInput!
    $condition: ModelStoreRecordConditionInput
  ) {
    createStoreRecord(input: $input, condition: $condition) {
      id
      storeId
      storeName
      clientId
      toastId
      timeZone
      address {
        street_1
        street_2
        city
        state
        zipCode
        country
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStoreRecord = /* GraphQL */ `
  mutation UpdateStoreRecord(
    $input: UpdateStoreRecordInput!
    $condition: ModelStoreRecordConditionInput
  ) {
    updateStoreRecord(input: $input, condition: $condition) {
      id
      storeId
      storeName
      clientId
      toastId
      timeZone
      address {
        street_1
        street_2
        city
        state
        zipCode
        country
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStoreRecord = /* GraphQL */ `
  mutation DeleteStoreRecord(
    $input: DeleteStoreRecordInput!
    $condition: ModelStoreRecordConditionInput
  ) {
    deleteStoreRecord(input: $input, condition: $condition) {
      id
      storeId
      storeName
      clientId
      toastId
      timeZone
      address {
        street_1
        street_2
        city
        state
        zipCode
        country
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      client
      userName
      firstName
      lastName
      email
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      client
      userName
      firstName
      lastName
      email
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      client
      userName
      firstName
      lastName
      email
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMetricData = /* GraphQL */ `
  mutation CreateMetricData(
    $input: CreateMetricDataInput!
    $condition: ModelMetricDataConditionInput
  ) {
    createMetricData(input: $input, condition: $condition) {
      metricId
      clientId
      storeId
      date
      hour
      frequency
      metricName
      metricType
      numberValue
      stringValue
      booleanValue
      dayPart
      diningOption
      source
      timestamp
      unit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMetricData = /* GraphQL */ `
  mutation UpdateMetricData(
    $input: UpdateMetricDataInput!
    $condition: ModelMetricDataConditionInput
  ) {
    updateMetricData(input: $input, condition: $condition) {
      metricId
      clientId
      storeId
      date
      hour
      frequency
      metricName
      metricType
      numberValue
      stringValue
      booleanValue
      dayPart
      diningOption
      source
      timestamp
      unit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMetricData = /* GraphQL */ `
  mutation DeleteMetricData(
    $input: DeleteMetricDataInput!
    $condition: ModelMetricDataConditionInput
  ) {
    deleteMetricData(input: $input, condition: $condition) {
      metricId
      clientId
      storeId
      date
      hour
      frequency
      metricName
      metricType
      numberValue
      stringValue
      booleanValue
      dayPart
      diningOption
      source
      timestamp
      unit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createThreshold = /* GraphQL */ `
  mutation CreateThreshold(
    $input: CreateThresholdInput!
    $condition: ModelThresholdConditionInput
  ) {
    createThreshold(input: $input, condition: $condition) {
      id
      clientId
      name
      entityType
      entityId
      metric
      thresholdType
      thresholdBands {
        position
        operator
        numberValue
        textValue
        backgroundColor
        color
        fontWeight
        fontStyle
        borderColor
        borderWidth
        icon
        text
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateThreshold = /* GraphQL */ `
  mutation UpdateThreshold(
    $input: UpdateThresholdInput!
    $condition: ModelThresholdConditionInput
  ) {
    updateThreshold(input: $input, condition: $condition) {
      id
      clientId
      name
      entityType
      entityId
      metric
      thresholdType
      thresholdBands {
        position
        operator
        numberValue
        textValue
        backgroundColor
        color
        fontWeight
        fontStyle
        borderColor
        borderWidth
        icon
        text
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteThreshold = /* GraphQL */ `
  mutation DeleteThreshold(
    $input: DeleteThresholdInput!
    $condition: ModelThresholdConditionInput
  ) {
    deleteThreshold(input: $input, condition: $condition) {
      id
      clientId
      name
      entityType
      entityId
      metric
      thresholdType
      thresholdBands {
        position
        operator
        numberValue
        textValue
        backgroundColor
        color
        fontWeight
        fontStyle
        borderColor
        borderWidth
        icon
        text
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createThresholdMapping = /* GraphQL */ `
  mutation CreateThresholdMapping(
    $input: CreateThresholdMappingInput!
    $condition: ModelThresholdMappingConditionInput
  ) {
    createThresholdMapping(input: $input, condition: $condition) {
      id
      entityId
      extEntityId
      thresholdId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateThresholdMapping = /* GraphQL */ `
  mutation UpdateThresholdMapping(
    $input: UpdateThresholdMappingInput!
    $condition: ModelThresholdMappingConditionInput
  ) {
    updateThresholdMapping(input: $input, condition: $condition) {
      id
      entityId
      extEntityId
      thresholdId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteThresholdMapping = /* GraphQL */ `
  mutation DeleteThresholdMapping(
    $input: DeleteThresholdMappingInput!
    $condition: ModelThresholdMappingConditionInput
  ) {
    deleteThresholdMapping(input: $input, condition: $condition) {
      id
      entityId
      extEntityId
      thresholdId
      createdAt
      updatedAt
      __typename
    }
  }
`;
