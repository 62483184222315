import React from 'react';
import ReactDOM from 'react-dom/client';
// import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import App from 'App';
// import reportWebVitals from './reportWebVitals';

// AWS Amplify
import { Amplify } from 'aws-amplify'
import config from 'aws-exports'

// Soft UI Context Provider
import { MaterialUIControllerProvider } from 'context'

// RadixDash components
import RDAuthContext from 'components/RDAuthContext' 


Amplify.configure(config)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RDAuthContext>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </RDAuthContext>
);
  // ReactDOM.render(
  //   <RDAuthContext>
  //     <BrowserRouter>
  //       <MaterialUIControllerProvider>
          
  //           <App />
          
  //       </MaterialUIControllerProvider>
  //     </BrowserRouter>
  //     </RDAuthContext>,
  //   document.getElementById("root")
  // );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
