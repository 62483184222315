import { Checkbox, FormControlLabel } from '@mui/material'
import { styled  } from '@mui/system'

// Note: Must use grmTheme provider in parent component

const Checkbox_GrmDark = styled(Checkbox)(({theme}) => ({  
  color: theme.grm.primary.main,
  '&.Mui-checked': {
    color: theme.grm.primary.main
  },
  '&.Mui-disabled': {
    color: theme.grm.disabled.main
  }
}))

export default Checkbox_GrmDark
