import React from 'react'
import PropTypes from 'prop-types'

// @mui material components
import { Grid } from '@mui/material'

// Material Dashboard 2 Pro React components
import MDBox from 'components/MDBox'

// RadixDash components
import RDDashboardLayout from  'components/RDDashboardLayout'
import RDDashboardNavbar from 'components/RDDashboardNavbar'
import RDFooter from 'components/RDFooter'
import RDHeader from 'components/RDHeader'

function RDDashboard(props){
  const backgroundImage = props.headerBg
  const logo = props.logo

  return (
    <RDDashboardLayout>
      <MDBox 
        width='calc(100% - 48px)'
        position='absolute'
        top='1.75rem'
      >
        <RDDashboardNavbar light absolute />
      </MDBox>
      <RDHeader bgImage={backgroundImage} logo={logo}/>
      <MDBox pb={3}>
        <Grid
          container
          alignItems='center'
        >
          { props.children }
        </Grid>
      </MDBox>
      <RDFooter />
    </RDDashboardLayout>
  )
}

RDDashboard.propTypes = {
  children: PropTypes.node,
  headerBg: PropTypes.string,
  logo: PropTypes.string
}

export default RDDashboard