import React from 'react'
import PropTypes from 'prop-types'

// @mui components
import { Dialog, DialogTitle, DialogContent, Grid, Stack } from '@mui/material'

// formik components
import { Formik, Form, validateYupSchema } from 'formik'
import Textfield from 'components/FormsUI/Textfield'
import Select from 'components/FormsUI/Select'
import * as Yup from 'yup'

// Material Dashboard Pro 2 components
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

// RadixDash components
import Select_Rd from 'components/FormsUI/Select/Select_Rd'

export default function EditUserDialog(props){

  console.log('editUserDialog props', props)

  const existingUsers = props.existingUsers
  
  // Need to remove 'this' user from existingUsers list

  const roles = props.roles[props.clientId]
  console.log('roles', roles)
  const clientId = props.data.original.client

  const INITIAL_FORM_STATE = {
    firstName: props.data.original.firstName,
    lastName: props.data.original.lastName,
    // Username: props.data.values.Username,
    email: props.data.original.email,
    role: props.data.original.role,
    // clientId: props.data.original.clientId
  }

  console.log('INITIAL_FORM_STATE', INITIAL_FORM_STATE)

  const FORM_VALIDATION = Yup.object().shape({
    firstName: Yup.string(),
    // .required('Required'),
    lastName: Yup.string(),
      // .required('Required'),
    email: Yup.string()
      .email('Invalid email')
      .required('Required'),
    role: Yup.string()
      .required('Required'),
    // clientId: Yup.string()
  })

  function handleSubmit(values){
    console.log('values', values)
    let roleIdx = values.role
    let role = roles[roleIdx]
    values.role = role
    values.userName = props.data.values.userName
    props.submit({
      index: props.data.index,
      oldData: props.data.values,
      newData: values
    })
  }

  // let bgColor = null
  // if(clientId !== 'grm'){
  //   bgColor = 'red'
  // }

  return (
    <Dialog
      open={ props.open }
    >
      <DialogTitle>
        Username: {props.data.values.userName}
      </DialogTitle>
      <DialogContent 
        // sx={{ backgroundColor: bgColor}}
      >
        {/* { 
          (clientId === 'grm') ? */}
            <Formik
              initialValues={INITIAL_FORM_STATE}
              validationSchema={FORM_VALIDATION}
              onSubmit={ (values) => { handleSubmit(values) }}
            >
              {({ isValid }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}
                      // Included to give spacing
                    />
                    
                    
                    <Grid item xs={12}>
                      <Textfield 
                        name='firstName'
                        label='First Name'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Textfield 
                        name='lastName'
                        label='Last Name'
                      />
                    </Grid>
                    
                    <Grid item xs={12}>
                      <Textfield 
                        name='email'
                        label='Email'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Select_Rd 
                        name='role'
                        options={ roles }
                        label='Roles'
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <Textfield
                        name='clientId'
                        label='Client ID'
                      />
                    </Grid> */}
                    <Grid item xs={6}>
                      <MDBox>
                        <MDTypography variant='body2' sx={{ fontSize: '.9rem'}}>
                          Company: {props.data.companyName}
                        </MDTypography>          
                      </MDBox>
                    </Grid>
                    <Grid item xs={3}>
                      <MDButton variant='contained' color='info' onClick={ props.close }>
                        Cancel
                      </MDButton>
                    </Grid>
                    <Grid item xs={3}>
                      <MDButton variant='contained' color='info' type='submit' disabled={!(isValid)}>
                        Submit
                      </MDButton>
                    </Grid>
                  
    
                  </Grid>
                </Form>
              )}
            </Formik> 
            {/* :
          
            // Error notification in case user has an invalid clientId
            <MDBox >
              <MDTypography variant='heading3' sx={{ color: 'yellow'}}>
                Error
              </MDTypography>
              <MDTypography variant='body2' sx={{ color: 'yellow'}}>
                <p>{props.data.values.Username} has an invalid Client Id.  </p>
                <p>Please contact RadixDash support.</p>
              </MDTypography>
            </MDBox>

          
        } */}
        

      </DialogContent>

    </Dialog>
  )
}

EditUserDialog.propTypes = {
  clientId: PropTypes.string,
  close: PropTypes.func,
  data: PropTypes.object,
  existingUsers: PropTypes.array,
  open: PropTypes.bool,
  roles: PropTypes.object,
  submit: PropTypes.func,
  username: PropTypes.string
}