/**
 * Formats a number as currency
 * @param {number} amount - Amount to format
 * @param {number} [digits=0] - Number of decimal places
 * @returns {string} Formatted currency string
 */
export const formatMoney = (amount, digits = 0) => {
  // Handle invalid inputs
  if (amount === null || amount === undefined || isNaN(amount)) {
    return '$0.00';
  }
  
  try {
    // Convert to number if it's a string
    const numericAmount = typeof amount === 'string' ? 
      parseFloat(amount.replace(/[$,]/g, '')) : 
      Number(amount);
    
    // Format the number using Intl.NumberFormat
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: digits,
      maximumFractionDigits: digits
    }).format(numericAmount);
  } catch (error) {
    console.error('Error formatting money value:', error);
    return '$0.00';
  }
};

/**
 * Formats a number with thousands separators
 * @param {number} num - Number to format
 * @returns {string} Formatted number string
 */
export const formatNumber = (num) => {
  if (num === null || num === undefined || isNaN(num)) {
    return '0';
  }
  
  try {
    const numericValue = typeof num === 'string' ? 
      parseFloat(num.replace(/,/g, '')) : 
      Number(num);
      
    return Math.round(numericValue).toString();
  } catch (error) {
    console.error('Error formatting number:', error);
    return '0';
  }
};

/**
 * Formats a number as a percentage
 * @param {number} value - Value to format
 * @returns {string} Formatted percentage string
 */
export const formatPercent = (value) => {
  if (value === null || value === undefined || isNaN(value)) {
    return '0.00%';
  }
  
  try {
    const numericValue = typeof value === 'string' ? 
      parseFloat(value.replace(/%/g, '')) : 
      Number(value);
      
    return `${numericValue.toFixed(2)}%`;
  } catch (error) {
    console.error('Error formatting percentage value:', error);
    return '0.00%';
  }
};