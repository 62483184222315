// import React from 'react'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import R0002CellStyle from './R0002CellStyle'
import { format } from 'date-fns'
import { LocalConvenienceStoreOutlined } from '@mui/icons-material'

function R0002ExportPdf( data, curStart, curEnd, compStart, compEnd, priceIncrease, priceIncreaseSwitch ){

  let sDate = format(curStart, 'M/d/yy')
  let eDate = format(curEnd, 'M/d/yy')

  const title = `Summary Report: ${sDate} - ${eDate}`
  let doc = new jsPDF({
    orientation: 'landscape',
    unit: 'pt',
    format: 'letter'
  })

  let fullDataArr = data.map(item => [
    item.store,
    item.pNSTotal,
    item.cNSTotal,
    item.pct,
    item.cNSDineIn,
    item.cNSToGo,
    item.cNSDelivery,
    item.cGSDelivery,
    item.cCCTotal,
    item.cCCDineIn,
    item.cCCToGo,
    item.cCCDelivery,
    item.cCCTotal,
    item.cCCDineIn,
    item.cCCToGo,
    item.cCCDelivery,
    item.cACTotal,
    item.cACDineIn,
    item.cACToGo,
    item.cACDelivery,
    item.cSPLHTotal,
    item.cFOH,
    item.cBOH,
    item.cLaborPct,
    item.pNSDineIn,
    item.ipNSToGo,
    item.pNSDelivery,
    item.pGSDelivery,
    item.pCCTotal,
    item.pCCDineIn,
    item.pCCToGo,
    item.pCCDelivery,
    item.pACTotal,
    item.pACDineIn,
    item.pACToGo,
    item.pACDelivery,
    item.pSPLHTotal,
    item.pFOH,
    item.pBOH,
    item.pLaborPct,
    item.rowType,
    item.style,
  ])
  console.log('*data', data)
  
  // for(let i = 0; i < data.length; i++){
  //   const fullData = [
  //     data[i].store,
  //     data[i].tipNSTotal,
  //     data[i].cNSTotal,
  //     data[i].pct,
  //     data[i].cNSDineIn,
  //     data[i].cNSToGo,
  //     data[i].cNSDelivery,
  //     data[i].cGSDelivery,
  //     data[i].cCCTotal,
  //     data[i].cCCDineIn,
  //     data[i].cCCToGo,
  //     data[i].cCCDelivery,
  //     data[i].cACTotal,
  //     data[i].cACDineIn,
  //     data[i].cACToGo,
  //     data[i].cACDelivery,
  //     data[i].cSPLHTotal,
  //     data[i].cFOH,
  //     data[i].cBOH,
  //     data[i].cLaborPct,
  //     data[i].tipNSDineIn,
  //     data[i].tipNSToGo,
  //     data[i].tipNSDelivery,
  //     data[i].tipGSDelivery,
  //     data[i].tipCCTotal,
  //     data[i].tipCCDineIn,
  //     data[i].tipCCToGo,
  //     data[i].tipCCDelivery,
  //     data[i].tipACTotal,
  //     data[i].tipACDineIn,
  //     data[i].tipACToGo,
  //     data[i].tipACDelivery,
  //     data[i].tipSPLHTotal,
  //     data[i].tipFOH,
  //     data[i].tipBOH,
  //     data[i].tipLaborPct,
  //     data[i].rowType,
  //     data[i].style,
  //   ]
  //   fullDataArr.push(fullData)
  // }

  console.log('*fullDataArr', fullDataArr)

  doc.autoTable({
    head: [
      [
        {
          content: title,
          colSpan: 20,
          styles: {
            // valign: 'center',
            halign: 'left',
            fillColor: [41, 38, 31],
            textColor: [207, 158, 11],
            cellPadding: 1,
            fontSize: 15
          }
        }
      ],
      [
        {
          content: 'Store',
          rowSpan: 2,
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [224, 224, 224],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold'
          }
        },
        {
          content: 'NET SALES',
          colSpan: 7,
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        
        },
        {
          content: 'CHECK COUNTS',
          colSpan: 4,
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [224, 224, 224],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          }
        },
        {
          content: 'AVERAGE CHECK',
          colSpan: 4,
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        {
          content: 'SALES PER LABOR HOUR',
          colSpan: 3,
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [224, 224, 224],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          }
        },
        {
          content: 'Labor %',
          rowSpan: 2,
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        }
      ],
      [
        { 
          content: 'PY Net',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          }  
        },
        { 
          content: 'CY Net',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          }  
        },
        { 
          content: '%',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          }  
        },
        { 
          content: 'Dine In',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { 
          content: 'To Go',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { 
          content: 'Net Delvry',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { 
          content: 'Grs Delvry',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { 
          content: 'Total',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [224, 224, 224],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { 
          content: 'Dine In',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [224, 224, 224],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { 
          content: 'To Go',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [224, 224, 224],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { 
          content: 'Delvry',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [224, 224, 224],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { 
          content: 'Total',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { 
          content: 'Dine In',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { 
          content: 'To Go',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { 
          content: 'Delvry',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [189, 189, 189],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { 
          content: 'Total',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [224, 224, 224],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { content: 'FOH',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [224, 224, 224],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
        { content: 'BOH',
          styles: { 
            valign: 'bottom', 
            halign: 'center', 
            fillColor: [224, 224, 224],
            textColor: [0, 0, 0],
            cellPadding: .5,
            fontStyle: 'bold',
          } 
        },
      ]
    ],
    body: fullDataArr,
    headStyles: {
      fontSize: 5,
    },
    bodyStyles: {
      fontSize: 6,
    },
    columnStyles: {
      1: {
        lineColor: '576574',
        lineWidth: { left: 1 }
      }
    },
    didParseCell: function(data) {
      if(data.row.section === 'body'){
        console.log('*data', data)
        console.log('*raw', data.row.raw)
        const rowStyles = data.row.raw[41]
        const rowType = data.row.raw[40]
        const columnNum = data.column.index
        const field = getField(columnNum)
        console.log('*rowStyles', rowStyles)
        console.log('*field', field)
        console.log('*rowType', rowType)
        
        if(rowStyles !== undefined){
          data.cell.styles.fillColor = rowStyles[field].bgColor
          data.cell.styles.textColor = rowStyles[field].textColor
          data.cell.styles.fontStyle = rowStyles[field].fontWeight
          data.cell.styles.halign = rowStyles[field].textAlign

        } else {
          data.cell.styles.fillColor = '#fff'
        }
        data.cell.styles.cellPadding = .5
        data.cell.styles.lineColor = [40, 40, 40]
        data.cell.styles.lineWidth = .25
        data.cell.styles.fontStyle = 'bold'

        
          

      }
    },
    // didParseCell: function(data) {
    //   console.log('didParseCell data', data)
    //   if(data.row.section === 'body'){
    //     const dataObj = {}
    //     for(let i = 0; i < data.row.raw.length; i++){
    //       const field = getField(i)
    //       dataObj[field] = data.row.raw[i]
    //     }

    //     const dataObj2 = {
    //       original: dataObj
    //     }
        
    //     const columnNum = data.column.index
    //     const field = getField(columnNum)
    //     const cell = {
    //       column: {
    //         id: field
    //       }
    //     }
    //     const options = [
    //       {
    //         option: 'priceIncrease',
    //         value: priceIncrease
    //       },
    //       {
    //         option: 'priceIncreaseSwitch',
    //         value: priceIncreaseSwitch
    //       }
    //     ]
    //     const setting = R0002CellStyle(dataObj2, cell, options)

    //     data.cell.styles.cellPadding = .5
    //     data.cell.styles.fontStyle = setting.fontWeight
    //     data.cell.styles.halign = setting.textAlign
    //     data.cell.styles.lineColor = [40, 40, 40]
    //     data.cell.styles.lineWidth = .25

    //     switch(setting.backgroundColor){
    //       case 'grey':
    //         data.cell.styles.fillColor = [189, 189, 189]
    //         data.cell.styles.textColor = '#000000'
    //         break;
    //       case 'green':
    //         data.cell.styles.fillColor = [0, 128, 0]
    //         data.cell.styles.textColor = '#ffffff'
    //         break;
    //       case 'red':
    //         data.cell.styles.fillColor = [255, 0, 0]
    //         data.cell.styles.textColor = '#ffffff'
    //         break;
    //       case 'white':
    //       case '':
    //         data.cell.styles.fillColor = [255, 255, 255]
    //         data.cell.styles.textColor = '#000000'
    //         break;
    //       default:
    //         data.cell.styles.fillColor = setting.backgroundColor
    //         if(setting.color !== ''){
    //           data.cell.styles.textColor = setting.color
    //           // data.cell.styles.lineColor = setting.color
    //           // data.cell.styles.lineWidth = 0            
    //         } else {
    //           data.cell.styles.textColor = '#000000'
    //         }
    //     }



    //   }
    // }
  })

  doc.save('output.pdf')

}

export default R0002ExportPdf

function getField(colIdx){
  
  switch(colIdx){
    case 0:  return 'store'      
    case 1:  return 'pNSTotal'   
    case 2:  return 'cNSTotal'   
    case 3:  return 'pct'        
    case 4:  return 'cNSDineIn'
    case 5:  return 'cNSToGo'
    case 6:  return 'cNSDelivery'
    case 7: return 'cGSDelivery'
    case 8: return 'cCCTotal'
    case 9: return 'cCCDineIn'
    case 10: return 'cCCToGo'
    case 11: return 'cCCDelivery'
    case 12: return 'cACTotal'
    case 13: return 'cACDineIn'
    case 14: return 'cACDelivery'
    case 15: return 'cACToGo'
    case 16: return 'cSPLHTotal'
    case 17: return 'cFOH'
    case 18: return 'cBOH'
    case 19: return 'cLaborPct'
    case 20:  return 'pNSDineIn'
    case 21:  return 'pNSToGo'
    case 22:  return 'pNSDelivery'
    case 23: return 'pGSDelivery'
    case 24: return 'pCCTotal'
    case 25: return 'pCCDineIn'
    case 26: return 'pCCToGo'
    case 27: return 'pCCDelivery'
    case 28: return 'pACTotal'
    case 29: return 'pACDineIn'
    case 30: return 'pACToGo'
    case 31: return 'pACDelivery'
    case 32: return 'pSPLHTotal'
    case 33: return 'pFOH'
    case 34: return 'pBOH'
    case 35: return 'pLaborPct'
    case 36: return 'rowType'
    case 37: return 'style'
    default: return ''
  }
}
