import { addWeeks, format } from 'date-fns';

export const filterData = (array, area) => {
  const idx = array.findIndex(x => x.area === area);
  return array[idx];
};

export const generateWeeks = (startWeek) => {
  const today = new Date();
  let week = new Date(startWeek);
  let weeks = [];

  while (week < today) {
    weeks.unshift(format(week, 'yyyy-MM-dd'));
    week = addWeeks(week, 1);
  }

  // Add two future weeks
  weeks.unshift(format(week, 'yyyy-MM-dd'));
  week = addWeeks(week, 1);
  weeks.unshift(format(week, 'yyyy-MM-dd'));
  return weeks;
};

export const getTextWidth = (text, font) => {
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
  const context = canvas.getContext("2d");
  context.font = font;
  return context.measureText(text).width;
};

export const getItemSize = (row) => {
  const { day, notes } = row;
  const charSize = Math.ceil(parseFloat(getComputedStyle(document.documentElement).fontSize));
  
  const notesWidth = getTextWidth(notes, "normal 12px verdana");
  
  const lineHeight = ['Totals', 'Avg Wage', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', ''].includes(day) 
    ? charSize 
    : charSize * 2;

  if (!notes) return lineHeight;
  
  const numRows = notes.length === 1 ? 1 : Math.ceil(notesWidth / 180);
  return numRows * lineHeight;
};