// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";

function RDTableHeadCell({ width, children, sorted, align, canSort, colSpan, rowSpan, px, py, columnStyle, ...rest }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  
  return (
    <MDBox
      component="th"
      width={width}
      colSpan={colSpan}
      // rowSpan={rowSpan}
      py={py}
      px={px}
      style={columnStyle}
      sx={({ palette: { light }, borders: { borderWidth } }) => ({
        borderBottom: `${borderWidth[1]} solid ${light.main}`,
      })}
    >
      <MDBox
        {...rest}
        position="relative"
        textAlign={align}
        rowSpan={rowSpan}
        // color={darkMode ? "white" : "secondary"}
        color={columnStyle !== undefined ? columnStyle.color : darkMode ? "white" : "secondary"}
        // opacity={0.7}
        sx={({
          // fontSize: columnStyle.fontSize,
          // fontWeight: columnStyle.fontWeight,
          // textTransform: "uppercase",
          cursor: canSort && sorted && "pointer",
          userSelect: sorted && "none",
        })}
        // sx={({ typography: { size, fontWeightBold } }) => ({
        //   fontSize: size.xxs,
        //   fontWeight: fontWeightBold,
        //   textTransform: "uppercase",
        //   cursor: canSort && sorted && "pointer",
        //   userSelect: sorted && "none",
        // })}
      >
        {children}
        {canSort && sorted && (
          <MDBox
            position="absolute"
            top={0}
            right={align !== "right" ? "16px" : 0}
            left={align === "right" ? "-5px" : "unset"}
            sx={({ typography: { size } }) => ({
              fontSize: size.lg,
            })}
          >
            <MDBox
              position="absolute"
              top={-6}
              color={sorted === "asce" ? "text" : "secondary"}
              opacity={sorted === "asce" ? 1 : 0.5}
            >
              <Icon>arrow_drop_up</Icon>
            </MDBox>
            <MDBox
              position="absolute"
              top={0}
              color={sorted === "desc" ? "text" : "secondary"}
              opacity={sorted === "desc" ? 1 : 0.5}
            >
              <Icon>arrow_drop_down</Icon>
            </MDBox>
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of RDTableHeadCell
RDTableHeadCell.defaultProps = {
  width: "auto",
  sorted: "none",
  align: "center",
};

// Typechecking props for the RDTableHeadCell
RDTableHeadCell.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
  canSort: PropTypes.bool,
  colSpan: PropTypes.number,
  rowSpan: PropTypes.number,
  sorted: PropTypes.oneOf([false, "none", "asce", "desc"]),
  align: PropTypes.oneOf(["left", "right", "center"]),
  py: PropTypes.number,
  px: PropTypes.number,
  columnStyle: PropTypes.object,
};

export default RDTableHeadCell;
