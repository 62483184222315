import { addDays, startOfDay, isEqual, isAfter, parse } from 'date-fns'
import { formatCurrency } from 'utils/reportUtils'
import { DAYS_OF_WEEK } from './constants'

export function createWeekDatesMap(weekStartDate) {
  const today = startOfDay(new Date())
  const startDate = parse(weekStartDate, 'yyyy-MM-dd', new Date())

  return DAYS_OF_WEEK.reduce((acc, day, index) => {
    const date = addDays(startDate, index)

    acc[day] = {
      date: date.toISOString().split('T')[0],
      isFuture: isEqual(date, today) || isAfter(date, today)
    }
    return acc
  }, {})
}

export function clearRowData(row) {
  const emptyRow = {
    actSales: '',
    actCheckCount: '',
    actAvgCheck: '',
    actFOH: '',
    actBOH: '',
    fohActVsTheo: '',
    bohActVsTheo: '',
    theoSPLH: '',
    actSPLH: ''
  }
  Object.assign(row, emptyRow)
}

export function initializeAreaRollup() {
  const dayRollup = {
    actSales: 0,
    actCheckCount: 0,
    actFOH: 0,
    actBOH: 0,
    adjTheoFOH: 0,
    adjTheoBOH: 0
  }

  return {
    ...DAYS_OF_WEEK.reduce((acc, day) => {
      acc[day] = { ...dayRollup }
      return acc
    }, {}),
    total: { ...dayRollup }
  }
}

export function updateRowMetrics(row, metrics, theoHours = null) {
  row.actSales = formatCurrency(metrics.actSales, 2)
  row.actCheckCount = metrics.actCheckCount
  row.actAvgCheck = metrics.actCheckCount > 0
    ? (metrics.actSales / metrics.actCheckCount).toFixed(2)
    : '0.00'
  row.actFOH = metrics.actFOH.toFixed(2)
  row.actBOH = metrics.actBOH.toFixed(2)

  if (theoHours) {
    row.adjTheoFOH = theoHours.adjTheoFOH.toFixed(2)
    row.adjTheoBOH = theoHours.adjTheoBOH.toFixed(2)
    row.fohActVsTheo = (metrics.actFOH - theoHours.adjTheoFOH).toFixed(2)
    row.bohActVsTheo = (metrics.actBOH - theoHours.adjTheoBOH).toFixed(2)
  }
}

export function safeAdd(accumulator, value) {
  const numValue = parseFloat(value)
  if (!isNaN(numValue)) {
    return accumulator + numValue
  }
  return accumulator
}