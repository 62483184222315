import React from 'react'

// RadixDash components
import RDDashboard from 'components/RDDashboard'
import RDMessageBox from 'components/RDMessageBox'

// Images
import backgroundImage from "assets/images/afa92/dashboard-bg-1.png";
import logoImage from 'assets/images/afa92b-icon.png'

export default function Checkpoints(){
  return (
    <RDDashboard
      headerBg={backgroundImage}
      logo={logoImage}
    >
      <h1>Checkpoints</h1>
    </RDDashboard>
  )
}