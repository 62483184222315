import { useEffect, useState } from 'react'
import { HookStatus } from './hookStatus'
import { getDataType } from 'utils/helperUtils'

// Reusable filtering hook
export default function useFilter(data, status, filters) {
  console.log('**** useFilter ****')

  // Filter logic: state, effect 
  const [filteredData, setFilteredData] = useState(data);
  const [filteredStatus, setFilteredStatus] = useState(status)
  const [dataType, setDataType] = useState(() => getDataType(data))

  useEffect(() => {
    if(data !== null){
      const dType = getDataType(data)
      setDataType(dType)
    }
  },[data])
  
  
  useEffect(() => {
    if(status === 'pending' || !data){
      setFilteredStatus(HookStatus.PENDING)
      return
    }
    if (dataType === 'array') {
      const updatedData = filterArray(data, filters[0]);
      setFilteredData(updatedData);
      setFilteredStatus(HookStatus.SUCCESS)
    } else {
      console.log('dataType', dataType)
    }      

  }, [data, dataType, status, filters])

  return {
    filteredData,
    filteredStatus
  };

}

// Filter functions
const operatorFns = {
  equals: (value, filterValue) => value === filterValue
}

// Get filter function
function getFilterFn(operator) {
  return operatorFns[operator]
}

// Filter data array
function filterArray(data, filter) {
  const { field, operator, value } = filter

  const filterFn = getFilterFn(operator)

  return data.filter(item => {
    // Get value for field
    const fieldValue = item[field]

    // Apply filter function
    return filterFn(fieldValue, value)
  })

}