import React, { useState } from 'react'

import { Checkbox, FormControlLabel, Grid } from '@mui/material'

import { ThemeProvider } from '@mui/material/styles'

import grmTheme from 'clients/grm/grmTheme'

import MDBox from 'components/MDBox'

import Checkbox_GrmDark from 'components/Checkbox/Checkbox_GrmDark';

export default function R0004Years({ selectedYears, setSelectedYears, years }){

  const handleYearChange = (year) => {
    const updatedYears = selectedYears.includes(year)
      ? selectedYears.filter((y) => y !== year)
      : [...selectedYears, year];

    updatedYears.sort((a, b) => a - b)  
    setSelectedYears(updatedYears);
    // setYears(updatedYears);
  };

  return (
    <ThemeProvider theme={grmTheme}>
      <MDBox
        sx={{
          px: 2
        }}
      >
        <Grid container spaacing={2}>
          {years.map((year) => (
            <Grid item xs={6} key={year}>
              <FormControlLabel 
                key={year}
                control={
                  <Checkbox_GrmDark 
                    checked={selectedYears.includes(year)}
                    onChange={() => handleYearChange(year)}
                  />
                }
                label={year}
              />
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </ThemeProvider>
  )
}