import React from 'react'
import PropTypes from 'prop-types'

import { CSVLink } from 'react-csv'

import { Tooltip } from '@mui/material'

import MDButton from 'components/MDButton'

export default function RDCSVExport(props){
  
  const csvReport = {
    filename: props.filename,
    headers: props.headers,
    data: props.data
  }

  return (
    <Tooltip title='Export to CSV'>
      <MDButton
        variant='gradient'
        iconOnly
        sx={{
          bgcolor: props.style.background,
          color: props.style.color,
          '&:hover': {
            bgcolor: props.style.backgroundHover
          },
          '&:focus:not(:hover)': {
            bgcolor: props.style.backgroundHover
          },
          margin: 0
        }}
      >
        <CSVLink {...csvReport} style={{ color: props.style.color }}>
          CSV
        </CSVLink>
      </MDButton>
    </Tooltip>
  )
}

RDCSVExport.propTypes = {
  data: PropTypes.array,
  filename: PropTypes.string,
  headers: PropTypes.array,
  style: PropTypes.object
}