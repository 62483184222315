export function getRuleStatement(rule){
  
  const operatorMap = {
    EQ: 'Equal to',
    NEQ: 'Not equal to',
    GT: 'Greater than',
    GTE: 'Greater than or equal to',
    LT: 'Less than',
    LTE: 'Less than or equal to',
    BTW: 'Between'
  }

  let condition
  if(rule.operator === 'BTW'){
    condition = rule.numberValue[0] + '% and ' + rule.numberValue[1] + '%'
  } else {
    condition = rule.numberValue[0] + '%'
  }
  const statement = rule.position + '. ' + ' Forecast Value is ' + operatorMap[rule.operator] + ' ' + condition

  return statement
}