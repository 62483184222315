// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";

// Authentication layout components
import RDLogIn from 'components/RDLogin/RDLogin'


function UnauthenticatedApp() {
  console.log('UnauthenticatedApp()...')

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme }>
      <CssBaseline />
      <RDLogIn />
      {/* <RDSignIn /> */}
    </ThemeProvider>
    
  );
}

export default UnauthenticatedApp;