// AWS Amplify components
import { API } from 'aws-amplify'

import { useState, useEffect } from 'react';

const QueryStatus = {
  IDLE: 'idle',
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error',
}

/**
 * Custom React hook to make GraphQL queries in components.
 * 
 * @param {Object} queryObject - Query configuration object
 * @param {String} queryObject.query - GraphQL query string
 * @param {Object} queryObject.variables - Query variables 
 * 
 * @returns {Object} 
 * @returns {String} return.status - Current query status 
 * @returns {String} return.data - Query response data
 * @returns {Object} return.error - Query error
 *
 * @example
 * 
 * const { status, data, error } = useQuery({
 *   query: GET_TODO,
 *   variables: { id: 1 }
 * })
 * 
 * if(status === 'pending') {
 *   return <Loader />
 * }
 *
 * if(status === 'error') {
 *   return <Error msg={error} /> 
 * }  
 *
 * return <Todo data={data.todo} />
 *
 */
export default function useQuery(queryObject) {
  console.log('**** useQuery ****');

  const [status, setStatus] = useState(QueryStatus.IDLE);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    let didCancel = false;
    setStatus(QueryStatus.PENDING)
    setData(null)

    const fetchData = async () => {
      try {
        const response = await API.graphql(queryObject);
        console.log('response', response)
        
          const responseData = JSON.parse(JSON.parse(response.data.dbGetItem.statusItem))
        
        console.log('responseData', responseData)
        if(!didCancel) {
          setData(responseData)
          setStatus(QueryStatus.SUCCESS)
        }
      } catch (err) {
        console.log('Error fetching data', err)
        if(!didCancel){
          setError(err)
          setStatus(QueryStatus.ERROR)
        }
        throw err
      }
    }

    if(queryObject !== null) {
     fetchData();
    }

    return () => {
      didCancel = true;
    };

  }, [queryObject]);

  return {
    status,
    data,
    error
  };
  
}

