import { useState, useEffect, useMemo } from 'react';
import { API, graphqlOperation } from 'aws-amplify'; 
import { listAreas2, listStoreRecords } from 'graphql/queries';

import { HookStatus } from 'hooks/hookStatus'
import useCachedFetch from './useCachedFetch';

// Function to normalize store names
const normalizeStoreName = (name) => {
  return name.toLowerCase().replace(/[^a-z0-9\s]/g, '').split(/\s+/)
}

// Function to find the best match
const findBestMatch = (targetName, namesList) => {

  const targetWords = normalizeStoreName(targetName)
  let bestMatch = null
  let highestSimilarity = 0

  namesList.forEach(name => {
    const nameWords = normalizeStoreName(name)
    const matchingWords = targetWords.filter(word => nameWords.includes(word))
    const similarity = matchingWords.length / Math.max(targetWords.length, nameWords.length)

    if (similarity > highestSimilarity) {
      highestSimilarity = similarity
      bestMatch = name
    }
  })

  return bestMatch
}

function useAreas() {
  
  const [status, setStatus] = useState(HookStatus.IDLE); 
  const [error, setError] = useState(null);
  // const [storeMap, setStoreMap] = useState(new Map())
  const [storeMaps, setStoreMaps] = useState({
    storeMap: new Map(),
    storeIdMap: new Map()
  })

  const storeRecords = useCachedFetch(fetchStoreRecords, setStatus)
  const data = useCachedFetch(fetchAreas, setStatus)

  const companyAreas = useMemo(() => 
    data ? data.filter(area => area.type === 'Company').map(area => area.name) 
    : [], 
    [data]
  )
  
  const franchiseAreas = useMemo(() => 
    data ? data.filter(area => area.type === 'Franchise').map(area => area.name)
    : [],
    [data]
  )

  useEffect(() => {
    if(data && storeRecords){
      const storeRecordsMap = new Map()
      // const storeNames = storeRecords.map(record => record.storeName)

      storeRecords.forEach(record => {
        storeRecordsMap.set(record.toastId, record.id)
        // storeRecordsMap.set(record.storeName, record.id)
      })

      const mapByLongName = new Map();
      const mapById = new Map();

      data.forEach(area => {
        area.stores.forEach(store => {
          
          let storeRecord = store.toastId ? storeRecordsMap.get(store.toastId) : null
          
          if (!store.toastId) {
            console.log(`No Toast ID found for store: ${store.shortName}`)
            store.status = 'Closed'
          } else if (!storeRecord) {
            console.log(`No matching store record found for store ${store.shortName}, toastId: ${store.toastId}`)
          }

          const toastGoLiveDate = toastGoLiveDates[store.shortName] || null
          const storeData = {
            shortName: store.shortName,
            area: area.areaName,
            areaType: area.areaType,
            status: store.status,
            type: store.type,
            id: store.storeId,
            storeRecordId: storeRecord ? storeRecord : null,
            toastId: store.toastId,
            city: storeLocations[store.shortName]?.city ?? null,
            state: storeLocations[store.shortName]?.state ?? null,
            zipcode: storeLocations[store.shortName]?.zipcode ?? null,
            toastGoLiveDate: toastGoLiveDate
          }

          mapByLongName.set(store.longName, storeData)

          if (storeData.storeRecordId) {
            mapById.set(storeData.storeRecordId, storeData)
          }
        })
      }) 

      setStoreMaps({ storeMap: mapByLongName, storeIdMap: mapById })     
    }
  }, [data, storeRecords])

  return {
    data,
    storeMap: storeMaps.storeMap,
    storeIdMap: storeMaps.storeIdMap,
    companyAreas,
    franchiseAreas,
    status,
    error 
  };
}

export default useAreas;

async function fetchAreas(setStatus) {

  setStatus(HookStatus.PENDING)

  try {
    const res = await API.graphql({ 
      query: listAreas2,
      variables: {
        report: 'store-admin',
        role: 'Admin',
        user: 'grmAdmin' 
      }
    });
    setStatus(HookStatus.SUCCESS)
    return res.data.listAreas2
  } catch (err) {
    console.log('Error fetching areas')
    setStatus(HookStatus.ERROR)
    throw err
  }  
  
}

async function fetchStoreRecords(setStatus) {
  setStatus(HookStatus.PENDING)

  // Fetch StoreRecords
  const variables = {
    limit: 1000
  }

  let allStoreRecords = []
  let nextToken = null

  do {
    if(nextToken){
      variables.nextToken = nextToken
    }

    try {
      const response = await API.graphql(graphqlOperation(listStoreRecords, variables))
      const { items, nextToken: newNextToken } = response.data.listStoreRecords
      allStoreRecords = allStoreRecords.concat(items)
      nextToken = newNextToken         
    } catch (err) {
      console.log('Error fetching StoreRecords', err)
      setStatus(HookStatus.ERROR)
      throw err
    }
  } while (nextToken)
   
  setStatus(HookStatus.SUCCESS)

  console.log('allStoreRecords', allStoreRecords)
  return allStoreRecords
}

const toastGoLiveDates = {
  'Aspen Place': null,
  'Baybrook': '2024-08-12',
  'Bell Tower': '2024-08-26',
  'Boca': '2024-08-12',
  'Brookfield': '2024-09-09',
  'Brooklyn': '2024-09-23',
  'CITYCENTRE': '2024-07-22',
  'Casa Paloma': '2024-04-22',
  'Cinco Ranch': '2024-08-05',
  'DATG Palm Beach': '2024-08-19',
  'Destin': '2024-09-16',
  'Domain': '2024-07-08',
  'El Paso': '2024-08-26',
  'First Colony': '2024-07-29',
  'Fort Worth': '2024-08-05',
  'Fritz Farm': '2024-09-09',
  'Grand Boulevard': '2024-09-16',
  'Grapevine': '2024-07-22',
  'Green Valley': '2024-08-05',
  'Highland Village': '2024-06-24',
  'Hoover': '2024-08-28',
  'Huntsville': '2024-09-30',
  'Irving': '2024-06-17',
  'La Cantera': '2024-08-05',
  'La Palmera': '2024-07-22',
  'Legacy': '2024-08-19',
  'Magnolia Park': null,
  'Meridian': '2024-11-04',
  'Mt. Pleasant': '2024-09-09',
  'Old Town': '2024-09-09',
  'Palazzo': '2024-08-19',
  'Park Lane': '2024-08-12',
  'Park West': '2024-07-08',
  'Prairiefire': '2024-08-26',
  'Quarry Village': '2024-07-29',
  'Realm': '2024-07-08',
  'SQ': '2024-08-26',
  'San Tan': '2024-06-10',
  'South Rainbow': '2024-07-29',
  'Sparks': '2024-09-09',
  'Stone Oak': '2024-07-15',
  'Towncenter': '2024-08-26',
  'WS West Shore': '2024-08-12',
  'WW DC Ranch': '2024-06-24',
  'Waterfront': '2024-09-16',
  'Watters Creek': '2024-07-15',
  'West Village': '2024-07-29',
}

const storeLocations = {
  'Aspen Place': {
    city: 'Flagstaff',
    state: 'AZ',
    zipcode: '86001',
  },
  'Baybrook': {
    city: 'Friendswood',
    state: 'TX',
    zipcode: '77546',
  },
  'Bell Tower': {
    city: 'Fort Myers',
    state: 'FL',
    zipcode: '33907',
  },
  'Boca': {
    city: 'Las Vegas',
    state: 'NV',
    zipcode: '89145',
  },
  'Brookfield': {
    city: 'Brookfield',
    state: 'WI',
    zipcode: '53045',
  },
  'Brooklyn': {
    city: 'Brooklyn',
    state: 'NY',
    zipcode: '11201',
  },
  'CITYCENTRE': {
    city: 'Houston',
    state: 'TX',
    zipcode: '77024',
  },
  'Casa Paloma': {
    city: 'Chandler',
    state: 'AZ',
    zipcode: '85226',
  },
  'Cinco Ranch': {
    city: 'Katy',
    state: 'TX',
    zipcode: '77494',
  },
  'DATG Palm Beach': {
    city: 'PB Gardens',
    state: 'FL',
    zipcode: '33410',
  },
  'Destin': {
    city: 'Miramar Beach',
    state: 'FL',
    zipcode: '32550',
  },
  'Domain': {
    city: 'Austin',
    state: 'TX',
    zipcode: '78758',
  },
  'El Paso': {
    city: 'El Paso',
    state: 'TX',
    zipcode: '79925',
  },
  'First Colony': {
    city: 'Sugar Land',
    state: 'TX',
    zipcode: '77479',
  },
  'Fort Worth': {
    city: 'Fort Worth',
    state: 'TX',
    zipcode: '76109',
  },
  'Fritz Farm': {
    city: 'Lexington',
    state: 'KY',
    zipcode: '40517',
  },
  'Grapevine': {
    city: 'Grapevine',
    state: 'TX',
    zipcode: '76051',
  },
  'Green Valley': {
    city: 'Las Vegas',
    state: 'NV',
    zipcode: '89123',
  },
  'Highland Village': {
    city: 'Highland Village',
    state: 'TX',
    zipcode: '75077',
  },
  'Hoover': {
    city: 'Hoover',
    state: 'AL',
    zipcode: '35244',
  },
  'Huntsville': {
    city: 'Huntsville',
    state: 'AL',
    zipcode: '35802',
  },
  'Irving': {
    city: 'Irving',
    state: 'TX',
    zipcode: '75039',
  },
  'La Cantera': {
    city: 'San Antonio',
    state: 'TX',
    zipcode: '78256',
  },
  'La Palmera': {
    city: 'Corpus Christi',
    state: 'TX',
    zipcode: '78411',
  },
  'Legacy': {
    city: 'Plano',
    state: 'TX',
    zipcode: '75024',
  },
  'Magnolia Park': {
    city: 'Greenville',
    state: 'SC',
    zipcode: '29607',
  },
  'Meridian': {
    city: 'Meridian',
    state: 'ID',
    zipcode: '83646',
  },
  'Mt. Pleasant': {
    city: 'Mount Pleasant',
    state: 'SC',
    zipcode: '29464',
  },
  'Old Town': {
    city: 'Scottsdale',
    state: 'AZ',
    zipcode: '85251',
  },
  'Palazzo': {
    city: 'Las Vegas',
    state: 'NV',
    zipcode: '89109',
  },
  'Park Lane': {
    city: 'Dallas',
    state: 'TX',
    zipcode: '75231',
  },
  'Park West': {
    city: 'Peoria',
    state: 'AZ',
    zipcode: '85345',
  },
  'Prairiefire': {
    city: 'Overland Park',
    state: 'KS',
    zipcode: '66223',
  },
  'Quarry Village': {
    city: 'San Antonio',
    state: 'TX',
    zipcode: '78209',
  },
  'Realm': {
    city: 'Lewisville',
    state: 'TX',
    zipcode: '75056',
  },
  'SQ': {
    city: 'Scotssdale',
    state: 'AZ',
    zipcode: '85254',
  },
  'San Tan': {
    city: 'Gilbert',
    state: 'AZ',
    zipcode: '85295',
  },
  'South Rainbow': {
    city: 'Las Vegas',
    state: 'NV',
    zipcode: '89118',
  },
  'Sparks': {
    city: 'Sparks',
    state: 'NV',
    zipcode: '89434',
  },
  'Stone Oak': {
    city: 'San Antonio',
    state: 'TX',
    zipcode: '78258',
  },
  'Towncenter': {
    city: 'Spring',
    state: 'TX',
    zipcode: '77380',
  },
  'WS West Shore': {
    city: 'Tampa',
    state: 'FL',
    zipcode: '33609',
  },
  'WW DC Ranch': {
    city: 'Scotsdale',
    state: 'AZ',
    zipcode: '85255',
  },
  'Waterfront': {
    city: 'Wichita',
    state: 'KS',
    zipcode: '67206',
  },
  'Watters Creek': {
    city: 'Allen',
    state: 'TX',
    zipcode: '75013',
  },
  'West Village': {
    city: 'Dallas',
    state: 'TX',
    zipcode: '75204',
  },
}