import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";

// AWS Amplify components
import { API, Auth } from 'aws-amplify'

// GraphQL
import { dbGetItem, listAreas2, listUsers } from '../../../graphql/queries'

// MUI components
import { Tab, Tabs } from '@mui/material'

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import DashboardLayout from 'layouts/DashboardLayout';

// Template components
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

// Icons
import GroupsIcon from '@mui/icons-material/Groups'
import StoreIcon from '@mui/icons-material/Store'

// RadixDash components
import AreaTable from './AreaTable'
import DashboardNavbar from 'components/RDNavbars/DashboardNavbar';
import Progress from 'components/Progress/Progress'
import StoreTable from './StoreTable'
import { useUser } from 'components/RDAuthContext'

const arrayToObj = (arr, key) => {
  return arr.reduce((obj, item) => {
    obj[item[key]] = item
    return obj
  }, {})
}

function TabPanel(props){
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <MDBox sx={{ p: 3 }}>
          {children}
        </MDBox>
      )}
    </div>
  )
}

function createStoreInput(arr){
  const finalArray = []
  
  for (let i=0; i < arr.length; i++){
    for (let k=0; k < arr[i].stores.length; k++){
      let storeObj = {}
      let storeArr = arr[i].stores[k]
      storeObj.areaId = arr[i].areaId
      storeObj.areaName = arr[i].areaName
      storeObj.storeId = storeArr.storeId
      storeObj.longName = storeArr.longName
      storeObj.shortName = storeArr.shortName
      storeObj.gm = storeArr.gm
      storeObj.status = storeArr.status
      storeObj.type = storeArr.type
      storeObj.bohMon = storeArr.bohBaseTheoHrs.Mon
      storeObj.bohTue = storeArr.bohBaseTheoHrs.Tue
      storeObj.bohWed = storeArr.bohBaseTheoHrs.Wed
      storeObj.bohThu = storeArr.bohBaseTheoHrs.Thu
      storeObj.bohFri = storeArr.bohBaseTheoHrs.Fri
      storeObj.bohSat = storeArr.bohBaseTheoHrs.Sat
      storeObj.bohSun = storeArr.bohBaseTheoHrs.Sun
      storeObj.fohMon = storeArr.fohBaseTheoHrs.Mon
      storeObj.fohTue = storeArr.fohBaseTheoHrs.Tue
      storeObj.fohWed = storeArr.fohBaseTheoHrs.Wed
      storeObj.fohThu = storeArr.fohBaseTheoHrs.Thu
      storeObj.fohFri = storeArr.fohBaseTheoHrs.Fri
      storeObj.fohSat = storeArr.fohBaseTheoHrs.Sat
      storeObj.fohSun = storeArr.fohBaseTheoHrs.Sun
      storeObj.compStores = storeArr.compStores
      finalArray.push(storeObj)
    }
  }
  console.log('finalArray', finalArray)
  return finalArray
}

// function createStoreInput(storeData){
//   console.log('createStoreInput()...')
//   console.log('storeData', storeData)
//   let out = flattenAreas(storeData)
//   console.log('out', out)
//   return out
// }

export default function StoreAdmin(){
  const { user } = useUser()
  console.log('user', user)
  const userName = user.username
  const role = user.role
  console.log('role', role)
  const client = user.client

  const [storesLoading, setStoresLoading] = useState(true)
  const [data, setData] = useState([])
  const [areas, setAreas] = useState([])
  const [admins, setAdmins] = useState({})
  const [areaMgrs, setAreaMgrs] = useState({})
  const [franAreaMgrs, setFranAreaMgrs] = useState({})
  const [franGenMgrs, setFranGenMgrs] = useState({})
  const [genMgrs, setGenMgrs] = useState({})
  const [managers, setManagers] = useState({})
  const [storeData, setStoreData] = useState([])
  const [stores, setStores] = useState([])
  const [tabValue, setTabValue] = useState(0)
  const [userList, setUserList] = useState([])

  // Get data when component loads
  useEffect(() => {
    getData()
  }, [])

  async function getData(){
    console.log('getData()...')
    try {
      const dbVar = {
        report: 'r0001',
        user: userName,
        role: role
      }
      const dbVarJSON = JSON.stringify(dbVar)

      console.log('client', client)

      let database
      switch(client){
        case 'dem':
          database = 'dem.areas'
          break;
        default:
          database = null
      }

      console.log('database', database)

      const queryResponse = await API.graphql({
        query: dbGetItem,
        variables: {
          db: database,
          dbVariables: dbVarJSON
        }
      })
      console.log('queryResponse', queryResponse)
      const responseAreas = JSON.parse(JSON.parse(queryResponse.data.dbGetItem.statusItem))
      console.log('responseAreas', responseAreas)
      setData(responseAreas)
    } catch (err) {
      console.log('Error fetching Areas data', err)
    }
  }

  // Set Areas and Stores once data loads
  useEffect(() => {
    
    if(data.length > 0 && userList.length > 0){
      let areaData = data.map(({stores, ...include}) => include)
          
      getAMNames(areaData)
    }    
  }, [data, userList])

  useEffect(() => {
    let rawStoreData = data.map(({areaManager, ...include}) => include)
    setStoreData(rawStoreData)
  }, [data])

  // Set userList when component loads
  useEffect(() => {

    async function fetchUserList(){
      try {
        const users = []

        const filter = {
          client: {
            eq: client
          }
        }

        let nextToken

        do {
          const queryResponse = await API.graphql({
            query: listUsers,
            variables: {
              filter: filter,
              nextToken: nextToken
            }
          })
          console.log({queryResponse})
          console.log('listUsers =>', queryResponse.data.listUsers.items)
          const userArr = queryResponse.data.listUsers.items.map(({
            createdAt,
            updatedAt,
            ...keep
          }) => keep)

          users.push(...userArr)
          nextToken = queryResponse.data.listUsers.nextToken
        } while (nextToken)

        users.sort((a, b) => (a.userName > b.userName) ? 1 : -1)
        setUserList(users)
        // const queryResponse = await API.graphql({
        //   query: listUsers
        // })
        // console.log('listUsers =>', queryResponse.data.listUsers.items)
        // const userArr = queryResponse.data.listUsers.items.map(({
        //   createdAt,
        //   id,
        //   updatedAt,
        //   ...keep
        // }) => keep)
        // setUserList(userArr)
      } catch (err) {
        console.log('Error listing users', err)
      }
    }

    fetchUserList()

  }, [])

  useEffect(() => {
    // Find all Users that are Managers and setManagers
    let areaMgrObj = {}
    let franAreaMgrObj = {}
    let franGenMgrObj = {}
    let genMgrObj = {}
    let adminObj = {}
    let managerObj = {}
    
    if(userList.length > 0){
      for(let i=0; i < userList.length; i++){
        // save names of users with role = Admin, AreaMgr, FranAreaMgr, or GenMgr
        let role = userList[i].role
        let client = userList[i].client
        
        if(role)
        {          
          switch(role){
            case 'Admin':
            case 'AreaMgr':
            case 'FranchiseAreaMgr':
            case 'FranchiseGenMgr':
            case 'GenMgr':
              let firstName = userList[i].firstName
              let lastName = userList[i].lastName
              let userName = userList[i].userName
              
              let displayName = `${firstName} ${lastName} [${userName}]`
              
              managerObj[`${userName}`] = displayName

              switch(role) {
                case 'Admin':
                  adminObj[userName] = displayName
                  break;
                case 'AreaMgr':
                case 'FranAreaMgr':
                  areaMgrObj[userName] = displayName
                  break;
                case 'GenMgr':
                case 'FranGenMgr':
                  genMgrObj[userName] = displayName
                  break;
                default:
                  // invalid role
              }          
              break;
            default:
              // No action
          }
        }
      }

      setAdmins(adminObj)
      
      setAreaMgrs(areaMgrObj)
      setGenMgrs(genMgrObj)
      setManagers(managerObj)
    }
  }, [userList])

  async function getAMNames(areaArr){
    console.log('areaArr', areaArr)
    let amArr = areaArr
    
    for(let i = 0; i < amArr.length; i++){
      let amUsername = amArr[i].areaManager
      let userIndex = userList.findIndex(x => x.Username === amUsername)
      if(userIndex !== -1){
        let firstName = ''
        let lastName = ''
        let fullName = ''
        let attributeIndex = null
        let attributeArr = userList[userIndex].Attributes
        
        // Get firstName
        attributeIndex = attributeArr.findIndex((a) => a.Name === 'custom:firstName')
        if(attributeIndex !== -1){
          firstName = attributeArr[attributeIndex].Value
        }
        
        // Get lastName
        attributeIndex = attributeArr.findIndex((a) => a.Name === 'custom:lastName')
        if(attributeIndex !== -1){
          lastName = attributeArr[attributeIndex].Value
        }
        
        fullName = firstName + ' ' + lastName
        console.log('fullName', fullName)
        amArr[i].amName = fullName
      } else {
        console.log(`${amUsername} not found in userList`)
      }
    }
    setAreas(amArr)
  }


  // Turn off progress once Areas or Stores is set
  useEffect(() => {
    if(areas.length > 0 ){
      setStoresLoading(false)
    }
  }, [areas])

  useEffect(() => {
    console.log('storeData changed', storeData)
    if(storeData.length > 0){
      // setStoresLoading(false)
      const storeInputData = createStoreInput(storeData)
      setStores(storeInputData)
    }
  }, [storeData])

  useEffect(() => {
    console.log('stores', stores)
  }, [stores])

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {
        storesLoading ? 
        <Progress /> : 
        (
          <MDBox width='1200px' mx='auto'>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label='store tabs'>
              <Tab label='Areas' />
              <Tab label='Stores' />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <AreaTable 
                areas={areas} 
                admins={admins}
                areaMgrs={areaMgrs}
                genMgrs={genMgrs}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <StoreTable 
                areas={ areas }  
                stores={ stores }
                managers={ managers }
              />
            </TabPanel>	
          </MDBox>
        )
      }
    </DashboardLayout>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number
}