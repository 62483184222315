// import { addDays, format, startOfDay } from 'date-fns';

import getNetSalesData from './getNetSalesData'

// import { netSalesData } from './netSalesData';

import { addDaysToStrDate, getHolidayCloseDatesForRange, isHoliday, multiSortObjectArray } from 'utils/helperUtils'

export default async function getCsvData(startDate, endDate, storeMap){
  console.log('getPredictor')

  const netSalesData = await getNetSalesData(startDate, endDate)
  console.log('netSalesData', netSalesData)

  const holidays = getHolidayCloseDatesForRange(startDate, endDate)
  console.log('holidays', holidays)

  const csvData = transformToCSV(netSalesData, startDate, endDate, storeMap, holidays)

  console.log('csvData', csvData)

  return {
    data: csvData
  }
}

function transformToCSV(data, startDate, endDate, storeMap, holidays){
  const results = []

  data.forEach(store => {
    if(storeMap.has(store.store) && storeMap.get(store.store).status !== 'Closed'){
      const storeData = populateStoreData(store, startDate, endDate, storeMap, holidays)
      
      results.push(...storeData)
    }
  })

  const sortedResult = multiSortObjectArray(results, 'item_id', 'timestamp')

  const csvHeaders = [
    {label: 'item_id', key: 'item_id'},
    {label: 'timestamp', key: 'timestamp'},
    {label: 'target_value', key: 'target_value'},
    {label: 'location', key: 'location'},
    {label: 'store_open', key: 'store_open'},
  ]

  const fileName = `target_time_series_${startDate}-${endDate}`

  return {
    filename: fileName,
    headers: csvHeaders,
    data: sortedResult
  }

}

function populateStoreData(storeSalesData, startDate, endDate, storeMap, holidays){
  console.log('storeSalesData', storeSalesData)
  let storeResults = []

  const existingDays = storeSalesData.values.map(d => d.day)

  const storeName = storeMap.get(storeSalesData.store).shortName
  const zip = storeMap.get(storeSalesData.store).zipcode
  const location = 'US_' + zip

  let currentDate = startDate

  while(currentDate <= endDate){
    let sales = 0
    
    let storeOpen = storeName === 'Palazzo' ? 1 : isHoliday(currentDate, holidays) ? 0 : 1

    if(existingDays.includes(currentDate) && storeOpen){
      
      sales = getSalesForDate(storeSalesData, currentDate)      
    }    

    if(storeName !== 'Palazzo' && sales === 0) {
      storeOpen = 0
    }

    storeResults.push({
      item_id: storeName,
      timestamp: currentDate,
      target_value: storeOpen ? parseFloat(sales).toFixed(2) : 0,
      location: location,
      store_open: storeOpen
    })

    currentDate = addDaysToStrDate(currentDate, 1)
  }

  return storeResults
}

function getSalesForDate(storeData, date){  

  const match = storeData.values.findIndex(day => {
    return day.day === date
  })

  if(match !== -1){
    return storeData.values[match].value
  }
}
