import PropTypes from 'prop-types'

// @mui material components
import { Icon, Stack, Tooltip } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';

// Material Dashboard 2 components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// RadixDash components
import Button_GrmHead from 'components/RDButton/Button_GrmHead'



export default function ReportAdminHeader(props){

  return (
    <MDBox
      textAlign='center'
      py={2}
      px={3}
      sx={{
        bgcolor: '#29261f',
      }}
    >
      <Stack 
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <MDTypography
          variant='h3'
          sx={{
            color: '#cf9e0b'
          }}
        >
          { props.title }
        </MDTypography>
        {
          props.button && 
          <Tooltip title='Report List'>
            <Button_GrmHead
              variant='gradient'
              iconOnly
              onClick={
                () => props.setPage('reportList')
              }
            >
              <Icon
                fontSize='large'
              >
                menu
              </Icon>
            </Button_GrmHead>
          </Tooltip>
        }
      </Stack>
    </MDBox>
  )

}

ReportAdminHeader.propTypes = {
  button: PropTypes.bool,
  setPage: PropTypes.func,
  title: PropTypes.string,
}