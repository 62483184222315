import React from 'react'

import { Box, Grid, Typography } from '@mui/material'

export default function PageTemplate({ config, width }){
  
  if(width === undefined){
    width = '1000px'
  }

  const items = [];

  let rowItems = [];
  let rowWidth = 0;

  config.forEach(item => {

    rowWidth += item.width;

    if (rowWidth <= 12) {
      // Keep adding to row  
      rowItems.push(item);
    } else {
      // New row
      items.push(
        <Grid container key={rowItems[0].id}>
          {rowItems.map(item => (
            <Grid key={item.id} item xs={12} md={item.width}>{item.component}</Grid>   
          ))}
        </Grid>
      );

      // Reset
      rowItems = [item]; 
      rowWidth = item.width;
    }

  });

  // Last row 
  items.push(
    <Grid container key={rowItems[0].id}>
       {rowItems.map(item => (
         <Grid key={item.id} item xs={item.width}>{item.component}</Grid>   
       ))}
    </Grid>
  );

  return (
    <Box width={width} sx={{bgcolor: '#fff'}}>
      {items}
    </Box> 
  );
}