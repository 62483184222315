import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, Stack, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import Textfield from 'components/FormsUI/Textfield'

// const fields = ['ProjSales', 'ForecastedSales', 'OneYearAgoSales', 'TwoYearsAgoSales', 'ProjAvgCheck', 'TheoFOH', 'TheoBOH', 'SchedFOH', 'SchedBOH']
const fields = ['ProjSales', 'Average', 'OneYearAgoSales', 'TwoYearsAgoSales', 'ProjAvgCheck', 'TheoFOH', 'TheoBOH', 'SchedFOH', 'SchedBOH']

export default function ProjectionRow({
  day,
  data,
  getTextFieldValue,
  handleTextFieldChange,
  curYear,
  expanded,
  onChange,
  style,
}){
  const panel = `${day}panel`
  
  return (
    <Box
      sx={{
        pt: 1,
      }}
      style={style}
    >
      <Accordion
        expanded={expanded}
        onChange={onChange(panel)}
      >
        <AccordionSummary
          expandIcon={
            <Box
              sx={{
                fontSize: '20px',
                pr: 1,         
              }}
            >              
              <ExpandMoreIcon />          
            </Box>
          }
          sx={{ flexDirection: 'row-reverse'}}
          aria-controls={panel}
          id={`${panel}-header`}
        >      
          <Stack
            direction='row'
            spacing={1}
            alignItems='center'
          >
            <Box
              sx={{
                writingMode: 'vertical-rl',
                textOrientation: 'upright',
                fontSize: '12px',
                textAlign: 'center',
                // borderRight: 1,
                pr: 1
              }}
            >            
              {day}           
            </Box>
            {fields.map(field => (
              <SPLHField 
                key={`${day}-${field}`}
                day={day}
                field={field}
                value={getTextFieldValue(day, field)}
                onChange={handleTextFieldChange}
                year={curYear}
              />
            ))}
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <DetailsPanel 
            year={curYear}
            day={day}
            notes={data} 
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

function SPLHField(props){
  switch(props.field){
    case 'TheoFOH':
    case 'TheoBOH':
      return (
        <Textfield 
          name={`${props.day.toLowerCase()}${props.field}`}
          label={ getFieldLabel(props.field) }
          value={ props.value}
          disabled={true}
        />
      )    
    case 'ForecastedSales':
    case 'Average':
    case 'OneYearAgoSales':
    case 'TwoYearsAgoSales':
      return (
        <Textfield 
          name={`${props.day.substring(0,3).toLowerCase()}${props.field}`}
          label={ getFieldLabel(props.field, props.year)}
          disabled={true}
        />
      )
    default:
      return (
        <Textfield 
          name={`${props.day.substring(0,3).toLowerCase()}${props.field}`}
          label={ getFieldLabel(props.field, props.year) }
          onChange={ props.onChange }
        />
      )
  }
}

function getFieldLabel(field, curYear){
  const oneYearAgo = curYear - 1
  const twoYearsAgo = curYear - 2
  switch(field){
    case 'ProjSales':
      return 'Projected Sales'
    case 'Average':
      return '2-Week Avg'
    case 'ForecastedSales':
      return 'Forecasted Sales'
    case 'OneYearAgoSales':
      return `${oneYearAgo} Sales`
    case 'TwoYearsAgoSales':
      return `${twoYearsAgo} Sales`
    case 'ProjAvgCheck':
      return 'Projected Avg Check'
    case 'TheoFOH':
      return 'Theoretical Hours FOH'
    case 'TheoBOH':
      return 'Theoretical Hours BOH'
    case 'SchedFOH':
      return 'Scheduled FOH Hours'
    case 'SchedBOH':
      return 'Scheduled BOH Hours'
    default:
      return null
  }
}

function DetailsPanel({
  year, 
  day,
  notes
}){
  const oneYearAgoNotes = notes[`${day.toLowerCase()}OneYearAgoNotes`]
  const twoYearsAgoNotes = notes[`${day.toLowerCase()}TwoYearsAgoNotes`]
  return (
    <Box>
      <Stack
        direction='row'
        justifyContent='space-evenly'
      >
        <Card
          sx={{
            width: '45%',
            backgroundColor: '#faf9f6'
          }}
        >
          
          <CardContent>
            <Typography
              variant='h6'
            >
              {year - 1} Notes
            </Typography>
            <Typography
              variant='body2'
            >
              {oneYearAgoNotes}
            </Typography>
          </CardContent>
        </Card>
        <Card
          sx={{
            width: '45%',
            backgroundColor: '#faf9f6'
          }}
        >
          
          <CardContent>
            <Typography
              variant='h6'
            >
              {year - 2} Notes
            </Typography>
            <Typography
              variant='body2'
            >
              {twoYearsAgoNotes}
            </Typography>
          </CardContent>
        </Card>
      </Stack>

    </Box>
  )
}