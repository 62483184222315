import utils from '../R0002Utils'

export default function transformToastData2(data, storeMap, shiftString) {
  // Initialize the output structure
  const output = [
    {
      data: [],
      pageId: 'r0002_LSD_company'
    },
    {
      data: [],
      pageId: 'r0002_LSD_franchise'
    }
  ]

  const dayParts = []
  if(shiftString.includes('L') || shiftString.includes('S')) dayParts.push('lunch')
  if(shiftString.includes('D')) dayParts.push('dinner')

  // Group metrics by storeId
  const metricsByStore = data.reduce((acc, item) => {
    if(!dayParts.includes(item.dayPart)){
      return acc;
    }

    const storeId = item.storeId

    // Find the store information by storeId
    let storeInfo = null
    for (let [key, value] of storeMap.entries()) {
      if(value.storeRecordId === storeId){
        storeInfo = value
        break
      }
    }
    
    if(!storeInfo){
      console.log(`Store information not found for storeId: ${storeId}`)
      return acc
    }

    if(!acc[storeId]) {
      const isEstablished = storeInfo.status === 'Established'
      acc[storeId] = {
        storeId: storeId,
        store: storeInfo.shortName,
        cCCDelivery: 0,
        cCCDineIn: 0,
        cCCToGo: 0,
        cCCTotal: 0,
        cNSDelivery: 0,
        cNSDineIn: 0,
        cNSToGo: 0,
        cNSTotal: 0,
        cACTotal: 0,
        cACDelivery: 0,
        cACDineIn: 0,
        cACToGo: 0,
        cSPLHTotal: 0,
        cFOH: 0,
        cBOH: 0,
        cOTTotal: 0,
        cOTFOH: 0,
        cOTBOH: 0,
        cLaborPct: 0,
        cLaborDollars: 0,
        // Intermediate calculation values
        _grossSales: 0,
        _bohOvertimeDollars: 0,
        _bohOvertimeHours: 0,
        _bohRegularDollars: 0,
        _bohRegularHours: 0,
        _fohOvertimeDollars: 0,
        _fohOvertimeHours: 0,
        _fohRegularDollars: 0,
        _fohRegularHours: 0,
        manager: isEstablished ? storeInfo.area : 'Others',
        rowType: isEstablished ? 'storeRow' : 'otherStoreRow',
        storeType: storeInfo.type 
      }
    }

    // Accumulate metrics
    switch (item.metricName) {
      case 'netSales':
        const salesPart = item.dinginOption === 'DELIVERY' ? 'Delivery' :
                          item.diningOption === 'DINE_IN' ? 'DineIn' : 'ToGo'
        acc[storeId][`cNS${salesPart}`] += item.numberValue
        acc[storeId].cNSTotal += item.numberValue
        break
      case 'checkCount':
        const checkPart = item.dinginOption === 'DELIVERY' ? 'Delivery' :
                          item.diningOption === 'DINE_IN' ? 'DineIn' : 'ToGo'
        acc[storeId][`cCC${checkPart}`] += item.numberValue
        acc[storeId].cCCTotal += item.numberValue
        break
      case 'grossSales':
        acc[storeId]._grossSales += item.numberValue
        break
      case 'bohOvertimeDollars':
        acc[storeId]._bohOvertimeDollars += item.numberValue
        acc[storeId].cLaborDollars += item.numberValue
        break
      case 'bohOvertimeHours':
        acc[storeId]._bohOvertimeHours += item.numberValue
        break
      case 'bohRegularDollars':
        acc[storeId]._bohRegularDollars += item.numberValue
        acc[storeId].cLaborDollars += item.numberValue
        break
      case 'bohRegularHours':
        acc[storeId]._bohRegularHours += item.numberValue
        break
      case 'fohOvertimeDollars':
        acc[storeId]._fohOvertimeDollars += item.numberValue
        acc[storeId].cLaborDollars += item.numberValue
        break
      case 'fohOvertimeHours':
        acc[storeId]._fohOvertimeHours += item.numberValue
        break
      case 'fohRegularHours':
        acc[storeId]._fohRegularHours += item.numberValue
        break
      case 'fohRegularDollars':
        acc[storeId]._fohRegularDollars += item.numberValue
        acc[storeId].cLaborDollars += item.numberValue
        break
    }

    // const type = item.metricName === 'netSales' ? 'NS' : 'CC'
    // const part = item.diningOption === 'DELIVERY' ? 'Delivery' : item.diningOption === 'DINE_IN' ? 'DineIn' : 'ToGo'
    // const key = `c${type}${part}`

    // acc[storeId][key] += item.numberValue
    // acc[storeId][`c${type}Total`] += item.numberValue

    return acc
  }, {})

  console.log('metricsByStore', metricsByStore)

  // Calculate the Average Check and add stores to appropriate arrays
  for (const storeId in metricsByStore) {
    const store = metricsByStore[storeId];
  
    // Store original net sales values
    const originalNetSales = {
      delivery: store.cNSDelivery,
      dineIn: store.cNSDineIn,
      toGo: store.cNSToGo,
      total: store.cNSTotal
    };

    const fohHours = store._fohOvertimeHours + store._fohRegularHours
    const bohHours = store._bohOvertimeHours + store._bohRegularHours
    const totalHours = fohHours + bohHours

    // Calculate SPLH metrics
    store.cSPLHTotal = totalHours > 0 ? store._grossSales / totalHours : 0
    store.cFOH = fohHours > 0 ? store._grossSales / fohHours : 0
    store.cBOH = bohHours > 0 ? store._grossSales / bohHours : 0

    // Calculate Overtime metrics
    store.cOTTotal = (store._bohOvertimeDollars + store._fohOvertimeDollars) / 3
    store.cOTFOH = store._fohOvertimeDollars / 3
    store.cOTBOH = store._bohOvertimeDollars / 3

    // Calculate Labor percentage
    // const overtimeDollars = store._bohOvertimeDollars + store._fohOvertimeDollars
    // const regularDollars = store._bohRegularDollars + store._fohRegularDollars
    // const totalLaborCost = overtimeDollars + regularDollars
    // console.log('totalLaborCost', totalLaborCost)
    // console.log('cLaborDollars', store.cLaborDollars)
    store.cLaborPct = store.cNSTotal > 0 ? (store.cLaborDollars / store.cNSTotal) * 100 : 0

    // Calculate average checks
    store.cACDelivery = calculateAverageCheck(originalNetSales.delivery, store.cCCDelivery);
    store.cACDineIn = calculateAverageCheck(originalNetSales.dineIn, store.cCCDineIn);
    store.cACToGo = calculateAverageCheck(originalNetSales.toGo, store.cCCToGo);
    store.cACTotal = calculateAverageCheck(originalNetSales.total, store.cCCTotal);
    
    // Format metrics
    ['cNSDelivery', 'cNSDineIn', 'cNSToGo', 'cNSTotal', 'cCCDelivery', 'cCCDineIn', 'cCCToGo', 'cCCTotal'].forEach(key => {
      store[key] = utils.formatNumberWithCommas(Math.round(store[key]));
      if (key.startsWith('cNS')) {
        store[key] = `$${store[key]}`;
      }
    });

    ['cSPLHTotal', 'cFOH', 'cBOH', 'cOTTotal', 'cOTFOH', 'cOTBOH', 'cLaborDollars'].forEach(key => {
      store[key] = `$${store[key].toFixed(0)}`
    })

    store.cLaborPct = `${store.cLaborPct.toFixed(2)}%`    

    const storeObject = {
      rowData: { ...store },
      rowType: store.rowType
    }

    delete storeObject.rowData.rowType
    delete storeObject.rowData.storeType
    // Object.keys(storeObject.rowData).forEach(key => {
    //   if (key.startsWith('_')) {
    //     delete storeObject.rowData[key]
    //   }
    // })

    // Push to appropriate array based on store type
    if (store.storeType === 'B&M') {
      output[0].data.push(storeObject);
    } else if (store.storeType === 'Franchise') {
      output[1].data.push(storeObject);
    }
  }

  // Append '_empty' to pageId if no data for that category
  if (output[0].data.length === 0) {
    output[0].pageId += '_empty'
  }
  if (output[1].data.length === 0) {
    output[1].pageId += '_empty'
  }

  return output;
}

// Helper function to safely parse numbers with commas
const safeParseInt = (value) => parseInt(value.replace(/,/g, ''), 10)

// Helper function to calculate average check
const calculateAverageCheck = (netSales, checkCount) => {
  // const parsedCheckCount = safeParseInt(checkCount);
  // const avgCheck = parsedCheckCount === 0 ? '$0.00' : `$${(netSales / parsedCheckCount).toFixed(2)}`;
  const avgCheck = checkCount === 0 ? '$0.00' : `$${(netSales / checkCount).toFixed(2)}`;
  return avgCheck
};