import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material'

import { createTheme, ThemeProvider } from '@mui/material/styles'

import grmTheme from '../../grmTheme'

import Checkbox_GrmDark from 'components/Checkbox/Checkbox_GrmDark'

function DayParts(props){
  const active = props.active

  const [shifts, setShifts] = useState(props.shifts)

  function handleShifts(shift){
    setShifts({ ...shifts, [shift.target.name]: shift.target.checked })

    props.shiftChange(shift)
  }

  return (
    <ThemeProvider theme={grmTheme}>
      <FormControl component='fieldset'>
        <FormLabel component='legend'>Day Parts</FormLabel>
        <FormGroup >
          <FormControlLabel
            control={
              <Checkbox_GrmDark
                checked={ shifts.lunch }
                onChange={ handleShifts }
                name='lunch'
                disabled={ !active.lunch }
              />
            }
            label='Lunch'          
          />
          <FormControlLabel
            control={
              <Checkbox_GrmDark
                checked={ shifts.social }
                onChange={ handleShifts }
                name='social'
                disabled={ !active.social }
              />
            }
            label='Social'
          />
          <FormControlLabel
            control={
              <Checkbox_GrmDark
                checked={ shifts.dinner }
                onChange={ handleShifts }
                name='dinner'
                disabled={ !active.dinner }
              />
            }
            label='Dinner'
          />
        </FormGroup>
      </FormControl>
    </ThemeProvider>
  )
}

export default DayParts

DayParts.propTypes = {
  active: PropTypes.object,
  shiftChange: PropTypes.func,
  shifts: PropTypes.object
}