import React, { useState } from 'react'

import { Divider, Stack } from '@mui/material'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

import Button_GrmDark from 'components/RDButton/Button_GrmDark'
import RDSwitch from 'components/RDSwitch'

import R0004Years from './R0004Years'

export default function R0004Config({ 
  areasActive,
  setAreasActive,
  years, 
  selectedYears, 
  setSelectedYears,
  period,
  setPeriod,
  reportType,
  setReportType,
  selectStores,
  selectProducts,
}){

  const switchPeriod = (value) => {
    const period = value ? 'month' : 'quarter'
    setPeriod(period)
  }

  const periodSwitchValue = period === 'month'

  const switchReportType = (value) => {
    const type = value ? 'count' : 'sales'
    setReportType(type)
  }

  const typeSwitchValue = reportType === 'count'

  const switchAreasActive = (value) => {
    const areas = value ? 'noAreas' : 'yesAreas'
    setAreasActive(areas)
  }

  const areasActiveSwitchValue = areasActive === 'noAreas'

  return (
    <Stack
      direction='column'
      justifyContent='center'
      alignItems='center'
      spacing={2}
    >
      <MDTypography variant='h6'>View Selectors</MDTypography>
      <RDSwitch 
        disabled={ false }
        leftLabel='Quarter'
        rightLabel='Month'
        toggleSwitch= { switchPeriod }
        value={ periodSwitchValue }
        style={{
          activeColor: '#a44c0e',
          disabledColor: '#b9ad9c'
        }}
      />
      <RDSwitch 
        disabled={ false }
        leftLabel='Sales'
        rightLabel='Count'
        toggleSwitch= { switchReportType }
        value={ typeSwitchValue }
        style={{
          activeColor: '#a44c0e',
          disabledColor: '#b9ad9c'
        }}
      />
      <Divider />
      <MDTypography variant='h6'>Data Filters</MDTypography>
      <R0004Years 
        years={ years }
        selectedYears={ selectedYears }
        setSelectedYears={ setSelectedYears }
      />
      <MDBox>
        <Stack
          direction='column'
          spacing={2}
        >
        <Button_GrmDark 
          onClick={ selectProducts }
        >
          Products
        </Button_GrmDark>
        <Button_GrmDark 
          onClick={ selectStores }
        >
          Stores
        </Button_GrmDark>
        </Stack>
      </MDBox>
      <RDSwitch 
        disabled={ false }
        leftLabel='Areas'
        rightLabel='No Areas'
        toggleSwitch= { switchAreasActive }
        value={ areasActiveSwitchValue }
        style={{
          activeColor: '#a44c0e',
          disabledColor: '#b9ad9c'
        }}
      />
    </Stack>
  )
}