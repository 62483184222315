import { createTheme } from '@mui/material/styles'

const grmTheme = createTheme({
  palette: {
    primary: {
      main: '#a44c0e',
      contrastText: '#f9e9d3'
    },
    secondary: {
      main: '#c4960c',
      contrastText: '#723509'
    },
    header: {
      main: '#29261f',
      contrastText: '#cf9e0b'
    }
  },
  grm: {
    primary: {
      main: '#a44c0e',
      contrastText: '#f9e9d3'
    },
    secondary: {
      main: '#c4960c',
      contrastText: '#723509'
    },
    disabled: {
      main: '#b9ad9c',
      contrastText: '#a29889'
    }
  },
  components: {
    MuiAutocomplete: {
      // '& .MuiOutlinedInput-notchedOutline': {
      //   borderColor: 'red'
      // },
      // '&:hover .MuiOutlinedInput-notchedOutline': {
      //   borderColor: '#906e07'
      // },
      // '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      //   borderColor: '#906e07'
      // },
      // '& .MuiAutocomplete-inputFocused': {
      //   bgcolor: '#cf9e0b'
      // },
      // '& .MuiAutocomplete-endAdornment': {
      //   '& .MuiIconButton-root': {
      //     color: '#cf9e0b'
      //   }
      // },
      // '& .MuiFormLabel-root': {               
      //   color: '#CF9E0B'
      // },
      // '& .MuiInputBase-input': {
      //   color: '#CF9E0B',
      // },

    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: '#a44c0e',
          color: '#f9e9d3',
          '&: hover': {
            backgroundColor: '#cf9e0b',
            color: '#29261f'
          }
        }
      },
      variants: [
        {
          props: { variant: 'header' },
          style: {
            backgroundColor: '#cf9e0b',
            color: '#29261f',
            '&: hover': {
              backgroundColor: '#906e07'
            }
          }
        }
      ]
    }
  }
})

export default grmTheme