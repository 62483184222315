import React, { useEffect, useState } from 'react';

// @mui material components
import { Box } from '@mui/material'

import { format, parse, startOfWeek, subDays } from 'date-fns'

// Theme components
import { ThemeProvider } from "@mui/material/styles";
import grmTheme from 'assets/theme/clients/grmTheme'

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setOpenConfigurator } from "context";
import RDConfigurator from 'components/RDConfigurator'

// Layout Components
import DashboardLayout from 'layouts/DashboardLayout';
import DashboardNavbar from 'components/RDNavbars/DashboardNavbar';
import PageTemplate from 'components/common/core/PageTemplate'

// Report Components
import FooterBase from 'components/common/elements/FooterBase'
import R0005Header from './R0005Header';
import R0005Table from './R0005Table/R0005Table';
import R0005Config from './R0005Config';

import { getAreas, getWeekRange } from './r0005Utils';

import useFilter from 'hooks/useFilter';
import useTableData from './r0005Hooks/useTableData';

const initialWeekStartDate = format(startOfWeek(subDays(new Date(), 1), {weekStartsOn: 1}), 'yyyy-MM-dd')

export default function R0005Main(){
  console.log('**** R0005Main ****')

  const [areas, setAreas] = useState([])
  const [filters, setFilters] = useState([{
    field: 'area',
    operator: 'equals',
    value: '',
  }])
  const [filter, setFilter] = useState(filters[0].value)
  const [reportTitle, setReportTitle] = useState('Revenue Projection Report')
  const [weekStartDate, setWeekStartDate] = useState(initialWeekStartDate)

  const { data, status } = useTableData(weekStartDate) 

  const {filteredData, filteredStatus} = useFilter(data, status, filters)

  const handleDropdownChange = (area) => {
    setFilters([
      {
        field: 'area',
        operator: 'equals',
        value: area
      }
    ])
  }

  useEffect(() => {
    console.log('** filters **', filters)
    setFilter(filters[0].value)
  }, [filters])

  

  useEffect(() => {

    if(status === 'success') {
      const newAreas = getAreas(data)
      newAreas.sort()
      setAreas(newAreas)
    } 
  }, [data, status])  

  useEffect(() => {
    if(areas.length > 0) {
      setFilters([{
        field: 'area',
        operator: 'equals',
        value: areas[0],
      }])
    }
  }, [areas])

  useEffect(() => {   

    const weekRange = getWeekRange(weekStartDate) 
    setReportTitle(`Revenue Projection Report | ${weekRange.from} - ${weekRange.to}`)
  }, [weekStartDate])

  const handleWeekChange = (date) => {
    console.log('date', date)
    handleConfiguratorOpen()
    setWeekStartDate(date)
  }
  
  /******************************** 
   * CONFIG handling
   ********************************/

  // Configurator State controls
  const [controller, dispatch] = useMaterialUIController();  
  const {
    openConfigurator,
  } = controller;
  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  const config = [
    {
      key: 'header',
      id: 1,
      width: 12,
      component: <R0005Header title={reportTitle} areas={areas} filter={filter} onChange={handleDropdownChange} tableData={filteredData} onConfigChange={handleConfiguratorOpen} />
    },
    {
      key: 'table',
      id: 2,
      width: 12,
    //   component: <R0005Table data={data} />
      component: <R0005Table data={filteredData}
      startDate={weekStartDate} status={filteredStatus} /> 
    },
    {
      key: 'footer',
      id: 3,
      width: 12,
      component: <FooterBase center='copyright' />
    }
  ]

  return (
    <ThemeProvider theme={grmTheme}>
      <DashboardLayout>
        <DashboardNavbar />
        <Box
          mb={3}
          sx={{
            width: '1200px',
            mx: 'auto'
          }}
        >        
          <PageTemplate 
            config={config}
            maxWidth='1000px'
            width='100%'
          />
          <RDConfigurator
            report='R0005'
            component={
              <R0005Config 
                selectedWeek={weekStartDate}
                onChange={handleWeekChange}
              />
            } 
          />
        </Box>
      </DashboardLayout>
    </ThemeProvider>
  )
}
