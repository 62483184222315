import { useEffect, useMemo, useState } from 'react'

import { Accordion, Box, Card, CardContent, Grid, Paper, Stack } from '@mui/material'

import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import Textfield from 'components/FormsUI/Textfield'
import Button_GrmDark from 'components/RDButton/Button_GrmDark'
import ProjectionRow from './ProjectionRow'

const FORM_VALIDATION = Yup.object().shape({
  week: Yup.string()
    .required('Required'),
  store: Yup.string()
    .required('Required'),
  avgWage: Yup.number()
    .required('Required'),
  monProjSales: Yup.number()
  .required('Required'),
  monProjAvgCheck: Yup.number()
  .required('Required'),
  monSchedFOH: Yup.number()
  .required('Required'),
  monSchedBOH: Yup.number()
  .required('Required'),
  tueProjSales: Yup.number()
  .required('Required'),
  tueProjAvgCheck: Yup.number()
  .required('Required'),
  tueSchedFOH: Yup.number()
  .required('Required'),
  tueSchedBOH: Yup.number()
  .required('Required'),
  wedProjSales: Yup.number()
  .required('Required'),
  wedProjAvgCheck: Yup.number()
  .required('Required'),
  wedSchedFOH: Yup.number()
  .required('Required'),
  wedSchedBOH: Yup.number()
  .required('Required'),
  thuProjSales: Yup.number()
  .required('Required'),
  thuProjAvgCheck: Yup.number()
  .required('Required'),
  thuSchedFOH: Yup.number()
  .required('Required'),
  thuSchedBOH: Yup.number()
  .required('Required'),
  friProjSales: Yup.number()
  .required('Required'),
  friProjAvgCheck: Yup.number()
  .required('Required'),
  friSchedFOH: Yup.number()
  .required('Required'),
  friSchedBOH: Yup.number()
  .required('Required'),
  satProjSales: Yup.number()
  .required('Required'),
  satProjAvgCheck: Yup.number()
  .required('Required'),
  satSchedFOH: Yup.number()
  .required('Required'),
  satSchedBOH: Yup.number()
  .required('Required'),
  sunProjSales: Yup.number()
  .required('Required'),
  sunProjAvgCheck: Yup.number()
  .required('Required'),
  sunSchedFOH: Yup.number()
  .required('Required'),
  sunSchedBOH: Yup.number()
  .required('Required'),
})

export default function ProjectionBody({
  data,
  getTextFieldValue,
  handleTextFieldChange,
  handleSubmitProjections,
  handleCancel,
  week
}){
  const days = useMemo(() => ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  [])

  const [curYear, setCurYear] = useState(new Date().getFullYear())
  const [expanded, setExpanded] = useState(null)

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    
    setExpanded(isExpanded ? panel : null)
    
  }
  
  useEffect(() => {
    if(week){
      setCurYear(parseInt(week.slice(0,4)))
    }
  }, [week])
  
  return (
    <Box
      textAlign='left'
      mx={4}
      my={3}
      sx={{
        backgroundColor: 'white'
      }}
    >
      <Formik
        initialValues={{ ...data }}
        validationSchema={FORM_VALIDATION}
        validateOnMount
        enableReinitialize
        onSubmit={values => {
          handleSubmitProjections(values);
        }}
      >
        {({ isValid }) => (
          <Form>
            <Stack
              direction='column'
              spacing={0}
            >
              {days.map((day, index) => (
                <ProjectionRow 
                  key={day}
                  day={day}
                  data={data}
                  getTextFieldValue={getTextFieldValue}
                  handleTextFieldChange={handleTextFieldChange}
                  curYear={curYear}
                  expanded={expanded === `${day}panel`}
                  onChange={ () => handleAccordionChange(`${day}panel`)}
                  style={{
                    ...(index === 0 && {
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }),
                    ...(index === days.length - 1 && {
                      borderBottomLeftRadius: 10,
                      borderBottomRightRadius: 10,
                    }),
                  }}
                />
              ))}
              <Stack 
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                mt={2}
              >
                <Card>
                  <CardContent
                    sx={{
                      p: 2,
                      '&:last-child': { pb: 2 },
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <Textfield 
                      name='avgWage' 
                      label='Average Wage' 
                      sx={{
                        flexGrow: 1,
                      }}
                    />
                  </CardContent>
                </Card>
                <Stack
                  direction='row'
                  spacing={2}
                >
                  <Button_GrmDark 
                    fullWidth
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button_GrmDark>

                  <Button_GrmDark
                    fullWidth
                    type='submit'
                    disabled={!isValid}
                  >
                    Submit
                  </Button_GrmDark>
                </Stack>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  )
}