import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// @mui components
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Dialog, DialogTitle, DialogContent, Grid, IconButton, Stack, Tooltip } from '@mui/material'
// import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
// import CheckBoxIcon from '@mui/icons-material/CheckBox'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

// formik components
import { Field, FieldArray, Formik, Form } from 'formik'
import Textfield from 'components/FormsUI/Textfield'
import * as Yup from 'yup'

// Material Dashboard Pro 2 components
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

// RadixDash components
import ComparisonStoreAccordion from './Accordions/ComparisonStoreAccordion';
import Progress from 'components/Progress/Progress'
import Select_Rd from 'components/FormsUI/Select/Select_Rd'

export default function EditStoreDialog(props){
  console.log('props', props)
  const rowData = props.rowData.values
  const rawData = props.rawData
  console.log('rowData', rowData)
  console.log('rawData', rawData)
  
  const areas = props.areas.reduce((obj, item) => Object.assign(obj, { [item.areaName]: item.areaName}), {})
  
  const [openProgress, setProgress] = useState(props.openProgress)
  const [expanded, setExpanded] = useState(false)
  const [managerArr, setManagerArr] = useState([])
  // const [managerObj, setManagerObj] = useState({})
  const [rawStore, setRawStore] = useState({
    areaId: '',
    areaName: '',
    storeId: '',
    longName: '',
    shortName: '',
    status: '',
    type: '',
    fohMon: 0,
    fohTue: 0,
    fohWed: 0,
    fohThu: 0,
    fohFri: 0,
    fohSat: 0,
    fohSun: 0,
    bohMon: 0,
    bohTue: 0,
    bohWed: 0,
    bohThu: 0,
    bohFri: 0,
    bohSat: 0,
    bohSun: 0,
    gm: [],
    compStores: []
  })
  const [companyStores, setCompanyStores] = useState([])
  // const [defaultStores, setDefaultStores] = useState([])
  const [selectedStores, setSelectedStores] = useState([])
  // const [compStoreValue, setCompStoreValue] = useState()
  // const [isChecked, setIsChecked] = useState()

  useEffect(() => {
    const rawIdx = rawData.findIndex(x => x.shortName === rowData.shortName)
    if(rawIdx !== -1){
      setRawStore(rawData[rawIdx])
    }
  },[])
   
  let typeObj = {}
  typeObj['B&M'] = "B&M"
  typeObj['Ghost Kitchen'] = 'Ghost Kitchen'
  typeObj['Franchise'] = 'Franchise'
  const statusObj = { New: 'New', Established: 'Established', Closed: 'Closed'}
  
  function handleSubmit(values){
    console.log('handleSubmit', values)
    values.compStores = selectedStores
    props.submit({
      index: props.rowData.index,
      oldData: props.rowData.original,
      newData: values
    })
  }  

  useEffect(() => {  
    let mgrArr = []
    // let mgrObj = {}
    rawStore.gm.forEach(x => {
      // mgrObj[x] = props.managers[x]
      mgrArr.push(x)
    })
    if(mgrArr.length === 0){
      mgrArr.push('')
    }

    setManagerArr(mgrArr)
    // setManagerObj(mgrObj)

    /**  FRANCHISE STORES only  **/
    if(rawStore.type === 'Franchise'){
      // Set Company Store list
      const compStores = props.rawData.filter(x => x.type === 'B&M').map(y => ({ storeId: y.storeId, shortName: y.shortName }))
      compStores.sort((a,b) => a.shortName > b.shortName ? 1 : -1)
      setCompanyStores(compStores)

      // Set isChecked state
      // let checked = {}
      // props.rawData.forEach(x => {
      //   const isComp = rawStore.compStores.some(y => y.shortName === x.shortName)
      //   return (
      //     // checked[x.shortName] = isComp
      //     checked[x.shortName] = {
      //       checked: isComp,
      //       storeId: x.storeId
      //     }
      //   )
      // })
      
      // setIsChecked(checked)

      // Initialize value of selected stores
      const initialStores = rawStore.compStores
      if(initialStores === undefined){
        setSelectedStores([])
      } else {
        setSelectedStores(initialStores)
      }
    }
  }, [rawStore])

  const INITIAL_FORM_STATE = {
    areaName: rawStore.areaName,
    shortName: rawStore.shortName,
    managers: managerArr,
    status: rawStore.status,
    type: rawStore.type,
    monFOH: rawStore.fohMon,
    tueFOH: rawStore.fohTue,
    wedFOH: rawStore.fohWed,
    thuFOH: rawStore.fohThu,
    friFOH: rawStore.fohFri,
    satFOH: rawStore.fohSat,
    sunFOH: rawStore.fohSun,
    monBOH: rawStore.bohMon,
    tueBOH: rawStore.bohTue,
    wedBOH: rawStore.bohWed,
    thuBOH: rawStore.bohThu,
    friBOH: rawStore.bohFri,
    satBOH: rawStore.bohSat,
    sunBOH: rawStore.bohSun,
    compStores: selectedStores,
  }

  const FORM_VALIDATION = Yup.object().shape({
    areaName: Yup.string()
      .required('Required'),
    shortName: Yup.string()
      .required('Required'),
    managers: Yup.array(),
    status: Yup.string()
      .required('Required'),
    type: Yup.string()
      .required('Required'),
    monFOH: Yup.number()
      .positive('Positive values only')
      .required('Required'),
    tueFOH: Yup.number()
      .positive('Positive values only')
      .required('Required'),
    wedFOH: Yup.number()
      .positive('Positive values only')
      .required('Required'),
    thuFOH: Yup.number()
      .positive('Positive values only')
      .required('Required'),
    friFOH: Yup.number()
      .positive('Positive values only')
      .required('Required'),
    satFOH: Yup.number()
      .positive('Positive values only')
      .required('Required'),
    sunFOH: Yup.number()
      .positive('Positive values only')
      .required('Required'),
    monBOH: Yup.number()
      .positive('Positive values only')
      .required('Required'),
    tueBOH: Yup.number()
      .positive('Positive values only')
      .required('Required'),
    wedBOH: Yup.number()
      .positive('Positive values only')
      .required('Required'),
    thuBOH: Yup.number()
      .positive('Positive values only')
      .required('Required'),
    friBOH: Yup.number()
      .positive('Positive values only')
      .required('Required'),
    satBOH: Yup.number()
      .positive('Positive values only')
      .required('Required'),
    sunBOH: Yup.number()
      .positive('Positive values only')
      .required('Required'),
    compStores: Yup.array()
      .nullable()
  })

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  // const handleCompStoreChange = (event, stores) => {
  //   console.log('selectedStores', selectedStores)
  //   console.log('stores', stores)
    
  //   setSelectedStores(stores)
  // }

  // const handleCheckboxChange = (event) => {
  //   console.log('handleCheckboxChange', event)
  //   const selectedItem = event.target.value
  //   console.log('selectedItem', selectedItem)
  //   let updatedCheckObj = {...isChecked}
  //   console.log('updatedCheckObj pre', updatedCheckObj)
  //   updatedCheckObj[selectedItem].checked = event.target.checked
  //   console.log('updatedCheckObj post', updatedCheckObj)
  //   setIsChecked(updatedCheckObj)

  //   let updatedSelectedStores = [...selectedStores]
  //   const storeIndex = updatedSelectedStores.findIndex(x => x.storeId === updatedCheckObj[selectedItem].storeId )
  //   console.log('storeIndex', storeIndex)
  //   console.log('updatedSelectedStores', updatedSelectedStores)

  //   switch(storeIndex){
  //     case -1:  // Store is not in selectedStores
  //       console.log(`Index = ${storeIndex}: add store`)
  //       if(event.target.checked){
  //         // Add store to selectedStores
  //         const storeObj = {
  //           storeId: updatedCheckObj[selectedItem].storeId,
  //           shortName: selectedItem
  //         }
  //         updatedSelectedStores.push(storeObj)
  //         updatedSelectedStores.sort((a,b) => a.shortName > b.shortName ? 1 : -1)
  //         console.log('updatedSelectedStores after add', updatedSelectedStores)
  //         setSelectedStores(updatedSelectedStores)
  //       }
  //       break;
  //     default:  // Store found in selectedStores
  //       // Remove store from selectedStores
  //       console.log('updatedSelectedStores pre', updatedSelectedStores)
  //       updatedSelectedStores.splice(storeIndex, 1)
  //       console.log('updatedSelectedStores post', updatedSelectedStores)
  //       setSelectedStores(updatedSelectedStores)
  //   }
  // }

  return (
    <Dialog
      open={ props.open }
      fullWidth
      maxWidth='md'
    >
      <DialogTitle>
        Store Settings: {rawStore.shortName}
      </DialogTitle>
      <DialogContent>
        { openProgress ?
          <Progress /> :
        
        <Formik
          initialValues={INITIAL_FORM_STATE}
          validationSchema={FORM_VALIDATION}
          enableReinitialize={ true }
          onSubmit={ (values) => handleSubmit(values) }
        >
          {({ values, isValid, dirty, errors }) => (
            <Form>
              <Stack direction='column' spacing={2}>
                <Select_Rd 
                  name='areaName'
                  options={ areas }
                  label='Area'
                  sx={{
                    mt: 1
                  }}
                />  
                <Stack direction='row' spacing={2}>
                  <Select_Rd 
                    name='type'
                    options={ typeObj }
                    label='Store Type'
                  />
                  <Select_Rd 
                    name='status'
                    options={ statusObj }
                    label='Store Status'
                  />
                </Stack>
                <Accordion
                  expanded={expanded === 'Managers'}
                  onChange={handleAccordionChange('Managers')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon fontSize='medium' />}
                  >
                    <MDTypography variant='h6'>
                      Managers [{managerArr.length}]
                    </MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FieldArray name='managers'>
                      
                      {({ insert, remove, push }) => (
                        <div>
                          { values.managers.length > 0 &&
                            values.managers.map((manager, index, {length}) => (
                              <div>
                                <Stack direction='row' sx={{ mb: 2 }}>
                                
                                  <Select_Rd
                                    name={`managers.${index}`}
                                    options={ props.managers }
                                    label={`Manager-${index + 1}`}
                                  />
                                
                                  { index > 0 ?
                                    (
                                      <Tooltip title='Delete Manager'>
                                        <IconButton onClick={() => remove(index)}><RemoveIcon/></IconButton>
                                      </Tooltip>
                                      ) :
                                    <IconButton disabled><RemoveIcon/> </IconButton>
                                  }
    
                                  { ((index+1) === length) &&
                                    <Tooltip title='Add Manager'>
                                      <IconButton onClick={() => push('')}><AddIcon/></IconButton>
                                    </Tooltip>
                                  }
                                </Stack>
                              </div>
                            ))
                          }
                        </div>
                      )}
                      
                    </FieldArray>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 'TheoHours'}
                  onChange={handleAccordionChange('TheoHours')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon fontSize='medium' />}
                  >
                    <MDTypography variant='h6'>
                      Theoretical Hours
                    </MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      
                      <Grid item xs={4} style={{ textAlign:'center' }}>
                        <MDTypography variant='body2'>
                          Mon
                        </MDTypography>
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='monFOH'
                          label='Mon FOH'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='monBOH'
                          label='Mon BOH'
                        />
                      </Grid>
                      
                      <Grid item xs={4} style={{ textAlign:'center' }}>
                        <MDTypography variant='body2'>
                          Tue
                        </MDTypography>
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='tueFOH'
                          label='Tue FOH'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='tueBOH'
                          label='Tue BOH'
                        />
                      </Grid>
                      
                      <Grid item xs={4} style={{ textAlign:'center' }}>
                        <MDTypography variant='body2'>
                          Wed
                        </MDTypography>
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='wedFOH'
                          label='Wed FOH'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='wedBOH'
                          label='Wed BOH'
                        />
                      </Grid>
                      
                      <Grid item xs={4} style={{ textAlign:'center' }}>
                        <MDTypography variant='body2'>
                          Thu
                        </MDTypography>
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='thuFOH'
                          label='Thu FOH'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='thuBOH'
                          label='Thu BOH'
                        />
                      </Grid>
                      
                      <Grid item xs={4} style={{ textAlign:'center' }}>
                        <MDTypography variant='body2'>
                          Fri
                        </MDTypography>
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='friFOH'
                          label='Fri FOH'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='friBOH'
                          label='Fri BOH'
                        />
                      </Grid>
                      
                      <Grid item xs={4} style={{ textAlign:'center' }}>
                        <MDTypography variant='body2'>
                          Sat
                        </MDTypography>
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='satFOH'
                          label='Sat FOH'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='satBOH'
                          label='Sat BOH'
                        />
                      </Grid>
                      
                      <Grid item xs={4} style={{ textAlign:'center' }}>
                        <MDTypography variant='body2'>
                          Sun
                        </MDTypography>
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='sunFOH'
                          label='Sun FOH'
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Textfield
                          name='sunBOH'
                          label='Sun BOH'
                        />
                      </Grid>

                    </Grid>
                    
                  </AccordionDetails>
                </Accordion>
                { rawStore.type === 'Franchise' ?
                  <ComparisonStoreAccordion
                    companyStores={ companyStores }
                    expanded={ expanded }
                    handleChange={ handleAccordionChange }
                    selectedStores={ selectedStores }
                    setSelections={ setSelectedStores }
                  />
                  : null
                }
                <Stack direction='row' spacing={2} justifyContent='flex-end'>
                  <MDButton variant='contained' color='info' onClick={ props.close }>
                    Cancel
                  </MDButton>
                  <MDButton variant='contained' color='info' type='submit' >
                    Submit
                  </MDButton>
                </Stack>
                {/* <div>
                  isValid: {isValid ? 'true' : 'false'}
                  <br></br>
                  dirty: {dirty ? 'true' : 'false'}
                  <br></br>
                  errors: {JSON.stringify(errors)}
                </div> */}
              </Stack>              
            </Form>
          )}
        </Formik>
        }
      </DialogContent>
    </Dialog>
  )
}

Autocomplete.propTypes = {
  id: PropTypes.string
}

EditStoreDialog.propTypes = {
  'data-option-index': PropTypes.number,
  id: PropTypes.string,
  key: PropTypes.string,
  areas: PropTypes.array,
  close: PropTypes.func,
  managers: PropTypes.object,
  open: PropTypes.bool,
  openProgress: PropTypes.bool,
  rawData: PropTypes.array,
  rowData: PropTypes.object,
  submit: PropTypes.func,
}

