import theme from '../../index'

import { createTheme } from '@mui/material/styles'

import components from './components'
import palette from './palette'

const grmTheme = createTheme({
  ...theme,

  components: components,
  palette: palette,
})

export default grmTheme