import { useState, useEffect, useCallback } from 'react'
import { API } from 'aws-amplify'
import { getR0003Reportv2, listAreas2 } from 'graphql/queries'
import { fetchToastData } from '../fetchToastData'
import { transformToastData } from '../transformToastData'
import { mergeMetrics } from '../utils/mergeMetrics'

const WEEKDAY_DAYS = ['Mon', 'Tue', 'Wed', 'Thu']
const FOH_WEEKDAY_DENOMINATOR = 316
const FOH_WEEKEND_DENOMINATOR = 230
const BOH_WEEKDAY_DENOMINATOR = 345
const BOH_WEEKEND_DENOMINATOR = 316

export const useR0003Data = (startDate, user, role, storeIdMap) => {
  console.log('useR0003Data hook')

  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  const fetchDataCallback = useCallback(async () => {
    if (!storeIdMap) {
      return
    }
    
    setLoading(true)

    try {
      // Calculate prior week's start date
      const priorWeekStart = new Date(startDate)
      priorWeekStart.setDate(priorWeekStart.getDate() - 7)
      const priorWeekStartStr = priorWeekStart.toISOString().split('T')[0]

      // Fetch base theoretical hours data
      const baseTheoResponse = await API.graphql({
        query: listAreas2,
        variables: {
          report: 'r0003_store_dropdown',
          user: user,
          role: 'Admin'
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })
      const baseTheoData = baseTheoResponse.data.listAreas2[0].stores
      
      // Create a map of store names to their base theoretical hours
      const baseTheoMap = baseTheoData.reduce((acc, store) => {
        acc[store.shortName] = {
          foh: store.fohBaseTheoHrs,
          boh: store.bohBaseTheoHrs
        }
        return acc
      }, {})
      
      // Fetch Aloha data from MongoDB
      const alohaResponse = await API.graphql({
        query: getR0003Reportv2,
        variables: { week: startDate, user, role },
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      })
      const alohaData = alohaResponse.data.getR0003Reportv2.data
      console.log('alohaData', alohaData)

      // Fetch current week Toast data from DynamoDB
      const toastData = await fetchToastData(startDate)
      console.log('toastData', toastData)

      // Fetch prior week Toast data from DynamoDB
      const priorWeekToastData = await fetchToastData(priorWeekStartStr)
      console.log('priorWeekToastData', priorWeekToastData)

      const transformedToastData = transformToastData(toastData, priorWeekToastData, storeIdMap)
      console.log('transformedToastData', transformedToastData)

      // Add theoretical hours calculations to alohaData
      const enrichedAlohaData = calculateTheoreticalHours(alohaData, transformedToastData, baseTheoMap, startDate)
      console.log('enrichedAlohaData', enrichedAlohaData)

      const mergedMetrics = mergeMetrics(enrichedAlohaData, transformedToastData, startDate, baseTheoMap)
      console.log('mergedMetrics', mergedMetrics)

      setData(mergedMetrics)
      
    } catch (err) {
      console.log('Error fetching R0003 data:', err)
      setError(err)
    } finally {
      setLoading(false)
    }  
  }, [startDate, user, role, storeIdMap])

  useEffect(() => {
    fetchDataCallback()
  }, [fetchDataCallback])

  return { data, error, loading }
}

// Helper function to calculate theoretical hours
function calculateTheoreticalHours(alohaData, toastData, baseTheoMap, weekStartDate) {
  const DAYS_OF_WEEK = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  
  // Create a map of day names to dates for the selected week
  const weekDates = DAYS_OF_WEEK.reduce((acc, day, index) => {
    const date = new Date(weekStartDate)
    date.setDate(date.getDate() + index)
    acc[day] = date.toISOString().split('T')[0] // 'YYYY-MM-DD' format
    return acc
  }, {})

  return alohaData.map(areaData => {
    const areaName = areaData.area
    const toastArea = toastData[areaName]
    const sectionData = areaData.data
    const NUM_DATA_ROWS = 11

    // Process each store section
    for (let i = 0; i < sectionData.length - NUM_DATA_ROWS; i += NUM_DATA_ROWS) {
      const storeName = sectionData[i].day
      const storeTheoHours = baseTheoMap[storeName]
      const toastStore = toastArea ? toastArea[storeName] : null

      if (!storeTheoHours) {
        console.warn(`No theoretical hours found for store: ${storeName}`)
        continue
      }

      // Initialize weekly totals
      const weekTotals = {
        adjTheoFOH: 0,
        adjTheoBOH: 0
      }

      // Process each day's data for the store
      DAYS_OF_WEEK.forEach((day, dayIndex) => {
        const rowIndex = i + dayIndex + 1
        const dayRow = sectionData[rowIndex]
        const dateStr = weekDates[day]
        
        // Get base theoretical hours for the day
        const fohBaseTheoHrs = parseFloat(storeTheoHours.foh[day]) || 0
        const bohBaseTheoHrs = parseFloat(storeTheoHours.boh[day]) || 0

        // Get actual sales from Toast data if available
        let actSales = 0
        if (toastStore && toastStore[dateStr]) {
          actSales = parseFloat(toastStore[dateStr].netSales) || 0
        } else {
          // Fallback to Aloha data if no Toast data available
          actSales = parseFloat((dayRow.actSales || '').replace(/[$,\s]/g, '')) || 0
        }

        // Calculate variable hours based on sales
        const isWeekday = WEEKDAY_DAYS.includes(day)
        const fohVarHrs = actSales / (isWeekday ? FOH_WEEKDAY_DENOMINATOR : FOH_WEEKEND_DENOMINATOR)
        const bohVarHrs = actSales / (isWeekday ? BOH_WEEKDAY_DENOMINATOR : BOH_WEEKEND_DENOMINATOR)

        // Calculate adjusted theoretical hours
        const adjTheoFOH = fohBaseTheoHrs + fohVarHrs
        const adjTheoBOH = bohBaseTheoHrs + bohVarHrs

        // Update the row with calculated values
        sectionData[rowIndex].adjTheoFOH = parseFloat(adjTheoFOH.toFixed(2))
        sectionData[rowIndex].adjTheoBOH = parseFloat(adjTheoBOH.toFixed(2))

        // Accumulate weekly totals
        weekTotals.adjTheoFOH += adjTheoFOH
        weekTotals.adjTheoBOH += adjTheoBOH
      })

      // Update totals row
      const totalRowIndex = i + 8
      sectionData[totalRowIndex].adjTheoFOH = parseFloat(weekTotals.adjTheoFOH.toFixed(2))
      sectionData[totalRowIndex].adjTheoBOH = parseFloat(weekTotals.adjTheoBOH.toFixed(2))
    }

    return areaData
  })
}