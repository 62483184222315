export default function THAssignmentCellStyle(row, cell){
  
  const value = row.original
  const column = cell.column.id

  let setting = {}

  let bgColor = '#fff'
  let borderLeft = null
  let borderTop = null
  let fontSize = '.6rem'
  let fontWeight = 'normal'
  let textAlign = 'center'
  let textColor = '#000'
  let textIndent = 0
  let padding = '0px 10px'

  
  if(value.store === value.area){
    bgColor = '#8643B0'
    textColor = '#fff'
    fontWeight = 'bold'
  } else if(row.index % 2 == 0){
    bgColor = 'rgba(255, 204, 255, 0.3)'
  }

  switch(column){
    case 'netSalesName':
    case 'avgCheckName':
    case 'splhTotalName':
    case 'splhBOHName':
    case 'splhFOHName':
    case 'edit':
      borderLeft = '1px solid #000'
      break;
    default:
      // no action
  }

  setting = {
    backgroundColor: bgColor,
    borderLeft: borderLeft,
    borderTop: borderTop,
    color: textColor,
    fontSize: fontSize,
    fontWeight: fontWeight,
    padding: padding,
    textAlign: textAlign,
    textIndent: textIndent,
    width: 200
  }

  return setting
}