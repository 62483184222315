import React from 'react'
import PropTypes from "prop-types";
import { TextField } from '@mui/material'
import { useField } from 'formik'

const TextFieldWrapper = ({
  name,
  ...otherProps
}) => {

  const [field, meta] = useField(name)

  const configTextField = {
    ...field,
    ...otherProps,
    fullWidth: true,
    size: 'small',
    variant: 'outlined'
  }

  if(meta && meta.touched && meta.error){
    configTextField.error = true
    configTextField.helperText = meta.error
  }

  return (
    <TextField {...configTextField} InputLabelProps={{ shrink: field.filled }}  />
  )
}

export default TextFieldWrapper

TextFieldWrapper.propTypes = {
  name: PropTypes.string,
}