import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// @mui material components
import { Divider, Grid, Stack, ThemeProvider } from '@mui/material'

import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDDatePicker from 'components/MDDatePicker'
import MDTypography from 'components/MDTypography'

import { format, parseISO, subDays, startOfWeek } from 'date-fns'

// RadixDash components
import Button_GrmDark from 'components/RDButton/Button_GrmDark'
import Button_DemDark from 'components/RDButton/Button_DemDark'
import DayParts from 'clients/dem/components/DayParts'
import DollarPercentSwitch from 'clients/dem/components/DollarPercentSwitch'
import GoalPlannerControl from 'clients/dem/views/R0001/GoalPlannerControl'
import StoreSelector from './StoreSelector'
import SwitchDemDark from 'components/RDSwitch/SwitchDemDark'


function R0001Config(props){
  // console.log('R0001Config props', props)

  // const [areas, setAreas] = useState([])
  const [storeData, setStoreData] = useState(props.storeData)
  const [stores, setStores] = useState([''])
  /********************************************************* 
   * REPORT CONFIGURATION STATES
   *   Used to hold component values until the "Update Report"
   *   button is clicked
   *********************************************************/
  // Date Range
  const [dateRange, setDateRange] = useState(props.dateRange)
  const minDate = new Date(2022, 0, 1)
  // Store Selection

/********************************************************* 
   * REPORT CONFIGURATION HANDLING
   *********************************************************/
  function handleDateChange(range){
    // console.log('handleDateChange', range)
    const startDate = format(range[0], 'yyyy-MM-dd')
    const endDate = format(range[1], 'yyyy-MM-dd')
    setDateRange([startDate, endDate])
  }

  useEffect(() => {
    const propsDateRangeStr = JSON.stringify(props.dateRange)
    const localDateRangeStr = JSON.stringify(dateRange)
    if(propsDateRangeStr === localDateRangeStr){
      console.log('equal')
    } else {
      console.log('not equal')
    }
  }, [])

  useEffect(() => {
    // console.log('props.dateRange', props.dateRange)
    setDateRange(props.dateRange)
  }, [props.dateRange])
  
  useEffect(() => {
    setStoreData(props.storeData)
  }, [props.storeData])

  useEffect(() => {
    console.log('storeData', storeData)
    if(storeData.length > 0){
      let storeArr = []
              
      storeData.forEach( (x) => {
        const { stores } = x
        stores.forEach( (store) => {
          // console.log('store', store)
          storeArr.push(store.shortName)
        })
      })
      storeArr.sort()
      setStores(storeArr)
      
    }

  }, [storeData])
  
  function handleUpdateButton(){
    console.log('handleUpdateButton')
    props.updateReport()
  }

  let allStoresFontWeight
  let textColor
  if(props.enableSelectStores === true){
    allStoresFontWeight = null
    textColor = '#b9ad9c'
  } else {
    allStoresFontWeight = 'bold'
  }
  
  return (
    
    <MDBox pt={0.5} pb={3} px={3}>
      <Stack direction='column' spacing={1} alignItems='center'>
          <MDTypography variant='h6'>
            Report Period
          </MDTypography>
          <MDDatePicker
            id='dateRange'
            value={ props.dateRange }
            options={{
              mode: 'range',
              maxDate: props.maxDate,
              'locale': {
                'firstDayOfWeek': 1
              },
              minDate: minDate, 'locale': {
                'firstDayOfWeek': 1
              }
            }}
            onClose={ range => props.dateChange(range)}
            fullWidth
            input={{ placeholder: "Select reporting period"}}
            sx={{ 
              '&.flatpickr-day.today': {
                background: 'red'
              }
            }}
          />
          <Stack 
            direction='row'
            spacing={0}
            alignItems='center'
            justifyContent='center'
          >
            <MDTypography
              variant='button'
              sx={{
                fontWeight: allStoresFontWeight,
                color: textColor
              }}
            >
              All Stores
            </MDTypography>
            <SwitchDemDark
              onChange={ props.handleStoreSelectSwitch }
            />
            <MDButton
              size='small'
              variant='text'
              onClick={ props.selectStores }
              disabled={ !props.enableSelectStores }
              sx={{
                color: '#0091d5',
                border: '1px solid #0091d5',
                fontSize: '.7rem',
                '&: focus:not(:hover)': {
                  color: '#0091d5'
                },
                '&: hover': {
                  backgroundColor: '#0091d5',
                  color: '#f1f1f1',
                  border: '1px #1c4e80 solid'
                },
                '&: disabled': {
                  backgroundColor: '#b9ad9c',
                  color: '#29261f',
                  border: 'none'
                }
              }}
            >
              Select Stores
            </MDButton>
          </Stack>
          <Button_DemDark onClick={ () => handleUpdateButton() }>
            Update Report
          </Button_DemDark>
      </Stack>
      <Divider sx={{ backgroundColor: '#a44c0e'}}/>
      <Stack direction='column' alignItems='center' spacing={2}>
        <MDTypography variant='h6'>
          Report Filters
        </MDTypography>
        <DayParts
          shifts={ props.shifts }
          shiftChange={ props.shiftChange }
          active={ props.shiftStatus }
        />
        <StoreSelector
          onChange={ props.storeChange }
          active={ props.r0001Ready }
          storeArr={ props.stores }
          storeData={ storeData }
        />
        <DollarPercentSwitch
          title='Net Sales'
          value={ props.netSalesSwitchSetting }
          handler={ (event) => props.netSalesChange(event) }
          active={ props.r0001Ready }
        />
        <DollarPercentSwitch
          title='Comp / Void / Promo'
          value={ props.cvpSwitchSetting }
          handler={ (event) => props.cvpChange(event) }
          active={ props.r0001Ready }
        />
      </Stack>
      
      <Divider sx={{ backgroundColor: '#a44c0e'}} />
      <Stack direction='column' alignItems='center'>
        <MDTypography variant='h6' mb={2}>
          Goal Planner
        </MDTypography>
        <GoalPlannerControl 
          button={ props.setGoalsButton}
          open={ props.gpOpen }
          switch={ props.gpSwitch }
          disableButton={ props.disableGPButton }
          active={ props.r0001Ready }
        />
      </Stack>
    </MDBox>
  )

}

R0001Config.propTypes = {
  cvpChange: PropTypes.func,
  cvpSwitchSetting: PropTypes.bool,
  dateChange: PropTypes.func,
  dateRange: PropTypes.array,
  disableGPButton: PropTypes.bool,
  enableSelectStores: PropTypes.bool,
  selectStores: PropTypes.func,
  gpOpen: PropTypes.bool,
  gpSwitch: PropTypes.func,
  handleStoreSelectSwitch: PropTypes.func,
  maxDate: PropTypes.instanceOf(Date),
  netSalesChange: PropTypes.func,
  netSalesSwitchSetting: PropTypes.bool,
  r0001Ready: PropTypes.bool,
  setGoalsButton: PropTypes.func,
  shiftChange: PropTypes.func,
  shifts: PropTypes.object,
  shiftStatus: PropTypes.object,
  storeChange: PropTypes.func,
  storeData: PropTypes.array,
  stores: PropTypes.array,
  updateReport: PropTypes.func,
}

export default R0001Config