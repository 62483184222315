import React from 'react';

// @mui components
import { Checkbox, FormControlLabel, Grid } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

// Material Dashboard 2 React Components
import MDBox from 'components/MDBox'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

export default function CompStoreSelector({
  allStores,
  selectedStores,
  setSelectedStores,
}) {

  console.log('allStores', allStores)
  console.log('selectedStores', selectedStores)

  function handleChange(storeId, storeName, checked){
    if(checked){
      // add store to selectedStores array
      setSelectedStores(prev => [...prev, {storeId: storeId, shortName: storeName}])
    } else {
      // remove store from selectedStores array
      setSelectedStores(prev => prev.filter(s => s.storeId !== storeId))
    }
  }

  function storeExists(id){
    return selectedStores.some(store => store.storeId === id)
  }

  return (
    <MDBox
      color='black'
      borderRadius='lg'
      p={2}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          {
            allStores.slice( 
              0, 
              Math.ceil(allStores.length / 3)
            ).map(store => {
              const checked = storeExists(store.storeId)
              return (
                <ListItem 
                  key={store.storeId}
                  checked={checked}
                  fieldId={store.storeId}
                  fieldName={store.shortName}
                  setFieldValue={ handleChange }
                />
              )
            })
          }
        </Grid>
        <Grid item xs={4}>
          {allStores.slice(Math.ceil(allStores.length / 3), Math.ceil(allStores.length * 2 / 3)).map(store => {
              const checked = storeExists(store.storeId)
              return (
                <ListItem 
                  key={store.storeId}
                  checked={checked}
                  fieldId={store.storeId}
                  fieldName={store.shortName}
                  setFieldValue={ handleChange }
                />
              )
            })
          }
        </Grid>
        <Grid item xs={4}>
          {allStores.slice(Math.ceil(allStores.length * 2 / 3)).map(store => {
              const checked = storeExists(store.storeId)
              return (
                <ListItem 
                  key={store.storeId}
                  checked={checked}
                  fieldId={store.storeId}
                  fieldName={store.shortName}
                  setFieldValue={ handleChange }
                />
              )
            })
          }
        </Grid>
      </Grid>
    </MDBox>
  )
}

function ListItem({
  checked,
  fieldId,
  fieldName,
  setFieldValue,
}) {

  const handleChange = (e) => {
    setFieldValue(fieldId, fieldName, e.target.checked)
  }
  return (
    <FormControlLabel
      key={ fieldId}
      control={
        <Checkbox
          name={ fieldId }
          icon={ icon }
          checkedIcon={ checkedIcon }
          style={{
            marginRight: 8
          }}
          checked={ checked }
          onChange={ handleChange }
        />
      }
      label={ fieldName }
      style={{ minWidth: 120 }}
    />
  )
}
