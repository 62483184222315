import { useEffect, useState } from 'react'

import { Accordion, AccordionDetails, AccordionSummary, Box, Button, IconButton, Stack, Typography } from '@mui/material'

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import RuleBoxRow from './ThresholdProfiles/RuleBoxRow'
import deleteProfile from './ThresholdProfiles/deleteProfile';

export default function R0005Profile({
  profile,
  onEdit,
  closeEdit,
}){

  const [expand, setExpand] = useState(false)

  useEffect(() => {
    console.log('expand', expand)
  }, [expand])

  function handleDelete(profileToDelete){
    
    deleteProfile(profileToDelete.id)
  }

  const handleEditClick = () => {
    
    onEdit(profile.id)
  }

  return (
    <Box>
      <Accordion
        expanded={expand}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon 
              style={{ cursor: 'pointer'}}
              onClick={() => setExpand(!expand)}
            />
          }
          sx={{
            cursor: 'unset !important',
            py: 0,
            bgcolor: '#e0e0e0',
            height: 50
          }}
          aria-controls='panel1a-content'
          id='profile-header'
        >
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
            sx={{
              width: '95%'
            }}
          >
            <Stack  
              direction='column'
              spacing={0}
            >
              <Typography 
                variant='caption'
                fontStyle='italic'
              >
                Profile
              </Typography>
              <Typography 
                variant='h6'
              >
                {profile.name}
              </Typography>
            </Stack>
            { expand &&
              <Box>
                <IconButton
                  size='small'
                  onClick={(event) => {
                    event.stopPropagation()
                    handleEditClick()
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  size='small'
                  onClick={(event) => {
                    event.stopPropagation()
                    handleDelete(profile)
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            }
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          {
            profile.thresholdBands ? (
              profile.thresholdBands.map(rule => (
              <RuleBoxRow 
                rule={rule}
                viewOnly
              />
              ))
            ): (
              <Box

              >
                <Typography variant='subtitle2'>
                  No Rules Defined
                </Typography>
              </Box>
            )
          }
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}