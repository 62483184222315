import React from 'react'
import PropTypes from "prop-types";

// @mui components
import { Dialog, DialogTitle, DialogContent, Grid } from '@mui/material'

// formik components
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

// Radix Components
import GrmDarkButton from 'clients/grm/components/GrmDarkButton'
import SelectGrm from 'components/FormsUI/Select/SelectGrm'



export default function R0003ProjectionSettings(props){

  const INITIAL_FORM_STATE = {
    week: 0,
    store: 0
  }

  const FORM_VALIDATION = Yup.object().shape({
    week: Yup.string()
      .required('Required'),
    store: Yup.string()
      .required('Required')
  })

  function handleSubmit(values){
    props.close(values)
  }

  return (
    <Dialog
      open={ props.open }
    >
      <DialogTitle color='#29261f'>
        Select Week & Store
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={INITIAL_FORM_STATE}
          validationSchema={FORM_VALIDATION}
          enableReinitialize
          onSubmit={ (values) => { handleSubmit(values) }}
        >
          {({ isValid }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={6} mt={1}>
                  <SelectGrm 
                    name='week'
                    label='Week Starting'
                    options={ props.weeks }
                    height='20px'
                  />
                </Grid>
                <Grid item xs={6} mt={1}>
                  <SelectGrm 
                    name='store'
                    label='Store'
                    options={ props.stores }
                    height='20px'
                  />
                </Grid>
                
                <Grid item xs={6}></Grid>
                <Grid item xs={3} >
                  <GrmDarkButton
                    variant='contained'
                    onClick={ props.cancel }
                    fullWidth
                  >
                    Cancel
                  </GrmDarkButton>
                </Grid>
                <Grid item xs={3}>
                  <GrmDarkButton
                    variant='contained'
                    type='submit'
                    disabled={ !(isValid && props.stores[0] !== 'Fetching stores...' && props.stores[0] !== 'Error: Unable to Load Stores') }
                    fullWidth
                  >
                    Ok
                  </GrmDarkButton>
                </Grid>
                
              </Grid>
            </Form>
          )}
        </Formik>  
      </DialogContent>
    </Dialog>
  )
}

R0003ProjectionSettings.propTypes = {
  cancel: PropTypes.func,
  close: PropTypes.func,
  open: PropTypes.bool,
  stores: PropTypes.array,
  weeks: PropTypes.array
}