import { AGGREGATION_TYPES, METRIC_TYPES } from '../../../../constants';

export const overtimeMetrics = {
  cOTTotal: { 
    type: AGGREGATION_TYPES.SUM,
    metricType: METRIC_TYPES.OVERTIME
  },
  cOTFOH: { 
    type: AGGREGATION_TYPES.SUM,
    metricType: METRIC_TYPES.OVERTIME
  },
  cOTBOH: {  
    type: AGGREGATION_TYPES.SUM,
    metricType: METRIC_TYPES.OVERTIME
  },
}