import React from 'react'

import { Grid } from '@mui/material'

// Material Dashboard 2 Pro React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// RadixDash components
import RDConfigButton from 'components/RDConfigurator/RDConfigButton'
import RDCSVExport from 'components/RDCSVExport'

export default function RDReportHeader({
  controls,
  dataReady,
  handleConfiguratorOpen,
  style,
  title
}){
  return (
    <MDBox pt={3} pl={3} pb={2} sx={{
      backgroundColor: style.bgColor
    }}>
      <Grid
        container
        justify-content='space-between'
        direction='row'
      >
        <Grid item xs={7}>
          <MDTypography 
            variant='h5'
            fontWeight='medium'
            style={{
              color: style.textColor
            }}
          >
            {title}
          </MDTypography>
        </Grid>
        <Grid 
          container
          item 
          xs={5} 
          justify-content='flex-end'
          direction='row-reverse'
        >
          {/* Render dynamic controls */}
          { controls.map((control, index) => (
            <Grid item key={index} pl={2} pr={2}>
              { (dataReady && (control.type === 'csvButton')) && (
                <RDCSVExport 
                  data={control.data}
                  headers={control.columns}
                  fileName={title}
                  style={{
                    background: style.textColor,
                    backgroundHover: style.hoverColor,
                    color: style.bgColor
                  }}
                />
              )}
              { (dataReady && (control.type === 'configButton')) && (
                <RDConfigButton 
                  disabled={control.disabled}
                  style={{
                    bgColor: style.textColor,
                    hoverColor: style.hoverColor,
                    textColor: style.bgColor
                  }}
                  toggleConfigurator={control.onClick}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </MDBox>
  )
}