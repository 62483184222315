import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// RadixDash components
import Progress from 'components/Progress/Progress'
import RDTable from 'components/RDTable'
import RDStickyTable from 'components/RDTable/RDStickyTable';
import RDReportTable from 'components/RDTable/RDReportTable'
import { ReportHeader } from './components/ReportHeader'
import R0003AddNoteDialog from '../R0003AddNoteDialog'
import R0003CellStyle from '../R0003CellStyle'

// RadixDash hooks
import { useUser } from 'components/RDAuthContext'
import useAreas from 'hooks/clients/grm/useAreas'
import { useNotes } from './hooks/useNotes'
import { useR0003Data } from './hooks/useR0003Data'

// RadixDash helpers
import { getColumns } from './config/tableConfig'
import {
  filterData,
  generateWeeks,
  getItemSize
} from './utils/dataUtils'

import { HookStatus } from 'hooks/hookStatus'

export default function R0003Report(props){

  const { user: cognitoUser, permissions } = useUser()
  const user = cognitoUser.userName
  let role = cognitoUser.role || 'Support'
  
  const permissionIndex = permissions.findIndex(x => x.report === 'R0003')
  const R0003UpdatePermission = permissionIndex !== -1 && 
    permissions[permissionIndex].updatePermissions !== 'no-permission'

  const [weeks] = useState(() => generateWeeks(new Date(2021, 2, 22)))

  const [area, setArea] = useState('empty')
  const [areas, setAreas] = useState([])
  const [dataReady, setDataReady] = useState(false)
  const [rowSizes, setRowSizes] = useState([])
  const [tableData, setTableData] = useState([{ day: 'Data Loading' }])
  const [week, setWeek] = useState(weeks[2])
  
  const { storeIdMap, status } = useAreas()

  const { data: rawData, loading } = useR0003Data(
    week, 
    user, 
    role, 
    status === HookStatus.SUCCESS ? storeIdMap : null
  )

  const {
    notes,
    openAddNote,
    store,
    openAddNoteDialog,
    submitNote,
    closeDialog
  } = useNotes(tableData, setTableData)
  
  const columns = getColumns(openAddNoteDialog)
  const handleWeek = (event, newWeek) => setWeek(newWeek)
  const handleArea = (event, newArea) => setArea(newArea)
  
  useEffect(() => {
    // rawData changed, now set areas based on the available data
    if (rawData?.length > 0){
      setAreas(rawData.map(x => x.area))
    } else {
      setAreas([])
    }
  }, [rawData])

  useEffect(() => {
    if(rawData?.length > 0 && area !== ''){
      const filteredData = filterData(rawData, area)
      
      if(filteredData){
        const sizeArr = filteredData.data.map(row => getItemSize(row))
        setRowSizes(sizeArr)
        setTableData(filteredData.data)        
      }
    } 
  }, [area, rawData])

  useEffect(() => {
    if(areas?.length > 0){
      setArea(areas[0])
    } else {
      setArea('')
    }
  }, [areas])

  useEffect(() => {
    setDataReady(tableData[0].day !== 'Data Loading')
  }, [tableData])

  return (
    
    <MDBox textAlign='center'>
      <ReportHeader 
        title={`SPLH Report | Week of ${week}`}
        area={area}
        areas={areas}
        week={week}
        weeks={weeks}
        dataReady={dataReady}
        onAreaChange={handleArea}
        onWeekChange={handleWeek}
        hasUpdatePermission={R0003UpdatePermission}
        onMenuClick={() => props.setPage('actions')}
      />
      
      {/* Report Body */}
      <MDBox textAlign='left'>
        <R0003AddNoteDialog 
          open={ openAddNote }
          submit={ (values) => submitNote(values, week) }
          cancel={ closeDialog }
          store={ store }
          notes={ notes }
        />

        { loading ? (
          <Progress />
        ) : (
          <MDBox sx={{ backgroundColor: '#e0e0e0'}}>
            <RDReportTable 
              columns={ columns }
              data={ tableData }
              rowSizes={ rowSizes }
              sizeFunc={ getItemSize }
              styleFunc={ R0003CellStyle }
              cellOptions={{
                style: []
              }}
            />
          </MDBox>
        )}
      </MDBox>
    </MDBox>    
  )
}

R0003Report.propTypes = {
  setPage: PropTypes.func
}