import { fetchToastData } from './fetchToastData'
// import { fetchToastDataParallel } from './fetchToastDataParallel'
// import transformToastData from './transformToastData'
import transformToastData2 from './transformToastData2'

export default async function getToastData(startDate, endDate, storeMap, shiftString){

  console.log('** Fetch Toast Data', new Date())
  // const toastData = await fetchToastData(startDate, endDate)
  const toastData = await fetchToastData(startDate, endDate)
  console.log('Toast Data Fetched', new Date())
  console.log('toastData', toastData)

  const filteredToastData = toastData.filter(data => {
    for (const [key, store] of storeMap){
      if(store.storeRecordId === data.storeId) {
        const toastGoLiveDate = store.toastGoLiveDate

        if (toastGoLiveDate === null) {
          return false
        }
        return new Date(data.date) >= new Date(toastGoLiveDate)
      }
    }
    return false; // If no matching store is found, exclude the data
  })

  console.log('filteredToastData', filteredToastData)

  const metricsData = transformToastData2(filteredToastData, storeMap, shiftString)
  console.log('metricsData', metricsData)

  return metricsData
}