import { API } from 'aws-amplify'
import { updateThreshold } from 'graphql/mutations'

export default async function updateProfile(profileData){
  console.log('updateProfile', profileData)

  const thresholdBands = profileData.rules.map(band => {
    return {
      position: band.position,
      operator: band.operator,
      numberValue: band.numberValue,
      backgroundColor: band.backgroundColor,
      color: band.color,
      fontWeight: band.fontWeight
    }
  })

  const updateThresholdInput = {
    id: profileData.id,
    name: profileData.name,
    thresholdBands: thresholdBands
  }

  try {
    const response = await API.graphql({
      query: updateThreshold,
      variables: {
        input: updateThresholdInput
      }
    })
    console.log('response', response)
  } catch (err) {
    console.error('Error updating profile',err)
  }
}