import React, {useEffect, useState} from 'react'

// AWS Amplify components
import { API, Geo } from 'aws-amplify'

// GraphQL
import { dbAddItem, dbEditItem } from 'graphql/mutations'

import MDBox from 'components/MDBox'

// RadixDash components
import AddMember from './AddMember'
import EditMember from './EditMember'
import RDTable from 'components/RDTable'
import LocationCellStyle from './LocationCellStyle'
import { dbDeleteItem } from '../../../../graphql/mutations'

const columns = [
  {
    Header: 'Class ID',
    accessor: 'classId',
    align: 'center',
    width: 100,
    disableGroupBy: true,
    columnStyle: {
      backgroundColor: '#e0e0e0',
      color: '#181818',
      borderBottom: '1px solid #000',
      fontSize: '.7rem',
      fontWeight: 'bold',
      paddingTop: '5px'
    }
  },
  {
    Header: 'First Name',
    accessor: 'firstName',
    align: 'center',
    disableGroupBy: true,
    columnStyle: {
      backgroundColor: '#e0e0e0',
      color: '#181818',
      borderBottom: '1px solid #000',
      fontSize: '.7rem',
      fontWeight: 'bold',
      paddingTop: '5px'
    }
  },
  {
    Header: 'Middle Name',
    accessor: 'middleName',
    align: 'center',
    disableGroupBy: true,
    disableSortBy: true,
    columnStyle: {
      backgroundColor: '#e0e0e0',
      color: '#181818',
      borderBottom: '1px solid #000',
      fontSize: '.7rem',
      fontWeight: 'bold',
      paddingTop: '5px'
    }
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    align: 'center',
    disableGroupBy: true,
    columnStyle: {
      backgroundColor: '#e0e0e0',
      color: '#181818',
      borderBottom: '1px solid #000',
      fontSize: '.7rem',
      fontWeight: 'bold',
      paddingTop: '5px'
    }
  },
  {
    Header: 'Suffix',
    accessor: 'suffix',
    align: 'center',
    width: 20,
    disableGroupBy: true,
    disableSortBy: true,
    columnStyle: {
      backgroundColor: '#e0e0e0',
      color: '#181818',
      borderBottom: '1px solid #000',
      fontSize: '.7rem',
      fontWeight: 'bold',
      paddingTop: '5px'
    }
  },
  {
    Header: 'Location',
    accessor: 'location',
    align: 'center',
    width: 400,
    disableGroupBy: true,
    columnStyle: {
      backgroundColor: '#e0e0e0',
      color: '#181818',
      borderBottom: '1px solid #000',
      fontSize: '.7rem',
      fontWeight: 'bold',
      paddingTop: '5px'
    }
  },
  // {
  //   Header: 'Freshman Squadron',
  //   accessor: 'freshmanSquadron',
  //   align: 'center',
  //   columnStyle: {
  //     backgroundColor: '#e0e0e0',
  //     color: '#181818',
  //     borderBottom: '1px solid #000',
  //     fontSize: '.7rem',
  //     fontWeight: 'bold',
  //     paddingTop: '5px'
  //   }
  // },
  // {
  //   Header: 'Graduation Squadron',
  //   accessor: 'graduationSquadron',
  //   align: 'center',
  //   columnStyle: {
  //     backgroundColor: '#e0e0e0',
  //     color: '#181818',
  //     borderBottom: '1px solid #000',
  //     fontSize: '.7rem',
  //     fontWeight: 'bold',
  //     paddingTop: '5px'
  //   }
  // },
]

export default function LocationTable(props){

  const [openAdd, setOpenAdd] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [locationData, setLocationData] = useState(null)
  const [rowToEdit, setRowToEdit] = useState(null)

  async function addMember(newMember){
    console.log('addMember()', newMember)

    let street = ''
    if(locationData.addressNumber){
      street = locationData.addressNumber + ' '
    }
    if(locationData.street){
      street = street + locationData.street
    }

    let fullMember = {
      firstName: newMember.firstName,
      middleName: newMember.middleName,
      lastName: newMember.lastName,
      classId: newMember.classId,
      freshmanSquadron: 0,
      graduationSquadron: 0,
      suffix: newMember.suffix,
      location: {
        street: street,
        country: locationData.country,
        latitude: locationData.geometry.point[1],
        longitude: locationData.geometry.point[0],
        municipality: locationData.municipality,
        postalCode: locationData.postalCode,
        region: locationData.region,
        label: locationData.label
      }
    }
    
    console.log('fullMember', fullMember)
    const fullMemberJSON = JSON.stringify(fullMember)
    console.log('fullMemberJSON', fullMemberJSON)

    console.log('locationData', locationData)

    // setOpenAdd(false)

    try {
      let result = await API.graphql(
        {
          query: dbAddItem,
          variables: {
            db: 'afa92.members',
            dbItem: fullMemberJSON
          }
        }
      )
      console.log('Successfully added new member', result)
      const parsedResult = JSON.parse(result.data.dbAddItem.statusItem)
      console.log('parsedResult', parsedResult)
      // Add new member to table and sort by Last Name
      let newMemberTableData = {
        _id: parsedResult._id,
        classId: parsedResult.classId,
        firstName: parsedResult.firstName,
        middleName: parsedResult.middleName,
        lastName: parsedResult.lastName,
        suffix: parsedResult.suffix,
        location: parsedResult.location.label,
        freshmanSquadron: parsedResult.freshmanSquadron,
        graduationSquadron: parsedResult.graduationSquadron,
        latitude: parsedResult.location.latitude,
        longitude: parsedResult.location.longitude,
        municipality: parsedResult.location.municipality,
        region: parsedResult.location.region,
        country: parsedResult.location.country,
        postalCode: parsedResult.location.postalCode
      }
      props.setData(newMemberTableData)
      setOpenAdd(false)
    } catch (err) {
      console.log('Error adding new member', err)
      setOpenAdd(false)
    }    
  }

  function openEditDialog(row){
    setRowToEdit(row)
  }

  useEffect(() => {
    if(rowToEdit){
      setOpenEdit(true)
    }
  }, [rowToEdit])

  async function modifyMember(values){
    console.log('modifyMember()', values)

    const index = values.index
    const newData = values.newData
    const oldData = values.oldData

    console.log('locationData', locationData)
    let street = ''
    if(locationData.addressNumber){
      street = locationData.addressNumber + ' '
    }
    if(locationData.street){
      street = street + locationData.street
    }

    let updatedMember = {
      firstName: newData.firstName,
      middleName: newData.middleName,
      lastName: newData.lastName,
      classId: newData.classId,
      freshmanSquadron: 0,
      graduationSquadron: 0,
      suffix: newData.suffix,
      location: {
        street: street,
        country: locationData.country,
        latitude: locationData.geometry.point[1],
        longitude: locationData.geometry.point[0],
        municipality: locationData.municipality,
        postalCode: locationData.postalCode,
        region: locationData.region,
        label: locationData.label
      }
    }

    const updatedMemberJSON = JSON.stringify(updatedMember)
    console.log('updatedMemberJSON', updatedMemberJSON)

    try {
      let result = await API.graphql(
        {
          query: dbEditItem,
          variables: {
            db: 'afa92.members',
            dbItemId: rowToEdit.original._id,
            dbItem: updatedMemberJSON
          }
        }
      )
      console.log('Successfully modified member', result)
      
      const parsedResult = JSON.parse(result.data.dbEditItem.statusItem)
      console.log('parsedResult', parsedResult)

      // Update data to table
      let updatedMemberTableData = {
        _id: parsedResult._id,
        classId: parsedResult.classId,
        firstName: parsedResult.firstName,
        middleName: parsedResult.middleName,
        lastName: parsedResult.lastName,
        suffix: parsedResult.suffix,
        location: parsedResult.location.label,
        freshmanSquadron: parsedResult.freshmanSquadron,
        graduationSquadron: parsedResult.graduationSquadron,
        latitude: parsedResult.location.latitude,
        longitude: parsedResult.location.longitude,
        municipality: parsedResult.location.municipality,
        region: parsedResult.location.region,
        country: parsedResult.location.country,
        postalCode: parsedResult.location.postalCode
      }
      console.log('setting data with updated info')
      props.updateData(updatedMemberTableData, index)
      setOpenEdit(false)
    } catch (err) {
      console.log('Error updating member', err)
      setOpenEdit(false)
    }
  }

  async function removeMember(row){
    console.log('removeMember()', row)
    const index = row.index
    const memberId = row.original._id
    console.log('memberId', memberId)

    try {
      let result = await API.graphql(
        {
          query: dbDeleteItem,
          variables: {
            db: 'afa92.members',
            dbItemId: memberId
          }
        }
      )
      console.log('Successfully deleted Member from DB', result)

      // Remove member from table
      const dataDelete = [...props.data]
      console.log('dataDelete', dataDelete)
      dataDelete.splice(index, 1)
      console.log('dataDelete spliced', dataDelete)
      props.setTable(dataDelete)
    } catch (err) {
      console.log('Error deleting Member', err)
    }
  }

  // async function getGeoCodes(address){
  //   console.log('getGeoCodes for', address)
  //   let params = {
  //     IndexName: 'afa92placeindex',
  //     Text: address,
  //     Language: 'en',
  //     MaxResults: 5
  //   }

  //   try {
  //     let results = await Geo.searchByText(address)
  //     console.log('success', results)
  //     return results
  //   } catch (err) {
  //     console.log('error', err)
  //     return `Error getting geoCodes ${err}`
  //   }
  // }

  
  return (
    <MDBox>
      { openAdd &&
        <AddMember 
          open={ openAdd }
          close={ () => setOpenAdd(false) }
          submit={ addMember }
          setLocationData={ (x) => setLocationData(x) }
        />
      }
      {
        openEdit &&
        <EditMember 
          open={ openEdit }
          data={ rowToEdit }
          close={ () => setOpenEdit(false) }
          submit={ modifyMember }
          setLocationData={ (x) => setLocationData(x) }
          locationData={ locationData }
        />
      }
      <RDTable 
        table={{
          columns: columns,
          rows: props.data,
        }}
        addRowButton='Add Member'
        editable={{
          onRowAdd: () => setOpenAdd(true),
          onRowEdit: row => openEditDialog(row),
          onRowDelete: row => removeMember(row),
        }}
        canSearch
        cellStyle={ LocationCellStyle }
      />
    </MDBox>
  )
}