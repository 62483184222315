/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateClient = /* GraphQL */ `
  subscription OnCreateClient($filter: ModelSubscriptionClientFilterInput) {
    onCreateClient(filter: $filter) {
      id
      clientId
      clientName
      contactName
      contactPhone
      contactEmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateClient = /* GraphQL */ `
  subscription OnUpdateClient($filter: ModelSubscriptionClientFilterInput) {
    onUpdateClient(filter: $filter) {
      id
      clientId
      clientName
      contactName
      contactPhone
      contactEmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteClient = /* GraphQL */ `
  subscription OnDeleteClient($filter: ModelSubscriptionClientFilterInput) {
    onDeleteClient(filter: $filter) {
      id
      clientId
      clientName
      contactName
      contactPhone
      contactEmail
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateForecastedSales = /* GraphQL */ `
  subscription OnCreateForecastedSales(
    $filter: ModelSubscriptionForecastedSalesFilterInput
  ) {
    onCreateForecastedSales(filter: $filter) {
      id
      storeName
      date
      forecastedSales
      storeId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateForecastedSales = /* GraphQL */ `
  subscription OnUpdateForecastedSales(
    $filter: ModelSubscriptionForecastedSalesFilterInput
  ) {
    onUpdateForecastedSales(filter: $filter) {
      id
      storeName
      date
      forecastedSales
      storeId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteForecastedSales = /* GraphQL */ `
  subscription OnDeleteForecastedSales(
    $filter: ModelSubscriptionForecastedSalesFilterInput
  ) {
    onDeleteForecastedSales(filter: $filter) {
      id
      storeName
      date
      forecastedSales
      storeId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateStoreRecord = /* GraphQL */ `
  subscription OnCreateStoreRecord(
    $filter: ModelSubscriptionStoreRecordFilterInput
  ) {
    onCreateStoreRecord(filter: $filter) {
      id
      storeId
      storeName
      clientId
      toastId
      timeZone
      address {
        street_1
        street_2
        city
        state
        zipCode
        country
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateStoreRecord = /* GraphQL */ `
  subscription OnUpdateStoreRecord(
    $filter: ModelSubscriptionStoreRecordFilterInput
  ) {
    onUpdateStoreRecord(filter: $filter) {
      id
      storeId
      storeName
      clientId
      toastId
      timeZone
      address {
        street_1
        street_2
        city
        state
        zipCode
        country
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteStoreRecord = /* GraphQL */ `
  subscription OnDeleteStoreRecord(
    $filter: ModelSubscriptionStoreRecordFilterInput
  ) {
    onDeleteStoreRecord(filter: $filter) {
      id
      storeId
      storeName
      clientId
      toastId
      timeZone
      address {
        street_1
        street_2
        city
        state
        zipCode
        country
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      id
      client
      userName
      firstName
      lastName
      email
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      id
      client
      userName
      firstName
      lastName
      email
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      id
      client
      userName
      firstName
      lastName
      email
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateMetricData = /* GraphQL */ `
  subscription OnCreateMetricData(
    $filter: ModelSubscriptionMetricDataFilterInput
  ) {
    onCreateMetricData(filter: $filter) {
      metricId
      clientId
      storeId
      date
      hour
      frequency
      metricName
      metricType
      numberValue
      stringValue
      booleanValue
      dayPart
      diningOption
      source
      timestamp
      unit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMetricData = /* GraphQL */ `
  subscription OnUpdateMetricData(
    $filter: ModelSubscriptionMetricDataFilterInput
  ) {
    onUpdateMetricData(filter: $filter) {
      metricId
      clientId
      storeId
      date
      hour
      frequency
      metricName
      metricType
      numberValue
      stringValue
      booleanValue
      dayPart
      diningOption
      source
      timestamp
      unit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMetricData = /* GraphQL */ `
  subscription OnDeleteMetricData(
    $filter: ModelSubscriptionMetricDataFilterInput
  ) {
    onDeleteMetricData(filter: $filter) {
      metricId
      clientId
      storeId
      date
      hour
      frequency
      metricName
      metricType
      numberValue
      stringValue
      booleanValue
      dayPart
      diningOption
      source
      timestamp
      unit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateThreshold = /* GraphQL */ `
  subscription OnCreateThreshold(
    $filter: ModelSubscriptionThresholdFilterInput
  ) {
    onCreateThreshold(filter: $filter) {
      id
      clientId
      name
      entityType
      entityId
      metric
      thresholdType
      thresholdBands {
        position
        operator
        numberValue
        textValue
        backgroundColor
        color
        fontWeight
        fontStyle
        borderColor
        borderWidth
        icon
        text
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateThreshold = /* GraphQL */ `
  subscription OnUpdateThreshold(
    $filter: ModelSubscriptionThresholdFilterInput
  ) {
    onUpdateThreshold(filter: $filter) {
      id
      clientId
      name
      entityType
      entityId
      metric
      thresholdType
      thresholdBands {
        position
        operator
        numberValue
        textValue
        backgroundColor
        color
        fontWeight
        fontStyle
        borderColor
        borderWidth
        icon
        text
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteThreshold = /* GraphQL */ `
  subscription OnDeleteThreshold(
    $filter: ModelSubscriptionThresholdFilterInput
  ) {
    onDeleteThreshold(filter: $filter) {
      id
      clientId
      name
      entityType
      entityId
      metric
      thresholdType
      thresholdBands {
        position
        operator
        numberValue
        textValue
        backgroundColor
        color
        fontWeight
        fontStyle
        borderColor
        borderWidth
        icon
        text
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateThresholdMapping = /* GraphQL */ `
  subscription OnCreateThresholdMapping(
    $filter: ModelSubscriptionThresholdMappingFilterInput
  ) {
    onCreateThresholdMapping(filter: $filter) {
      id
      entityId
      extEntityId
      thresholdId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateThresholdMapping = /* GraphQL */ `
  subscription OnUpdateThresholdMapping(
    $filter: ModelSubscriptionThresholdMappingFilterInput
  ) {
    onUpdateThresholdMapping(filter: $filter) {
      id
      entityId
      extEntityId
      thresholdId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteThresholdMapping = /* GraphQL */ `
  subscription OnDeleteThresholdMapping(
    $filter: ModelSubscriptionThresholdMappingFilterInput
  ) {
    onDeleteThresholdMapping(filter: $filter) {
      id
      entityId
      extEntityId
      thresholdId
      createdAt
      updatedAt
      __typename
    }
  }
`;
