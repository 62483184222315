import {addDays, endOfWeek, format, lastDayOfWeek, parse, startOfWeek} from 'date-fns';

export const csvColumns = [
  {label: 'Store', key: 'store'}
]

export const minDate = new Date(2019, 0, 1)
export const maxDate = getMaxDate()

export const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export function getAreas(data){
  const areaSet = new Set()
  if(data !== null){
    data.forEach(item => areaSet.add(item.area))
  }
  
  return [...areaSet]
}

export function getColumns(dateStr) {
  const date = parse(dateStr, 'yyyy-MM-dd', new Date()) 

  const headerStyleDark = {
    fontSize: '.7rem', 
    color: '#181818',
    backgroundColor: '#bdbdbd',
    borderLeft: '1px solid black',
    borderBottom: '1px solid black'
  };
  const headerStyleLight = {
    fontSize: '.7rem', 
    color: '#181818',
    backgroundColor: '#e0e0e0',
    borderLeft: '1px solid black',
    borderBottom: '1px solid black'
  };

  const darkIndex = [0, 2, 4, 6]

  const headerFontSize = '.9rem'
  const bodyFontSize = '.7rem'
  const storeWidth = 122
  const dayWidth = 67
  const totalWidth = 70
  
  const columns = [
    {
      header: 's',
      headerStyle: {
        fontSize: headerFontSize,
        backgroundColor: '#e0e0e0',
        color: '#e0e0e0',
      },
      columns: [
        {
          header: 'Store',
          align: 'center',
          headerStyle: {
            paddingLeft: 0,
            fontSize: headerFontSize,
            backgroundColor: '#e0e0e0',
            borderBottom: '1px solid black'
          },
          accessorKey: 'store',
          colSpan: 1,
          cellType: 'standard',
          cellProps: {
            align: 'left',
            currency: false,
            fontSize: bodyFontSize,
            width: storeWidth,
            // borderBottom: '1px dashed grey',
          },
        }
      ]
    }
  ];

  for (let i = 0; i < 7; i++) {
    const nextDate = addDays(date, i)

    const headerDate = format(nextDate, 'M/d')

    columns.push({
      header: headerDate,
      headerStyle: darkIndex.includes(i) ? {...headerStyleDark, borderLeft: '2px solid black'} : {...headerStyleLight, borderLeft: '2px solid black'},
      colSpan: 2,
      columns: [
        { 
          header: 'ACT',
          headerStyle: darkIndex.includes(i) ? {...headerStyleDark, borderLeft: '2px solid black'} : {...headerStyleLight, borderLeft: '2px solid black'},
          accessorKey: `act${i+1}`,
          cellType: 'standard',
          cellProps: {
            align: 'center',
            currency: true,
            fontSize: bodyFontSize,
            width: dayWidth,
            borderLeft: '2px solid black',
            // borderBottom: '1px dashed grey',
          }
        },
        {
          header: 'FCST',
          headerStyle: darkIndex.includes(i) ? headerStyleDark : headerStyleLight,
          accessorKey: `fcst${i+1}`,
          cellType: 'conditional',
          cellProps: {
            align: 'center',
            currency: true,
            fontSize: bodyFontSize,
            width: dayWidth,
            borderLeft: '1px solid black',
            // borderBottom:  '1px dashed grey',
          }
        }
      ]
    });
  }

  columns.push({
    header: 'Total',
    headerStyle: {...headerStyleLight, borderLeft: '2px solid black'},
    colSpan: 2,
    columns: [
      {
        header: 'ACT',
        headerStyle: {...headerStyleLight, borderLeft: '2px solid black'},
        accessorKey: 'actTotal',
        aggregate: 'sum',
        cellType: 'standard',
        cellProps: {
          align: 'center',
          currency: true,
          fontSize: bodyFontSize,
          fontWeight: 'bold',
          width: totalWidth,
          borderLeft: '2px solid black',
          // borderBottom: '1px dashed grey',
        }
      },
      {
        header: 'FCST',
        headerStyle: headerStyleLight,
        accessorKey: 'fcstTotal',
        aggregate: 'sum',
        cellType: 'conditional',
        cellProps: {
          align: 'center',
          currency: true,
          fontSize: bodyFontSize,
          fontWeight: 'bold',
          width: totalWidth,
          // borderBottom: '1px dashed grey',
        }
      }
    ]
  });

  return columns;
}

function getMaxDate(){
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const oneWeekFromToday = addDays(today, 7)
  const maxDate = lastDayOfWeek(oneWeekFromToday, {weekStartsOn: 1})
  return maxDate
}

export function getWeekRange(date){
  const inputDate = parse(date, 'yyyy-MM-dd', new Date())
  const start = startOfWeek(inputDate, {weekStartsOn: 1})
  const end = endOfWeek(inputDate, {weekStartsOn: 1})

  return {
    from: format(start, 'yyyy.MM.dd'),
    to: format(end, 'yyyy.MM.dd')
  }
}

export function transformData(data) {
  const dataCopy = [...data]
  // Sum actuals and forecasts for each area
  const areaTotals = {};
  
  dataCopy.forEach(item => {
    const itemCopy = {...item}
    if (!areaTotals[itemCopy.area]) {
      areaTotals[itemCopy.area] = {
        area: itemCopy.area,
        store: `${itemCopy.area} Totals`,
        act1: 0,
        fcst1: 0,
        act2: 0, 
        fcst2: 0,
        act3: 0,
        fcst3: 0,
        act4: 0,
        fcst4: 0,
        act5: 0,
        fcst5: 0,
        act6: 0, 
        fcst6: 0,
        act7: 0,
        fcst7: 0
      };
    }
    
    for(let i = 1; i <= 7; i++) {
      areaTotals[itemCopy.area][`act${i}`] += itemCopy[`act${i}`];
      areaTotals[itemCopy.area][`fcst${i}`] += itemCopy[`fcst${i}`];
    }
  });

  // Add area totals as new rows 
  Object.values(areaTotals).forEach(total => {
    dataCopy.push(total);
  });
  
  // Sum act and fcst for each store
  dataCopy.forEach(item => {
    item.actTotal = 0;
    item.fcstTotal = 0;
    
    for (let i = 1; i <= 7; i++) {
      item.actTotal += item[`act${i}`];
      item.fcstTotal += item[`fcst${i}`]; 
    }    
  });

  return dataCopy;
}

export const testData = [
  {
    area: 'Area 1',
    store: "Store 1", 
    act1: 9432, fcst1: 9205,
    act2: 9993, fcst2: 9678,
    act3: 10755, fcst3: 10351,
    act4: 8652, fcst4: 9073,
    act5: 10283, fcst5: 10092,
    act6: 11254, fcst6: 10842, 
    act7: 12234, fcst7: 11805
  },
  {
    area: 'Area 1',
    store: "Store 2",
    act1: 8532, fcst1: 8305, 
    act2: 9193, fcst2: 8878,
    act3: 9255, fcst3: 9051,
    act4: 8654, fcst4: 9773,
    act5: 10283, fcst5: 11092,
    act6: 13254, fcst6: 10842, 
    act7: 12234, fcst7: 9805  
  },  
  {
    area: 'Area 2',
    store: "Store 3", 
    act1: 9482, fcst1: 8205,
    act2: 9583, fcst2: 10678,
    act3: 10745, fcst3: 12351,
    act4: 8554, fcst4: 9874,
    act5: 13283, fcst5: 12392,
    act6: 15254, fcst6: 10542, 
    act7: 12844, fcst7: 10505
  },
  {
    area: 'Area 2',
    store: "Store 4",
    act1: 8332, fcst1: 8335, 
    act2: 9113, fcst2: 8888,
    act3: 9225, fcst3: 9001,
    act4: 8652, fcst4: 9773,
    act5: 10223, fcst5: 10992,
    act6: 11224, fcst6: 10442, 
    act7: 12334, fcst7: 11885  
  },  
]

