import PropTypes from 'prop-types'

// @mui material components
import { List } from '@mui/material'

// Material Dashboard 2 components
import MDBox from 'components/MDBox'

import ReportListItem from './ReportListItem'

export default function ReportList({
  setPage,
  userName
}){
  
  const pages = [
    {
      page: 'r0002',
      title: 'R0002: Summary Report'
    },
    // {
    //   page: 'r0003',
    //   title: 'R0003: SPLH Report'
    // },
    {
      page: 'r0005',
      title: 'R0005: Revenue Projection Report'
    },
  ]

  if(userName === 'devuser'){
    pages.push({
      page: 'r0003',
      title: 'R0003: SPLH Report'
    })
  }

  return (
    <MDBox component='div'>
      <MDBox
        textAlign='left'
        mx={6}
        my={2}
      >
        <List>
          {
            pages.map(p => (
              <ReportListItem 
                key={p.page}
                page={p.page}
                setPage={setPage}
                title={p.title}
              />
            ))
          }
        </List>  
      </MDBox>
    </MDBox>
  )
}

ReportList.propTypes = {
  setPage: PropTypes.func
}