import React from 'react'

import { Stack, Switch } from '@mui/material'

import MDTypography from 'components/MDTypography'

export default function RDSwitch({
  disabled,
  leftLabel,
  toggleSwitch,
  rightLabel,
  style,
  value
}){

  const handleChange = (event) => {
    toggleSwitch(event.target.checked)
  }

  return (
    <Stack
      direction='row'
      spacing={0}
      alignItems='center'
      justifyContent='center'
    >
      <MDTypography
        variant='button'
        sx={{
          fontWeight: value ? 'normal' : 'bold'
        }}
      >
        { leftLabel }
      </MDTypography>
      <Switch
        checked={ value }
        onChange={ handleChange }
        disabled={ disabled } 
        sx={{
          '& .MuiSwitch-thumb': {
            backgroundColor: style.activeColor,
          },
          '& .Mui-disabled' : {
            '& .MuiSwitch-thumb': {
              backgroundColor: style.disabledColor
            }
          }
        }}
      />
      <MDTypography
        variant='button'
        sx={{
          fontWeight: value ? 'bold' : 'normal'
        }}
      >
        { rightLabel }
      </MDTypography>
    </Stack>
  )
}