import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// AWS Amplify components
import { API } from 'aws-amplify'

// GraphQL
import { createRole, deleteRole, updateRole } from '../../../graphql/mutations'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDAlert from 'components/MDAlert'

// RadixDash components
import { useUser } from 'components/RDAuthContext'
import RDTable from 'components/RDTable'
import EditRoleDialog from './EditRoleDialog'
import AddRoleDialog from './AddRoleDialog'
import RolesCellStyle from './RolesCellStyle'

import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle';

const useStyles = makeStyles(styles)

export default function RolesTable(props){

  const classes = useStyles()

  const { user } = useUser()
  const clientId = user.client

  const [data, setData] = useState(props.roles)
  const [rowToEdit, setRowToEdit] = useState(null)
  const [openAdd, setOpenAdd] = useState(null)
  const [openEdit, setOpenEdit] = useState(false)
  const [alert, setAlert] = useState(null)
  const [alertMessage, setAlertMessage] = useState({
    type: 'none',
    message: ''
  })

  const columns = [
    { 
      Header: 'Role', 
      accessor: 'role', 
      width: '20%',
      align: 'center',
      disableGroupBy: true,
      disableSortBy: true,
      columnStyle: {
        backgroundColor: '#e0e0e0',
        color: '#181818',
        borderBottom: '1px solid #000',
        fontSize: '.7rem',
        fontWeight: 'bold',
        paddingTop: '5px'
      } 
    },
    { 
      Header: 'Description', 
      accessor: 'description',
      align: 'center',
      disableGroupBy: true,
      disableSortBy: true,
      columnStyle: {
        backgroundColor: '#e0e0e0',
        color: '#181818',
        borderBottom: '1px solid #000',
        fontSize: '.7rem',
        fontWeight: 'bold',
        paddingTop: '5px'
      } 
    }
  ]

  async function addRole(newRole) {
    console.log('addRole()...', newRole)

    try {
      let result = await API.graphql(
        {
          query: createRole,
          variables: {
            clientId: clientId,
            role: newRole.role,
            description: newRole.description
          }
        }
      )
      console.log('Successfully added new role to DB', result)

      // Add new role to existing users and sort by role
      let newData = [...data, newRole]
      newData.sort((a, b) => (a.role > b.role) ? 1 : -1)

      console.log('newData', newData)

      // Update the usertable with the new role
      setData([...newData])
      setOpenAdd(false)

      // Notify Admin that role creation was successful
      setAlertMessage({
        type: 'success',
        message: `${newRole.role} role has been created`
      })
      
    } catch (err) {
      console.log('Error creating role', err)
      setOpenAdd(false)
      // Notify Admin that role creation failed
      setAlertMessage({
        type: 'error',
        message: err.errors[0].message
      })
    }
  }

  async function modifyRole(values) {
    console.log('modifyRole...')
    console.log('data', data)
    const index = values.index
    const newData = values.newData
    const oldData = values.oldData

    try {
      let result = await API.graphql(
        {
          query: updateRole,
          variables: {
            clientId: clientId,
            role: oldData.role,
            newRole: newData.role,
            description: newData.description
          }
        }
      )
      console.log('Successfully updated role', result)

      // Update dataTable
      console.log('data', data)
      const dataUpdate = [...data]
      console.log('dataUpdate pre', dataUpdate)
      // const index = oldData.tableData.id
      dataUpdate[index] = newData
      console.log('dataUpdate new', dataUpdate)
      setData([...dataUpdate])
      setOpenEdit(false)

      // Notify Admin that role change was successful
      setAlertMessage({
        type: 'success',
        message: 'Role successfully updated'
      })

    } catch (err) {
      console.log('Error updating role', err.errors)
      setOpenEdit(false)
      // Notify Admin that role modification failed
      setAlertMessage({
        type: 'error',
        message: err.errors[0].message
      })
    }
  }

  async function removeRole(row) {
    console.log('removeRole()...')
    const index = row.index
    const role = row.values.role
    try {
      let result = await API.graphql(
        {
          query: deleteRole,
          variables: {
            clientId: clientId,
            role: role
          }
        }
      )
      console.log('Successfully deleted Role from DB', result)

      // Remove Role from table
      const dataDelete = [...data]
      dataDelete.splice(index, 1)
      setData([...dataDelete])

      // Notify Admin that role deletion was successful
      setAlertMessage({
        type: 'success',
        message: `${role} successfuly deleted`
      })

    } catch (err) {
      console.log('Error deleting Role', err)
      // Notify Admin that role deletion failed
      setAlertMessage({
        type: 'error',
        message: err.errors[0].message
      })
    }
  }

  /************************************
   * ALERT Functions
   * **********************************/
  // Determine which alert to trigger
  useEffect(() => {
    console.log('alertMessage type:', alertMessage.type)
    switch(alertMessage.type){
      case 'error':
        errorAlert()
        break;
      case 'success':
        successAlert()
        break;
      default:
        // No alert triggered
    }
  }, [alertMessage.type])

  // Close the alert
  const hideAlert = () => {
    
    setAlert(null)
    setAlertMessage('')
  }

  const successAlert = () => {
    console.log('successAlert...')
    setAlert(
      <MDAlert color='success' dismissible>
        { alertMessage.message }
      </MDAlert>
    )
    setTimeout(function() {hideAlert()}, 3000)
  }

  const errorAlert = () => {
    console.log('errorAlert()')
    setAlert(
      <MDAlert color='error' dismissible>
        { alertMessage.message }
      </MDAlert>
    )
    setTimeout(function() {hideAlert()}, 3000)
  }
  
  function handleAddRow(){
    console.log('Add Row Button clicked')
    
  }

  function openEditDialog(row){
    console.log('Edit row clicked')
    setRowToEdit(row)   
  }

  
  useEffect(() => {
    if(rowToEdit){
      setOpenEdit(true)
    }
  }, [rowToEdit])

  const addRowButtonLabel = 'Add Role'


  return (
    <MDBox>
      { alert }
      { openEdit && 
        <EditRoleDialog
          open={ openEdit }
          data={ rowToEdit }
          close={ () => setOpenEdit(false) }
          submit={ modifyRole }
          existingRoles={ data.map( a => a.role ) }
        />
      }
      { openAdd &&
        <AddRoleDialog 
          open={ openAdd }
          close={ () => setOpenAdd(false) }
          submit={ addRole }
          existingRoles={ data.map( a => a.role ) }
        />
      }
      <RDTable 
        table={{
          columns: columns,
          rows: data
        }}
        entriesPerPage        
        showTotalEntries
        canSearch
        addRowButton='Add Role'
        editable={{
          onRowAdd: () => setOpenAdd(true),
          onRowEdit: row => openEditDialog(row),
          onRowDelete: row => removeRole(row)
        }}
        tableType='Role'
        cellStyle={ RolesCellStyle }
      />
      {/* <RDTable 
        table={{
          columns: columns,
          rows: data
        }}
        entriesPerPage        
        showTotalEntries
        canSearch
        addRowButton='Add Role'
        handleAdd={() => setOpenAdd(true) }
        editable
        handleEdit={row => openEditDialog(row)}
        handleDelete={ row => removeRole(row) }
      /> */}
      
      {/* <MaterialTable
        title={ ' '}
        data={ data }
        columns={ columns }
        editable={{
          onRowAdd: newData => addRole(newData),
          onRowUpdate: (newData, oldData) => modifyRole(newData, oldData),
          onRowDelete: oldData => removeRole(oldData)
        }}
        options={{
          pageSize: 10
        }}
      /> */}
    </MDBox>
  )

}

RolesTable.propTypes = {
  roles: PropTypes.array
}