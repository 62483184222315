import { AGGREGATION_TYPES, METRIC_TYPES } from '../../../../constants';
import { formatMoney } from '../../../../utils/formatters';

export const averageCheckMetrics = {
  cACTotal: { 
    type: AGGREGATION_TYPES.CALCULATED,
    metricType: METRIC_TYPES.AVERAGE_CHECK,
    inputs: ['cNSTotal', 'cCCTotal'],
    calculate: (ns, cc) => formatMoney((ns/cc), 2)
  },
  cACDineIn: {
    type: AGGREGATION_TYPES.CALCULATED,
    metricType: METRIC_TYPES.AVERAGE_CHECK,
    inputs: ['cNSDineIn', 'cCCDineIn'],
    calculate: (ns, cc) => formatMoney((ns/cc), 2)
  },
  cACToGo: {
    type: AGGREGATION_TYPES.CALCULATED,
    metricType: METRIC_TYPES.AVERAGE_CHECK,
    inputs: ['cNSToGo', 'cCCToGo'],
    calculate: (ns, cc) => formatMoney((ns/cc), 2)
  },
  cACDelivery: {
    type: AGGREGATION_TYPES.CALCULATED,
    metricType: METRIC_TYPES.AVERAGE_CHECK,
    inputs: ['cNSDelivery', 'cCCDelivery'],
    calculate: (ns, cc) => formatMoney((ns/cc), 2)
  }
}