import { useEffect, useState } from 'react'

import { CircularProgress, Stack, Typography } from '@mui/material'

import MDDatePicker from 'components/MDDatePicker'

import { CSVLink } from 'react-csv'

import Button_GrmDark from 'components/RDButton/Button_GrmDark'
import ValidationTable from './ValidationTable'

import { format, startOfDay, subDays } from 'date-fns'

import { subDaysFromStrDate } from 'utils/helperUtils'

import getCsvData from './getCsvData'

import useAreas from 'hooks/clients/grm/useAreas'

const initialDate = format(subDays(new Date(), 1), 'yyyy-MM-dd')

export default function CreatePredictor(){

  const { storeMap, status } = useAreas()

  const [endDate, setEndDate] = useState(initialDate)
  const [startDate, setStartDate] = useState(subDaysFromStrDate(initialDate, 730))
  const [csvData, setCsvData] = useState({
    filename: '',
    headers: [],
    data: []
  })
  const [validationData, setValidationData] = useState({
    filename: '',
    headers: [],
    data: []
  })
  const [processingData, setProcessingData] = useState(false)

  useEffect(() => {
    console.log('storeMap', storeMap)
    
  }, [storeMap])

  useEffect(() => {
    console.log('status', status)
  }, [status])

  useEffect(() => {
    console.log('csvData', csvData)
  }, [csvData])

  useEffect(() => {
    console.log('validationData', validationData)
  }, [validationData])

  useEffect(() => {
    console.log('endDate', endDate)
    setStartDate(subDaysFromStrDate(endDate, 730))
  }, [endDate])

  function handleDateChange(date){
    console.log('date', date)
    console.log('date', date[0])
    setEndDate(format(date[0], 'yyyy-MM-dd'))
  }

  function handleStartDateChange(date){
    console.log('date', date)
    setStartDate(format(date[0], 'yyyy-MM-dd'))
  }

  async function handleDownloadButton(){
    console.log('Download button clicked')
    setProcessingData(true)
    
    const csv = await getCsvData(startDate, endDate, storeMap)
    
    setProcessingData(false)
    console.log('csv', csv)
    
    setCsvData(csv.data)
    // setValidationData(csv.validation)
  }

  useEffect(() => {
    console.log('csvData', csvData)
  }, [csvData])

  return (
    <Stack 
        direction='column' 
        spacing={2}
        justifyContent='center'
        alignItems='center'
      >
        <Typography variant='h3'>
          Create New Predictor
        </Typography>
        <Stack
          direction='row'
          spacing={2} 
          justifyContent='space-between' 
          alignItems='center'
        >
          <Typography variant='body1'>
            Set Training End Date
          </Typography>
          <MDDatePicker 
            value={endDate}
            fullWidth={false}
            options={{
              weekNumbers: true 
            }}
            onChange={ date => handleDateChange(date)}
          />
        </Stack>
        <Stack
          direction='row'
          spacing={2} 
          justifyContent='space-between' 
          alignItems='center'
        >
          <Typography variant='body1'>
            Set Training Start Date
          </Typography>
          <MDDatePicker 
            value={startDate}
            fullWidth={false}
            options={{
              weekNumbers: true 
            }}
            onChange={ date => handleStartDateChange(date)}
          />
        </Stack>
        <Typography variant='subtitle2'>
          Training Date Range: {startDate} to {endDate}
        </Typography>
        <Stack
          direction='row' 
          spacing={2} 
          justifyContent='space-between' 
          alignItems='center'
        >
          <Typography variant='body1'>
            2. Fetch Data
          </Typography>
          <Button_GrmDark 
            onClick={() => handleDownloadButton()}
            disabled={status !== 'success' || processingData}
          >
            {processingData ? 'Processing' : 'Fetch'}
          </Button_GrmDark>
        </Stack>
        <Button_GrmDark
          disabled={csvData.data.length === 0}
        >
          <CSVLink
            {...csvData}
            style={{
              color: '#f1f1f1'
            }}
          >
            Download Data
          </CSVLink>
        </Button_GrmDark>
        <Button_GrmDark
          disabled={validationData.data.length === 0}
        >
          <CSVLink
            {...validationData}
            style={{
              color: '#f1f1f1'
            }}
          >
            Download Validation
          </CSVLink>
        </Button_GrmDark>
        {/* <ValidationTable /> */}
      </Stack>
  )
}
