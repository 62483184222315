import { tableSort } from "./r0004Utils";

const quarters = ['q1', 'q2', 'q3', 'q4']
const quartersMap = { 'Q1': 'q1', 'Q2': 'q2', 'Q3': 'q3', 'Q4': 'q4' }
const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
const monthsMap = { '01': 'jan', '02': 'feb', '03': 'mar', '04': 'apr', '05': 'may', '06': 'jun', '07':'jul', '08': 'aug', '09': 'sep', '10': 'oct', '11': 'nov', '12': 'dec'}

export default function generateTableData(rawData, intervalType, storeMap, includeAreas) {
  const tableData = [];

  if(rawData == null || rawData.length === 0){
    return tableData;
  }

  const intervals = intervalType === 'quarter'
  ? quarters
  : months

  const intervalMap = intervalType === 'quarter'
  ? quartersMap
  : monthsMap
  
  // Loop through each period in rawData
  rawData.forEach(period => {
    const year = period.period.slice(0,4);
    let curInterval = intervalMap[period.period.slice(-2)]
    
    let yearData = tableData.find(y => y.product === year);
    if (!yearData) {
      yearData = {
        product: year,
        subRows: [],
        rowType: 'yearRow'
      };
      intervals.forEach(interval => {
        yearData[`${interval}Count`] = 0
        yearData[`${interval}Sales`] = 0
      })
      tableData.push(yearData);
    }
    
    // Loop through each product for the period
    period.products.forEach(product => {
      let categoryData = yearData.subRows.find(c => c.product === product.category)
      if (!categoryData) {
        categoryData = {
          product: product.category,
          subRows: [],
          rowType: 'categoryRow'
        }
        intervals.forEach(interval => {
          categoryData[`${interval}Count`] = 0
          categoryData[`${interval}Sales`] = 0
        })
        yearData.subRows.push(categoryData);
      }
          
      let productData = categoryData.subRows.find(p => p.product === product.product);
      if (!productData) {
        productData = {
          product: product.product,
          subRows: [],
          rowType: 'productRow'
        };
        intervals.forEach(interval => {
          productData[`${interval}Count`] = 0
          productData[`${interval}Sales`] = 0
        })
        categoryData.subRows.push(productData);
      }
      // Loop through each store for the product
      product.stores.forEach(store => {
        let storeData = null
        let areaData = null
        if(includeAreas === 'yesAreas') {
          const area = storeMap[store.store]?.areaName;
          
          areaData = productData.subRows.find(a => a.product === area);
          if (!areaData) {
            areaData = {
              product: area,
              subRows: [],
              rowType: 'areaRow'
            };
            intervals.forEach(interval => {
              areaData[`${interval}Count`] = 0
              areaData[`${interval}Sales`] = 0
            })
            productData.subRows.push(areaData);
          }
          storeData = areaData.subRows.find(s => s.product === store.store)
        } else {
          storeData = productData.subRows.find(s => s.product === store.store)
        }
        if(!storeData){
          storeData = {
            product: store.store,
            rowType: 'storeRow',
            subRows: []
          }
          intervals.forEach(interval => {
            storeData[`${interval}Count`] = 0
            storeData[`${interval}Sales`] = 0
          })

          storeData[`${curInterval}Count`] = store.qty
          storeData[`${curInterval}Sales`] = store.sales
          if(includeAreas === 'yesAreas') {
            areaData.subRows.push(storeData)
          } else {
            productData.subRows.push(storeData)
          }
        } else {

          storeData[`${curInterval}Count`] = store.qty
          storeData[`${curInterval}Sales`] = store.sales
        } 
      });       
    });
  });

  const sortedTable = tableSort(tableData, 'product', 'ascending')
  
  aggregate(sortedTable, intervalType)
  
  return sortedTable;  
}

function aggregate(arr, periodType) {
  const aggregateValues = (rows) => {
    rows.forEach(row => {
      if (row.subRows.length > 0) {
        aggregateValues(row.subRows);

        const periods = periodType === 'quarter'
        ? quarters
        : months

        for (const period of periods) {
          row[`${period}Count`] = row.subRows.reduce((sum, r) => sum + r[`${period}Count`], 0);
          row[`${period}Sales`] = row.subRows.reduce((sum, r) => sum + r[`${period}Sales`], 0);
        }
      }
    });
  }
  
  aggregateValues(arr);
  return arr;
}