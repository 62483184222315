import React from 'react';

import { useTheme } from '@mui/material/styles'
import { Box, Stack, Toolbar } from '@mui/material';


export default function HeaderBase({  
  left,
  center,
  right,
  toolbarSx,
}) {
  const theme = useTheme()

  return (
    <Toolbar
      sx={{
        bgcolor: theme.palette.header.main,
        color: theme.palette.header.contrastText,
        ...toolbarSx,
        // display: 'flex',
        pt: 3,
        pb: 2,
        pl: 3
      }}
    >
      <Stack
        direction='row'
        spacing={.5}
        sx={{ width: '100%' }}
      >
        <Box
          sx={{
            // flex: 1, 
            textAlign: 'left',
            width: left.width
          }}
        >
          {left.content}
        </Box>
        <Box
          sx={{
            // flex: 1,
            textAlign: 'center',
            width: center.width,
          }}
        >
          {center.content}
        </Box>
        <Box
          sx={{
            // flex: 1,
            textAlign: 'right',
            width: right.width,
            pr: 1
          }}
        >
          {right.content}
        </Box>
      </Stack>
    </Toolbar>
  )

  // return (
  //     <Toolbar
  //       sx={{
  //         bgcolor: theme.palette.header.main,
  //         color: theme.palette.header.contrastText,
  //         ...toolbarSx,
  //       }}
  //     >
  //       <Stack 
  //         direction="row"
  //         spacing={2}
  //         justifyContent="space-between"
  //         alignItems="center"
  //       >

  //         <Stack alignItems="center">
  //           {left}
  //         </Stack>
        
  //         <Stack alignItems="center">  
  //           {center}
  //         </Stack>

  //         <Stack alignItems="center">
  //           {right}  
  //         </Stack>

  //       </Stack>
  //     </Toolbar>
  // );
}