import { Grid, Typography } from '@mui/material'

import { useTheme } from '@mui/material/styles'

import ButtonCsvExport from 'components/common/elements/ButtonCsvExport';
import ConfigButton from 'components/common/elements/Configurator/ConfigButton';
import FilterDropdown from 'components/common/elements/FilterDropdown';
import HeaderBase from "components/common/elements/HeaderBase";

import { csvColumns } from './r0005Utils';



export default function R0005Header({
  areas,
  filter,
  onChange,
  onConfigChange,
  tableColumns,
  tableData,
  title,
}){
  const theme = useTheme()

  const reportTitle = (
    <Typography
      variant='h5'
      fontWeight='medium'
      style={{
        color: theme.palette.header.contrastText
      }}
    >
      {title}
    </Typography>
  )

  const left = {
    content: reportTitle,
    width: '60%',
  }

  const center = {
    content: null,
    width: '0%',
  }

  const right = {
    content: <Controls csvData={tableData} csvFilename={title} csvHeaders={csvColumns} filterOptions={areas} filterValue={filter} filterHandler={onChange} toggleConfig={onConfigChange}/>,
    width: '40%',
  }

  return (
    <HeaderBase 
      left={left}
      center={center}
      right={right}
    />
  )
}

function Controls({
  csvData,
  csvFilename,
  csvHeaders,
  filterOptions,
  filterValue,
  filterHandler,
  toggleConfig,
}){
  return (
    <Grid
      container
      direction='row'
    >
      <Grid
        item
        xs={8}
        px={2}
      >
        <FilterDropdown 
          options={filterOptions}
          value={filterValue}
          onChange={filterHandler}
          label='Select Area'
          defaultValue=''
        />
      </Grid>
      <Grid
        item
        xs={2}
      >
        {/* <ButtonCsvExport
          data={csvData}
          filename={csvFilename}
          headers={csvHeaders}
        /> */}
      </Grid>
      <Grid
        item
        xs={2}
      >
        <ConfigButton
          toggleConfigurator={toggleConfig}
        />
      </Grid>
    </Grid>
  )
}