import { addDays, addWeeks, format, parseISO, startOfWeek, startOfYear, subDays, subWeeks } from 'date-fns'

export function addDaysToStrDate(strDate, days){
  const date = parseISO(strDate)
  const updatedDate = addDays(date, days)
  return format(updatedDate, 'yyyy-MM-dd')
}

/**
 * Deeply clones an object, preserving non-JSON values such as functions, undefined, symbols,
 * Date objects, RegExp objects, Maps, Sets, and handling circular references.
 * 
 * @param {Object} obj - The object to be cloned.
 * @param {WeakMap} [hash=new WeakMap()] - A WeakMap to track circular references.
 * @returns {Object} - A deep clone of the input object.
 */
export function deepClone(obj, hash = new WeakMap()) {
  // Return non-objects (primitive types) as-is
  if (Object(obj) !== obj) return obj;
  
  // Handle circular references by returning the cloned object from the hash map
  if (hash.has(obj)) return hash.get(obj);
  
  // Handle specific types
  if (obj instanceof Date) return new Date(obj);          // Clone Date objects
  if (obj instanceof RegExp) return new RegExp(obj);      // Clone RegExp objects
  if (obj instanceof Map) {                               // Clone Map objects
    const result = new Map();
    hash.set(obj, result);                                // Track the object in the hash map
    obj.forEach((value, key) => {
      result.set(deepClone(key, hash), deepClone(value, hash));
    });
    return result;
  }
  if (obj instanceof Set) {                               // Clone Set objects
    const result = new Set();
    hash.set(obj, result);                                // Track the object in the hash map
    obj.forEach(value => {
      result.add(deepClone(value, hash));
    });
    return result;
  }
  
  // Create a new object or array based on the input object's prototype
  const result = Array.isArray(obj) ? [] : Object.create(Object.getPrototypeOf(obj));
  hash.set(obj, result);                                  // Track the object in the hash map
  
  // Recursively copy properties
  Reflect.ownKeys(obj).forEach(key => {
    result[key] = deepClone(obj[key], hash);
  });
  
  return result;
}

export function subDaysFromStrDate(strDate, days){
  const date = parseISO(strDate)
  const updatedDate = subDays(date, days)
  return format(updatedDate, 'yyyy-MM-dd')
}


export function getDataType(data) {

  if(typeof data === "string") {
    return "string";
  }

  if(Array.isArray(data)) {
    return "array"; 
  }  

  if(data instanceof Date) { 
    return "date";
  }

  if(data instanceof Set) {
    return "set";
  }

  // if(Immutable.isImmutable(data)) { 
  //   return "immutable";
  // }

  return "object"; // default

}

export function getPrevYearDate(date, numYearsAgo){
  let isString = false
  if(getDataType(date) === 'string'){
    date = parseISO(date)
    isString = true
  }
  const weeksToSubtract = numYearsAgo * 52

  const prevDate = subWeeks(date, weeksToSubtract)
  if(isString)
  {
    return format(prevDate, 'yyyy-MM-dd')
  }
  return prevDate
}

export function getWeekStartDate(date){
  const mondayDate = startOfWeek(date, { weekStartsOn: 1} )
  
  const startDate = format(mondayDate, 'yyyy-MM-dd')
  
  return startDate
}

/**
 * Gets the dates for Thanksgiving and Christmas within a given date range.
 *
 * @param {Date} startDate - The start date of the range.
 * @param {Date} endDate - The end date of the range.
 * @returns {string[]} An array of strings representing the dates of Thanksgiving and Christmas in the format 'yyyy-MM-dd', sorted from earliest to latest.
 */
export function getHolidayCloseDatesForRange(sDate, eDate){
  
  let startDate
  let endDate

  if(getDataType(sDate) === 'string'){
    startDate = parseDate(sDate)
  } else {
    startDate = sDate
  }

  if(getDataType(eDate) === 'string'){
    endDate = parseDate(eDate)
  } else {
    endDate = eDate
  }

  const result = []

  let currentDate = new Date(startDate)

  while (currentDate <= endDate){
    const year = currentDate.getFullYear()
    const thanksgivingDate = calculateThanksgivingDate(year)
    const christmasDate = new Date(year, 11, 25)
    
    if(currentDate <= thanksgivingDate && thanksgivingDate <= endDate){
      result.push(format(thanksgivingDate, 'yyyy-MM-dd'))
    }

    if(currentDate <= christmasDate &&christmasDate <= endDate){
      result.push(format(christmasDate, 'yyyy-MM-dd'))
    }

    currentDate = startOfYear(new Date(currentDate.getFullYear() + 1, 0, 1))
  }

  return result.sort()
}

/**
 * Calculates the date of Thanksgiving for a given year.
 *
 * @param {number} year - The year for which to calculate the Thanksgiving date.
 * @returns {Date} The date of Thanksgiving for the specified year.
 */
function calculateThanksgivingDate(year){
  
  const november = new Date(year, 10, 1)
  
  // Find the first Thursday in November
  while(november.getDay() !== 4 /* Thursday */) {
    november.setDate(november.getDate() + 1)
  }

  // Add three weeks to get to the fourth Thursday
  november.setDate(november.getDate() + 21)

  return november
}

export function isHoliday(strDate, holidayArr){
  return holidayArr.includes(strDate)
}

export function parseDate(strDate){

  return parseISO(strDate)

}

/**
 * Parses a value to number, handling various formats
 * @param {string|number} value - Value to parse
 * @returns {number} Parsed numeric value
 */
export function parseValue(value) {
  if (typeof value === 'number') return value;
  if (!value) return 0;

  // Handle currency strings
  if (value.includes('$')) {
    return parseFloat(value.replace(/[$,]/g, ''));
  }

  // Handle percentage strings
  if (value.includes('%')) {
    return parseFloat(value.replace(/%/g, ''));
  }

  // Handle plain numbers with commas
  return parseFloat(value.replace(/,/g, ''));
}

/**
 * Replaces an object in an array that has the
 * same id as the new object.
 * 
 * @param {object[]} arr - The array to search
 * @param {object} newObj - The new object that
 * replace the object in the array which has the
 * same id 
 */
export function replaceObject(arr, newObj){
  for(let i = 0; i < arr.length; i++){
    if(arr[i].id === newObj.id){
      arr[i] = newObj
      break;
    }
  }
}

export function multiSortObjectArray(arr, ...properties){
  return arr?.sort((a, b) => {
    for(let prop of properties){
      if(a[prop] < b[prop]){
        return -1;
      }
      if(a[prop] > b[prop]){
        return 1
      }
    }
    // If all properties are equal, return 0
    return 0
  })
}

export function sortObjectArray(arr, key, order='asc'){
  return arr?.sort((a, b) => {
    if(a[key] < b[key]) {
      return order === 'asc' ? -1 : 1
    }
    if(a[key] > b[key]) {
      return order === 'asc' ? 1 : -1
    }
    return 0
  })
}

export function hexToRgb(hex){
  let r, g, b;

  // Remove # if present
  if(hex.indexOf('#') === 0){
    hex = hex.slice(1);
  }

  // Convert from 3 digit to 6 digit hex
  if(hex.length === 3){
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  r = parseInt(hex.slice(0, 2), 16)
  g = parseInt(hex.slice(2, 4), 16)
  b = parseInt(hex.slice(4, 6), 16)

  return [r, g, b]
}

export function rgbToHex(r, g, b){
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
}

export function darkenColor(color, percent){
  
  let rgb
  // Check if color is hex
  if(color.match(/^#/)){
    rgb = hexToRgb(color)
  } else if (color.indexOf('rgb') === 0){
    rgb = color.match(/\d+/g).map(Number)
  } else {
    throw new Error('Invalid color format')
  }
  
  // Darken
  rgb[0] = Math.round(rgb[0] * (100 - percent) / 100)
  rgb[1] = Math.round(rgb[1] * (100 - percent) / 100)
  rgb[2] = Math.round(rgb[2] * (100 - percent) / 100)

  // Convert RGB back to hex
  return rgbToHex(rgb[0], rgb[1], rgb[2])

}