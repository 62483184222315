import { useMemo } from 'react'

// GraphQL
import { dbGetItem } from 'graphql/queries'

import useQuery from 'hooks/useQuery'

export default function useProjectedSales(query){
  console.log('*** useProjectedSales ***')
  // const query = useMemo(() => getProjectedSalesQuery(date), [date])
  return useQuery(query)
}

function getProjectedSalesQuery(startDate){

  console.log('getProjectedSalesQuery', startDate)
  
  const dbVar = JSON.stringify({
    report: 'r0005_ProjectedSales',
    startDates: [startDate]
  })

  console.log('dbVar', dbVar)

  const query = {
    query: dbGetItem,
    variables: {
      db: 'grm.r0003reports',
      dbVariables: dbVar,
    }
  }

  return query 
}