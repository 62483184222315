import React from 'react'
import PropTypes from 'prop-types'

// @mui components
import { Dialog, DialogTitle, DialogContent, Grid } from '@mui/material'

// formik components
import { Formik, Form } from 'formik'
import Textfield from 'components/FormsUI/Textfield'
import * as Yup from 'yup'

// Material Dashboard Pro 2 components
import MDButton from 'components/MDButton'

export default function AddRoleDialog(props){

  const existingRoles = props.existingRoles
  
  function handleSubmit(values){
    props.submit(values)
  }

  const INITIAL_FORM_STATE = {
    role: '',
    description: ''
  }

  const FORM_VALIDATION = Yup.object().shape({
    role: Yup.string()
      .notOneOf(existingRoles, 'Role already defined.')
      .required('Required'),
    description: Yup.string()
      .required('Required')
  })

  return (
    <Dialog
      open={ props.open }
    >
      <DialogTitle>Add Role</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={INITIAL_FORM_STATE}
          validationSchema={FORM_VALIDATION}
          onSubmit={ (values) => { handleSubmit(values) }}
        >
          {({ isValid, dirty }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} 
                  // Included to give spacing to 'role' Texfield
                />

                <Grid item xs={12}>
                  <Textfield 
                    name='role'
                    label='Role'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Textfield 
                    name='description'
                    label='Description'
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={3}>
                  <MDButton variant='contained' color='info' onClick={ props.close }>
                    Cancel
                  </MDButton>
                </Grid>
                <Grid item xs={3}>
                  <MDButton variant='contained' color='info' type='submit' disabled={!(dirty && isValid)}>
                    Submit
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

AddRoleDialog.propTypes = {
  close: PropTypes.func,
  existingRoles: PropTypes.array,
  open: PropTypes.bool,
  submit: PropTypes.func,
}