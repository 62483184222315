import { deepClone } from 'utils/helperUtils'
import { formatCurrency } from 'utils/reportUtils'
import { DAYS_OF_WEEK, NUM_DATA_ROWS } from './constants'
import { clearRowData, createWeekDatesMap, initializeAreaRollup, updateRowMetrics } from './helpers'
import { processToastStoreData, processAlohaStoreData } from './processors'
import { calculateAreaRollupTheoHours } from './calculations'

export function mergeMetrics(alohaData, toastData, weekStartDate, baseTheoMap) {
  console.log('*** mergeMetrics ***')
  const mergedData = deepClone(alohaData)
  const weekDates = createWeekDatesMap(weekStartDate)
  console.log('weekDates', weekDates)

  mergedData.forEach(areaData => {
    const areaName = areaData.area
    const toastArea = toastData[areaName]
    const sectionData = areaData.data
    const rollupIndex = sectionData.length - NUM_DATA_ROWS
    const areaRollup = initializeAreaRollup()

    // Initialize labor dollars in rollup
    areaRollup.total.totalLaborDollars = 0
    DAYS_OF_WEEK.forEach(day => {
      areaRollup[day].totalLaborDollars = 0
    })

    // Get list of stores in this area
    const stores = []
    for (let i = 0; i < rollupIndex; i += NUM_DATA_ROWS) {
      stores.push(sectionData[i].day)
    }

    // Process each store section
    for (let i = 0; i < rollupIndex; i += NUM_DATA_ROWS) {
      const storeName = sectionData[i].day
      const toastStore = toastArea ? toastArea[storeName] : null
      let storeTotals = {
        actSales: 0,
        actCheckCount: 0,
        actFOH: 0,
        actBOH: 0,
        adjTheoFOH: 0,
        adjTheoBOH: 0,
        totalLaborDollars: 0,
        totalLaborHours: 0
      }
      let hasAnyValue = false  // Track if we've found any valid values

      DAYS_OF_WEEK.forEach((day, dayIndex) => {
        try {
          const rowIndex = i + dayIndex + 1
          const { date: dateStr, isFuture } = weekDates[day]
          const storeDay = sectionData[rowIndex]
          
          if (isFuture) {
            // Clear data for future dates
            clearRowData(sectionData[rowIndex])
          } else {
            
            // Get store metrics (either Toast or Aloha)
            const metrics = toastStore && toastStore[dateStr]
              ? processToastStoreData(toastStore[dateStr])
              : processAlohaStoreData(sectionData[rowIndex])

            if (metrics) {
              // Update store day row
              updateRowMetrics(sectionData[rowIndex], metrics, {
                adjTheoBOH: storeDay.adjTheoBOH,
                adjTheoFOH: storeDay.adjTheoFOH
              })

              // Add SPLH calculations for store day row
              const totalTheoHours = parseFloat(storeDay.adjTheoBOH) + parseFloat(storeDay.adjTheoFOH)
              const totalActHours = parseFloat(storeDay.actBOH) + parseFloat(storeDay.actFOH)
              const actSalesNum = parseFloat(storeDay.actSales.replace(/[$,\s]/g, ''))

              storeDay.theoSPLH = totalTheoHours > 0
                ? formatCurrency(actSalesNum / totalTheoHours, 2)
                : formatCurrency(0, 2)

              storeDay.actSPLH = totalActHours > 0
                ? formatCurrency(actSalesNum / totalActHours, 2)
                : formatCurrency(0, 2)

              // Get labor dollars for the day
              const laborDollars = toastStore && toastStore[dateStr]
                ? (toastStore[dateStr].bohRegularDollars || 0) +
                  (toastStore[dateStr].bohOvertimeDollars || 0) +
                  (toastStore[dateStr].fohRegularDollars || 0) +
                  (toastStore[dateStr].fohOvertimeDollars || 0)
                : 0

              // Accumulate store totals including theoretical hours
              Object.keys(storeTotals).forEach(key => {
                let value = 0
                if (key === 'adjTheoFOH' || key === 'adjTheoBOH') {
                  value = parseFloat(storeDay[key])
                } else if (key === 'totalLaborDollars') {
                  value = metrics.laborDollars || 0
                } else {
                  value = parseFloat(metrics[key])
                }
                if (!isNaN(value)) {
                  storeTotals[key] += value
                  hasAnyValue = true
                }
              })

              // Safely accumulate each metric to area rollup (handling NaN values)
              const actFOH = parseFloat(storeDay.actFOH) || 0
              const actBOH = parseFloat(storeDay.actBOH) || 0
              const adjTheoFOH = isNaN(storeDay.adjTheoFOH) ? 0 : parseFloat(storeDay.adjTheoFOH)
              const adjTheoBOH = isNaN(storeDay.adjTheoBOH) ? 0 : parseFloat(storeDay.adjTheoBOH)
              const actSales = parseFloat(storeDay.actSales.replace(/[$,\s]/g, '')) || 0
              const actCheckCount = parseFloat(storeDay.actCheckCount) || 0

              // Initialize area rollup day values if undefined
              if (!areaRollup[day].adjTheoFOH) areaRollup[day].adjTheoFOH = 0
              if (!areaRollup[day].adjTheoBOH) areaRollup[day].adjTheoBOH = 0

              // Accumulate values
              areaRollup[day].actFOH += actFOH
              areaRollup[day].actBOH += actBOH
              areaRollup[day].adjTheoFOH = parseFloat(areaRollup[day].adjTheoFOH) + adjTheoFOH
              areaRollup[day].adjTheoBOH = parseFloat(areaRollup[day].adjTheoBOH) + adjTheoBOH
              areaRollup[day].actSales += actSales
              areaRollup[day].actCheckCount += actCheckCount
              areaRollup[day].totalLaborDollars += laborDollars

              // Initialize area total values if undefined
              if (!areaRollup.total.adjTheoFOH) areaRollup.total.adjTheoFOH = 0
              if (!areaRollup.total.adjTheoBOH) areaRollup.total.adjTheoBOH = 0

              // Accumulate to area total
              areaRollup.total.actFOH += actFOH
              areaRollup.total.actBOH += actBOH
              areaRollup.total.adjTheoFOH += adjTheoFOH
              areaRollup.total.adjTheoBOH += adjTheoBOH
              areaRollup.total.actSales += actSales
              areaRollup.total.actCheckCount += actCheckCount
              areaRollup.total.totalLaborDollars += laborDollars
            }
          }
        } catch (err) {
          console.log(`Error processing data for ${storeName} on ${day}:`, err)
        }
      })

      // If we haven't found any valid values, ensure totals are set to 0
      if (!hasAnyValue) {
        Object.keys(storeTotals).forEach(key => {
          storeTotals[key] = 0
        })
      }

      // Update store totals row
      const totalRowIndex = i + 8
      updateRowMetrics(sectionData[totalRowIndex], storeTotals, {
        adjTheoBOH: storeTotals.adjTheoBOH,
        adjTheoFOH: storeTotals.adjTheoFOH
      })

      const totalTheoHours = storeTotals.adjTheoBOH + storeTotals.adjTheoFOH
      const totalActHours = storeTotals.actBOH + storeTotals.actFOH

      sectionData[totalRowIndex].theoSPLH = totalTheoHours > 0
        ? formatCurrency(storeTotals.actSales / totalTheoHours, 2)
        : formatCurrency(0, 2)

      sectionData[totalRowIndex].actSPLH = totalActHours > 0
        ? formatCurrency(storeTotals.actSales / totalActHours, 2)
        : formatCurrency(0, 2)

      // Add wage and labor calculations
      const avgWageRowIndex = i + 9
      const laborPctRowIndex = i + 10

      // Calculate Act Avg Wage
      const totalHours = storeTotals.actFOH + storeTotals.actBOH
      sectionData[avgWageRowIndex].actAvgCheck = totalHours > 0
        ? formatCurrency((storeTotals.totalLaborDollars / totalHours).toFixed(2), 2)
        : formatCurrency(0, 2)
      
      // Set Act Labor %
      sectionData[avgWageRowIndex].adjTheoBOH = formatCurrency(storeTotals.totalLaborDollars, 2)

      // Calculate Prev Week Avg Wage
      const priorWeekData = toastArea && toastArea[storeName]?.priorWeek
      sectionData[laborPctRowIndex].actAvgCheck = priorWeekData?.laborHours > 0
        ? formatCurrency((priorWeekData.laborDollars / priorWeekData.laborHours), 2)
        : formatCurrency(0, 2)

      // Calculate Act Labor %
      sectionData[laborPctRowIndex].adjTheoBOH = storeTotals.actSales > 0
        ? ((storeTotals.totalLaborDollars / storeTotals.actSales) * 100).toFixed(2) + '%'
        : '0.00%'
    }
    /******* */

    // Update Area Rollup section with calculated values
    DAYS_OF_WEEK.forEach((day, dayIndex) => {
      const rowIndex = rollupIndex + dayIndex +1
      const { isFuture } = weekDates[day]

      if (isFuture) {
        // Clear data for future dates in area rollup
        clearRowData(sectionData[rowIndex])
      } else {

        const dailyRollup = areaRollup[day]
        
        // Update basic metrics
        sectionData[rowIndex].actSales = formatCurrency(dailyRollup.actSales, 2)
        sectionData[rowIndex].actCheckCount = dailyRollup.actCheckCount
        sectionData[rowIndex].actFOH = dailyRollup.actFOH.toFixed(2)
        sectionData[rowIndex].actBOH = dailyRollup.actBOH.toFixed(2)
        sectionData[rowIndex].adjTheoFOH = dailyRollup.adjTheoFOH.toFixed(2)
        sectionData[rowIndex].adjTheoBOH = dailyRollup.adjTheoBOH.toFixed(2)

        // Calculate and update variances
        sectionData[rowIndex].fohActVsTheo = (dailyRollup.actFOH - dailyRollup.adjTheoFOH).toFixed(2)
        sectionData[rowIndex].bohActVsTheo = (dailyRollup.actBOH - dailyRollup.adjTheoBOH).toFixed(2)

        // Calculate and update SPLH
        const totalTheoHours = dailyRollup.adjTheoFOH + dailyRollup.adjTheoBOH
        const totalActHours = dailyRollup.actFOH + dailyRollup.actBOH

        sectionData[rowIndex].theoSPLH = totalTheoHours > 0 
          ? formatCurrency(dailyRollup.actSales / totalTheoHours, 2)
          : formatCurrency(0, 2)

        sectionData[rowIndex].actSPLH = totalActHours > 0
          ? formatCurrency(dailyRollup.actSales / totalActHours, 2)
          : formatCurrency(0, 2)

        // Calculate average check
        dailyRollup.actAvgCheck = dailyRollup.actCheckCount > 0
          ? (dailyRollup.actSales / dailyRollup.actCheckCount).toFixed(2)
          : '0.00'
      }
    })

    // Update Area Rollup total row
    const totalRowIndex = sectionData.length - 3
    const totalRollup = areaRollup.total

    // Update basic metrics for total row
    sectionData[totalRowIndex].actSales = formatCurrency(totalRollup.actSales, 2)
    sectionData[totalRowIndex].actCheckCount = totalRollup.actCheckCount
    sectionData[totalRowIndex].actFOH = totalRollup.actFOH.toFixed(2)
    sectionData[totalRowIndex].actBOH = totalRollup.actBOH.toFixed(2)
    sectionData[totalRowIndex].adjTheoFOH = totalRollup.adjTheoFOH.toFixed(2)
    sectionData[totalRowIndex].adjTheoBOH = totalRollup.adjTheoBOH.toFixed(2)

    // Calculate and update variances for total row
    sectionData[totalRowIndex].fohActVsTheo = (totalRollup.actFOH - totalRollup.adjTheoFOH).toFixed(2)
    sectionData[totalRowIndex].bohActVsTheo = (totalRollup.actBOH - totalRollup.adjTheoBOH).toFixed(2)

    // Calculate and update SPLH for total row
    const totalTheoHours = totalRollup.adjTheoFOH + totalRollup.adjTheoBOH
    const totalActHours = totalRollup.actFOH + totalRollup.actBOH

    sectionData[totalRowIndex].theoSPLH = totalTheoHours > 0 
      ? formatCurrency(totalRollup.actSales / totalTheoHours, 2)
      : formatCurrency(0, 2)

    sectionData[totalRowIndex].actSPLH = totalActHours > 0
      ? formatCurrency(totalRollup.actSales / totalActHours, 2)
      : formatCurrency(0, 2)

    // Calculate total average check
    sectionData[totalRowIndex].actAvgCheck = totalRollup.actCheckCount > 0
      ? (totalRollup.actSales / totalRollup.actCheckCount).toFixed(2)
      : '0.00'
    
    // Add area rollup wage and labor calculations
    const areaAvgWageRowIndex = totalRowIndex + 1
    const areaLaborPctRowIndex = totalRowIndex + 2

    // Calculate and set area Act Avg Wage
    const areaTotalHours = totalRollup.actFOH + totalRollup.actBOH
    sectionData[areaAvgWageRowIndex].actAvgCheck = areaTotalHours > 0
      ? formatCurrency((totalRollup.totalLaborDollars / areaTotalHours).toFixed(2), 2)
      : formatCurrency(0, 2)

    // Set area Act Labor $
    sectionData[areaAvgWageRowIndex].adjTheoBOH = formatCurrency(totalRollup.totalLaborDollars, 2)

    // Calculate and set area Prev Week Act Avg Wage
    let areaPriorWeekLaborDollars = 0
    let areaPriorWeekHours = 0

    for (let i=0; i < rollupIndex; i += NUM_DATA_ROWS) {
      const storeName = sectionData[i].day
      const toastStore = toastArea ? toastArea[storeName] : null
      const priorWeekData = toastStore?.priorWeek

      if (priorWeekData) {
        areaPriorWeekLaborDollars += priorWeekData.laborDollars
        areaPriorWeekHours += priorWeekData.laborHours
      }
    }

    sectionData[areaLaborPctRowIndex].actAvgCheck = areaPriorWeekHours > 0
      ? formatCurrency((areaPriorWeekLaborDollars / areaPriorWeekHours).toFixed(2), 2)
      : formatCurrency(0, 2)

    // Calculate and set area Act Labor %
    sectionData[areaLaborPctRowIndex].adjTheoBOH = totalRollup.actSales > 0
      ? ((totalRollup.totalLaborDollars / totalRollup.actSales) * 100).toFixed(2) + '%'
      : '0.00%'
  })

  return mergedData
}