import { useEffect, useState } from 'react'

import { API, graphqlOperation } from 'aws-amplify'

import { QueryStatus } from 'utils/queryUtils'
import { listThresholdMappings } from 'graphql/queries'
import { onCreateThresholdMapping, onUpdateThresholdMapping, onDeleteThresholdMapping } from 'graphql/subscriptions'

import { replaceObject } from 'utils/helperUtils'

export default function useThresholdMap(){
  console.log('*** useThresholdMap')
  const [data, setData] = useState([])
  const [status, setStatus] = useState(QueryStatus.IDLE)
  const [error, setError] = useState(null)

  useEffect(() => {
    let didCancel = false;
    setStatus(QueryStatus.PENDING)
    setData([])

    const fetchData = async () => {
      try {

        const map = []
        let nextToken

        do {
          const response = await API.graphql({
            query: listThresholdMappings,
            variables: {
              nextToken: nextToken
            }
          })
          console.log('response', {response})

          const mapArr = response.data.listThresholdMappings.items.map(({
            createdAt,
            updatedAt,
            ...keep
          }) => keep)

          map.push(...mapArr)
          nextToken = response.data.listThresholdMappings.nextToken
        } while (nextToken)

        
        if(!didCancel) {
          setData(map)
          setStatus(QueryStatus.SUCCESS)
        }

      } catch (err) {
        console.log('Error fetching threshold map', err)
        if(!didCancel){
          setError(err)
          setStatus(QueryStatus.ERROR)
        }
        throw err
      }
    }

    fetchData();    

    resubscribe()

    return () => {
      console.log('unmount')
      didCancel = true;
    }
  }, [])

  function resubscribe() {
    const createSub = API.graphql(graphqlOperation(onCreateThresholdMapping)).subscribe({
      next: (subData) => {
        const { createdAt, updatedAt, ...keep } = subData.value.data.onCreateThresholdMapping

        const newMapping = {...keep}

        setData(existingData => {
          let updatedData = JSON.parse(JSON.stringify(existingData))
          updatedData.push(newMapping)
          return updatedData
        })
      },
      error: (err) => {
        console.log('onCreateThresholdMapping err', err)        
      }
    })

    const updateSub = API.graphql(graphqlOperation(onUpdateThresholdMapping)).subscribe({
      next: (subData) => {
        const { createdAt, updatedAt, ...keep } = subData.value.data.onUpdateThresholdMapping

        const newMapping = {...keep}

        setData(prevData => {
          const dataCopy = JSON.parse(JSON.stringify(prevData))
          replaceObject(dataCopy, newMapping)
          return dataCopy
        })
      },
      error: (err) => {
        console.log('onUpdateThresholdMapping err', err)
        createSub.unsubscribe()
        updateSub.unsubscribe()
        deleteSub.unsubscribe()
        setTimeout(() => {
          resubscribe()
        }, 5000)
      }
    })

    const deleteSub = API.graphql(graphqlOperation(onDeleteThresholdMapping)).subscribe({
      next: (subData) => {
        const { id } = subData.value.data.onDeleteThresholdMapping

        setData(existingData => {
          return existingData.filter(t => t.id !== id)
        })
      },
      error: (err) => {
        console.log('onDeleteThresholdMapping err', err)        
      }
    })

    return {
      unsubscribe: () => {
        createSub.unsubscribe()
        updateSub.unsubscribe()
        deleteSub.unsubscribe()
      }
    }
  }

  return {
    status,
    data,
    error,
  }
}

