import React, { useEffect, useState } from 'react'

// AWS Amplify components
import { API } from 'aws-amplify'
import { createMessage } from 'graphql/mutations'
import { listMessages } from 'graphql/queries'

// MUI components
import { Icon, Stack } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle';

// MD2 components
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDEditor from 'components/MDEditor'
import MDTypography from 'components/MDTypography'

// RadixDash components
import MessageTable from './MessageTable'
import NewMessageDialog from './NewMessageDialog'
import Progress from 'components/Progress/Progress'
import { useUser } from 'components/RDAuthContext'


// const activeMessages = [
//   {
//     messageId: '1',
//     createdAt: '2022-08-04',
//     note: 'Message 1',
//     status: 'active',
//     position: 1
//   },
//   {
//     messageId: '2',
//     createdAt: '2022-08-04',
//     note: 'Message 2',
//     status: 'active',
//     position: 2
//   },
// ]

export default function MessageAdmin(){

  const { user } = useUser()
  const clientId = user.client

  const [content, setContent] = useState('')
  const [message, setMessage] = useState('')
  const [msgLoading, setMsgLoading] = useState(true)
  const [activeMessages, setActiveMessages] = useState([{}])
  const [inactiveMessages, setInactiveMessages] = useState([{}])

  // Get messages from database when component loads
  useEffect(() => {
    fetchMessages()
  }, [])

  async function fetchMessages() {
    console.log('fetchMessages')
    try {
      const queryResponse = await API.graphql({
        query: listMessages,
        variables: {
          clientId: clientId,
          status: 'all'
        }
      })

      const allMessages = queryResponse.data.listMessages
      console.log('allMessages', allMessages)

      const activeMessageList = allMessages.filter(x => {
        return x.status === 'active'
      })

      setActiveMessages(activeMessageList)

      const inactiveMessageList = allMessages.filter(x => {
        return x.status === 'inactive'
      })

      setInactiveMessages(inactiveMessageList)
      
      setMsgLoading(false)
    } catch (err) {
      console.log('Error fetching messages', err)
    }
  }

  async function postMessage(){
    console.log('postMessage')

    const position = activeMessages.length + 1

    const inputData = {
      clientId: clientId,
      note: content,
      status: 'active',
      position: position
    }

    console.log('inputData', inputData)

    try {
      let postedMessage = await API.graphql(
        {
          query: createMessage,
          variables: inputData
        }
      )
      console.log('Message successfully added to DB', postedMessage)

      // Add new message to Active message list and sort by position
      let newList = [...activeMessages, postedMessage.data.createMessage]
      newList.sort((a, b) => (a.position > b.position) ? 1: -1)
      
      // Update the Active Message table with the new message
      setActiveMessages([...newList])
      // Clear editor
      setContent('')
    } catch (err) {
      console.log('Error posting message', err)
    }
  }

  function insertMessage(msgType, msg){
    console.log('msg', msg)
    console.log('msgType', msgType)
    let updateArr = []
    if(msgType === 'active'){
      msg.position = activeMessages.length + 1
      updateArr = [...activeMessages, msg]
      updateArr.sort((a,b) => (a.position > b.position) ? 1 : -1)
      setActiveMessages([...updateArr])
    } else {
      msg.position = inactiveMessages.length + 1
      updateArr = [...inactiveMessages, msg]
      updateArr.sort((a,b) => (a.position > b.position) ? 1 : -1)
      setInactiveMessages([...updateArr])
    }
  }

  const onChange = (content, delta, source, editor) => {
    setContent(editor.getHTML())
  }

  return (
    <MDBox
      sx={{
        maxWidth: 1000,
        mx: 'auto'
      }}
    >
      {
        msgLoading ?
        <MDBox
          ml='2rem'
        >
          <Progress />
        </MDBox> :
        
        <MDBox
        >
          <Stack
            spacing={2}
          >
            <MDBox>
              <MDEditor 
                value={ content }
                onChange={ onChange }
              />
              <MDButton
                variant='contained'
                color='info'
                sx={{
                  mt: 2
                }}
                disabled={ content === '' || content === '<p><br></p>'}
                onClick={ postMessage }
              >
                Post Message
              </MDButton>
            </MDBox>
            <MDBox
              mb={3}
            >
              <MDBox
                display='flex'
                alignItems='center'
              >
                <Icon 
                  sx={{
                    color: 'green',
                    mr: 1
                  }}
                >
                  circle
                </Icon>
                <MDTypography variant='h4'>
                  Active Messages
                </MDTypography>
              </MDBox>
              <MessageTable 
                messages={ activeMessages }
                setExternal={ (msg) => insertMessage('inactive', msg)}
                tableType='active'
              />
            </MDBox>
            
            <MDBox
              mb={3}
            >
              <MDBox
                display='flex'
                alignItems='center'
              >
                <Icon 
                  sx={{
                    color: 'red',
                    mr: 1
                  }}
                >
                  circle
                </Icon>
                <MDTypography variant='h4'>
                  Inactive Messages
                </MDTypography>
              </MDBox>
              <MessageTable 
                messages={ inactiveMessages }
                setExternal={ (msg) => insertMessage('active', msg)}
                tableType='inactive'
              />
            </MDBox>
          </Stack>
        </MDBox>
      }
    </MDBox>
  )
}