import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types"; 

import { Card } from '@mui/material'


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography'

import RDTable from 'components/RDTable'
import RDStickyTable from 'components/RDTable/RDStickyTable';
import R0002CellStyle from './R0002CellStyle';

const columnNames = require('./columns3')

export default function R0002Table(props){
  // console.log('R0002Table props', props)

  const [data, setData] = useState([...props.data])

  useEffect(() => {    
    let tipData = [...props.data]
    tipData.forEach((row, index) => {
        switch(row.rowType){
          case 'storeRow':
          case 'totalRow':
            if(props.compStoreSwitch){
              console.log('setting Company Store tips')
              tipData[index].tipNSTotal = tipData[index].compNSTotal
              tipData[index].tipNSDineIn = tipData[index].compNSDineIn
              tipData[index].tipNSToGo = tipData[index].compNSToGo
              tipData[index].tipNSDelivery = tipData[index].compNSDelivery
              tipData[index].tipGSDelivery = tipData[index].compGSDelivery
              tipData[index].tipCCTotal = tipData[index].compCCTotal
              tipData[index].tipCCDineIn = tipData[index].compCCDineIn
              tipData[index].tipCCToGo = tipData[index].compCCToGo
              tipData[index].tipCCDelivery = tipData[index].compCCDelivery
              tipData[index].tipACTotal = tipData[index].compACTotal
              tipData[index].tipACDineIn = tipData[index].compACDineIn
              tipData[index].tipACToGo = tipData[index].compACToGo
              tipData[index].tipACDelivery = tipData[index].compACDelivery
              tipData[index].tipSPLHTotal = tipData[index].compSPLHTotal
              tipData[index].tipFOH = tipData[index].compFOH
              tipData[index].tipBOH = tipData[index].compBOH
              tipData[index].tipOTTotal = tipData[index].compOTTotal
              tipData[index].tipOTFOH = tipData[index].compOTFOH
              tipData[index].tipOTBOH = tipData[index].compOTBOH
              tipData[index].tipLaborPct = tipData[index].compLaborPct
            } else {
              console.log('setting Previous Period tips')
              tipData[index].tipNSTotal = tipData[index].pNSTotal
              tipData[index].tipNSDineIn = tipData[index].pNSDineIn
              tipData[index].tipNSToGo = tipData[index].pNSToGo
              tipData[index].tipNSDelivery = tipData[index].pNSDelivery
              tipData[index].tipGSDelivery = tipData[index].pGSDelivery
              tipData[index].tipCCTotal = tipData[index].pCCTotal
              tipData[index].tipCCDineIn = tipData[index].pCCDineIn
              tipData[index].tipCCToGo = tipData[index].pCCToGo
              tipData[index].tipCCDelivery = tipData[index].pCCDelivery
              tipData[index].tipACTotal = tipData[index].pACTotal
              tipData[index].tipACDineIn = tipData[index].pACDineIn
              tipData[index].tipACToGo = tipData[index].pACToGo
              tipData[index].tipACDelivery = tipData[index].pACDelivery
              tipData[index].tipSPLHTotal = tipData[index].pSPLHTotal
              tipData[index].tipFOH = tipData[index].pFOH
              tipData[index].tipBOH = tipData[index].pBOH
              tipData[index].tipOTTotal = tipData[index].pOTTotal
              tipData[index].tipOTFOH = tipData[index].pOTFOH
              tipData[index].tipOTBOH = tipData[index].pOTBOH
              tipData[index].tipLaborPct = tipData[index].pLaborPct
            }
            break;
          default:
            // No Action
        }
      })      
    setData(tipData)
  }, [props.data, props.compStoreSwitch])

  useEffect(() => {
    console.log('data changed', data)
  }, [props.data])

  const columns = columnNames.columns
  
  return (
    <MDBox 
      // width='100%'  
      sx={{backgroundColor: '#e0e0e0'}}
    >  
      <RDStickyTable
        columns={columns}
        data={data}
        styleFunc={R0002CellStyle}
        cellOptions={{
          style: [
            {
              option: 'priceIncrease',
              value: props.priceIncrease
            },
            {
              option: 'priceIncreaseSwitch',
              value: props.priceIncreaseSwitch
            }
          ]
        }}
      /> 
    </MDBox>
  )
}

R0002Table.propTypes = {
  compStoreSwitch: PropTypes.bool,
  data: PropTypes.array,
  priceIncrease: PropTypes.number,
  priceIncreaseSwitch: PropTypes.bool,
}