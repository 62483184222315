


export default function R0001CellStyle(row, cell, options){
  
  const value = row.original
  const column = cell.column.id

  let setting = {}
  
  let bgColor = '#fff'
  let borderLeft = null
  let fontSize = '.7rem'
  let fontWeight = 'normal'
  let textAlign = 'center'
  let textColor = '#000'
  let textIndent = 0
  let borderTop = null
  let padding = '0px 10px'

  // Row Settings
  switch(value.kpi){
    case 'Average Check Total':
    case 'Average Check Dine-In':
    case 'Average Check Delivery (Gross)':
    case 'Average Check Delivery (Net)':
    case 'Average Check To Go':
      bgColor = '#d9e1f2'
      break;
    case 'Net Sales Food':
    case 'Comp Count':
    case 'Delivery Door Dash Gross Sales':
      borderTop = '7px solid #a44c0e'
      switch(row.index%2){
        case 0:
          break;
        default:
          bgColor = '#f9e9d3'
      }
      break;
    case 'Salad Dollars':
    case 'Cheese Cake Dollars':
    case 'Dessert Trio Dollars':
    case 'Comp Service Count':
    case 'Comp Quality Count':
    case 'Void Wrong Made':
    case 'Void 3rd Party No Show':
      bgColor = '#e6e6e6'
      break;
    case 'Bruschetta Dollars':
    case 'Cannoli Dollars':
    case 'Comp Service Dollars':
    case 'Comp Quality Dollars':
    case 'Void Guest No Show':
      bgColor = '#f3f2f1'
      break;
    default:
      switch(row.index%2){
        case 0:
          break;
        default:
          bgColor = '#f9e9d3' 
      }
  }

  switch(column){
    case 'kpi':
      textAlign = 'left'
      switch(value.kpi){
        case 'Check Count':
        case 'Net Sales':
        case 'Gross Sales':
        case 'Dine-In Check Count':
        case 'Dine-In Net Sales':
        case 'Delivery Gross Sales':
        case 'Delivery Net Sales':
        case 'To Go Check Count':
        case 'To Go Net Sales':
        case 'Net Sales Starters':
        case 'Net Sales Pizza':
        case 'Net Sales Desserts':
        case 'Void Wrong Made':
        case 'Void Guest No Show':
        case 'Void 3rd Party No Show':
        case 'Comp Service Count':
        case 'Comp Service Dollars':
        case 'Comp Quality Count':
        case 'Comp Quality Dollars':
          textIndent = '0.5rem'
          break;
        case 'Salad Dollars':
        case 'Bruschetta Dollars':
        case 'Cheese Cake Dollars':
        case 'Cannoli Dollars':
        case 'Dessert Trio Dollars':
          textIndent = '1rem'
          break;
        default:
          // no action
      }
      break;
    case 'actualValue':
    case 'actualPercent':
    case 'valueToMid':
      borderLeft = '7px solid #a44c0e'
      break;
    case 'valueRank':
      switch(value.kpi){
        case 'Comp Count':
        case 'Comp Dollars':
        case 'Void Count':
        case 'Void Dollars':
        case 'Void Wrong Made':
        case 'Promo Count':
        case 'Promo Dollars':
          if(options.cvpPct){
            switch(value.percentRank){
              case 'Low':
                bgColor = 'green'
                textColor = '#fff'
                fontWeight = 'bold'
                break;
              case 'Mid':
                bgColor = 'yellow'
                fontWeight = 'bold'
                break;
              default:
                bgColor = 'red'
                textColor = '#fff'
            }
          } else {
            switch(value.valueRank){
              case 'Low':
                bgColor = 'green'
                textColor = '#fff'
                fontWeight = 'bold'
                break;
              case 'Mid':
                bgColor = 'yellow'
                fontWeight = 'bold'
                break;
              default:
                bgColor = 'red'
                textColor = '#fff'
            }
          }          
          break;
        case 'Net Sales Food':
        case 'Net Sales Starters':
        case 'Net Sales Pizza':
        case 'Net Sales Desserts':
        case 'Net Sales Beverage':
        case 'Net Sales Beer':
        case 'Net Sales Liquor':
        case 'Net Sales Wine':
          if(options.nsPct){
            switch(value.percentRank){
              case 'Top':
                bgColor = 'green'
                textColor = '#fff'
                fontWeight = 'bold'
                break;
              case 'Mid':
                bgColor = 'yellow'
                fontWeight = 'bold'
                break;
              default:
                bgColor = 'red'
                textColor = '#fff'
            }
          } else {
            switch(value.valueRank){
              case 'Top':
                bgColor = 'green'
                textColor = '#fff'
                fontWeight = 'bold'
                break;
              case 'Mid':
                bgColor = 'yellow'
                fontWeight = 'bold'
                break;
              default:
                bgColor = 'red'
                textColor = '#fff'
            }
          }
          break;            
        default:
          switch(value.valueRank){
            case 'Low':
              bgColor = 'red'
              textColor = '#fff'
              break;
            case 'Mid':
              bgColor = 'yellow'
              fontWeight = 'bold'
              break;
            default:
              bgColor = 'green'
              textColor = '#fff'
              fontWeight = 'bold'
          }          
      }
      break;   
    case 'top':
      borderLeft = '1px solid #000'
      break;
    default:
      // no action
  }


  setting = {
    backgroundColor: bgColor,
    borderLeft: borderLeft,
    borderTop: borderTop,
    color: textColor,
    fontSize: fontSize,
    fontWeight: fontWeight,
    padding: padding,
    textAlign: textAlign,
    textIndent: textIndent,
    width: 200
  }

  return setting
}