import { PAGE_TYPES } from '../../constants';
import buildR0002Pages from '../../utils/pageBuilder';
import { parseValue } from 'utils/helperUtils'
import { calculatePercentage } from 'clients/grm/views/R0002/R0002Utils';
import { formatPercent } from '../../utils/formatters';

/**
 * Filters merged data by page type
 * @param {Array} mergedData - Full merged dataset
 * @param {string} pageType - Type of page to filter for
 * @returns {Array} Filtered data array
 */
export const filterByPageType = (mergedData, pageType) => {
  return mergedData.filter(dataset => dataset.pageId.includes(pageType));
};

/**
 * Processes dataset for a specific store type
 * @param {Array} data - Data to process
 * @param {string} storeType - Type of store (company/franchise)
 * @param {DataProcessor} dataProcessor - Instance of DataProcessor
 * @param {Array} thresholds - Threshold configurations
 * @returns {Array} Processed data
 */
export const processDataset = (data, storeType, dataProcessor, thresholds) => {
  // Handle empty pages
  if (data.some(dataset => dataset.pageId.includes(PAGE_TYPES.EMPTY))) {
    return [];
  }

  // Process each dataset
  const processedData = data.reduce((acc, dataset) => {
    const processed = dataProcessor.processData(dataset.data);
    return [...acc, ...processed];
  }, []);

  // Build final pages with thresholds
  return buildR0002Pages(processedData, thresholds, storeType);
};

export const processSSSRow = (totalRowData) => {
  const cNSTotal = parseValue(totalRowData.cNSTotal)
  const pNSTotal = parseValue(totalRowData.pNSTotal)
  const cNSDineIn = parseValue(totalRowData.cNSDineIn)
  const cNSToGo = parseValue(totalRowData.cNSToGo)
  const cNSDelivery = parseValue(totalRowData.cNSDelivery)
  const cGSDelivery = parseValue(totalRowData.cGSDelivery)

  return {
    rowType: 'summaryRow',
    rowData: {
      store: 'SSS %',
      pNSTotal: formatPercent(calculatePercentage(cNSTotal, pNSTotal)),
      cNSDineIn: formatPercent(calculatePercentage(cNSDineIn, cNSTotal)),
      cNSToGo: formatPercent(calculatePercentage(cNSToGo, cNSTotal)),
      cNSDelivery: formatPercent(calculatePercentage(cNSDelivery, cNSTotal)),
      cGSDelivery: formatPercent(calculatePercentage(cGSDelivery, cNSTotal))
    }
  }
}