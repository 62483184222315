import { API } from 'aws-amplify'
import { dbGetItem, listForecastedSales } from 'graphql/queries'
import { createForecastedSales } from 'graphql/mutations'
import { addDays, addWeeks, format, parseISO, startOfWeek } from 'date-fns'
import { getPrevYearDate, getWeekStartDate, subDaysFromStrDate } from "utils/helperUtils";


export const initialData = {
  week: '',
  store: '',
  avgWage: '',
  monProjSales: '',
  monForecastedSales: '',
  monOneYearAgoSales: '',
  monOneYearAgoNotes: '',
  monTwoYearsAgoSales: '',
  monTwoYearsAgoNotes: '',
  monProjAvgCheck: '',
  monSchedFOH: '',
  monSchedBOH: '',
  tueProjSales: '',
  tueForecastedSales: '',
  tueOneYearAgoSales: '',
  tueOneYearAgoNotes: '',
  tueTwoYearsAgoSales: '',
  tueTwoYearsAgoNotes: '',
  tueProjAvgCheck: '',
  tueSchedFOH: '',
  tueSchedBOH: '',
  wedProjSales: '',
  wedForecastedSales: '',
  wedOneYearAgoNotes: '',
  wedTwoYearsAgoSales: '',
  wedTwoYearsAgoNotes: '',
  wedProjAvgCheck: '',
  wedSchedFOH: '',
  wedSchedBOH: '',
  thuProjSales: '',
  thuForecastedSales: '',
  thuOneYearAgoNotes: '',
  thuTwoYearsAgoSales: '',
  thuTwoYearsAgoNotes: '',
  thuProjAvgCheck: '',
  thuSchedFOH: '',
  thuSchedBOH: '',
  friProjSales: '',
  friForecastedSales: '',
  friOneYearAgoNotes: '',
  friTwoYearsAgoSales: '',
  friTwoYearsAgoNotes: '',
  friProjAvgCheck: '',
  friSchedFOH: '',
  friSchedBOH: '',
  satProjSales: '',
  satForecastedSales: '',
  satOneYearAgoNotes: '',
  satTwoYearsAgoSales: '',
  satTwoYearsAgoNotes: '',
  satProjAvgCheck: '',
  satSchedFOH: '',
  satSchedBOH: '',
  sunProjSales: '',
  sunForecastedSales: '',
  sunOneYearAgoNotes: '',
  sunTwoYearsAgoSales: '',
  sunTwoYearsAgoNotes: '',
  sunProjAvgCheck: '',
  sunSchedFOH: '',
  sunSchedBOH: ''
}

export const initialTheoHours = {
  Mon: 0,
  Tue: 0,
  Wed: 0,
  Thu: 0,
  Fri: 0,
  Sat: 0,
  Sun: 0
}

 /* Function to set the week values to use in the
   * dropdown for the user to select the week for
   * the projections to be set
   */

export const setWeeks = () => {
  const today = new Date()
  const currentMonday = startOfWeek(today, { weekStartsOn: 1})
  const nextMonday = addWeeks(currentMonday, 1)
  const followingMonday = addWeeks(currentMonday, 2)

  const weeks = [
    format(currentMonday, 'yyyy-MM-dd'),
    format(nextMonday, 'yyyy-MM-dd'),
    format(followingMonday, 'yyyy-MM-dd')
  ]
  
  return weeks
}

export const weeks = setWeeks()

const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']

export async function getHistoricalSalesData(store, selectedWeek){
  console.log(`getHistoricalSalesData(), store=${store}, selectedWeek1=${selectedWeek}`)

  let salesData = {}

  // Get sales data for most recently completed 2 weeks
  try {
    // Get current week
    const currentWeek = getWeekStartDate(new Date())
    const oneWeekAgo = subDaysFromStrDate(currentWeek, 7)
    const twoWeeksAgo = subDaysFromStrDate(currentWeek, 14)

    const pastWeeksSalesData = await fetchSalesData(oneWeekAgo, twoWeeksAgo, store)

    const twoWeeksAgoData = pastWeeksSalesData.week1
    const oneWeekAgoData = pastWeeksSalesData.week2

    days.forEach(day => {
      const average = ((oneWeekAgoData[day].actSales + twoWeeksAgoData[day].actSales)/2)
      salesData[`${day}Average`] = average.toFixed(2)
    })

  } catch (err) {
    console.log('Error fetching pastWeeksSalesData', err)
    throw err
  }

  // Get sales data from 1 year ago and 2 years ago
  try {
    const oneYearAgo = getPrevYearDate(selectedWeek, 1)
    const twoYearsAgo = getPrevYearDate(selectedWeek, 2)

    const pastYearsSalesData = await fetchSalesData(oneYearAgo, twoYearsAgo, store)
    const twoYearsAgoData = pastYearsSalesData.week1
    const oneYearAgoData = pastYearsSalesData.week2

    days.forEach(day => {
      salesData[`${day}OneYearAgoSales`] = oneYearAgoData[day].actSales.toFixed(0)
      salesData[`${day}OneYearAgoNotes`] = oneYearAgoData[day].notes
      salesData[`${day}TwoYearsAgoSales`] = twoYearsAgoData[day].actSales.toFixed(0)
      salesData[`${day}TwoYearsAgoNotes`] = twoYearsAgoData[day].notes
    })
  } catch (err) {
    console.log('Error fetching pastYearsSalesData', err)
    throw err
  }

  return salesData
}

export async function getForecastedSales(store, selectedWeek){
  console.log('getForecastedData')
  const startDate = selectedWeek
  const endDate = format(addDays(parseISO(startDate), 6), 'yyyy-MM-dd')
  
  const filter = {
    storeName: {
      eq: store.toLowerCase()
    },
    date: {
      between: [startDate, endDate]
    }
  }

  try {
    const forecastResponse = await API.graphql({
      query: listForecastedSales,
      variables: { filter }
    })

    const transformedForecastData = tranformForecastedSales(forecastResponse.data.listForecastedSales.items)
    return transformedForecastData
  } catch (error) {
    console.log('Error fetching forcasted sales', error)
  }
}

function tranformForecastedSales(forecastData){

  const result = {
    monForecastedSales: 0,
    tueForecastedSales: 0,
    wedForecastedSales: 0,
    thuForecastedSales: 0,
    friForecastedSales: 0,
    satForecastedSales: 0,
    sunForecastedSales: 0
  };

  forecastData.forEach(forecast => {
    const date = parseISO(forecast.date)
    const dayOfWeek = format(date, 'eee').toLowerCase()

    switch (dayOfWeek) {
      case 'mon':
        result.monForecastedSales = forecast.forecastedSales.toFixed(0);
        break;
      case 'tue':
        result.tueForecastedSales = forecast.forecastedSales.toFixed(0);
        break;
      case 'wed':
        result.wedForecastedSales = forecast.forecastedSales.toFixed(0);
        break;
      case 'thu':
        result.thuForecastedSales = forecast.forecastedSales.toFixed(0);
        break;
      case 'fri':
        result.friForecastedSales = forecast.forecastedSales.toFixed(0);
        break;
      case 'sat':
        result.satForecastedSales = forecast.forecastedSales.toFixed(0);
        break;
      case 'sun':
        result.sunForecastedSales = forecast.forecastedSales.toFixed(0);
        break;
      default:
        break;
    }
  })

  return result
}

async function fetchSalesData(week1, week2, store){

  const dbVars = {
    report: 'r0003ActSalesAndNotes',
    week1: week1,
    week2: week2,
    store: store
  }
  console.log('dbVars', dbVars)

  const dbVarsJSON = JSON.stringify(dbVars)

  try {
    const queryResponse = await API.graphql({
      query: dbGetItem,
      variables: {
        db: 'grm.reports',
        dbVariables: dbVarsJSON
      }
    })
    console.log('queryResponse', queryResponse)
    const responseData = JSON.parse(JSON.parse(queryResponse.data.dbGetItem.statusItem))
    console.log('responseData', responseData)
    return responseData
  } catch (err){
    console.log('Error fetching sales data', err)
    throw err
  }
}

export function removeDayKeys(obj){
  const dayPrefixes = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
  const keyPatterns = [
    'Average',
    'OneYearAgoSales',
    'OneYearAgoNotes',
    'TwoYearsAgoSales',
    'TwoYearsAgoNotes',
    'ForecastedSales'
  ]

  const regex = new RegExp(`^(${dayPrefixes.join('|')})(${keyPatterns.join('|')})$`)

  Object.keys(obj).forEach(key => {
    if(regex.test(key)){
      delete obj[key]
    }
  })

  return obj
}

export async function writeToDynamoDB(record) {
  console.log('record', record)
  try {
    const result = await API.graphql({
      query: createForecastedSales,
      variables: {
        input: record
      }
    })
    console.log('writeToDynamoDB result', result)
    return result
  } catch (error) {
    console.log('Error inserting forecast record', error)
  } 
}

function cleanNumberInput(value) {
  if (typeof value === 'number'){
    return value
  }
  const num = Number(value)
  return isNaN(num) ? null : num
}

export function cleanProjectionData(formData){
  const cleanedData = {}
  const stringFields = ['week', 'store']

  for (const field in formData) {
    if (stringFields.includes(field)){
      cleanedData[field] = formData[field].trim()
    } else {
      cleanedData[field] = cleanNumberInput(formData[field])
    }
  }

  return cleanedData
}