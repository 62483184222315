import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from "prop-types"; 

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import RDFormatTable from 'components/RDTable/RDFormatTable'

const columns = require('./r0002Columns')

export default function R0002Table(props){
  console.log('R0002Table props', props)

  const [data, setData] = useState([...props.data])
  
  // const tableColumns = useMemo(() => columns.columns )
  const tableColumns = columns.columns

  useEffect(() => {
    let tipData = props.data.map((row) => {
      switch (row.rowType) {
        case 'storeRow':
        case 'totalRow':
          if (props.storeTypeSwitch && props.compStoreSwitch) {
            // Store Type is Franchise & comparing to Company stores
            return {
              ...row,
              tipType: 'company',
              tipNSTotal: row.compNSTotal,
              tipNSDineIn: row.compNSDineIn,
              tipNSToGo: row.compNSToGo,
              tipNSDelivery: row.compNSDelivery,
              tipGSDelivery: row.compGSDelivery,
              tipCCTotal: row.compCCTotal,
              tipCCDineIn: row.compCCDineIn,
              tipCCToGo: row.compCCToGo,
              tipCCDelivery: row.compCCDelivery,
              tipACTotal: row.compACTotal,
              tipACDineIn: row.compACDineIn,
              tipACToGo: row.compACToGo,
              tipACDelivery: row.compACDelivery,
              tipSPLHTotal: row.compSPLHTotal,
              tipFOH: row.compFOH,
              tipBOH: row.compBOH,
              tipOTTotal: row.compOTTotal,
              tipOTFOH: row.compOTFOH,
              tipOTBOH: row.compOTBOH,
              tipLaborPct: row.compLaborPct,
            };
          } else {
            return {
              ...row,
              tipType: 'previous',
              tipNSTotal: row.pNSTotal,
              tipNSDineIn: row.pNSDineIn,
              tipNSToGo: row.pNSToGo,
              tipNSDelivery: row.pNSDelivery,
              tipGSDelivery: row.pGSDelivery,
              tipCCTotal: row.pCCTotal,
              tipCCDineIn: row.pCCDineIn,
              tipCCToGo: row.pCCToGo,
              tipCCDelivery: row.pCCDelivery,
              tipACTotal: row.pACTotal,
              tipACDineIn: row.pACDineIn,
              tipACToGo: row.pACToGo,
              tipACDelivery: row.pACDelivery,
              tipSPLHTotal: row.pSPLHTotal,
              tipFOH: row.pFOH,
              tipBOH: row.pBOH,
              tipOTTotal: row.pOTTotal,
              tipOTFOH: row.pOTFOH,
              tipOTBOH: row.pOTBOH,
              tipLaborPct: row.pLaborPct,
            };
          }
        default:
          return row;
      }
    });
    
    setData(tipData);
  }, [props.data, props.storeTypeSwitch, props.compStoreSwitch]);
  // const data = useMemo(() => tipData, [])

  return (
    <MDBox   
      sx={{backgroundColor: '#e0e0e0'}}
    > 
      <RDFormatTable
        columns={tableColumns}
        data={data}
        conditions={props.thresholds}
        compStore={props.compStoreSwitch}
      /> 
    </MDBox>
  )
}

// R0002Table.propTypes = {
//   compStoreSwitch: PropTypes.bool,
//   data: PropTypes.array,
//   priceIncrease: PropTypes.number,
//   priceIncreaseSwitch: PropTypes.bool,
// }