import React, { useEffect, useState } from 'react'

// MUI components
import { Tab, Tabs } from '@mui/material'

// MD2 components
import MDBox from 'components/MDBox'

// RadixDash components
import MessageAdmin from 'clients/admin/DashboardAdmin/MessageAdmin'
import RDDashboardLayout from 'components/RDDashboardLayout'
import RDDashboardNavbar from 'components/RDDashboardNavbar'
import RDFooter from 'components/RDFooter'
import RDTabPanel from 'components/RDTabPanel'



export default function DashboardAdmin(){

  const [tabValue, setTabValue] = useState(0)
  const [verticalTabValue, setVerticalTabValue] = useState(0)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const handleVerticalTabChange = (event, newValue) => {
    setVerticalTabValue(newValue)
  }

  return (
    <RDDashboardLayout>
      <RDDashboardNavbar />
        <MDBox
          mx='auto'
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label='dashboard-tabs'
          >
            <Tab label='Home' />
          </Tabs>
          <RDTabPanel
            value={tabValue}
            index={0}            
          >
            <MDBox
              sx={{
                flexGrow: 1,
                display: 'flex',
                bgcolor: 'white'
              }}
            >
              <Tabs
                value={verticalTabValue}
                onChange={handleVerticalTabChange}
                aria-label='vertical-tabs'
                orientation='vertical'
                sx={{ borderRight: 1, borderColor: 'info'}}
              >
                <Tab label='Notifications' id='vertical-tab-0' />
              </Tabs>
              <RDTabPanel
                value={verticalTabValue}
                index={0}
              >
                <MessageAdmin />
              </RDTabPanel>
            </MDBox>
          </RDTabPanel>
        </MDBox>
      <RDFooter />
    </RDDashboardLayout>
  )
}