import React, {useEffect, useState} from 'react'

import { Geo } from 'aws-amplify'

import { Autocomplete, Stack, TextField } from '@mui/material'

import { useField, useFormikContext } from 'formik'

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import RDCountrySelector from 'components/RDLocations/RDCountrySelector'

export default function LocationAutoComplete({
  name,
  setLocationData,
  initialAddress,
  ...otherProps
}){

  const { setFieldValue } = useFormikContext()
  const [field, meta] = useField(name)

  const [address, setAddress] = useState(initialAddress)
  const [addresses, setAddresses] = useState([initialAddress])
  // const [address, setAddress] = useState('')
  // const [addresses, setAddresses] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [country, setCountry] = useState('USA')

  useEffect(() => {
    console.log('initialAddress', initialAddress)
    if(initialAddress == null){
      setAddress(initialAddress)
    }
  },[])

  useEffect(() => {
    /********************************************
     Call getAddresses in order to update the Autocomplete options list when the user types another character in the input field
     ********************************************/
    if(inputValue.length > 0){
      getAddresses(inputValue)
    }
  }, [inputValue])

  async function getAddresses(text){
    /********************************************
     Call aws Geo function to find potential address options for the user to select based on the input they are typing.
     ********************************************/
     const searchAreaContraints = {
      countries: [country],
      maxResults: 5
    }
    
    try {
      let results = await Geo.searchByText(text, searchAreaContraints)
      // console.log('Geo results', results[0].label)
      setAddresses(results)
    } catch(err) {
      console.log('Error getting addresses', err)
    }
  }

  useEffect(() => {
    /********************************************
     User has selected an address, set the location data and field value
     ********************************************/
     if(address){
      setLocationData(address)
      setFieldValue(name, address.label)
     }
  }, [address])

  return (
    <MDBox>
      
      <Stack direction='column' spacing={2}>
        <RDCountrySelector 
          setCountry={ (value) => setCountry(value) }
        />
        <Autocomplete
          id='address-selector'
          sx={{ width: 350 }}
          getOptionLabel={(option) => typeof option === 'string' ? option : option.label }
          filterOptions={(x) => x}
          options={addresses}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={address}
          onChange={(event, newValue) => {
            setAddress(newValue)
          }}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue)
          }}
          renderInput={(params) => (
            <TextField {...params} label='Type address' fullWidth />
          )}
          renderOption={(props, option) => (
            <MDBox
              component='li'
              sx={{
                '& > img': {mr: 2, flexShrink: 0}
              }}
              {...props}
            >
              {option.label}
            </MDBox>
          )}
          size='small'
        />
        
      </Stack>
    </MDBox>
  )
}