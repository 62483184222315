/***********************************************
 * RDFormatTable is has the following features:
 * - Static header rows
 * - Data-driven cell formatting
 ***********************************************/
import React, { useCallback, useMemo, useEffect } from 'react'

// @mui components
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'

// react-table components
import { useBlockLayout, useTable } from 'react-table'

// react-window components
import { FixedSizeList } from 'react-window'

// RadixDash components
import RDFormatBodyCell from './RDFormatBodyCell'
import scrollbarWidth from './scrollbarWidth'

// Create a default prop getter
const defaultPropGetter = () => ({})

export default function RDFormatTable({
  columns,
  data,
  conditions,
  compStore,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
}){
  
  const defaultColumn = useMemo(
    () => ({
      width: 50,
    }),
    []
  )

  const scrollBarSize = useMemo(
    () => scrollbarWidth(), []
  )

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useBlockLayout
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    totalColumnsWidth,
    prepareRow,
  } = tableInstance

  const rowHeight = 18
  // Set table height based on browser viewport height
  const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)
      return (
        <TableRow
          {...row.getRowProps({
            style
          })}
        >
          {row.cells.map(cell => {
            
            return (
              <RDFormatBodyCell
                {...cell.getCellProps()}
                width={cell.column.width}
                style={cell.column.cellStyle}
                conditions={conditions}
                compType={compStore}
              >
                {cell.render('Cell')}
              </RDFormatBodyCell>
            )
          })}
        </TableRow>
      )
    },
    [prepareRow, rows]
  )

  return (
    <TableContainer>
      <Table
        {...getTableProps()}
      >
        <TableHead
          sx={{
            padding: 0,
            width: 'calc(100% - 1rem)'
          }}
        >
          {headerGroups.map(headerGroup => (
            <TableRow
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.headerStyle,
                      width: column.width,
                    },
                    getColumnProps(column),
                    getHeaderProps(column),
                  ])}
                >
                  {column.render('Header')}
                </th>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody
          {...getTableBodyProps()}
        >
          <FixedSizeList
            height={Math.max(vh - 300, 450)}
            itemCount={ rows.length }
            itemSize={ rowHeight }
            width={ totalColumnsWidth + scrollBarSize }
            overscanCount={30}
          >
            { RenderRow }
          </FixedSizeList>
        </TableBody>
      </Table>
    </TableContainer>
  )
}