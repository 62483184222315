
// @mui components
import { Button, Tooltip } from '@mui/material'
import Icon from '@mui/material/Icon'
import { useTheme } from '@mui/material/styles'

export default function ConfigButton({
  disabled,
  toggleConfigurator
}){

  const theme = useTheme()

  return (
    <Tooltip title='Report Settings'>
      <Button
        variant='contained'
        color='headerButton'
        style={{
          width: 40,
          height: 40,
          minWidth: 40,
          borderRadius: 5,
        }}
        sx={{
          '&:hover': {
            bgcolor: 'headerButton.hover'
          }
        }}
        disabled={disabled}
        onClick={ toggleConfigurator }
      >
        <Icon
          fontSize='small'
          // color='header'
          sx={{
            mx: 'auto',
            my: 'auto',
          }}
        >
          settings
        </Icon>
      </Button>
    </Tooltip>
  )
}